import {
  AllocationAndProgressCardSkaTimeStatusFragment,
  MitemStatus,
} from '../../../../generated/graphql';
import { RecursiveOmit } from '../../../../services/typeHelpers';
import {
  calculateDoneVsPlan,
  filterExpectedToBeDoneSkas,
} from '../../common/utils/teamPageHelpers';
import { AllocationAndProgressCard_MigV2WithoutTypename } from '../components/KeyActivitySection/components/skaSection/components/allocationAndProgressCard/AllocationAndProgressCard';
import {
  ActivitiesExpectedToBeDonePerGroup,
  ActivitiesPerGroup,
  AlignmentPercentagePerGroup,
  CompletedActivitiesPerGroup,
  DoneVsPlanPerGroup,
  OverdueActivitiesPerGroup,
  UpcomingActivitiesPerGroup,
} from './calculationTypes';

type AllocationAndProgressCardSkaTimeStatusWithoutTypename = RecursiveOmit<
  AllocationAndProgressCardSkaTimeStatusFragment,
  '__typename'
>;

export type SkaWithNonNullableAlignedMigV2 =
  AllocationAndProgressCardSkaTimeStatusWithoutTypename;

const getAmountOfSkasWithMigAlignment = (
  sprintKeyActivities: SkaWithNonNullableAlignedMigV2[],
  alignedMigId: string
) =>
  sprintKeyActivities.filter((ska) => {
    return supportsMig(ska, alignedMigId);
  }).length;

//Creating new object and calculating total activities per SKA with the same mig alignment
export const countActivitiesPerMig = (
  selectedSprintKeyActivities: SkaWithNonNullableAlignedMigV2[],
  teamMigs: AllocationAndProgressCard_MigV2WithoutTypename[]
) => {
  const amountOfActivitiesPerMigAlignment: Record<string, ActivitiesPerGroup> =
    {};

  if (teamMigs) {
    for (const mig of teamMigs) {
      const current = {
        id: mig.domainId.itemId,
        name: mig.name,
        activities: getAmountOfSkasWithMigAlignment(
          selectedSprintKeyActivities,
          mig.domainId.itemId
        ),
      };
      amountOfActivitiesPerMigAlignment[mig.domainId.itemId] = current;
    }
  }

  return amountOfActivitiesPerMigAlignment;
};

export const countUpcomingSkasPerMig = (
  selectedSprintKeyActivities: SkaWithNonNullableAlignedMigV2[],
  amountOfActivitiesPerMigAlignment: Record<string, ActivitiesPerGroup>
) => {
  const migAlignmentWithUpcomingSkas: Record<
    string,
    UpcomingActivitiesPerGroup
  > = {};

  Object.entries(amountOfActivitiesPerMigAlignment).forEach(
    ([shortMigId, val]) =>
      (migAlignmentWithUpcomingSkas[shortMigId] = {
        id: shortMigId,
        name: val.name,
        activities: val.activities,
        upcomingActivities: 0,
      })
  );

  for (const ska of selectedSprintKeyActivities) {
    for (const supportedMig of ska.sprintKeyActivity?.supportedMigs ?? []) {
      const current =
        migAlignmentWithUpcomingSkas[supportedMig.domainId.itemId];

      if (
        ska.status === MitemStatus.ACTIVE ||
        ska.status === MitemStatus.PLANNED
      ) {
        current.upcomingActivities++;
        migAlignmentWithUpcomingSkas[supportedMig.domainId.itemId] = current;
      }
    }
  }

  return migAlignmentWithUpcomingSkas;
};

//Calculating how much % each mig alignment have out of all SKA's
export const calculateMigAlignmentPercentage = (
  amountOfSKAs: number,
  amountOfActivitiesPerMigAlignment: Record<string, UpcomingActivitiesPerGroup>
) => {
  const migAlignmentAndPercentageOfAllActivities: Record<
    string,
    AlignmentPercentagePerGroup
  > = {};

  Object.entries(amountOfActivitiesPerMigAlignment).forEach(
    ([shortMigId, val]) =>
      (migAlignmentAndPercentageOfAllActivities[shortMigId] = {
        ...val,
        percentageOfAllActivities:
          amountOfSKAs === 0 || val.activities === 0
            ? null
            : Math.floor((val.activities / amountOfSKAs) * 100),
      })
  );

  return migAlignmentAndPercentageOfAllActivities;
};

//Calculating the goal of how many SKA's should be done for each alignment
export const countGoalPerMig = (
  filteredSprint: SkaWithNonNullableAlignedMigV2[],
  migAlignmentAndPercentageOfAllActivities: Record<
    string,
    AlignmentPercentagePerGroup
  >
) => {
  const migAlignmentGoal: Record<string, ActivitiesExpectedToBeDonePerGroup> =
    {};

  Object.entries(migAlignmentAndPercentageOfAllActivities).forEach(
    ([shortMigId, val]) =>
      (migAlignmentGoal[shortMigId] = {
        ...val,
        goal: 0,
      })
  );

  for (const ska of filteredSprint) {
    for (const supportedMig of ska.sprintKeyActivity?.supportedMigs ?? []) {
      const current = migAlignmentGoal[supportedMig.domainId.itemId];
      current.goal = current.goal + 1;
      migAlignmentGoal[supportedMig.domainId.itemId] = current;
    }
  }

  return migAlignmentGoal;
};

//Calculating how many activities that are completed for each mig alignment
export const countCompletedActivitiesPerMig = (
  filteredSprint: SkaWithNonNullableAlignedMigV2[],
  migAlignmentGoal: Record<string, ActivitiesExpectedToBeDonePerGroup>
) => {
  const migCompletedActivities: Record<string, CompletedActivitiesPerGroup> =
    {};

  Object.entries(migAlignmentGoal).forEach(
    ([shortMigId, val]) =>
      (migCompletedActivities[shortMigId] = {
        ...val,
        completedActivities: 0,
      })
  );

  for (const ska of filteredSprint) {
    for (const supportedMig of ska.sprintKeyActivity?.supportedMigs ?? []) {
      const current = migCompletedActivities[supportedMig.domainId.itemId];
      if (ska.status === MitemStatus.COMPLETED) {
        current.completedActivities++;
      }
      migCompletedActivities[supportedMig.domainId.itemId] = current;
    }
  }

  return migCompletedActivities;
};

export const countOverdueActivitiesPerMig = (
  sprintKeyActivities: SkaWithNonNullableAlignedMigV2[],
  migData: Record<string, CompletedActivitiesPerGroup>
) => {
  const migOverdueActivities: Record<string, OverdueActivitiesPerGroup> = {};

  Object.entries(migData).forEach(
    ([shortMigId, val]) =>
      (migOverdueActivities[shortMigId] = {
        ...val,
        overdueActivities: 0,
      })
  );

  for (const ska of sprintKeyActivities) {
    for (const supportedMig of ska.sprintKeyActivity?.supportedMigs ?? []) {
      const current = migOverdueActivities[supportedMig.domainId.itemId];
      if (ska.status === MitemStatus.OVERDUE) {
        current.overdueActivities++;
      }
      migOverdueActivities[supportedMig.domainId.itemId] = current;
    }
  }

  return migOverdueActivities;
};

//Calculating performance for Done vs Plan
export const migDoneVsPlan = (
  migCompletedActivities: Record<string, OverdueActivitiesPerGroup>
) => {
  const migData: Record<string, DoneVsPlanPerGroup> = {};

  Object.entries(migCompletedActivities).forEach(
    ([shortMigId, val]) =>
      (migData[shortMigId] = {
        ...val,
        doneVsPlan:
          val.percentageOfAllActivities === null
            ? null
            : calculateDoneVsPlan(val.completedActivities, val.goal),
      })
  );

  return migData;
};

export const getMigData = (
  sprintKeyActivities: AllocationAndProgressCardSkaTimeStatusWithoutTypename[],
  teamMigs: AllocationAndProgressCard_MigV2WithoutTypename[]
) => {
  const activeMigIds = teamMigs.map((mig) => mig.domainId.itemId);

  const skasAlignedToActiveMigs = sprintKeyActivities.filter(
    (ska): ska is SkaWithNonNullableAlignedMigV2 => {
      if (!ska.sprintKeyActivity?.supportedMigs?.length) {
        return false;
      }
      return activeMigIds.some((migId) => supportsMig(ska, migId));
    }
  );

  const amountOfActivitiesPerMigAlignment = countActivitiesPerMig(
    skasAlignedToActiveMigs,
    teamMigs
  );

  const upcomingSkasPerMigAlignment = countUpcomingSkasPerMig(
    skasAlignedToActiveMigs,
    amountOfActivitiesPerMigAlignment
  );

  const migAlignmentPercentage = calculateMigAlignmentPercentage(
    skasAlignedToActiveMigs.length,
    upcomingSkasPerMigAlignment
  );

  const filteredSkasExpectedToBeDone = filterExpectedToBeDoneSkas(
    skasAlignedToActiveMigs
  );

  const migAlignmentGoal = countGoalPerMig(
    filteredSkasExpectedToBeDone,
    migAlignmentPercentage
  );

  const migCompletedActivities = countCompletedActivitiesPerMig(
    skasAlignedToActiveMigs,
    migAlignmentGoal
  );

  const migOverdueActivities = countOverdueActivitiesPerMig(
    skasAlignedToActiveMigs,
    migCompletedActivities
  );

  const migData = migDoneVsPlan(migOverdueActivities);

  const completeMigData = Object.entries(migData).map(([key, val]) => ({
    id: key,
    name: val.name,
    amountOfSKAs: val.activities,
    percentageOfAllActivities: val.percentageOfAllActivities,
    doneVsPlan: val.doneVsPlan,
    completed: val.completedActivities,
    upcomingActivities: val.upcomingActivities,
    overdueActivities: val.overdueActivities,
  }));

  return completeMigData;
};

function supportsMig(
  ska: SkaWithNonNullableAlignedMigV2,
  alignedMigId: string
) {
  return (
    ska.sprintKeyActivity?.supportedMigs?.some(
      (mig) => mig.domainId.itemId === alignedMigId
    ) ?? false
  );
}
