import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Colors } from '../../../../../../../../componentLibrary/Colors';
import { Empty, Spin } from 'antd';
import SmallLegends from './contributorsGraph/SmallLegends';
import { CustomTooltip } from '../../../../../../../../../components/graphs/CustomTooltip';
import { stringSort } from '../../../../../../../../../services/stringSort';
import { friendlyUsername } from '../../../../../../../../../services/friendlyUsername';
import { gql, useQuery } from '@apollo/client';
import { ContributorsGraphKeyActivityCommitmentsAndAchievementsDocument } from '../../../../../../../../../generated/graphql';

interface Props {
  wkaId: string;
  teamId: string;
}

export const ContributorsGraph = ({ teamId, wkaId }: Props) => {
  const { data, loading } = useQuery(
    ContributorsGraphKeyActivityCommitmentsAndAchievementsDocument,
    {
      variables: { teamId, weeklyKeyActivityId: wkaId },
    }
  );

  const commitmentsAndAchievements =
    data?.teamKeyActivityCommitmentAndAchievementsForPeriod
      ?.commitmentsAndAchievements;

  if (
    commitmentsAndAchievements == null ||
    commitmentsAndAchievements.length === 0
  ) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  if (loading) {
    return <Spin />;
  }

  const teamMembersCommitment = commitmentsAndAchievements.flatMap((caa) =>
    caa.teamMembers.map((member) => {
      return {
        commitment: member.commitment,
        achievement: member.achievement,
        user: {
          friendlyUsername: friendlyUsername(member.user),
        },
      };
    })
  );

  const teamMembersCommitmentSorted = teamMembersCommitment.sort((a, b) =>
    stringSort(a.user.friendlyUsername, b.user.friendlyUsername)
  );

  return (
    <div>
      <div className="flx flx--jc-center ">
        <SmallLegends
          colors={{
            committed: Colors.Grays.FRAMES_AND_LINES,
            fulfilled: Colors.Action.BLUE,
          }}
        />
      </div>
      <ResponsiveContainer
        width="99%"
        height={teamMembersCommitmentSorted.length * 40 + 50}
      >
        <BarChart
          data={teamMembersCommitmentSorted}
          layout="vertical"
          margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />

          <XAxis type="number" tick={{ fontSize: 11 }} strokeOpacity={0.2} />
          <YAxis
            type="category"
            dataKey="user.friendlyUsername"
            tick={{ fontSize: 11 }}
            strokeOpacity={0.2}
            width={95}
          />

          <Tooltip
            cursor={false}
            content={(props) => <CustomTooltip {...props} />}
          />

          <Bar
            dataKey="commitment"
            fill={Colors.Grays.FRAMES_AND_LINES}
            barSize={8}
          />
          <Bar dataKey="achievement" fill={Colors.Action.BLUE} barSize={8} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CONTRIBUTORS_GRAPH_TEAM_COMMITMENTS_AND_ACHIEVEMENTS = gql`
  query contributorsGraphKeyActivityCommitmentsAndAchievements(
    $teamId: ID!
    $weeklyKeyActivityId: ID
    $tenantId: ID
  ) {
    teamKeyActivityCommitmentAndAchievementsForPeriod(
      teamId: $teamId
      weeklyKeyActivityId: $weeklyKeyActivityId
      tenantId: $tenantId
    ) {
      teamId
      commitmentsAndAchievements {
        ...ContributorsGraph_WeeklyKeyActivityCommitmentAndAchievement
        ...ContributorsGraph_SprintKeyActivityCommitmentAndAchievement
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CONTRIBUTORS_GRAPH_WKA_FRAGMENT = gql`
  fragment ContributorsGraph_WeeklyKeyActivityCommitmentAndAchievement on WeeklyKeyActivityCommitmentAndAchievement {
    id
    startDate
    endDate
    teamMembers {
      commitment
      achievement
      user {
        id
        name
        displayName
        email
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CONTRIBUTORS_GRAPH_SKA_FRAGMENT = gql`
  fragment ContributorsGraph_SprintKeyActivityCommitmentAndAchievement on SprintKeyActivityCommitmentAndAchievement {
    id
    teamMembers {
      commitment
      achievement
      user {
        id
        name
        displayName
        email
      }
    }
  }
`;
