import { gql, useMutation } from '@apollo/client';
import { CreateInitiativeDocument } from '../../../../../generated/graphql';

export const useCreateInitiative = () => {
  const [createInitiative, { loading, error }] = useMutation(
    CreateInitiativeDocument
  );
  return { createInitiative, loading, error };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_INITIATIVE = gql`
  mutation createInitiative(
    $tenantId: ID
    $teamId: ID!
    $initiative: InitiativeInput!
  ) {
    createInitiative(
      tenantId: $tenantId
      teamId: $teamId
      initiative: $initiative
    ) {
      id
      name
      description
      rev
      domainId {
        teamId
        itemId
      }
    }
  }
`;
