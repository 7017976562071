import Skeleton from 'react-loading-skeleton';

export const KeyActivitySectionSkeleton = () => {
  return (
    <>
      <h2 className="mb--l">
        <Skeleton width={'50%'} />
      </h2>
    </>
  );
};
