import Icon from '@ant-design/icons';

const TruckOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.99984 11.3333C3.44428 11.3333 2.97206 11.1388 2.58317 10.75C2.19428 10.3611 1.99984 9.88885 1.99984 9.33329H0.666504V1.99996C0.666504 1.63329 0.797059 1.3194 1.05817 1.05829C1.31928 0.797182 1.63317 0.666626 1.99984 0.666626H11.3332V3.33329H13.3332L15.3332 5.99996V9.33329H13.9998C13.9998 9.88885 13.8054 10.3611 13.4165 10.75C13.0276 11.1388 12.5554 11.3333 11.9998 11.3333C11.4443 11.3333 10.9721 11.1388 10.5832 10.75C10.1943 10.3611 9.99984 9.88885 9.99984 9.33329H5.99984C5.99984 9.88885 5.80539 10.3611 5.4165 10.75C5.02762 11.1388 4.55539 11.3333 3.99984 11.3333ZM3.99984 9.99996C4.18873 9.99996 4.34706 9.93607 4.47484 9.80829C4.60262 9.68052 4.6665 9.52218 4.6665 9.33329C4.6665 9.1444 4.60262 8.98607 4.47484 8.85829C4.34706 8.73052 4.18873 8.66663 3.99984 8.66663C3.81095 8.66663 3.65262 8.73052 3.52484 8.85829C3.39706 8.98607 3.33317 9.1444 3.33317 9.33329C3.33317 9.52218 3.39706 9.68052 3.52484 9.80829C3.65262 9.93607 3.81095 9.99996 3.99984 9.99996ZM1.99984 7.99996H2.53317C2.72206 7.79996 2.93873 7.63885 3.18317 7.51663C3.42762 7.3944 3.69984 7.33329 3.99984 7.33329C4.29984 7.33329 4.57206 7.3944 4.8165 7.51663C5.06095 7.63885 5.27762 7.79996 5.4665 7.99996H9.99984V1.99996H1.99984V7.99996ZM11.9998 9.99996C12.1887 9.99996 12.3471 9.93607 12.4748 9.80829C12.6026 9.68052 12.6665 9.52218 12.6665 9.33329C12.6665 9.1444 12.6026 8.98607 12.4748 8.85829C12.3471 8.73052 12.1887 8.66663 11.9998 8.66663C11.8109 8.66663 11.6526 8.73052 11.5248 8.85829C11.3971 8.98607 11.3332 9.1444 11.3332 9.33329C11.3332 9.52218 11.3971 9.68052 11.5248 9.80829C11.6526 9.93607 11.8109 9.99996 11.9998 9.99996ZM11.3332 6.66663H14.1665L12.6665 4.66663H11.3332V6.66663Z" />
  </svg>
);

export const TruckOutlineIcon = (props: any) => (
  <Icon component={TruckOutlineIconSvg} {...props} />
);
