import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  MigSection_MigV2Fragment,
  MigStatus,
} from '../../../../../generated/graphql';
import { ToggleSection } from '../ToggleSection';
import { stringSort } from '../../../../../services/stringSort';
import { groupByStatus } from '../../../common/utils/teamPageHelpers';
import { TeamOverviewPageDivider } from '../TeamOverviewPageDivider';
import { TeamEntityPlaceholder } from '../TeamEntityPlaceholder';
import { useCurrentLocale } from '../../../../../hooks/useCurrentLocale';
import { gql } from '@apollo/client';
import { MigCollapsibleCardWithActions } from './components/MigCollapsibleCardWithActions';
import { MigSectionSkeleton } from './MigSection.skeleton';

interface Props {
  teamInfo: { id: string; name: string };
  teamMigs?: MigSection_MigV2Fragment[];
  loading?: boolean;
}

export const MigSection = ({ teamInfo, loading, teamMigs }: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const { locale } = useCurrentLocale();
  const selectedLanguage = locale;

  const migsByStatus = groupByStatus(teamMigs ?? [], Object.values(MigStatus));

  const { ENDED, UPCOMING, ACTIVE, OVERDUE } = migsByStatus;

  const showEmptyComponentForEndedAndUpcomingMigs =
    UPCOMING.length === 0 && ENDED.length === 0;

  const showEmptyComponentForActiveAndOverdueMigs =
    ACTIVE.length === 0 && OVERDUE.length === 0 && !loading;

  return (
    <>
      <div>
        <h2 className="mb--l" data-intercom-target="MIG section header">
          {t('MigSection.header')}
        </h2>
        {showEmptyComponentForActiveAndOverdueMigs && (
          <div className="flx flx--jc-center">
            <TeamEntityPlaceholder
              header={t('MigSection.noMigPlaceholder.header')}
              text={t('MigSection.noMigPlaceholder.text')}
              buttonText={t('MigSection.noMigPlaceholder.buttonText')}
              url={`https://help.howwe.io/${selectedLanguage}/articles/28937-mig-most-important-goal`}
              size="small"
              data-intercom-target="No active MIG's placeholder"
            />
          </div>
        )}
        {[ACTIVE, OVERDUE]
          .flat()
          .sort((a, b) => stringSort(a.name, b.name))
          .map((mig) => {
            return (
              <MigCollapsibleCardWithActions
                key={mig.id}
                mig={mig}
                teamInfo={teamInfo}
                onToggleExpand={() =>
                  setExpanded((prev) => ({
                    ...prev,
                    [mig.id]: !prev[mig.id],
                  }))
                }
                isExpanded={expanded[mig.id]}
                loading={loading}
              />
            );
          })}
        <div className="flx flx--jc-center flx--column">
          <ToggleSection
            titleExpanded={t('MigSection.toggleSectionExpanded')}
            titleNotExpanded={t('MigSection.toggleSectionNotExpanded')}
            data-intercom-target="Expandable section to show ended and upcoming MIGs"
            disabled={loading}
          >
            <div className="mt--l">
              {showEmptyComponentForEndedAndUpcomingMigs && (
                <div className="flx flx--jc-center">
                  <TeamEntityPlaceholder
                    header={t(
                      'MigSection.noMigPlaceholder.headerToggleSection'
                    )}
                    text={t('MigSection.noMigPlaceholder.text')}
                    buttonText={t('MigSection.noMigPlaceholder.buttonText')}
                    url={`https://help.howwe.io/${selectedLanguage}/articles/28937-mig-most-important-goal`}
                    data-intercom-target="No ended or upcoming MIG's placeholder"
                  />
                </div>
              )}
              {UPCOMING.length > 0 && (
                <TeamOverviewPageDivider title={t('common.upcoming')} />
              )}
              {UPCOMING.sort((a, b) => {
                const dateSort = stringSort(a.startDate, b.startDate);
                if (dateSort === 0) {
                  return stringSort(a.name, b.name);
                }
                return dateSort;
              }).map((mig) => {
                return (
                  <MigCollapsibleCardWithActions
                    key={mig.id}
                    mig={mig}
                    teamInfo={teamInfo}
                    onToggleExpand={() =>
                      setExpanded((prev) => ({
                        ...prev,
                        [mig.id]: !prev[mig.id],
                      }))
                    }
                    isExpanded={expanded[mig.id]}
                    loading={loading}
                  />
                );
              })}
              {ENDED.length > 0 && (
                <TeamOverviewPageDivider title={t('common.ended')} />
              )}
              {ENDED.sort((a, b) => {
                const dateSort = stringSort(
                  a.lastOutcomeDate,
                  b.lastOutcomeDate,
                  'desc'
                );
                if (dateSort === 0) {
                  return stringSort(a.name, b.name);
                }
                return dateSort;
              }).map((mig) => {
                return (
                  <MigCollapsibleCardWithActions
                    key={mig.id}
                    mig={mig}
                    teamInfo={teamInfo}
                    onToggleExpand={() =>
                      setExpanded((prev) => ({
                        ...prev,
                        [mig.id]: !prev[mig.id],
                      }))
                    }
                    isExpanded={expanded[mig.id]}
                    loading={loading}
                  />
                );
              })}
            </div>
          </ToggleSection>
        </div>
      </div>{' '}
    </>
  );
};

MigSection.Skeleton = MigSectionSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIG_SECTION_MIG_FRAGMENT = gql`
  fragment MigSection_MigV2 on MigV2 {
    id
    startDate
    ...MigCollapsibleCardWithActions_MigV2
  }
`;
