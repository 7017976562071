import { Typography } from 'antd';
import { Colors } from '../../../../../componentLibrary/Colors';
import { useTranslation } from 'react-i18next';
interface Props {
  name: string;
  completed: boolean;
}

const truncate = (name: string) => {
  return name.length > 35 ? name.substring(0, 35) + '...' : name;
};

export const TeamInitiativesCardTitle = ({ name, completed }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="mb--s flx" title={name}>
      {completed && (
        <>
          <Typography.Text
            strong
            style={{ color: Colors.Status.OK_GREEN }}
            className="space--r"
          >
            {t('TeamInitiativesCardTitle.completed')}
          </Typography.Text>
          <Typography.Text strong className="space--r">
            -
          </Typography.Text>
        </>
      )}
      <Typography.Text strong>{truncate(name)}</Typography.Text>
    </div>
  );
};
