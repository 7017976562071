import { enforceString, Stringable } from './enforceString';

export const stringSort = (
  a: Stringable,
  b: Stringable,
  sortOrder: 'asc' | 'desc' = 'asc'
) => {
  const direction = sortOrder === 'desc' ? -1 : 1;
  const stringA = enforceString(a);
  const stringB = enforceString(b);

  return (
    stringA.localeCompare(stringB, undefined, {
      numeric: true,
      sensitivity: 'base',
    }) * direction
  );
};
