import { useTenantDetails } from '../../../../hooks/useTenantDetails';
import './labels.less';
import { LabelsTable } from './labels/LabelsTable';
import { TeamInformationList } from './TeamInformationList';
import { TeamInitiatives } from './TeamInitiatives';
interface Props {
  teamId: string;
}

export const OverviewPage = ({ teamId }: Props) => {
  const {
    features: { teamInitiativesEnabled: hasInitiative },
    loading,
  } = useTenantDetails();

  return (
    <div style={{ maxWidth: 1080 }}>
      <TeamInformationList teamId={teamId} />
      <LabelsTable teamId={teamId} />
      {!loading && hasInitiative && <TeamInitiatives teamId={teamId} />}
    </div>
  );
};
