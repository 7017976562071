import 'react-multi-carousel/lib/styles.css';
import './HighlightCarouselSection.less';
import {
  HighlightCarouselSection_MitemFragment,
  MitemStatus,
} from '../../../../generated/graphql';
import { Radio, Skeleton } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import { HighlightCarousel } from './HighlightCarouselSection/HighlightCarousel';

interface Props {
  sprintKeyActivities?: HighlightCarouselSection_MitemFragment[];
  onScrollToActivitiesProgress?: () => void;
  loading?: boolean;
}

export const HighlightCarouselSection = ({
  sprintKeyActivities,
  onScrollToActivitiesProgress,
  loading,
}: Props) => {
  const { t } = useTranslation();
  const [highlightFilterStatus, setHighlightFilterStatus] = useState<
    MitemStatus.COMPLETED | MitemStatus.OVERDUE | MitemStatus.PLANNED
  >(MitemStatus.COMPLETED);

  const highlights = sprintKeyActivities?.filter((ska) => ska.milestone) ?? [];
  const hasNoHighlights = !loading && highlights.length === 0;

  if (hasNoHighlights) {
    return (
      <div className="HighlightCarouselSection__container ">
        <h2
          className="mt--xl mb--l"
          data-intercom-target="Initiative overview title"
        >
          {t('HighlightCarouselSection.title')}
        </h2>
        <div className="flx flx--jc-center">
          <div className="HighlightCarouselSection__noHighlight flx--jc-space-between flx--ai-center flx">
            <div>
              <h3 className="mb">
                {t('HighlightCarouselSection.noMilestones.title')}
              </h3>
              <div> {t('HighlightCarouselSection.noMilestones.text')}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mb--xl">
      <div className="flx flx--jc-space-between flx--ai-center">
        <h2
          className="mt--xl mb--l"
          data-intercom-target="Initiative overview title"
        >
          {t('HighlightCarouselSection.title')}
        </h2>
        <Radio.Group
          value={highlightFilterStatus}
          onChange={(e) => setHighlightFilterStatus(e.target.value)}
          buttonStyle="outline"
        >
          <Radio.Button value={MitemStatus.COMPLETED}>
            {t('common.sprintKeyActivity.statuses.completed')}
          </Radio.Button>
          <Radio.Button value={MitemStatus.OVERDUE}>
            {t('common.sprintKeyActivity.statuses.overdue')}
          </Radio.Button>
          <Radio.Button value={MitemStatus.PLANNED}>
            {t('HighlightCarouselSection.upcoming')}
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className="HighlightCarouselSection__container">
        {loading ? (
          <div className="flx">
            <div className="HighlightCarouselSection__loadingCard mr--xl">
              <Skeleton active />
            </div>
            <div className="HighlightCarouselSection__loadingCard mr--xl">
              <Skeleton active />
            </div>
            <div className="HighlightCarouselSection__loadingCard">
              <Skeleton active />
            </div>
          </div>
        ) : (
          <HighlightCarousel
            highlights={highlights}
            filterStatus={highlightFilterStatus}
            onScrollToActivitiesProgress={onScrollToActivitiesProgress}
          />
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_HIGHLIGHT_CAROUSEL_SECTION_FRAGMENT = gql`
  fragment HighlightCarouselSection_Mitem on Mitem {
    id
    milestone
    ...HighlightCarousel_Mitem
  }
`;
