import Icon from '@ant-design/icons';

const RunIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 11 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.66667 14.3333V10.3333L5.26667 9L4.6 11.9333L0 11L0.266667 9.66667L3.46667 10.3333L4.53333 4.93333L3.33333 5.4V7.66667H2V4.53333L4.63333 3.4C5.02222 3.23333 5.30833 3.125 5.49167 3.075C5.675 3.025 5.84444 3 6 3C6.23333 3 6.45 3.06111 6.65 3.18333C6.85 3.30556 7.01111 3.46667 7.13333 3.66667L7.8 4.73333C8.08889 5.2 8.48056 5.58333 8.975 5.88333C9.46945 6.18333 10.0333 6.33333 10.6667 6.33333V7.66667C9.93333 7.66667 9.24722 7.51389 8.60833 7.20833C7.96944 6.90278 7.43333 6.5 7 6L6.6 8L8 9.33333V14.3333H6.66667ZM7 2.66667C6.63333 2.66667 6.31945 2.53611 6.05833 2.275C5.79722 2.01389 5.66667 1.7 5.66667 1.33333C5.66667 0.966667 5.79722 0.652778 6.05833 0.391667C6.31945 0.130556 6.63333 0 7 0C7.36667 0 7.68056 0.130556 7.94167 0.391667C8.20278 0.652778 8.33333 0.966667 8.33333 1.33333C8.33333 1.7 8.20278 2.01389 7.94167 2.275C7.68056 2.53611 7.36667 2.66667 7 2.66667Z" />
  </svg>
);

export const RunIcon = (props: any) => (
  <Icon component={RunIconSvg} {...props} />
);
