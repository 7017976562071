import { WarningTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { MigOutOfDateWarningMigFragment } from '../../generated/graphql';
import { getMigWarnings } from '../../services/getMigWarnings';
import cx from 'classnames';
import dayjs from 'dayjs';
import { gql } from '@apollo/client';

interface Props {
  mig: MigOutOfDateWarningMigFragment;
  iconClassName?: string;
}

export const MIG_OUT_OF_DATE_WARNING_FRAGMENT = gql`
  fragment MigOutOfDateWarningMig on MigV2 {
    id
    periodData {
      targetDate
      goal
      actual
    }
    timePeriodInterval
  }
`;

export const MigOutOfDateWarning = ({ mig, iconClassName }: Props) => {
  const { t } = useTranslation();
  const warnings = getMigWarnings(
    mig.periodData,
    mig.timePeriodInterval,
    dayjs()
  );

  if (warnings.length === 0) return null;

  return (
    <Tooltip
      placement="top"
      title={
        <div>
          {t('MigCard.missingData')}
          <div>
            {warnings.map((w) => (
              <div key={w}>{w}</div>
            ))}
          </div>
        </div>
      }
    >
      <WarningTwoTone
        twoToneColor="#ffbb33"
        className={cx('txt--warning', iconClassName)}
      />
    </Tooltip>
  );
};
