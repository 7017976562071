import { PerformanceTag } from '../../../../../../../../components/PerformanceTag';
import {
  PublishStatus,
  WkaHeaderContent_WeeklyKeyActivityFragment,
} from '../../../../../../../../generated/graphql';
import { useKeyActivityPerformance } from '../../../../../../../../hooks/useKeyActivityPerformanceQuery';
import { Period } from '../../../../../../../../services/GraphTickHelper';
import { PreviewGraph } from '../../../../../../../../components/PreviewGraph';
import { SubHeader } from '../../../../SubHeader';
import cx from 'classnames';
import './WkaHeaderContent.less';
import { gql } from '@apollo/client';

interface Props {
  wka: WkaHeaderContent_WeeklyKeyActivityFragment;
  periodData: Period[];
  expanded: boolean;
}

export const WkaHeaderContent = ({ wka, periodData, expanded }: Props) => {
  const { data: akpiTargetFulfillmentForTeamData } = useKeyActivityPerformance(
    wka.teamId,
    wka.id
  );

  return (
    <div className="flx flx--1 flx--ai-center">
      <div className="flx flx--1 flx--jc-space-between flx--column">
        <div className="flx flx--ai-center">
          {wka.status !== PublishStatus.UPCOMING && (
            <PerformanceTag
              value={akpiTargetFulfillmentForTeamData?.targetFulfillment}
              className="WkaHeaderContent__performanceValue mr--l"
              data-intercom-target="Performance value for WKA"
            />
          )}

          <h3>{wka.name}</h3>
        </div>
        <SubHeader lastOutcomeDate={wka.endDate} unit={wka.unit} />
      </div>

      {!expanded && (
        <div
          className={cx('flx flx--ai-center', {
            'MigHeaderContent--fadeIn': !expanded,
          })}
        >
          <PreviewGraph
            periodData={periodData}
            goalLegend={akpiTargetFulfillmentForTeamData?.targetValue}
            progressLegend={akpiTargetFulfillmentForTeamData?.actualValue}
            data-intercom-target="Preview graph for WKA"
          />
        </div>
      )}
      <div className="WkaHeaderContent__actions ml--xxl mr--xxl"></div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WKA_HEADER_CONTENT_FRAGMENT = gql`
  fragment WkaHeaderContent_WeeklyKeyActivity on WeeklyKeyActivity {
    id
    name
    endDate
    unit
    teamId
    status
    periodData {
      targetDate
      goal
      actual
    }
  }
`;
