import { useLazyQuery, gql } from '@apollo/client';
import {
  Action,
  TeamKeyActivityCommitmentAndAchievementsForPeriodDocument,
  TimePeriodFilter,
} from '../generated/graphql';

import { useEffect } from 'react';
import { useTeamPermissions } from '../usePermissions';

interface Props {
  teamId: string;
  timePeriodFilter?: TimePeriodFilter;
  weeklyKeyActivityId?: string;
  tenantId?: string;
}

export const useTeamCommitmentAndAchievementV2 = ({
  teamId,
  timePeriodFilter,
  weeklyKeyActivityId,
  tenantId,
}: Props) => {
  const { isAllowed, loading: permissionLoading } = useTeamPermissions({
    requestedAction: {
      resource: 'achievement',
      action: Action.READ,
    },

    teamId: teamId,
  });

  const [load, { data, error, loading }] = useLazyQuery(
    TeamKeyActivityCommitmentAndAchievementsForPeriodDocument,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (isAllowed) {
      load({
        variables: {
          teamId,
          weeklyKeyActivityId,
          timePeriodFilter,
          tenantId,
        },
      });
    }
  }, [
    isAllowed,
    load,
    teamId,
    weeklyKeyActivityId,
    timePeriodFilter,
    tenantId,
  ]);

  const commitmentsAndAchievements =
    data?.teamKeyActivityCommitmentAndAchievementsForPeriod;

  return {
    data: commitmentsAndAchievements,
    authorized: error ? error.message === 'unauthorized' : isAllowed,
    error,
    loading: permissionLoading || loading,
  };
};

export type TeamCommitmentAndAchievementsResponseV2 = ReturnType<
  typeof useTeamCommitmentAndAchievementV2
>;

export const TEAM_COMMITMENTS_AND_ACHIEVEMENTS = gql`
  query teamKeyActivityCommitmentAndAchievementsForPeriod(
    $teamId: ID!
    $timePeriodFilter: TimePeriodFilter
    $weeklyKeyActivityId: ID
    $tenantId: ID
  ) {
    teamKeyActivityCommitmentAndAchievementsForPeriod(
      teamId: $teamId
      timePeriodFilter: $timePeriodFilter
      weeklyKeyActivityId: $weeklyKeyActivityId
      tenantId: $tenantId
    ) {
      teamId
      startDate
      endDate
      commitmentsAndAchievements {
        ... on WeeklyKeyActivityCommitmentAndAchievement {
          id
          name
          type
          startDate
          endDate
          teamMembers {
            commitment
            achievement
            user {
              id
              name
              displayName
              email
            }
          }
        }
        ... on SprintKeyActivityCommitmentAndAchievement {
          id
          name
          type
          startDate
          endDate
          teamMembers {
            commitment
            achievement
            user {
              id
              name
              displayName
              email
            }
          }
        }
      }
    }
  }
`;
