import Icon from '@ant-design/icons';
import React from 'react';

const TasksSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="currentColor"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            d="M0 0H30V30H0z"
            transform="translate(-313.000000, -396.000000) translate(313.000000, 396.000000)"
          />
          <g fill="currentColor">
            <path
              d="M3.371 6.352l9.09 4.593 9.149-4.593-9.156-4.64-9.083 4.64zm9.088 6.303L0 6.359 12.452 0 25 6.359l-12.541 6.296z"
              transform="translate(-313.000000, -396.000000) translate(313.000000, 396.000000) translate(2.000000, 4.000000) translate(0.000000, 0.000000)"
            />
            <path
              d="M3.372 10.873l9.09 4.59 9.148-4.59-2.788-1.414-6.363 3.195-6.32-3.194-2.767 1.413zm9.087 6.3L0 10.88l6.135-3.133 6.326 3.197 6.364-3.196L25 10.88l-12.54 6.293z"
              transform="translate(-313.000000, -396.000000) translate(313.000000, 396.000000) translate(2.000000, 4.000000) translate(0.000000, 0.000000)"
            />
            <path
              d="M3.371 15.392l9.09 4.593 9.149-4.592-2.788-1.412-6.363 3.192-6.32-3.194-2.768 1.413zm9.088 6.303L0 15.399l6.134-3.132 6.326 3.197 6.364-3.193L25 15.4l-12.541 6.296z"
              transform="translate(-313.000000, -396.000000) translate(313.000000, 396.000000) translate(2.000000, 4.000000) translate(0.000000, 0.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const TasksIcon = (props: any) => (
  <Icon component={TasksSvg} {...props} />
);
