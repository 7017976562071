import { gql, useQuery } from '@apollo/client';
import {
  InitiativeNode,
  buildInitiativeTree,
} from '../appPages/company/initiatives_old/initiativesPageV1/services/initiativeTreeNodeHelper';
import { stringSort } from '../services/stringSort';
import React from 'react';
import { GetTenantInitiativesOldDocument } from '../generated/graphql';

export function findHierarchicalInitiativeById(
  hierarchicalInitiatives: InitiativeNode[],
  id: string
): InitiativeNode | null {
  for (let i = 0; i < hierarchicalInitiatives.length; i++) {
    const initiative = hierarchicalInitiatives[i];
    if (initiative.id === id) {
      return initiative;
    }
    if (initiative.children) {
      const result = findHierarchicalInitiativeById(initiative.children, id);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

export const useTenantInitiatives = (tenantId?: string) => {
  return useQuery(GetTenantInitiativesOldDocument, {
    variables: {
      tenantId,
    },
  });
};

export const useHierarchicalInitiatives = (
  tenantId?: string,
  includeArchived?: boolean
) => {
  const { data, loading, error } = useTenantInitiatives(tenantId);

  const activeInitiatives = data?.tenantInitiatives.allInitiatives;

  const activeStrategicInitiatives = data?.tenantInitiatives.globalInitiatives;

  const initiativesTreeData = React.useMemo(
    () => buildInitiativeTree(activeInitiatives ?? [], includeArchived),
    [activeInitiatives, includeArchived]
  );

  const strategicInitiativesIds = activeStrategicInitiatives?.map(
    (gi) => gi.id
  );

  const strategicInitiativeTree = initiativesTreeData
    .sort((a, b) => stringSort(a.tag.title, b.tag.title))
    .filter((i) => strategicInitiativesIds?.includes(i.id));

  const nonStrategicInitiativeTree = initiativesTreeData
    .sort((a, b) => stringSort(a.tag.title, b.tag.title))
    .filter((i) => !strategicInitiativesIds?.includes(i.id));

  return {
    loading,
    error,
    allInitiatives: activeInitiatives ?? [],
    hierarchicalInitiatives: initiativesTreeData ?? [],
    hierarchicalStrategicInitiatives: strategicInitiativeTree ?? [],
    hierarchicalTeamInitiativeTree: nonStrategicInitiativeTree ?? [],
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TENANT_INITIATIVES = gql`
  query getTenantInitiativesOld($tenantId: ID) {
    tenantInitiatives(tenantId: $tenantId) {
      allInitiatives {
        id
        domainId {
          teamId
          itemId
          tenantId
        }
        ...InitiativeContent
        archived
      }
      globalInitiatives {
        id
        domainId {
          teamId
          itemId
          tenantId
        }
        ...InitiativeContent
        archived
      }
    }
  }
`;
