import Icon from '@ant-design/icons';

const AirplaneIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.66683 13.6667V12.6667L6.00016 11.6667V8.00004L0.333496 9.66671V8.33337L6.00016 5.00004V1.33337C6.00016 1.0556 6.0975 0.819596 6.29216 0.625374C6.48638 0.430707 6.72238 0.333374 7.00016 0.333374C7.27794 0.333374 7.51394 0.430707 7.70816 0.625374C7.90283 0.819596 8.00016 1.0556 8.00016 1.33337V5.00004L13.6668 8.33337V9.66671L8.00016 8.00004V11.6667L9.3335 12.6667V13.6667L7.00016 13L4.66683 13.6667Z" />
  </svg>
);

export const AirplaneIcon = (props: any) => (
  <Icon component={AirplaneIconSvg} {...props} />
);
