import { Tooltip, Typography } from 'antd';
import { FC } from 'react';
import './MitemBoardColumn.less';
import { useTranslation } from 'react-i18next';
import { LockFilled, UnlockOutlined } from '@ant-design/icons';
import { Sprint } from '../../../../common/hooks/useSprintPlanningData';
import { DisplayDateRange } from '../../../../../../components/DisplayDateRange';
import cx from 'classnames';
interface Props {
  sprint: Sprint;
  columnIndex: number;
  hasMitems: boolean;
  children: React.ReactNode;
}

export const MitemBoardColumn: FC<Props> = ({
  children,
  sprint,
  columnIndex,
  hasMitems,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className="MitemBoardColumn"
      data-intercom-target={`Sprint Board Column Index ${columnIndex + 1}`}
    >
      <div
        className={cx('MitemBoardColumn__header ', {
          'MitemBoardColumn__header--active': sprint.isCurrent && sprint.locked,
          'MitemBoardColumn__header--planned': !sprint.locked && hasMitems,
        })}
      >
        <Typography.Title className="mb--none" level={5}>
          {sprint.isCurrent &&
            (sprint.locked ? (
              <Tooltip title={t('MitemBoardColumn.locked')}>
                <LockFilled />
              </Tooltip>
            ) : (
              <Tooltip title={t('MitemBoardColumn.unlocked')}>
                <UnlockOutlined />
              </Tooltip>
            ))}{' '}
          {sprint.isCurrent
            ? t('MitemBoardColumn.currentSprint') +
              ` (#${sprint.sprintNumber + 1})`
            : sprint.isNext
              ? t('MitemBoardColumn.nextSprint') +
                ` (#${sprint.sprintNumber + 1})`
              : t('MitemBoardColumn.sprint', {
                  sprintNumber: sprint.sprintNumber + 1,
                })}
        </Typography.Title>
        <Typography.Text type="secondary" className="ml--auto">
          <DisplayDateRange
            className="MitemBoardColumn__dateRange"
            startDate={sprint.startDate}
            endDate={sprint.endDate}
          />
        </Typography.Text>
      </div>
      <div>{children}</div>
    </div>
  );
};
