import { useEffect, useMemo } from 'react';
import {
  useGlobalPermissionsLazy,
  useTeamMemberPermissionsLazy,
  useTeamPermissionsLazy,
  useTenantPermissionsLazy,
  useUserPermissionsLazy,
} from './usePermissions';
import {
  Action,
  UsePermissions__PermissionTeamFragment,
  UsePermissions__PermissionTeamMemberFragment,
  UsePermissions__PermissionTenantFragment,
  UsePermissions__PermissionUserFragment,
} from './generated/graphql';
import { keysWithoutTypename } from './services/typeHelpers';

export type GlobalResource =
  keysWithoutTypename<UsePermissions__PermissionTeamFragment>;

export type TeamResource =
  keysWithoutTypename<UsePermissions__PermissionTeamFragment>;

export type UserResource =
  keysWithoutTypename<UsePermissions__PermissionUserFragment>;

export type TenantResource =
  keysWithoutTypename<UsePermissions__PermissionTenantFragment>;

export type TeamMemberResource =
  keysWithoutTypename<UsePermissions__PermissionTeamMemberFragment>;

type ResourceType =
  | GlobalResource
  | TenantResource
  | TeamResource
  | TeamMemberResource
  | UserResource;

type ResourceWithAction<
  T extends ResourceType,
  R = {
    resource: T;
    action: Action | Action[];
  },
> = R | R[];

interface GlobalResourceRequest {
  type: 'GlobalResource';
  requestedAction: ResourceWithAction<GlobalResource>;
  tenantId: string;
}

interface TenantResourceRequest {
  type: 'TenantResource';
  requestedAction: ResourceWithAction<TenantResource>;
  tenantId?: string;
}

export interface TeamResourceRequest {
  type: 'TeamResource';
  requestedAction: ResourceWithAction<TeamResource>;
  teamId: string;
  tenantId?: string;
}

interface UserResourceRequest {
  type: 'UserResource';
  requestedAction: ResourceWithAction<UserResource>;
  userId: string;
  tenantId?: string;
}

interface TeamMemberResourceRequest {
  type: 'TeamMemberResource';
  requestedAction: ResourceWithAction<TeamMemberResource>;
  userId: string;
  teamId: string;
  tenantId?: string;
}

type ResourceOwner =
  | GlobalResourceRequest
  | TenantResourceRequest
  | TeamResourceRequest
  | UserResourceRequest
  | TeamMemberResourceRequest;

type Props = {
  resourceOwner: ResourceOwner | ResourceOwner[];
  children: React.ReactNode;
};

export const PermissionChecker: React.FC<Props> = (props) => {
  const [fetchGlobalPermissions, { permissionsGlobal }] =
    useGlobalPermissionsLazy();
  const [fetchTenantPermissions, { permissionsTenant }] =
    useTenantPermissionsLazy();
  const [fetchTeamPermissions, { permissionsTeam }] = useTeamPermissionsLazy();
  const [fetchUserPermissions, { permissionsUser }] = useUserPermissionsLazy();
  const [fetchTeamMemberPermissions, { permissionsTeamMember }] =
    useTeamMemberPermissionsLazy();

  const resourceOwners = useMemo(
    () =>
      Array.isArray(props.resourceOwner)
        ? props.resourceOwner
        : [props.resourceOwner],
    [props.resourceOwner]
  );

  useEffect(() => {
    resourceOwners.forEach((resourcePermission) => {
      if (resourcePermission.type === 'GlobalResource') {
        fetchGlobalPermissions();
      }
      if (resourcePermission.type === 'TenantResource') {
        fetchTenantPermissions({
          variables: { tenantId: resourcePermission.tenantId },
        });
      }
      if (resourcePermission.type === 'TeamResource') {
        fetchTeamPermissions({
          variables: {
            tenantId: resourcePermission.tenantId,
            teamId: resourcePermission.teamId,
          },
        });
      }
      if (resourcePermission.type === 'UserResource') {
        fetchUserPermissions({
          variables: {
            tenantId: resourcePermission.tenantId,
            userId: resourcePermission.userId,
          },
        });
      }
      if (resourcePermission.type === 'TeamMemberResource') {
        fetchTeamMemberPermissions({
          variables: {
            tenantId: resourcePermission.tenantId,
            teamId: resourcePermission.teamId,
            userId: resourcePermission.userId,
          },
        });
      }
    });
  }, [
    resourceOwners,
    fetchGlobalPermissions,
    fetchTenantPermissions,
    fetchTeamPermissions,
    fetchUserPermissions,
    fetchTeamMemberPermissions,
  ]);

  const userPermissions = {
    ...permissionsTeamMember,
    ...permissionsUser,
    ...permissionsTeam,
    ...permissionsTenant,
    ...permissionsGlobal,
  };

  const resourceRequests = resourceOwners.flatMap(
    (resourceOwner) => resourceOwner.requestedAction
  );

  const isAllowed = resourceRequests.some((resourceRequest) => {
    const requestedActions = Array.isArray(resourceRequest.action)
      ? resourceRequest.action
      : [resourceRequest.action];

    return requestedActions.some((requestedAction) =>
      userPermissions?.[resourceRequest.resource]?.includes(requestedAction)
    );
  });

  return <>{isAllowed && props.children}</>;
};
