import Icon from '@ant-design/icons';

const WarningIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.333496 5.66671C0.333496 4.68893 0.530718 3.76393 0.925163 2.89171C1.31961 2.01949 1.8835 1.26671 2.61683 0.633374L3.56683 1.56671C2.96683 2.08893 2.50016 2.70282 2.16683 3.40837C1.8335 4.11393 1.66683 4.86671 1.66683 5.66671H0.333496ZM12.3335 5.66671C12.3335 4.86671 12.1668 4.11393 11.8335 3.40837C11.5002 2.70282 11.0335 2.08893 10.4335 1.56671L11.3835 0.633374C12.1168 1.26671 12.6807 2.01949 13.0752 2.89171C13.4696 3.76393 13.6668 4.68893 13.6668 5.66671H12.3335ZM1.66683 11.6667V10.3334H3.00016V5.66671C3.00016 4.74448 3.27794 3.92504 3.8335 3.20837C4.38905 2.49171 5.11127 2.02226 6.00016 1.80004V1.33337C6.00016 1.0556 6.09738 0.819485 6.29183 0.625041C6.48627 0.430596 6.72238 0.333374 7.00016 0.333374C7.27794 0.333374 7.51405 0.430596 7.7085 0.625041C7.90294 0.819485 8.00016 1.0556 8.00016 1.33337V1.80004C8.88905 2.02226 9.61127 2.49171 10.1668 3.20837C10.7224 3.92504 11.0002 4.74448 11.0002 5.66671V10.3334H12.3335V11.6667H1.66683ZM7.00016 13.6667C6.6335 13.6667 6.31961 13.5362 6.0585 13.275C5.79739 13.0139 5.66683 12.7 5.66683 12.3334H8.3335C8.3335 12.7 8.20294 13.0139 7.94183 13.275C7.68072 13.5362 7.36683 13.6667 7.00016 13.6667ZM4.3335 10.3334H9.66683V5.66671C9.66683 4.93337 9.40572 4.3056 8.8835 3.78337C8.36127 3.26115 7.7335 3.00004 7.00016 3.00004C6.26683 3.00004 5.63905 3.26115 5.11683 3.78337C4.59461 4.3056 4.3335 4.93337 4.3335 5.66671V10.3334Z" />
  </svg>
);

export const WarningIcon = (props: any) => (
  <Icon component={WarningIconSvg} {...props} />
);
