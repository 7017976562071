import { InfoCircleOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '../../../Auth0Provider';
import { ErrorAlert } from '../../../components/ErrorAlert';
import { SectionHeader } from '../../../components/SectionHeader';
import { useTeamMembers, TeamMember } from '../../../hooks/useTeamMembersV2';
import { stringSort } from '../../../services/stringSort';
import { AssignTeamMemberRoleModal } from './AssignTeamMemberRoleModal';
import { AssignUserSearch } from './AssignUserSearch';
import { UnassignUserFromTeamModalWithConfirmButton } from './UnassignUserFromTeamModalWithConfirmButton';
import { UserAvatar } from '../../../components/UserAvatar';
import { friendlyUsername } from '../../../services/friendlyUsername';
import { Btn } from '../../../components/Button';
import { useTeamPermissions } from '../../../usePermissions';
import { Action } from '../../../generated/graphql';

interface Props {
  teamId: string;
  tenantId?: string;
}

interface ColumnPropsWithExclude<T> extends ColumnProps<T> {
  excluded?: boolean;
}

export const TeamMemberTable = ({ teamId, tenantId }: Props) => {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const { isAllowed } = useTeamPermissions({
    requestedAction: [
      {
        resource: 'team',
        action: Action.UPDATE,
      },
    ],
    teamId: teamId,
  });

  const [currentMemberId, setCurrentMemberId] = useState<string | null>(null);
  const { data, error, loading } = useTeamMembers(teamId, tenantId);
  const columns: ColumnPropsWithExclude<TeamMember>[] = [
    {
      title: t('common.name'),

      dataIndex: ['user', 'name'],
      defaultSortOrder: 'ascend',
      sorter: (a, b) => stringSort(a.user.name, b.user.name),
      key: 'name',
      render: (_, record) => {
        return (
          <div>
            <UserAvatar user={record.user} />
            <span className="ml--l">{friendlyUsername(record.user)}</span>
          </div>
        );
      },
    },
    {
      title: t('common.email'),

      dataIndex: ['user', 'email'],
      defaultSortOrder: 'ascend',
      sorter: (a, b) => stringSort(a.user.email, b.user.email),
      key: 'email',
    },
    {
      title: t('TeamMemberTable.teamRole'),
      key: 'teamAssociations',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        const roleA = a.sprintAdmin ? 'SPRINT_ADMIN' : a.teamRole;
        const roleB = b.sprintAdmin ? 'SPRINT_ADMIN' : b?.teamRole;

        return stringSort(roleA, roleB);
      },
      render: (_, record) => {
        const role = record.sprintAdmin ? 'SPRINT_ADMIN' : record.teamRole;

        return t(`common.teamRole.${role.toLowerCase()}`);
      },
    },
    {
      title: t('common.actions'),
      key: 'actions',
      width: '25%',
      excluded: !isAllowed,
      render: (_, member) => {
        if (user?.howwe.userId === member.user.id)
          return (
            <Tooltip
              title={
                <div className="flx flx--column">
                  {t('TeamMemberTable.manageTeamInfo')}
                </div>
              }
            >
              <div className="flx flx--jc-center flx--ai-center">
                <InfoCircleOutlined className="TeamSection__InfoIcon" />
                <span className="ml--s">
                  {t('TeamMemberTable.noActionsPossible')}
                </span>
              </div>
            </Tooltip>
          );
        return (
          <>
            <Btn type="text" onClick={() => setCurrentMemberId(member.user.id)}>
              {t('TeamMemberTable.changeRole.buttonText')}
            </Btn>

            <UnassignUserFromTeamModalWithConfirmButton
              member={member}
              tenantId={tenantId}
            />
            <AssignTeamMemberRoleModal
              teamId={member.teamId}
              tenantId={tenantId}
              teamRole={member.teamRole}
              sprintAdmin={member.sprintAdmin}
              user={member.user}
              visible={currentMemberId === member.user.id}
              hideModal={() => setCurrentMemberId(null)}
            />
          </>
        );
      },
    },
  ];

  return (
    <SectionHeader
      title={t('TeamMemberTable.title')}
      extra={
        isAllowed && (
          <AssignUserSearch
            teamId={teamId}
            tenantId={tenantId}
            members={data?.membersV2.members}
          />
        )
      }
    >
      <ErrorAlert error={error} title={t('TeamMemberTable.fetchMemberError')} />

      <Table
        bordered={false}
        size="middle"
        loading={loading}
        className="showScrollBar"
        dataSource={data?.membersV2.members ?? []}
        columns={columns.filter((column) => !column.excluded)}
        pagination={false}
        rowKey={(item) => item.user.id}
        rowClassName={() => 'border-none'}
      />
    </SectionHeader>
  );
};
