import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { GetTeamWeeklyKeyActivityDocument } from '../../../../generated/graphql';
import { weeklyKeyActivityDTOtoAkpiData } from '../../../../services/akpiUtils';

interface Props {
  teamId: string;
  tenantId?: string;
  keyActivityId: string;
}

export const useWeeklyKeyActivityLazy = () => {
  const [fetchWeeklyKeyActivity, { loading, data, error }] = useLazyQuery(
    GetTeamWeeklyKeyActivityDocument
  );

  const weeklyKeyActivity =
    data?.weeklyKeyActivity &&
    weeklyKeyActivityDTOtoAkpiData(data?.weeklyKeyActivity);

  return {
    fetchWeeklyKeyActivity,
    weeklyKeyActivity,
    error,
    loading,
  };
};

export const useWeeklyKeyActivity = ({
  teamId,
  keyActivityId,
  tenantId,
}: Props) => {
  const { loading, data, error } = useQuery(GetTeamWeeklyKeyActivityDocument, {
    variables: { teamId: teamId, weeklyKeyActivityId: keyActivityId, tenantId },
  });

  const keyActivity =
    data?.weeklyKeyActivity &&
    weeklyKeyActivityDTOtoAkpiData(data?.weeklyKeyActivity);

  return {
    keyActivity,
    error,
    loading,
  };
};

export const GET_TEAM_WEEKLY_KEY_ACTIVITY = gql`
  query getTeamWeeklyKeyActivity(
    $teamId: ID!
    $weeklyKeyActivityId: ID!
    $tenantId: ID
  ) {
    weeklyKeyActivity(
      teamId: $teamId
      weeklyKeyActivityId: $weeklyKeyActivityId
      tenantId: $tenantId
    ) {
      id
      name
      description
      archivedAt
      acceptanceCriteria
      achievementDescriptionMandatory
      commitmentStrategyEnabled
      currentPeriodIndex
      startDate
      endDate
      unit
      status
      periodData {
        targetDate
        goal
        actual
        historic
      }
      supportsMigsDetails {
        id
        name
        lastOutcomeDate
        status
        team {
          tenantId
          id
          name
        }
        domainId {
          itemId
          teamId
          tenantId
        }
      }
      ignoreListedUsers
      teamId
    }
  }
`;
