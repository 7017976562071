import { useEffect } from 'react';
import { useAuth0 } from '../Auth0Provider';
import { ga4 } from '../services/ga4/ga4';
import { useLocation } from 'react-router-dom';
import { debounce } from '../services/debounce';

export function useGa4(gaID: string | undefined) {
  const location = useLocation();
  const { user } = useAuth0();

  useEffect(() => {
    /* Reminder:
      `NODE_ENV === 'production'` doesn't mean the Production environment,
      it means any environment except local development, e.g. integration, qa, production
    */
    const isLocalDevelopment = import.meta.env.MODE !== 'production';
    const analyticsEnabled = !isLocalDevelopment && gaID;
    const analyticsId = isLocalDevelopment ? 'DEV_MOCK_ANALYTICS_ID' : gaID;
    ga4.initialize(analyticsId!, {
      testMode: !analyticsEnabled,
      gtagOptions: { send_page_view: false },
    });
  }, [gaID]);

  useEffect(() => {
    if (user) {
      ga4.gtag('set', 'user_id', user.howwe.userId);
      ga4.gtag('set', 'user_properties', {
        tenant_id: user.howwe.tenantId,
        user_role: user.howwe.role,
      });
    }
  }, [user]);

  useEffect(() => {
    // wait until user is loaded before logging location
    if (user) {
      /* debounce to avoid spamming GA with page views for redirects, e.g.
          clicking on 'My team' opens /team/<id> and then redirects to /team/<id>/overview
          resulting in two page views being logged, one for /team/<id> and one for /team/<id>/overview.
          With this debounce, only the last page view within a 500ms window will be logged.
        */
      setGA4Location(location.pathname);
    }
  }, [location, user]);
}

const setGA4Location = debounce((pathName: string) => {
  const page_location = getFullUrl(pathName);
  ga4.gtag('event', 'page_view', {
    page_location,
  });
});

const GUID_REGEX =
  /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/gi;

const getFullUrl = (pathName: string) => {
  const { origin } = window.location;
  const pathWithoutUrls = pathName.replaceAll(GUID_REGEX, '<id>');

  return origin + pathWithoutUrls;
};
