import Icon, {
  IconComponentProps,
} from '@ant-design/icons/lib/components/Icon';

const PersonShieldSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M10 10.835c-.82 0-1.51-.282-2.073-.844-.563-.563-.844-1.254-.844-2.073 0-.82.281-1.51.844-2.073.562-.562 1.253-.844 2.073-.844.82 0 1.51.282 2.073.844.562.563.843 1.254.843 2.073 0 .82-.28 1.51-.843 2.073-.563.562-1.254.844-2.073.844Zm0-1.667c.36 0 .66-.118.896-.354s.354-.535.354-.896-.118-.66-.354-.896A1.214 1.214 0 0 0 10 6.668c-.361 0-.66.118-.896.354a1.214 1.214 0 0 0-.354.896c0 .361.118.66.354.896s.535.354.896.354Zm0 9.167c-1.93-.486-3.525-1.594-4.782-3.323-1.257-1.73-1.885-3.65-1.885-5.76V4.167L10 1.668l6.666 2.5v5.083c0 2.111-.628 4.032-1.885 5.76-1.257 1.73-2.85 2.838-4.781 3.324Zm0-14.896L5 5.314V9.25c0 .75.104 1.48.312 2.188.209.708.493 1.375.854 2A9.384 9.384 0 0 1 8 12.75a7.89 7.89 0 0 1 2-.25 7.89 7.89 0 0 1 2 .25c.639.167 1.25.396 1.833.688a9.137 9.137 0 0 0 .854-2A7.713 7.713 0 0 0 15 9.25V5.314l-5-1.875Zm0 10.729c-.5 0-.986.056-1.459.167a7.394 7.394 0 0 0-1.354.458c.403.417.84.778 1.313 1.083.472.306.972.542 1.5.709a6.166 6.166 0 0 0 1.5-.709c.472-.305.91-.666 1.312-1.083a7.396 7.396 0 0 0-1.354-.458A6.346 6.346 0 0 0 10 14.168Z" />
  </svg>
);

export const PersonShieldIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={PersonShieldSvg} {...props} />
);
