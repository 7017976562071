import Icon from '@ant-design/icons';

const ForkRightIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 12V2.53333L0.933333 3.6L0 2.66667L2.66667 0L5.33333 2.66667L4.4 3.6L3.33333 2.53333V6.9C3.72222 6.55556 4.16111 6.30833 4.65 6.15833C5.13889 6.00833 5.62778 5.93333 6.11667 5.93333C6.23889 5.93333 6.35833 5.93889 6.475 5.95C6.59167 5.96111 6.7 5.97778 6.8 6L5.73333 4.93333L6.66667 4L9.33333 6.66667L6.66667 9.33333L5.73333 8.4L6.8 7.33333C6.67778 7.31111 6.55278 7.29167 6.425 7.275C6.29722 7.25833 6.17222 7.25 6.05 7.25C5.45 7.25 4.89722 7.41944 4.39167 7.75833C3.88611 8.09722 3.53333 8.62222 3.33333 9.33333V12H2Z" />
  </svg>
);

export const ForkRightIcon = (props: any) => (
  <Icon component={ForkRightIconSvg} {...props} />
);
