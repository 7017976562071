import { gql } from '@apollo/client';
import { SkaGraph } from './skaSection/components/SkaGraph';
import { SimpleSkaSection_MitemTermFragment } from '../../../../../../generated/graphql';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  teamId: string;
  sprintTerm: SimpleSkaSection_MitemTermFragment;
}

export const SimpleSkaSection = ({ teamId, sprintTerm }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="mb--xl">{t('SimpleSkaSection.header')}</h2>
      <Alert
        message={t('SimpleSkaSection.permissionAlertMessage')}
        showIcon
        className="mb--l"
      />
      <SkaGraph
        teamId={teamId}
        selectedSprint={null}
        currentSprintTerm={sprintTerm}
        width={1000}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SIMPLE_SKA_SECTION_SKA_TERM_FRAGMENT = gql`
  fragment SimpleSkaSection_MitemTerm on MitemTerm {
    id
    ...SkaGraph_MitemTerm
  }
`;
