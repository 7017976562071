import { gql, useMutation } from '@apollo/client';
import { Alert, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CreateSprintTermDocument,
  GetSprintsForPlanningDocument,
  GetTeamKeyActivitiesPreviewDocument,
} from '../../../generated/graphql';
import { showNotification } from '../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../services/howweErrorParser';

import { SprintTermForm } from './components/SprintTermForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  teamId: string;
  tenantId?: string;
}

export const CreateSprintTermModal = ({
  isOpen,
  onClose,
  teamId,
  tenantId,
}: Props) => {
  const { t } = useTranslation();
  const [createSprintTerm, { error, loading }] = useMutation(
    CreateSprintTermDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: (
            <strong>{t('CreateSprintTermModal.createdSuccessfully')}</strong>
          ),
          description: t('CreateSprintTermModal.description'),
          duration: 8,
        });

        onClose();
      },
      refetchQueries: () => [
        {
          query: GetTeamKeyActivitiesPreviewDocument,
          variables: { teamId, tenantId },
        },
        {
          query: GetSprintsForPlanningDocument,
          variables: { teamId, includeUpcoming: true },
        },
      ],
    }
  );

  const handleSubmit = (
    startDate: string,
    endDate: string,
    sprintInterval: number
  ) => {
    // Publish dates are same as start / end dates. In the future publish-dates will be deprecated
    createSprintTerm({
      variables: {
        teamId,
        tenantId,
        term: {
          startDate: startDate,
          endDate: endDate,
          sprintInterval: sprintInterval,
          publishDate: startDate,
          unpublishDate: endDate,
        },
      },
    });
  };

  const howweErrors = howweErrorParser(error);

  return (
    <Modal
      title={t('CreateSprintTermModal.title')}
      footer={false}
      keyboard={false}
      onCancel={onClose}
      confirmLoading={loading}
      open={isOpen}
      destroyOnClose // - this does not work when working with children using ant form
    >
      <SprintTermForm
        teamId={teamId}
        tenantId={tenantId}
        handleSave={handleSubmit}
        onClose={onClose}
      />
      {howweErrors && (
        <Alert
          type="error"
          className="mt"
          message={t('CreateSprintTermModal.error')}
          description={
            <ul>
              {howweErrors.map((e, i) => (
                <li key={i}>{e.translation}</li>
              ))}
            </ul>
          }
        />
      )}
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_SPRINT_TERM = gql`
  mutation createSprintTerm(
    $teamId: ID!
    $term: MitemTermInput!
    $tenantId: ID
  ) {
    createMitemTerm(teamId: $teamId, term: $term, tenantId: $tenantId) {
      id
      sprintInterval
      startDate
      endDate
      publishDate
      unpublishDate
    }
  }
`;
