import {
  CalendarFilled,
  CheckCircleFilled,
  StopFilled,
} from '@ant-design/icons';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { mitemStatusColor } from '../../../../../../../../../styleVars';

export const MitemActionsLegend = () => {
  const { t } = useTranslation();
  return (
    <div className="flx flx--column">
      <div className="mb">
        <div style={{ color: mitemStatusColor.COMPLETED }}>
          <CheckCircleFilled className="mr" />
          <Typography.Text type="secondary">
            {t('MitemActions.Completed')}
          </Typography.Text>
        </div>
      </div>
      <div className="mb">
        <div style={{ color: mitemStatusColor.PLANNED }}>
          <CalendarFilled className="mr" />
          <Typography.Text type="secondary">
            {t('MitemActions.Reschedule')}
          </Typography.Text>
        </div>
      </div>
      <div className="mb">
        <div style={{ color: mitemStatusColor.NOT_COMPLETED }}>
          <StopFilled className="mr" />
          <Typography.Text type="secondary">
            {t('MitemActions.Stop')}
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};
