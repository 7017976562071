import { LinkOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TitleWithTooltip } from '../../../../appPages/team/setup/components/TitleWithTooltip';
import { CurveEditorPeriodAction } from '../../keyActivityPage/components/WeeklyKeyActivity/curveEditor/CurveEditorPeriodAction';
import { standardDateFormat } from '../../../../services/dateFormats';
import {
  BaseMigPeriod,
  getMigInitialDate,
  correctTargetDates,
  interpolateCurve,
} from '../../../../services/migUtils';
import { stringSort } from '../../../../services/stringSort';
import { NumberInput } from '../../../NumberInput';
import { InterpolationHelper } from './migCurveEditor/InterpolationHelper';
import { MigEditableData } from '../MigSetupModalContent';
import { DisplayDate } from '../../../DisplayDate';

interface MigCureData extends MigEditableData {
  firstOutcomeDate: string;
  lastOutcomeDate: string;
}

interface Props {
  data: MigCureData;
  setActiveDot: (periodIndex: number | null) => void;
  periodData: BaseMigPeriod[];
  setPeriodData: (line: BaseMigPeriod[]) => void;
}

export type interpolationPeriod = {
  targetDate: string;
  value: number | null;
  selected: boolean;
};

export const MigCurveEditor = ({
  data,
  periodData,
  setPeriodData,
  setActiveDot,
}: Props) => {
  const { t } = useTranslation();
  const { firstOutcomeDate, lastOutcomeDate } = data;
  const [interpolationKey, setInterpolationKey] = useState<'goal' | 'historic'>(
    'goal'
  );

  const fullInterpolationRange = useMemo(() => {
    return [
      {
        targetDate: standardDateFormat(
          getMigInitialDate[data.timePeriodInterval](firstOutcomeDate)
        ),
        value: null,
        selected: false,
      },
      {
        targetDate: correctTargetDates(
          data.timePeriodInterval,
          standardDateFormat(lastOutcomeDate)
        ),
        value: null,
        selected: false,
      },
    ] as [interpolationPeriod, interpolationPeriod];
  }, [firstOutcomeDate, lastOutcomeDate, data.timePeriodInterval]);

  const [selectedFields, setSelectedFields] = useState<
    [interpolationPeriod, interpolationPeriod]
  >(fullInterpolationRange);

  const [integersOnly, setIntegersOnly] = useState(false);

  useEffect(() => {
    setSelectedFields(fullInterpolationRange);
  }, [fullInterpolationRange]);

  if (!data) {
    return <p>Awaiting data</p>;
  }

  const interpolatePartOfCurve = () => {
    const [startIndex, endIndex] = selectedFields.map((s) =>
      periodData.findIndex((p) => p.targetDate === s.targetDate)
    );

    const numberOfValueToInterpolate = endIndex - startIndex + 1;

    if (selectedFields[0].value == null || selectedFields[1].value == null)
      return;

    const interpolatedGoals = interpolateCurve(
      selectedFields[0].value,
      selectedFields[1].value,
      numberOfValueToInterpolate,
      integersOnly
    );

    const newPeriod = periodData.map((period, index) => {
      if (index >= startIndex && index <= endIndex) {
        return {
          ...period,
          [interpolationKey]: interpolatedGoals[index - startIndex],
        };
      }

      return period;
    });

    setPeriodData(newPeriod);
  };

  const handlePeriodValueChange = (
    period: BaseMigPeriod,
    value: string | number | null,
    type: 'goal' | 'actual' | 'historic'
  ) => {
    const periodToMutate = periodData.find(
      (p) => p.targetDate === period.targetDate
    );
    if (periodToMutate) {
      periodToMutate[type] = typeof value === 'string' ? Number(value) : value;
    }
    setPeriodData([...periodData]);
  };

  const handleLinkClick = (
    e: React.MouseEvent<HTMLElement>,
    p: BaseMigPeriod
  ) => {
    e.stopPropagation();
    if (
      selectedFields.some((s) => s.targetDate === p.targetDate && s.selected)
    ) {
      return setSelectedFields(fullInterpolationRange);
    }

    const selectedPeriodValue =
      periodData.find((ld) => ld.targetDate === p.targetDate)?.[
        interpolationKey
      ] ?? null;

    const newState: [interpolationPeriod, interpolationPeriod] =
      e.shiftKey && selectedFields[0].selected
        ? [
            selectedFields[0],
            {
              targetDate: p.targetDate,
              value: selectedPeriodValue,
              selected: true,
            },
          ]
        : [
            {
              targetDate: p.targetDate,
              value: selectedPeriodValue,
              selected: true,
            },
            selectedFields[1],
          ];

    setSelectedFields(
      newState.sort((a, b) => stringSort(a?.targetDate, b?.targetDate))
    );
  };

  const goalColumns: ColumnProps<BaseMigPeriod>[] = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <TitleWithTooltip
            tooltipContent={
              <Trans i18nKey="KeyActivityLineEditor.linkHelper">
                Click <LinkOutlined /> to mark the first row, then shift + click
                another <LinkOutlined /> to select all the rows between them
              </Trans>
            }
            title={<LinkOutlined />}
            tooltipPlacement="topRight"
          />
        </div>
      ),
      key: 'select',
      width: 48,
      render: (_, p) => {
        return (
          <CurveEditorPeriodAction
            onClick={(e) => handleLinkClick(e, p)}
            toggled={selectedFields.some(
              (sf) => sf.targetDate === p.targetDate && sf.selected
            )}
            icon={<LinkOutlined />}
          />
        );
      },
    },
    {
      title: t('MigCurveEditor.targetDate'),
      dataIndex: 'targetDate',
      key: 'targetDateGoal',
      render: (targetDate) => <DisplayDate date={targetDate} />,
    },
    {
      title: t('MigCurveEditor.goal'),
      key: 'goalValue',
      render: (_, period) => (
        <NumberInput
          id={period.targetDate}
          value={period.goal ?? undefined}
          onFocus={() =>
            setActiveDot(
              // todo: this should actually send targetDate but we need to update shared packages first
              periodData.findIndex((p) => p.targetDate === period.targetDate)
            )
          }
          onBlur={() => setActiveDot(null)}
          onChange={(value) =>
            handlePeriodValueChange(period, value ?? null, 'goal')
          }
        />
      ),
    },
  ];

  const actualColumns: ColumnProps<BaseMigPeriod>[] = [
    {
      title: t('MigCurveEditor.targetDate'),
      dataIndex: 'targetDate',
      key: 'targetDate',
      render: (targetDate) => <DisplayDate date={targetDate} />,
    },
    {
      title: t('MigCurveEditor.actual'),
      key: 'actualValue',
      render: (_, period) => (
        <NumberInput
          id={period.targetDate}
          value={period.actual ?? undefined}
          onFocus={() =>
            setActiveDot(
              // todo: this should actually send targetDate but we need to update shared packages first
              periodData.findIndex((p) => p.targetDate === period.targetDate)
            )
          }
          onBlur={() => setActiveDot(null)}
          onChange={(value) =>
            handlePeriodValueChange(period, value ?? null, 'actual')
          }
        />
      ),
    },
  ];

  const additionalColumns: ColumnProps<BaseMigPeriod>[] = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <TitleWithTooltip
            tooltipContent={
              <Trans i18nKey="KeyActivityLineEditor.linkHelper">
                Click <LinkOutlined /> to mark the first row, then shift + click
                another <LinkOutlined /> to select all the rows between them
              </Trans>
            }
            title={<LinkOutlined />}
            tooltipPlacement="topRight"
          />
        </div>
      ),
      key: 'select',
      width: 48,
      render: (_, p) => {
        return (
          <CurveEditorPeriodAction
            onClick={(e) => handleLinkClick(e, p)}
            toggled={selectedFields.some(
              (sf) => sf.targetDate === p.targetDate && sf.selected
            )}
            icon={<LinkOutlined />}
          />
        );
      },
    },
    {
      title: t('MigCurveEditor.targetDate'),
      dataIndex: 'targetDate',
      key: 'targetDate',
      render: (targetDate) => <DisplayDate date={targetDate} />,
    },
    {
      title: t('MigCurveEditor.historic'),
      key: 'historic',
      render: (_, period) => (
        <NumberInput
          value={period.historic ?? undefined}
          onFocus={() =>
            setActiveDot(
              // todo: this should actually send targetDate but we need to update shared packages first
              periodData.findIndex((p) => p.targetDate === period.targetDate)
            )
          }
          onBlur={() => setActiveDot(null)}
          onChange={(value) =>
            handlePeriodValueChange(period, value ?? null, 'historic')
          }
        />
      ),
    },
  ];

  return (
    <Tabs
      type="card"
      onChange={(key) => {
        if (key === 'goal' || key === 'historic') {
          setSelectedFields(fullInterpolationRange);
          setInterpolationKey(key);
        }
      }}
    >
      <Tabs.TabPane tab={t('MigCurveEditor.goalTab')} key="goal">
        <div
          className="mb--l"
          style={{
            display: 'flex',
          }}
        >
          <InterpolationHelper
            setSelectedFields={setSelectedFields}
            interpolationFunction={interpolatePartOfCurve}
            periodData={periodData}
            selectedFields={selectedFields}
            integersOnly={integersOnly}
            setIntegersOnly={setIntegersOnly}
          />
        </div>
        <Table
          dataSource={periodData}
          columns={goalColumns}
          pagination={false}
          scroll={{ y: '50vh' as unknown as number }} // ugly, but it works (for now..), if the table is higher than 50vh, it scrolls
          rowClassName={(p) => selectClass(p, selectedFields)}
          rowKey="targetDate"
          size="small"
        ></Table>
      </Tabs.TabPane>

      <Tabs.TabPane tab={t('MigCurveEditor.historicTab')} key="historic">
        <div
          className="mb--l"
          style={{
            display: 'flex',
          }}
        >
          <InterpolationHelper
            setSelectedFields={setSelectedFields}
            interpolationFunction={interpolatePartOfCurve}
            periodData={periodData}
            selectedFields={selectedFields}
            integersOnly={integersOnly}
            setIntegersOnly={setIntegersOnly}
          />
        </div>
        <Table
          dataSource={periodData}
          columns={additionalColumns}
          pagination={false}
          scroll={{ y: '50vh' as unknown as number }} // ugly, but it works (for now..), if the table is higher than 50vh, it scrolls
          rowClassName={(p) => selectClass(p, selectedFields)}
          rowKey="targetDate"
          size="small"
        ></Table>
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('MigCurveEditor.actualTab')} key="actual">
        <Table
          dataSource={periodData}
          columns={actualColumns}
          pagination={false}
          scroll={{ y: '50vh' as unknown as number }} // ugly, but it works (for now..), if the table is higher than 50vh, it scrolls
          rowKey="targetDate"
          size="small"
        ></Table>
      </Tabs.TabPane>
    </Tabs>
  );
};

function selectClass(
  p: BaseMigPeriod,
  selectedFields: [interpolationPeriod, interpolationPeriod]
) {
  if (
    selectedFields.some((sf) => sf.targetDate === p.targetDate && sf.selected)
  ) {
    return 'selected';
  }

  const isBetweenSelected = moment(p.targetDate).isBetween(
    selectedFields[0].targetDate,
    selectedFields[1].targetDate
  );

  return isBetweenSelected && selectedFields.every((sf) => sf.selected)
    ? 'selected--inRange'
    : '';
}
