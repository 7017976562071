import { Switch } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isDefined } from '../../../../../../../../../services/isDefined';
import { TeamContributingMemberFilter } from '../common/TeamContributingMemberFilter';
import { UserContributionData } from '../hooks/usePreviousPeriodSummary';
import { WeeklyKeyActivityFilter } from './WeeklyKeyActivityProgressLog/WeeklyKeyActivityFilter';
import { WeeklyKeyActivityUserSection } from './WeeklyKeyActivityProgressLog/WeeklyKeyActivityUserSection';

interface Props {
  teamId: string;
  tenantId?: string;
  userContributionData?: UserContributionData[] | null;
}

const filterWeeklyKeyActivities = (
  weeklyKeyActivities: UserContributionData['weeklyKeyActivities'],
  filter: string | 'ALL_KEY_ACTIVITIES',
  showOnlyNotCompletedKeyActivities: boolean
) => {
  let filteredKeyActivities;
  filteredKeyActivities =
    filter !== 'ALL_KEY_ACTIVITIES'
      ? weeklyKeyActivities.filter((wka) => wka.id === filter)
      : weeklyKeyActivities;

  if (showOnlyNotCompletedKeyActivities) {
    filteredKeyActivities = filteredKeyActivities?.filter((wka) => {
      const committed = wka.quantity ?? 0;
      const achieved = wka.achievements.reduce(
        (partialSum, a) => partialSum + (a.quantity ?? 0),
        0
      );
      return achieved < committed;
    });
  }

  return filteredKeyActivities ?? [];
};

export const WeeklyKeyActivityProgressLog = ({
  userContributionData,
  teamId,
  tenantId,
}: Props) => {
  const { t } = useTranslation();
  const [filteredTeamMemberId, setFilteredTeamMemberId] = useState<
    string | 'ALL_MEMEMBERS'
  >('ALL_MEMEMBERS');
  const [
    showOnlyNotCompletedKeyActivities,
    setShowOnlyNotCompletedKeyActivities,
  ] = useState(false);
  const [expandAllReports, setExpandAllReports] = useState(false);

  const [filteredKeyActivity, setFilteredKeyActivity] = useState<
    string | 'ALL_KEY_ACTIVITIES'
  >('ALL_KEY_ACTIVITIES');

  const filteredUsers =
    filteredTeamMemberId !== 'ALL_MEMEMBERS'
      ? userContributionData?.filter((u) => u.user.id === filteredTeamMemberId)
      : userContributionData;

  const wkaUserSection = filteredUsers
    ?.map((userContributions) => {
      const filteredWeeklyKeyActivities = filterWeeklyKeyActivities(
        userContributions.weeklyKeyActivities,
        filteredKeyActivity,
        showOnlyNotCompletedKeyActivities
      );

      return { userContributions, wkas: filteredWeeklyKeyActivities };
    })
    .filter((section) => section.wkas.length > 0)
    .map((section) => (
      <div
        key={section.userContributions.user.id}
        className="borderBottom pb mt--l mb"
      >
        <WeeklyKeyActivityUserSection
          user={section.userContributions.user}
          filteredWeeklyKeyActivities={section.wkas}
          expandReports={expandAllReports}
        />
      </div>
    ));

  return (
    <div>
      <h3>{t('WeeklyKeyActivityProgressLog.title')}</h3>
      <div className="borderBottom flx pb--s">
        <TeamContributingMemberFilter
          contributingMembers={userContributionData
            ?.filter((cm) => cm.weeklyKeyActivities.length > 0)
            .map((u) => u.user)}
          setFileredTeamMember={setFilteredTeamMemberId}
          teamId={teamId}
          tenantId={tenantId}
          className={'mr'}
        />
        <WeeklyKeyActivityFilter
          userContributionData={userContributionData}
          setFilteredKeyActivity={setFilteredKeyActivity}
          className={'mr'}
        />
        <div
          className="flx flx--ai-center mr clickable"
          onClick={() =>
            setShowOnlyNotCompletedKeyActivities(
              !showOnlyNotCompletedKeyActivities
            )
          }
        >
          <Switch className="mr" checked={showOnlyNotCompletedKeyActivities} />
          {t('WeeklyKeyActivityProgressLog.showOnlyIncomplete')}
        </div>
        <div
          className="flx flx--ai-center clickable"
          onClick={() => setExpandAllReports(!expandAllReports)}
        >
          <Switch className="mr" checked={expandAllReports} />
          {t('WeeklyKeyActivityProgressLog.expandAllReports')}
        </div>
      </div>
      <div>
        {isDefined(wkaUserSection) && wkaUserSection.length > 0 ? (
          wkaUserSection
        ) : (
          <div className="center-content mt">
            {t('WeeklyKeyActivityProgressLog.noResults')}
          </div>
        )}
      </div>
    </div>
  );
};
