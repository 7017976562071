import { useRef } from 'react';
import {
  Action,
  GetInitiativeReportDocument,
  GetTenantInitiativesOldDocument,
  InitiativeContentFragment,
  TeamResourceType,
} from '../../../../generated/graphql';
import { useInitiativeReport } from './hooks/useInitiativeReport';
import { JoinInitiativesDrawer } from './JoinInitiativesDrawer';
import { CreateSubInitiativeDrawer } from './CreateSubInitiativeDrawer';
import { ActivitiesProgress } from './initiativeContent/ActivitiesProgress';
import { TeamContributionSection } from './initiativeContent/TeamContributionSection';
import { InitiativeMeta } from './initiativeContent/InitiativeMeta';
import { HighlightCarouselSection } from './HighlightCarouselSection';
import { InitiativeOverview } from './initiativeContent/InitiativeOverview';
import scrollIntoView from 'scroll-into-view-if-needed';
import { usePermissionForTeams } from '../../../../hooks/usePermissionForTeams';
import { atLeastOne } from '../../../../services/error-parser/helpers';

interface Props {
  initiative: InitiativeContentFragment;
  filteredTeamId?: string;
}

export const InitiativeContent = ({ initiative, filteredTeamId }: Props) => {
  const { data, loading } = useInitiativeReport(
    initiative.domainId,
    filteredTeamId
  );

  const activitiesProgressRef = useRef<HTMLDivElement>(null);
  const { data: permittedToCreateInitiativesForTeams } = usePermissionForTeams(
    TeamResourceType.TEAM_INITIATIVE,
    Action.CREATE
  );

  const { data: permittedToJoinInitiativesForTeams } = usePermissionForTeams(
    TeamResourceType.TEAM_LINKS,
    Action.CREATE
  );

  const hasPermissionsToCreateInitiative = atLeastOne(
    permittedToCreateInitiativesForTeams?.permittedToPerformActionForTeam
      .permittedResources
  );

  const hasPermissionsToJoinInitiative = atLeastOne(
    permittedToJoinInitiativesForTeams?.permittedToPerformActionForTeam
      .permittedResources
  );
  return (
    <div style={{ overflow: 'hidden' }}>
      <InitiativeMeta
        data={initiative}
        actions={
          <>
            {hasPermissionsToCreateInitiative && (
              <CreateSubInitiativeDrawer
                className="mr--xl"
                disabled={initiative.completed.value || initiative.archived}
                initiativeDomainId={initiative.domainId}
                initiativeFullId={initiative.id}
                refetchQueries={[
                  {
                    query: GetTenantInitiativesOldDocument,
                  },
                  {
                    query: GetInitiativeReportDocument,
                    variables: {
                      teamId: initiative.team.id,
                      initiativeId: initiative.domainId.itemId,
                      filter: { teamId: filteredTeamId },
                      forceUpdate: true,
                    },
                  },
                ]}
              />
            )}

            {hasPermissionsToJoinInitiative && (
              <JoinInitiativesDrawer
                alreadyJoinedTeams={
                  data?.initiativeReportV2.summary.teams.map(
                    (t) => t.teamId.domainId.itemId
                  ) ?? []
                }
                key={initiative.id}
                initiativeFullId={initiative.id}
                disabled={initiative.completed.value || initiative.archived}
                refetchQueries={[
                  {
                    query: GetInitiativeReportDocument,
                    variables: {
                      teamId: initiative.team.id,
                      initiativeId: initiative.domainId.itemId,
                      filter: { teamId: filteredTeamId },
                      forceUpdate: true,
                    },
                  },
                ]}
              />
            )}
          </>
        }
      />

      <div className="divider" />

      <div className="flx flx--column mb--xl">
        <InitiativeOverview
          activitiesSummary={data?.initiativeReportV2.summary.activitiesSummary}
        />
      </div>

      <div className="divider" />

      <HighlightCarouselSection
        sprintKeyActivities={data?.initiativeReportV2.summary.oldSprintKAs}
        loading={loading}
        onScrollToActivitiesProgress={() => {
          if (activitiesProgressRef.current)
            scrollIntoView(activitiesProgressRef.current, {
              scrollMode: 'if-needed',
              block: 'nearest',
              inline: 'nearest',
              behavior: 'smooth',
            });
        }}
      />

      <div className="divider" />

      <div className="flx flx--column mb--xl">
        <TeamContributionSection
          teamSummary={data?.initiativeReportV2.summary.teams}
          teamId={filteredTeamId}
        />
      </div>

      <div className="divider mb" />
      <div ref={activitiesProgressRef}>
        <ActivitiesProgress
          sprintKeyActivities={data?.initiativeReportV2.summary.oldSprintKAs}
          contributingTeams={data?.initiativeReportV2.summary.teams.map(
            (t) => t.team
          )}
          initiative={initiative}
          lastUpdatedAt={data?.initiativeReportV2.updatedAt}
          filteredTeamId={filteredTeamId}
          loading={loading}
        />
      </div>
    </div>
  );
};
