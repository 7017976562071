import { useTranslation } from 'react-i18next';
import {
  generateArgumentsMap,
  GenericInsights,
  getInsightCode,
} from '../../../../../../../../hooks/useAccelerationMeetingInsights';
import { GenericInsightCard } from '../genericCard/GenericInsightCard';
import * as sentry from '@sentry/react';

type Props = {
  genericInsights: GenericInsights[];
};

export const SprintInsightCards = ({ genericInsights }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      {genericInsights.map((gi, index) => {
        const args = generateArgumentsMap(gi.args);
        if (!i18n.exists(`NextSprintInsights.insight.${gi.code}.short`)) {
          sentry.captureMessage(
            `NextSprintInsights.insight.${gi.code} has no translation`,
            {
              level: 'warning',
            }
          );
          return null;
        }
        return (
          <GenericInsightCard
            keyValue={index}
            heading={t('NextSprintInsights.sprintInsight')}
            key={index}
            title={t(`NextSprintInsights.insight.${gi.code}.short`, args)}
            backSide={{
              description: t(`backCardDescriptions.${getInsightCode(gi.code)}`),
              why: t(
                `backCardWhyImportantDescriptions.${getInsightCode(gi.code)}`
              ),
            }}
          >
            {t(`NextSprintInsights.insight.${gi.code}.long`, args)}
          </GenericInsightCard>
        );
      })}
    </>
  );
};
