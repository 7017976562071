type SprintTerm = {
  startDate: string;
};

export const getEarliestTermStartDate = (terms: SprintTerm[]) => {
  let earliestStartDate = terms[0].startDate;
  for (const term of terms) {
    if (term.startDate < earliestStartDate) {
      earliestStartDate = term.startDate;
    }
  }
  return earliestStartDate;
};
