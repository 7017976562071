import { LinkOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Table, Tabs, Empty } from 'antd';
import React, { useState } from 'react';

import './KeyActivityLineEditor.less';
import { ColumnProps } from 'antd/lib/table/Column';
import { NumberInput } from '../../../../../NumberInput';
import { stringToNumber } from '../../../../../../services/stringToNumber';
import { CurveEditorPeriodAction } from './CurveEditorPeriodAction';
import { Trans, useTranslation } from 'react-i18next';
import { TitleWithTooltip } from '../../../../../../appPages/team/setup/components/TitleWithTooltip';
import { toCumulativePeriods } from '../../../../../../services/akpiUtils';
import { Btn } from '../../../../../Button';

interface Props {
  setActiveDot: (periodIndex: number | null) => void;
  setPeriodData: (periodData: Period[]) => void;
  periodData: Period[];
}

interface Period {
  targetDate: string;
  timePeriodIndex: number;
  label: string;
  goal?: number;
  actual?: number;
  historic?: number;
}

export const KeyActivityLineEditor = ({
  periodData,
  setPeriodData,
  setActiveDot,
}: Props) => {
  const { t } = useTranslation();

  const [selectedFields, setSelectedFields] = useState<number[]>([]);
  const [lockedFields, setLockedFields] = useState<number[]>([]);
  const [interpolationKey, setInterpolationKey] = useState<'goal' | 'historic'>(
    'goal'
  );

  if (!periodData || periodData.length === 0)
    return (
      <Empty
        description={t('KeyActivityLineEditor.emptyText')}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    );

  const cumulativePeriodData = toCumulativePeriods(periodData ?? []);

  const toggleLock = (periodIndex: number) => {
    if (lockedFields.includes(periodIndex)) {
      setLockedFields(
        lockedFields.filter((fieldIndex) => fieldIndex !== periodIndex)
      );
    } else {
      setLockedFields([...lockedFields, periodIndex]);
    }
  };

  const handlePeriodValueChange = (
    period: Period,
    value: number | string | undefined,
    type: 'goal' | 'historic'
  ) => {
    value = typeof value === 'string' ? stringToNumber(value) : value;
    periodData[period.timePeriodIndex] = {
      ...period,
      [type]: value,
    };
    setPeriodData(periodData);
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLElement>, p: Period) => {
    e.stopPropagation();
    if (selectedFields.includes(p.timePeriodIndex)) {
      return setSelectedFields([]);
    }
    const newState = e.shiftKey
      ? [selectedFields[0], p.timePeriodIndex]
      : [p.timePeriodIndex];
    setSelectedFields(newState);
  };

  const baseColumns: ColumnProps<Period>[] = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <TitleWithTooltip
            tooltipContent={
              <Trans i18nKey="KeyActivityLineEditor.linkHelper">
                Click <LinkOutlined /> to mark the first row, then shift + click
                another <LinkOutlined /> to select all the rows between them
              </Trans>
            }
            title={<LinkOutlined />}
            tooltipPlacement="topRight"
          />
        </div>
      ),
      key: 'select',
      width: 50,
      render: (_, p) => (
        <CurveEditorPeriodAction
          onClick={(e) => handleLinkClick(e, p)}
          toggled={selectedFields.includes(p.timePeriodIndex)}
          icon={<LinkOutlined />}
        />
      ),
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <TitleWithTooltip
            tooltipContent={
              <Trans i18nKey="KeyActivityLineEditor.lockHelper">
                Locked <LockOutlined /> fields will not be changed by the Fill
                function
              </Trans>
            }
            title={<LockOutlined />}
            tooltipPlacement="topRight"
          />
        </div>
      ),
      key: 'lock',
      width: 50,
      render: (_, p) => {
        const Icon = lockedFields.includes(p.timePeriodIndex)
          ? LockOutlined
          : UnlockOutlined;
        return (
          <CurveEditorPeriodAction
            onClick={() => toggleLock(p.timePeriodIndex)}
            toggled={lockedFields.includes(p.timePeriodIndex)}
            icon={<Icon />}
          />
        );
      },
    },
    {
      title: t('KeyActivityLineEditor.targetDate'),
      key: 'label',
      dataIndex: 'label',
    },
  ];

  const goalColumns: ColumnProps<Period>[] = [
    ...baseColumns,
    {
      title: t('KeyActivityLineEditor.goalPerWeek'),
      key: 'target',
      render: (_, p) => (
        <NumberInput
          id={p.label}
          value={p.goal}
          min={0}
          onFocus={() => setActiveDot(p.timePeriodIndex)}
          onBlur={() => setActiveDot(null)}
          onChange={(value) =>
            handlePeriodValueChange(p, value ?? undefined, 'goal')
          }
        />
      ),
    },
    {
      title: t('KeyActivityLineEditor.goal'),
      key: 'accumulated',
      render: (_, p) => cumulativePeriodData[p.timePeriodIndex].goal,
    },
  ];

  const historicColumns: ColumnProps<Period>[] = [
    ...baseColumns,
    {
      title: t('KeyActivityLineEditor.historicPerWeek'),
      key: 'target',
      render: (_, p) => (
        <NumberInput
          id={p.label}
          value={p.historic}
          min={0}
          onFocus={() => setActiveDot(p.timePeriodIndex)}
          onBlur={() => setActiveDot(null)}
          onChange={(value) =>
            handlePeriodValueChange(p, value ?? undefined, 'historic')
          }
        />
      ),
    },
    {
      title: t('KeyActivityLineEditor.historic'),
      key: 'accumulated',
      render: (_, p) => cumulativePeriodData[p.timePeriodIndex].historic,
    },
  ];

  return (
    <div>
      <Tabs
        type="card"
        onChange={(key) => {
          if (key === 'goal' || key === 'historic') {
            setInterpolationKey(key);
          } else {
            throw new Error(`Unknown tab "${key}" in AKPI curve editor`);
          }
        }}
      >
        <Tabs.TabPane
          tab={t('KeyActivityLineEditor.tabGoal')}
          key="goal"
        ></Tabs.TabPane>
        <Tabs.TabPane
          tab={t('KeyActivityLineEditor.tabHistoric')}
          key="historic"
        ></Tabs.TabPane>
      </Tabs>
      <AkpiInterpolationHelper
        periodData={periodData}
        setPeriodData={setPeriodData}
        selectedFields={selectedFields}
        lockedFields={lockedFields}
        interpolationKey={interpolationKey}
      />
      <Table
        dataSource={periodData}
        columns={
          interpolationKey === 'historic' ? historicColumns : goalColumns
        }
        pagination={false}
        scroll={{ y: '50vh' as unknown as number }} // ugly, but it works (for now..), if the table is heigher than 50vh, it scrolls
        rowClassName={(p) =>
          selectedFields.includes(p.timePeriodIndex)
            ? 'selected'
            : p.timePeriodIndex > Math.min(...selectedFields) &&
                p.timePeriodIndex < Math.max(...selectedFields)
              ? 'selected--inRange'
              : ''
        }
        rowKey="label"
        size="small"
      />
    </div>
  );
};

interface AkpiInterpolationHelperProps {
  periodData: Period[];
  setPeriodData: (periodData: Period[]) => void;
  selectedFields: number[];
  lockedFields: number[];
  interpolationKey: 'goal' | 'historic';
}

const AkpiInterpolationHelper = ({
  periodData,
  setPeriodData,
  selectedFields,
  lockedFields,
  interpolationKey,
}: AkpiInterpolationHelperProps) => {
  const [weeklyGoal, setWeeklyGoal] = useState<number>();
  const { t } = useTranslation();
  const updateAllValuesInCurve = () => {
    setPeriodData(
      periodData.map((p, i) => {
        if (lockedFields.includes(i)) {
          return p;
        }
        return {
          ...p,
          [interpolationKey]: weeklyGoal,
        };
      })
    );
  };
  const updateSelectedValuesInCurve = () => {
    const start = Math.min(...selectedFields);
    const end = Math.max(...selectedFields);

    const newPeriodData = periodData.map((p, i) => {
      if (i < start || i > end) return p;
      if (lockedFields.includes(i))
        return { ...p, [interpolationKey]: p[interpolationKey] ?? 0 };
      return { ...p, [interpolationKey]: weeklyGoal };
    });

    setPeriodData(newPeriodData);
  };

  return (
    <div className="flx mb">
      <div className="mr">
        <label htmlFor="weeklyGoal">
          {interpolationKey === 'goal'
            ? t('AkpiInterpolationHelper.weeklyGoal')
            : t('AkpiInterpolationHelper.historic')}
        </label>
        <NumberInput
          className="ml"
          id="weeklyGoal"
          name="end"
          min={0}
          value={weeklyGoal}
          onChange={(value) => {
            value = typeof value === 'string' ? stringToNumber(value) : value;
            if (value && value < 0) return;
            setWeeklyGoal(value ?? undefined);
          }}
        />
      </div>
      {selectedFields.length <= 1 ? (
        <Btn
          type="primary"
          ghost
          onClick={updateAllValuesInCurve}
          style={{ alignSelf: 'flex-end' }}
        >
          {t('AkpiInterpolationHelper.fillAll')}
        </Btn>
      ) : (
        <Btn
          onClick={updateSelectedValuesInCurve}
          style={{ alignSelf: 'flex-end' }}
          type="primary"
          ghost
        >
          {t('AkpiInterpolationHelper.fillSelected')}
        </Btn>
      )}
    </div>
  );
};
