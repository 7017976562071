import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FlagOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { Divider, Popconfirm, Popover, Tooltip, Typography } from 'antd';
import cx from 'classnames';
import React, { useContext, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { DisplayName } from '../../../../../components/DisplayName';
import { ErrorAlert } from '../../../../../components/ErrorAlert';
import {
  Action,
  MitemListEntry_MitemFragment,
  MitemStatus,
} from '../../../../../generated/graphql';
import { useTeamMembers } from '../../../../../hooks/useTeamMembers';
import { ArchiveOutlinedIcon } from '../../../../../icons/ArchiveIcon';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { stringSort } from '../../../../../services/stringSort';
import { InitiativeTag } from '../../../../../components/initiative/InitiativeTag';
import {
  Icons,
  InitiativeIcon,
} from '../../../../company/initiatives_old/initiativesPageV1/InitiativeIcons';
import { Sprint } from '../../../common/hooks/useSprintPlanningData';
import { TagImage } from '../../../setup/overviewPage/labels/TagImage';
import {
  SorterConjunction,
  tagsContext,
} from '../../common/TeamSprintProvider';
import {
  isPossibleToChangeArchiveState,
  useMitemArchivation,
} from '../../common/services/useMitemArchivation';
import './MitemListEntry.less';
import { CopySprintKeyActivityDrawer } from './mitemListEntry/CopySprintKeyActivityDrawer';
import { EditSprintKeyActivityDrawer } from './mitemListEntry/EditSprintKeyActivityDrawer';
import { ItemTypes } from './MitemPlanningBoard';
import { Btn } from '../../../../../components/Button';
import { useTeamPermissions } from '../../../../../usePermissions';
import { StarIcon } from '../../../../../icons/Star';
import { gql } from '@apollo/client';
import { useTenantDetails } from '../../../../../hooks/useTenantDetails';
import { match } from 'ts-pattern';
import { MilestoneStatusIcon } from '../../../../../components/MilestoneStatusIcon';
import { useKeyActivityDetailsModal } from '../../../../../hooks/useKeyActivityDetailsModal';

interface Props {
  teamId: string;
  sprintKeyActivity: MitemListEntry_MitemFragment;
  sprint: Sprint;
  highlighted?: boolean;
}

export const MitemListEntry = ({
  teamId,
  sprintKeyActivity,
  sprint,
  highlighted,
}: Props) => {
  const { features } = useTenantDetails();
  const keyActivityDetailsModal = useKeyActivityDetailsModal();

  const { isAllowed: isAllowedToEdit } = useTeamPermissions({
    requestedAction: {
      resource: 'sprintKA',
      action: Action.UPDATE,
    },
    teamId: teamId,
  });

  const initiativeIds = match(features)
    .with({ teamInitiativesEnabled: true }, () => {
      return sprintKeyActivity?.supportedInitiatives?.map((i) => i.id) ?? [];
    })
    .with({ tenantInitiativesEnabled: true }, () => {
      return sprintKeyActivity?.supportsInitiatives2?.map((i) => i.id) ?? [];
    })
    .otherwise(() => []);

  const mitemTagsIds = sprintKeyActivity.tags.map((t) => t.id);
  const allTagIds = [...mitemTagsIds, ...initiativeIds];
  const { tagsSelectedForFiltering, untaggedOnly, andOrChoice } = useContext(
    tagsContext
  ) as any;
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [showCopyDrawer, setShowCopyDrawer] = useState(false);

  const chooseTagLogic = () => {
    /**andOrChoice: 0 = AND, 1 = OR. These are default values passed by antd Switch component. */
    if (andOrChoice === SorterConjunction.AND) {
      return !tagsSelectedForFiltering.every((tid: string) =>
        allTagIds.includes(tid)
      );
    }
    if (andOrChoice === SorterConjunction.OR) {
      return !tagsSelectedForFiltering.some((tid: string) =>
        allTagIds.includes(tid)
      );
    }
    console.error('Switch for TAG logic returned unexpected value');
    return false;
  };

  const hasEveryOrSomeOfSelectedTagFilters =
    tagsSelectedForFiltering &&
    tagsSelectedForFiltering.length > 0 &&
    chooseTagLogic();

  const { name, owner } = sprintKeyActivity;
  const { t } = useTranslation();
  const [archiveMitem, { loading, error }] = useMitemArchivation({
    onCompleted(data) {
      showNotification('success', {
        message: data.editSprintKa.archived
          ? t('MitemListEntry.archiveSuccess')
          : t('MitemListEntry.reactivateSuccess'),
      });
    },
    update(cache) {
      cache.modify({
        fields: {
          sprintKeyActivities(existingMitems, { readField }) {
            const newPayload =
              existingMitems?.sprintKeyActivities?.filter((mitemRef: any) => {
                return readField('id', mitemRef) !== sprintKeyActivity.id;
              }) ?? [];

            return { ...existingMitems, sprintKeyActivities: newPayload };
          },
        },
      });
    },
  });

  const deadlineChangeAllowed = !sprintKeyActivity.completed;

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.MITEM,
    item: {
      mitem: sprintKeyActivity,
      sprint: sprint,
    },
    canDrag: () => {
      return isAllowedToEdit && deadlineChangeAllowed;
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const { isMember, loading: loadingMembers } = useTeamMembers(
    sprintKeyActivity.teamId
  );

  const showOwnerWarning =
    !loadingMembers && !isMember(sprintKeyActivity.owner.id);

  const archive = {
    icon: sprint.locked ? (
      <ArchiveOutlinedIcon className="font-size--sm" />
    ) : (
      <DeleteOutlined className="font-size--sm" />
    ),
    tooltipTitle: sprint.locked
      ? t('MitemListEntry.archiveMitem')
      : t('MitemListEntry.deleteMitem'),
    confirmTitle: sprint.locked
      ? t('MitemListEntry.confirmArchive.title.archive')
      : t('MitemListEntry.confirmArchive.title.remove'),
  };

  const teamTags = sprintKeyActivity.tags ?? [];

  const sortedTeamTags =
    [...teamTags]
      .sort((a, b) => {
        return stringSort(a.name, b.name);
      })
      .map((t) => <TagImage key={t.id} tag={t} />) ?? [];

  const sortedInitiativeTags = match(features)
    .with({ teamInitiativesEnabled: true }, () => {
      return (
        sprintKeyActivity?.supportedInitiatives
          ?.sort((a, b) => {
            return stringSort(a.tag.title, b.tag.title);
          })
          .map(({ id, tag, completed, archived }) => (
            <InitiativeTag
              key={id}
              title={tag.title}
              borderColor={tag.colorCode}
              completed={completed?.value}
              icon={Icons[tag.iconId as InitiativeIcon]}
              archived={archived}
            />
          )) ?? []
      );
    })
    .with({ tenantInitiativesEnabled: true }, () => {
      return (
        sprintKeyActivity?.supportsInitiatives2
          ?.sort((a, b) => {
            return stringSort(a.initiative.tag.title, b.initiative.tag.title);
          })
          .map(({ id, initiative }) => (
            <InitiativeTag
              key={id}
              title={initiative.tag.title}
              borderColor={initiative.tag.colorCode}
              completed={initiative.metadata.completedAt != null}
              icon={Icons[initiative.tag.iconId as InitiativeIcon]}
              archived={initiative.metadata.archived}
            />
          )) ?? []
      );
    })
    .otherwise(() => []);

  return (
    <>
      <React.Fragment key={sprintKeyActivity.id}>
        <MaybeTooltip showOwnerWarning={showOwnerWarning}>
          <div
            onClick={() =>
              keyActivityDetailsModal.openModal(
                sprintKeyActivity.id,
                sprintKeyActivity.teamId
              )
            }
            className={cx(
              'MitemListEntry',
              `MitemListEntry--${sprintKeyActivity.status}`,
              {
                'MitemListEntry--dragged': isDragging, // this class ends up on the original MItem, but not the one you drag
                'MitemListEntry--canChangeDeadline': deadlineChangeAllowed,
                'MitemListEntry--inLockedSprint': sprint.locked,
                'MitemListEntry--pendingConfirmation': highlighted,
              }
            )}
            ref={drag}
          >
            <Typography.Text
              className={cx('MitemListEntry__name', {
                'MitemListEntry--dimmed dimmable':
                  (!untaggedOnly && hasEveryOrSomeOfSelectedTagFilters) ||
                  (untaggedOnly &&
                    (sprintKeyActivity.tags.length > 0 ||
                      initiativeIds.length > 0)),
              })}
              strong
            >
              {name}
            </Typography.Text>

            <div
              className={cx('flx flx--gap--xs flx--wrap mt--m ', {
                'MitemListEntry--dimmed dimmable':
                  (!untaggedOnly && hasEveryOrSomeOfSelectedTagFilters) ||
                  (untaggedOnly &&
                    (sprintKeyActivity.tags.length > 0 ||
                      initiativeIds.length > 0)),
              })}
            >
              {sprintKeyActivity.milestone && <StarIcon />}
              {sprintKeyActivity.supportsMilestones.length > 0 && (
                <Popover
                  title={t('MitemListEntry.supportsMilestones', {
                    count: sprintKeyActivity.supportsMilestones.length,
                  })}
                  content={sprintKeyActivity.supportsMilestones.map((m) => (
                    <div key={m.id} className="flx flx--1 flx--ai-center">
                      <MilestoneStatusIcon metadata={m.milestone.metadata} />
                      <div className="ml--s mr">{m.milestone.name}</div>
                    </div>
                  ))}
                >
                  <FlagOutlined />
                </Popover>
              )}
              {sortedInitiativeTags}
              {sortedTeamTags}
            </div>

            <Divider className="mt--s mb--s" />
            <div className="flx flx--jc-space-between flx--ai-center">
              <div
                className={cx('MitemListEntry__owner', {
                  'MitemListEntry--dimmed dimmable':
                    (!untaggedOnly && hasEveryOrSomeOfSelectedTagFilters) ||
                    (untaggedOnly &&
                      (sprintKeyActivity.tags.length > 0 ||
                        initiativeIds.length > 0)),
                })}
              >
                {t('MitemListEntry.owner')} <DisplayName user={owner} />
                {showOwnerWarning && (
                  <WarningFilled className="txt--danger MitemListEntry__warning" />
                )}
              </div>

              <div className="flx">
                {isAllowedToEdit && (
                  <>
                    <Tooltip
                      placement="top"
                      title={t('MitemListEntry.copySka')}
                      mouseEnterDelay={0.7}
                    >
                      <Btn
                        className="txt--secondary"
                        type="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowCopyDrawer(true);
                        }}
                        icon={<CopyOutlined className="font-size--sm" />}
                        size="small"
                      />
                    </Tooltip>
                    {isPossibleToChangeArchiveState(
                      sprintKeyActivity,
                      sprint
                    ) && (
                      <Popconfirm
                        title={archive.confirmTitle}
                        onConfirm={(e) => {
                          e?.stopPropagation();
                          archiveMitem(
                            sprintKeyActivity.teamId,
                            sprintKeyActivity.id
                          );
                        }}
                        onCancel={(e) => e?.stopPropagation()}
                        okText={t('MitemListEntry.confirmArchive.confirm')}
                        cancelText={t('MitemListEntry.confirmArchive.cancel')}
                        disabled={loading}
                      >
                        <Tooltip
                          placement="top"
                          title={archive.tooltipTitle}
                          mouseEnterDelay={0.7}
                        >
                          <Btn
                            className="txt--secondary"
                            type="text"
                            icon={archive.icon}
                            size="small"
                            onClick={(e) => e.stopPropagation()}
                          />
                        </Tooltip>
                      </Popconfirm>
                    )}

                    {sprintKeyActivity.status !== MitemStatus.ARCHIVED && (
                      <Tooltip
                        placement="top"
                        title={t('MitemListEntry.editMitem')}
                        mouseEnterDelay={0.7}
                      >
                        <Btn
                          className="txt--secondary"
                          onClick={(e) => {
                            e?.stopPropagation();
                            setShowEditDrawer(true);
                          }}
                          type="text"
                          size="small"
                          icon={<EditOutlined className="font-size--sm" />}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {error && (
            <ErrorAlert
              error={error}
              title={t('MitemListEntry.archiveError')}
            />
          )}
        </MaybeTooltip>
      </React.Fragment>
      <EditSprintKeyActivityDrawer
        mitem={sprintKeyActivity}
        showModal={showEditDrawer}
        onCompleted={() => setShowEditDrawer(false)}
        onCancel={() => setShowEditDrawer(false)}
      />
      <CopySprintKeyActivityDrawer
        mitem={sprintKeyActivity}
        showDrawer={showCopyDrawer}
        onCompleted={() => setShowCopyDrawer(false)}
        onCancel={() => setShowCopyDrawer(false)}
      />
    </>
  );
};

interface MaybeTooltipProps {
  showOwnerWarning: boolean | null;
  children: React.ReactNode;
}
const MaybeTooltip: React.FC<MaybeTooltipProps> = ({
  showOwnerWarning,
  children,
}) => {
  const { t } = useTranslation();

  if (showOwnerWarning) {
    return (
      <Tooltip title={t('MitemListEntry.noLongerMember')}>{children}</Tooltip>
    );
  }

  return <>{children}</>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MITEM_LIST_ENTRY_MITEM = gql`
  fragment MitemListEntry_Mitem on Mitem {
    id
    deadline
    status
    completed
    milestone
    name
    completed
    archived
    teamId
    owner {
      id
    }
    tags {
      id
      name
    }
    supportsMilestones {
      id
      domainId {
        itemId
        tenantId
      }
      milestone {
        id
        name
        ...MilestoneStatusIcon_Milestone
      }
    }
    supportedInitiatives {
      id
      tag {
        title
      }
    }
    supportsInitiatives2 {
      id
      initiative {
        id
        tag {
          title
        }
      }
    }
    ...EditSprintKeyActivityDrawer__Mitem
    ...CopySprintKeyActivityDrawer_Mitem
  }
`;
