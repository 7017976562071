import { Divider } from 'antd';
import { GlobalMigSection } from './components/GlobalMigSection/GlobalMigSection';

import { Suspense } from 'react';
import { InitiativeSection } from './components/InitiativeSection/InitiativeSection';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from 'react-error-boundary';

export const StrategicPlanPage = () => {
  return (
    <div className="content mt">
      <ErrorBoundary
        FallbackComponent={() => <GlobalMigSection.Error />}
        onError={(error) => Sentry.captureException(error)}
      >
        <Suspense fallback={<GlobalMigSection.Skeleton />}>
          <GlobalMigSection />
        </Suspense>
      </ErrorBoundary>
      <Divider />
      <Suspense fallback={<InitiativeSection.Skeleton />}>
        <InitiativeSection />
      </Suspense>
    </div>
  );
};
