import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  GetTeamInitiativesDocument,
  InitiativeContentFragment,
  LeaveInitiativeDocument,
} from '../../../../../../../../generated/graphql';
import { showNotification } from '../../../../../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../../../../../services/howweErrorParser';

interface Props {
  initiative: InitiativeContentFragment;
  teamId: string | undefined;
}

export const LeaveInitiative = ({ initiative, teamId }: Props) => {
  const { t } = useTranslation();

  let howweErrors;

  const [leaveInitiative] = useMutation(LeaveInitiativeDocument, {
    onCompleted: () => {
      showNotification('success', {
        message: (
          <div className="flx flx--jc-space-between flx--ai-center">
            <div>
              <strong>
                {t('InitiativeNotifications.leftSuccess', {
                  name: initiative.name,
                })}{' '}
              </strong>
            </div>
          </div>
        ),
      });
    },
    refetchQueries: () => [
      {
        query: GetTeamInitiativesDocument,
        variables: {
          teamId: teamId,
        },
      },
    ],
    onError: (e) => {
      howweErrors = howweErrorParser(e);

      showNotification('error', {
        message: (
          <strong>
            <ul>
              {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
            </ul>
          </strong>
        ),
      });
    },
  });
  const handleLeaveInitiative = () => {
    if (teamId) {
      leaveInitiative({
        variables: {
          teamId: teamId,
          initiativeFullId: initiative.id,
        },
      });
    }
  };
  return (
    <div onClick={handleLeaveInitiative}>{t('InitiativeDropdown.leave')}</div>
  );
};
