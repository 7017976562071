import { Skeleton, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';

import { useTranslation } from 'react-i18next';

import { stringSort } from '../../../../../../../services/stringSort';

export const SprintKeyActivityTableSkeleton = () => {
  const { t } = useTranslation();

  const milestoneColumns: ColumnProps<any>[] = [
    {
      title: t('common.deadline'),
      width: 120,
      key: 'deadline',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => stringSort(a.deadline, b.deadline),
      dataIndex: ['deadline'],
      render: () => {
        return <Skeleton paragraph={false} />;
      },
    },
    {
      title: t('common.status'),
      key: 'status',
      dataIndex: ['status'],
      width: 150,
      render: () => {
        return <Skeleton paragraph={false} />;
      },
    },
    {
      title: t('SprintKeyActivityTable.name'),
      key: 'name',
      dataIndex: ['name'],
      render: () => {
        return <Skeleton paragraph={false} />;
      },
    },
    {
      title: t('common.actions'),
      dataIndex: 'action',
      key: 'action',
      width: 120,
      render: () => {
        return <Skeleton paragraph={false} />;
      },
    },
  ];

  return (
    <div>
      <Table
        columns={milestoneColumns}
        dataSource={[{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]}
        style={{ borderRadius: '6px' }}
        pagination={{ pageSize: 20 }}
        rowKey={(ska) => ska.id}
      />
    </div>
  );
};
