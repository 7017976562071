import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { GetInitiativeReportDocument } from '../../../../../generated/graphql';

export const useInitiativeReport = (
  domainId: {
    itemId: string;
    teamId: string;
  },
  filteredTeamId?: string
) => {
  const { itemId: initiativeId, teamId } = domainId;
  const { data, error, loading } = useQuery(GetInitiativeReportDocument, {
    variables: {
      initiativeId,
      teamId,
      filter: { teamId: filteredTeamId },
    },
    fetchPolicy: 'cache-and-network',
  });
  return { data, error, loading };
};

export const useInitiativeReportLazy = () => {
  return useLazyQuery(GetInitiativeReportDocument);
};

// eslint-disable-next-line
const INITIATIVE_REPORT = gql`
  query getInitiativeReport(
    $tenantId: ID
    $teamId: ID!
    $initiativeId: ID!
    $filter: InitiativeReportFilter
    $forceUpdate: Boolean
  ) {
    initiativeReportV2(
      tenantId: $tenantId
      teamId: $teamId
      initiativeId: $initiativeId
      filter: $filter
      forceUpdate: $forceUpdate
    ) {
      id
      ...InitiativeMeta
      updatedAt
      filteredFor {
        teamId
      }
      summary {
        activitiesSummary {
          ...InitiativeOverview
        }
        oldSprintKAs {
          ...ColumnFields
          ...HighlightCarouselSection_Mitem
        }
        teams {
          team {
            id
            name
          }
          teamId {
            id
            domainId {
              itemId
            }
          }
          activitiesSummary {
            onTrackPercentage
            totalNumberOfActivities
            completed {
              keyActivitiesMarkedAsMilestone
              keyActivitiesNotMarkedAsMilestones
              total
            }
            overdue {
              keyActivitiesMarkedAsMilestone
              keyActivitiesNotMarkedAsMilestones
              total
            }
            planned {
              keyActivitiesMarkedAsMilestone
              keyActivitiesNotMarkedAsMilestones
              total
            }
            expectedToBeCompleted {
              keyActivitiesMarkedAsMilestone
              keyActivitiesNotMarkedAsMilestones
              total
            }
          }
        }
      }
    }
  }
`;
