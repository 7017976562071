import { Form, Modal } from 'antd';

import { CreateSubinitiativeModalContent } from './CreateSubinitiativeModalContent';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../../../ErrorPage';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
  initiativeId: string;
  onClose?: () => void;
}

export const CreateSubInitiativeModal = ({
  initiativeId,
  visible,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleClose = () => {
    onClose?.();
    form.resetFields();
  };

  return (
    <Modal
      open={visible}
      width={766}
      footer={null}
      style={{ top: 20 }}
      onCancel={handleClose}
      title={<h3>{t('CreateSubInitiativeModal.title')}</h3>}
      destroyOnClose
    >
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Suspense
          fallback={
            <CreateSubinitiativeModalContent.Skeleton isSubInitiative />
          }
        >
          <CreateSubinitiativeModalContent
            form={form}
            initiativeId={initiativeId}
            onSuccess={handleClose}
          />
        </Suspense>
      </ErrorBoundary>
    </Modal>
  );
};
