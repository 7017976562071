import { useTranslation } from 'react-i18next';
import { progressColor } from '../../../../../../../../../../components/akpi/teamCommitmentFulfillment/TeamCommitmentFulfillmentChart';
import { RenderedMarkdown } from '../../../../../../../../../../components/RenderedMarkdown';
import { useKeyActivityPerformance } from '../../../../../../../../../../hooks/useKeyActivityPerformanceQuery';
import { friendlyDate } from '../../../../../../../../../../services/dateFormats';
import { KeyActivitySummaryGraph } from '../../common/KeyActivitySummaryGraph';
import { useClosestPeriods } from '../../../../../../../../../../hooks/useClosestPeriods';
import { useCountdown } from '../../../../../../../../../../hooks/useCountdown';
import './WeeklyKeyActivityBlock.less';

import { TeamKeyActivityBarChart } from './TeamKeyActivityBarChart';
import { ToggleContent } from '../../common/ToogleContent';

enum FilterPeriod {
  PERVIOUS_PERIOD = 'PERVIOUS_PERIOD',
  ALL_TIME = 'ALL_TIME',
}

interface Props {
  startDate?: string;
  weeklyKeyActivity: {
    id: string;
    name: string | null;
    startDate: string;
    endDate: string;
    teamId: string;
    descriptionHtml: string | null;
    acceptanceCriteriaHtml: string | null;
    unit: string | null;
    periodDataWithCommitment: {
      targetDate: string;
      goal: number;
      actual: number | null;
      committed: number | null;
    }[];
  };
  weeklyKeyActivityTeamContributions: {
    id: string;
    name: string;
    totalCommitted: number;
    totalAchieved: number;
  }[];
  filterPeriod: FilterPeriod;
}
export const WeeklyKeyActivityBlock = ({
  startDate,
  weeklyKeyActivity,
  weeklyKeyActivityTeamContributions,
  filterPeriod,
}: Props) => {
  const { t } = useTranslation();
  const { lastFinishedPeriod } = useClosestPeriods(weeklyKeyActivity.teamId);
  const { data } = useKeyActivityPerformance(
    weeklyKeyActivity.teamId,
    weeklyKeyActivity.id
  );
  const teamContributions = weeklyKeyActivityTeamContributions.find(
    (wc) => wc.id === weeklyKeyActivity.id
  );

  const { timeDiff } = useCountdown(weeklyKeyActivity.endDate);
  return (
    <>
      <div>
        <h4 className="mb--none">{weeklyKeyActivity.name}</h4>
        <div className="flx flx--jc-space-between">
          {filterPeriod === FilterPeriod.PERVIOUS_PERIOD && (
            <span className="txt--secondary font-size--sm">
              {t('WeeklyKeyActivityBlock.dateFrom', {
                dateFrom: friendlyDate(startDate),
              })}
            </span>
          )}
          {filterPeriod === FilterPeriod.ALL_TIME && (
            <span className="txt--secondary font-size--sm">
              {t('WeeklyKeyActivityBlock.dueIn', {
                timeLeft: timeDiff,
              })}
            </span>
          )}
        </div>

        {filterPeriod === FilterPeriod.ALL_TIME && (
          <>
            <KeyActivitySummaryGraph
              periodData={weeklyKeyActivity.periodDataWithCommitment}
              referenceDate={lastFinishedPeriod?.timePeriodEndDate}
              targetFulfillment={data?.targetFulfillment}
            />
          </>
        )}
        {filterPeriod === FilterPeriod.PERVIOUS_PERIOD && teamContributions && (
          <div className="mb">
            <TeamKeyActivityBarChart data={teamContributions} />
            <div className="flx mt">
              <div className="mr--xl">
                <div className="flx flx--ai-center ">
                  <div
                    className="WeeklyKeyActivityBlock__Legend mr--s"
                    style={{
                      backgroundColor: progressColor.committed,
                      border: `1px solid ${progressColor.committedBorder}`,
                    }}
                  />
                  <span className="WeeklyKeyActivityBlock__totalCommitted">
                    {teamContributions?.totalCommitted}
                  </span>
                </div>
                <span className="WeeklyKeyActivityBlock__LegendLabels txt--secondary">
                  {t('common.committed', {
                    count: teamContributions?.totalCommitted,
                  })}
                </span>
              </div>
              <div>
                <div className="flx flx--ai-center">
                  <div
                    className="WeeklyKeyActivityBlock__Legend mr--s"
                    style={{
                      backgroundColor: progressColor.achieved,
                      border: `1px solid ${progressColor.achievedBorder}`,
                    }}
                  />
                  <span className="WeeklyKeyActivityBlock__totalAchieved">
                    {teamContributions?.totalAchieved}
                  </span>
                </div>
                <span className="WeeklyKeyActivityBlock__LegendLabels txt--secondary">
                  {t('KeyActivityContributionForUser.achieved')}
                </span>
              </div>
            </div>
          </div>
        )}
        {filterPeriod === FilterPeriod.PERVIOUS_PERIOD &&
          !teamContributions && (
            <div
              style={{ height: 110 }}
              className="center-content secondary italic"
            >
              <div className="text-c" style={{ padding: 20 }}>
                {t('WeeklyKeyActivityBlock.noActivity')}
              </div>
            </div>
          )}
      </div>

      <ToggleContent
        showContentTitle={t('WeeklyKeyActivityBlock.showDetails')}
        hideContentTitle={t('WeeklyKeyActivityBlock.hideDetails')}
      >
        <div>
          <div className="mb--s">
            <span className="txt--secondary space--r">
              {t('WeeklyKeyActivityBlock.unit')}:
            </span>
            <div>{weeklyKeyActivity.unit}</div>
          </div>
          <div className="mb--s">
            <span className="txt--secondary space--r">
              {t('common.description')}:
            </span>
            <RenderedMarkdown
              html={weeklyKeyActivity.descriptionHtml}
              emptyPlaceholder="-"
            />
          </div>
          <div className="mb--s">
            <span className="txt--secondary space--r">
              {t('WeeklyKeyActivityBlock.acceptanceCriteriaHtml')}
            </span>
            <RenderedMarkdown
              html={weeklyKeyActivity.acceptanceCriteriaHtml}
              emptyPlaceholder="-"
            />
          </div>
        </div>
      </ToggleContent>
    </>
  );
};
