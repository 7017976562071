import React, { CSSProperties } from 'react';
import './DrawerTitle.less';

interface Props {
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
}

export const DrawerTitle: React.FC<Props> = ({
  children,
  className = '',
  style,
}) => {
  return (
    <div className={'DrawerTitle ' + className} style={style}>
      {children}
    </div>
  );
};
