import { useTranslation } from 'react-i18next';
import './MigGraph.less';
import { NoData } from '../../../../../../components/NoData';
import { TeamOverviewGraphLegends } from '../../TeamOverviewGraphLegends';
import { Graph } from '../../Graph';
import { gql } from '@apollo/client';
import { MigGraph_MigV2Fragment } from '../../../../../../generated/graphql';
import { friendlyDate } from '../../../../../../services/dateFormats';

interface Props {
  mig: MigGraph_MigV2Fragment;
  actions?: React.ReactNode;
}

export const MigGraph = ({ mig, actions }: Props) => {
  const { t } = useTranslation();

  const periodData = mig.periodData.map((p) => ({
    ...p,
    label: friendlyDate(p.targetDate),
  }));

  if (periodData.length === 0) {
    return (
      <div className="MigGraph__noData">
        <NoData />
      </div>
    );
  }

  return (
    <div className="MigGraph flx flx--column">
      <div className="flx flx--ai-center">
        <h3 className="mb flx--1">{t('MigGraph.progressOverTime')}</h3>
        {actions}
      </div>

      <Graph
        periodData={periodData}
        minY={mig.minY}
        maxY={mig.maxY}
        data-intercom-target="Graph for MIG"
      >
        <TeamOverviewGraphLegends
          performance={mig.mostRecentReport?.goalFulfillment}
          goal={mig?.mostRecentReport.goal}
          progress={mig?.mostRecentReport.actual}
          historic={mig?.mostRecentReport.historic}
        />
      </Graph>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIG_GRAPH_FRAGMENT = gql`
  fragment MigGraph_MigV2 on MigV2 {
    id
    domainId {
      teamId
      itemId
    }
    periodData {
      targetDate
      actual
      goal
      historic
    }
    name
    unit
    mostRecentReport {
      targetDate
      actual
      goal
      goalFulfillment
      historic
    }
    minY
    maxY
  }
`;
