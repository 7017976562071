import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './genericCard.less';

export const NoInsightsInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="flx flx--column flx--jc-center flx--ai-center mt--xxl txt--secondary">
      <CheckCircleOutlined className="icon  mb--xl" />
      <span className="font-size--lg">{t('MigInsights.MigNoInsights')}</span>
    </div>
  );
};
