import { gql, useLazyQuery } from '@apollo/client';
import { GetAccelerationMeetingOverviewTeamDocument } from '../generated/graphql';

export const useTeamActiveStatusDetailsLazy = () => {
  return useLazyQuery(GetAccelerationMeetingOverviewTeamDocument);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_ACCELERATION_MEETING_OVERVIEW = gql`
  query getAccelerationMeetingOverviewTeam($teamId: ID!) {
    accelerationMeetingOverviewTeamV2(teamId: $teamId) {
      id
      name
      active
      totalMembers
      activeMembers
      completionPercent
      mostRecentMeetingDate
      insightComment {
        code
        args {
          name
          value
        }
      }
      lastMeetingsDetails {
        accelerationMeetingId
        periodStartAt
        periodEndAt
        offWeek
        periodStatus
        meetingCreatedAt
      }
    }
  }
`;
