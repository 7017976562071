import * as sentry from '@sentry/react';
import { getParsedErrors } from './error-parser';
import { ApolloError } from '@apollo/client';

export const howweErrorParser = (error?: ApolloError | unknown) => {
  if (!error) return;
  const errors = getParsedErrors(
    error,
    (missingKey, error) =>
      sentry.captureException(
        new Error(`Missing error translation key: '${missingKey}'`, {
          cause: error,
        }),
        { level: 'warning' }
      ),
    (error) => {
      sentry.captureException(error, {
        level: 'error',
      });
    }
  );
  return errors;
};
