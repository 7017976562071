import { gql, useQuery } from '@apollo/client';
import { GetAccelerationMeetingOverviewDocument } from '../../../../generated/graphql';

export const useAccelerationMeetingOverview = () => {
  const { data, loading, error } = useQuery(
    GetAccelerationMeetingOverviewDocument,
    { fetchPolicy: 'cache-and-network' }
  );

  return { data, loading, error };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACCELERATON_MEETING_OVERVIEW = gql`
  query getAccelerationMeetingOverview($tenantId: ID) {
    accelerationMeetingOverview(tenantId: $tenantId) {
      summary {
        totalActiveTeams
        totalNeverStartedTeams
        totalInactiveTeams
        totalTeams
      }
      teams {
        id
        name
        active
        mostRecentMeetingDate
        neverStarted
        completionPercent
        teamHierarchy {
          id
          name
          tenantId
        }
        insightComment {
          code
          args {
            name
            value
          }
        }
      }
    }
  }
`;
