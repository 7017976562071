import { isObject } from '../isObject';
import i18next from 'i18next';
import { getErrorArray, capitalizeFirstLetter, getPointerKey } from './helpers';
import { ApolloError } from '@apollo/client';

// Errors that the user can't affect, just asking them try again
const retryErrors = [
  'custom.insert_conflict',
  'generic.malformed_request',
  'generic.general_validation_error',
  'generic.http_status_code',
  'generic.server_error',
  'generic.not_an_array',
  'generic.not_a_map',
  'generic.not_a_char',
  'generic.not_a_boolean',
  'generic.size_of_collection',
  'generic.unknown_property',
];

export type HowweError = {
  translationKey: string;
  pointer?: string;
  translation: string;
  details: string;
  meta?: any;
  unparsedError?: any;
};

const notHowweError = (unparsedError: any): HowweError => ({
  pointer: '/',
  details: 'not a Howwe error',
  translationKey: 'generic.retry',
  translation: capitalizeFirstLetter(
    i18next.t('generic.retry', { ns: 'error' })
  ),
  unparsedError,
});

const getRetryError = (unparsedError: any): HowweError => ({
  pointer: unparsedError.source.pointer,
  details: unparsedError.detail,
  translationKey: 'generic.retry',
  translation: capitalizeFirstLetter(
    i18next.t('generic.retry', { ns: 'error' })
  ),
  meta: unparsedError.meta,
  unparsedError,
});

export const getParsedErrors = (
  error: ApolloError | unknown,
  onMissingTranslation: (key: string, error: any) => void,
  onError: (error: any) => void
): HowweError[] => {
  if (!(error instanceof ApolloError)) {
    onError(error);
    return [notHowweError(error)];
  }
  return handleGraphQLError(error, onMissingTranslation, onError);
};

type HowweApolloError = {
  graphQLErrors: ReadonlyArray<{
    extensions?: { [key: string]: any } | null;
  }>;
};

export const handleGraphQLError = (
  error: HowweApolloError,
  onMissingTranslation: (key: string, error: any) => void,
  onError: (error: any) => void
) => {
  const howweErrors = error.graphQLErrors.flatMap((e) => {
    try {
      return getErrorArray(e.extensions);
    } catch {
      return [];
    }
  });

  if (howweErrors.length === 0) {
    onError(error);
    return [notHowweError(error)];
  }

  const returnError = howweErrors.map((unparsedError) => {
    onError(unparsedError);
    if (!isObject(unparsedError.meta)) {
      return getRetryError(unparsedError);
    }

    const translationKey =
      unparsedError.meta.type === 'custom_error'
        ? `custom.${unparsedError.meta.code}`
        : `generic.${unparsedError.meta.type}`;

    if (retryErrors.includes(translationKey)) {
      return getRetryError(unparsedError);
    }

    if (i18next.exists(translationKey, { ns: 'error' })) {
      const pointerKey = getPointerKey(unparsedError.source.pointer);

      const hasPointerTranslation = i18next.exists(pointerKey, { ns: 'error' });

      if (!hasPointerTranslation) {
        onMissingTranslation(pointerKey, unparsedError);
      }

      const args = hasPointerTranslation
        ? {
            ...unparsedError.meta,
            pointer: i18next.t(pointerKey, { ns: 'error' }),
          }
        : unparsedError.meta;

      return {
        pointer: unparsedError.source.pointer,
        details: unparsedError.detail,
        translationKey: translationKey,
        translation: capitalizeFirstLetter(
          i18next.t(translationKey, { ...args, ns: 'error' }) as string
        ),
        meta: unparsedError.meta,
        unparsedError,
      } as HowweError;
    } else {
      onMissingTranslation(translationKey, unparsedError);
      return {
        pointer: unparsedError.source.pointer,
        details: unparsedError.detail,
        translationKey: translationKey,
        translation: capitalizeFirstLetter(unparsedError.detail),
        meta: unparsedError.meta,
        unparsedError,
      } as HowweError;
    }
  });
  return returnError;
};
