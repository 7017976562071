import { ComponentType } from 'react';
import './ProgressCard.less';
import { ProgressCardIcon } from './ProgressCardIcon';

interface Props {
  SvgIcon: ComponentType;
  color: string;
  children: React.ReactNode;
}

export const ProgressCard = ({ SvgIcon, color, children }: Props) => {
  return (
    <div className="ProgressCard flx flx--ai-center">
      <ProgressCardIcon SvgIcon={SvgIcon} color={color} />
      {children}
    </div>
  );
};
