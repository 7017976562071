import { Tooltip } from 'antd';
import { toPng } from 'html-to-image';
import { useRef } from 'react';
import { Btn } from '../components/Button';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Options } from 'html-to-image/lib/types';
import { standardDateFormat } from '../services/dateFormats';
import dayjs from 'dayjs';

/**
 * @description This hook returns a ref and a button that will save the image of the ref as a png.
 * Add the ref to the element that should be saved as an image.
 *
 * To exclude an element from the image, add the attribute `data-image-ignore` to it.
 *
 * @example
 * const { ref, SaveButton } = useImageSaver('my-file-name');
 * return (
 *  <div ref={ref}>
 *    <h1>My Graph</h1>
 *    <Graph />
 *    <p data-image-ignore>
 *      Some text that shouldn't be included in the image
 *    </p>
 *  </div>
 *  <SaveImageButton />
 * )
 */
export const useImageSaver = (filename: string, toPngOptions: Options = {}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const handleSave = () => {
    if (ref.current === null) {
      return;
    }

    const cleanedElement = duplicateAndRemoveIgnoredElements(ref.current);

    toPng(cleanedElement, {
      cacheBust: true,
      skipFonts: true,
      backgroundColor: '#fff',
      ...toPngOptions,
    })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = getFilename(filename);
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        document.body.removeChild(cleanedElement);
      });
  };

  const SaveImageButton = () => (
    <Tooltip title={t('useImageSaver.buttonTooltip')}>
      <Btn
        type="link"
        icon={<DownloadOutlined />}
        onClick={handleSave}
        data-image-ignore
      >
        {t('useImageSaver.buttonText')}
      </Btn>
    </Tooltip>
  );

  return { ref, SaveImageButton };
};

const sanitizeFilename = (filename: string) => {
  return filename.trim().replace(/[^a-z0-9 ,-]/gi, '_');
};

const getFilename = (filename: string) => {
  return `${sanitizeFilename(filename)} - ${standardDateFormat(dayjs())}.png`;
};

const duplicateAndRemoveIgnoredElements = (element: HTMLElement) => {
  // Clone the element so that we can remove elements from it without affecting the original
  const clonedNode = element.cloneNode(true) as HTMLElement;

  // remove margin on container so that it doesn't affect the image
  clonedNode.setAttribute('style', 'margin: 0 !important;');

  // Remove all elements with the attribute `data-image-ignore`
  clonedNode.querySelectorAll('[data-image-ignore]').forEach((element) => {
    element.remove();
  });

  // Create a temporary container to clone the element into
  const tempContainer = document.body.appendChild(
    document.createElement('div')
  );

  // Set the container to inline-block so that it doesn't take up the whole width
  tempContainer.setAttribute('style', 'display: inline-block;');

  tempContainer.appendChild(clonedNode);

  return tempContainer;
};
