const generateBackgroundColorHexCode = (
  backgroundColorCode: string | undefined
) => {
  if (!backgroundColorCode) return '#000000';
  if (backgroundColorCode.charAt(0) !== '#') {
    backgroundColorCode = `#${backgroundColorCode}`;
  }
  return backgroundColorCode;
};

const getContrast = (hexColor: string): string => {
  if (hexColor?.charAt(0) === '#') {
    hexColor = hexColor.slice(1);
  }
  // Convert to RGB value
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Get YIQ ratio (magic numbers come from here: https://pl.wikipedia.org/wiki/YIQ)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? 'black' : 'white';
};

interface TagImageProps {
  tag: { id: string; backgroundColor: string; name: string };
  highlight?: boolean;
}

export const TagImage = ({ tag, highlight }: TagImageProps) => {
  return (
    <span
      className={`activity-tag no-wrap ${highlight ? 'blink' : ''}`}
      key={tag.id}
      style={{
        backgroundColor:
          generateBackgroundColorHexCode(tag.backgroundColor) || '#000000',
        color: getContrast(tag.backgroundColor || 'ffffff'),
      }}
    >
      {tag.name}
    </span>
  );
};
