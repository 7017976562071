import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ManageSearchSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M.795 11.25v-1.5h8v1.5h-8Zm0-3.75V6h4v1.5h-4Zm0-3.75v-1.5h4v1.5h-4Zm14.938 7.5-3.188-3.188c-.319.223-.67.393-1.052.511a4.036 4.036 0 0 1-1.198.177c-1.11 0-2.055-.389-2.833-1.167-.778-.777-1.167-1.722-1.167-2.833 0-1.111.39-2.056 1.167-2.833C8.24 1.139 9.184.75 10.295.75c1.112 0 2.056.389 2.833 1.167.778.777 1.167 1.722 1.167 2.833 0 .417-.059.816-.177 1.198A3.773 3.773 0 0 1 13.608 7l3.187 3.188-1.062 1.062Zm-5.438-4c.695 0 1.285-.243 1.771-.729a2.411 2.411 0 0 0 .73-1.771c0-.695-.244-1.285-.73-1.771a2.411 2.411 0 0 0-1.77-.729c-.695 0-1.286.243-1.772.729a2.411 2.411 0 0 0-.729 1.771c0 .695.243 1.285.73 1.771a2.411 2.411 0 0 0 1.77.729Z" />
  </svg>
);

export const ManageSearchIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={ManageSearchSvg} {...props} />
);
