import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { GetMigTargetFulfillmentForTeamDocument } from '../generated/graphql';

export const useTeamMigPerformance = (teamId: string) => {
  const res = useQuery(GetMigTargetFulfillmentForTeamDocument, {
    variables: { teamId },
  });

  if (res.error) {
    return { ...res, data: null };
  }

  return {
    ...res,
    teamMigPerformance: res.data?.teamMigs.migFulfillment?.goalFulfillment,
  };
};

export const useTeamMigPerformanceLazy = () => {
  const [fetchTeamMigPerformance, { data, error, loading }] = useLazyQuery(
    GetMigTargetFulfillmentForTeamDocument
  );

  return {
    fetchTeamMigPerformance,
    teamMigPerformance: data?.teamMigs.migFulfillment?.goalFulfillment,
    loading,
    error,
  };
};

export const useMigPerformance = (teamId: string, migId: string) => {
  const queryResponse = useTeamMigPerformance(teamId);

  const migPerformance = queryResponse.data?.teamMigs.migs.find(
    (d) => d.domainId.itemId === migId
  );

  return {
    ...queryResponse,
    migPerformance: migPerformance?.mostRecentReport.goalFulfillment,
  };
};

export const GET_TEAM_TARGET_FULFILLMENT = gql`
  query getMigTargetFulfillmentForTeam($teamId: ID!) {
    teamMigs(teamId: $teamId) {
      migFulfillment {
        goalFulfillment
        id
        domainId {
          itemId
          tenantId
        }
      }
      migs {
        id
        domainId {
          itemId
          tenantId
        }
        mostRecentReport {
          goalFulfillment
          targetDate
        }
      }
    }
  }
`;
