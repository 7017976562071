import { Col, Divider, Row } from 'antd';
import { ToggleSection } from '../../../team/overview/components/ToggleSection';
import { useTranslation } from 'react-i18next';
import { InitiativeCardSkeleton } from '../../../../components/initiatives/initiativeCard/InitiativeCard.skeleton';
import {
  MilestoneOrActiveToggle,
  MilestoneOrActiveToggleEnum,
} from '../initiativeDetails/milestoneOrActivityToggle/MilestoneOrActiveToggle';

export const InitiativeStartPageSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb--xl">
        <h2 className="mb--xl">
          {t('InitiativeStartPage.companyInitiatives.title')}
        </h2>
        <h3 className="mb--l">
          {t('InitiativeStartPage.companyInitiatives.activeTitle')}
        </h3>
        <div className="mb--l">
          <MilestoneOrActiveToggle
            value={MilestoneOrActiveToggleEnum.Milestones}
            onChange={() => {}}
            disabled={true}
          />
        </div>
        <Row gutter={[18, 18]} className="mb--xl">
          <Col xs={12} xl={8} key={'1'}>
            <InitiativeCardSkeleton />
          </Col>
          <Col xs={12} xl={8} key={'2'}>
            <InitiativeCardSkeleton />
          </Col>
          <Col xs={12} xl={8} key={'3'}>
            <InitiativeCardSkeleton />
          </Col>
          <Col xs={12} xl={8} key={'4'}>
            <InitiativeCardSkeleton />
          </Col>
          <Col xs={12} xl={8} key={'5'}>
            <InitiativeCardSkeleton />
          </Col>
          <Col xs={12} xl={8} key={'6'}>
            <InitiativeCardSkeleton />
          </Col>
        </Row>
        <div className="flx flx--jc-center flx--column">
          <ToggleSection
            titleExpanded={t('InitiativeStartPage.companyInitiatives.showLess')}
            titleNotExpanded={t(
              'InitiativeStartPage.companyInitiatives.showMore'
            )}
            data-intercom-target="Expandable section to show completed and upcoming Company Initiatives"
            disabled={true}
          >
            <div className="flx flx--jc-space-between mb">
              <h3>
                {t(
                  'InitiativeStartPage.companyInitiatives.completedAndUpcoming'
                )}
              </h3>
            </div>
          </ToggleSection>
        </div>
      </div>
      <Divider />
      <div>
        <h2 className="mb--xl">
          {t('InitiativeStartPage.otherInitiatives.title')}
        </h2>
        <h3 className="mb--l">
          {t('InitiativeStartPage.otherInitiatives.activeTitle')}
        </h3>
        <MilestoneOrActiveToggle
          value={MilestoneOrActiveToggleEnum.Milestones}
          onChange={() => {}}
          disabled={true}
        />
        <Row gutter={[18, 18]} className="mb--xl">
          <Col xs={12} xl={8} key={'1'}>
            <InitiativeCardSkeleton />
          </Col>
          <Col xs={12} xl={8} key={'2'}>
            <InitiativeCardSkeleton />
          </Col>
          <Col xs={12} xl={8} key={'3'}>
            <InitiativeCardSkeleton />
          </Col>
        </Row>
        <div className="flx flx--jc-center flx--column">
          <ToggleSection
            titleExpanded={t('InitiativeStartPage.otherInitiatives.showLess')}
            titleNotExpanded={t(
              'InitiativeStartPage.otherInitiatives.showMore'
            )}
            data-intercom-target="Expandable section to show completed and upcoming Company Initiatives"
            disabled={true}
          >
            <div className="flx flx--jc-space-between mb">
              <h3>
                {t('InitiativeStartPage.otherInitiatives.completedAndUpcoming')}
              </h3>
            </div>
          </ToggleSection>
        </div>
      </div>
    </div>
  );
};
