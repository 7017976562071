import { Col, Row } from 'antd';
import { InitiativeCardSkeleton } from '../../../../../components/initiatives/initiativeCard/InitiativeCard.skeleton';

export const InitiativeSectionGridSkeleton = () => {
  return (
    <div className="mt--l">
      <Row gutter={[18, 18]} className="mb--xl">
        <Col xs={12} xl={8}>
          <InitiativeCardSkeleton />
        </Col>
        <Col xs={12} xl={8}>
          <InitiativeCardSkeleton />
        </Col>
        <Col xs={12} xl={8}>
          <InitiativeCardSkeleton />
        </Col>
      </Row>
    </div>
  );
};
