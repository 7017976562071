import { match } from 'ts-pattern';
import { MigStatus } from '../../../../../../../../../../generated/graphql';
import { Colors } from '../../../../../../../../../componentLibrary/Colors';

export const getColorForMigStatus = (mig: { status: MigStatus }) => {
  let statusColor: string = '';

  match(mig)
    .with(
      { status: MigStatus.ACTIVE },
      () => (statusColor = Colors.Action.BLUE)
    )
    .with(
      { status: MigStatus.ARCHIVED },
      () => (statusColor = Colors.Grays.DISABLED_FONTS)
    )
    .with(
      { status: MigStatus.ENDED },
      () => (statusColor = Colors.Status.OK_GREEN)
    )
    .with(
      { status: MigStatus.OVERDUE },
      () => (statusColor = Colors.Status.OVERDUE)
    )
    .otherwise(() => (statusColor = Colors.Grays.DISABLED_FONTS));

  return statusColor;
};
