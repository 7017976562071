import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAuth0 } from './Auth0Provider';
import { sessionId } from './App';
import { useTenantOverride } from './TenantOverrideProvider';

const METABASE_DASHBOARD_URL = 'https://metabase.howwe.io/dashboard/13';

export const useBasicTagsForSentry = () => {
  const { user } = useAuth0();
  const { tenantId } = useTenantOverride();

  // Add sessionId to scope when hook is first called
  useEffect(() => {
    Sentry.getCurrentScope().setTag('SessionId', sessionId);
  }, []);

  // Add userId and tenantId to scope, and corresponding useful urls to context
  useEffect(() => {
    const usefulUrls: Record<string, string> = {};

    if (user) {
      Sentry.getCurrentScope().setUser({ id: user.howwe.userId });

      usefulUrls.metabaseUser = `${METABASE_DASHBOARD_URL}?user_id=${user.howwe.userId}`;
    }

    if (tenantId) {
      Sentry.getCurrentScope().setTag('TenantId', tenantId);

      usefulUrls.metabaseTenant = `${METABASE_DASHBOARD_URL}?tenant_id=${tenantId}`;
    }

    if (Object.keys(usefulUrls).length > 0) {
      Sentry.setContext('Useful Urls', usefulUrls);
    }
  }, [user, tenantId]);
};
