import Icon from '@ant-design/icons';

const DollarIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 8 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.34997 12V10.5667C2.76108 10.4333 2.25275 10.1778 1.82497 9.8C1.39719 9.42222 1.0833 8.88889 0.883301 8.2L2.11663 7.7C2.2833 8.23333 2.53052 8.63889 2.8583 8.91667C3.18608 9.19444 3.61663 9.33333 4.14997 9.33333C4.60552 9.33333 4.99163 9.23056 5.3083 9.025C5.62497 8.81944 5.7833 8.5 5.7833 8.06667C5.7833 7.67778 5.66108 7.36945 5.41663 7.14167C5.17219 6.91389 4.60552 6.65556 3.71663 6.36667C2.76108 6.06667 2.10552 5.70833 1.74997 5.29167C1.39441 4.875 1.21663 4.36667 1.21663 3.76667C1.21663 3.04444 1.44997 2.48333 1.91663 2.08333C2.3833 1.68333 2.86108 1.45556 3.34997 1.4V0H4.6833V1.4C5.23886 1.48889 5.69719 1.69167 6.0583 2.00833C6.41941 2.325 6.6833 2.71111 6.84997 3.16667L5.61663 3.7C5.4833 3.34444 5.29441 3.07778 5.04997 2.9C4.80552 2.72222 4.47219 2.63333 4.04997 2.63333C3.56108 2.63333 3.18886 2.74167 2.9333 2.95833C2.67775 3.175 2.54997 3.44444 2.54997 3.76667C2.54997 4.13333 2.71663 4.42222 3.04997 4.63333C3.3833 4.84444 3.96108 5.06667 4.7833 5.3C5.54997 5.52222 6.13052 5.875 6.52497 6.35833C6.91941 6.84167 7.11663 7.4 7.11663 8.03333C7.11663 8.82222 6.8833 9.42222 6.41663 9.83333C5.94997 10.2444 5.37219 10.5 4.6833 10.6V12H3.34997Z" />
  </svg>
);

export const DollarIcon = (props: any) => (
  <Icon component={DollarIconSvg} {...props} />
);
