import React from 'react';
import { Typography, Radio } from 'antd';

import { useTranslation } from 'react-i18next';
import { lineColor } from '../../../../../../../../styleVars';
import { ResponsiveContainer, LineChart, Line, YAxis, XAxis } from 'recharts';

import {
  addSameAsLastPeriodStrategy,
  addAccordingToPlan,
  addReachGoalThisPeriodStrategy,
  addReachGoalAtEndDateStrategy,
  Strategy,
} from './strategyUtils';
import './SelectStrategy.less';

interface Props {
  strategy: Strategy;
  strategies: Record<Strategy, number>;
  setStrategy: (strategy: Strategy) => void;
  unit: string;
}

interface GraphProps {
  periodData: {
    goal: number;
    actual?: number;
    strategy?: number;
  }[];
}

interface StrategyBoxProps {
  title: string;
  toReachGoal: number;
  selected: boolean;
  unit: string;
  children: React.ReactNode;
}

const StrategyBox: React.FC<StrategyBoxProps> = ({
  children,
  title,
  toReachGoal,
  selected,
  unit,
}) => {
  return (
    <div className={`Strategy__Box ${selected ? 'selected' : ''}`}>
      <div className="Strategy__Overlay" />
      <div style={{ padding: 8 }}>
        <div
          style={{ display: 'flex', justifyContent: 'space-between' }}
          className="mb"
        >
          {title}
          <Radio checked={selected} />
        </div>
        <div>
          {children}
          <div className="mt--s">
            {toReachGoal} {unit}
          </div>
        </div>
      </div>
    </div>
  );
};

const StrategyGraph = ({ periodData }: GraphProps) => {
  return (
    <ResponsiveContainer debounce={300} height={50} width="100%">
      <LineChart data={periodData} margin={{ left: 0, top: 5 }}>
        <Line
          type="linear"
          dataKey="goal"
          stroke={lineColor.goal}
          strokeWidth={2}
          dot={false}
          isAnimationActive={false}
        />
        <Line
          type="linear"
          dataKey="actual"
          stroke={lineColor.actual}
          strokeWidth={2}
          dot={false}
          isAnimationActive={false}
        />
        <Line
          type="linear"
          dataKey="strategy"
          stroke="#888"
          strokeWidth={2}
          strokeDasharray="4,6"
          dot={false}
          isAnimationActive={false}
        />
        <YAxis tick={false} width={1} />
        <XAxis tick={false} height={1} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export const SelectStrategy = ({
  strategy,
  setStrategy,
  strategies,
  unit,
}: Props) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  // These numbers are just hardcoded and tested to look good
  const weeklyGoal = 12;
  const numberOfPeriods = 12;
  const currentPeriod = 8;
  const baseCurve = Array.from(
    { length: numberOfPeriods },
    (v, timePeriodIndex) => {
      const goalForPeriod = timePeriodIndex * weeklyGoal;
      const actualForPeriod =
        timePeriodIndex <= currentPeriod
          ? goalForPeriod - timePeriodIndex ** 1.8
          : undefined;
      return {
        actual: actualForPeriod,
        goal: goalForPeriod,
        timePeriodIndex: timePeriodIndex,
      };
    }
  );

  const sameAsLastPeriodBox = (
    <div
      className="Strategy_NoChange"
      onClick={() => setStrategy(Strategy.SAME_AS_LAST_PERIOD)}
    >
      {t('SelectStrategy.currentTrendStrategy')}
      <div className="flx mt">
        <div style={{ width: '100%' }}>
          <StrategyBox
            unit={unit}
            title={t('SelectStrategy.keepPaceStrategy')}
            toReachGoal={strategies.SAME_AS_LAST_PERIOD}
            selected={strategy === Strategy.SAME_AS_LAST_PERIOD}
          >
            <StrategyGraph
              periodData={addSameAsLastPeriodStrategy(baseCurve, currentPeriod)}
            />
          </StrategyBox>
        </div>
      </div>
    </div>
  );

  const accordingToPlanBox = (
    <div
      className="Strategy_NoChange"
      onClick={() => setStrategy(Strategy.ACCORDING_TO_PLAN)}
    >
      {t('SelectStrategy.accordingToPlanStrategy')}
      <div className="flx mt">
        <div style={{ width: '100%' }}>
          <StrategyBox
            unit={unit}
            title={t('SelectStrategy.accordingToPlanStrategySelectTitle')}
            toReachGoal={strategies.ACCORDING_TO_PLAN}
            selected={strategy === Strategy.ACCORDING_TO_PLAN}
          >
            <StrategyGraph
              periodData={addAccordingToPlan(baseCurve, currentPeriod)}
            />
          </StrategyBox>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Text type="secondary" strong>
        {t('SelectStrategy.planForwardTitle')}
      </Text>
      <div className="flx mt" style={{ maxWidth: '600px' }}>
        {strategy === Strategy.SAME_AS_LAST_PERIOD
          ? sameAsLastPeriodBox
          : accordingToPlanBox}
        <div className="Strategy_Change">
          {t('SelectStrategy.catchUpOptionsTitle')}
          <div className="flx mt">
            <div
              className="Strategy__Change__Container mr"
              onClick={() => setStrategy(Strategy.REACH_GOAL_THIS_PERIOD)}
            >
              <StrategyBox
                unit={unit}
                title={t('SelectStrategy.catchUpThisWeekStrategy')}
                toReachGoal={strategies.REACH_GOAL_THIS_PERIOD}
                selected={strategy === Strategy.REACH_GOAL_THIS_PERIOD}
              >
                <StrategyGraph
                  periodData={addReachGoalThisPeriodStrategy(
                    baseCurve,
                    currentPeriod
                  )}
                />
              </StrategyBox>
            </div>
            <div
              className="Strategy__Change__Container"
              onClick={() => setStrategy(Strategy.REACH_GOAL_AT_END_DATE)}
            >
              <StrategyBox
                unit={unit}
                title={t('SelectStrategy.catchUpAtEndStrategy')}
                toReachGoal={strategies.REACH_GOAL_AT_END_DATE}
                selected={strategy === Strategy.REACH_GOAL_AT_END_DATE}
              >
                <StrategyGraph
                  periodData={addReachGoalAtEndDateStrategy(
                    baseCurve,
                    currentPeriod
                  )}
                />
              </StrategyBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
