import { Progress, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { roundToFloor } from '../../../../../../../../../services/roundNumbersHelper';
import './SprintProgressCard.less';

interface Props {
  total: number;
  completed: number;
}

export const SprintProgressCard = ({ total, completed }: Props) => {
  const { t } = useTranslation();
  const progressPercentage =
    total !== 0 ? roundToFloor((completed / total) * 100)! : 0;
  return (
    <div className="SprintProgressCard flx flx--jc-space-between">
      <div className="flx flx--column flx--jc-space-between">
        <Typography.Text strong>
          {t('SprintProgressCard.Title')}
        </Typography.Text>
        <div>
          <div className="SprintProgressCard__completeLegend" />
          {t('SprintProgressCard.CompleteLegend', {
            completed,
            total,
          })}
        </div>
      </div>
      <div className="m--l">
        <Progress
          size="default"
          type="circle"
          percent={progressPercentage}
          strokeColor={'#027C34'}
          strokeWidth={8}
          format={(percent) => (
            <div>
              <div>{`${percent}%`}</div>
            </div>
          )}
        />
      </div>
    </div>
  );
};
