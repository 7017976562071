import dayjs from 'dayjs';
import { PublishStatus } from '../generated/graphql';

export const getStatusByDates = (
  startDate: string,
  endDate: string,
  today = dayjs()
) => {
  if (today.isBefore(startDate)) {
    return PublishStatus.UPCOMING;
  }
  if (dayjs(endDate).isBefore(today)) {
    return PublishStatus.ENDED;
  }
  return PublishStatus.ACTIVE;
};
