import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { SprintPlanningPage } from './planning/SprintPlanningPage';
import { SprintSummaryPage } from './summary/SprintSummaryPage';
import { useCreateMitemModal } from './common/hooks/useCreateMitemModal';
import { TeamGantishForMilestones } from './components/TeamGantishForMilestones';
import { useTenantDetails } from '../../../hooks/useTenantDetails';

export const TeamSprintRoutes = () => {
  const match = useRouteMatch();
  const { features } = useTenantDetails();
  const { teamId } = useParams<{ teamId: string }>();
  const sprintKaCreationModal = useCreateMitemModal();

  return (
    <>
      {features.tenantInitiativesEnabled && (
        <TeamGantishForMilestones teamId={teamId} />
      )}

      <Switch>
        <Route exact path={match.path + '/summary'}>
          <SprintSummaryPage
            teamId={teamId}
            createMitemModal={sprintKaCreationModal}
          />
        </Route>
        <Route exact path={match.path + '/planning'}>
          <SprintPlanningPage
            teamId={teamId}
            createMitemModal={sprintKaCreationModal}
          />
        </Route>
        <Redirect exact from={match.url + '/'} to={match.url + '/summary'} />
      </Switch>
    </>
  );
};
