import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Modal, Spin, Typography } from 'antd';
import { useMutation, gql } from '@apollo/client';
import {
  GetTeamKeyActivitiesPreviewDocument,
  PublishStatus,
  UpdateWeeklyKeyActivityStatusDocument,
} from '../../../generated/graphql';

import { KeyActivityModalState } from '../KeyActivitySetupPage';

import { useWeeklyKeyActivityLazy } from './hooks/useWeeklyKeyActivity';

import { AkpiCard } from '../../akpi/teamAkpiPreviews/AkpiCard';
import { getStatusByDates } from '../../../services/sprintTermStatus';
import { ErrorAlert } from '../../ErrorAlert';
import { showNotification } from '../../../services/fetchNotificationProperties';

interface Props {
  teamId: string;
  tenantId?: string;
  keyActivityModalState: KeyActivityModalState | null;
  onClose: () => void;
}

export const StatusWeeklyActivityModal = ({
  keyActivityModalState,
  onClose,
  teamId,
  tenantId,
}: Props) => {
  const { t } = useTranslation();
  const { fetchWeeklyKeyActivity, weeklyKeyActivity, loading } =
    useWeeklyKeyActivityLazy();
  const toBeArchived =
    keyActivityModalState?.type === 'UpdateStatusWeeklyKeyActivity' &&
    keyActivityModalState.status !== PublishStatus.ARCHIVED;

  const currentStatus =
    keyActivityModalState?.type === 'UpdateStatusWeeklyKeyActivity' &&
    keyActivityModalState.status;

  useEffect(() => {
    if (
      keyActivityModalState?.type === 'UpdateStatusWeeklyKeyActivity' &&
      keyActivityModalState.id
    ) {
      fetchWeeklyKeyActivity({
        variables: {
          teamId,
          tenantId,
          weeklyKeyActivityId: keyActivityModalState.id,
        },
      });
    }
  }, [fetchWeeklyKeyActivity, keyActivityModalState, teamId, tenantId]);

  const [
    updateWeeklyKeyActivityStatus,
    { error: updateError, loading: updateLoading },
  ] = useMutation(UpdateWeeklyKeyActivityStatusDocument, {
    onCompleted: (response) => {
      showNotification('success', {
        message: (
          <strong>
            {response.updateWeeklyKeyActivityStatusV2.status ===
            PublishStatus.ARCHIVED
              ? t('StatusWeeklyActivityModal.archivedSuccess')
              : t('StatusWeeklyActivityModal.unarchiveSuccess')}
          </strong>
        ),
        description: response.updateWeeklyKeyActivityStatusV2.name,
      });

      onClose();
    },
    refetchQueries: () => [
      {
        query: GetTeamKeyActivitiesPreviewDocument,
        variables: { teamId, tenantId },
      },
    ],
  });

  const isOpen =
    keyActivityModalState?.type === 'UpdateStatusWeeklyKeyActivity' &&
    keyActivityModalState.id != null;

  useEffect(() => {
    (window as any).Intercom?.('update', {
      hide_default_launcher: isOpen,
    });
  }, [isOpen]);

  const handleSave = () => {
    if (keyActivityModalState?.id == null) {
      throw new Error(
        'weeklyKeyActivityId is null or undefined when trying to change status on a weekly key activity'
      );
    }

    updateWeeklyKeyActivityStatus({
      variables: {
        teamId,
        tenantId,
        weeklyKeyActivityId: keyActivityModalState?.id,
        archive: toBeArchived,
      },
    });
  };

  const fromActiveStatus =
    keyActivityModalState?.type === 'UpdateStatusWeeklyKeyActivity' &&
    keyActivityModalState.status === PublishStatus.ACTIVE;

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      onOk={handleSave}
      keyboard={true}
      confirmLoading={updateLoading}
      okText={toBeArchived ? t('common.archive') : t('common.reactivate')}
      destroyOnClose
      title={
        <div className="flx flx--column">
          <Typography.Text>
            {toBeArchived
              ? t('StatusWeeklyActivityModal.archiveTitle')
              : t('StatusWeeklyActivityModal.activationTitle')}
          </Typography.Text>
        </div>
      }
    >
      <Spin spinning={loading}>
        <div>
          {weeklyKeyActivity && (
            <AkpiCard akpiData={weeklyKeyActivity} showDescription={false} />
          )}
          {toBeArchived && currentStatus ? (
            <div className="mt--xl">
              <Alert
                type="warning"
                className="mb--l"
                message={t('StatusWeeklyActivityModal.archiveWarningTitle')}
                description={t('StatusSprintTermModal.currentStatus', {
                  status: t(`KeyActivityStatus.${currentStatus}`),
                })}
              />
              <Typography.Title level={5}>
                {t('StatusWeeklyActivityModal.pleaseNoteTitle')}
              </Typography.Title>
              <ul>
                {fromActiveStatus && (
                  <li>{t('StatusWeeklyActivityModal.archiveNoteOne')}</li>
                )}
                <li>{t('StatusWeeklyActivityModal.archiveNoteTwo')}</li>
              </ul>
            </div>
          ) : (
            <div className="mt--xl">
              <Alert
                className="mb"
                type="info"
                message={t('StatusWeeklyActivityModal.reactivateTitle')}
              />
              <Typography.Title level={5}>
                {t('StatusWeeklyActivityModal.pleaseNoteTitle')}
              </Typography.Title>

              {weeklyKeyActivity && (
                <p>
                  {t(
                    `StatusWeeklyActivityModal.${getStatusByDates(
                      weeklyKeyActivity.startDate,
                      weeklyKeyActivity.endDate
                    )}`
                  )}
                </p>
              )}
            </div>
          )}
          <ErrorAlert
            error={updateError}
            title={t('StatusWeeklyActivityModal.activationError')}
          />
        </div>
      </Spin>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_WEEKLY_KEY_ACTIVITY_STATUS = gql`
  mutation updateWeeklyKeyActivityStatus(
    $teamId: ID!
    $weeklyKeyActivityId: ID!
    $archive: Boolean!
    $tenantId: ID
  ) {
    updateWeeklyKeyActivityStatusV2(
      teamId: $teamId
      weeklyKeyActivityId: $weeklyKeyActivityId
      archive: $archive
      tenantId: $tenantId
    ) {
      name
      id
      status
    }
  }
`;
