import Skeleton from 'react-loading-skeleton';
import './SkaCard.less';
import cx from 'classnames';

interface Props {
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
}

export const SkaCard = ({ children, className, loading }: Props) => {
  if (loading) {
    return <Skeleton height={400} />;
  }
  return <div className={cx('SkaCard', className)}>{children}</div>;
};
