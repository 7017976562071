import { useTranslation } from 'react-i18next';

import { Btn } from '../../../../Button';
import { TeamMigSelect } from '../../../InitiativeForm/teamMigSelector/TeamMigSelect';
import { Alert, Skeleton, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { TeamSelector } from '../../../../../appPages/company/initiatives_old/initiativesPageV1/TeamSelector';

export const ManageCompanyMigsSkeleton = () => {
  const { t } = useTranslation();

  const columns: ColumnProps<any>[] = [
    {
      title: t('common.status'),
      dataIndex: 'id',
      key: 'status',
      render() {
        return <Skeleton paragraph={false} />;
      },
    },
    {
      title: t('common.title'),
      dataIndex: 'id',
      key: 'name',
      render() {
        return <Skeleton paragraph={false} />;
      },
    },
    {
      title: t('common.team'),
      dataIndex: 'id',
      key: 'team',
      render() {
        return <Skeleton paragraph={false} />;
      },
    },
    {
      key: 'actions',
      align: 'right',
      dataIndex: 'id',
      render() {
        return <Skeleton paragraph={false} />;
      },
    },
  ];

  return (
    <div>
      <Alert
        className="mb--xl"
        showIcon
        closable
        message={t('ManageCompanyMigs.helpText')}
      />

      <h3 className="mb--l">{t('ManageCompanyMigs.linkMostImportantGoal')}</h3>
      <div className="flx mb--xl">
        <TeamSelector className="mr" disabled />
        <TeamMigSelect
          placeholder={t('ManageCompanyMigs.selectMig')}
          className="flx--1"
          disabled
        />
        <Btn type="primary" className="ml--xl" disabled={true}>
          {t('ManageCompanyMigs.linkMig')}
        </Btn>
      </div>
      <h3 className="mb--l">{t('ManageCompanyMigs.currentlyLinked')}</h3>
      <Table
        size="middle"
        className="showScrollBar"
        rowKey={(record) => record.id}
        dataSource={[{ id: 1 }, { id: 2 }]}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};
