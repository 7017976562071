import dayjs, { Dayjs } from 'dayjs';
import moment, { Moment } from 'moment';

import { API_DATE_FORMAT } from './dateFormats';

export const dateCompare = (dateA: string | Moment, dateB: string | Moment) => {
  return moment(dateA).diff(dateB);
};

export function momentToDayjs(date: Moment): Dayjs;
export function momentToDayjs(date: Moment | string): Dayjs;
export function momentToDayjs(date: Moment | null): Dayjs | null;
export function momentToDayjs(date: Moment | undefined): Dayjs | undefined;
export function momentToDayjs(
  date: Moment | string | null | undefined
): Dayjs | null | undefined;
export function momentToDayjs(date: any) {
  if (typeof date === 'string') return dayjs(date);

  if (moment.isMoment(date)) {
    return dayjs(date.format(API_DATE_FORMAT));
  }

  return date;
}

export function dayjsToMoment(date: Dayjs): Moment;
export function dayjsToMoment(date: Dayjs | null): Moment | null;
export function dayjsToMoment(date: Dayjs | undefined): Moment | undefined;
export function dayjsToMoment(date?: Dayjs): Moment | undefined;
export function dayjsToMoment(
  date: Dayjs | null | undefined
): Moment | null | undefined;
export function dayjsToMoment(date: any) {
  if (dayjs.isDayjs(date)) {
    return moment(date.format(API_DATE_FORMAT));
  }

  return date;
}
