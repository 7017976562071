import { Alert } from 'antd';
import { useInitiativeReportLazy } from '../../hooks/useInitiativeReport';
import { Btn } from '../../../../../../components/Button';
import dayjs from 'dayjs';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface Props {
  initiativeDomainId: { tenantId: string; teamId: string; itemId: string };
  lastUpdatedAt: string;
  filteredTeamId?: string;
  className?: string;
}

export const RefetchReportInfoWithButton = ({
  initiativeDomainId,
  filteredTeamId,
  lastUpdatedAt,
  className,
}: Props) => {
  const { t } = useTranslation();
  const [fetchInitiativeReport, { loading }] = useInitiativeReportLazy();

  return (
    <Alert
      className={className}
      type="info"
      message={
        <div>
          <InfoCircleOutlined className="mr" />
          {t('RefetchReportInfoWithButton.lastUpdated', {
            date: dayjs(lastUpdatedAt).fromNow(),
          })}
          <Btn
            size="small"
            loading={loading}
            className="ml"
            onClick={() =>
              fetchInitiativeReport({
                variables: {
                  tenantId: initiativeDomainId.tenantId,
                  teamId: initiativeDomainId.teamId,
                  initiativeId: initiativeDomainId.itemId,
                  filter: { teamId: filteredTeamId },
                  forceUpdate: true,
                },
              })
            }
          >
            {t('RefetchReportInfoWithButton.updateReport')}
          </Btn>
        </div>
      }
    />
  );
};
