import './CardSkeleton.less';
import cx from 'classnames';

interface Props {
  className?: string;
}

export const CardSkeleton = ({ className }: Props) => {
  return <div className={cx('CardSkeleton__card', className)} />;
};
