import { Modal } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import './SubInitiativesModal.less';
import { useTeams } from '../../../../../../../hooks/useTeams';
import { TenantTeamFragment } from '../../../../../../../generated/graphql';
import { TeamOutlineIcon } from '../../../../../../../icons/initiativeIcons/TeamOutlineIcon';
import { InitiativeNode } from '../../../../../../company/initiatives_old/initiativesPageV1/services/initiativeTreeNodeHelper';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../TeamInitiativesCardFooter.less';
import { Link } from 'react-router-dom';
import './ContributingTeamsModal.less';

interface Props {
  hierarchicalInitiative: InitiativeNode;
}

export const ContributingTeamsModal = ({ hierarchicalInitiative }: Props) => {
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const { t } = useTranslation();

  const columns: ColumnsType<TenantTeamFragment> = [
    {
      title: 'Team',
      dataIndex: ['id'],
      key: 'id',
      render: (_, record) => {
        return (
          <div key={record.id}>
            <Link
              to={'/team/' + record.id}
              className="ContributingTeamsModal__teamName"
            >
              {record.name}
            </Link>
          </div>
        );
      },
    },
  ];
  const { teams } = useTeams();

  const teamsContributing =
    hierarchicalInitiative.totalTeamsContributingIds.length;

  const allContributingTeams =
    hierarchicalInitiative.totalTeamsContributingIds.map((id) => {
      const contributingTeam = teams.find((team) => team.id === id);
      return contributingTeam as NonNullable<TenantTeamFragment>;
    });

  return (
    <div>
      <Modal
        open={openTeamModal}
        onCancel={() => setOpenTeamModal(false)}
        title={t('TeamInitiativesCard.teamsContributing')}
        footer={null}
      >
        <div className="TeamInitiativesCard__table">
          <Table
            columns={columns}
            pagination={false}
            size={'small'}
            scroll={{ y: 240 }}
            dataSource={allContributingTeams}
          />
        </div>
      </Modal>
      <div className="clickable" onClick={() => setOpenTeamModal(true)}>
        <span className="space--r txt--secondary">
          {t('TeamInitiativesCard.teams')}
        </span>
        <span className="mr TeamInitiativesCardFooter__icon">
          <TeamOutlineIcon className="mr--xs " />
          {teamsContributing}
        </span>
      </div>
    </div>
  );
};
