import { useTranslation } from 'react-i18next';
import './TeamOverviewSection.less';
import { OverviewProgressCardSkeleton } from '../progressCard/OverviewProgressCard.skeleton';

export const TeamOverviewSectionSkeleton = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h2 className="mb--l">{t('TeamOverviewSection.header')}</h2>
      <div
        className="TeamOverviewSection__container"
        data-intercom-target="Team overview header"
      >
        <OverviewProgressCardSkeleton />
        <OverviewProgressCardSkeleton />
        <OverviewProgressCardSkeleton />
        <OverviewProgressCardSkeleton />
      </div>
    </div>
  );
};
