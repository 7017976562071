import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const AlignmentIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
  >
    <g mask="url(#a)">
      <path
        d="M12.787 18.094v-2.5H9.453V7.26H7.786v2.5H1.953V3.094h5.833v2.5h5v-2.5h5.834V9.76h-5.834v-2.5H11.12v6.667h1.667v-2.5h5.833v6.667h-5.834Zm1.666-10h2.5V4.76h-2.5v3.334Zm0 8.333h2.5v-3.333h-2.5v3.333ZM3.62 8.094h2.5V4.76h-2.5v3.334Z"
        fill="#868686"
      />
    </g>
  </svg>
);

export const AlignmentIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={AlignmentIconSvg} {...props} />
);
