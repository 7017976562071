import { Modal, Alert, Form } from 'antd';

import { gql, useMutation } from '@apollo/client';
import { useLazyTimezones } from '../../../../../../hooks/useTimezones2';
import { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Btn } from '../../../../../../components/Button';
import { UpdateTeamTimezoneDocument } from '../../../../../../generated/graphql';
import { showNotification } from '../../../../../../services/fetchNotificationProperties';
import { TimezoneSelector } from '../../../../components/TimezoneSelector';
import { useTranslation } from 'react-i18next';

interface Props {
  teamName: string;
  tenantId?: string;
  teamId: string;
  timezone: string;
}

export const TeamTimezoneModalWithButton = ({
  teamName,
  tenantId,
  teamId,
  timezone,
}: Props) => {
  const { t } = useTranslation();
  const [formRef] = Form.useForm();
  const [modalVisibility, setModalVisibility] = useState(false);
  const [fetchTimezones, { data }] = useLazyTimezones();

  useEffect(() => {
    if (modalVisibility) fetchTimezones();
  }, [fetchTimezones, modalVisibility]);

  const [updateTimezone, { error, loading }] = useMutation(
    UpdateTeamTimezoneDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: (
            <strong>{t('TeamTimezoneModalWithButton.successMessage')}</strong>
          ),
        });
        setModalVisibility(false);
      },
    }
  );

  const handleSubmit = () => {
    formRef.validateFields().then(({ timezone }) => {
      updateTimezone({
        variables: {
          tenantId,
          teamId: teamId,
          teamDelta: {
            timezone: timezone,
          },
        },
      });
    });
  };

  return (
    <>
      <Btn
        type="link"
        icon={<EditOutlined />}
        onClick={() => setModalVisibility(true)}
        className="pr--s"
      >
        {t('TeamTimezoneModalWithButton.edit')}
      </Btn>
      <Modal
        title={`${teamName} | ${t('TeamTimezoneModalWithButton.title')}`}
        open={modalVisibility}
        onOk={handleSubmit}
        onCancel={() => setModalVisibility(false)}
        cancelButtonProps={{ disabled: loading }}
        confirmLoading={loading}
        destroyOnClose
      >
        <Form form={formRef} initialValues={{ timezone }} layout="vertical">
          <Form.Item
            name="timezone"
            label={t('TeamTimezoneModalWithButton.timezone')}
          >
            <TimezoneSelector timezoneIds={data?.timezones.timezones} />
          </Form.Item>
        </Form>
        {error && (
          <Alert
            message="Woops.. Something went wrong"
            description={error.message}
            type="error"
          />
        )}
      </Modal>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_TEAM_TIMEZONE = gql`
  mutation updateTeamTimezone(
    $tenantId: ID
    $teamId: ID!
    $teamDelta: TeamInput!
  ) {
    updateTeam(tenantId: $tenantId, teamId: $teamId, teamDelta: $teamDelta) {
      id
      timezone
    }
  }
`;
