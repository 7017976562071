import React from 'react';
import { TooltipProps } from 'recharts';

import './TeamKeyActivityBarChartTooltip.less';

import {
  NameType,
  Payload,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import { useTranslation } from 'react-i18next';
import { ChartLegend } from './ChartLegend';

export interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
  payload?: Array<Payload<ValueType, NameType> & { stroke?: string }>;
}
export const TeamKeyActivityBarChartTooltip = ({
  active,
  payload,
}: CustomTooltipProps) => {
  const { t } = useTranslation();
  if (!active || !payload || payload.length <= 0) {
    return null;
  }
  return (
    <div className="TeamKeyActivityBarChartTooltip">
      <div>
        {payload?.map((item, i) => {
          if (item.name === 'totalCommitted') {
            return (
              <div key={`${i}-${item.name}`}>
                <ChartLegend
                  color={item.color}
                  borderColor={item.stroke}
                  name={t('TeamKeyActivityBarChartTooltip.totalCommitted')}
                />
                :<span className="CustomTooltip__value">{item.value}</span>
              </div>
            );
          }
          return (
            <div key={`${i}-${item.name}`}>
              <ChartLegend
                color={item.color}
                borderColor={item.stroke}
                name={t('TeamKeyActivityBarChartTooltip.totalAchieved')}
              />
              :<span className="CustomTooltip__value">{item.value}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
