import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressCard } from '../progressCard/ProgressCard';

interface Props {
  SvgIcon: ComponentType;
  iconColor: string;
  daysSinceLastMeeting?: number;
  totalMembers: number;
  totalActiveMembers: number;
  loading?: boolean;
}

export const TeamActivityProgressCard = ({
  SvgIcon,
  iconColor,
  daysSinceLastMeeting,
  totalMembers,
  totalActiveMembers,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ProgressCard SvgIcon={SvgIcon} color={iconColor}>
      <div className="fullWidth flx flx--column ">
        <div className="flx flx--jc-space-between">
          <h4>{t('TeamActivityProgressCard.accMeetingText')}</h4>
          <h4>
            {daysSinceLastMeeting === undefined ? '-' : daysSinceLastMeeting}
          </h4>
        </div>

        <div className="flx flx--jc-space-between mt--s">
          <h5 className="txt--secondary">
            {t('TeamActivityProgressCard.activeMembers')}:
          </h5>
          <h5 className="txt--secondary">
            {totalActiveMembers}/{totalMembers}
          </h5>
        </div>
      </div>
    </ProgressCard>
  );
};
