import Icon from '@ant-design/icons';

const MicOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.00016 8.33337C4.44461 8.33337 3.97238 8.13893 3.5835 7.75004C3.19461 7.36115 3.00016 6.88893 3.00016 6.33337V2.33337C3.00016 1.77782 3.19461 1.3056 3.5835 0.916707C3.97238 0.527818 4.44461 0.333374 5.00016 0.333374C5.55572 0.333374 6.02794 0.527818 6.41683 0.916707C6.80572 1.3056 7.00016 1.77782 7.00016 2.33337V6.33337C7.00016 6.88893 6.80572 7.36115 6.41683 7.75004C6.02794 8.13893 5.55572 8.33337 5.00016 8.33337ZM4.3335 13V10.95C3.17794 10.7945 2.22238 10.2778 1.46683 9.40004C0.711274 8.52226 0.333496 7.50004 0.333496 6.33337H1.66683C1.66683 7.2556 1.99183 8.04171 2.64183 8.69171C3.29183 9.34171 4.07794 9.66671 5.00016 9.66671C5.92238 9.66671 6.7085 9.34171 7.3585 8.69171C8.0085 8.04171 8.3335 7.2556 8.3335 6.33337H9.66683C9.66683 7.50004 9.28905 8.52226 8.5335 9.40004C7.77794 10.2778 6.82238 10.7945 5.66683 10.95V13H4.3335ZM5.00016 7.00004C5.18905 7.00004 5.34738 6.93615 5.47516 6.80837C5.60294 6.6806 5.66683 6.52226 5.66683 6.33337V2.33337C5.66683 2.14449 5.60294 1.98615 5.47516 1.85837C5.34738 1.7306 5.18905 1.66671 5.00016 1.66671C4.81127 1.66671 4.65294 1.7306 4.52516 1.85837C4.39738 1.98615 4.3335 2.14449 4.3335 2.33337V6.33337C4.3335 6.52226 4.39738 6.6806 4.52516 6.80837C4.65294 6.93615 4.81127 7.00004 5.00016 7.00004Z" />
  </svg>
);

export const MicOutlineIcon = (props: any) => (
  <Icon component={MicOutlineIconSvg} {...props} />
);
