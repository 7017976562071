import { useTranslation } from 'react-i18next';
import { CollapseCardSkeleton } from '../../../../team/overview/components/collapseCard/CollapseCard.skeleton';

export const GlobalMigSectionSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flx flx--jc-space-between">
        <h2>{t('GlobalMigSection.title')}</h2>
      </div>

      <div className="mt">
        <CollapseCardSkeleton className="mb--l" />
        <CollapseCardSkeleton />
      </div>
    </div>
  );
};
