import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const TeamActivityIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 22"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 22V20H18V22H2ZM2 2V0H18V2H2ZM10 12C10.8333 12 11.5417 11.7083 12.125 11.125C12.7083 10.5417 13 9.83333 13 9C13 8.16667 12.7083 7.45833 12.125 6.875C11.5417 6.29167 10.8333 6 10 6C9.16667 6 8.45833 6.29167 7.875 6.875C7.29167 7.45833 7 8.16667 7 9C7 9.83333 7.29167 10.5417 7.875 11.125C8.45833 11.7083 9.16667 12 10 12ZM2 19C1.45 19 0.979333 18.8043 0.588 18.413C0.196 18.021 0 17.55 0 17V5C0 4.45 0.196 3.97933 0.588 3.588C0.979333 3.196 1.45 3 2 3H18C18.55 3 19.021 3.196 19.413 3.588C19.8043 3.97933 20 4.45 20 5V17C20 17.55 19.8043 18.021 19.413 18.413C19.021 18.8043 18.55 19 18 19H2ZM3.75 17C4.5 16.0667 5.40833 15.3333 6.475 14.8C7.54167 14.2667 8.71667 14 10 14C11.2833 14 12.4583 14.2667 13.525 14.8C14.5917 15.3333 15.5 16.0667 16.25 17H18V5H2V17H3.75ZM6.7 17H13.3C12.8167 16.6667 12.296 16.4167 11.738 16.25C11.1793 16.0833 10.6 16 10 16C9.4 16 8.821 16.0833 8.263 16.25C7.70433 16.4167 7.18333 16.6667 6.7 17ZM10 10C9.71667 10 9.47933 9.904 9.288 9.712C9.096 9.52067 9 9.28333 9 9C9 8.71667 9.096 8.479 9.288 8.287C9.47933 8.09567 9.71667 8 10 8C10.2833 8 10.521 8.09567 10.713 8.287C10.9043 8.479 11 8.71667 11 9C11 9.28333 10.9043 9.52067 10.713 9.712C10.521 9.904 10.2833 10 10 10Z" />
  </svg>
);

export const TeamActivityIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={TeamActivityIconSvg} {...props} />
);
