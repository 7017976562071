type Nullish = null | undefined;
export function roundToDecimals(value: number, decimals?: number): number;
export function roundToDecimals(value: null, decimals?: number): null;
export function roundToDecimals(value: undefined, decimals?: number): undefined;
export function roundToDecimals(
  value: number | undefined,
  decimals?: number
): number | undefined;
export function roundToDecimals(
  value: number | null,
  decimals?: number
): number | null;
export function roundToDecimals(
  value: number | Nullish,
  decimals?: number
): number | Nullish;
export function roundToDecimals(
  value: number | Nullish,
  decimals: number = 2
): any {
  if (value == null) return value;
  return parseFloat(value.toFixed(decimals));
}

export const roundToFloor = (value: number | Nullish) => {
  if (value == null) return value;
  return Math.floor(value);
};
