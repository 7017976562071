import './CollapseCard.less';
import cx from 'classnames';
import { CollapseCardHeaderSkeleton } from './CollapseCardHeader.skeleton';

interface Props {
  className?: string;
}

export const CollapseCardSkeleton = ({ className }: Props) => {
  return (
    <div className={cx('CollapseCard', className)}>
      <CollapseCardHeaderSkeleton />
    </div>
  );
};
