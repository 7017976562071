import {
  AlertOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { notification } from 'antd';
import { ArgsProps } from 'antd/es/notification/interface';

type NotificationType = 'success' | 'error' | 'warning' | 'info';

const applyIcon = (type: NotificationType) => {
  switch (type) {
    case 'success':
      return <CheckCircleOutlined />;
    case 'error':
      return <AlertOutlined />;
    case 'warning':
      return <WarningOutlined />;
    case 'info':
      return <InfoCircleOutlined />;
    default:
      return <InfoCircleOutlined />;
  }
};

const applyStyle = (type: NotificationType): { color: string } => {
  switch (type) {
    case 'success':
      return { color: '#52c41a' };
    case 'error':
      return { color: '#f5222d' };
    case 'warning':
      return { color: '#faad14' };
    case 'info':
      return { color: '#1890ff' };
  }
};

export const fetchNotificationProperties = (type: NotificationType) => {
  return {
    icon: applyIcon(type),
    style: applyStyle(type),
    duration: type === 'error' ? 0 : 5, // 0 means no auto-close - errors stay until dismissed by user.
  };
};

export const showNotification = (
  type: NotificationType,
  options: ArgsProps
) => {
  notification[type]({
    ...fetchNotificationProperties(type),
    ...options,
  });
};
