import Icon from '@ant-design/icons';

const GraduateHatOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.99984 12L3.33317 9.46667V5.46667L0.666504 4L7.99984 0L15.3332 4V9.33333H13.9998V4.73333L12.6665 5.46667V9.46667L7.99984 12ZM7.99984 6.46667L12.5665 4L7.99984 1.53333L3.43317 4L7.99984 6.46667ZM7.99984 10.4833L11.3332 8.68333V6.16667L7.99984 8L4.6665 6.16667V8.68333L7.99984 10.4833Z" />
  </svg>
);

export const GraduateHatOutlineIcon = (props: any) => (
  <Icon component={GraduateHatOutlineIconSvg} {...props} />
);
