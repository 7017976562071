import { useTranslation } from 'react-i18next';
import placeholderUrl from '../../../../../../images/placeholder_mig.svg';

export const GlobalMigsEmptyPlaceholder = () => {
  const { t } = useTranslation();
  return (
    <div className="flx mb--xxl mt--xxl" style={{ marginLeft: 200 }}>
      <img src={placeholderUrl} height="160px" />
      <div className="flx flx--column ml--xxl">
        <h3 className="mb--l">{t('GlobalMigsEmptyPlaceholder.title')}</h3>
        <p className="txt--secondary" style={{ maxWidth: 500 }}>
          {t('GlobalMigsEmptyPlaceholder.description')}
        </p>
        {/* <div className="mt--auto">
          <Btn type="primary" size="middle" href="#" target="_blank">
            {t('GlobalMigsEmptyPlaceholder.learnMore')}
          </Btn>
        </div> */}
      </div>
    </div>
  );
};
