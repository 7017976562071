import React, { useState, useContext, createContext, ReactNode } from 'react';

interface Context {
  steps: WizardStep[];
  currentStepPath: number[];
  currentSteps: WizardStep[];
  activeStep: WizardComponentStep;
  goToStep: (nextPath: number[]) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  isLocked: (stepPath: number[]) => boolean;
  unlockStep: (stepPath: number[]) => void;
  lockStep: (stepPath: number[]) => void;
}

export const WizardNavigationContext = createContext<Context>({} as Context);
export const useWizardNavigation = () => useContext(WizardNavigationContext);

interface ConfigComponentStep {
  isMandatory?: boolean;
  title: ReactNode;
  description?: ReactNode;
  component: (args?: any) => React.ReactNode;
  skipStep?: boolean;
  key: string;
  type: 'ConfigComponentStep';
}

interface ConfigGroupStep {
  title: ReactNode;
  description?: ReactNode;
  skipStep?: boolean;
  subSteps: ConfigStep[];
  key: string;
  type: 'ConfigGroupStep';
}

export type ConfigStep = ConfigGroupStep | ConfigComponentStep;

interface Props {
  config: ConfigStep[];
  defaultStepKey?: string | null;
  children: ReactNode;
}

interface WizardGroupStep {
  title: ReactNode;
  description?: ReactNode;
  stepPath: number[];
  subSteps: WizardStep[];
  key: string;
  type: 'WizardGroupStep';
}

interface WizardComponentStep {
  isMandatory?: boolean;
  description?: ReactNode;
  title: ReactNode;
  stepPath: number[];
  component: (args?: any) => React.ReactNode;
  parentInfo?: { title: ReactNode; numberOfSteps: number } | null;
  index: number;
  nextStep: number[] | null;
  prevStep: number[] | null;
  key: string;
  type: 'WizardComponentStep';
}

export type WizardStep = WizardGroupStep | WizardComponentStep;

const configBuilder = (
  config: ConfigStep[],
  parentPath = [] as number[],
  parentNext?: () => number[] | null,
  parentPrev?: () => number[] | null,
  parentInfo?: { title: ReactNode; numberOfSteps: number } | null
): WizardStep[] => {
  return config
    .filter((c) => !c.skipStep)
    .map((c, index, myArray) => {
      const path = [...parentPath, index];

      const nextStep = () => {
        if (index < myArray.length - 1) {
          return [...parentPath, index + 1];
        }
        return parentNext ? parentNext() : null;
      };

      const prevStep = () => {
        if (index > 0) {
          return [...parentPath, index - 1];
        }
        return parentPrev ? parentPrev() : null;
      };
      if (c.type === 'ConfigComponentStep') {
        return {
          title: c.title,
          description: c.description,
          stepPath: path,
          component: c.component,
          isMandatory: c.isMandatory,
          nextStep: nextStep(),
          prevStep: prevStep(),
          parentInfo: parentInfo,
          index: index,
          key: c.key,
          type: 'WizardComponentStep',
        };
      } else {
        return {
          title: c.title,
          description: c.description,
          subSteps: configBuilder(c.subSteps, path, nextStep, prevStep, {
            title: c.title,
            numberOfSteps: c.subSteps.filter((s) => !s.skipStep).length,
          }),
          stepPath: path,
          key: c.key,
          type: 'WizardGroupStep',
        };
      }
    });
};

export const WizardNavigationProvider: React.FC<Props> = ({
  children,
  config,
  defaultStepKey,
}) => {
  const wizardSteps = configBuilder(config);

  const [currentStepPath, setCurrentStepPath] = useState(() =>
    defaultStepKey
      ? getStepByKey(wizardSteps, defaultStepKey)?.stepPath ?? [0]
      : [0]
  );
  const [actionRequiredSteps, setActionRequiredSteps] = useState(
    getMandatorySteps(wizardSteps)
  );

  const activeComponentStep = getActiveComponentStep(
    wizardSteps,
    currentStepPath
  );

  const goToStep = (nextPath: number[]) => {
    const targetStep = getTargetStep(wizardSteps, nextPath);

    if (targetStep.type === 'WizardGroupStep') {
      goToStep([...nextPath, 0]);
    } else {
      setCurrentStepPath(nextPath);
    }
  };

  const goToNextStep = () => {
    const nextPath = activeComponentStep.nextStep;

    if (!nextPath) return;
    goToStep(nextPath);
  };

  const goToPreviousStep = () => {
    const prevPath = activeComponentStep.prevStep;
    if (!prevPath) return;

    const targetStep = getTargetStep(wizardSteps, prevPath);

    if (targetStep.type === 'WizardGroupStep') {
      setCurrentStepPath([...prevPath, targetStep.subSteps.length - 1]);
    } else {
      setCurrentStepPath(prevPath);
    }
  };

  const getCurrentSteps = () => {
    return currentStepPath.reduce((acc, _, index) => {
      const currentStep = currentStepPath.slice(
        0,
        currentStepPath.length - index
      );
      return [getTargetStep(wizardSteps, currentStep), ...acc];
    }, [] as WizardStep[]);
  };

  const isLocked = (stepPath: number[]) => {
    const key = keyFromPath(stepPath);
    return Object.entries(actionRequiredSteps).some(
      ([path, isMandatory]) => path.localeCompare(key) < 0 && isMandatory
    );
  };

  const unlockStep = (stepPath: number[]) => {
    setActionRequiredSteps({
      ...actionRequiredSteps,
      [keyFromPath(stepPath)]: false,
    });
  };

  const lockStep = (stepPath: number[]) => {
    setActionRequiredSteps({
      ...actionRequiredSteps,
      [keyFromPath(stepPath)]: true,
    });
  };

  return (
    <WizardNavigationContext.Provider
      value={{
        steps: wizardSteps,
        currentStepPath,
        currentSteps: getCurrentSteps(),
        activeStep: activeComponentStep,
        goToStep,
        goToNextStep,
        goToPreviousStep,
        isLocked,
        unlockStep,
        lockStep,
      }}
    >
      {children}
    </WizardNavigationContext.Provider>
  );
};
function getActiveComponentStep(
  conf: WizardStep[],
  currentStep: number[]
): WizardComponentStep {
  const [step] =
    currentStep.reduce((acc, step) => {
      const activeStep = acc[step];
      if (activeStep.type === 'WizardGroupStep') return activeStep.subSteps;
      return [acc[step]];
    }, conf) ?? [];
  return step as WizardComponentStep;
}

function getTargetStep(conf: WizardStep[], targetStep: number[]) {
  const [step] =
    targetStep.reduce((acc, step, index) => {
      const isDeepest = targetStep.length - 1 === index;
      const activeStep = acc[step];
      if (activeStep.type === 'WizardGroupStep' && !isDeepest) {
        return activeStep.subSteps;
      }
      return [acc[step]];
    }, conf) ?? [];
  return step as WizardStep;
}

const getStepByKey = (steps: WizardStep[], key: string): WizardStep | null => {
  let subReturn = null;
  for (const step of steps) {
    if (subReturn != null) return subReturn;
    if (step.key === key) return step;
    if (step.type === 'WizardGroupStep') {
      subReturn = getStepByKey(step.subSteps, key);
    }
  }

  return subReturn;
};

const getMandatorySteps = (
  steps: WizardStep[]
): { [path: string]: boolean } => {
  return steps.reduce(
    (acc, step) => {
      if (step.type === 'WizardGroupStep') {
        return { ...acc, ...getMandatorySteps(step.subSteps) };
      }
      return { ...acc, [keyFromPath(step.stepPath)]: !!step.isMandatory };
    },
    {} as { [path: string]: boolean }
  );
};

const keyFromPath = (stepPath: number[]) => stepPath.join('-');
