import { gql, useQuery } from '@apollo/client';
import {
  Action,
  PermittedToPerformActionForTeamDocument,
  TeamResourceType,
} from '../generated/graphql';

export const usePermissionForTeams = (
  resourceType: TeamResourceType,
  action: Action
) => {
  return useQuery(PermittedToPerformActionForTeamDocument, {
    variables: {
      requestPermission: {
        action,
        resourceType,
      },
    },
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PERMISSIONS_FOR_TEAM = gql`
  query permittedToPerformActionForTeam(
    $requestPermission: PermissionsForTeamsInput!
  ) {
    permittedToPerformActionForTeam(requestPermission: $requestPermission) {
      permittedResources {
        teamId
        resourceType
        action
      }
    }
  }
`;
