import {
  CalendarOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Drawer, Empty, List, Popconfirm, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayDateRange } from '../../../../../components/DisplayDateRange';
import { ErrorAlert } from '../../../../../components/ErrorAlert';
import {
  DeleteOffPeriodDocument,
  GetOffPeriodsDocument,
} from '../../../../../generated/graphql';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { useTeamOffPeriods } from '../../../common/hooks/useTeamOffPeriods';
import { CreateOffWeek } from './CreateOffWeek';
import { Btn } from '../../../../../components/Button';

interface Props {
  teamId: string;
}

export const ViewOffWeeks = ({ teamId }: Props) => {
  const { t } = useTranslation();
  const [showOffWeeks, setShowOffWeeks] = useState(false);
  const { activePeriods, upcomingPeriods, endedPeriods, error } =
    useTeamOffPeriods(teamId);

  return (
    <div>
      <Drawer
        title={t('ViewOffWeeks.title')}
        open={showOffWeeks}
        width={420}
        footer={false}
        destroyOnClose
        onClose={() => setShowOffWeeks(false)}
      >
        <div className="mt--l">
          <div className="mb--l">
            <div className="flx flx--jc-space-between">
              <Typography.Text strong>
                {t('ViewOffWeeks.activeTitle')}
              </Typography.Text>
              <CreateOffWeek
                teamId={teamId}
                buttonType="primary"
                buttonSize="small"
              />
            </div>
            <List
              itemLayout="horizontal"
              className="mt--s"
              dataSource={activePeriods}
              bordered
              locale={{
                emptyText: (
                  <Empty
                    image={false}
                    imageStyle={{ height: 0 }}
                    description={
                      <span className="center-content pb pt">
                        <CheckCircleOutlined
                          style={{ fontSize: '32px' }}
                          className="mb"
                        />
                        {t('ViewOffWeeks.noActiveOffPeriods')}
                      </span>
                    }
                  />
                ),
              }}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <DisplayDateRange
                        startDate={item.startDate}
                        endDate={item.endDate}
                      />
                    }
                  />
                  <ViewOffWeeksPopConfirm
                    teamId={teamId}
                    offPeriodId={item.id}
                  />
                </List.Item>
              )}
            />
          </div>
          <div className="mb--l">
            <Typography.Text strong>{t('common.upcoming')}</Typography.Text>
            <List
              className="mt--s"
              itemLayout="horizontal"
              dataSource={upcomingPeriods}
              bordered
              locale={{
                emptyText: (
                  <Empty
                    imageStyle={{ height: 60 }}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      <span className="center-content pb pt">
                        {t('ViewOffWeeks.noUpcomingPeriods')}
                      </span>
                    }
                  />
                ),
              }}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <DisplayDateRange
                        startDate={item.startDate}
                        endDate={item.endDate}
                      />
                    }
                  />
                  <ViewOffWeeksPopConfirm
                    teamId={teamId}
                    offPeriodId={item.id}
                  />
                </List.Item>
              )}
            />
          </div>
          <div>
            <Typography.Text strong>
              {t('ViewOffWeeks.endedTitle')}
            </Typography.Text>
            <List
              className="mt--s"
              itemLayout="horizontal"
              dataSource={endedPeriods}
              bordered
              locale={{
                emptyText: (
                  <Empty
                    imageStyle={{ height: 60 }}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      <span className="center-content pb pt">
                        {t('ViewOffWeeks.noEndedPeriods')}
                      </span>
                    }
                  />
                ),
              }}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <DisplayDateRange
                        startDate={item.startDate}
                        endDate={item.endDate}
                      />
                    }
                  />
                  <ViewOffWeeksPopConfirm
                    teamId={teamId}
                    offPeriodId={item.id}
                  />
                </List.Item>
              )}
            />
          </div>
          <ErrorAlert title="" error={error} />
        </div>
      </Drawer>
      <Btn
        icon={<CalendarOutlined />}
        type="link"
        onClick={() => setShowOffWeeks(true)}
      >
        {t('TeamOverview.viewOffWeeks')}
      </Btn>
    </div>
  );
};

interface ViewOffWeeksPopConfirmProps {
  teamId: string;
  offPeriodId: string;
}

const ViewOffWeeksPopConfirm = ({
  teamId,
  offPeriodId,
}: ViewOffWeeksPopConfirmProps) => {
  const { t } = useTranslation();

  const [deletePeriod, { loading }] = useMutation(DeleteOffPeriodDocument, {
    onCompleted: () => {
      showNotification('success', {
        message: (
          <strong>{t('ViewOffWeeksPopConfirm.removeSuccessful')}</strong>
        ),
      });
    },
    onError: () => {
      showNotification('error', {
        message: <strong>{t('ViewOffWeeksPopConfirm.errorOnRemove')}</strong>,
      });
    },
    refetchQueries: () => [
      {
        query: GetOffPeriodsDocument,
        variables: { teamId },
      },
    ],
  });

  const handleDeleteOffPeriod = (offPeriodId: string) => {
    deletePeriod({
      variables: {
        teamId,
        offPeriodId,
      },
    });
  };

  return (
    <Popconfirm
      placement="topRight"
      title={t('ViewOffWeeksPopConfirm.confirmText')}
      onConfirm={() => handleDeleteOffPeriod(offPeriodId)}
      okText="Yes"
      cancelText="No"
    >
      <Btn type="text" icon={<DeleteOutlined />} loading={loading} />
    </Popconfirm>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DELETE_OFF_PERIOD = gql`
  mutation deleteOffPeriod($teamId: ID!, $offPeriodId: ID!) {
    deleteOffPeriod(teamId: $teamId, offPeriodId: $offPeriodId) {
      id
      teamId
      startDate
      endDate
    }
  }
`;
