import { gql, useQuery } from '@apollo/client';
import { Layout, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetWeeklyKeyActivityDocument,
  TimePeriodFilter,
} from '../../../generated/graphql';
import { useTeamCommitmentAndAchievementV2 } from '../../../hooks/useTeamCommitmentAndAchievementV2';
import { useTeamKeyActivityLog } from '../../../hooks/useTeamKeyActivityLog';
import { weeklyKeyActivityDTOtoAkpiData } from '../../../services/akpiUtils';
import { ErrorAlert } from '../../ErrorAlert';
import { FullscreenModal } from '../../FullscreenModal';
import { MigRelations } from '../../mig/MigCard/MigDescription/MigRelations';
import { RenderedMarkdown } from '../../RenderedMarkdown';
import { ScrollableCard } from '../../ScrollableCard';
import { AkpiCardLarge } from '../AkpiCardLarge';
import { AkpiAchievementList } from './akpiDetailedView/AkpiAchievementList';
import { CommitmentAndAchievementCard } from './akpiDetailedView/CommitmentAndAchievementCard';
import { Btn } from '../../Button';
import { ga4 } from '../../../services/ga4/ga4';

interface Props {
  weeklyKeyActivityId: string;
  teamId: string;
  tenantId?: string;
  onClose: () => void;
  defaultTimePeriodFilter?: TimePeriodFilter;
}

export const AkpiDetailedView = ({
  weeklyKeyActivityId,
  teamId,
  tenantId,
  onClose,
  defaultTimePeriodFilter,
}: Props) => {
  useEffect(() => {
    ga4.gtag('event', 'open_weekly_key_activity_detailed_view');
  }, []);

  const { t } = useTranslation();
  const { loading, data, error } = useQuery(GetWeeklyKeyActivityDocument, {
    variables: { teamId, tenantId, weeklyKeyActivityId },
  });

  const [selectedPeriod, setSelectedPeriod] = useState(defaultTimePeriodFilter);

  const handlePeriodChange = (value?: TimePeriodFilter) => {
    setSelectedPeriod(value);
  };

  const teamCommitmentAndAchievement = useTeamCommitmentAndAchievementV2({
    teamId,
    weeklyKeyActivityId: weeklyKeyActivityId,
    timePeriodFilter: selectedPeriod,
    tenantId,
  });

  const teamActivities = useTeamKeyActivityLog({
    teamId,
    weeklyKeyActivityId,
    timePeriodFilter: selectedPeriod,
    tenantId,
  });

  const authorized =
    teamActivities.authorized || teamCommitmentAndAchievement.authorized;

  const weeklyKeyActivity =
    data && weeklyKeyActivityDTOtoAkpiData(data.weeklyKeyActivity);

  return (
    <FullscreenModal
      onCancel={onClose}
      keyboard={true}
      maskClosable={true}
      width="min(100%, 1280px)"
      title={
        <>
          <Typography.Text type="secondary" className="mr--s">
            {weeklyKeyActivity?.team.name} |
          </Typography.Text>
          {weeklyKeyActivity?.name}
        </>
      }
      styles={{ body: { padding: 0 } }}
      footer={
        <Btn type="primary" ghost onClick={onClose}>
          {t('AkpiDetailedView.closeButton')}
        </Btn>
      }
    >
      <Layout
        style={{
          minHeight: '100%',
          padding: 16,
        }}
      >
        <div
          style={{
            minHeight: '100%',
            flexDirection: 'row',
            display: 'flex',
          }}
        >
          <Layout.Content className="mr--s">
            <div className="mb--s bg--white">
              {loading && (
                <div className="AkpiCardLarge__loader">
                  <Spin size="large" delay={200} />
                </div>
              )}

              {!weeklyKeyActivity && !loading && (
                <p>{t('AkpiDetailedView.noData')}</p>
              )}

              {weeklyKeyActivity && (
                <AkpiCardLarge akpi={weeklyKeyActivity} height={200} />
              )}
            </div>
            {authorized && (
              <CommitmentAndAchievementCard
                onPeriodChange={handlePeriodChange}
                timePeriodFilter={selectedPeriod}
                teamCommitmentAndAchievements={teamCommitmentAndAchievement}
              />
            )}
          </Layout.Content>
          <div
            style={{ width: '40%', display: 'flex', flexDirection: 'column' }}
          >
            <ScrollableCard
              title={t('AkpiDetailedView.about')}
              className="mb--s"
              style={{ maxHeight: '13rem' }}
            >
              <Typography.Text strong>
                {t('common.description')}
              </Typography.Text>
              <RenderedMarkdown
                html={weeklyKeyActivity?.descriptionHtml}
                emptyPlaceholder="-"
              />
              <Typography.Text strong>
                {t('AkpiDetailedView.definitionOfDone')}
              </Typography.Text>
              <RenderedMarkdown
                html={weeklyKeyActivity?.acceptanceCriteriaHtml}
                emptyPlaceholder="-"
              />
              <Typography.Text strong>
                {t('AkpiDetailedView.migRelations')}
              </Typography.Text>
              <MigRelations
                relations={weeklyKeyActivity?.supportsMigsDetails}
              />
            </ScrollableCard>

            {authorized && (
              <AkpiAchievementList
                teamActivities={teamActivities}
                onPeriodChange={handlePeriodChange}
                timePeriodFilter={selectedPeriod}
              />
            )}
          </div>
        </div>
      </Layout>
      {error && (
        <ErrorAlert
          error={error}
          title={t('AkpiDetailedView.fetchAkpiError')}
        />
      )}
    </FullscreenModal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_AKPI = gql`
  query getWeeklyKeyActivity(
    $teamId: ID!
    $weeklyKeyActivityId: ID!
    $tenantId: ID
  ) {
    weeklyKeyActivity(
      teamId: $teamId
      weeklyKeyActivityId: $weeklyKeyActivityId
      tenantId: $tenantId
    ) {
      id
      name
      descriptionHtml
      acceptanceCriteriaHtml
      currentPeriodIndex
      archivedAt
      startDate
      endDate
      unit
      periodDataWithCommitment {
        targetDate
        goal
        actual
        committed
        historic
      }
      supportsMigsDetails {
        id
        name
        lastOutcomeDate
        domainId {
          itemId
          tenantId
          teamId
        }
        team {
          id
          name
        }
      }
      teamId
      team {
        id
        name
      }
    }
  }
`;
