import './TeamGantishLegend.less';
import { TeamGantishMilestoneStats } from './GantishLegend/TeamGantishMilestoneStats';
import { TeamGantishLegendRow } from './GantishLegend/TeamGantishLegendRow';
import { useTranslation } from 'react-i18next';

export const TeamGantishLegendSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className="TeamGantishLegend">
      <div className="TeamGantishLegend__row">
        <TeamGantishMilestoneStats.Skeleton.Header />
      </div>

      <div className="TeamGantishLegend__row">
        <h4>
          <TeamGantishLegendRow.Skeleton />
        </h4>
        <TeamGantishMilestoneStats.Skeleton />
      </div>

      <div className="TeamGantishLegend__row TeamGantishLegend__row--spacer">
        <h4 className="mb">{t('TeamGantishLegend.otherMilestones')}</h4>
      </div>

      <TeamGantishLegendRow.Skeleton />
      <TeamGantishLegendRow.Skeleton />
    </div>
  );
};
