import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  GetTeamMembersV2HookDocument,
  UserTeamRole,
} from '../generated/graphql';

export const useTeamMembers = (teamId: string, tenantId?: string) => {
  const { data, loading, error } = useQuery(GetTeamMembersV2HookDocument, {
    variables: { teamId, tenantId },
  });

  const usersInTeam = data?.membersV2.members ?? [];

  const isMember = (userId: string) =>
    loading ? null : usersInTeam.some((u) => u.user.id === userId);

  return {
    data,
    isMember,
    loading,
    error,
    usersInTeam,
  };
};

export const useTeamMembersLazy = (teamId: string) => {
  const [fetchMembers, { data, loading, error }] = useLazyQuery(
    GetTeamMembersV2HookDocument,
    {
      variables: { teamId },
    }
  );

  return {
    fetchMembers,
    teamMembers: data,
    loading,
    error,
  };
};

export type TeamUser = {
  id: string;
  name: string | null;
  displayName: string | null;
  email: string;
  initials: string;
};

export type TeamMember = {
  teamId: string;
  teamRole: UserTeamRole;
  sprintAdmin: boolean;
  user: TeamUser;
};

export const GET_TEAM_MEMBERS_V2_HOOK = gql`
  query getTeamMembersV2Hook($teamId: ID!, $tenantId: ID) {
    membersV2(
      teamId: $teamId
      filter: { archived: false }
      tenantId: $tenantId
    ) {
      teamId
      teamName
      members {
        teamId
        teamRole
        sprintAdmin
        user {
          id
          name
          displayName
          email
          initials
        }
      }
    }
  }
`;
