import { Select } from 'antd';
import { stringSort } from '../../../../../../services/stringSort';

interface Props {
  milestones?: {
    id: string;
    name: string;
    metadata: { completedAt: string | null; archived: boolean };
  }[];
  selectedMilestoneIds?: string[];
  value?: string;
  onChange?: (migId: string) => void;
  className?: string;
  disabled?: boolean;
  placeHolder?: string;
  loading?: boolean;
}

export const TeamMilestoneSelector = ({
  milestones,
  selectedMilestoneIds,
  loading,
  onChange,
  className,
  disabled,
  value,
  placeHolder,
}: Props) => {
  const options = milestones
    ?.toSorted((a, b) => {
      return stringSort(a.name, b.name);
    })
    .map((t) => ({
      value: t.id,
      disabled: selectedMilestoneIds?.includes(t.id),
      label: t.name,
      searchText: t.name,
    }));

  return (
    <Select
      allowClear
      loading={loading}
      showSearch
      placeholder={placeHolder}
      optionFilterProp="children"
      disabled={disabled}
      onChange={onChange}
      value={value}
      className={className}
      options={options}
      filterOption={(input, option) =>
        option?.searchText?.toLowerCase().includes(input.toLowerCase()) ?? false
      }
    />
  );
};
