import { PlusOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { AutoComplete, Spin } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AssignUserToTeamDocument,
  GetTeamMembersV2HookDocument,
  GetUsersForTeamAssignmentDocument,
} from '../../../generated/graphql';
import { TeamMember } from '../../../hooks/useTeamMembersV2';
import { showNotification } from '../../../services/fetchNotificationProperties';
import { stringSort } from '../../../services/stringSort';
import './AssignUserSearch.less';
import { CreateTeamMemberModal } from './CreateTeamMemberModal';
import { Btn } from '../../../components/Button';
import { UserAvatar } from '../../../components/UserAvatar';
import { DisplayName } from '../../../components/DisplayName';
import { P, match } from 'ts-pattern';
import cx from 'classnames';

interface Props {
  teamId: string;
  members?: TeamMember[] | null;
  tenantId?: string;
}

const CREATE_NEW_USER = 'CREATE_NEW_USER';

export const AssignUserSearch = ({ teamId, members, tenantId }: Props) => {
  const { t } = useTranslation();
  const [showSearch, setShowSearch] = useState(false);
  const [filterQuery, setFilterQuery] = useState('');
  const [showCreateMember, setShowCreateMember] = useState(false);

  const { data, loading } = useQuery(GetUsersForTeamAssignmentDocument, {
    variables: { tenantId },
  });
  const [addUser, assignRequest] = useMutation(AssignUserToTeamDocument, {
    refetchQueries: () => [
      {
        query: GetTeamMembersV2HookDocument,
        variables: { teamId, tenantId },
      },
    ],
    onError: () => {
      showNotification('error', {
        message: t('AssignUserSearch.error', filterQuery),
      });
    },
  });
  const filterValueInLowerCase = filterQuery.toLowerCase();
  const users =
    data?.usersPaginated.content
      .filter(
        (u) =>
          u.email.toLowerCase().includes(filterValueInLowerCase) ||
          u.name?.toLowerCase().includes(filterValueInLowerCase) ||
          u.displayName?.toLowerCase().includes(filterValueInLowerCase)
      )
      .sort((a, b) => stringSort(a.email, b.email)) ?? [];

  const { Option } = AutoComplete;
  const memberEmails = members?.map((m) => m.user.email);

  const drowndownContent = match([{ loading }, { users }])
    .with([{ loading: true }, { users: P.any }], () => (
      <Option title="loading" key="loading" value="loading" disabled>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin />
        </div>
      </Option>
    ))
    .with([{ loading: false }, { users: [] }], () => (
      <Option key="no results" value="no results" disabled>
        {t('AssignUserSearch.noMatch')}
      </Option>
    ))
    .otherwise(() =>
      users.map((u) => {
        const isAlreadyMember = memberEmails?.includes(u.email);
        return (
          <Option
            title={u.email}
            key={u.email}
            value={u.email}
            disabled={isAlreadyMember}
          >
            <div
              className={cx('AssignUserSearch__user', {
                'AssignUserSearch__user--alreadyMember': isAlreadyMember,
              })}
            >
              <span className="AssignUserSearch__userAvatar">
                <UserAvatar user={u} />
              </span>
              <div className="ml pr--s ellipsis">
                <DisplayName user={u} />
                <div className="font-size--sm txt--secondary ellipsis">
                  {u.email}
                </div>
              </div>
              <div>
                {isAlreadyMember && (
                  <span className="txt--secondary">
                    {t('AssignUserSearch.alreadyMember')}
                  </span>
                )}
              </div>
            </div>
          </Option>
        );
      })
    );

  const listUsersWithCreateButton = [
    <Option
      title={t('AssignUserSearch.createNewMemberOption')}
      key="create-user"
      value={CREATE_NEW_USER}
    >
      <div className="AssignUserSearch__createUserLink">
        {t('AssignUserSearch.createNew')}
      </div>
    </Option>,
  ].concat(drowndownContent);

  const userAlreadyInTeam = memberEmails?.includes(filterValueInLowerCase);
  const perfectMatch = users.some((u) => u.email === filterValueInLowerCase);

  const handleSelect = (value: string) => {
    if (value === CREATE_NEW_USER) {
      setShowCreateMember(true);
    } else {
      setFilterQuery(value);
    }
  };

  const handleAssignUser = () => {
    const selectedUser = users.find((u) => u.email === filterValueInLowerCase);
    if (selectedUser) {
      addUser({
        variables: {
          teamId,
          userId: selectedUser.id,
          tenantId,
        },
      }).then(() => {
        showNotification('success', {
          message: (
            <span>
              <strong>{selectedUser!.email} </strong>
              {t('AssignUserSearch.success')}
            </span>
          ),
        });
        setFilterQuery('');
      });
    } else {
      showNotification('warning', {
        message: 'Could not find selected user',
      });
    }
  };

  if (!showSearch)
    return (
      <Btn
        data-testid="open-searchbar-add-member-btn"
        icon={<PlusOutlined />}
        onClick={() => setShowSearch(true)}
      >
        {t('AssignUserSearch.addButton')}
      </Btn>
    );

  return (
    <>
      {showCreateMember && (
        <CreateTeamMemberModal
          teamId={teamId}
          tenantId={tenantId}
          value={filterQuery}
          onClose={() => {
            setShowCreateMember(false);
          }}
          onComplete={() => setFilterQuery('')}
        />
      )}
      <div style={{ display: 'flex' }}>
        <div style={{ width: 350, marginRight: '8px' }}>
          <AutoComplete
            defaultActiveFirstOption={false}
            value={filterQuery}
            defaultOpen={true}
            autoFocus={true}
            onSearch={setFilterQuery}
            placeholder={t('AssignUserSearch.searchPlaceholder')}
            // todo: proper typings for onSelect --> https://github.com/ant-design/ant-design/issues/34201
            onSelect={(s: any) => handleSelect(s as string)}
            style={{ width: '100%' }}
          >
            {listUsersWithCreateButton}
          </AutoComplete>
        </div>
        <Btn
          type="primary"
          loading={assignRequest.loading}
          data-testid="add-member-btn"
          disabled={!perfectMatch || userAlreadyInTeam}
          onClick={handleAssignUser}
        >
          {t('AssignUserSearch.confirm')}
        </Btn>
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LIST_USERS = gql`
  query getUsersForTeamAssignment($tenantId: ID) {
    usersPaginated(filter: { archived: false }, tenantId: $tenantId) {
      content {
        id
        email
        name
        displayName
        initials
        teamIds
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ASSIGN_USER_TO_TEAM = gql`
  mutation assignUserToTeam($teamId: ID!, $userId: ID!, $tenantId: ID) {
    assignUserToTeam(teamId: $teamId, userId: $userId, tenantId: $tenantId) {
      id
      membersV2 {
        teamId
        teamName
        members {
          teamId
          teamRole
          sprintAdmin
          user {
            id
            name
            displayName
            email
          }
        }
      }
    }
  }
`;
