import { Modal } from 'antd';
import { InitiativeNode } from '../../../../../../company/initiatives_old/initiativesPageV1/services/initiativeTreeNodeHelper';
import Table, { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import './SubInitiativesModal.less';
import { useTranslation } from 'react-i18next';
import { SubinitiativeIcon } from '../../../../../../../icons/initiativeIcons/SubinitiativeIcon';
import { useState } from 'react';

interface Props {
  hierarchicalInitiative: InitiativeNode;
}

const SubInitiativesModal = ({ hierarchicalInitiative }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const columns: ColumnsType<InitiativeNode> = [
    {
      title: 'Title',
      dataIndex: ['name'],
    },
    {
      title: 'Driven by',
      dataIndex: ['team', 'name'],
      render: (_, record) => {
        return (
          <Link
            className="TeamInitiativesCard__teamLink"
            to={'/team/' + record.team.id}
          >
            {record.team.name}
          </Link>
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={'Sub-initiatives'}
        footer={null}
      >
        <div className="TeamInitiativesCard__toInitiative mb">
          <span className="txt--secondary space--r">
            {t('TeamInitiativesCard.toInitiative')}
          </span>
          <Link
            className="TeamInitiativesCard__initiativeName"
            to={
              '/company/initiatives/' +
              hierarchicalInitiative.id +
              (hierarchicalInitiative.team.id
                ? `?teamId=${hierarchicalInitiative.team.id}`
                : '')
            }
          >
            {hierarchicalInitiative.name}
          </Link>
        </div>

        <div className="TeamInitiativesCard__table">
          <Table
            columns={columns}
            dataSource={hierarchicalInitiative.children ?? []}
            pagination={false}
            size={'small'}
            scroll={{ y: 240 }}
          />
        </div>
      </Modal>
      {hierarchicalInitiative.children ? (
        <div className="clickable" onClick={() => setOpen(true)}>
          <span className="space--r txt--secondary">
            {t('TeamInitiativesCard.subInitiatives')}
          </span>
          <span className="mr TeamInitiativesCardFooter__icon">
            <SubinitiativeIcon className="mr--xs" />
            {hierarchicalInitiative.subInitiativeIds.length}
          </span>
        </div>
      ) : (
        <div className="TeamInitiativesCardFooter__items-disabled">
          <span className="space--r">
            {t('TeamInitiativesCard.subInitiatives')}
          </span>
          <span className="mr ">
            <SubinitiativeIcon className="mr--xs" />
            {hierarchicalInitiative.subInitiativeIds.length}
          </span>
        </div>
      )}
    </div>
  );
};

export default SubInitiativesModal;
