import Icon from '@ant-design/icons';

const PlantOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.33333 12V6.68333C4.62222 6.68333 3.93889 6.54722 3.28333 6.275C2.62778 6.00278 2.05 5.61667 1.55 5.11667C1.05 4.61667 0.666667 4.03889 0.4 3.38333C0.133333 2.72778 0 2.04444 0 1.33333V0H1.33333C2.03333 0 2.71111 0.136111 3.36667 0.408333C4.02222 0.680556 4.6 1.06667 5.1 1.56667C5.44444 1.91111 5.73056 2.28889 5.95833 2.7C6.18611 3.11111 6.36111 3.55 6.48333 4.01667C6.53889 3.93889 6.6 3.86389 6.66667 3.79167C6.73333 3.71944 6.80556 3.64444 6.88333 3.56667C7.38333 3.06667 7.96111 2.68056 8.61667 2.40833C9.27222 2.13611 9.95556 2 10.6667 2H12V3.33333C12 4.04444 11.8639 4.72778 11.5917 5.38333C11.3194 6.03889 10.9333 6.61667 10.4333 7.11667C9.93333 7.61667 9.35833 8 8.70833 8.26667C8.05833 8.53333 7.37778 8.66667 6.66667 8.66667V12H5.33333ZM5.33333 5.33333C5.33333 4.8 5.23056 4.29167 5.025 3.80833C4.81944 3.325 4.52778 2.89444 4.15 2.51667C3.77222 2.13889 3.34167 1.84722 2.85833 1.64167C2.375 1.43611 1.86667 1.33333 1.33333 1.33333C1.33333 1.86667 1.43333 2.37778 1.63333 2.86667C1.83333 3.35556 2.12222 3.78889 2.5 4.16667C2.87778 4.54444 3.31111 4.83333 3.8 5.03333C4.28889 5.23333 4.8 5.33333 5.33333 5.33333ZM6.66667 7.33333C7.2 7.33333 7.70833 7.23333 8.19167 7.03333C8.675 6.83333 9.10556 6.54444 9.48333 6.16667C9.86111 5.78889 10.1528 5.35556 10.3583 4.86667C10.5639 4.37778 10.6667 3.86667 10.6667 3.33333C10.1333 3.33333 9.62222 3.43611 9.13333 3.64167C8.64444 3.84722 8.21111 4.13889 7.83333 4.51667C7.45556 4.89444 7.16667 5.325 6.96667 5.80833C6.76667 6.29167 6.66667 6.8 6.66667 7.33333Z" />
  </svg>
);

export const PlantOutlineIcon = (props: any) => (
  <Icon component={PlantOutlineIconSvg} {...props} />
);
