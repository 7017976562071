import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { CurrentSprintDocument } from '../generated/graphql';

export const useCurrentSprint = (teamId: string) => {
  const queryResponse = useQuery(CurrentSprintDocument, {
    variables: {
      teamId,
    },
  });

  return useMemo(
    () => ({
      ...queryResponse,
      currentSprint: queryResponse.data?.currentSprint,
    }),
    [queryResponse]
  );
};

// This is used indirectly, `GET_CURRENT_SPRINTDocument` is generated from it
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_CURRENT_SPRINT = gql`
  query currentSprint($teamId: ID!) {
    currentSprint(teamId: $teamId) {
      id
      teamId
      startDate
      endDate
      locked
      finalized
      sprintNumber
    }
  }
`;
