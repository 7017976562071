import {
  EditOutlined,
  EllipsisOutlined,
  FullscreenOutlined,
} from '@ant-design/icons';
import { Dropdown, MenuProps, Typography } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { KeyActivityModalState } from '../KeyActivitySetupPage';
import { ExpandArrow } from '../../ExpandArrow';
import { PublishStatus } from '../../../generated/graphql';
import { friendlyDate } from '../../../services/dateFormats';
import { stringSort } from '../../../services/stringSort';
import { KeyActivityWeeklyTableCard } from './keyActivityTable/keyActivityTableCard/KeyActivityTableWeeklyCard';
import { useCountdown } from '../../../hooks/useCountdown';
import { SprintTermTableCard } from './keyActivityTable/SprintTermTableCard';
import { UnarchiveOutlinedIcon } from '../../../icons/UnarchiveIcon';
import { ArchiveOutlinedIcon } from '../../../icons/ArchiveIcon';
import { useState } from 'react';
import { AkpiDetailedView } from '../../akpi/teamAkpiPreviews/AkpiDetailedView';
import { momentToDayjs } from '../../../services/dateHelpers';
import { Btn } from '../../Button';

type MitemTerm = {
  __typename: 'MitemTerm';
  id: string;
  startDate: Moment;
  endDate: Moment;
  publishDate: Moment;
  archivedAt: Moment;
  unpublishDate: Moment;
  status: PublishStatus;
};

type WeeklyKeyActivity = {
  __typename: 'WeeklyKeyActivity';
  name: string;
  id: string;
  startDate: Moment;
  endDate: Moment;
  archivedAt: Moment;
  status: PublishStatus;
};

interface Props {
  teamId: string;
  tenantId?: string;
  status: PublishStatus;
  setKeyActivityModalState: (
    keyActivityModalState: KeyActivityModalState | null
  ) => void;
  keyActivities?: (MitemTerm | WeeklyKeyActivity)[];
}

interface KeyActivityTableActionsProps {
  keyActivity: MitemTerm | WeeklyKeyActivity;
  tableStatus: PublishStatus;
  teamId?: string;
  tenantId?: string;
  setKeyActivityModalState: (
    keyActivityModalState: KeyActivityModalState | null
  ) => void;
}

const WeeklyKeyActivityTableActions = ({
  keyActivity,
  setKeyActivityModalState,
  tableStatus,
  teamId,
  tenantId,
}: KeyActivityTableActionsProps) => {
  const [showLargeCard, setShowLargeCard] = useState<boolean>(false);
  const { t } = useTranslation();

  type MenuItem = Required<MenuProps>['items'][number];

  const weeklyKeyActivityOptions: MenuItem[] = [
    {
      key: 'editWeeklyKeyActivity',
      label: t('common.edit'),
      icon: <EditOutlined />,
      onClick: () => {
        setKeyActivityModalState({
          type: 'EditWeeklyKeyActivity',
          id: keyActivity.id,
        });
      },
    },
    {
      key: 'archiveStatusWeeklyKeyActivity',
      label: t('common.archive'),
      icon: <ArchiveOutlinedIcon className="keyActivityPage__icon" />,
      onClick: () => {
        setKeyActivityModalState({
          type: 'UpdateStatusWeeklyKeyActivity',
          id: keyActivity.id,
          status: keyActivity.status,
        });
      },
    },
    {
      key: 'expandWeeklyKeyActivity',
      label: t('WeeklyKeyActivityCard.expand'),
      icon: <FullscreenOutlined />,
      onClick: () => setShowLargeCard(true),
    },
  ];

  if (tableStatus === PublishStatus.ARCHIVED) {
    return (
      <div onClick={(event) => event.stopPropagation()}>
        <Btn
          size="small"
          icon={<UnarchiveOutlinedIcon />}
          onClick={() =>
            setKeyActivityModalState({
              id: keyActivity.id,
              type: 'UpdateStatusWeeklyKeyActivity',
              status: PublishStatus.ARCHIVED,
            })
          }
        >
          {t('common.reactivate')}
        </Btn>
      </div>
    );
  }
  if (!teamId) throw new Error('Missing teamId');

  return (
    <div onClick={(event) => event.stopPropagation()}>
      {showLargeCard && teamId && (
        <AkpiDetailedView
          teamId={teamId}
          tenantId={tenantId}
          weeklyKeyActivityId={keyActivity.id}
          onClose={() => setShowLargeCard(false)}
        />
      )}
      <Dropdown
        menu={{
          items: weeklyKeyActivityOptions,
        }}
        placement="bottom"
      >
        <Btn size="small" icon={<EllipsisOutlined rotate={90} />} />
      </Dropdown>
    </div>
  );
};

const SprintTermTableActions = ({
  keyActivity,
  setKeyActivityModalState,
  tableStatus,
}: KeyActivityTableActionsProps) => {
  const { t } = useTranslation();
  if (tableStatus === PublishStatus.ARCHIVED) {
    return (
      <div onClick={(event) => event.stopPropagation()}>
        <Btn
          size="small"
          icon={<UnarchiveOutlinedIcon />}
          onClick={() =>
            setKeyActivityModalState({
              id: keyActivity.id,
              type: 'UpdateStatusSprintTerm',
              status: PublishStatus.ARCHIVED,
            })
          }
        >
          {t('common.reactivate')}
        </Btn>
      </div>
    );
  }
  type MenuItem = Required<MenuProps>['items'][number];
  const sprintTermOptions: MenuItem[] = [
    {
      label: t('common.edit'),
      key: 'EditSprintTerm',
      icon: <EditOutlined />,
      onClick: () =>
        setKeyActivityModalState({
          type: 'EditSprintTerm',
          id: keyActivity.id,
        }),
    },
    {
      label: t('common.archive'),
      key: 'UpdateStatusSprintTerm',
      icon: <ArchiveOutlinedIcon className="keyActivityPage__icon" />,
      onClick: () =>
        setKeyActivityModalState({
          type: 'UpdateStatusSprintTerm',
          id: keyActivity.id,
          status: keyActivity.status,
        }),
    },
  ];

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Dropdown menu={{ items: sprintTermOptions }} placement="bottom">
        <Btn size="small" icon={<EllipsisOutlined rotate={90} />} />
      </Dropdown>
    </div>
  );
};

interface ExcludedColumnProps<T> extends ColumnProps<T> {
  excluded?: boolean;
}

export const KeyActivityTable = ({
  teamId,
  tenantId,
  status,
  keyActivities,
  setKeyActivityModalState,
}: Props) => {
  const { t } = useTranslation();

  const columns: ExcludedColumnProps<MitemTerm | WeeklyKeyActivity>[] = [
    {
      title: t('common.title'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => {
        const nameA =
          a.__typename === 'WeeklyKeyActivity'
            ? a.name
            : t('MitemTermCard.title');
        const nameB =
          b.__typename === 'WeeklyKeyActivity'
            ? b.name
            : t('MitemTermCard.title');
        return stringSort(nameA, nameB);
      },
      render: (name, keyActivity) => {
        if (keyActivity.__typename === 'WeeklyKeyActivity') {
          return name;
        }

        return t('MitemTermCard.title');
      },
    },
    {
      title: t('KeyActivityTable.type'),
      key: 'type',
      sorter: (a, b) => {
        const typeA =
          a.__typename === 'WeeklyKeyActivity'
            ? t('KeyActivityType.weekly')
            : t('KeyActivityType.sprintTerm');
        const typeB =
          b.__typename === 'WeeklyKeyActivity'
            ? t('KeyActivityType.weekly')
            : t('KeyActivityType.sprintTerm');
        return stringSort(typeA, typeB);
      },
      render: (_, keyActivity) => {
        if (keyActivity.__typename === 'WeeklyKeyActivity') {
          return t('KeyActivityType.weekly');
        }

        return t('KeyActivityType.sprintTerm');
      },
    },
    {
      title: t('common.startDate'),
      sorter: (a, b) => a.startDate.diff(b.startDate),
      defaultSortOrder: 'ascend',
      dataIndex: 'startDate',
      key: 'startDate',
      excluded: status !== PublishStatus.UPCOMING,
      render: (_, { startDate }) => {
        return (
          <div className="flx">
            {friendlyDate(momentToDayjs(startDate))}
            <Typography.Text type="secondary" className="ml flx">
              (<TimeToStart startDate={startDate} />
              <div className="space--l">{t('KeyActivityTable.timeLeft')}</div>)
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: t('KeyActivityTable.archivedAt'),
      sorter: (a, b) => {
        return a.archivedAt.diff(b.endDate);
      },
      defaultSortOrder: 'ascend',
      dataIndex: 'archivedAt',
      key: 'archivedAt',
      excluded: status !== PublishStatus.ARCHIVED,
      render: (_, keyActivity) => {
        return friendlyDate(momentToDayjs(keyActivity.archivedAt));
      },
    },
    {
      title: t('KeyActivityTable.endDate'),
      sorter: (a, b) => a.endDate.diff(b.endDate),
      defaultSortOrder: 'ascend',
      dataIndex: 'endDate',
      key: 'endDate',
      excluded: status !== PublishStatus.ENDED,
      render: (_, { endDate }) => {
        return friendlyDate(momentToDayjs(endDate));
      },
    },
    {
      title: t('common.actions'),
      key: 'actions',
      width: 90,
      render: (_, keyActivity) => {
        if (keyActivity.__typename === 'WeeklyKeyActivity') {
          return (
            <WeeklyKeyActivityTableActions
              keyActivity={keyActivity}
              tableStatus={status}
              teamId={teamId}
              tenantId={tenantId}
              setKeyActivityModalState={setKeyActivityModalState}
            />
          );
        }
        return (
          <SprintTermTableActions
            keyActivity={keyActivity}
            tableStatus={status}
            setKeyActivityModalState={setKeyActivityModalState}
          />
        );
      },
    },
    Table.EXPAND_COLUMN,
  ];

  return (
    <Table
      size="small"
      dataSource={keyActivities}
      columns={columns.filter((c) => c.excluded !== true)}
      bordered
      rowKey={(keyActivity) => keyActivity.id}
      expandable={{
        expandRowByClick: true,
        expandedRowRender: (keyActivity) => {
          if (keyActivity.__typename === 'WeeklyKeyActivity') {
            return (
              <KeyActivityWeeklyTableCard
                teamId={teamId}
                tenantId={tenantId}
                keyActivityId={keyActivity.id}
              />
            );
          }
          return (
            <SprintTermTableCard
              teamId={teamId}
              tenantId={tenantId}
              termId={keyActivity.id}
            />
          );
        },
        expandIcon: ({ expanded, onExpand, record }) => (
          <ExpandArrow
            onClick={(e) => onExpand(record, e)}
            expanded={expanded}
          />
        ),
      }}
    />
  );
};

interface TimeToStartProps {
  startDate: Moment;
}

const TimeToStart = ({ startDate }: TimeToStartProps) => {
  const { timeDiff } = useCountdown(startDate);
  return <div>{timeDiff}</div>;
};
