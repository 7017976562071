import { DownOutlined } from '@ant-design/icons';
import { Dropdown, DropdownProps } from 'antd';
import { NavLink, NavLinkProps } from 'react-router-dom';
import cx from 'classnames';
import './TabLink.less';

interface TabLinkProps extends NavLinkProps {
  loading?: boolean;
}

type ClassNameProp = NavLinkProps['className'];

export const TabLink = ({
  className,
  loading,
  activeClassName,
  children,
  ...props
}: TabLinkProps) => {
  const finalClassName = getFinalClassName(className);

  if (loading) {
    return (
      <div className="TabLink">
        {children}
        <DownOutlined className="ml--s" />
      </div>
    );
  }

  return (
    <NavLink
      className={finalClassName}
      activeClassName={'TabLink--isActive ' + activeClassName}
      {...props}
    >
      {children}
    </NavLink>
  );
};

interface TabDropdownProps extends DropdownProps {
  isActive?: boolean;
  dataTestId?: string;
}

export const TabDropdown = ({
  children,
  isActive,
  dataTestId,
  ...dropdownProps
}: TabDropdownProps) => (
  <Dropdown {...dropdownProps}>
    <div
      data-testid={dataTestId}
      className={cx('TabLink', { 'TabLink--isActive': isActive })}
    >
      {children}
      <DownOutlined className="ml--s" />
    </div>
  </Dropdown>
);

const getFinalClassName = (className: ClassNameProp) => {
  const baseClassName = 'TabLink ';
  let finalClassName: ClassNameProp = baseClassName;

  if (typeof className === 'function') {
    finalClassName = (isActive: boolean) => baseClassName + className(isActive);
  } else if (typeof className === 'string') {
    finalClassName = baseClassName + className;
  }

  return finalClassName;
};
