import { useState } from 'react';
import { ContributorsGraph } from './wkaDetails/ContributorsGraph';
import { ReportsTable } from './wkaDetails/ReportsTable';
import { SmallCollapseCard } from './wkaDetails/SmallCollapseCard';
import {
  Action,
  WkaDetails_WeeklyKeyActivityFragment,
} from '../../../../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { PermissionChecker } from '../../../../../../../../PermissionChecker';
import { gql } from '@apollo/client';
import { WkaDetailsSection } from './wkaDetails/WkaDetailsSection';

enum WkaCategories {
  DESCRIPTION = 'description',
  CONTRIBUTORS = 'contributors',
  REPORTS = 'reports',
}

interface Props {
  wka: WkaDetails_WeeklyKeyActivityFragment;
}

export const WkaDetails = ({ wka }: Props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<WkaCategories | null>(
    WkaCategories.DESCRIPTION
  );

  const toggle = (category: WkaCategories) => {
    if (selected === category) {
      setSelected(null);
    } else {
      setSelected(category);
    }
  };

  return (
    <div className="flx flx--1 flx--column scroll-y" data-image-ignore>
      <SmallCollapseCard
        title={t('WkaDetails.detailsTitle')}
        children={<WkaDetailsSection wka={wka} />}
        visible={selected === WkaCategories.DESCRIPTION}
        onClick={() => toggle(WkaCategories.DESCRIPTION)}
        key={'WkaDetails_description'}
        data-intercom-target="WKA description section"
      />
      <PermissionChecker
        resourceOwner={{
          type: 'TeamResource',
          teamId: wka.teamId,
          requestedAction: {
            resource: 'teamInternalData',
            action: Action.READ,
          },
        }}
      >
        <SmallCollapseCard
          title={t('WkaDetails.contributorsCardTitle')}
          children={<ContributorsGraph teamId={wka.teamId} wkaId={wka.id} />}
          visible={selected === WkaCategories.CONTRIBUTORS}
          onClick={() => toggle(WkaCategories.CONTRIBUTORS)}
          key={'WkaDetails_contributors'}
          data-intercom-target="WKA contributors section"
        />
        <SmallCollapseCard
          title={t('WkaDetails.reportsCardTitle')}
          children={
            <ReportsTable teamId={wka.teamId} wkaId={wka.id} unit={wka.unit} />
          }
          visible={selected === WkaCategories.REPORTS}
          onClick={() => toggle(WkaCategories.REPORTS)}
          key={'WkaDetails_reports'}
          data-intercom-target="WKA reports section"
        />
      </PermissionChecker>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WKA_DETAILS_FRAGMENT = gql`
  fragment WkaDetails_WeeklyKeyActivity on WeeklyKeyActivity {
    id
    unit
    teamId
    ...WkaDetailsSection_WeeklyKeyActivity
  }
`;
