import { MigCardMigFragment, MigInterval } from '../../../../generated/graphql';
import { MigCard, MigCardProps } from '../../../mig/MigCard';

type Nullable<T> = { [P in keyof T]?: T[P] | null };

type PeriodData = Omit<MigCardMigFragment['periodData'][number], '__typename'>;

type Props = Omit<MigCardProps, 'mig'> & {
  mig: Omit<
    Nullable<MigCardMigFragment>,
    'periodData' | '__typename' | 'id' | 'unit'
  > & {
    periodData: Nullable<PeriodData>[];
  };
};

export const PreviewMigCard = ({ mig, ...props }: Props) => {
  const migDefaults: MigCardMigFragment = {
    __typename: 'MigV2',
    id: '',
    name: '',
    unit: '',
    periodData: [],
    timePeriodInterval: MigInterval.MONTH,
    lastOutcomeDate: '',
    minY: null,
    maxY: null,
    mostRecentReport: {
      __typename: 'MigPeriodData',
      targetDate: '',
      actual: null,
      goal: 0,
      goalFulfillment: null,
    },
    mirroredFromTeam: null,
    domainId: {
      __typename: 'MigDomainId',
      itemId: '',
      teamId: '',
    },
  };

  const newData = Object.fromEntries(
    Object.entries(mig).map(([key, value]) => {
      const typedKey = key as keyof MigCardMigFragment;
      if (value == null) {
        return [key, migDefaults[typedKey]];
      }
      return [key, value];
    })
  ) as MigCardMigFragment;

  return <MigCard mig={newData} {...props}></MigCard>;
};
