import React from 'react';
export const CustomizedAxisTick = (props: any) => {
  const {
    x,
    y,
    payload: { value },
  } = props;
  const displayLength = 12;
  const name =
    value.length > displayLength
      ? value.substr(0, displayLength) + '..'
      : value;
  return (
    <g transform={`translate(${x},${y})`}>
      <title>{value}</title>
      <text
        fontSize="12"
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-25)"
      >
        {name}
      </text>
    </g>
  );
};
