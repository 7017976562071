import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CompleteInitiativeDocument,
  InitiativeContentFragment,
} from '../../../../../../../../generated/graphql';
import { showNotification } from '../../../../../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../../../../../services/howweErrorParser';

interface Props {
  tenantId?: string;
  teamId: string;
  initiative: InitiativeContentFragment;
}

export const CompleteStateInitiative = ({ initiative, teamId }: Props) => {
  const { t } = useTranslation();

  let howweErrors;
  const isCompleted = initiative.completed?.value;

  const [changeCompleteState] = useMutation(CompleteInitiativeDocument, {
    onCompleted: (data) => {
      showNotification('success', {
        message: (
          <strong>
            {isCompleted
              ? t('CompleteStateInitiative.markedAsActive', {
                  name: data.editInitiative.name,
                })
              : t('CompleteStateInitiative.markedAsCompleted', {
                  name: data.editInitiative.name,
                })}
          </strong>
        ),
      });
    },
    onError: (e) => {
      howweErrors = howweErrorParser(e);

      showNotification('error', {
        message: (
          <strong>
            <ul>
              {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
            </ul>
          </strong>
        ),
      });
    },
  });

  const handleArchiveInitiative = () => {
    changeCompleteState({
      variables: {
        teamId,
        initiativeId: initiative.domainId.itemId,
        initiative: { completed: !isCompleted, rev: initiative.rev },
      },
    });
  };

  return (
    <div onClick={handleArchiveInitiative}>
      {!isCompleted
        ? t('CompleteStateInitiative.complete')
        : t('CompleteStateInitiative.reactivate')}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const COMPLETE_INITIATIVE = gql`
  mutation completeInitiative(
    $teamId: ID!
    $initiativeId: ID!
    $initiative: UpdateInitiativeInput!
  ) {
    editInitiative(
      teamId: $teamId
      initiativeId: $initiativeId
      initiative: $initiative
    ) {
      ...InitiativeContent
    }
  }
`;
