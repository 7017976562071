import { gql, useSuspenseQuery } from '@apollo/client';
import { GetGlobalMigsDocument } from '../../../../../generated/graphql';
import { MigCollapsibleCard } from '../../../../team/overview/components/migSection/components/MigCollapsibleCard';
import { useState } from 'react';
import { GlobalMigSectionSkeleton } from './GlobalMigSection.skeleton';
import { useTranslation } from 'react-i18next';
import { GlobalMigSectionError } from './GlobalMigSection.error';
import { GlobalMigsEmptyPlaceholder } from './components/GlobalMigsEmptyPlaceholder';

export const GlobalMigSection = () => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(GetGlobalMigsDocument);
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  const globalMigs = data?.globalMigs.globalMigs || [];
  return (
    <div>
      <h2>{t('GlobalMigSection.title')}</h2>

      <div className="mt">
        {globalMigs.map((mig) => (
          <MigCollapsibleCard
            key={mig.id}
            mig={mig}
            onToggleExpand={() =>
              setExpanded((prev) => ({
                ...prev,
                [mig.id]: !prev[mig.id],
              }))
            }
            isExpanded={expanded[mig.id]}
          />
        ))}
        {globalMigs.length === 0 && <GlobalMigsEmptyPlaceholder />}
      </div>
    </div>
  );
};

GlobalMigSection.Skeleton = GlobalMigSectionSkeleton;
GlobalMigSection.Error = GlobalMigSectionError;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_GLOBAL_MIGS = gql`
  query getGlobalMigs {
    globalMigs {
      globalMigs {
        id
        ...MigCollapsibleCard_MigV2
      }
    }
  }
`;
