import { Modal } from 'antd';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../../../ErrorPage';
import { MarkInitiativeAsArchivedModalContent } from './MarkInitiativeAsArchivedModalContent';

interface Props {
  visible: boolean;
  initiativeId: string;
  onClose: () => void;
}

export const MarkInitiativeAsArchivedModal = ({
  initiativeId,
  visible,
  onClose,
}: Props) => {
  return (
    <Modal
      open={visible}
      footer={null}
      style={{ top: 20 }}
      onCancel={onClose}
      destroyOnClose
    >
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Suspense fallback={<MarkInitiativeAsArchivedModalContent.Skeleton />}>
          <MarkInitiativeAsArchivedModalContent
            initiativeId={initiativeId}
            onSuccess={onClose}
          />
        </Suspense>
      </ErrorBoundary>
    </Modal>
  );
};
