import { gql, useQuery } from '@apollo/client';
import { Empty, Spin, Tabs, TabsProps } from 'antd';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Action,
  MitemStatus,
  ReportDrawer2Document,
} from '../../../generated/graphql';
import { isDefined } from '../../../services/isDefined';
import { ErrorAlert } from '../../ErrorAlert';
import { CommittedActivities } from './components/CommittedActivities';
import { KaEntry } from './components/KaEntry';
import { TeamSection } from './components/TeamSection';
import {
  getCommittedActivities,
  isSprintKA,
  mergeKeyActivities,
  SprintKA,
  WeeklyKA,
} from './reportDrawerUtils';
import { useMyTeams } from '../../../hooks/useMyTeams';
import { PermissionChecker } from '../../../PermissionChecker';
import { MilestoneSection } from './components/MilestoneSection';

interface Props {
  closeDrawer: () => void;
}

enum TabKey {
  committed = 'committed',
  allActivities = 'allActivities',
}

export const ReportDrawer = ({ closeDrawer }: Props) => {
  const { teams } = useMyTeams();
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(ReportDrawer2Document, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const [activeTab, setActiveTab] = useState<TabKey>(TabKey.committed);
  if (error) return <ErrorAlert error={error} />;

  const checkIfWKAsAreDue = (weeklyKAs: WeeklyKA[]): boolean => {
    if (weeklyKAs.length === 0) return false;
    return weeklyKAs.some(
      (wka) =>
        isDefined(wka.progress.commitment) &&
        isDefined(wka.progress.achievement) &&
        wka.progress.achievement < wka.progress.commitment
    );
  };

  const checkIfSKAsAreDue = (sprintKAs: SprintKA[]): boolean => {
    if (sprintKAs.length === 0) return false;
    if (sprintKAs.find((sk) => sk.progress.committed)) return true;
    return sprintKAs.some(
      (ska) =>
        ska.mitemStatus !== MitemStatus.COMPLETED &&
        isDefined(ska.periodEndDate) &&
        Date.parse(ska.deadline) < Date.parse(ska.periodEndDate)
    );
  };

  const checkIfKAsAreDue = (tp: {
    weeklyKeyActivityProgress: WeeklyKA[];
    sprintKeyActivityProgress: SprintKA[];
  }): boolean => {
    return (
      checkIfWKAsAreDue(tp.weeklyKeyActivityProgress) ||
      checkIfSKAsAreDue(tp.sprintKeyActivityProgress)
    );
  };

  if (teams.length === 0) {
    return (
      <div>
        <span className="mt--xxl">{t('ReportDrawer.noTeamPlaceholder')}</span>
        <Empty
          className="mt--l"
          description={t('ReportDrawer.emptyDescription')}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      </div>
    );
  }

  const items: TabsProps['items'] = [
    {
      key: TabKey.committed,
      label: t('ReportDrawer.tabCommitted'),
      children: (
        <div style={{ minHeight: 400 }}>
          <div>
            {data?.myProgressV2.teamProgress.map((tp) => (
              <TeamSection
                key={tp.teamId}
                teamName={tp.name}
                periodStartTime={tp.periodStartTime}
                periodEndTime={tp.periodEndTime}
                keyActivitiesCount={getCommittedActivities(tp).length}
                KAShowDueIn={checkIfKAsAreDue(tp)}
              >
                <CommittedActivities
                  teamId={tp.teamId}
                  keyActivities={getCommittedActivities(tp)}
                  onViewAll={() => setActiveTab(TabKey.allActivities)}
                />
              </TeamSection>
            ))}
          </div>
          <div>
            <PermissionChecker
              resourceOwner={{
                type: 'TenantResource',
                requestedAction: {
                  resource: 'milestone',
                  action: Action.UPDATE,
                },
              }}
            >
              <Suspense fallback={<MilestoneSection.Skeleton />}>
                <MilestoneSection />
              </Suspense>
            </PermissionChecker>
          </div>
        </div>
      ),
    },
    {
      key: TabKey.allActivities,
      label: t('ReportDrawer.tabAllActivities'),
      children: data?.myProgressV2.teamProgress.map((tp) => (
        <TeamSection
          key={tp.teamId}
          teamName={tp.name}
          periodStartTime={tp.periodStartTime}
          periodEndTime={tp.periodEndTime}
          keyActivitiesCount={tp.weeklyKeyActivityProgress.length}
          KAShowDueIn={checkIfKAsAreDue(tp)}
        >
          {mergeKeyActivities(
            tp.weeklyKeyActivityProgress,
            tp.sprintKeyActivityProgress
          ).map((ka) => (
            <KaEntry
              keyActivity={ka}
              teamId={tp.teamId}
              key={
                isSprintKA(ka) ? ka.sprintKeyActivityId : ka.weeklyKeyActivityId
              }
            />
          ))}
          {tp.weeklyKeyActivityProgress.length +
            tp.sprintKeyActivityProgress.length ===
            0 && (
            <Empty
              description={t('ReportDrawer.noKeyActivities')}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </TeamSection>
      )),
    },
  ];

  return (
    <div>
      <Spin spinning={loading}>
        <Tabs
          activeKey={activeTab}
          size="small"
          animated={true}
          onChange={(key: string) => setActiveTab(key as TabKey)}
          tabBarExtraContent={
            <Link onClick={closeDrawer} to="/my-page/achievements">
              {t('ReportDrawer.reportsLink')}
            </Link>
          }
          items={items}
        />
      </Spin>
    </div>
  );
};

export const REPORT_DRAWER_2_QUERY = gql`
  query reportDrawer2 {
    myProgressV2 {
      tenantId
      userId
      teamProgress {
        teamId
        name
        periodStartTime
        periodEndTime
        accelerationMeetingId
        weeklyKeyActivityProgress {
          weeklyKeyActivityId
          name
          definitionOfDoneHtml
          achievementDescriptionMandatory
          unit
          periodEndDate
          progress {
            commitment
            note
            achievement
          }
        }
        sprintKeyActivityProgress {
          sprintKeyActivityId
          name
          deadline
          ownerId
          periodEndDate
          mitemStatus
          definitionOfDone
          progress {
            note
            committed
            completedAt
            permittedToComplete
          }
        }
      }
    }
  }
`;
