import { Select } from 'antd';

interface Props {
  timezoneIds?: string[];
  value?: string;
  onChange?: (timezoneId: string) => void;
  className?: string;
  disabled?: boolean;
}

export const DEFAULT_TIMEZONE = 'Europe/Stockholm';

export const TimezoneSelector = ({
  timezoneIds,
  onChange,
  className,
  disabled,
  value,
}: Props) => {
  const timezoneOptions = timezoneIds?.map((id) => ({ value: id, label: id }));
  return (
    <Select
      optionFilterProp="children"
      disabled={disabled}
      onChange={onChange}
      defaultValue={DEFAULT_TIMEZONE}
      value={value}
      showSearch
      className={className}
      options={timezoneOptions}
      filterOption={(input, option) =>
        (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '')
          .toLowerCase()
          .localeCompare((optionB?.label ?? '').toLowerCase())
      }
    />
  );
};
