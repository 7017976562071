import React, { FunctionComponent } from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';

interface Props extends ModalProps {
  fakeFooterHeight?: number;
}

export const FullscreenModal: FunctionComponent<Props> = ({
  children,
  fakeFooterHeight,
  styles,
  ...props
}) => {
  let modalChromeHeight = 1;
  const verticalMargin = 8;
  const marginMultiplier = fakeFooterHeight ? 1 : 2;

  if (props.title) modalChromeHeight += MODAL_HEADER_HEIGHT;
  if (props.footer !== null) modalChromeHeight += MODAL_FOOTER_HEIGHT;
  if (fakeFooterHeight) {
    modalChromeHeight += MODAL_FOOTER_EMPTY_HEIGHT;
  }

  const { body, ...restStyles } = styles || {};

  return (
    <Modal
      open
      style={{ top: verticalMargin, padding: 0 }}
      keyboard={false}
      maskClosable={false}
      styles={{
        body: {
          height: `calc(100vh - ${
            modalChromeHeight + verticalMargin * marginMultiplier
          }px)`,
          overflow: 'auto',
          marginBottom: fakeFooterHeight
            ? fakeFooterHeight - MODAL_FOOTER_EMPTY_HEIGHT * 2
            : 0,
          ...body,
        },
        ...restStyles,
      }}
      width="100%"
      footer={fakeFooterHeight ? <div /> : props.footer}
      {...props}
    >
      {children}
    </Modal>
  );
};

const MODAL_FOOTER_HEIGHT = 53;
const MODAL_FOOTER_EMPTY_HEIGHT = 17;
const MODAL_HEADER_HEIGHT = 55;
