import {
  EditOutlined,
  MoreOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { ArchiveOutlinedIcon } from '../../../../../../../../../icons/ArchiveIcon';
import { CheckIcon } from '../../../../../../../../../icons/overviewIcons/CheckIcon';
import { Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../../../../../../components/Button';
import { MilestoneModalState } from '../../hooks/useMilestoneModal';
import { TeamOutlineIcon } from '../../../../../../../../../icons/initiativeIcons/TeamOutlineIcon';
import { useTenantPermissions } from '../../../../../../../../../usePermissions';
import {
  Action,
  GetMilestoneTeamIdsDocument,
  TeamResourceType,
} from '../../../../../../../../../generated/graphql';
import { usePermissionForTeams } from '../../../../../../../../../hooks/usePermissionForTeams';
import { atLeastOne } from '../../../../../../../../../services/error-parser/helpers';
import { useEffect, useMemo, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { PublishedWithChangesIcon } from '../../../../../../../../../icons/PublishedWithChangesIcon';
import { getUnique } from '../../../../../../../../../services/getUnique';
import { AccountTreeOutlineIcon } from '../../../../../../../../../icons/initiativeIcons/AccountTreeOutlineIcon';

type MenuItem = Required<MenuProps>['items'][number] & { include?: boolean };

interface Props {
  milestone: {
    id: string;
    domainId: {
      itemId: string;
      tenantId: string;
    };
    name: string;
  };
  initiativeId: string;
  openModal: (modalState: MilestoneModalState) => void;
}

export const MilestoneActions = ({
  milestone,
  openModal,
  initiativeId,
}: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const [fetchTeams, { data }] = useLazyQuery(GetMilestoneTeamIdsDocument, {
    variables: { milestoneId: milestone.domainId.itemId, initiativeId },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (open) {
      fetchTeams();
    }
  }, [open, fetchTeams]);

  const contributingTeams = useMemo(() => {
    const milestoneTeams = data?.milestone.teamLinks.map((tl) => tl.team) ?? [];
    const initiativeTeams =
      data?.teamsLinkedToInitiative.linkedTeams.map((lt) => lt.team) ?? [];
    return getUnique(milestoneTeams.concat(initiativeTeams), (link) => link.id);
  }, [data?.milestone.teamLinks, data?.teamsLinkedToInitiative.linkedTeams]);

  const { permissionsTenant } = useTenantPermissions();
  const { data: teamLinkPermissions } = usePermissionForTeams(
    TeamResourceType.TEAM_LINKS,
    Action.UPDATE
  );

  const { data: skaPermissions } = usePermissionForTeams(
    TeamResourceType.SPRINT_KA,
    Action.CREATE
  );

  const allowedToCreateSkasForTeams = useMemo(
    () =>
      skaPermissions?.permittedToPerformActionForTeam.permittedResources ?? [],
    [skaPermissions]
  );

  const allowedTeams = useMemo(() => {
    return (
      contributingTeams.filter((ct) =>
        allowedToCreateSkasForTeams.some((prt) => ct.id === prt.teamId)
      ) ?? []
    );
  }, [allowedToCreateSkasForTeams, contributingTeams]);

  const allowedToUpdateMilestone = permissionsTenant?.milestone.some(
    (permittedAction) => permittedAction === Action.UPDATE
  );

  const allowedToUpdateTeamLinks = atLeastOne(
    teamLinkPermissions?.permittedToPerformActionForTeam.permittedResources
  );

  const items: MenuItem[] = [
    {
      label: (
        <div className="txt--action-blue">
          {t('MilestoneActions.addKeyActivity')}
        </div>
      ),
      key: 'addKeyActivity',
      icon: <PlusCircleOutlined className="txt--action-blue font-size--lg" />,
      onClick: () =>
        openModal({
          milestoneId: milestone.id,
          milestoneDomainId: milestone.domainId,
          type: 'addKeyActivity',
          allowedTeams: allowedTeams.map((team) => ({
            id: team.id,
            name: team.name,
          })),
        }),
      include: allowedToCreateSkasForTeams.length > 0,
    },
    {
      label: <div className="txt--action-blue">{t('common.edit')}</div>,
      key: 'EditMilestone',
      icon: <EditOutlined className="txt--action-blue font-size--lg" />,
      onClick: () =>
        openModal({ milestoneId: milestone.domainId.itemId, type: 'edit' }),
      include: allowedToUpdateMilestone,
    },
    {
      label: (
        <div className="txt--action-blue">
          {t('MilestoneActions.manageTeams')}
        </div>
      ),
      key: 'ManageTeams',
      icon: <TeamOutlineIcon className="txt--action-blue font-size--lg" />,
      onClick: () =>
        openModal({
          milestoneId: milestone.domainId.itemId,
          milestoneName: milestone.name,
          type: 'manageTeams',
        }),
      include: allowedToUpdateTeamLinks,
    },
    {
      label: <div className="txt--action-blue">{t('common.archive')}</div>,
      key: 'ArchiveMilestone',
      icon: <ArchiveOutlinedIcon className="txt--action-blue font-size--lg" />,
      onClick: () =>
        openModal({ milestoneId: milestone.domainId.itemId, type: 'archive' }),
      include: allowedToUpdateMilestone,
    },
    {
      key: 'MarkAsComplete',
      label: (
        <div className="txt--action-blue">
          {t('MilestoneActions.markedAsCompleted')}
        </div>
      ),
      onClick: () =>
        openModal({
          milestoneId: milestone.domainId.itemId,
          type: 'markAsCompleted',
        }),
      icon: <CheckIcon className="txt--action-blue font-size--lg" />,
      include:
        allowedToUpdateMilestone && !data?.milestone.metadata.completedAt,
    },
    {
      key: 'realign',
      label: (
        <div className="txt--action-blue">{t('MilestoneActions.realign')}</div>
      ),
      onClick: () =>
        openModal({
          milestoneId: milestone.domainId.itemId,
          type: 'realign',
        }),
      icon: (
        <AccountTreeOutlineIcon className="txt--action-blue font-size--lg" />
      ),
      include:
        allowedToUpdateMilestone && !data?.milestone.metadata.completedAt,
    },
    {
      key: 'reactivate',
      label: (
        <div className="txt--action-blue">
          {t('MilestoneActions.reactivate')}
        </div>
      ),
      onClick: () =>
        openModal({
          milestoneId: milestone.domainId.itemId,
          type: 'reactivate',
        }),
      icon: (
        <PublishedWithChangesIcon className="txt--action-blue font-size--lg" />
      ),
      include:
        allowedToUpdateMilestone && !!data?.milestone.metadata.completedAt,
    },
  ];

  const filteredItems = items.filter(
    (item) => 'include' in item === false || item.include === true
  );

  if (filteredItems.length === 0) {
    return null;
  }

  return (
    <>
      <div onClick={(e) => e.stopPropagation()}>
        <Dropdown
          menu={{ items: filteredItems }}
          onOpenChange={(open) => setOpen(open)}
        >
          <Btn type="text" icon={<MoreOutlined />} />
        </Dropdown>
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_ACTIONS_TEAM_LINKS = gql`
  query getMilestoneTeamIds($milestoneId: ID!, $initiativeId: ID!) {
    milestone(milestoneId: $milestoneId) {
      id
      teamLinks {
        id
        team {
          id
          name
        }
      }
      metadata {
        completedAt
        status
      }
    }
    teamsLinkedToInitiative(initiativeId: $initiativeId) {
      id
      linkedTeams {
        team {
          id
          name
        }
      }
    }
  }
`;
