import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { stringSort } from '../../../../services/stringSort';
import './MigPreviewTable.less';

interface PreviewTeam {
  id: string;
  name: string;
}

interface Props {
  teams: PreviewTeam[];
  onChanged: (teamId: string) => void;
  selectedTeam: string | null;
  loading?: boolean;
}

export const TeamSelector = ({ teams, onChanged, loading }: Props) => {
  const { t } = useTranslation();
  const { Option } = Select;

  const teamsOptions = teams
    .sort((a, b) => stringSort(a.name, b.name))
    .map((t) => (
      <Option title={t.name} key={t.id} value={t.id}>
        {t.name}
      </Option>
    ));

  return (
    <Select
      showSearch
      loading={loading}
      placeholder={t('common.searchTeamPlaceholder')}
      optionFilterProp="children"
      style={{ width: '100%' }}
      onChange={(value) => onChanged(value)}
      filterOption={(input, option) =>
        (option!.children as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
    >
      {teamsOptions}
    </Select>
  );
};
