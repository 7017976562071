import { gql, useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ArchivedInitiatives_QueryDocument } from '../../../../generated/graphql';

import { ArchivedInitiativesTable } from './archivedInitiatives/ArchivedInitiativesTable';

export const ArchivedInitiatives = () => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(ArchivedInitiatives_QueryDocument, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <div>
      <h3>{t('ArchivedInitiatives.title')}</h3>
      <ArchivedInitiativesTable
        allInitiatives={data.tenantInitiatives2.initiatives}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const QUERY = gql`
  query ArchivedInitiatives_query($tenantId: ID) {
    tenantInitiatives2(tenantId: $tenantId) {
      initiatives {
        metadata {
          completedAt
          archived
          status
          supportsInitiatives {
            id
            domainId {
              itemId
              tenantId
            }
          }
          supportsMigs {
            id
            domainId {
              itemId
              tenantId
            }
          }
        }
        ...companyInitiativeTable__InitiativeLight
      }
    }
  }
`;
