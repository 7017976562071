import { Alert, Input, Modal, Form, Radio, Typography, Select } from 'antd';
import { useMutation, useQuery, gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CopyText } from '../../../components/CopyText';
import {
  GetCompanyLocaleDocument,
  CreateAndAssignUserToTeamDocument,
  UserTeamRole,
  GetTeamMembersV2HookDocument,
} from '../../../generated/graphql';
import { availableLanguages } from '../../../languages';
import { ErrorAlert } from '../../../components/ErrorAlert';
import { showNotification } from '../../../services/fetchNotificationProperties';

interface Props {
  teamId: string;
  value?: string;
  onClose: () => void;
  onComplete: () => void;
  tenantId?: string;
}

const SPRINT_ADMIN = 'SPRINT_ADMIN';

export const CreateTeamMemberModal = (props: Props) => {
  const { t } = useTranslation();
  const { teamId, onClose, onComplete } = props;
  const [form] = Form.useForm();
  const defaultLanguage = availableLanguages[0].code; // Currently english

  const companyLocaleQuery = useQuery(GetCompanyLocaleDocument, {
    fetchPolicy: 'network-only',
  });

  const [addUser, { loading, error }] = useMutation(
    CreateAndAssignUserToTeamDocument,
    {
      refetchQueries: () => [
        {
          query: GetTeamMembersV2HookDocument,
          variables: { teamId: teamId, tenantId: props.tenantId },
        },
      ],
      onCompleted: () => {
        showNotification('success', {
          message: <span>{t('TeamSettingsPage.createMember.success')}</span>,
        });
        onComplete();
        onClose();
      },
    }
  );

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      addUser({
        variables: {
          teamId: teamId,
          tenantId: props.tenantId,
          name: values.name === '' ? null : values.name,
          email: values.email,
          teamRole:
            values.teamRole === SPRINT_ADMIN
              ? UserTeamRole.MEMBER
              : values.teamRole,
          sprintAdmin: values.teamRole === SPRINT_ADMIN,
          locale: values.locale,
        },
      });
    });
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  // text directly in a <Radio.Group> has font-size: 0 for some reason, so we have to override it
  const fixZeroHeight = {
    fontSize: '14px',
  };

  if (companyLocaleQuery.loading) return null;

  const signupLink = `${window.location.origin}/signup`;

  return (
    <Modal
      title={t('TeamSettingsPage.createMember.title')}
      open
      onOk={handleSubmit}
      onCancel={props.onClose}
      cancelButtonProps={{ disabled: loading }}
      confirmLoading={loading}
    >
      <Alert
        className="mb"
        type="info"
        message={
          <div>
            <div className="mb">
              {t('TeamSettingsPage.createMember.infoText')}
            </div>
            <Link to="/signup">{signupLink}</Link>
            <span className="ml--l">
              <CopyText text={signupLink} />
            </span>
          </div>
        }
      />
      <Form
        form={form}
        data-testid="create-member-form"
        initialValues={{
          email: props.value,
          teamRole: SPRINT_ADMIN,
          locale: companyLocaleQuery.data?.tenant?.locale || defaultLanguage,
        }}
        layout="vertical"
      >
        <Form.Item
          name="email"
          label={t('TeamSettingsPage.createMember.labelEmail')}
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
            },
            { type: 'email' },
            {
              min: 4,
              max: 256,
            },
          ]}
        >
          <Input autoComplete="off" type="email" autoFocus />
        </Form.Item>
        <Form.Item
          name="name"
          label={t('TeamSettingsPage.createMember.labelFullName')}
          validateTrigger="onBlur"
          rules={[
            {
              min: 5,
              max: 256,
            },
          ]}
        >
          <Input autoComplete="off" type="text" />
        </Form.Item>
        <Form.Item
          name="locale"
          label={t('TeamSettingsPage.createMember.language')}
        >
          <Select allowClear>
            {availableLanguages.map((language) => {
              return (
                <Select.Option key={language.code} value={language.code}>
                  {t(`locale.${language.code}`)}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="teamRole">
          <Radio.Group>
            <Radio style={radioStyle} value={UserTeamRole.MEMBER}>
              <Typography.Text strong>
                {t('common.teamRole.member')}
              </Typography.Text>
            </Radio>
            <div className="mb" style={fixZeroHeight}>
              {t('TeamSettingsPage.assignTeamRole.memberDescription')}
            </div>

            <Radio style={radioStyle} value={SPRINT_ADMIN}>
              <Typography.Text strong>
                {t('common.teamRole.sprint_admin')}
              </Typography.Text>
            </Radio>
            <div style={fixZeroHeight}>
              {t('TeamSettingsPage.assignTeamRole.sprintAdminDescription')}
            </div>

            <Radio style={radioStyle} value={UserTeamRole.LEADER}>
              <Typography.Text strong>
                {t('common.teamRole.leader')}
              </Typography.Text>
            </Radio>
            <div style={fixZeroHeight}>
              {t('TeamSettingsPage.assignTeamRole.leaderDescription')}
            </div>
          </Radio.Group>
        </Form.Item>
      </Form>
      <ErrorAlert
        error={error}
        title={t('TeamSettingsPage.createMember.errorTitle')}
      />
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_AND_ASSIGN_USER_TO_TEAM = gql`
  mutation createAndAssignUserToTeam(
    $teamId: ID!
    $tenantId: ID
    $email: String!
    $name: String
    $displayName: String
    $locale: String
    $teamRole: UserTeamRole
    $sprintAdmin: Boolean
  ) {
    createAndAssignUserToTeam(
      teamId: $teamId
      tenantId: $tenantId
      name: $name
      email: $email
      displayName: $displayName
      locale: $locale
      teamRole: $teamRole
      sprintAdmin: $sprintAdmin
    ) {
      name
      email
      role
      locale
      id
      teamAssociations {
        role
        sprintAdmin
        team {
          id
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TENANT_LOCALE = gql`
  query getCompanyLocale {
    tenant {
      locale
    }
  }
`;
