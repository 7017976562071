import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useMostImportantGoalsInsights,
  useNextSprintInsights,
} from '../../../../../../../hooks/useAccelerationMeetingInsights';
import { showNotification } from '../../../../../../../services/fetchNotificationProperties';
import { NoInsightsInfo } from './genericCard/NoInsightsInfo';
import { MigInsightCards } from './insightCards/MigInsightCards';
import { SprintInsightCards } from './insightCards/SprintInsightCards';
import * as sentry from '@sentry/react';

type Props = {
  teamId: string;
};

export const AccelerationMeetingInsights = ({ teamId }: Props) => {
  const { t } = useTranslation();

  const {
    nextSprintInsights,
    loading: nextSprintInsightLoading,
    error: fetchSprintInsightError,
  } = useNextSprintInsights(teamId);

  const {
    migInsights: {
      genericInsights: migGenricInsights,
      customInsights: migCustomInsights,
    },
    loading: migInsightLoading,
    error: fetchMigInsightError,
  } = useMostImportantGoalsInsights(teamId);

  if (fetchMigInsightError) {
    sentry.captureMessage(t('GenericInsights.migError'), {
      level: 'error',
    });
    showNotification('error', {
      message: t('GenericInsights.migError'),
    });
  }

  if (fetchSprintInsightError) {
    sentry.captureMessage('GenericInsights.sprintError', {
      level: 'error',
    });
    showNotification('error', {
      message: t('GenericInsights.sprintError'),
    });
  }

  const sprintGenericInsights = nextSprintInsights.genericInsights;
  const numberOfInsights =
    sprintGenericInsights.length +
    migGenricInsights.length +
    (migCustomInsights.actual != null ? 1 : 0);

  if (numberOfInsights === 0)
    return (
      <>
        {t('AccelerationMeetingInsights.insightHeader_zero')}
        <NoInsightsInfo />
      </>
    );

  return (
    <>
      {nextSprintInsightLoading && migInsightLoading ? (
        <Spin />
      ) : (
        <div className="pt--xl">
          <h2 className="text-c">
            {t('AccelerationMeetingInsights.insightHeader', {
              count: numberOfInsights,
            })}
          </h2>
          <MigInsightCards
            genericInsights={migGenricInsights}
            customInsights={migCustomInsights}
          />
          <SprintInsightCards
            genericInsights={nextSprintInsights.genericInsights}
          />
        </div>
      )}
    </>
  );
};
