import React from 'react';
import './TreeLink.less';

interface Props {
  target: Point;
  source: Point;
  nodeHeight: number;
}

interface Point {
  x: number;
  y: number;
}

export const TreeLink = ({ target, source, nodeHeight }: Props) => {
  const getCurve = () => {
    const targetY = target.y - nodeHeight;
    return (
      'M' +
      target.x +
      ',' +
      targetY +
      'H' +
      target.x +
      'V' +
      (targetY + source.y) / 2 +
      'H' +
      source.x +
      'V' +
      (targetY + source.y) / 2 +
      'L' +
      source.x +
      ',' +
      source.y
    );
  };

  return (
    <svg className="TreeLink">
      <path d={getCurve()} />
    </svg>
  );
};
