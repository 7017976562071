import Icon from '@ant-design/icons';

const VerifiedBadgeOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.29984 9.36671L9.0665 5.60004L8.1165 4.65004L5.29984 7.46671L3.89984 6.06671L2.94984 7.01671L5.29984 9.36671ZM5.99984 13.6667C4.45539 13.2778 3.18039 12.3917 2.17484 11.0084C1.16928 9.62504 0.666504 8.08893 0.666504 6.40004V2.33337L5.99984 0.333374L11.3332 2.33337V6.40004C11.3332 8.08893 10.8304 9.62504 9.82484 11.0084C8.81928 12.3917 7.54428 13.2778 5.99984 13.6667ZM5.99984 12.2667C7.15539 11.9 8.11095 11.1667 8.8665 10.0667C9.62206 8.96671 9.99984 7.74448 9.99984 6.40004V3.25004L5.99984 1.75004L1.99984 3.25004V6.40004C1.99984 7.74448 2.37762 8.96671 3.13317 10.0667C3.88873 11.1667 4.84428 11.9 5.99984 12.2667Z" />
  </svg>
);

export const VerifiedBadgeOutlineIcon = (props: any) => (
  <Icon component={VerifiedBadgeOutlineIconSvg} {...props} />
);
