import { Moment } from 'moment';
import { friendlyDate, standardDateFormat } from './dateFormats';
import { momentToDayjs } from './dateHelpers';
import { roundToDecimals } from './roundNumbersHelper';
import { Period } from './GraphTickHelper';

interface AkpiDto {
  startDate: string;
  endDate: string;
  periodData?: PeriodDataDto[];
  periodDataWithCommitment?: PeriodDataDto[];
}

export const weeklyKeyActivityDTOtoAkpiData = <T extends AkpiDto>(
  akpiDTO: T
) => {
  const { periodData, periodDataWithCommitment: periodsWithCommitments } =
    akpiDTO;
  const periods = periodData ?? periodsWithCommitments ?? [];

  return {
    ...akpiDTO,
    periodData: akpiDtoPeriodDataToPeriodData(periods),
  };
};

const akpiDtoPeriodDataToPeriodData = (periodData: PeriodDataDto[]) => {
  return periodData.map(
    ({ goal, actual, committed, historic, targetDate }, index) => {
      return {
        timePeriodIndex: index,
        targetDate,
        label: friendlyDate(targetDate),
        goal,
        actual: actual ?? undefined,
        committed: committed ?? undefined,
        historic: historic ?? undefined,
      };
    }
  );
};

export function toCumulativePeriods(periods: PeriodData[]) {
  if (!periods) return periods;
  let goalAcc = 0;
  let historicAcc = 0;
  return periods.map((p) => {
    goalAcc += typeof p.goal === 'number' ? p.goal : 0;
    historicAcc += typeof p.historic === 'number' ? p.historic : 0;
    return {
      ...p,
      goal: roundToDecimals(goalAcc, 6),
      historic:
        p.historic == null ? undefined : roundToDecimals(historicAcc, 6),
    };
  });
}

export function accumulatePeriodData(periods: Period[]) {
  if (!periods) return periods;
  let goalAcc = 0;
  let actualAcc = 0;
  return periods.map((p) => {
    goalAcc += typeof p.goal === 'number' ? p.goal : 0;
    actualAcc += typeof p.actual === 'number' ? p.actual : 0;
    return {
      ...p,
      goal: roundToDecimals(goalAcc, 6),
      actual: roundToDecimals(actualAcc, 6),
    };
  });
}

interface TimelinePeriod {
  timePeriodStartDate: Moment;
  timePeriodEndDate: Moment;
}

export const timelineToEmptyPeriodData = (
  timeline?: TimelinePeriod[] | null
) => {
  if (!timeline) return [];
  return timeline?.map((value, index) => {
    const targetDateMoment = value.timePeriodEndDate;
    const label = friendlyDate(momentToDayjs(targetDateMoment));
    const targetDate = standardDateFormat(targetDateMoment);
    return { timePeriodIndex: index, label, targetDate };
  });
};

export const mergePeriodData = (
  periodData: PeriodData[],
  emptyPeriodData: PeriodData[]
) => {
  const newCurveWithOldPoints = emptyPeriodData.map((bcp, index) => {
    const periodDataPoint = periodData.find(
      (p) => p.targetDate === bcp.targetDate
    );
    return { ...bcp, ...periodDataPoint, timePeriodIndex: index };
  });
  return newCurveWithOldPoints;
};

export function deaccumulatePeriodData(cumulativePeriodData: PeriodData[]) {
  let lastGoalValue = 0;
  let lastHistoricValue = 0;
  const deaccumulatedPeriodData: PeriodData[] = [];
  for (const p of cumulativePeriodData) {
    const previousPeriod = cumulativePeriodData[p.timePeriodIndex - 1];
    if (previousPeriod?.goal != null) {
      lastGoalValue = previousPeriod.goal;
    }
    if (previousPeriod?.historic != null) {
      lastHistoricValue = previousPeriod.historic;
    }

    const weekGoal = p.goal && p.goal - lastGoalValue;
    const weekHistoric = p.historic && p.historic - lastHistoricValue;
    deaccumulatedPeriodData.push({
      ...p,
      goal: roundToDecimals(weekGoal, 6),
      historic: roundToDecimals(weekHistoric, 6),
    });
  }
  return deaccumulatedPeriodData;
}

interface PeriodDataDto {
  targetDate: string;
  goal: number;
  actual?: number | null;
  committed?: number | null;
  historic?: number | null;
}

interface PeriodData {
  targetDate: string;
  timePeriodIndex: number;
  label: string;
  goal?: number;
  actual?: number;
  historic?: number;
}
