import { Checkbox, Divider, Form, Modal, Tooltip } from 'antd';
import { Btn } from '../../../../../../../components/Button';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  ArchiveSkaDocument,
  CreateMilestoneFromSkaDocument,
  CreateMilestoneFromSkaWithButton__InitiativeLightFragment,
  CreateMilestoneFromSkaWithButton__MitemFragment,
  GetTenantBusinessPlanInitiativeDocument,
  SupportedEntityType,
} from '../../../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { MilestoneForm } from '../createMilestoneButton/components/MilestoneForm';
import { howweErrorParser } from '../../../../../../../services/howweErrorParser';
import { showNotification } from '../../../../../../../services/fetchNotificationProperties';
import { utcTimeFormat } from '../../../../../../../services/dateFormats';
import { ConvertSkaToMilestoneIcon } from '../../../../../../../icons/initiativeIcons/ConvertSkaToMilestoneIcon';

interface Props {
  initiative: CreateMilestoneFromSkaWithButton__InitiativeLightFragment;
  sprintKeyActivity: CreateMilestoneFromSkaWithButton__MitemFragment;
}

export const CreateMilestoneFromSkaWithButton = ({
  initiative,
  sprintKeyActivity,
}: Props) => {
  const [archiveKeyActivity, setArchiveKeyActivity] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [createMilestoneFromSka, { loading }] = useMutation(
    CreateMilestoneFromSkaDocument
  );

  const [archiveSprintKeyActivity, { loading: archiveSkaLoading }] =
    useMutation(ArchiveSkaDocument);

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const initialValues = {
    name: sprintKeyActivity.name,
    deadlineAt: sprintKeyActivity.deadline,
    description: sprintKeyActivity.definitionOfDone,
    assignedTo: initiative.assignedTo,
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const { description, deadlineAt, name, assignedTo } = values;

      createMilestoneFromSka({
        variables: {
          milestone: {
            description,
            deadlineAt: utcTimeFormat(deadlineAt),
            assignedTo: [
              {
                id: assignedTo,
              },
            ],
            supports: [
              {
                type: SupportedEntityType.initiative,
                item: {
                  domainId: {
                    itemId: initiative.domainId.itemId,
                    tenantId: initiative.domainId.tenantId,
                  },
                },
              },
            ],
            name: name,
          },
        },

        onCompleted: (data) => {
          showNotification('success', {
            message: (
              <strong>
                {t('CreateMilestoneFromSkaWithButton.createSuccess', {
                  name: data.createMilestone.name,
                })}
              </strong>
            ),
          });
          if (archiveKeyActivity) {
            archiveSprintKeyActivity({
              variables: {
                teamId: sprintKeyActivity.teamId,
                sprintKaId: sprintKeyActivity.id,
                sprintKaData: {
                  archive: true,
                },
              },
            });
          }
          setShowModal(false);
          setArchiveKeyActivity(true);
        },
        refetchQueries: () => [
          {
            query: GetTenantBusinessPlanInitiativeDocument,
            variables: { initiativeId: initiative.domainId.itemId },
          },
        ],
        onError: (e) => {
          const howweErrors = howweErrorParser(e);

          showNotification('error', {
            message: (
              <strong>
                <ul>
                  {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
                </ul>
              </strong>
            ),
          });
        },
      });
    });
  };

  return (
    <div>
      <Tooltip
        placement="top"
        title={t('CreateMilestoneFromSkaWithButton.tooltip')}
        mouseEnterDelay={0.7}
      >
        <Btn
          icon={<ConvertSkaToMilestoneIcon />}
          type="text"
          className="txt--secondary space--l"
          onClick={() => setShowModal(true)}
        />
      </Tooltip>
      <Modal
        open={showModal}
        onCancel={() => {
          setShowModal(false);
          form.resetFields();
        }}
        onOk={handleSubmit}
        okButtonProps={{ loading: loading || archiveSkaLoading }}
        title={<h3>{t('CreateMilestoneFromSkaWithButton.title')}</h3>}
        destroyOnClose
        maskClosable={false}
      >
        <div>
          <MilestoneForm
            form={form}
            tag={initiative?.tag}
            initialValue={initialValues}
          />
          <Divider />
          <Checkbox
            style={{ fontSize: '16px' }}
            checked={!archiveKeyActivity}
            onChange={() => setArchiveKeyActivity(!archiveKeyActivity)}
          >
            {t('CreateMilestoneFromSkaWithButton.keepKeyActivity')}
          </Checkbox>
        </div>
      </Modal>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_MILESTONE_FROM_SKA_WITH_BUTTON__MITEM = gql`
  fragment CreateMilestoneFromSkaWithButton__Mitem on Mitem {
    id
    name
    definitionOfDone
    deadline
    teamId
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_MILESTONE_FROM_SKA_WITH_BUTTON__INITIATIVE = gql`
  fragment CreateMilestoneFromSkaWithButton__InitiativeLight on InitiativeLight {
    id
    domainId {
      itemId
      tenantId
    }
    assignedTo {
      id
      name
      email
      displayName
      initials
    }
    tag {
      ...MilestoneForm_InitiativeTag
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_MILESTONE_FROM_SKA_WITH_BUTTON__MUTATION = gql`
  mutation createMilestoneFromSka($tenantId: ID, $milestone: MilestoneInput!) {
    createMilestone(tenantId: $tenantId, milestone: $milestone) {
      id
      name
      description
      rev
      domainId {
        itemId
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_MILESTONE_FROM_SKA_WITH_BUTTON__ARCHIVE_SKA = gql`
  mutation archiveSka(
    $teamId: ID!
    $sprintKaId: ID!
    $sprintKaData: SprintKaInput!
  ) {
    editSprintKa(
      teamId: $teamId
      sprintKaId: $sprintKaId
      sprintKaData: $sprintKaData
    ) {
      id
      teamId
      tenantId
      name
      completed
      archived
      status
    }
  }
`;
