import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { TimePeriodFilter } from '../generated/graphql';

interface Props {
  timePeriodFilter?: TimePeriodFilter;
  onPeriodChange: (value?: TimePeriodFilter) => void;
}

export function AkpiPeriodSelect({ timePeriodFilter, onPeriodChange }: Props) {
  const { t } = useTranslation();
  const selectedPeriod = timePeriodFilter ?? ALL_TIME;

  return (
    <Select
      size="small"
      popupMatchSelectWidth={false}
      value={selectedPeriod}
      bordered={false}
      onChange={(value: TimePeriodFilter | 'ALL_TIME') => {
        onPeriodChange(value === ALL_TIME ? undefined : value);
      }}
    >
      <Select.Option value={TimePeriodFilter.CURRENT}>
        {t('AkpiPeriodSelect.currentPeriod')}
      </Select.Option>
      <Select.Option value={TimePeriodFilter.PREVIOUS}>
        {t('AkpiPeriodSelect.previousPeriod')}
      </Select.Option>
      <Select.Option value={ALL_TIME}>
        {t('AkpiPeriodSelect.allTime')}
      </Select.Option>
    </Select>
  );
}

const ALL_TIME = 'ALL_TIME';
