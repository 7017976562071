import Icon from '@ant-design/icons';

const GrassIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.333496 11.3333V9.99996H4.16683C3.92238 9.05552 3.4585 8.24163 2.77516 7.55829C2.09183 6.87496 1.27794 6.41107 0.333496 6.16663C0.555718 6.11107 0.775163 6.0694 0.991829 6.04163C1.2085 6.01385 1.4335 5.99996 1.66683 5.99996C3.15572 5.99996 4.41683 6.51663 5.45016 7.54996C6.4835 8.58329 7.00016 9.8444 7.00016 11.3333H0.333496ZM8.3335 11.3333C8.3335 10.8666 8.2835 10.4027 8.1835 9.94163C8.0835 9.48052 7.93905 9.03885 7.75016 8.61663C8.21683 7.82774 8.85294 7.1944 9.6585 6.71663C10.4641 6.23885 11.3557 5.99996 12.3335 5.99996C12.5668 5.99996 12.7918 6.01385 13.0085 6.04163C13.2252 6.0694 13.4446 6.11107 13.6668 6.16663C12.7224 6.41107 11.9113 6.87496 11.2335 7.55829C10.5557 8.24163 10.0891 9.05552 9.8335 9.99996H13.6668V11.3333H8.3335ZM7.00016 7.34996C7.00016 6.62774 7.1335 5.94996 7.40016 5.31663C7.66683 4.68329 8.0335 4.12496 8.50016 3.64163C8.96683 3.15829 9.51405 2.77218 10.1418 2.48329C10.7696 2.1944 11.4391 2.03885 12.1502 2.01663C11.5279 2.40552 10.9835 2.88329 10.5168 3.44996C10.0502 4.01663 9.68905 4.64996 9.4335 5.34996C8.94461 5.58329 8.49738 5.8694 8.09183 6.20829C7.68627 6.54718 7.32238 6.92774 7.00016 7.34996ZM5.7835 6.09996C5.65016 5.99996 5.51683 5.90551 5.3835 5.81663C5.25016 5.72774 5.11127 5.63885 4.96683 5.54996C4.96683 5.48329 4.97238 5.41385 4.9835 5.34163C4.99461 5.2694 5.00016 5.19996 5.00016 5.13329C5.00016 4.28885 4.86683 3.48885 4.60016 2.73329C4.3335 1.97774 3.95572 1.28885 3.46683 0.666626C4.20016 0.966626 4.83627 1.39718 5.37516 1.95829C5.91405 2.5194 6.32794 3.16663 6.61683 3.89996C6.41683 4.23329 6.24461 4.58607 6.10016 4.95829C5.95572 5.33052 5.85016 5.71107 5.7835 6.09996Z" />
  </svg>
);

export const GrassIcon = (props: any) => (
  <Icon component={GrassIconSvg} {...props} />
);
