import { useState } from 'react';
import { Form, DatePicker, Select, Spin, Alert } from 'antd';
import moment from 'moment';
import { PublishStatus } from '../../../../generated/graphql';
import {
  getTermLength,
  isConflictingWithTerms,
  isInterruptedTerm,
} from '../../../../services/sprintKeyActivityUtils';
import {
  isAccDay,
  isEndOfPeriod,
  isInCurrentPeriod,
} from '../../../../services/accelerationDayHelpers';
import { roundToDecimals } from '../../../../services/roundNumbersHelper';
import {
  FRIENDLY_DATE_FORMAT,
  standardDateFormat,
} from '../../../../services/dateFormats';
import { useAccelerationDay } from '../../../../hooks/useAccelerationDay';
import { useSprintTerms } from '../../../../hooks/useSprintTerms';
import { useClosestPeriods } from '../../../../hooks/useClosestPeriods';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
import { dayjsToMoment, momentToDayjs } from '../../../../services/dateHelpers';
import { Btn } from '../../../Button';

interface MitemTeamPageData {
  id: string;
  endDate: string;
  startDate: string;
  publishDate: string;
  unpublishDate: string;
  sprintInterval: number;
  status: PublishStatus;
  published: boolean;
  periodData: {
    targetDate: string;
    goal: number;
    actual: number | null;
  }[];
}

interface Props {
  initialValues?: MitemTeamPageData;
  onClose: () => void;
  handleSave: (
    startDate: string,
    endDate: string,
    sprintInterval: number
  ) => void;
  teamId: string;
  tenantId?: string;
}

const DEFAULT_PERIOD_LENGTH_IN_DAYS = 7;

export const SprintTermForm = ({
  initialValues,
  teamId,
  tenantId,
  handleSave,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [formRef] = Form.useForm();
  const { data: accelerationDay } = useAccelerationDay(teamId, tenantId);
  const { data: mitemTerms, loading } = useSprintTerms(teamId, tenantId);
  const { currentPeriod } = useClosestPeriods(teamId, tenantId);
  const [termLength, setTermLength] = useState<number | undefined>(
    getTermLength(initialValues?.startDate, initialValues?.endDate)
  );
  const [sprintLength, setSprintLength] = useState<number | undefined>(
    initialValues?.sprintInterval
  );

  const isOkToChangeStartDate =
    initialValues == null || moment().isBefore(initialValues.startDate);

  const MAX_SPRINT_LENGTH = 20;

  const firstSelectableAccelerationDate = currentPeriod?.timePeriodStartDate;

  const firstSelectableEndDate = currentPeriod?.timePeriodEndDate;

  const otherTerms = mitemTerms?.filter(
    (t) => t.id !== initialValues?.id && t.status !== PublishStatus.ARCHIVED
  );

  const handleSubmit = () => {
    formRef.validateFields().then((values) => {
      const startDate = standardDateFormat(values.startDate);
      const endDate = standardDateFormat(values.endDate);
      const sprintInterval = values.sprintInterval;

      handleSave(startDate, endDate, sprintInterval);
    });
  };

  const isNotValidStartDate = (startDateCandidate: Dayjs) => {
    if (!currentPeriod) return true; // Disabled all dates until we know current period

    const endDate: Dayjs | undefined = formRef.getFieldValue('endDate');
    const isStartOfCurrentPeriod = startDateCandidate.isSame(
      firstSelectableAccelerationDate,
      'day'
    );

    const isLessThanTwoPeriodsApart =
      endDate != null &&
      endDate.diff(startDateCandidate, 'days') <= DEFAULT_PERIOD_LENGTH_IN_DAYS;

    if (isLessThanTwoPeriodsApart) return true;

    return (
      // Rule: Cannot be in the current period (example: if acc. day changed from Monday to Wednesday, we get a longer period with a Wednesday in the middle that shouldn't be selectable)
      isInCurrentPeriod(startDateCandidate, currentPeriod) ||
      // Rule: Cannot overlap with existing term
      isConflictingWithTerms(startDateCandidate, otherTerms) ||
      // Rule: Cannot be interrupted by other terms
      isInterruptedTerm(
        dayjsToMoment(startDateCandidate),
        dayjsToMoment(endDate),
        otherTerms
      ) ||
      // Rule: Cannot be before the first selectable acceleration day
      startDateCandidate.isBefore(firstSelectableAccelerationDate, 'day') ||
      // Rule: Must be on an acceleration day
      (!isStartOfCurrentPeriod &&
        accelerationDay &&
        !isAccDay(startDateCandidate, accelerationDay)) ||
      // Rule: StartDate cannot be after endDate
      (!!endDate && startDateCandidate.isSameOrAfter(endDate, 'day'))
    );
  };

  const isNotValidEndDate = (endDateCandidate: Dayjs) => {
    const startDate: Dayjs | undefined = formRef.getFieldValue('startDate');

    const isLessThanTwoPeriodsApart =
      startDate != null &&
      endDateCandidate.diff(startDate, 'days') <= DEFAULT_PERIOD_LENGTH_IN_DAYS;

    if (isLessThanTwoPeriodsApart) return true;

    return (
      // Rule: Cannot overlap with existing term
      isConflictingWithTerms(endDateCandidate, otherTerms) ||
      // Rule: Cannot be interrupted by other terms
      isInterruptedTerm(
        dayjsToMoment(startDate),
        dayjsToMoment(endDateCandidate),
        otherTerms
      ) ||
      // Rule: EndDate cannot be before firstSelectableEndDate
      endDateCandidate.isBefore(firstSelectableEndDate, 'day') ||
      // Rule: EndDate must end on the day before the acceleration day
      (accelerationDay && !isEndOfPeriod(endDateCandidate, accelerationDay)) ||
      // Rule: EndDate cannot be before StartDate
      (!!startDate && endDateCandidate.isSameOrBefore(startDate, 'day'))
    );
  };

  const getDuration = () => {
    const startDate: Dayjs | undefined = formRef.getFieldValue('startDate');
    const endDate: Dayjs | undefined = formRef.getFieldValue('endDate');
    return getTermLength(startDate, endDate);
  };

  const numberOfSprints =
    termLength && sprintLength && roundToDecimals(termLength / sprintLength, 2);

  const conflictWarning = otherTerms?.some(
    (t) =>
      t.status === PublishStatus.UPCOMING || t.status === PublishStatus.ACTIVE
  );

  const initialValuesWithDayjs = initialValues
    ? {
        ...initialValues,
        startDate: momentToDayjs(initialValues.startDate),
        endDate: momentToDayjs(initialValues.endDate),
        publishDate: momentToDayjs(initialValues.publishDate),
      }
    : undefined;

  return (
    <Spin spinning={loading}>
      <div>
        <Form
          form={formRef}
          data-testid="mitem-term-form"
          layout="vertical"
          initialValues={initialValuesWithDayjs}
        >
          <div className="flx flx--jc-space-between">
            <Form.Item
              name="startDate"
              label={t('common.startDate')}
              className="flx--1"
              extra={
                termLength && termLength > 1
                  ? t('SprintTermForm.intervalHelper', {
                      duration: termLength,
                    })
                  : ''
              }
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                disabled={!isOkToChangeStartDate}
                disabledDate={isNotValidStartDate}
                format={FRIENDLY_DATE_FORMAT}
                onChange={() => setTermLength(getDuration)}
                showToday={false}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name="endDate"
              label={t('common.endDate')}
              className="flx--1 ml"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                disabled={currentPeriod == null}
                disabledDate={isNotValidEndDate}
                format={FRIENDLY_DATE_FORMAT}
                onChange={() => setTermLength(getDuration)}
                showToday={false}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="sprintInterval"
            label={t('SprintTermForm.sprintIntervalTitle')}
            extra={
              numberOfSprints
                ? t('SprintTermForm.numberOfSprintsHelper', {
                    numberOfSprints: numberOfSprints,
                  })
                : ''
            }
            className="flx--1"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              disabled={termLength === null}
              onChange={(v: number) => setSprintLength(v)}
            >
              {Array.from({
                length:
                  termLength! > MAX_SPRINT_LENGTH
                    ? MAX_SPRINT_LENGTH
                    : termLength!,
              }).map((_, i) => (
                <Select.Option key={i} value={i + 1}>
                  {i + 1}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>

        {conflictWarning && (
          <Alert
            type="info"
            className="mb--l"
            description={t('SprintTermForm.overlappingTermsWarning')}
            message={t('SprintTermForm.pleaseNote')}
          />
        )}

        <div className="flx flx--jc-flx-end">
          <Btn className="mr--s" onClick={onClose}>
            {t('SprintTermForm.cancel')}
          </Btn>
          <Btn type="primary" onClick={handleSubmit}>
            {t('SprintTermForm.save')}
          </Btn>
        </div>
      </div>
    </Spin>
  );
};
