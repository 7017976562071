import Icon from '@ant-design/icons';

const SuccessIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 9"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.4665 8.01667L0.666504 4.21667L1.6165 3.26667L4.4665 6.11667L10.5832 0L11.5332 0.95L4.4665 8.01667Z" />
  </svg>
);

export const SuccessIcon = (props: any) => (
  <Icon component={SuccessIconSvg} {...props} />
);
