import { Card, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { BetaBadge } from '../../../../../../../../components/BetaBadge';
import { DisplayDateRange } from '../../../../../../../../components/DisplayDateRange';
import { usePreviousPeriodSummary } from './hooks/usePreviousPeriodSummary';
import { SprintKeyActivityProgressLog } from './sprintKeyActivitySummary/SprintKeyActivityProgressLog';
import { SprintKeyActivityTeamSummary } from './sprintKeyActivitySummary/SprintKeyActivityTeamSummary';
import { TourLink } from '../../../../../../../../components/TourLink';
import { gql } from '@apollo/client';
import { SprintKeyActivitySummary_MitemAccelerationMeetingSprintsFragment } from '../../../../../../../../generated/graphql';

interface Props {
  teamId: string;
  tenantId?: string;
  sprints?: SprintKeyActivitySummary_MitemAccelerationMeetingSprintsFragment | null;
}

export const SprintKeyActivitySummary = ({
  teamId,
  tenantId,
  sprints,
}: Props) => {
  const { t } = useTranslation();

  // todo: refactor and use local query with fragments instead (and use the SprintKeyActivityTeamSummary_SprintTermFragment)
  const { userContributionData, data, loading } = usePreviousPeriodSummary({
    teamId,
    tenantId,
  });

  const sprint =
    sprints?.previousSprint !== null &&
    sprints?.previousSprint.finalized !== true
      ? sprints?.previousSprint
      : sprints.currentSprint;

  const sprintTerm =
    data?.accelerationMeetingPeriodSummary.sprintKeyActivityTerm;

  return (
    <Spin spinning={loading}>
      <div className="mb">
        <h2 className="mb--none">
          {t('SprintKeyActivitySummary.title')}
          <BetaBadge className="space--l" />
        </h2>

        <Typography.Text type="secondary">
          <DisplayDateRange
            startDate={data?.accelerationMeetingPeriodSummary.startDate}
            endDate={data?.accelerationMeetingPeriodSummary.endDate}
          />
        </Typography.Text>
        <TourLink
          intercomTarget="Acceleration meeting - Sprint key activity summary help modal"
          engTourId={3559}
          sweTourId={3413}
        />
      </div>
      <div className="flx">
        <Card style={{ width: 360, minWidth: 360 }}>
          {sprintTerm && (
            <SprintKeyActivityTeamSummary
              sprintTerm={sprintTerm}
              sprint={sprint}
            />
          )}
        </Card>
        <Card style={{ width: '100%', marginLeft: 16 }}>
          <SprintKeyActivityProgressLog
            userContributionData={userContributionData}
            teamId={teamId}
            tenantId={tenantId}
            sprint={sprint}
          />
        </Card>
      </div>
    </Spin>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_SUMMARY = gql`
  fragment SprintKeyActivitySummary_MitemAccelerationMeetingSprints on MitemAccelerationMeetingSprints {
    currentSprint {
      id
      ...SprintKeyActivityTeamSummary_MitemSprint
    }
    previousSprint {
      id
      finalized
      ...SprintKeyActivityTeamSummary_MitemSprint
    }
  }
`;
