import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const MarkAsCompleteIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 60 60"
    fill="currentColor"
  >
    <g>
      <path
        d="M30 27.5C31.375 27.5 32.5521 27.0104 33.5312 26.0312C34.5104 25.0521 35 23.875 35 22.5C35 21.125 34.5104 19.9479 33.5312 18.9688C32.5521 17.9896 31.375 17.5 30 17.5C28.625 17.5 27.4479 17.9896 26.4688 18.9688C25.4896 19.9479 25 21.125 25 22.5C25 23.875 25.4896 25.0521 26.4688 26.0312C27.4479 27.0104 28.625 27.5 30 27.5ZM17.5 52.5V47.5H27.5V39.75C25.4583 39.2917 23.6354 38.4271 22.0312 37.1562C20.4271 35.8854 19.25 34.2917 18.5 32.375C15.375 32 12.7604 30.6354 10.6562 28.2812C8.55208 25.9271 7.5 23.1667 7.5 20V17.5C7.5 16.125 7.98958 14.9479 8.96875 13.9688C9.94792 12.9896 11.125 12.5 12.5 12.5H17.5V7.5H42.5V12.5H47.5C48.875 12.5 50.0521 12.9896 51.0312 13.9688C52.0104 14.9479 52.5 16.125 52.5 17.5V20C52.5 23.1667 51.4479 25.9271 49.3438 28.2812C47.2396 30.6354 44.625 32 41.5 32.375C40.75 34.2917 39.5729 35.8854 37.9688 37.1562C36.3646 38.4271 34.5417 39.2917 32.5 39.75V47.5H42.5V52.5H17.5ZM17.5 27V17.5H12.5V20C12.5 21.5833 12.9583 23.0104 13.875 24.2812C14.7917 25.5521 16 26.4583 17.5 27ZM30 35C32.0833 35 33.8542 34.2708 35.3125 32.8125C36.7708 31.3542 37.5 29.5833 37.5 27.5V12.5H22.5V27.5C22.5 29.5833 23.2292 31.3542 24.6875 32.8125C26.1458 34.2708 27.9167 35 30 35ZM42.5 27C44 26.4583 45.2083 25.5521 46.125 24.2812C47.0417 23.0104 47.5 21.5833 47.5 20V17.5H42.5V27Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const MarkAsCompleteIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={MarkAsCompleteIconSvg} {...props} />
);
