import './MilestoneStatusIcon.less';
import {
  MilestoneStatus,
  MilestoneStatusIcon_MilestoneFragment,
} from '../generated/graphql';
import { match } from 'ts-pattern';
import { CheckIcon } from '../icons/overviewIcons/CheckIcon';
import { WarningIcon } from '../icons/performanceIcons/WarningIcon';
import { UpcomingEventIcon } from '../icons/overviewIcons/UpcomingEventIcon';
import { ArchiveIcon } from '../icons/initiativeIcons/ArchiveIcon';
import { gql } from '@apollo/client';

interface Props {
  metadata: MilestoneStatusIcon_MilestoneFragment['metadata'];
}

export const MilestoneStatusIcon = ({ metadata }: Props) => {
  const status = match(metadata)
    .with({ archived: true }, () => ({
      icon: ArchiveIcon,
      className: 'MilestoneStatusIcon--ARCHIVED',
    }))
    .with({ status: MilestoneStatus.COMPLETED }, () => ({
      icon: CheckIcon,
      className: 'MilestoneStatusIcon--COMPLETED',
    }))
    .with({ status: MilestoneStatus.OVERDUE }, () => ({
      icon: WarningIcon,
      className: 'MilestoneStatusIcon--OVERDUE',
    }))
    .with({ status: MilestoneStatus.UPCOMING }, () => ({
      icon: UpcomingEventIcon,
      className: 'MilestoneStatusIcon--PLANNED',
    }))

    .exhaustive();

  return <status.icon className={status.className} />;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_METADATA_FRAGMENT = gql`
  fragment MilestoneStatusIcon_Milestone on Milestone {
    metadata {
      status
      archived
    }
  }
`;
