import { ColumnProps } from 'antd/es/table';
import { SkaCard } from './SkaCard';
import { Table } from 'antd';
import { statusColorHelper } from '../../../../../../../../services/statusColorHelper';
import { stringSort } from '../../../../../../../../services/stringSort';
import { useTranslation } from 'react-i18next';
import { getTeamMembersData } from './sprintProgressTable/sprintProgressTableCalculations';
import { useTeamMembers } from '../../../../../../../../hooks/useTeamMembersV2';
import { gql } from '@apollo/client';
import { SprintProgressTableSkaTimeStatusFragment } from '../../../../../../../../generated/graphql';
import { SelectedSprint } from './SprintSelector';
import { UserAvatar } from '../../../../../../../../components/UserAvatar';
import { friendlyUsername } from '../../../../../../../../services/friendlyUsername';
import {
  checkIfActiveSprint,
  checkIfPlannedSprint,
} from '../utils/sprintStatusCheck';
import './SprintProgressTable.less';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_PROGRESS_TABLE_FRAGMENT = gql`
  fragment SprintProgressTableSkaTimeStatus on SkaTimeStatus {
    id
    status
    deadline
    owner {
      id
      name
    }
  }
`;

export interface TeamMember {
  id: string;
  initials: string;
  name?: string | null;
  email: string;
  displayName?: string | null;
}

interface TeamMemberData {
  user: TeamMember;
  completedActivities: number;
  overdueActivities: number;
  upcomingActivities: number;
  doneVsPlan: number | null;
}

interface ExcludedColumnProps<T> extends ColumnProps<T> {
  excluded?: boolean;
}

interface Props {
  teamId: string;
  sprintKeyActivities: SprintProgressTableSkaTimeStatusFragment[];
  selectedSprint: SelectedSprint | null;
}

export const SprintProgressTable = ({
  teamId,
  sprintKeyActivities,
  selectedSprint,
}: Props) => {
  const { t } = useTranslation();
  const { usersInTeam, loading } = useTeamMembers(teamId);

  const isPlannedSprint = checkIfPlannedSprint(selectedSprint);
  const isActiveSprint = checkIfActiveSprint(selectedSprint);

  const teamMembersData = getTeamMembersData(
    sprintKeyActivities,
    usersInTeam.map((teamMember) => teamMember.user)
  );

  const columns: ExcludedColumnProps<TeamMemberData>[] = [
    {
      key: 'name',
      title: <h5>{t('SprintProgressTable.teamMember')}</h5>,
      dataIndex: 'name',
      align: 'left',
      width: '50%',
      sorter: (a, b) => stringSort(a.user.name, b.user.name),
      render: (_, record) => {
        return (
          <div>
            <UserAvatar user={record.user} size="small" />
            <span className="ml--l">{friendlyUsername(record.user)}</span>
          </div>
        );
      },
    },
    {
      key: 'completedActivities',
      title: <h5>{t('common.sprintKeyActivity.statuses.completed')}</h5>,
      dataIndex: 'completedActivities',
      align: 'center',
      sorter: (a, b) =>
        stringSort(a.completedActivities, b.completedActivities),
      excluded: isPlannedSprint,
    },
    {
      key: 'overdueActivities',
      title: <h5>{t('common.sprintKeyActivity.statuses.overdue')}</h5>,
      dataIndex: 'overdueActivities',
      align: 'center',
      sorter: (a, b) => stringSort(a.overdueActivities, b.overdueActivities),
      excluded: isPlannedSprint,
    },
    {
      key: 'upcomingActivities',
      title: <h5>{t('common.sprintKeyActivity.statuses.upcoming')}</h5>,
      dataIndex: 'upcomingActivities',
      align: 'center',
      sorter: (a, b) => stringSort(a.upcomingActivities, b.upcomingActivities),
      excluded: selectedSprint?.finalized,
      width: isPlannedSprint ? '10%' : undefined,
    },
    {
      key: 'doneVsPlan',
      title: <h5>{t('common.doneVsPlan')}</h5>,
      dataIndex: 'doneVsPlan',
      align: 'center',
      sorter: (a, b) => stringSort(a.doneVsPlan, b.doneVsPlan),
      defaultSortOrder: 'descend',
      excluded: isPlannedSprint || isActiveSprint,
      render: (doneVsPlan) => {
        return (
          <div
            className="AllocationAndProgressTable__performanceValue text-c"
            style={{
              color: statusColorHelper(doneVsPlan),
            }}
          >
            {doneVsPlan === null ? '-' : doneVsPlan + '%'}
          </div>
        );
      },
    },
  ];
  return (
    <SkaCard>
      <h3
        className="SprintProgressTable__header mb--xl"
        data-intercom-target="Sprint progress header"
      >
        {t('SprintProgressTable.header')}
      </h3>

      <Table
        dataSource={teamMembersData}
        columns={columns.filter((c) => !c.excluded)}
        rowKey={(row) => row.user.id}
        loading={loading}
        pagination={false}
      />
    </SkaCard>
  );
};
