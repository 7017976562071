import Icon, {
  IconComponentProps,
} from '@ant-design/icons/lib/components/Icon';

const ShieldXSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    width="1em"
    height="1em"
  >
    <g>
      <path d="m8.25 12.917 1.75-1.75 1.75 1.75 1.167-1.167-1.75-1.75 1.75-1.75-1.167-1.167L10 8.833l-1.75-1.75L7.083 8.25 8.833 10l-1.75 1.75 1.167 1.167ZM10 18.333c-1.93-.486-3.524-1.593-4.781-3.323-1.257-1.729-1.886-3.649-1.886-5.76V4.167L10 1.667l6.667 2.5V9.25c0 2.111-.629 4.031-1.886 5.76-1.257 1.73-2.85 2.837-4.781 3.323Zm0-1.75c1.444-.458 2.639-1.375 3.583-2.75A7.915 7.915 0 0 0 15 9.25V5.313l-5-1.875-5 1.875V9.25c0 1.68.472 3.208 1.417 4.583.944 1.375 2.139 2.292 3.583 2.75Z" />
    </g>
  </svg>
);

export const ShieldXIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={ShieldXSvg} {...props} />
);
