import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { AchievementStatus } from '../../../generated/graphql';
import { MyAchievementsCard } from './components/MyAchievementsCard';

interface Props {
  userId: string;
  tenantId?: string;
}

export const MyAchievementsPage = ({ userId, tenantId }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('Sidebar.myActivities')} />
      <div
        data-intercom-target="Current Period Achievements List Area"
        className="mb--xl"
      >
        <MyAchievementsCard
          userId={userId}
          tenantId={tenantId}
          title={t('MyAchievementsPage.currentPeriod')}
          achievementStatus={AchievementStatus.DELETABLE_BY_USER}
        />
      </div>
      <div data-intercom-target="Previous Period Achievements List Area">
        <MyAchievementsCard
          userId={userId}
          tenantId={tenantId}
          title={t('MyAchievementsPage.pastPeriods')}
          achievementStatus={AchievementStatus.NOT_DELETABLE_BY_USER}
        />
      </div>
    </>
  );
};
