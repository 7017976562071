import { Btn } from '../../../../components/Button';
import TeamEntityPlaceholderPic from './images/teamEntityPlaceholder.svg';
import './TeamEntityPlaceholder.less';

interface Props {
  header: string;
  text?: string;
  buttonText: string;
  url: string;
  size?: 'small' | 'default';
}

export const TeamEntityPlaceholder = ({
  header,
  text,
  url,
  buttonText,
  size,
}: Props) => {
  const styles = {
    width: size === 'small' ? '200px' : '400px',
    height: size === 'small' ? '150px' : '350px',
  };

  return (
    <>
      <img
        src={TeamEntityPlaceholderPic}
        alt="No Weekly Key Activity"
        width={styles.width}
        height={styles.height}
      />
      <div className="TeamEntityPlaceholder__width flx flx--jc-center flx--column ml--xl">
        {size === 'small' ? (
          <h3 className="mb--l">{header}</h3>
        ) : (
          <h2 className="mb--l">{header}</h2>
        )}

        {text && <span className="txt--secondary mb--l">{text}</span>}
        <div>
          <Btn
            type="primary"
            size={size === 'small' ? 'small' : 'middle'}
            href={url}
            target="_blank"
          >
            {buttonText}
          </Btn>
        </div>
      </div>
    </>
  );
};
