import { ApolloError } from '@apollo/client';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { howweErrorParser } from '../services/howweErrorParser';

interface Props {
  error?: ApolloError;
  title?: string;
}

export const ErrorAlert = ({ error, title }: Props) => {
  const howweErrors = howweErrorParser(error);
  const { t } = useTranslation();
  if (!howweErrors) return null;

  return (
    <Alert
      type="error"
      className="mt"
      message={title ?? t('ErrorAlert.defaultTitle')}
      description={
        <ul>
          {howweErrors.map((e, i) => (
            <li key={i}>{e.translation}</li>
          ))}
        </ul>
      }
    />
  );
};
