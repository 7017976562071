import Icon from '@ant-design/icons';

const SubinitiativeIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.99967 12V10H6.33301V3.33333H4.99967V5.33333H0.333008V0H4.99967V2H8.99967V0H13.6663V5.33333H8.99967V3.33333H7.66634V8.66667H8.99967V6.66667H13.6663V12H8.99967ZM10.333 4H12.333V1.33333H10.333V4ZM10.333 10.6667H12.333V8H10.333V10.6667ZM1.66634 4H3.66634V1.33333H1.66634V4Z" />
  </svg>
);

export const SubinitiativeIcon = (props: any) => (
  <Icon component={SubinitiativeIconSvg} {...props} />
);
