import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const TargetIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      d="M9 17.333a8.115 8.115 0 0 1-3.25-.657 8.415 8.415 0 0 1-2.646-1.78 8.416 8.416 0 0 1-1.781-2.647A8.114 8.114 0 0 1 .667 9c0-1.152.218-2.236.656-3.25a8.415 8.415 0 0 1 1.781-2.645A8.415 8.415 0 0 1 5.75 1.322 8.115 8.115 0 0 1 9 .666c1.153 0 2.236.219 3.25.656a8.415 8.415 0 0 1 2.646 1.782 8.416 8.416 0 0 1 1.781 2.645A8.115 8.115 0 0 1 17.333 9a8.114 8.114 0 0 1-.656 3.25 8.416 8.416 0 0 1-1.781 2.646 8.415 8.415 0 0 1-2.646 1.781 8.115 8.115 0 0 1-3.25.657Zm0-1.667c1.861 0 3.438-.646 4.73-1.938 1.29-1.291 1.937-2.867 1.937-4.729 0-1.86-.646-3.437-1.938-4.729C12.438 2.98 10.861 2.333 9 2.333c-1.861 0-3.437.646-4.73 1.937C2.98 5.562 2.334 7.138 2.334 9c0 1.86.646 3.437 1.938 4.729C5.563 15.02 7.139 15.665 9 15.665Zm0-1.667c-1.389 0-2.57-.486-3.542-1.458S4 10.388 4 8.999c0-1.389.486-2.57 1.458-3.541C6.431 4.485 7.611 3.999 9 3.999c1.389 0 2.57.486 3.542 1.459C13.514 6.43 14 7.61 14 8.999c0 1.39-.486 2.57-1.458 3.542-.973.972-2.153 1.458-3.542 1.458Zm0-1.666a3.21 3.21 0 0 0 2.354-.98A3.21 3.21 0 0 0 12.334 9a3.21 3.21 0 0 0-.98-2.354A3.21 3.21 0 0 0 9 5.666a3.21 3.21 0 0 0-2.354.98 3.21 3.21 0 0 0-.98 2.353c0 .917.327 1.702.98 2.354a3.21 3.21 0 0 0 2.354.98Zm0-1.667c-.458 0-.85-.163-1.177-.49A1.605 1.605 0 0 1 7.333 9c0-.458.164-.85.49-1.177.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.327.327.49.719.49 1.177 0 .459-.163.851-.49 1.177-.326.327-.719.49-1.177.49Z"
      fill="currentColor"
    />
  </svg>
);

export const TargetIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={TargetIconSvg} {...props} />
);
