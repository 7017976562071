import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { FullscreenModal } from '../../FullscreenModal';

import { gql, useMutation } from '@apollo/client';
import { without } from '../../../services/without';
import {
  CreateWeeklyKeyActivityDocument,
  GetTeamKeyActivitiesPreviewDocument,
} from '../../../generated/graphql';
import { toCumulativePeriods } from '../../../services/akpiUtils';
import {
  KeyActivityData,
  WeeklyKeyActivity,
} from './components/WeeklyKeyActivity';
import { howweErrorParser } from '../../../services/howweErrorParser';
import { showNotification } from '../../../services/fetchNotificationProperties';
import { KeyActivityModalState } from '../KeyActivitySetupPage';

interface Props {
  isOpen: boolean;
  teamId: string;
  tenantId?: string;
  keyActivityModalState: KeyActivityModalState | null;
  onClose: () => void;
}

export interface PeriodWithMandatoryGoal {
  targetDate: string;
  label: string;
  goal: number;
  timePeriodIndex: number;
  actual?: number;
}

export const CreateWeeklyModal = ({
  isOpen,
  onClose,
  teamId,
  tenantId,
  keyActivityModalState,
}: Props) => {
  const { t } = useTranslation();
  const [saveWeeklyKeyActivity, { error, loading }] = useMutation(
    CreateWeeklyKeyActivityDocument,
    {
      onCompleted: (response) => {
        showNotification('success', {
          message: <strong>{t('CreateKeyActivityModal.saveSuccess')}</strong>,
          description: response.createWeeklyKeyActivity.name,
        });
        onClose();
      },
      refetchQueries: () => [
        {
          query: GetTeamKeyActivitiesPreviewDocument,
          variables: { teamId, tenantId },
        },
      ],
    }
  );
  const prefilledData =
    keyActivityModalState?.type === 'CreateWeeklyKeyActivity' &&
    keyActivityModalState.weeklyKeyActivityToCopy
      ? {
          ...keyActivityModalState.weeklyKeyActivityToCopy,
          supportsMigsDetails: [],
          ignoreListedUsers: [],
        }
      : undefined;

  useEffect(() => {
    (window as any).Intercom?.('update', {
      hide_default_launcher: isOpen,
    });
  }, [isOpen]);

  const handleSave = (keyActivityData: KeyActivityData) => {
    const periodDataToSave = toCumulativePeriods(
      keyActivityData.periodData ?? []
    ) as PeriodWithMandatoryGoal[];
    const weeklyKeyActivityToSave = {
      ...without(
        keyActivityData,
        'startDate',
        'endDate',
        'supportsMigsDetails'
      ),
      periodData:
        periodDataToSave?.map((l) => without(l, 'label', 'timePeriodIndex')) ??
        [],
      supportsMigs: keyActivityData.supportsMigsDetails?.map((mig) => ({
        domainId: without(mig.domainId, '__typename'),
      })),
    };

    saveWeeklyKeyActivity({
      variables: {
        teamId,
        weeklyKeyActivity: weeklyKeyActivityToSave,
        tenantId,
      },
    });
  };

  return (
    <FullscreenModal
      open={isOpen}
      onCancel={onClose}
      keyboard={false}
      destroyOnClose
      title={
        <div className="flx flx--column">
          <Typography.Text>
            {t('CreateKeyActivityModal.createKeyActivityTitle')}
          </Typography.Text>
        </div>
      }
      footer={null}
    >
      <WeeklyKeyActivity
        weeklyKeyActivityData={prefilledData}
        teamId={teamId}
        tenantId={tenantId}
        onClose={onClose}
        handleSave={handleSave}
        saveErrors={howweErrorParser(error)}
        saveLoading={loading}
        startDateDisabled={false}
      />
    </FullscreenModal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_WEEKLY_KEY_ACTIVITY_V2 = gql`
  mutation createWeeklyKeyActivity(
    $teamId: ID!
    $tenantId: ID
    $weeklyKeyActivity: WeeklyKeyActivityInput!
  ) {
    createWeeklyKeyActivity(
      teamId: $teamId
      tenantId: $tenantId
      weeklyKeyActivity: $weeklyKeyActivity
    ) {
      name
      id
      description
      acceptanceCriteria
      commitmentStrategyEnabled
      startDate
      endDate
      unit
      periodData {
        targetDate
        goal
      }
      supportsMigsDetails {
        id
        name
        lastOutcomeDate
        domainId {
          itemId
          tenantId
          teamId
        }
        team {
          id
          name
        }
      }
    }
  }
`;
