import Icon from '@ant-design/icons';

const ErrorIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.73145 10.1751C6.92033 10.1751 7.07867 10.1112 7.20645 9.98346C7.33422 9.85569 7.39811 9.69735 7.39811 9.50846C7.39811 9.31957 7.33422 9.16124 7.20645 9.03346C7.07867 8.90569 6.92033 8.8418 6.73145 8.8418C6.54256 8.8418 6.38422 8.90569 6.25645 9.03346C6.12867 9.16124 6.06478 9.31957 6.06478 9.50846C6.06478 9.69735 6.12867 9.85569 6.25645 9.98346C6.38422 10.1112 6.54256 10.1751 6.73145 10.1751ZM6.06478 7.50846H7.39811V3.50846H6.06478V7.50846ZM4.23145 12.8418L0.731445 9.3418V4.3418L4.23145 0.841797H9.23145L12.7314 4.3418V9.3418L9.23145 12.8418H4.23145ZM4.79811 11.5085H8.66478L11.3981 8.77513V4.90846L8.66478 2.17513H4.79811L2.06478 4.90846V8.77513L4.79811 11.5085Z" />
  </svg>
);

export const ErrorIcon = (props: any) => (
  <Icon component={ErrorIconSvg} {...props} />
);
