import Icon from '@ant-design/icons';
import React from 'react';

const MigSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 -5 30 40"
    fill="currentColor"
  >
    <path
      d="M15.8,2.2C8.3,2.2,2.2,8.3,2.2,15.8s6.1,13.6,13.6,13.6c7.5,0,13.6-6.1,13.6-13.6S23.3,2.2,15.8,2.2 M15.8,31.6
	C7.1,31.6,0,24.5,0,15.8S7.1,0,15.8,0c8.7,0,15.8,7.1,15.8,15.8S24.5,31.6,15.8,31.6"
    />
    <path
      d="M15.8,6.3c-5.2,0-9.5,4.2-9.5,9.5s4.2,9.5,9.5,9.5c5.2,0,9.5-4.2,9.5-9.5C25.2,10.6,21,6.3,15.8,6.3 M15.8,27.5
	c-6.4,0-11.7-5.2-11.7-11.7c0-6.4,5.2-11.7,11.7-11.7s11.7,5.2,11.7,11.7S22.2,27.4,15.8,27.5"
    />
    <path
      d="M15.8,10.5c-2.9,0-5.3,2.4-5.3,5.3s2.4,5.3,5.3,5.3s5.3-2.4,5.3-5.3C21.1,12.9,18.7,10.5,15.8,10.5 M15.8,23.3
	c-4.1,0-7.5-3.4-7.5-7.5c0-4.1,3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5C23.3,19.9,19.9,23.3,15.8,23.3"
    />
    <path d="M14.7,16.9h2.2v-2.2h-2.2V16.9z" />
  </svg>
);

export const MigIcon = (props: any) => <Icon component={MigSvg} {...props} />;
