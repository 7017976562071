import { ComponentType } from 'react';
import './OverviewProgressCard.less';
import { ProgressCard } from './ProgressCard';

interface Props {
  SvgIcon: ComponentType;
  iconColor: string;
  title: string;
  numberOfCompletedActivities: number | null;
  numberOfSprintKeyActivities: number;
  valueColor?: string;
}

export const CompletionCard = ({
  SvgIcon,
  iconColor,
  title,
  numberOfCompletedActivities,
  numberOfSprintKeyActivities,
  valueColor,
}: Props) => {
  const value =
    numberOfCompletedActivities !== null
      ? `${numberOfCompletedActivities}/${numberOfSprintKeyActivities}`
      : '-';

  return (
    <ProgressCard SvgIcon={SvgIcon} color={iconColor}>
      <div className="OverviewProgressCard flx flx--ai-center flx--jc-space-between">
        <h4 className="OverviewProgressCard__title">{title}</h4>
        <div
          className="OverviewProgressCard__value pl--l"
          style={{ color: valueColor ? valueColor : undefined }}
        >
          {value}
        </div>
      </div>
    </ProgressCard>
  );
};
