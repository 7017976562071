import React from 'react';
import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';
import './ScrollableCard.less';

interface Props extends CardProps {}

export const ScrollableCard: React.FC<Props> = ({
  children,
  className,
  styles,
  ...rest
}) => {
  const classes = 'ScrollableCard ' + (className ?? '');

  return (
    <Card
      className={classes}
      styles={{ ...styles, body: { overflow: 'auto', maxHeight: '100%' } }}
      {...rest}
    >
      {children}
    </Card>
  );
};
