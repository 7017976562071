import {
  EditOutlined,
  EllipsisOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Dropdown, Empty, Typography } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Action,
  MigSetupTableMigFragment,
  MigStatus,
} from '../../../generated/graphql';
import { useCountdown } from '../../../hooks/useCountdown';
import { ArchiveOutlinedIcon } from '../../../icons/ArchiveIcon';
import { UnarchiveOutlinedIcon } from '../../../icons/UnarchiveIcon';
import { friendlyDate } from '../../../services/dateFormats';
import { stringSort } from '../../../services/stringSort';
import { ExpandArrow } from '../../ExpandArrow';
import { MigModalState } from '../MigSetupPage';
import { MigSetupTablePreview } from './migSetupTable/MigSetupTablePreview';
import { Btn } from '../../Button';
import { useTeamPermissions } from '../../../usePermissions';
import dayjs from 'dayjs';
import { gql } from '@apollo/client';

interface Props {
  teamId: string;
  tenantId?: string;
  status: MigStatus;
  setMigModalState: (setMigModalState: MigModalState | null) => void;
  teamMigs?: MigSetupTableMigFragment[];
}

export const TEAM_PERFORMANCE_MIG_CARD_MIG_FRAGMENT = gql`
  fragment MigSetupTableMig on MigV2 {
    id
    name
    status
    startDate
    mirroredFromTeam(teamId: $teamId) {
      id
      name
    }
    domainId {
      itemId
      teamId
      tenantId
    }
    ...MigSetupTablePreviewMig
  }
`;

interface ExcludedColumnProps<T> extends ColumnProps<T> {
  excluded?: boolean;
}

export const MigSetupTable = ({
  teamId,
  tenantId,
  status,
  teamMigs,
  setMigModalState,
}: Props) => {
  const { t } = useTranslation();
  const { isAllowed } = useTeamPermissions({
    requestedAction: { resource: 'mig', action: Action.UPDATE },
    teamId: teamId,
    tenantId: tenantId,
  });
  const columns: ExcludedColumnProps<MigSetupTableMigFragment>[] = [
    {
      title: t('common.title'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => {
        return stringSort(a.name, b.name);
      },
      render: (_, mig) => {
        return mig.mirroredFromTeam ? (
          <div>
            <strong>{mig.name}</strong>
            <div className="font-size--sm">
              {t('common.mig.managedBy')}
              {mig.mirroredFromTeam.name}
            </div>
          </div>
        ) : (
          <strong>{mig.name}</strong>
        );
      },
    },

    {
      title: t('common.startDate'),
      sorter: (a, b) => dayjs(a.startDate).diff(b.startDate),
      defaultSortOrder: 'ascend',
      dataIndex: 'startDate',
      key: 'startDate',
      excluded: status !== MigStatus.UPCOMING,
      render: (startDate) => {
        return (
          <div className="flx">
            {friendlyDate(startDate)}
            <Typography.Text type="secondary" className="ml flx">
              (<TimeToStart startDate={startDate} />
              <div className="space--l">{t('MigSetupTable.timeLeft')}</div>)
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: t('MigSetupTable.endDate'),
      sorter: (a, b) => dayjs(a.lastOutcomeDate).diff(b.lastOutcomeDate),
      defaultSortOrder: 'ascend',
      dataIndex: 'lastOutcomeDate',
      key: 'lastOutcomeDate',
      excluded: status !== MigStatus.ENDED,
      render: (_, { lastOutcomeDate }) => {
        return lastOutcomeDate;
      },
    },
    {
      title: t('common.actions'),
      key: 'actions',
      width: 90,
      excluded: !isAllowed,
      render: (_, teamMig) => {
        return (
          <MigTableActions
            teamMig={teamMig}
            tableStatus={status}
            setMigModalState={setMigModalState}
          />
        );
      },
    },
    Table.EXPAND_COLUMN,
  ];

  return (
    <Table
      size="small"
      dataSource={teamMigs}
      columns={columns.filter((c) => c.excluded !== true)}
      bordered
      rowKey={(teamMig) => teamMig.id}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span className="center-content pb pt">
                {t('common.mig.noData')}
              </span>
            }
          />
        ),
      }}
      expandable={{
        expandRowByClick: true,
        expandedRowRender: (teamMig) => {
          return <MigSetupTablePreview teamMig={teamMig} />;
        },
        expandIcon: ({ expanded, onExpand, record }) => (
          <ExpandArrow
            onClick={(e) => onExpand(record, e)}
            expanded={expanded}
          />
        ),
      }}
    />
  );
};

interface MigTableActionsProps {
  teamMig: MigSetupTableMigFragment;
  tableStatus: MigStatus;
  setMigModalState: (setMigModalState: MigModalState | null) => void;
}

const MigTableActions = ({
  teamMig,
  setMigModalState,
  tableStatus,
}: MigTableActionsProps) => {
  const { t } = useTranslation();

  if (tableStatus === MigStatus.ARCHIVED && teamMig.mirroredFromTeam == null) {
    return (
      <div onClick={(event) => event.stopPropagation()}>
        <Btn
          size="small"
          icon={<UnarchiveOutlinedIcon />}
          onClick={() =>
            setMigModalState({
              id: teamMig.domainId.itemId,
              type: 'UpdateMigStatus',
              status: MigStatus.ARCHIVED,
            })
          }
        >
          {t('common.mig.reactivateMIG')}
        </Btn>
      </div>
    );
  }

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Dropdown
        menu={{
          items: teamMig.mirroredFromTeam
            ? [
                {
                  key: 'RemoveMirrorMig',
                  label: t('common.mig.removeMirrorMig'),
                  icon: <StopOutlined />,
                  onClick: () =>
                    setMigModalState({
                      type: 'RemoveMirrorMig',
                      domainId: teamMig.domainId,
                    }),
                },
              ]
            : [
                {
                  key: 'EditMig',
                  icon: <EditOutlined className="keyActivityPage__icon" />,
                  label: t('common.edit'),
                  onClick: () =>
                    setMigModalState({
                      type: 'EditMig',
                      id: teamMig.domainId.itemId,
                    }),
                },
                {
                  key: 'UpdateStatusMig',
                  icon: (
                    <ArchiveOutlinedIcon className="keyActivityPage__icon" />
                  ),
                  label: t('common.archive'),
                  onClick: () =>
                    setMigModalState({
                      type: 'UpdateMigStatus',
                      id: teamMig.domainId.itemId,
                      status: teamMig.status,
                    }),
                },
              ],
        }}
        placement="bottom"
      >
        <Btn size="small" icon={<EllipsisOutlined rotate={90} />} />
      </Dropdown>
    </div>
  );
};

interface TimeToStartProps {
  startDate: Moment;
}

const TimeToStart = ({ startDate }: TimeToStartProps) => {
  const { timeDiff } = useCountdown(startDate);
  return <div>{timeDiff}</div>;
};
