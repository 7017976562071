import { gql, useQuery } from '@apollo/client';
import {
  GetTeamsWithMigsDocument,
  MigStatus,
} from '../../../../generated/graphql';

export const useTeamsWithMigsPreview = (tenantId?: string) => {
  const { loading, data, error } = useQuery(GetTeamsWithMigsDocument, {
    variables: { tenantId },
  });

  return {
    teamsWithMigs: data,
    error,
    loading,
  };
};
export interface TeamWithMig {
  id: string;
  name: string;
  teamMigs: {
    id: string;
    name: string;
    status: MigStatus;
    lastOutcomeDate: string;
    domainId: {
      itemId: string;
      teamId: string;
      tenantId: string;
    };
  }[];
  parent?: {
    id: string;
    name: string;
  } | null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAMS_WITH_MIGS = gql`
  query getTeamsWithMigs($tenantId: ID) {
    teams(tenantId: $tenantId, teamFilter: { active: true }) {
      name
      id
      teamMigs(status: [ACTIVE, UPCOMING]) {
        id
        name
        lastOutcomeDate
        status
        domainId {
          itemId
          teamId
          tenantId
        }
      }
      parent {
        name
        id
      }
    }
  }
`;
