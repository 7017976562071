import { gql, useSuspenseQuery } from '@apollo/client';
import { GetInitiativesForNavigationDocument } from '../../../../generated/graphql';
import { InitiativeTreeWrapper } from './initiativeTreeWrapper/InitiativeTreeWrapper';
import { useTranslation } from 'react-i18next';
import { InitiativesNavigationSkeleton } from './InitiativesNavigation.skeleton';
import { useMemo } from 'react';
import { navigationTreeBuilder } from './initiativeTreeWrapper/navigationTreeBuilder';

export const InitiativesNavigation = () => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(GetInitiativesForNavigationDocument);

  const { initiatives } = data.tenantInitiatives2;
  const { links } = data.listTenantLinks;

  const memoizedNavigationTree = useMemo(
    () =>
      navigationTreeBuilder(
        initiatives
          .filter((i) => i.metadata.archived == false)
          .sort((a, b) => a.name.localeCompare(b.name))
      ),
    [initiatives]
  );

  const companyInitiatives = memoizedNavigationTree.filter((i) =>
    links.some((l) => l.domainId.itemId === i.baseInitiative.domainId.itemId)
  );

  const otherInitiatives = memoizedNavigationTree.filter(
    (i) => !companyInitiatives.includes(i)
  );

  return (
    <div>
      <div className="mb">
        <h3 className="mb--xs">
          {t('InitiativesNavigation.companyInitiatives')}
        </h3>
        <InitiativeTreeWrapper treeData={companyInitiatives} />
      </div>
      <div>
        <h3 className="mb--xs">
          {t('InitiativesNavigation.otherInitiatives')}
        </h3>
        <InitiativeTreeWrapper treeData={otherInitiatives} />
      </div>
    </div>
  );
};

InitiativesNavigation.skeleton = InitiativesNavigationSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVES_NAVIGATION = gql`
  query getInitiativesForNavigation($tenantId: ID) {
    tenantInitiatives2(tenantId: $tenantId) {
      initiatives {
        ...initiativeTreeWrapper__Initiative2
      }
    }
    listTenantLinks(tenantId: $tenantId, type: INITIATIVE) {
      links {
        id
        domainId {
          itemId
          tenantId
        }
      }
    }
  }
`;
