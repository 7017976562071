import { useCountdown } from '../../../../../hooks/useCountdown';
import { Typography, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TitleWithTooltip } from '../../../../../appPages/team/setup/components/TitleWithTooltip';

interface Props {
  periodEndTime?: string | null;
}

export const TeamSectionCountdown = ({ periodEndTime }: Props) => {
  const { t } = useTranslation();
  const { timeDiff, deadlineMissed } = useCountdown(periodEndTime);

  if (periodEndTime == null) return null;

  if (deadlineMissed) {
    return (
      <Typography.Text type="secondary">
        <Tooltip
          title={
            <div className="flx flx--column">
              {t('TeamSection.timeOverdueInfo')}
            </div>
          }
        >
          <span>
            {t('TeamSection.pastDeadline', {
              timeOverDue: timeDiff,
            })}
            <WarningOutlined className="TeamSection__ReportInfoIcon ml--s" />
          </span>
        </Tooltip>
      </Typography.Text>
    );
  }

  return (
    <Typography.Text type="secondary">
      <TitleWithTooltip
        tooltipContent={t('TeamSection.timeRemainingInfo')}
        title={t('TeamSection.dueIn', {
          timeLeft: timeDiff,
        })}
      />
    </Typography.Text>
  );
};
