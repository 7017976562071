import { CheckCircleOutlined, DownOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Divider, Dropdown, Empty, Form, Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetTeamFullMigsV2Document,
  MigInterval,
  UpdateTeamMigActualDocument,
} from '../../generated/graphql';
import { friendlyDate } from '../../services/dateFormats';
import { showNotification } from '../../services/fetchNotificationProperties';
import { toMap } from '../../services/toMap';
import { ErrorAlert } from '../ErrorAlert';
import { NumberInput } from '../NumberInput';
import './UpdateMig.less';
import { Btn } from '../Button';
import dayjs from 'dayjs';
import { DisplayDate } from '../DisplayDate';

export interface Period {
  targetDate: string;
  goal: number;
  actual?: number;
  historic?: number;
}

export interface MigData {
  id: string;
  name: string;
  domainId: {
    itemId: string;
    teamId: string;
  };
  description?: string | null;
  lastOutcomeDate: string;
  timePeriodInterval: MigInterval;
  periodData: Period[];
  unit: string;
  isMirrored: boolean;
  rev: string;
}

interface Props {
  migData: MigData;
  teamId: string;
  onClose: () => void;
}

enum menuOptions {
  SHOWALL = 'SHOWALL',
  SHOWMISSING = 'SHOWMISSING',
}

export const UpdateMig = ({ migData: mig, teamId, onClose }: Props) => {
  const { t } = useTranslation();
  const today = dayjs();

  const periodType =
    mig.timePeriodInterval === MigInterval.WEEK ? 'week' : 'month';
  const numberOfPeriods =
    mig.timePeriodInterval === MigInterval.QUARTER ? 3 : 1;

  const currentPeriodThreshold = today.add(numberOfPeriods, periodType);

  const allPointsToCurrentDate = mig.periodData.filter((mp) =>
    dayjs(mp.targetDate).isSameOrBefore(currentPeriodThreshold)
  );
  const missingPointsToCurrentDate = mig.periodData.filter(
    (p) => p.actual == null && dayjs(p.targetDate).isSameOrBefore(today)
  );

  const initialFormData = toMap(allPointsToCurrentDate, (p) => p.targetDate);

  const [updateMig, { error, loading }] = useMutation(
    UpdateTeamMigActualDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: t('UpdateMig.saved'),
        });

        (window as any).Intercom?.('update', {
          last_updated_mig_at: new Date(),
        });
        onClose();
      },
      // TODO: Replace with a smaller query just for team performance
      refetchQueries: [
        {
          query: GetTeamFullMigsV2Document,
          variables: { teamId },
        },
      ],
    }
  );

  const [showFullTable, setShowFullTable] = useState(false);
  if (!mig) return null;

  const handleMenuClick = ({ key }: MenuInfo) => {
    if (key === menuOptions.SHOWALL) {
      setShowFullTable(true);
    } else setShowFullTable(false);
  };

  const handleSave = (values: { [targetDate: string]: { actual: number } }) => {
    const formPeriodData = mig.periodData.map((p) => {
      const formActual = values[p.targetDate]?.actual;
      const actual = formActual === undefined ? p.actual : formActual;
      return {
        targetDate: p.targetDate,
        actual,
        goal: p.goal,
        historic: p.historic,
      };
    });

    updateMig({
      variables: {
        teamId: teamId,
        migId: mig.domainId.itemId,
        rev: mig.rev,
        periodData: formPeriodData,
      },
    });
  };

  const columns: ColumnProps<Period>[] = [
    {
      title: t('UpdateMig.period'),
      dataIndex: 'targetDate',
      key: 'targetDate',
      render: (targetDate) => <DisplayDate date={targetDate} />,
    },
    {
      title: t('common.goal'),
      dataIndex: 'goal',
      key: 'goal',
    },
    {
      title: t('common.actual'),
      dataIndex: 'actual',
      key: 'actual',
      render: (_, period) => {
        return (
          <Form.Item name={[period.targetDate, 'actual']} noStyle>
            <NumberInput data-testid={`actual-input-${period.targetDate}`} />
          </Form.Item>
        );
      },
    },
  ];

  const menuItems = [
    {
      key: menuOptions.SHOWALL,
      label: (
        <span data-testid="show-all-btn">{t('UpdateMig.allPeriods')}</span>
      ),
    },
    {
      key: menuOptions.SHOWMISSING,
      label: t('UpdateMig.onlyMissingPeriodData'),
    },
  ];

  const updateDisabled =
    !showFullTable && missingPointsToCurrentDate.length === 0;

  return (
    <div className="UpdateMig">
      <Typography.Text strong>{t('UpdateMig.dueBy')}</Typography.Text>
      <Typography.Paragraph>
        {friendlyDate(mig.lastOutcomeDate)}
      </Typography.Paragraph>

      <Typography.Text strong>{t('common.description')}</Typography.Text>
      <Typography.Paragraph style={{ whiteSpace: 'pre-wrap' }}>
        {mig.description ?? '-'}
      </Typography.Paragraph>

      <Divider type="horizontal" />
      <div className="flx flx--jc-space-between flx--ai-center">
        <Typography.Text strong>
          {t('UpdateMig.updateMigActual')}
        </Typography.Text>
        <div>
          {t('UpdateMig.show')}
          <Dropdown
            menu={{ items: menuItems, onClick: handleMenuClick }}
            placement="bottomRight"
          >
            <Btn
              type="link"
              className="ant-dropdown-link pa--none"
              data-testid="toggle-all-dropdown"
            >
              {showFullTable
                ? t('UpdateMig.allPeriods')
                : t('UpdateMig.onlyMissingPeriodData')}
              <DownOutlined />
            </Btn>
          </Dropdown>
        </div>
      </div>
      <Form onFinish={handleSave} initialValues={initialFormData}>
        <Table
          size="small"
          bordered
          dataSource={
            showFullTable ? allPointsToCurrentDate : missingPointsToCurrentDate
          }
          columns={columns}
          pagination={false}
          rowKey={(m) => m.targetDate}
          locale={{
            emptyText: (
              <Empty
                image={false}
                imageStyle={{ height: 0 }}
                description={
                  <span className="center-content pb pt">
                    <CheckCircleOutlined
                      style={{ fontSize: '32px' }}
                      className="mb"
                    />
                    {t('UpdateMig.everythingUpToDate')}
                  </span>
                }
              />
            ),
          }}
        />

        <ErrorAlert error={error} title={t('UpdateMig.migSaveError')} />

        <div className="UpdateMig__footer">
          <Btn
            htmlType="submit"
            disabled={updateDisabled}
            type="primary"
            loading={loading}
            data-testid="update-mig-btn"
          >
            {t('UpdateMig.updateMigButton')}
          </Btn>
        </div>
      </Form>
    </div>
  );
};

export const UPDATE_TEAM_MIG = gql`
  mutation updateTeamMigActual(
    $teamId: ID!
    $migId: ID!
    $periodData: [MigPeriodInput!]!
    $rev: String!
    $tenantId: ID
  ) {
    updateTeamMigActual(
      teamId: $teamId
      migId: $migId
      periodData: $periodData
      rev: $rev
      tenantId: $tenantId
    ) {
      id
      rev
      periodData {
        targetDate
        goal
        actual
        historic
      }
    }
  }
`;
