import { Drawer } from 'antd';
import { Suspense, useState } from 'react';
import { useTenantDetails } from '../../../../../../../hooks/useTenantDetails';
import {
  CreateSkaForTenantInitiativeDocument,
  GetSprintKeyActivitiesForInitiativeDocument,
  GetSprintKeyActivitiesForMilestoneDocument,
  GetTenantBusinessPlanInitiativeDocument,
  SprintKaInput,
} from '../../../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { MIG_ASSOCIATION_OTHER } from '../../../../../../team/sprint/planning/SprintPlanningPage';
import { standardDateFormat } from '../../../../../../../services/dateFormats';
import { gql, useMutation } from '@apollo/client';
import { SprintKaForm } from '../../../../../../team/sprint/planning/components/SprintKaForm';
import { showNotification } from '../../../../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../../../../services/howweErrorParser';
import { DrawerTitle } from '../../../../../../../components/DrawerTitle';
import { SelectInitiativeTeam } from './SelectInitiativeTeam';

interface Props {
  contributingTeams: { id: string; name: string }[];
  initiativeId: string;
  open: boolean;
  onClose: () => void;
  milestone?: { id: string; domainId: { itemId: string; tenantId: string } };
}

export const CreateSkaForTenantInitiativeDrawer = ({
  initiativeId,
  open,
  milestone,
  onClose,
}: Props) => {
  const [teamId, setTeamId] = useState<string | undefined>();
  // this is used to force the form to re-render when a new SKA is created
  // this removes the need for the form instance here and reduces the complexity with form state
  const [createdSkas, setCreatedSkas] = useState(0);

  const { features } = useTenantDetails();
  const { t } = useTranslation();

  const [createSkaForInitiative, { loading: createPending }] = useMutation(
    CreateSkaForTenantInitiativeDocument,
    {
      onCompleted: (data) => {
        showNotification('success', {
          message: t('CreateSkaForTenantInitiativeDrawer.saveSuccess'),
          description: data.createSprintKeyActivity.name,
          placement: 'top',
        });
        setCreatedSkas((skas) => skas + 1);
      },
      refetchQueries: () => [
        {
          query: GetSprintKeyActivitiesForInitiativeDocument,
          variables: { initiativeId },
        },
        {
          query: GetSprintKeyActivitiesForMilestoneDocument,
          skip: !milestone,
          variables: { milestoneId: milestone?.domainId.itemId },
        },
        {
          query: GetTenantBusinessPlanInitiativeDocument,
          variables: { initiativeId },
        },
      ],

      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: t('CreateSkaForTenantInitiativeDrawer.saveError'),
          description: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
    }
  );

  const submit = (mitem: any, teamId: string) => {
    let tags = mitem.tags;
    let noMigAssociation: string | null | undefined = mitem.noMigAssociation;
    const {
      supportsMigId,
      supportsInitiativeIds,
      supportsMilestoneIds,
      ...rest
    } = mitem;
    const supportsInitiativeIdsToBeSubmitted =
      (supportsInitiativeIds as (string | null | undefined)[]) ?? [];

    const supportsMilestoneIdsToBeSubmitted =
      (supportsMilestoneIds as (string | null | undefined)[]) ?? [];

    const skaToSubmit: SprintKaInput = {
      ...rest,
      tags,
      supportsMigIds:
        supportsMigId === MIG_ASSOCIATION_OTHER || supportsMigId == null
          ? []
          : [{ id: supportsMigId }],
      noMigAssociation,
      deadline: standardDateFormat(mitem.deadline),
      supportsMilestoneIds: supportsMilestoneIdsToBeSubmitted
        .filter((i) => i != undefined)
        .filter((i) => i !== ''),
    };

    if (features.teamInitiativesEnabled) {
      skaToSubmit.supportsInitiativeIds = supportsInitiativeIdsToBeSubmitted
        .filter((i) => i != undefined)
        .filter((i) => i !== '');
    }

    if (features.tenantInitiativesEnabled) {
      skaToSubmit.supportsInitiative2Ids = supportsInitiativeIdsToBeSubmitted
        .filter((i) => i != undefined)
        .filter((i) => i !== '');
    }

    createSkaForInitiative({
      variables: {
        teamId,
        sprintKeyActivity: skaToSubmit,
      },
    });
  };

  return (
    <Drawer
      title={
        <DrawerTitle className="CreateMitemDrawer__title">
          {t('CreateSkaForTenantInitiativeDrawer.modalTitle')}
        </DrawerTitle>
      }
      open={open}
      onClose={() => {
        setTeamId(undefined);
        onClose();
      }}
      width={420}
      destroyOnClose
    >
      <Suspense fallback={<SelectInitiativeTeam.skeleton />}>
        {!teamId && (
          <SelectInitiativeTeam
            onConfirm={setTeamId}
            initiativeId={initiativeId}
            targetMilestone={milestone}
          />
        )}
      </Suspense>

      {teamId && (
        <SprintKaForm
          key={teamId + createdSkas}
          teamId={teamId}
          isEdit={false}
          onSubmit={(ska) => submit(ska, teamId)}
          submitPending={createPending}
          initialValues={{
            supportsMilestones: milestone ? [milestone] : [],
          }}
          onCancel={() => {
            onClose();
          }}
        />
      )}
    </Drawer>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_SKA_FOR_INITIATIVE = gql`
  mutation createSkaForTenantInitiative(
    $teamId: ID!
    $sprintKeyActivity: SprintKaInput!
  ) {
    createSprintKeyActivity(
      teamId: $teamId
      sprintKeyActivity: $sprintKeyActivity
    ) {
      ...CreateSprintKeyActivityDrawerWithButton__Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CreateSprintKeyActivityForInitiativeFragment = gql`
  fragment CreateSprintKeyActivityForInitiative__Mitem on Mitem {
    id
    name
    archived
    archivedAt
    completed
    completedAt
    deadline
    definitionOfDone
    noMigAssociation
    milestone
    supportedInitiatives {
      id
      tag {
        title
        iconId
        colorCode
      }
    }
    supportsInitiatives2 {
      id
      initiative {
        tag {
          title
          iconId
          colorCode
        }
      }
    }
    status
    tags {
      id
      name
      teamId
      active
      backgroundColor
      createdAt
    }
    owner {
      id
      email
      name
      displayName
      archivedAt
    }
    supportedMigs {
      id
      domainId {
        itemId
        teamId
      }
      name
    }
    teamId
  }
`;
