import { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';

export const useCountdown = (targetDate?: string | Moment | null) => {
  const momentTargetDate = moment.utc(targetDate ?? undefined).endOf('day');
  const deadlineMissed = moment.utc().isAfter(momentTargetDate, 'day');

  const [timeDiff, setTimeDiff] = useState(
    moment.utc().to(momentTargetDate, true)
  );

  useEffect(() => {
    setTimeDiff(moment.utc().to(momentTargetDate, true));
    const interval = setInterval(() => {
      setTimeDiff(moment.utc().to(momentTargetDate, true));
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, [momentTargetDate]);

  return { timeDiff, deadlineMissed };
};
