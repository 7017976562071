import { InitiativeContentFragment } from '../../../../../generated/graphql';
import { stringSort } from '../../../../../services/stringSort';

export interface InitiativeNode extends InitiativeContentFragment {
  key: string;
  children?: InitiativeNode[];
  title: React.ReactNode;
  link: string;
  directlyContributingTeamIds: string[];
  isContributing: boolean;
  totalTeamsContributingIds: string[];
  subInitiativeIds: string[];
  parentIsCompletedOrArchived: boolean;
}

export function buildInitiativeTree(
  initiatives: InitiativeContentFragment[],
  includeArchived: boolean = false
): InitiativeNode[] {
  const allInitiativesWithKeys =
    (initiatives.map((i) => ({
      ...i,
      key: i.domainId.itemId,
      title: i.name,
      isContributing: false,
    })) as InitiativeNode[]) ?? [];

  return _buildInitiativeTree(
    allInitiativesWithKeys,
    null,
    null,
    includeArchived
  );
}

function _buildInitiativeTree(
  initiatives: InitiativeNode[],
  parentId: string | null = null,
  parentPath: string | null = null,
  includeArchived: boolean = false,
  parentIsCompletedOrArchived: boolean = false
): InitiativeNode[] {
  const result: InitiativeNode[] | null = [];

  // Filter out initiatives that are children of the parent initiative
  const filteredInitiatives = parentId
    ? initiatives.filter((i) =>
        i.supportsInitiatives.some((s) => s.domainId.itemId === parentId)
      )
    : initiatives.filter((i) => i.supportsInitiatives.length === 0);

  // Recursively build the tree nodes
  filteredInitiatives.forEach((initiative) => {
    // The path is the key of the node, and is used to identify the node in the tree, this must be unique based on the position of the node in the tree,
    // otherwise the tree will not render correctly
    const path = parentPath
      ? parentPath + '/' + initiative.domainId.itemId
      : initiative.domainId.itemId;

    const children = _buildInitiativeTree(
      initiatives,
      initiative.domainId.itemId,
      path,
      includeArchived,
      parentIsCompletedOrArchived ||
        initiative.completed.value ||
        initiative.archived
    );

    // directlyContributingTeamIds is either the team owners id or teams that have joined this initiative hence directly contributing.
    const directlyContributingTeamIds = initiative.teamsContributing.map(
      (tc) => tc.domainId.itemId
    );

    // create a new set to store all unique sub-initiative IDs
    const subInitiativeIds = new Set<string>();

    // totalTeamsContributingSet contains directly contributing teams and indirectly contributing teams by sub-initiatives
    const totalTeamsContributingSet = new Set<string>(
      directlyContributingTeamIds
    );

    // add contributing team IDs and sub-initiative ids from child nodes
    children.forEach((child) => {
      child.subInitiativeIds.forEach((id) => subInitiativeIds.add(id));
      subInitiativeIds.add(child.id);
      child.totalTeamsContributingIds.forEach((id) =>
        totalTeamsContributingSet.add(id)
      );
    });

    const node: InitiativeNode = {
      ...initiative,
      key: path,
      directlyContributingTeamIds: directlyContributingTeamIds,
      totalTeamsContributingIds: Array.from(totalTeamsContributingSet), // convert set to an array
      subInitiativeIds: Array.from(subInitiativeIds), // convert set to an array
      parentIsCompletedOrArchived: parentIsCompletedOrArchived,
    };

    if (children.length > 0) {
      node.children = children.sort((a, b) =>
        stringSort(a.tag.title, b.tag.title)
      );
    }

    // If the node is archived and has no children, do not add it to the tree
    if (children.length === 0 && node.archived && !includeArchived) {
      return;
    }

    result.push(node);
  });
  return result;
}

// This is a tree traversal function that takes a tree of TreeNode and a callback function
// that takes a TreeNode and returns a TreeNode or null based on the logic in the callback
export function initiativeTreeWalker(
  treeNodes: InitiativeNode[],
  callback: (node: InitiativeNode) => InitiativeNode | null
): InitiativeNode[] {
  const modifiedNodes: InitiativeNode[] = [];
  for (const node of treeNodes) {
    let modifiedNode: InitiativeNode | null = node;
    if (node.children) {
      modifiedNode = {
        ...modifiedNode,
        children: initiativeTreeWalker(node.children, callback),
      };
    }
    // The tree walker traverses the tree in a depth first manner, so the callback
    // will be called on the way up the tree
    modifiedNode = callback(modifiedNode);
    if (modifiedNode) {
      modifiedNodes.push(modifiedNode);
    }
  }
  return modifiedNodes;
}

// This function finds all parents Id based on the nodeId.
// For example used to determine what nodes that should be expanded on render
export const getParentKeys = (
  treeNodes: InitiativeNode[],
  nodeId?: string,
  parentKeys: string[] = [],
  result: string[] = []
): string[] | null => {
  for (const node of treeNodes) {
    if (node.domainId.itemId === nodeId) {
      result.push(...parentKeys, node.key);
    }
    if (node.children) {
      getParentKeys(node.children, nodeId, [...parentKeys, node.key], result);
    }
  }
  return result.length > 0 ? result : null;
};
