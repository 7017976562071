import { PerformanceTag } from '../../../../components/PerformanceTag';
import { GraphLegend } from './teamOverviewGraphLegends/GraphLegend';
import { useTranslation } from 'react-i18next';
import { roundToDecimals } from '../../../../services/roundNumbersHelper';
import { Colors } from '../../../componentLibrary/Colors';
import './TeamOverviewGraphLegends.less';

interface Props {
  performance?: number | null;
  goal?: number | null;
  progress?: number | null;
  historic?: number | null;
}

export const TeamOverviewGraphLegends = ({
  performance,
  goal,
  progress,
  historic,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="TeamOverviewGraphLegends flx flx--1">
      <GraphLegend
        className="mr--xl"
        title={t('common.goal')}
        color={Colors.Howwe.MAIN_ORANGE}
        value={roundToDecimals(goal, 2)}
      />
      <GraphLegend
        className="mr--xl"
        title={t('TeamOverviewGraphLegends.progress')}
        color={Colors.Action.BLUE}
        value={roundToDecimals(progress, 2)}
      />
      {historic && (
        <GraphLegend
          className="mr--xl"
          title={t('common.historic')}
          color={Colors.Grays.SECONDARY_FONT}
          value={roundToDecimals(historic, 2)}
        />
      )}
      {performance != null && (
        <div className="flx flx--1 flx--ai-flx-end flx--column">
          <PerformanceTag value={performance} />
          <span className="font-size--sm txt--secondary mt--s">
            {t('TeamOverviewGraphLegends.fulfillment')}
          </span>
        </div>
      )}
    </div>
  );
};
