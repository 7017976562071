import Icon from '@ant-design/icons';

export const LeaveInitiativeIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.09538 15.1216C1.63704 15.1216 1.24454 14.9585 0.917877 14.6324C0.591766 14.3057 0.428711 13.9132 0.428711 13.4549V1.78825C0.428711 1.32992 0.591766 0.937415 0.917877 0.610749C1.24454 0.284637 1.63704 0.121582 2.09538 0.121582H7.92871V1.78825H2.09538V13.4549H7.92871V15.1216H2.09538ZM11.262 11.7882L10.1162 10.5799L12.2412 8.45492H5.42871V6.78825H12.2412L10.1162 4.66325L11.262 3.45492L15.4287 7.62158L11.262 11.7882Z" />
  </svg>
);

export const LeaveInitiativeIcon = (props: any) => (
  <Icon component={LeaveInitiativeIconSvg} {...props} />
);
