import { InitiativesColors } from '../../../../../styleVars';

export function getInitiativeColor(
  percentage: number,
  totalNumberOfActivities: number
) {
  if (percentage === 0 && totalNumberOfActivities === 0)
    return InitiativesColors.neverStarted;

  if (percentage >= 90) {
    return InitiativesColors.green;
  } else if (percentage >= 70) {
    return InitiativesColors.orange;
  }

  return InitiativesColors.red;
}
