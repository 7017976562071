import { Spin, Empty, Divider } from 'antd';

import { useTranslation } from 'react-i18next';
import { ScrollableCard } from '../../../ScrollableCard';
import { AkpiAchievement } from './akpiAchievementList/AkpiAchievement';
import { TimePeriodFilter } from '../../../../generated/graphql';
import { AkpiPeriodSelect } from '../../../AkpiPeriodSelect';
import {
  AccelerationMeetingEntry,
  AkpiAchievementEntry,
} from '../../../../hooks/useTeamKeyActivityLog';
import { AccelerationMeetingEvent } from './akpiAchievementList/AccelerationMeetingEvent';
import { useRouteMatch } from 'react-router-dom';

interface Props {
  teamActivities: {
    data: (AccelerationMeetingEntry | AkpiAchievementEntry)[];
    loading: boolean;
  };
  timePeriodFilter?: TimePeriodFilter;
  onPeriodChange: (value?: TimePeriodFilter) => void;
}

export const AkpiAchievementList = ({
  teamActivities,
  timePeriodFilter,
  onPeriodChange,
}: Props) => {
  const { t } = useTranslation();
  const match = useRouteMatch();

  let isOldMeeting = false;
  const isAccMeeting = !!useRouteMatch(match.path + '/acceleration-meeting');

  const teamActivityList = teamActivities?.data.map((ae, index) => {
    if (ae.__typename === 'TeamAccelerationMeeting') {
      const meetingComponent = (
        <div className="mb--l" key={index}>
          <AccelerationMeetingEvent
            accelerationMeeting={ae}
            expanded={!isOldMeeting && isAccMeeting}
          />
          <Divider />
        </div>
      );

      isOldMeeting = true;

      return meetingComponent;
    }

    return <AkpiAchievement {...ae} key={index} />;
  });

  return (
    <Spin spinning={teamActivities.loading}>
      <ScrollableCard
        title={t('AkpiAchievementList.title')}
        style={{ maxHeight: '57vh' }}
        extra={
          <AkpiPeriodSelect
            timePeriodFilter={timePeriodFilter}
            onPeriodChange={onPeriodChange}
          />
        }
      >
        {teamActivityList?.length === 0 ? (
          <div className="center-content">
            <Empty
              description={t('AkpiAchievementList.noAchievements')}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        ) : (
          teamActivityList
        )}
      </ScrollableCard>
    </Spin>
  );
};
