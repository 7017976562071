import { PureQueryOptions, RefetchQueriesFunction } from '@apollo/client';
import { Drawer, Empty, Spin } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerTitle } from '../../../../../../../../components/DrawerTitle';
import { useSprintPlanningData } from '../../../../../../common/hooks/useSprintPlanningData';
import { PlannedKeyActivities } from './AddExistingMitemDrawer/PlannedKeyActivities';
import dayjs from 'dayjs';

interface Props {
  teamId: string;
  showModal: boolean;
  onCancel: () => void;
  refetchQueries?: Array<string | PureQueryOptions> | RefetchQueriesFunction;
}

export const AddExistingMitemDrawer = ({
  teamId,
  showModal,
  onCancel,
  refetchQueries,
}: Props) => {
  const { t } = useTranslation();

  const { data, loading } = useSprintPlanningData(teamId);
  // TODO: Should be changed, need to handle intercom on a higher level
  const isAccMeeting = window.location.href.includes('acceleration-meeting');

  useEffect(() => {
    if (!isAccMeeting) {
      (window as any).Intercom?.('update', {
        hide_default_launcher: showModal,
      });

      // sometimes the drawer is unmounted rather than getting the prop showModal={false}
      // this will make sure we show the intercom widget again in that case
      return () =>
        (window as any).Intercom?.('update', {
          hide_default_launcher: false,
        });
    }
  }, [showModal, isAccMeeting]);

  if (loading) return <Spin delay={300} className="center-content" />;

  const hasCurrentAndFutureSprints =
    data?.currentSprintAndOnward != null &&
    data?.currentSprintAndOnward.length > 1; // Make sure there is both a current sprint and at least one future sprint

  if (!hasCurrentAndFutureSprints || data?.sprintPeriodMap == null) {
    return (
      <Empty
        description={t('PlannedSprints.noSprints')}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    );
  }

  const currentSprint = data?.currentSprintAndOnward[0];
  const currentSprintPeriods =
    data?.sprintPeriodMap[currentSprint.sprintNumber];
  const today = dayjs();
  const futurePeriodsInSprint = currentSprintPeriods.filter((csp) =>
    dayjs(csp.timePeriodEndDate).isSameOrAfter(today, 'day')
  );

  return (
    <Drawer
      title={
        <DrawerTitle>{t('AddExistingMitemDrawer.modalTitle')}</DrawerTitle>
      }
      open={showModal}
      width={420}
      destroyOnClose
      forceRender={true}
      onClose={onCancel}
      styles={{
        header: { backgroundColor: '#eaeaea' },
        content: {
          borderLeft: '8px solid #eaeaea',
        },
      }}
    >
      <>
        <PlannedKeyActivities
          teamId={teamId}
          sprints={data?.currentSprintAndOnward}
          refetchQueries={refetchQueries}
          futurePeriodsInSprint={futurePeriodsInSprint}
        />
      </>
    </Drawer>
  );
};
