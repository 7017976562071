import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleFilled,
  EditOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InitiativeContentFragment } from '../../../../../../../generated/graphql';
import { ArchiveIcon } from '../../../../../../../icons/initiativeIcons/ArchiveIcon';
import { LeaveInitiativeIcon } from '../../../../../../../icons/initiativeIcons/LeaveInitiativeIcon';
import { EditInitiativesDrawer } from '../../../../../../company/initiatives_old/initiativesPageV1/EditInitiativesDrawer';
import { ArchiveInitiative } from './initiativeDropDownMenu/ArchiveInitiative';
import { LeaveInitiative } from './initiativeDropDownMenu/LeaveInitiative';
import { EditSubInitiativesDrawer } from '../../../../../../company/initiatives_old/initiativesPageV1/EditSubInitiativeDrawer';
import { Btn } from '../../../../../../../components/Button';
import { CompleteStateInitiative } from './initiativeDropDownMenu/CompleteStateInitiative';
import { useCompleteInitiativeFeatureFlag } from '../../../../../../../hooks/featureFlagHooks';

interface Props {
  initiative: InitiativeContentFragment;
  teamId: string | undefined;
  isSubInitiative: boolean;
}

type InitiativeTypes = 'Initiative' | 'SubInitiative';

export const InitiativeDropdownMenu = ({
  initiative,
  teamId,
  isSubInitiative,
}: Props) => {
  const [initiativeFormType, setInitiativeFormType] = useState<InitiativeTypes>(
    isSubInitiative ? 'SubInitiative' : 'Initiative'
  );

  const completeInitiativeIsEnabled = useCompleteInitiativeFeatureFlag();

  const isJoinedInitiative = teamId !== initiative.team.id;
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);

  const items = isJoinedInitiative
    ? [
        {
          key: 'LeaveInitiative',
          label: <LeaveInitiative initiative={initiative} teamId={teamId} />,
          icon: <LeaveInitiativeIcon />,
        },
      ]
    : [
        {
          key: 'EditInitiative',
          label: (
            <div onClick={() => setOpenDrawer(true)}>{t('common.edit')}</div>
          ),
          icon: <EditOutlined />,
        },
        {
          key: 'ConvertInitiative',
          label: (
            <div
              onClick={() => {
                setInitiativeFormType(
                  isSubInitiative ? 'Initiative' : 'SubInitiative'
                );
                return setOpenDrawer(true);
              }}
            >
              {isSubInitiative
                ? t('InitiativeDropdownMenu.convertToInitiative')
                : t('InitiativeDropdownMenu.convertToSubInitiative')}
            </div>
          ),
          icon: isSubInitiative ? <ArrowUpOutlined /> : <ArrowDownOutlined />,
        },
        {
          key: 'ArchiveInitiative',
          label: <ArchiveInitiative initiative={initiative} />,
          icon: <ArchiveIcon />,
        },
        {
          key: 'MarkAsCompleted',
          exclude: !completeInitiativeIsEnabled || initiative.archived,
          label: (
            <CompleteStateInitiative initiative={initiative} teamId={teamId} />
          ),
          icon: <CheckCircleFilled />,
        },
      ];

  return (
    <div>
      <EditSubInitiativesDrawer
        visible={openDrawer && initiativeFormType === 'SubInitiative'}
        onClose={() => {
          setOpenDrawer(false);
          setInitiativeFormType(
            isSubInitiative ? 'SubInitiative' : 'Initiative'
          );
        }}
        onCompleted={() => {
          setOpenDrawer(false);
        }}
        initiativeDomainId={initiative.domainId}
      />
      <EditInitiativesDrawer
        visible={openDrawer && initiativeFormType === 'Initiative'}
        onClose={() => {
          setOpenDrawer(false);
          setInitiativeFormType(
            isSubInitiative ? 'SubInitiative' : 'Initiative'
          );
        }}
        onCompleted={() => {
          setOpenDrawer(false);
        }}
        initiativeDomainId={initiative.domainId}
      />
      <Dropdown
        menu={{ items: items.filter((i) => !i.exclude) }}
        placement="bottom"
      >
        <Btn type="text" icon={<MoreOutlined />} />
      </Dropdown>
    </div>
  );
};
