import { gql } from '@apollo/client';
import { InitiativeMetaFragment } from '../../../../../generated/graphql';
import { InitiativeTag } from '../../../../../components/initiative/InitiativeTag';
import { Icons } from '../InitiativeIcons';
import { useTranslation } from 'react-i18next';
import {
  findHierarchicalInitiativeById,
  useHierarchicalInitiatives,
} from '../../../../../hooks/useTenantInitiatives';
import './InitiativeMeta.less';
import { Link } from 'react-router-dom';
import { Btn } from '../../../../../components/Button';
import { useState } from 'react';
import cx from 'classnames';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { TourLink } from '../../../../../components/TourLink';
import { Alert } from 'antd';
import { match } from 'ts-pattern';

type Props = {
  data: Omit<InitiativeMetaFragment, '__typename'> | undefined;
  actions?: React.ReactNode;
};

export const InitiativeMeta = ({ data, actions }: Props) => {
  const { t } = useTranslation();
  const [showingMore, setShowMore] = useState(false);
  const { name, tag, description, team } = data || {};

  const { hierarchicalInitiatives } = useHierarchicalInitiatives();

  const initiative = data?.id
    ? findHierarchicalInitiativeById(hierarchicalInitiatives, data.id)
    : null;

  const numberOfSubInitiatives = initiative?.subInitiativeIds.length;

  const teamsContributing = initiative?.totalTeamsContributingIds.length;

  const alertInfo = match(initiative)
    .with({ archived: true }, () => (
      <Alert type="info" message={t('InitiativeMeta.archivedInfo')} />
    ))
    .with({ completed: { value: true } }, () => (
      <Alert type="success" message={t('InitiativeMeta.completedInfo')} />
    ))
    .with({ parentIsCompletedOrArchived: true }, () => (
      <Alert
        type="info"
        message={t('InitiativeMeta.completedOrArchivedParentInfo')}
      />
    ))
    .otherwise(() => null);

  const title = match(initiative)
    .with({ archived: true }, () => (
      <h2
        className="bold mr--auto flx"
        data-intercom-target="Initiative name headline"
      >
        <div className="InitiativeMeta__ArchivedTitle space--r">
          {t('InitiativeMeta.archived')}
        </div>
        <div className="space--r">-</div>

        {name}
      </h2>
    ))
    .with({ completed: { value: true } }, () => (
      <h2
        className="bold mr--auto flx"
        data-intercom-target="Initiative name headline"
      >
        <div className="InitiativeMeta__CompletedTitle space--r">
          {t('InitiativeMeta.completed')}
        </div>
        <div className="space--r">-</div>

        {name}
      </h2>
    ))

    .otherwise(() => (
      <h2
        className="bold mr--auto flx"
        data-intercom-target="Initiative name headline"
      >
        {name}
      </h2>
    ));

  return (
    <div>
      <div
        className={cx('InitiativeMeta__content', {
          'InitiativeMeta__content--expanded': showingMore,
        })}
      >
        <div className="flx flx--jc-space-between">
          <div className="flx flx--ai-center">
            {title}
            <TourLink
              intercomTarget="Initiative overview tour button"
              engTourId="4845"
              sweTourId="5063"
            />
          </div>
          <div className="flx">{actions}</div>
        </div>
        <div className="mt mb--xl">
          {tag && (
            <InitiativeTag
              title={tag.title}
              borderColor={tag.colorCode}
              icon={Icons[tag.iconId as keyof typeof Icons]}
              completed={data?.completed?.value}
              archived={data?.archived}
            />
          )}
        </div>
        <div className="flx">
          <div className="flx--1 pr--xxl">
            <h4 className="bold mb">
              {t('InitiativeMeta.purposeAndObjective')}
            </h4>
            <div style={{ whiteSpace: 'pre-wrap' }}>{description}</div>
            <ul className="mt">
              {data?.objectives.map((ob, index) => (
                <li key={index} className="mb--skipLast">
                  {ob.text}
                </li>
              ))}
            </ul>
          </div>
          <div className="InitiativeMeta__secondary">
            <h4 className="bold mb">{t('InitiativeMeta.drivenBy')}</h4>
            <Link to={`/team/${team?.id}`}>{team?.name}</Link>

            {data?.supportsMigs !== undefined &&
              data.supportsMigs.length > 0 && (
                <>
                  <h4 className="bold mt--xl mb">
                    {t('InitiativeMeta.supportsMig')}
                  </h4>

                  <ul>
                    {data?.supportsMigs?.map((mig) => (
                      <li key={`migId:${mig.id}`}>{mig.migV2.name}</li>
                    ))}
                  </ul>
                </>
              )}
            <h4 className="bold mt--xl mb">{t('InitiativeMeta.stats')}</h4>
            <div className="flx">
              <label className="mb">{t('InitiativeMeta.subInitiatives')}</label>
              <div className="bold ml--auto">{numberOfSubInitiatives}</div>
            </div>
            <div className="flx">
              <label className="mb">{t('InitiativeMeta.teamsInvolved')}</label>
              <div className="bold ml--auto">{teamsContributing}</div>
            </div>
          </div>
        </div>
      </div>

      <Btn
        className="mt--l mb--xxl"
        icon={showingMore ? <UpOutlined /> : <DownOutlined />}
        type="link"
        onClick={() => setShowMore((current) => !current)}
      >
        {showingMore
          ? t('InitiativeMeta.showMore')
          : t('InitiativeMeta.showMore')}
      </Btn>
      {alertInfo}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_META_FRAGMENT = gql`
  fragment InitiativeMeta on InitiativeReport {
    id
    name
    description
    archived
    completed {
      value
    }
    objectives {
      text
    }
    tag {
      iconId
      colorCode
      title
    }
    team {
      id
      name
    }
    supportsMigs {
      id
      migV2 {
        id
        name
        domainId {
          itemId
          teamId
        }
      }
    }
  }
`;
