import { Modal, Spin } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../SectionHeader';
import { PublishStatus } from '../../generated/graphql';
import { ActiveKeyActivitiesCard } from './keyActivityPage/ActiveKeyActivitiesCard';
import { KeyActivityTable } from './keyActivityPage/KeyActivityTable';
import { CreateWeeklyModal } from './keyActivityPage/CreateWeeklyModal';
import { useTeamKeyActivitiesPreview } from './keyActivityPage/hooks/useTeamKeyActivitiesPreview';
import { EditSprintTermModal } from './keyActivityPage/EditSprintTermModal';
import { CreateSprintTermModal } from './keyActivityPage/CreateSprintTermModal';
import { EditWeeklyModal } from './keyActivityPage/EditWeeklyModal';
import { StatusWeeklyActivityModal } from './keyActivityPage/StatusWeeklyActivityModal';
import { ArchiveOutlinedIcon } from '../../icons/ArchiveIcon';
import './KeyActivitySetupPage.less';
import { StatusSprintTermModal } from './keyActivityPage/StatusSprintTermModal';
import { CopyWeeklyKeyActivityModal } from './keyActivityPage/CopyWeeklyKeyActivityModal';
import { pick } from '../../services/pick';
import { Btn } from '../Button';

interface Props {
  teamId: string;
  tenantId?: string;
}

interface WeeklyKeyActivityMeta {
  name: string;
  acceptanceCriteria?: string | null;
  achievementDescriptionMandatory: boolean;
  commitmentStrategyEnabled: boolean;
  description?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  unit: string;
}

interface CreateWeeklyKeyActivity {
  id: null;
  type: 'CreateWeeklyKeyActivity';
  weeklyKeyActivityToCopy?: WeeklyKeyActivityMeta;
}

interface CreateSprintTerm {
  id: null;
  type: 'CreateSprintTerm';
}

interface EditWeeklyKeyActivity {
  id: string;
  type: 'EditWeeklyKeyActivity';
}

interface EditSprintTerm {
  id: string;
  type: 'EditSprintTerm';
}

interface UpdateStatusSprintTerm {
  id: string;
  type: 'UpdateStatusSprintTerm';
  status: PublishStatus;
}

interface UpdateStatusWeeklyKeyActivity {
  id: string;
  type: 'UpdateStatusWeeklyKeyActivity';
  status: PublishStatus;
}

export type KeyActivityModalState =
  | CreateWeeklyKeyActivity
  | CreateSprintTerm
  | EditWeeklyKeyActivity
  | EditSprintTerm
  | UpdateStatusSprintTerm
  | UpdateStatusWeeklyKeyActivity;

export const KeyActivitySetupPage = ({ teamId, tenantId }: Props) => {
  const { t } = useTranslation();

  const [keyActivityModalState, setKeyActivityModalState] =
    useState<KeyActivityModalState | null>(null);

  const keyActivities = useTeamKeyActivitiesPreview({
    teamId,
    tenantId,
  });

  const [showArchived, setShowArchived] = useState(false);

  const { weeklyKeyActivities, sprintTerms } = keyActivities;

  const numberOfArchivedWeeklyKeyActivities =
    weeklyKeyActivities == null
      ? 0
      : weeklyKeyActivities.filter((w) => w.status === PublishStatus.ARCHIVED)
          .length;

  const numberOfArchivedSprintKeyActivities =
    sprintTerms == null
      ? 0
      : sprintTerms.filter((w) => w.status === PublishStatus.ARCHIVED).length;

  return (
    <>
      <CreateWeeklyModal
        keyActivityModalState={keyActivityModalState}
        isOpen={keyActivityModalState?.type === 'CreateWeeklyKeyActivity'}
        teamId={teamId}
        tenantId={tenantId}
        onClose={() => setKeyActivityModalState(null)}
      />

      <EditWeeklyModal
        keyActivityModalState={keyActivityModalState}
        teamId={teamId}
        tenantId={tenantId}
        onClose={() => setKeyActivityModalState(null)}
      />

      <CreateSprintTermModal
        isOpen={keyActivityModalState?.type === 'CreateSprintTerm'}
        teamId={teamId}
        tenantId={tenantId}
        onClose={() => setKeyActivityModalState(null)}
      />

      <EditSprintTermModal
        keyActivityModalState={keyActivityModalState}
        teamId={teamId}
        tenantId={tenantId}
        onClose={() => setKeyActivityModalState(null)}
      />

      <StatusWeeklyActivityModal
        keyActivityModalState={keyActivityModalState}
        teamId={teamId}
        tenantId={tenantId}
        onClose={() => setKeyActivityModalState(null)}
      />

      <StatusSprintTermModal
        keyActivityModalState={keyActivityModalState}
        teamId={teamId}
        tenantId={tenantId}
        onClose={() => setKeyActivityModalState(null)}
      />

      <Modal
        width={800}
        open={showArchived}
        footer={false}
        title={t('common.archived.single')}
        onCancel={() => setShowArchived(false)}
      >
        <div data-intercom-target="Archived Key Activities">
          <KeyActivityTable
            teamId={teamId}
            tenantId={tenantId}
            status={PublishStatus.ARCHIVED}
            setKeyActivityModalState={setKeyActivityModalState}
            keyActivities={[
              ...(weeklyKeyActivities?.filter(
                (w) => w.status === PublishStatus.ARCHIVED
              ) ?? []),
              ...(sprintTerms?.filter(
                (w) => w.status === PublishStatus.ARCHIVED
              ) ?? []),
            ]}
          />
        </div>
      </Modal>

      <Spin spinning={keyActivities.loading}>
        <div>
          <SectionHeader
            title={t('common.active')}
            extra={
              <div>
                <Btn
                  data-intercom-target="Create Sprint Term"
                  type="primary"
                  className="mr"
                  onClick={() =>
                    setKeyActivityModalState({
                      id: null,
                      type: 'CreateSprintTerm',
                    })
                  }
                >
                  {t('KeyActivitySetupPage.createSprintTerm')}
                </Btn>
                <Btn
                  data-intercom-target="Create Weekly Activity"
                  type="primary"
                  onClick={() =>
                    setKeyActivityModalState({
                      id: null,
                      type: 'CreateWeeklyKeyActivity',
                    })
                  }
                >
                  {t('KeyActivitySetupPage.createWeekly')}
                </Btn>

                <CopyWeeklyKeyActivityModal
                  className="ml"
                  tenantId={tenantId}
                  onConfirm={(keyActivity) => {
                    const weeklyKeyActivity = pick(
                      keyActivity,
                      'acceptanceCriteria',
                      'description',
                      'achievementDescriptionMandatory',
                      'unit',
                      'name',
                      'commitmentStrategyEnabled'
                    );
                    setKeyActivityModalState({
                      id: null,
                      type: 'CreateWeeklyKeyActivity',
                      weeklyKeyActivityToCopy: weeklyKeyActivity,
                    });
                  }}
                />
              </div>
            }
          >
            <ActiveKeyActivitiesCard
              teamId={teamId}
              tenantId={tenantId}
              keyActivities={keyActivities}
              setKeyActivityModalState={setKeyActivityModalState}
            />
          </SectionHeader>
          <div className="pt--xl">
            <SectionHeader title={t('common.upcoming')}>
              <div data-intercom-target="Upcoming Key Activities">
                <KeyActivityTable
                  teamId={teamId}
                  tenantId={tenantId}
                  status={PublishStatus.UPCOMING}
                  setKeyActivityModalState={setKeyActivityModalState}
                  keyActivities={[
                    ...(weeklyKeyActivities?.filter(
                      (w) => w.status === PublishStatus.UPCOMING
                    ) ?? []),
                    ...(sprintTerms?.filter(
                      (w) => w.status === PublishStatus.UPCOMING
                    ) ?? []),
                  ]}
                />
              </div>
            </SectionHeader>

            <SectionHeader
              title={t('KeyActivitySetupPage.endedKeyActivities')}
              extra={
                <Btn
                  data-intercom-target="Show Archived Key Activities"
                  onClick={() => setShowArchived(true)}
                  icon={<ArchiveOutlinedIcon />}
                >
                  {t('KeyActivitySetupPage.showArchiveButton', {
                    counter:
                      numberOfArchivedWeeklyKeyActivities +
                      numberOfArchivedSprintKeyActivities,
                  })}
                </Btn>
              }
            >
              <div data-intercom-target="Ended Key Activities">
                <KeyActivityTable
                  teamId={teamId}
                  tenantId={tenantId}
                  status={PublishStatus.ENDED}
                  setKeyActivityModalState={setKeyActivityModalState}
                  keyActivities={[
                    ...(weeklyKeyActivities?.filter(
                      (w) => w.status === PublishStatus.ENDED
                    ) ?? []),
                    ...(sprintTerms?.filter(
                      (w) => w.status === PublishStatus.ENDED
                    ) ?? []),
                  ]}
                />
              </div>
            </SectionHeader>
          </div>
        </div>
      </Spin>
    </>
  );
};
