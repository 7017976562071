import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const MarkAsArchivedIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="48"
    viewBox="0 0 56 48"
    fill="currentColor"
  >
    <path
      d="M0.5 47.5L28 0L55.5 47.5H0.5ZM9.125 42.5H46.875L28 10L9.125 42.5ZM28 40C28.7083 40 29.3021 39.7604 29.7813 39.2812C30.2604 38.8021 30.5 38.2083 30.5 37.5C30.5 36.7917 30.2604 36.1979 29.7813 35.7188C29.3021 35.2396 28.7083 35 28 35C27.2917 35 26.6979 35.2396 26.2188 35.7188C25.7396 36.1979 25.5 36.7917 25.5 37.5C25.5 38.2083 25.7396 38.8021 26.2188 39.2812C26.6979 39.7604 27.2917 40 28 40ZM25.5 32.5H30.5V20H25.5V32.5Z"
      fill="currentColor"
    />
  </svg>
);

export const MarkAsArchivedIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={MarkAsArchivedIconSvg} {...props} />
);
