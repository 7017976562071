import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  CreateTeamMigDocument,
  GetTeamFullMigsV2Document,
  MigInputV2,
} from '../../../generated/graphql';
import { showNotification } from '../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../services/howweErrorParser';
import { FullscreenModal } from '../../FullscreenModal';

import { MigModalState } from '../MigSetupPage';
import { MigSetupModalContent } from './MigSetupModalContent';

interface Props {
  onClose: () => void;
  teamId: string;
  tenantId?: string;
  migModalState: MigModalState | null;
}

export const CreateMigModal = ({
  onClose,
  teamId,
  tenantId,
  migModalState,
}: Props) => {
  const { t } = useTranslation();
  const isOpen = migModalState?.type === 'CreateMig';

  const [createNewMig, { error, loading }] = useMutation(
    CreateTeamMigDocument,
    {
      onCompleted: (response) => {
        showNotification('success', {
          message: <strong>{t('CreateMigModal.saveSuccess')}</strong>,
          description: response.createTeamMig.name,
        });
        onClose();
      },
      refetchQueries: () => [
        {
          query: GetTeamFullMigsV2Document,
          variables: { teamId, tenantId },
        },
      ],
    }
  );

  const handleSave = (mig: MigInputV2) => {
    createNewMig({
      variables: {
        teamId,
        tenantId,
        mig,
      },
    });
  };

  return (
    <FullscreenModal
      title={t('CreateMigModal.title')}
      onCancel={onClose}
      footer={null}
      open={isOpen}
      styles={{ body: { padding: 0 } }}
      destroyOnClose
    >
      <MigSetupModalContent
        teamId={teamId}
        tenantId={tenantId}
        saveLoading={loading}
        handleSave={handleSave}
        saveErrors={howweErrorParser(error)}
        fetchLoading={false}
        onClose={onClose}
      />
    </FullscreenModal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_MIG = gql`
  mutation createTeamMig($teamId: ID!, $mig: MigInputV2!, $tenantId: ID) {
    createTeamMig(teamId: $teamId, mig: $mig, tenantId: $tenantId) {
      id
      name
      description
      unit
      startDate
      timePeriodInterval
      lastOutcomeDate
      firstOutcomeDate
      preferredOutcome
      minY
      maxY
      periodData {
        targetDate
        goal
        actual
        historic
      }
      team {
        id
        name
      }
    }
  }
`;
