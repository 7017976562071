import { useTranslation } from 'react-i18next';
import { SupportMig } from '../MigRelationSelector';
import cx from 'classnames';
import { MigStatus } from '../../../../../generated/graphql';
import dayjs from 'dayjs';

interface Props {
  supportMig: SupportMig;
  action: React.ReactNode;
  className?: string;
}

export const RelationsMig = ({ supportMig, action, className }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx('MigRelationSelector__mig', className)}
      key={supportMig.id}
    >
      <div className="flx mb--s">
        <div>{supportMig.name}</div>
        <div className="ml--auto">{action}</div>
      </div>
      <div className="flx flx--jc-space-between">
        <div className="MigRelationSelector__secondaryText">
          {supportMig.team.name}
        </div>
        <div className="MigRelationSelector__secondaryText">
          {supportMig.status === MigStatus.ARCHIVED ? (
            <span className="txt--danger bold">
              {t('MigRelationSelector.archived')}
            </span>
          ) : (
            <>
              {t('MigRelationSelector.endsIn')}
              <span title={supportMig.lastOutcomeDate}>
                {dayjs(supportMig.lastOutcomeDate).fromNow()}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
