import dayjs from 'dayjs';
import './GigantishChartColumns.less';
import { daysToPx } from '../Gigantish';

interface Props {
  boundary: {
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  };
}

export const GigantishChartColumns = ({ boundary }: Props) => {
  const monthsPerColumn = 6;

  const totalMonths = boundary.end.diff(boundary.start, 'month') + 1;
  const totalColumns = totalMonths / monthsPerColumn;

  const columns = Array.from({ length: totalColumns }, (_, i) => {
    const theBeginning = boundary.start
      .add(i * monthsPerColumn, 'month')
      .startOf('month');
    const theEnd = theBeginning
      .add(monthsPerColumn - 1, 'month')
      .endOf('month');

    const period = {
      start: theBeginning.startOf('month'),
      end: theEnd,
      days: theEnd.diff(theBeginning, 'day') + 1,
    };

    return period;
  });

  return (
    <>
      {columns.map((column, index) => (
        <div
          className="GigantishChartColumns__column"
          key={index}
          style={{ width: daysToPx(column.days) }}
          title={`${column.start.format('YYYY MMM')} to ${column.end.format('YYYY MMM')} (${column.days})`}
        >
          <div>{column.start.format('YYYY MMM')}</div>
        </div>
      ))}
    </>
  );
};
