import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import './MitemFilter.less';

export type MitemFilterStatus =
  | 'ALL'
  | 'COMPLETED'
  | 'NOT_COMPLETED'
  | 'POSTPONED'
  | 'ACTIVE'
  | 'PLANNED'
  | 'OVERDUE';

interface Props {
  onChange: (status: MitemFilterStatus) => void;
  value: MitemFilterStatus;
}

export const StatusFilter = ({ onChange, value }: Props) => {
  const { t } = useTranslation();
  const { Option } = Select;

  return (
    <div
      className="MitemFilters__filterContainer mr--s"
      data-intercom-target="Sprint View Table Status Filter"
    >
      <h5>{t('common.status')}</h5>
      <Select
        defaultValue={value}
        onChange={onChange}
        className="MitemFilter__Select"
      >
        <Option value={'ALL'} className="MitemFilters__divider">
          {t('common.showAll')}
        </Option>
        <Option value={'ACTIVE'}>
          {t('common.sprintKeyActivity.statuses.active')}
        </Option>
        <Option value={'COMPLETED'}>
          {t('common.sprintKeyActivity.statuses.completed')}
        </Option>
        <Option value={'NOT_COMPLETED'}>
          {t('common.sprintKeyActivity.statuses.not_completed')}
        </Option>
        <Option value={'POSTPONED'}>
          {t('common.sprintKeyActivity.statuses.postponed')}
        </Option>
        <Option value={'OVERDUE'}>
          {t('common.sprintKeyActivity.statuses.overdue')}
        </Option>
        <Option value={'PLANNED'}>
          {t('common.sprintKeyActivity.statuses.planned')}
        </Option>
      </Select>
    </div>
  );
};
