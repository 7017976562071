import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const PersonSearchIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    <g mask="url(#a)">
      <path
        d="M9.874 10.876a3.21 3.21 0 0 1-2.354-.98 3.21 3.21 0 0 1-.98-2.354c0-.916.327-1.701.98-2.354a3.21 3.21 0 0 1 2.354-.979 3.21 3.21 0 0 1 2.354.98 3.21 3.21 0 0 1 .979 2.353 3.21 3.21 0 0 1-.98 2.354 3.21 3.21 0 0 1-2.353.98Zm0-1.667c.458 0 .85-.163 1.177-.49.326-.326.49-.718.49-1.177 0-.458-.164-.85-.49-1.177a1.605 1.605 0 0 0-1.177-.49c-.459 0-.851.164-1.177.49-.327.327-.49.719-.49 1.177 0 .459.163.851.49 1.177.326.327.718.49 1.177.49Zm9.25 11.25-2.667-2.667a5.188 5.188 0 0 1-.938.417 3.338 3.338 0 0 1-1.062.167c-1.042 0-1.927-.365-2.656-1.094-.73-.73-1.094-1.615-1.094-2.656 0-1.042.365-1.927 1.094-2.657.729-.729 1.614-1.093 2.656-1.093s1.927.364 2.656 1.093c.73.73 1.094 1.615 1.094 2.657 0 .375-.056.729-.167 1.062-.11.333-.25.646-.416.938l2.666 2.666-1.166 1.167Zm-4.667-3.75c.583 0 1.076-.201 1.48-.604.402-.403.603-.896.603-1.48 0-.583-.201-1.076-.604-1.479a2.012 2.012 0 0 0-1.479-.604c-.583 0-1.076.202-1.48.604a2.012 2.012 0 0 0-.603 1.48c0 .583.201 1.076.604 1.479.403.403.896.604 1.479.604Zm-11.25.833V15.23c0-.472.118-.91.354-1.313a2.31 2.31 0 0 1 .98-.916 13.04 13.04 0 0 1 2.395-.917c.89-.25 1.875-.375 2.959-.375-.167.25-.31.517-.428.802-.118.285-.211.58-.28.886-.834.069-1.577.211-2.23.427-.653.215-1.201.44-1.646.677a.754.754 0 0 0-.323.302.838.838 0 0 0-.114.427v.646h4.312c.07.305.163.597.281.875.118.277.26.541.428.791H3.207Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const PersonSearchIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={PersonSearchIconSvg} {...props} />
);
