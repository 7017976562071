import { NodeIndexOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useCurrentLocale } from '../hooks/useCurrentLocale';
import { Btn } from './Button';

interface Props {
  engTourId: string | number;
  sweTourId: string | number;
  intercomTarget: string;
  tourName?: string;
}

export const TourLink = ({
  tourName,
  intercomTarget,
  engTourId,
  sweTourId,
}: Props) => {
  const { locale } = useCurrentLocale();
  const { t } = useTranslation();

  const linkText = tourName || t('TourLink.linkText');

  return (
    <Btn
      type="link"
      size="small"
      data-intercom-target={intercomTarget}
      onClick={() => {
        (window as any).Intercom(
          'startTour',
          locale === 'sv' ? sweTourId : engTourId
        );
      }}
      icon={<NodeIndexOutlined />}
    >
      <span style={{ marginLeft: 2 }}>{linkText}</span>
    </Btn>
  );
};
