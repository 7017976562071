import {
  EditOutlined,
  EllipsisOutlined,
  FullscreenOutlined,
} from '@ant-design/icons';
import { Alert, Dropdown, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { KeyActivityModalState } from '../../KeyActivitySetupPage';

import { AkpiCard } from '../../../akpi/teamAkpiPreviews/AkpiCard';
import { useWeeklyKeyActivity } from '../hooks/useWeeklyKeyActivity';
import { ArchiveOutlinedIcon } from '../../../../icons/ArchiveIcon';
import { useState } from 'react';
import { AkpiDetailedView } from '../../../akpi/teamAkpiPreviews/AkpiDetailedView';
import { Btn } from '../../../Button';

interface Props {
  teamId: string;
  tenantId?: string;
  keyActivityId: string;
  setKeyActivityModalState: (
    keyActivityModalState: KeyActivityModalState | null
  ) => void;
}

export const WeeklyKeyActivityCard = ({
  teamId,
  tenantId,
  keyActivityId,
  setKeyActivityModalState,
}: Props) => {
  const { t } = useTranslation();
  const [showLargeCard, setShowLargeCard] = useState<boolean>(false);
  const { keyActivity, loading, error } = useWeeklyKeyActivity({
    teamId,
    keyActivityId,
    tenantId,
  });

  if (error) return <Alert message={t('WeeklyKeyActivityCard.fetchError')} />;

  return (
    <Spin spinning={loading}>
      {keyActivity && (
        <>
          {showLargeCard && teamId && (
            <AkpiDetailedView
              teamId={teamId}
              tenantId={tenantId}
              weeklyKeyActivityId={keyActivity.id}
              onClose={() => setShowLargeCard(false)}
            />
          )}
          <AkpiCard
            akpiData={keyActivity}
            showDescription={false}
            action={
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'EditWeeklyKeyActivity',
                      icon: <EditOutlined />,
                      label: t('common.edit'),
                      onClick: () =>
                        setKeyActivityModalState({
                          type: 'EditWeeklyKeyActivity',
                          id: keyActivity.id,
                        }),
                    },
                    {
                      key: 'UpdateStatusWeeklyKeyActivity',
                      icon: (
                        <ArchiveOutlinedIcon className="keyActivityPage__icon" />
                      ),
                      label: t('common.archive'),
                      onClick: () =>
                        setKeyActivityModalState({
                          type: 'UpdateStatusWeeklyKeyActivity',
                          id: keyActivity.id,
                          status: keyActivity.status,
                        }),
                    },
                    {
                      key: 'ExpandWeeklyKeyActivity',
                      icon: <FullscreenOutlined />,
                      label: t('WeeklyKeyActivityCard.expand'),
                      onClick: () => setShowLargeCard(true),
                    },
                  ],
                }}
                placement="bottom"
              >
                <Btn size="small" icon={<EllipsisOutlined rotate={90} />} />
              </Dropdown>
            }
          />
        </>
      )}
    </Spin>
  );
};
