import { useRouteMatch } from 'react-router-dom';
import { InitiativeTag } from '../../../../../components/initiative/InitiativeTag';
import { Tooltip } from 'antd';
import { ArchivedIcon } from '../../../../../icons/overviewIcons/ArchivedIcon';
import { CheckIcon } from '../../../../../icons/overviewIcons/CheckIcon';
import { Colors } from '../../../../componentLibrary/Colors';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import './InitiativeTitle.less';

interface Props {
  domainId: { itemId: string };
  isTopInitiative: boolean;
  tag: { title: string; colorCode: string; iconId: string };
  metadata: { archived: boolean; completedAt: string | null };
}

export const InitiativeTitle = ({
  domainId,
  isTopInitiative,
  tag,
  metadata,
}: Props) => {
  const { t } = useTranslation();
  const match = useRouteMatch<{ initiativeId: string }>(
    '/company/initiatives/:initiativeId'
  );

  const initiativeId = match?.params.initiativeId ?? undefined;

  return (
    <div
      className={cx('InitiativeTitle__link flx ', {
        active: domainId.itemId === initiativeId,
      })}
    >
      {isTopInitiative && (
        <div>
          <InitiativeTag tag={tag} hideTitle />
        </div>
      )}

      <div className="flx flx--ai-center ml--s">
        {tag.title}
        {metadata.archived && (
          <div className="flx ml--xs flx--jc-center flx--ai-center">
            <Tooltip placement="topRight" title={t('common.archived')}>
              <div className="InitiativeTitle__icon InitiativeTitle__icon--archived">
                <ArchivedIcon />
              </div>
            </Tooltip>
          </div>
        )}
        {metadata.completedAt && (
          <div className="flx ml--xs flx--jc-center flx-ai-center">
            <Tooltip placement="topRight" title={t('common.completed')}>
              <div className="InitiativeTitle__icon">
                <CheckIcon style={{ color: Colors.Status.OK_GREEN }} />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};
