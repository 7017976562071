import { Colors } from '../../../../../../../componentLibrary/Colors';
import { useTranslation } from 'react-i18next';
import { PostponedIcon } from '../../../../../../../../icons/PostponedIcon';
import { OverviewProgressCard } from '../../../../progressCard/OverviewProgressCard';
import { CompletionCard } from '../../../../progressCard/CompletionCard';
import { SuccessIcon } from '../../../../../../../../icons/performanceIcons/SuccessIcon';
import { WarningIcon } from '../../../../../../../../icons/performanceIcons/WarningIcon';
import { ArchiveOutlinedIcon } from '../../../../../../../../icons/ArchiveIcon';
import cx from 'classnames';
import './PlannedSprint.less';

interface Props {
  numberOfSprintKeyActivities: number;
  numberOfCompletedActivities: number;
  numberOfOverdueActivities: number;
  numberOfUpcomingActivities: number;
  numberOfArchivedActivities: number;
}

export const PlannedSprint = ({
  numberOfSprintKeyActivities,
  numberOfCompletedActivities,
  numberOfOverdueActivities,
  numberOfUpcomingActivities,
  numberOfArchivedActivities,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={cx({
          'PlannedSprint__card--notActive': numberOfCompletedActivities === 0,
        })}
      >
        <CompletionCard
          SvgIcon={SuccessIcon}
          iconColor={
            numberOfCompletedActivities !== 0
              ? Colors.Status.OK_GREEN
              : Colors.Grays.DISABLED_FONTS
          }
          title={t('common.sprintKeyActivity.activities.completed')}
          numberOfCompletedActivities={
            numberOfCompletedActivities !== 0
              ? numberOfCompletedActivities
              : null
          }
          numberOfSprintKeyActivities={numberOfSprintKeyActivities}
          data-intercom-target="Completed sprint key activities card"
        />
      </div>
      <div
        className={cx({
          'PlannedSprint__card--notActive': numberOfOverdueActivities === 0,
        })}
      >
        <OverviewProgressCard
          SvgIcon={WarningIcon}
          iconColor={
            numberOfOverdueActivities !== 0
              ? Colors.Status.OVERDUE
              : Colors.Grays.DISABLED_FONTS
          }
          title={t('common.sprintKeyActivity.activities.overdue')}
          value={
            numberOfOverdueActivities !== 0 ? numberOfOverdueActivities : null
          }
          data-intercom-target="Overdue sprint key activities card"
        />
      </div>
      <div>
        <OverviewProgressCard
          SvgIcon={PostponedIcon}
          title={t('common.sprintKeyActivity.activities.upcoming')}
          value={numberOfUpcomingActivities}
          iconColor={Colors.Status.FUTURE_PURPLE}
          data-intercom-target="Upcoming sprint key activities card"
        />
      </div>
      <div
        className={cx({
          'PlannedSprint__card--notActive': numberOfArchivedActivities === 0,
        })}
      >
        <OverviewProgressCard
          SvgIcon={ArchiveOutlinedIcon}
          iconColor={
            numberOfArchivedActivities !== 0
              ? Colors.Grays.SECONDARY_FONT
              : Colors.Grays.DISABLED_FONTS
          }
          title={t('common.sprintKeyActivity.activities.archived')}
          value={
            numberOfArchivedActivities !== 0 ? numberOfArchivedActivities : null
          }
          data-intercom-target="Archived sprint key activities card"
        />
      </div>
    </>
  );
};
