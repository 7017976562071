import { gql, useLazyQuery } from '@apollo/client';
import { Dayjs } from 'dayjs';
import { Moment } from 'moment';
import { useCallback } from 'react';
import { GetTeamTimelineDocument } from '../generated/graphql';
import { convertDateStringsToMoments } from '../services/convertDateStringsToMoments';
import { standardDateFormat } from '../services/dateFormats';

export const useLazyTeamTimeline = () => {
  const [fetchTimeline, { data, loading, error }] = useLazyQuery(
    GetTeamTimelineDocument
  );

  const timeline = data?.teamTimeline.timeline.map((period) =>
    convertDateStringsToMoments(
      period,
      'timePeriodStartDate',
      'timePeriodEndDate'
    )
  );

  const getTimeline = useCallback(
    (
      teamId: string,
      startDate: Moment | string | Dayjs,
      endDate: Moment | string | Dayjs,
      tenantId?: string
    ) => {
      fetchTimeline({
        variables: {
          teamId,
          tenantId,
          startDate: standardDateFormat(startDate),
          endDate: standardDateFormat(endDate),
        },
      });
    },
    [fetchTimeline]
  );

  return {
    error,
    loading,
    timeline,
    getTimeline,
    startDate: data?.teamTimeline.startDate,
    endDate: data?.teamTimeline.endDate,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_TIMELINE = gql`
  query getTeamTimeline(
    $teamId: ID!
    $startDate: String!
    $endDate: String!
    $tenantId: ID
  ) {
    teamTimeline(
      teamId: $teamId
      startDate: $startDate
      endDate: $endDate
      tenantId: $tenantId
    ) {
      startDate
      endDate
      timeline {
        timePeriodStartDate
        timePeriodEndDate
      }
    }
  }
`;
