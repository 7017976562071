import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './KeyActivitySection.less';
import cx from 'classnames';
import { AccordionIconDown } from '../../../../../../../../../icons/AccordionIconDown';
import { DisplayDate } from '../../../../../../../../../components/DisplayDate';

interface Props {
  numberOfReports: number;
  status: React.ReactNode;
  title: string;
  deadline?: string;
  note?: string | null;
  expandReports: boolean;
  children?: React.ReactNode;
}

export const KeyActivitySection: React.FC<Props> = ({
  status,
  title,
  deadline,
  expandReports,
  numberOfReports,
  note,
  children,
}) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const hasAchievements = numberOfReports > 0;

  // Todo: it is considered a bad practice to update state in a useEffect like this.
  // For more info see https://beta.reactjs.org/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
  useEffect(() => {
    hasAchievements && setOpen(expandReports);
  }, [expandReports, hasAchievements]);

  return (
    <div className="flx">
      <div className="KeyActivitySection__status">{status}</div>
      <div
        className="flx flx--1 clickable"
        onClick={() => setOpen((current) => !current)}
      >
        <div className="flx--1">
          <h3 className="mb--none">{title}</h3>
          {deadline && (
            <>
              <Typography.Text type="secondary" className="space--r">
                {t('common.deadline')}:
              </Typography.Text>
              <DisplayDate date={deadline} className="mr--xl" />
            </>
          )}
          <Typography.Text type="secondary" className="space--r">
            {t('WeeklyKeyActivitySection.reports')}
          </Typography.Text>
          <span>{numberOfReports}</span>

          {note && (
            <div className="KeyActivitySection__CommitmentNote">
              <span className="bold space--r">
                {t('WeeklyKeyActivitySection.note')}:
              </span>
              {note}
            </div>
          )}
          <div
            className={cx('KeyActivitySection__ToggleContent', {
              open: isOpen,
            })}
          >
            <div className="KeyActivitySection__ToggleContentInner preserveLinebreaks">
              {children}
            </div>
          </div>
        </div>

        <div className="ml--l">
          <AccordionIconDown
            className={cx('KeyActivitySection__AccordionIcon', {
              open: isOpen,
            })}
          />
        </div>
      </div>
    </div>
  );
};
