import Icon from '@ant-design/icons';

const ForumIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.333496 10.3334V1.00004C0.333496 0.811152 0.397385 0.652818 0.525163 0.525041C0.652941 0.397263 0.811274 0.333374 1.00016 0.333374H9.66683C9.85572 0.333374 10.0141 0.397263 10.1418 0.525041C10.2696 0.652818 10.3335 0.811152 10.3335 1.00004V7.00004C10.3335 7.18893 10.2696 7.34726 10.1418 7.47504C10.0141 7.60282 9.85572 7.66671 9.66683 7.66671H3.00016L0.333496 10.3334ZM3.66683 11C3.47794 11 3.31961 10.9362 3.19183 10.8084C3.06405 10.6806 3.00016 10.5223 3.00016 10.3334V9.00004H11.6668V3.00004H13.0002C13.1891 3.00004 13.3474 3.06393 13.4752 3.19171C13.6029 3.31948 13.6668 3.47782 13.6668 3.66671V13.6667L11.0002 11H3.66683ZM9.00016 1.66671H1.66683V7.11671L2.45016 6.33337H9.00016V1.66671Z" />
  </svg>
);

export const ForumIcon = (props: any) => (
  <Icon component={ForumIconSvg} {...props} />
);
