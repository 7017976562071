import { ComponentType } from 'react';
import './OverviewProgressCard.less';
import { statusColorHelper } from '../../../../../services/statusColorHelper';
import { ProgressCard } from './ProgressCard';

interface BaseProps {
  SvgIcon: ComponentType;
  title: string;
  value?: number | null;
  subText?: React.ReactElement;
}
interface Props1 extends BaseProps {
  iconColor?: undefined;
  isPercentage: true;
}
interface Props2 extends BaseProps {
  iconColor: string;
  isPercentage?: false;
}

export const OverviewProgressCard = ({
  SvgIcon,
  title,
  value,
  subText,
  iconColor,
  isPercentage,
}: Props1 | Props2) => {
  const statusColor = statusColorHelper(value);

  let val = value ?? '-';
  if (value != null && isPercentage) {
    val = value + '%';
  }

  return (
    <ProgressCard
      SvgIcon={SvgIcon}
      color={isPercentage ? statusColor : iconColor}
    >
      <div className="OverviewProgressCard flx flx flx--column ">
        <div className="flx flx--ai-center flx--jc-space-between">
          <h4 className="OverviewProgressCard__title">{title}</h4>
          <div
            className="OverviewProgressCard__value pl--l"
            style={{
              color: isPercentage ? statusColor : undefined,
            }}
          >
            {val}
          </div>
        </div>

        {subText && (
          <div className="mt--xs">
            <h5>{subText}</h5>
          </div>
        )}
      </div>
    </ProgressCard>
  );
};
