import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { DisplayDate } from '../../../../../../../../components/DisplayDate';
import { FooterItem } from '../../../../../../../../components/mig/MigCard';
import { MigOutOfDateWarning } from '../../../../../../../../components/mig/MigOutOfDateWarning';
import { PerformanceTag } from '../../../../../../../../components/PerformanceTag';
import { roundToDecimals } from '../../../../../../../../services/roundNumbersHelper';
import './detailsTabFooter.less';
import { gql } from '@apollo/client';
import { DetailsTabFooterMigFragment } from '../../../../../../../../generated/graphql';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIG_DETAILS_TAB_FOOTER_FRAGMENT = gql`
  fragment DetailsTabFooterMig on MigV2 {
    id
    ...MigOutOfDateWarningMig
  }
`;

interface Props {
  targetDate: string | null | undefined;
  targetValue: number | undefined;
  actualValue?: number | null;
  targetFulfillment: number | null | undefined;
  mig: DetailsTabFooterMigFragment;
}

export const DetailsTabFooter = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Row className="MigDrawerCard__footer">
      <Col span={24} className="flx">
        <FooterItem title={t('MigCard.resultFor')}>
          <DisplayDate date={props?.targetDate} emptyState={'-'} />
        </FooterItem>
        <FooterItem title={t('common.goal')}>
          <span className="MigGoal">
            {roundToDecimals(props?.targetValue) ?? '-'}
          </span>
        </FooterItem>
        <FooterItem title={t('common.actual')}>
          <span className="MigActual">
            {roundToDecimals(props?.actualValue) ?? '-'}
          </span>
        </FooterItem>
        <FooterItem title={t('MigCard.goalFulfillment')}>
          <PerformanceTag value={props?.targetFulfillment} />
          <MigOutOfDateWarning
            mig={props.mig}
            iconClassName="MigCard__warningIcon"
          />
        </FooterItem>
      </Col>
    </Row>
  );
};
