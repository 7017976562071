import React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface Props {
  delta: number;
}

const THRESHOLD = 2;

export const TrendArrow = ({ delta }: Props) => {
  const { t } = useTranslation();
  const direction =
    delta > 0 + THRESHOLD ? -45 : delta < 0 - THRESHOLD ? 45 : 0;

  return (
    <ArrowRightOutlined
      title={t('TrendArrow.deltaValue', { value: delta })}
      style={{
        transform: `rotate(${direction}deg)`,
      }}
    />
  );
};
