import Icon from '@ant-design/icons';

const ConvertSkaToMilestoneIconSvg = () => (
  <svg
    viewBox="64 64 896 896"
    data-icon="flag"
    fill="currentColor"
    aria-hidden="true"
    width="1em"
    height="1em"
  >
    <path
      d="M214.583 363.396h403.77a68.655 68.655 0 0 1 68.654 68.655v399.82a68.655 68.655 0 0 1-68.655 68.655h-403.77a68.655 68.655 0 0 1-68.654-68.655v-399.82a68.655 68.655 0 0 1 68.655-68.655z"
      fill="#fff"
      stroke="#9a9a9a"
      stroke-width="59.995"
      stroke-linecap="round"
      stroke-linejoin="bevel"
      stroke-miterlimit="30"
    />
    <path
      d="m511.584 785.144-97.94-72.929-96.98 74.201 39.093-115.683L255.22 601.43l122.102 1.432 34.844-117.033 36.37 116.568 122.073-3.026-99.625 70.61z"
      fill="#9a9a9a"
      stroke="#999"
      stroke-width="24.29"
    />
    <path
      d="M430.94 130.28h403.77a68.655 68.655 0 0 1 68.655 68.655v399.82a68.655 68.655 0 0 1-68.655 68.655H430.94a68.655 68.655 0 0 1-68.654-68.655v-399.82a68.655 68.655 0 0 1 68.654-68.655z"
      fill="#fff"
      stroke="#000"
      stroke-width="59.995"
      stroke-linecap="round"
      stroke-linejoin="bevel"
      stroke-miterlimit="30"
    />
    <path
      fill="#000"
      d="M803.322 302.492H685.355V250.42c0-8.157-6.59-14.746-14.746-14.746H482.6v-18.433a3.697 3.697 0 0 0-3.686-3.686h-25.805a3.697 3.697 0 0 0-3.686 3.686v361.273a3.697 3.697 0 0 0 3.686 3.687h25.805a3.697 3.697 0 0 0 3.687-3.687V456.862h114.28v52.071c0 8.157 6.59 14.746 14.746 14.746h191.696c8.156 0 14.745-6.59 14.745-14.746V317.237c0-8.156-6.59-14.745-14.745-14.745zM482.6 423.684V268.853h169.577v154.831zm302.29 66.817H630.057v-33.639h51.61a3.697 3.697 0 0 0 3.687-3.686V335.67h99.534z"
    />
  </svg>
);

export const ConvertSkaToMilestoneIcon = (props: any) => (
  <Icon component={ConvertSkaToMilestoneIconSvg} {...props} />
);
