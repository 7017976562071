import Icon from '@ant-design/icons';

const TimelineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 8"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.99984 8C1.63317 8 1.31928 7.86944 1.05817 7.60833C0.797059 7.34722 0.666504 7.03333 0.666504 6.66667C0.666504 6.3 0.797059 5.98611 1.05817 5.725C1.31928 5.46389 1.63317 5.33333 1.99984 5.33333H2.17484C2.22484 5.33333 2.27762 5.34444 2.33317 5.36667L5.3665 2.33333C5.34428 2.27778 5.33317 2.225 5.33317 2.175V2C5.33317 1.63333 5.46373 1.31944 5.72484 1.05833C5.98595 0.797222 6.29984 0.666667 6.6665 0.666667C7.03317 0.666667 7.34706 0.797222 7.60817 1.05833C7.86928 1.31944 7.99984 1.63333 7.99984 2C7.99984 2.02222 7.98873 2.13333 7.9665 2.33333L9.6665 4.03333C9.72206 4.01111 9.77484 4 9.82484 4H10.1748C10.2248 4 10.2776 4.01111 10.3332 4.03333L12.6998 1.66667C12.6776 1.61111 12.6665 1.55833 12.6665 1.50833V1.33333C12.6665 0.966667 12.7971 0.652778 13.0582 0.391667C13.3193 0.130556 13.6332 0 13.9998 0C14.3665 0 14.6804 0.130556 14.9415 0.391667C15.2026 0.652778 15.3332 0.966667 15.3332 1.33333C15.3332 1.7 15.2026 2.01389 14.9415 2.275C14.6804 2.53611 14.3665 2.66667 13.9998 2.66667H13.8248C13.7748 2.66667 13.7221 2.65556 13.6665 2.63333L11.2998 5C11.3221 5.05556 11.3332 5.10833 11.3332 5.15833V5.33333C11.3332 5.7 11.2026 6.01389 10.9415 6.275C10.6804 6.53611 10.3665 6.66667 9.99984 6.66667C9.63317 6.66667 9.31928 6.53611 9.05817 6.275C8.79706 6.01389 8.6665 5.7 8.6665 5.33333V5.15833C8.6665 5.10833 8.67762 5.05556 8.69984 5L6.99984 3.3C6.94428 3.32222 6.8915 3.33333 6.8415 3.33333H6.6665C6.64428 3.33333 6.53317 3.32222 6.33317 3.3L3.29984 6.33333C3.32206 6.38889 3.33317 6.44167 3.33317 6.49167V6.66667C3.33317 7.03333 3.20262 7.34722 2.9415 7.60833C2.68039 7.86944 2.3665 8 1.99984 8Z" />
  </svg>
);

export const TimelineIcon = (props: any) => (
  <Icon component={TimelineIconSvg} {...props} />
);
