import { initiativeTreeWalker } from '../appPages/company/initiatives_old/initiativesPageV1/services/initiativeTreeNodeHelper';
import { InitiativeContentFragment } from '../generated/graphql';
import { findHierarchicalTeamById, useHierarchicalTeams } from './useTeams';
import { useHierarchicalInitiatives } from './useTenantInitiatives';

export const useGroupedAndFilteredInitiatives = (ids: {
  tenantId?: string;
  teamId?: string;
}) => {
  const { tenantId, teamId } = ids;
  const {
    allInitiatives,
    hierarchicalStrategicInitiatives,
    hierarchicalTeamInitiativeTree,
    loading: loadingInitiatives,
    error: errorInitiatives,
  } = useHierarchicalInitiatives(tenantId);

  const allInitiativesRecord = allInitiatives.reduce(
    (acc, initiativeNode) => ({
      ...acc,
      [initiativeNode.domainId.itemId]: initiativeNode,
    }),
    {} as Record<string, InitiativeContentFragment>
  );

  const {
    hierarchicalTeams,
    loading: loadingTeams,
    error: errorTeams,
  } = useHierarchicalTeams(tenantId);

  if (!teamId) {
    return {
      allInitiativesRecord,
      hierarchicalStrategicInitiatives: hierarchicalStrategicInitiatives,
      hierarchicalTeamInitiatives: hierarchicalTeamInitiativeTree,
      loading: loadingInitiatives || loadingTeams,
      error: errorInitiatives || errorTeams,
    };
  }

  // When filtered by a teamId, we find that team in the hierarchicalTeams tree, that node
  // contains the teamId and all descendantIds, we then filter the initiatives by those ids
  const team = findHierarchicalTeamById(hierarchicalTeams, teamId);

  // We need to include the teamId in the array of ids to filter
  const teamIdWithDescendantsIds = [teamId, ...(team?.descendantIds ?? [])];

  // Using the tree walker to filter the initiatives by the teamId and descendantIds and if the node or any of its children are contributing
  // we set the isContributing flag to true, building a new tree with the filtered nodes
  const filteredStrategicInitiatives = initiativeTreeWalker(
    hierarchicalStrategicInitiatives,
    (node) => {
      if (
        node.directlyContributingTeamIds.some((tcId) =>
          teamIdWithDescendantsIds.includes(tcId)
        ) ||
        node.children?.some((child) => child.isContributing)
      ) {
        return { ...node, isContributing: true };
      }
      return null;
    }
  );

  const filteredTeamInitiatives = initiativeTreeWalker(
    hierarchicalTeamInitiativeTree,
    (node) => {
      if (
        node.directlyContributingTeamIds.includes(teamId) ||
        node.children?.some((child) => child.isContributing)
      ) {
        return { ...node, isContributing: true };
      }
      return null;
    }
  );

  return {
    allInitiativesRecord,
    hierarchicalStrategicInitiatives: filteredStrategicInitiatives,
    hierarchicalTeamInitiatives: filteredTeamInitiatives,
    loading: loadingInitiatives || loadingTeams,
    error: errorInitiatives || errorTeams,
  };
};
