import { useTranslation } from 'react-i18next';
import Table, { ColumnProps } from 'antd/es/table';

import { Skeleton } from 'antd';

export const CompanyInitiativeTableSkeleton = () => {
  const { t } = useTranslation();
  const columns: ColumnProps<any>[] = [
    {
      title: t('common.status'),
      width: 120,
      key: 'status',
      dataIndex: ['initiative', 'metadata'],
      render: () => {
        return <Skeleton paragraph={false} />;
      },
    },
    {
      title: t('CompanyInitiativeTable.tagColumn'),
      width: 120,
      key: 'status',
      dataIndex: ['initiative', 'tag'],
      render: () => {
        return <Skeleton paragraph={false} />;
      },
    },
    {
      title: t('CompanyInitiativeTable.initiativeColumn'),
      key: 'name',
      dataIndex: ['initiative', 'name'],
      render: () => {
        return <Skeleton paragraph={false} />;
      },
    },
    {
      title: '',
      key: 'unlink',
      width: 90,
      render: () => {
        return <Skeleton paragraph={false} />;
      },
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      dataSource={[{ id: 1 }, { id: 2 }]}
      columns={columns}
      style={{ borderRadius: '6px' }}
      pagination={false}
    />
  );
};
