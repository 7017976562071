export const debounce = <T extends (...args: any[]) => void>(
  cb: T,
  delay = 500
) => {
  let timerId: NodeJS.Timeout | null = null;
  return (...args: Parameters<T>) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      timerId = null;
      cb(...args);
    }, delay);
  };
};
