import Icon from '@ant-design/icons';

const CordIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 7.99996V6.66663H11.3333C11.5222 6.66663 11.6806 6.73052 11.8083 6.85829C11.9361 6.98607 12 7.1444 12 7.33329C12 7.52218 11.9361 7.68052 11.8083 7.80829C11.6806 7.93607 11.5222 7.99996 11.3333 7.99996H10ZM10 10.6666V9.33329H11.3333C11.5222 9.33329 11.6806 9.39718 11.8083 9.52496C11.9361 9.65274 12 9.81107 12 9.99996C12 10.1888 11.9361 10.3472 11.8083 10.475C11.6806 10.6027 11.5222 10.6666 11.3333 10.6666H10ZM7.33333 11.3333C6.96667 11.3333 6.65278 11.2027 6.39167 10.9416C6.13056 10.6805 6 10.3666 6 9.99996H4.66667V7.33329H6C6 6.96663 6.13056 6.65274 6.39167 6.39163C6.65278 6.13052 6.96667 5.99996 7.33333 5.99996H9.33333V11.3333H7.33333ZM2.66667 9.33329C1.93333 9.33329 1.30556 9.07218 0.783333 8.54996C0.261111 8.02774 0 7.39996 0 6.66663C0 5.93329 0.261111 5.30552 0.783333 4.78329C1.30556 4.26107 1.93333 3.99996 2.66667 3.99996H3.66667C3.94444 3.99996 4.18056 3.90274 4.375 3.70829C4.56944 3.51385 4.66667 3.27774 4.66667 2.99996C4.66667 2.72218 4.56944 2.48607 4.375 2.29163C4.18056 2.09718 3.94444 1.99996 3.66667 1.99996H1.33333C1.14444 1.99996 0.986111 1.93607 0.858333 1.80829C0.730556 1.68051 0.666667 1.52218 0.666667 1.33329C0.666667 1.1444 0.730556 0.98607 0.858333 0.858293C0.986111 0.730515 1.14444 0.666626 1.33333 0.666626H3.66667C4.31111 0.666626 4.86111 0.894404 5.31667 1.34996C5.77222 1.80551 6 2.35551 6 2.99996C6 3.6444 5.77222 4.1944 5.31667 4.64996C4.86111 5.10551 4.31111 5.33329 3.66667 5.33329H2.66667C2.3 5.33329 1.98611 5.46385 1.725 5.72496C1.46389 5.98607 1.33333 6.29996 1.33333 6.66663C1.33333 7.03329 1.46389 7.34718 1.725 7.60829C1.98611 7.8694 2.3 7.99996 2.66667 7.99996H4V9.33329H2.66667Z" />
  </svg>
);

export const CordIcon = (props: any) => (
  <Icon component={CordIconSvg} {...props} />
);
