import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';

export enum MilestoneOrActiveToggleEnum {
  Milestones,
  Activities,
}

export const MilestoneOrActiveToggleSkeleton = () => {
  const { t } = useTranslation();
  return (
    <Radio.Group disabled={true}>
      <Radio value={MilestoneOrActiveToggleEnum.Milestones}>
        {t('common.milestones')}
      </Radio>
      <Radio value={MilestoneOrActiveToggleEnum.Activities}>
        {t('common.activities')}
      </Radio>
    </Radio.Group>
  );
};
