export type Stringable = string | number | null | undefined;

export const enforceString = (value: Stringable): string => {
  const valueType = typeof value;

  // Not using `valueType` because ts couldn't restrict return type to string
  if (typeof value === 'string') {
    return value;
  }
  if (valueType === 'number') {
    return '' + value;
  }
  if (value == null) {
    return '';
  }

  throw new Error(`Cannot convert type ${valueType} to string`);
};
