import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { DisplayDateRange } from '../../../../../../../../../components/DisplayDateRange';
import {
  SprintKeyActivityTeamSummary_MitemSprintFragment,
  SprintKeyActivityTeamSummary_SprintTermFragment,
} from '../../../../../../../../../generated/graphql';
import { useClosestPeriods } from '../../../../../../../../../hooks/useClosestPeriods';
import { useKeyActivityPerformance } from '../../../../../../../../../hooks/useKeyActivityPerformanceQuery';
import { KeyActivitySummaryGraph } from '../common/KeyActivitySummaryGraph';

import { SprintPerformance } from './SprintKeyActivityTeamSummary/SprintPreviousPeriod/SprintPerformance';
import { gql } from '@apollo/client';

interface Props {
  sprintTerm: SprintKeyActivityTeamSummary_SprintTermFragment;
  sprint?: SprintKeyActivityTeamSummary_MitemSprintFragment | null;
}

export const SprintKeyActivityTeamSummary = ({ sprintTerm, sprint }: Props) => {
  const { t } = useTranslation();
  const { lastFinishedPeriod } = useClosestPeriods(sprintTerm.teamId);

  const { data } = useKeyActivityPerformance(
    sprintTerm.teamId,
    sprintTerm.id,
    sprintTerm.tenantId
  );

  return (
    <div>
      <h3>{t('SprintKeyActivityTeamSummary.title')}</h3>
      <Divider className="mt--s" />
      <>
        <div>
          <SprintPerformance sprint={sprint} teamId={sprintTerm.teamId} />

          <Divider />

          <div>
            <h4 className="mb--none">
              {t('SprintKeyActivityTeamSummary.sprintPerformance')}
            </h4>
            <span className="txt--secondary font-size--sm">
              <DisplayDateRange
                startDate={sprintTerm.startDate}
                endDate={sprintTerm.endDate}
              />
            </span>

            <KeyActivitySummaryGraph
              periodData={sprintTerm?.periodData ?? []}
              referenceDate={lastFinishedPeriod?.timePeriodEndDate}
              targetFulfillment={data?.targetFulfillment}
            />
          </div>
        </div>
      </>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_SUMMARY = gql`
  fragment SprintKeyActivityTeamSummary_MitemSprint on MitemSprint {
    id
    ...SprintPerformance_MitemSprint
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_TERM = gql`
  fragment SprintKeyActivityTeamSummary_SprintTerm on MitemTerm {
    id
    teamId
    tenantId
    startDate
    endDate
    periodData {
      targetDate
      actual
      goal
    }
  }
`;
