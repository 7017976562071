interface BasicUser {
  displayName?: string | null;
  name?: string | null;
  email?: string | null;
}
export const friendlyUsername = (user: BasicUser) =>
  user.displayName ||
  user.name ||
  user.email?.replace('@', '\u200B@').replace('.', '.\u200B') ||
  'Anonymous user';
