export const addReachGoalAtEndDateStrategy = <T extends Period>(
  baseCurve: T[],
  previousPeriodIndex: number | null
) => {
  if (previousPeriodIndex == null) return baseCurve;
  const previousActual = baseCurve[previousPeriodIndex].actual ?? 0;
  const endGoal = baseCurve[baseCurve.length - 1].goal;
  const numberOfWeeksLeft = baseCurve.length - previousPeriodIndex;
  const perWeek = (endGoal - previousActual) / (numberOfWeeksLeft - 1);

  return baseCurve.map((bc) => {
    if (bc.timePeriodIndex >= previousPeriodIndex) {
      return {
        ...bc,
        strategy:
          previousActual + perWeek * (bc.timePeriodIndex - previousPeriodIndex),
      };
    }

    return bc;
  });
};

export const addReachGoalThisPeriodStrategy = <T extends Period>(
  baseCurve: T[],
  previousPeriodIndex: number | null
) => {
  if (previousPeriodIndex == null) return baseCurve;

  const previousActual = baseCurve[previousPeriodIndex].actual ?? 0;

  return baseCurve.map((bc) => {
    if (bc.timePeriodIndex === previousPeriodIndex) {
      return {
        ...bc,
        strategy: previousActual,
      };
    } else if (bc.timePeriodIndex > previousPeriodIndex) {
      return {
        ...bc,
        strategy: bc.goal,
      };
    }

    return bc;
  });
};

// deprecated
export const addSameAsLastPeriodStrategy = <T extends Period>(
  baseCurve: T[],
  previousPeriodIndex: number | null
) => {
  if (previousPeriodIndex == null) return baseCurve;
  const previousActual = baseCurve[previousPeriodIndex]?.actual ?? 0;
  const previousPreviousActual =
    baseCurve[previousPeriodIndex - 1]?.actual ?? 0;
  const perWeek = previousActual - previousPreviousActual;

  return baseCurve.map((bc) => {
    if (bc.timePeriodIndex >= previousPeriodIndex) {
      return {
        ...bc,
        strategy:
          previousActual + perWeek * (bc.timePeriodIndex - previousPeriodIndex),
      };
    }

    return bc;
  });
};

export const addAccordingToPlan = <T extends Period>(
  baseCurve: T[],
  previousPeriodIndex: number | null
) => {
  if (previousPeriodIndex == null) return baseCurve;
  let previousStratValue = baseCurve[previousPeriodIndex]?.actual ?? 0;

  return baseCurve.map((period, index) => {
    if (period.timePeriodIndex >= previousPeriodIndex) {
      const nextPeriod = baseCurve[index + 1];

      const newPeriod = {
        ...period,
        strategy: previousStratValue,
      };

      if (nextPeriod) {
        // Note that this mutates the previousStratValue variable that lives outside the scope of the mapping operation,
        // the updated value is not used until the next iteration/period. Yes this is ugly, but it works.
        previousStratValue =
          previousStratValue + (nextPeriod.goal - period.goal);
      }

      return newPeriod;
    }

    return period;
  });
};

export enum Strategy {
  ACCORDING_TO_PLAN = 'ACCORDING_TO_PLAN',
  ALREADY_ON_TRACK = 'ALREADY_ON_TRACK',
  REACH_GOAL_AT_END_DATE = 'REACH_GOAL_AT_END_DATE',
  REACH_GOAL_THIS_PERIOD = 'REACH_GOAL_THIS_PERIOD',
  SAME_AS_LAST_PERIOD = 'SAME_AS_LAST_PERIOD',
}

interface Period {
  timePeriodIndex: number;
  goal: number;
  actual?: number | null;
}
