import Icon from '@ant-design/icons';

const WarningOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.666504 13L7.99984 0.333374L15.3332 13H0.666504ZM2.9665 11.6667H13.0332L7.99984 3.00004L2.9665 11.6667ZM7.99984 11C8.18873 11 8.34706 10.9362 8.47484 10.8084C8.60262 10.6806 8.6665 10.5223 8.6665 10.3334C8.6665 10.1445 8.60262 9.98615 8.47484 9.85837C8.34706 9.7306 8.18873 9.66671 7.99984 9.66671C7.81095 9.66671 7.65262 9.7306 7.52484 9.85837C7.39706 9.98615 7.33317 10.1445 7.33317 10.3334C7.33317 10.5223 7.39706 10.6806 7.52484 10.8084C7.65262 10.9362 7.81095 11 7.99984 11ZM7.33317 9.00004H8.6665V5.66671H7.33317V9.00004Z" />
  </svg>
);

export const WarningOutlineIcon = (props: any) => (
  <Icon component={WarningOutlineIconSvg} {...props} />
);
