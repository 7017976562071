import { useEffect } from 'react';
import { PerformanceTag } from '../../../../../components/PerformanceTag';
import { useTeamMigPerformanceLazy } from '../../../../../hooks/useMigPerformanceQuery';

interface Props {
  teamId: string;
  fetchDetails: boolean;
}

export const TeamMigPerformanceTag = ({ teamId, fetchDetails }: Props) => {
  const { fetchTeamMigPerformance, teamMigPerformance, loading } =
    useTeamMigPerformanceLazy();

  useEffect(() => {
    if (fetchDetails) fetchTeamMigPerformance({ variables: { teamId } });
  }, [fetchDetails, fetchTeamMigPerformance, teamId]);

  return <PerformanceTag value={teamMigPerformance} loading={loading} />;
};
