import { SelectionIndicator } from '../../appPages/company/initiatives_old/initiativesPageV1/initiativeDrawer/SelectionIndicator';
import './IconContainer.less';
import { InitiativeIcon } from '../../appPages/company/initiatives_old/initiativesPageV1/InitiativeIcons';

interface Props {
  id?: string;
  onChange?: (tagIcon: InitiativeIcon) => void;
  icons: Record<InitiativeIcon, React.ReactNode>;
  value?: InitiativeIcon;
}

export const IconContainer = ({ id, value, onChange, icons }: Props) => {
  return (
    <div className="flx flx--row flx--gap--xs flx--wrap" id={id}>
      {Object.entries(icons).map(([iconId, icon], i) => {
        return (
          <SelectionIndicator key={i} active={value === iconId}>
            <div
              className="IconContainer__icon clickable"
              onClick={() => onChange?.(iconId as InitiativeIcon)}
            >
              {icon}
            </div>
          </SelectionIndicator>
        );
      })}
    </div>
  );
};
