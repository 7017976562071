import Icon from '@ant-design/icons';

const CarOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 11"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 9.66671V10.3334C2 10.5223 1.93622 10.6805 1.80867 10.808C1.68067 10.936 1.52222 11 1.33333 11H0.666667C0.477778 11 0.319556 10.936 0.192 10.808C0.0640001 10.6805 0 10.5223 0 10.3334V5.00004L1.4 1.00004C1.46667 0.800041 1.58622 0.63893 1.75867 0.516707C1.93067 0.394485 2.12222 0.333374 2.33333 0.333374H9.66667C9.87778 0.333374 10.0696 0.394485 10.242 0.516707C10.414 0.63893 10.5333 0.800041 10.6 1.00004L12 5.00004V10.3334C12 10.5223 11.936 10.6805 11.808 10.808C11.6804 10.936 11.5222 11 11.3333 11H10.6667C10.4778 11 10.3196 10.936 10.192 10.808C10.064 10.6805 10 10.5223 10 10.3334V9.66671H2ZM1.86667 3.66671H10.1333L9.43333 1.66671H2.56667L1.86667 3.66671ZM3 7.66671C3.27778 7.66671 3.51378 7.56937 3.708 7.37471C3.90267 7.18049 4 6.94449 4 6.66671C4 6.38893 3.90267 6.15293 3.708 5.95871C3.51378 5.76404 3.27778 5.66671 3 5.66671C2.72222 5.66671 2.48622 5.76404 2.292 5.95871C2.09733 6.15293 2 6.38893 2 6.66671C2 6.94449 2.09733 7.18049 2.292 7.37471C2.48622 7.56937 2.72222 7.66671 3 7.66671ZM9 7.66671C9.27778 7.66671 9.51378 7.56937 9.708 7.37471C9.90267 7.18049 10 6.94449 10 6.66671C10 6.38893 9.90267 6.15293 9.708 5.95871C9.51378 5.76404 9.27778 5.66671 9 5.66671C8.72222 5.66671 8.48622 5.76404 8.292 5.95871C8.09733 6.15293 8 6.38893 8 6.66671C8 6.94449 8.09733 7.18049 8.292 7.37471C8.48622 7.56937 8.72222 7.66671 9 7.66671ZM1.33333 8.33337H10.6667V5.00004H1.33333V8.33337Z" />
  </svg>
);

export const CarOutlineIcon = (props: any) => (
  <Icon component={CarOutlineIconSvg} {...props} />
);
