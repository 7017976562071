import Icon from '@ant-design/icons';

const AddDomainIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.333496 12V0H7.00016V2.66667H13.6668V8H12.3335V4H7.00016V5.33333H8.3335V6.66667H7.00016V8H8.3335V9.33333H7.00016V10.6667H9.66683V12H0.333496ZM1.66683 10.6667H3.00016V9.33333H1.66683V10.6667ZM1.66683 8H3.00016V6.66667H1.66683V8ZM1.66683 5.33333H3.00016V4H1.66683V5.33333ZM1.66683 2.66667H3.00016V1.33333H1.66683V2.66667ZM4.3335 10.6667H5.66683V9.33333H4.3335V10.6667ZM4.3335 8H5.66683V6.66667H4.3335V8ZM4.3335 5.33333H5.66683V4H4.3335V5.33333ZM4.3335 2.66667H5.66683V1.33333H4.3335V2.66667ZM12.3335 13.3333V12H11.0002V10.6667H12.3335V9.33333H13.6668V10.6667H15.0002V12H13.6668V13.3333H12.3335ZM9.66683 6.66667V5.33333H11.0002V6.66667H9.66683ZM9.66683 9.33333V8H11.0002V9.33333H9.66683Z" />
  </svg>
);

export const AddDomainIcon = (props: any) => (
  <Icon component={AddDomainIconSvg} {...props} />
);
