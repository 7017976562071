import { AccountTreeOutlineIcon } from '../../../../icons/initiativeIcons/AccountTreeOutlineIcon';
import { AddDomainIcon } from '../../../../icons/initiativeIcons/AddDomainIcon';
import { AddShoppingCartOutlineIcon } from '../../../../icons/initiativeIcons/AddShoppingCartOutlineIcon';
import { AirplaneIcon } from '../../../../icons/initiativeIcons/AirplaneIcon';
import { AlarmOutlineIcon } from '../../../../icons/initiativeIcons/AlarmOutlineIcon';
import { ApartmentOutlineIcon } from '../../../../icons/initiativeIcons/ApartmentOutlineIcon';
import { BankIcon } from '../../../../icons/initiativeIcons/BankIcon';
import { BoltOutlineIcon } from '../../../../icons/initiativeIcons/BoltOutlineIcon';
import { BugOutlineIcon } from '../../../../icons/initiativeIcons/BugOutlineIcon';
import { BusOutlineIcon } from '../../../../icons/initiativeIcons/BusOutlineIcon';
import { CalendarIcon } from '../../../../icons/initiativeIcons/CalendarIcon';
import { CallOutlineIcon } from '../../../../icons/initiativeIcons/CallOutlineIcon';
import { CarOutlineIcon } from '../../../../icons/initiativeIcons/CarOutlineIcon';
import { CelebratingIcon } from '../../../../icons/initiativeIcons/CelebratingIcon';
import { ChartIcon } from '../../../../icons/initiativeIcons/ChartIcon';
import { ChatIcon } from '../../../../icons/initiativeIcons/ChatIconSvg';
import { CheckboxIcon } from '../../../../icons/initiativeIcons/CheckboxIcon';
import { CoffeeIcon } from '../../../../icons/initiativeIcons/CoffeeIcon';
import { ComputerOutlineIcon } from '../../../../icons/initiativeIcons/ComputerOutlineIcon';
import { ConstructionWorkerIcon } from '../../../../icons/initiativeIcons/ConstructionWorkerIcon';
import { CordIcon } from '../../../../icons/initiativeIcons/CordIcon';
import { DeleteOutlineIcon } from '../../../../icons/initiativeIcons/DeleteOutlineIcon';
import { DollarIcon } from '../../../../icons/initiativeIcons/DollarIcon';
import { EnvelopeOutlineIcon } from '../../../../icons/initiativeIcons/EnvelopeOutlineIcon';
import { FaceOutlineIcon } from '../../../../icons/initiativeIcons/FaceOutlineIcon';
import { FlowSheetIcon } from '../../../../icons/initiativeIcons/FlowSheetIcon';
import { ForkRightIcon } from '../../../../icons/initiativeIcons/ForkRightIcon';
import { ForumIcon } from '../../../../icons/initiativeIcons/ForumIcon';
import { GamingIcon } from '../../../../icons/initiativeIcons/GamingIcon';
import { GlobeIcon } from '../../../../icons/initiativeIcons/GlobeIcon';
import { GolfCourseIcon } from '../../../../icons/initiativeIcons/GolfCourseIcon';
import { GraduateHatOutlineIcon } from '../../../../icons/initiativeIcons/GraduateHatOutlineIcon';
import { GrassIcon } from '../../../../icons/initiativeIcons/GrassIcon';
import { GrowthIcon } from '../../../../icons/initiativeIcons/GrowthIcon';
import { HumidityIcon } from '../../../../icons/initiativeIcons/HumidityIcon';
import { InfoOutlineIcon } from '../../../../icons/initiativeIcons/InfoOutlineIcon';
import { InternetIcon } from '../../../../icons/initiativeIcons/InternetIcon';
import { KeyOutlineIcon } from '../../../../icons/initiativeIcons/KeyOutlineIcon';
import { MapOutlineIcon } from '../../../../icons/initiativeIcons/MapOutlineIcon';
import { MeditationIcon } from '../../../../icons/initiativeIcons/MeditationIcon';
import { MergeIcon } from '../../../../icons/initiativeIcons/MergeIcon';
import { MicOutlineIcon } from '../../../../icons/initiativeIcons/MicOutlineIcon';
import { MoneyOutlineIcon } from '../../../../icons/initiativeIcons/MoneyOutlineIcon';
import { OpenDoorOutlineIcon } from '../../../../icons/initiativeIcons/OpenDoorOutlineIcon';
import { OpenLockIcon } from '../../../../icons/initiativeIcons/OpenLockIcon';
import { PaletteOutlineIcon } from '../../../../icons/initiativeIcons/PaletteOutlineIcon';
import { PlantOutlineIcon } from '../../../../icons/initiativeIcons/PlantOutlineIcon';
import { PortraitOutlineIcon } from '../../../../icons/initiativeIcons/PortraitOutlineIcon';
import { RealEstateIcon } from '../../../../icons/initiativeIcons/RealEstateIcon';
import { RobotFaceIcon } from '../../../../icons/initiativeIcons/RobotFaceIcon';
import { RocketOutlineIcon } from '../../../../icons/initiativeIcons/RocketOutlineIcon';
import { RunIcon } from '../../../../icons/initiativeIcons/RunIcon';
import { SavingsOutlineIcon } from '../../../../icons/initiativeIcons/SavingsOutlineIcon';
import { ScienceIcon } from '../../../../icons/initiativeIcons/ScienceIcon';
import { SearchOutlineIcon } from '../../../../icons/initiativeIcons/SearchOutlineIcon';
import { SendOutlineIcon } from '../../../../icons/initiativeIcons/SendOutlineIcon';
import { ShoppingCartOutlineIcon } from '../../../../icons/initiativeIcons/ShoppingCartOutlineIcon';
import { SmartphoneIcon } from '../../../../icons/initiativeIcons/SmartphoneIcon';
import { SnowyWeatherIcon } from '../../../../icons/initiativeIcons/SnowyWeatherIcon';
import { SparklesIcon } from '../../../../icons/initiativeIcons/SparklesIcon';
import { SprintIcon } from '../../../../icons/initiativeIcons/SprintIcon';
import { StarOutlineIcon } from '../../../../icons/initiativeIcons/StarOutlineIcon';
import { StopGestureIcon } from '../../../../icons/initiativeIcons/StopGestureIcon';
import { SyringeOutlineIcon } from '../../../../icons/initiativeIcons/SyringeOutlineIcon';
import { TagOutlineIcon } from '../../../../icons/initiativeIcons/TagOutlineIcon';
import { TimelineIcon } from '../../../../icons/initiativeIcons/TimelineIcon';
import { ToolsIcon } from '../../../../icons/initiativeIcons/ToolsIcon';
import { TreeOutlineIcon } from '../../../../icons/initiativeIcons/TreeOutlineIcon';
import { TruckOutlineIcon } from '../../../../icons/initiativeIcons/TruckOutlineIcon';
import { VerifiedBadgeOutlineIcon } from '../../../../icons/initiativeIcons/VerifiedBadgeOutlineIcon';
import { VideoCamIcon } from '../../../../icons/initiativeIcons/VideoCamIcon';
import { WarningOutlineIcon } from '../../../../icons/initiativeIcons/WarningOutlineIcon';

export const Icons = {
  accountTreeIcon: <AccountTreeOutlineIcon />,
  addDomainIcon: <AddDomainIcon />,
  addShoppingCartIcon: <AddShoppingCartOutlineIcon />,
  airplaneIcon: <AirplaneIcon />,
  alarmIcon: <AlarmOutlineIcon />,
  apartmentIcon: <ApartmentOutlineIcon />,
  bankIcon: <BankIcon />,
  boltIcon: <BoltOutlineIcon />,
  bugIcon: <BugOutlineIcon />,
  busIcon: <BusOutlineIcon />,
  calendarIcon: <CalendarIcon />,
  callIcon: <CallOutlineIcon />,
  carIcon: <CarOutlineIcon />,
  celebratingIcon: <CelebratingIcon />,
  chartIcon: <ChartIcon />,
  chatIcon: <ChatIcon />,
  checkboxIcon: <CheckboxIcon />,
  coffeeIcon: <CoffeeIcon />,
  computerIcon: <ComputerOutlineIcon />,
  constructionWorkerIcon: <ConstructionWorkerIcon />,
  cordIcon: <CordIcon />,
  deleteIcon: <DeleteOutlineIcon />,
  dollarIcon: <DollarIcon />,
  envelopeIcon: <EnvelopeOutlineIcon />,
  faceIcon: <FaceOutlineIcon />,
  flowSheetIcon: <FlowSheetIcon />,
  forkRightIcon: <ForkRightIcon />,
  forumIcon: <ForumIcon />,
  gamingIcon: <GamingIcon />,
  globeIcon: <GlobeIcon />,
  golfCourseIcon: <GolfCourseIcon />,
  graduateHatIcon: <GraduateHatOutlineIcon />,
  grassIcon: <GrassIcon />,
  growthIcon: <GrowthIcon />,
  humidityIcon: <HumidityIcon />,
  infoIcon: <InfoOutlineIcon />,
  internetIcon: <InternetIcon />,
  keyIcon: <KeyOutlineIcon />,
  mapIcon: <MapOutlineIcon />,
  meditationIcon: <MeditationIcon />,
  mergeIcon: <MergeIcon />,
  micIcon: <MicOutlineIcon />,
  moneyIcon: <MoneyOutlineIcon />,
  openDoorIcon: <OpenDoorOutlineIcon />,
  openLockIcon: <OpenLockIcon />,
  paletteIcon: <PaletteOutlineIcon />,
  plantIcon: <PlantOutlineIcon />,
  portraitIcon: <PortraitOutlineIcon />,
  realEstateIcon: <RealEstateIcon />,
  robotFaceIcon: <RobotFaceIcon />,
  rocketIcon: <RocketOutlineIcon />,
  runIcon: <RunIcon />,
  savingsIcon: <SavingsOutlineIcon />,
  scienceIcon: <ScienceIcon />,
  searchIcon: <SearchOutlineIcon />,
  sendIcon: <SendOutlineIcon />,
  sparklesIcon: <SparklesIcon />,
  sprintIcon: <SprintIcon />,
  starIcon: <StarOutlineIcon />,
  stopGestureIcon: <StopGestureIcon />,
  syringeIcon: <SyringeOutlineIcon />,
  tagOutlineIcon: <TagOutlineIcon />,
  shoppingCartIcon: <ShoppingCartOutlineIcon />,
  smartphoneIcon: <SmartphoneIcon />,
  snowyWeatherIcon: <SnowyWeatherIcon />,
  timelineIcon: <TimelineIcon />,
  toolsIcon: <ToolsIcon />,
  treeIcon: <TreeOutlineIcon />,
  truckIcon: <TruckOutlineIcon />,
  verifiedBadgeIcon: <VerifiedBadgeOutlineIcon />,
  videoCamIcon: <VideoCamIcon />,
  warningIcon: <WarningOutlineIcon />,
};

export type InitiativeIcon = keyof typeof Icons;
