import { Drawer, Form, Skeleton } from 'antd';
import { DrawerTitle } from '../../../../components/DrawerTitle';
import { showNotification } from '../../../../services/fetchNotificationProperties';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { EditSubInitiativeForm } from './editSubInitiativesDrawer/EditSubInitiativeForm';
import { useInitiativeLazy } from '../../../../hooks/useInitiative';
import { useEditInitiative } from './hooks/useEditInitiative';
import { howweErrorParser } from '../../../../services/howweErrorParser';
import { Btn } from '../../../../components/Button';
import { useTenantInitiatives } from '../../../../hooks/useTenantInitiatives';

interface Props {
  visible: boolean;
  onClose: () => void;
  onCompleted?: () => void;
  initiativeDomainId: { teamId: string; itemId: string };
}

export const EditSubInitiativesDrawer = ({
  visible,
  onClose,
  onCompleted,
  initiativeDomainId,
}: Props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [getInitiative, { data, loading }] = useInitiativeLazy();

  const initiatives = useTenantInitiatives();
  const allInitiatives =
    initiatives.data?.tenantInitiatives.allInitiatives ?? [];

  const selectableInitiatives = allInitiatives?.filter(
    (ti) =>
      (ti.archived === false && ti.completed.value === false) ||
      data?.initiative.supportsInitiatives?.some((si) => si.id === ti.id)
  );

  useEffect(() => {
    if (visible && initiativeDomainId) {
      getInitiative({
        variables: {
          teamId: initiativeDomainId.teamId,
          initiativeId: initiativeDomainId.itemId,
        },
      });
    }
  }, [initiativeDomainId, visible, getInitiative]);

  const { editInitiative, loading: savePending } = useEditInitiative();

  let howweErrors;

  const handleSubmit = () => {
    if (data) {
      form.validateFields().then((values) => {
        const { description, name, tagTitle, supportsInitiative } = values;
        const parentTag = allInitiatives.find(
          (i) => i.id === supportsInitiative
        )?.tag;
        if (!parentTag) {
          throw new Error(
            'Could not find parent initiative with id ' + supportsInitiative
          );
        }
        const migsIds = values.migs as string[];
        const objectives: string[] = values.objectives ?? [];

        editInitiative({
          variables: {
            teamId: initiativeDomainId.teamId,
            initiativeId: initiativeDomainId.itemId,
            initiative: {
              description: description,
              supportsInitiatives: [{ id: supportsInitiative }],
              supportsMigs: migsIds
                .map((migId) => ({ id: migId }))
                .filter((mig) => mig.id !== ''),
              objectives: objectives
                .filter((o) => !!o)
                .map((objective: string) => ({
                  text: objective,
                })),
              name: name,
              tag: {
                colorCode: parentTag.colorCode,
                iconId: parentTag.iconId,
                title: tagTitle,
              },
              rev: data?.initiative.rev ?? '',
            },
          },
          onCompleted: (data) => {
            showNotification('success', {
              message: (
                <strong>
                  {t('common.initiative.editSuccess', {
                    name: data.editInitiative.name,
                  })}
                </strong>
              ),
            });
            onCompleted?.();
          },
          onError: (e) => {
            howweErrors = howweErrorParser(e);

            showNotification('error', {
              message: (
                <strong>
                  <ul>
                    {howweErrors?.map((e, i) => (
                      <li key={i}>{e.translation}</li>
                    ))}
                  </ul>
                </strong>
              ),
            });
          },
        });
      });
    }
  };

  return (
    <div>
      <Drawer
        placement="right"
        closable={true}
        width={400}
        open={visible}
        onClose={onClose}
        title={
          <DrawerTitle>
            {t('EditSubInitiativeDrawer.editSubInitiative')}
          </DrawerTitle>
        }
      >
        {data?.initiative ? (
          <EditSubInitiativeForm
            form={form}
            initialValue={data?.initiative}
            initiativesLoading={initiatives.loading}
            initiatives={selectableInitiatives}
          />
        ) : (
          <Skeleton />
        )}

        <Btn
          type="primary"
          htmlType="submit"
          onClick={handleSubmit}
          loading={loading || savePending}
        >
          {t('common.save')}
        </Btn>
      </Drawer>
    </div>
  );
};
