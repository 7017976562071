import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SmallCollapseOpenedIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 5"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 -4.37114e-07L10 5L0 5L5 -4.37114e-07Z" />
  </svg>
);

export const SmallCollapseOpenedIcon = (
  props: Omit<IconComponentProps, 'ref'>
) => <Icon component={SmallCollapseOpenedIconSvg} {...props} />;
