import { isDefined } from '../isDefined';

export const capitalizeFirstLetter = (translation: string) => {
  const trimmedTranslation = translation.trim();
  return (
    trimmedTranslation.charAt(0).toUpperCase() + trimmedTranslation.slice(1)
  );
};

export const getErrorArray = (extensions: any) => {
  return extensions.response.body.errors;
};

export const getPointerKey = (pointer: string) => {
  const dynamicKeyPart = pointer.replace(/\//g, '.').replace(/\[[0-9]+\]/g, '');
  return `pointer${dynamicKeyPart}`;
};

export const atLeastOne = (array: any[] | null | undefined): array is any[] => {
  if (!isDefined(array)) return false;
  return array.length > 0;
};
