import { gql, useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { GetSprintsDocument } from '../generated/graphql';
import { showNotification } from '../services/fetchNotificationProperties';
import { ErrorAlert } from '../components/ErrorAlert';
import { useQueryParams } from '../appPages/team/common/hooks/useQuery';

export const useTeamSprints = (teamId: string) => {
  const [selectedSprintIndex, setSelectedSprintIndex] = useState(0);
  const { queryParams, setParam } = useQueryParams();

  const {
    data: sprintData,
    error: errorFetchingSprintData,
    loading: loadingSprintData,
  } = useQuery(GetSprintsDocument, {
    variables: { teamId },
    fetchPolicy: 'no-cache',
    onError(error) {
      showNotification('error', {
        message: <ErrorAlert error={error} />,
      });
    },
  });

  const preselectedSprint = queryParams.get('sprint');

  const preselectedSprintIndex =
    preselectedSprint != null
      ? parseInt(preselectedSprint, 10)
      : sprintData?.sprints?.currentSprintIndex;

  useEffect(() => {
    if (sprintData) {
      setSelectedSprintIndex(preselectedSprintIndex ?? 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sprintData]); // preselectedSprintIndex is intentionally not included in the dependency array to prevent this from running on every sprint change (it's only used to set the initial value after the available sprints are loaded)

  const currentSprintIndex = sprintData?.sprints?.currentSprintIndex ?? 0;

  const sprints = useMemo(() => {
    return (sprintData?.sprints?.payload ?? []).map((sprint, index) => ({
      ...sprint,
      isPreviousSprint: index < currentSprintIndex,
    }));
  }, [sprintData?.sprints?.payload, currentSprintIndex]);

  return {
    sprints,
    errorFetchingSprintData,
    loadingSprintData,
    selectedSprintIndex,
    setSelectedSprintIndex: (val: number) => {
      setParam('sprint', val.toString());
      setSelectedSprintIndex(val);
    },
    currentSprintIndex,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MITEM_SPRINT_FRAGMENT = gql`
  fragment BaseMitemSprint on MitemSprint {
    id
    startDate
    finalized
    teamId
    endDate
    locked
    sprintNumber
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_SPRINTS = gql`
  query getSprints($teamId: ID!) {
    sprints(teamId: $teamId) {
      currentSprintIndex
      payload {
        ...BaseMitemSprint
      }
    }
  }
  ${MITEM_SPRINT_FRAGMENT}
`;
