import './TeamGantishLegendRow.less';
import { TeamGantishMilestoneStats } from './TeamGantishMilestoneStats';
import { TeamGantishLegendRowSkeleton } from './TeamGantishLegendRow.skeleton';
import { gql } from '@apollo/client';
import { TeamGantishLegendRow__MilestoneFragment } from '../../../../../../../generated/graphql';
import React from 'react';

interface Props {
  label: React.ReactNode;
  milestones: TeamGantishLegendRow__MilestoneFragment[];
}

export const TeamGantishLegendRow = ({ label, milestones }: Props) => {
  return (
    <div className="TeamGantishLegendRow gantishShared-AppendAnimation">
      <div className="TeamGantishLegendRow__row">
        <div>{label}</div>
        <TeamGantishMilestoneStats milestones={milestones} />
      </div>
    </div>
  );
};

TeamGantishLegendRow.Skeleton = TeamGantishLegendRowSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_GANTISH_LEGEND_ROW__MILESTONES = gql`
  fragment TeamGantishLegendRow__Milestone on Milestone {
    id
    ...TeamGantishMilestoneStats__Milestone
  }
`;
