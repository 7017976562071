import { Dayjs } from 'dayjs';
import { AccelerationDay } from '../generated/graphql';

/* 
  We start with sunday since that is what moment.day() does,
  this way our acceleration day logic can be locale agnostic
*/

export const accelerationDayToDisplay = {
  [AccelerationDay.MONDAY]: 'Monday',
  [AccelerationDay.TUESDAY]: 'Tuesday',
  [AccelerationDay.WEDNESDAY]: 'Wednesday',
  [AccelerationDay.THURSDAY]: 'Thursday',
  [AccelerationDay.FRIDAY]: 'Friday',
  [AccelerationDay.SATURDAY]: 'Saturday',
  [AccelerationDay.SUNDAY]: 'Sunday',
};

export const WEEKDAY = [
  AccelerationDay.SUNDAY,
  AccelerationDay.MONDAY,
  AccelerationDay.TUESDAY,
  AccelerationDay.WEDNESDAY,
  AccelerationDay.THURSDAY,
  AccelerationDay.FRIDAY,
  AccelerationDay.SATURDAY,
];

export const getEndOfPeriodDay = (accelerationDay: AccelerationDay) => {
  if (accelerationDay == null) {
    throw new Error('accelerationDay missing');
  }
  const dayIndex = getEndOfPeriodDayIndex(accelerationDay);

  return WEEKDAY[dayIndex];
};

export const getEndOfPeriodDayIndex = (accelerationDay: AccelerationDay) => {
  if (accelerationDay == null) {
    throw new Error('accelerationDay missing');
  }
  let dayIndex = WEEKDAY.indexOf(accelerationDay);

  dayIndex = dayIndex === 0 ? 6 : dayIndex - 1;
  return dayIndex;
};

export const isAccDay = (date: Dayjs, accelerationDay: AccelerationDay) => {
  if (accelerationDay == null) {
    throw new Error('accelerationDay missing');
  }
  if (date == null) {
    throw new Error('date missing');
  }

  const weekday = WEEKDAY[date.day()];
  return weekday === accelerationDay;
};

export const isEndOfPeriod = (
  current: Dayjs,
  accelerationDay: AccelerationDay
) => {
  if (accelerationDay == null) {
    throw new Error('accelerationDay missing');
  }
  const endOfPeriodDay = getEndOfPeriodDay(accelerationDay);
  const isEnd = endOfPeriodDay === WEEKDAY[current.day()];
  return isEnd;
};

export const isInCurrentPeriod = (
  date: Dayjs,
  currentPeriod: {
    timePeriodStartDate: string;
    timePeriodEndDate: string;
  }
) =>
  date.isAfter(currentPeriod.timePeriodStartDate, 'day') &&
  date.isBefore(currentPeriod.timePeriodEndDate, 'day');
