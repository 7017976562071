import Icon from '@ant-design/icons';

const SyringeOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.599837 6.44997C0.477615 6.31663 0.416504 6.1583 0.416504 5.97497C0.416504 5.79163 0.477615 5.6333 0.599837 5.49997L2.4665 3.6333L1.74984 2.91663L1.54984 3.11663C1.4165 3.24997 1.25817 3.31663 1.07484 3.31663C0.891504 3.31663 0.733171 3.24997 0.599837 3.11663C0.477615 2.99441 0.416504 2.83886 0.416504 2.64997C0.416504 2.46108 0.477615 2.30552 0.599837 2.1833L1.93317 0.849967C2.0665 0.716634 2.22484 0.649967 2.40817 0.649967C2.5915 0.649967 2.74984 0.716634 2.88317 0.849967C3.00539 0.97219 3.0665 1.12775 3.0665 1.31663C3.0665 1.50552 3.00539 1.66108 2.88317 1.7833L2.68317 1.9833L3.39984 2.69997L5.2665 0.833301C5.39984 0.699967 5.55817 0.633301 5.7415 0.633301C5.92484 0.633301 6.08317 0.699967 6.2165 0.833301C6.34984 0.966634 6.4165 1.12497 6.4165 1.3083C6.4165 1.49163 6.34984 1.64997 6.2165 1.7833L5.7665 2.21663L10.6832 7.1333C10.9387 7.38886 11.0665 7.70275 11.0665 8.07497C11.0665 8.44719 10.9387 8.76108 10.6832 9.01663L10.2165 9.49997L13.3665 12.6333H11.4665L9.26651 10.4333L8.79984 10.9166C8.54428 11.1722 8.23039 11.3 7.85817 11.3C7.48595 11.3 7.17206 11.1722 6.9165 10.9166L1.99984 5.99997L1.54984 6.44997C1.4165 6.57219 1.25817 6.6333 1.07484 6.6333C0.891504 6.6333 0.733171 6.57219 0.599837 6.44997ZM2.93317 5.06663L7.84984 9.9833L9.73317 8.0833L8.73317 7.06663L7.79984 7.99997C7.6665 8.12219 7.50817 8.18608 7.32484 8.19163C7.1415 8.19719 6.98873 8.13886 6.8665 8.01663C6.73317 7.8833 6.6665 7.72497 6.6665 7.54163C6.6665 7.3583 6.73317 7.19997 6.8665 7.06663L7.79984 6.1333L6.79984 5.1333L5.8665 6.06663C5.73317 6.18886 5.57484 6.24997 5.3915 6.24997C5.20817 6.24997 5.04984 6.18886 4.9165 6.06663C4.79428 5.9333 4.73317 5.77497 4.73317 5.59163C4.73317 5.4083 4.79428 5.24997 4.9165 5.11663L5.84984 4.1833L4.83317 3.16663L2.93317 5.06663Z" />
  </svg>
);

export const SyringeOutlineIcon = (props: any) => (
  <Icon component={SyringeOutlineIconSvg} {...props} />
);
