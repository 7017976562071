import { ReactNode } from 'react';
import './SelectionIndicator.less';
import cx from 'classnames';

interface Props {
  active: boolean;
  children: ReactNode;
  className?: string;
}

export const SelectionIndicator = ({ active, children, className }: Props) => {
  return (
    <div
      className={cx(
        { 'selectionIndicator--active': active },
        'selectionIndicator',
        className
      )}
    >
      {children}
    </div>
  );
};
