import { Modal, Spin } from 'antd';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../../../ErrorPage';
import { RealignInitiativeModalContent } from './RealignInitiativeModalContent';

interface Props {
  visible: boolean;
  initiativeId: string;
  onClose?: () => void;
  onSetAsTopInitiative: () => void;
}

export const RealignInitiativeModal = ({
  initiativeId,
  visible,
  onClose,
  onSetAsTopInitiative,
}: Props) => {
  return (
    <Modal
      open={visible}
      footer={null}
      style={{ top: 20 }}
      onCancel={onClose}
      destroyOnClose
    >
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Suspense fallback={<Spin />}>
          <RealignInitiativeModalContent
            onClose={onClose}
            initiativeId={initiativeId}
            onSetAsTopInitiative={onSetAsTopInitiative}
          />
        </Suspense>
      </ErrorBoundary>
    </Modal>
  );
};
