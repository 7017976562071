import { useState } from 'react';
import './ToogleContent.less';
import cx from 'classnames';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

interface Props {
  hideContentTitle: string;
  showContentTitle: string;
  children?: React.ReactNode;
}

export const ToggleContent: React.FunctionComponent<Props> = ({
  children,
  showContentTitle,
  hideContentTitle,
}) => {
  const [toggle, setToggle] = useState(false);
  return (
    <div>
      <div className="center-content mt mb" onClick={() => setToggle(!toggle)}>
        <Typography.Text strong className="flx link clickable">
          {toggle ? (
            <UpOutlined className="space--r" />
          ) : (
            <DownOutlined className="space--r" />
          )}
          {toggle ? hideContentTitle : showContentTitle}
        </Typography.Text>
      </div>
      <div className={cx('ToggleContent', { open: toggle })}>
        <div className="ToggleContent__inner">{children}</div>
      </div>
    </div>
  );
};
