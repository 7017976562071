import { PlusCircleFilled } from '@ant-design/icons';
import {
  PureQueryOptions,
  RefetchQueriesFunction,
  gql,
  useMutation,
} from '@apollo/client';
import { Drawer, Form, Select, Skeleton, Tooltip } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerTitle } from '../../../../components/DrawerTitle';
import {
  Action,
  JoinInitiativeDocument,
  TeamResourceType,
} from '../../../../generated/graphql';
import { showNotification } from '../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../services/howweErrorParser';
import { useTenantInitiatives } from '../../../../hooks/useTenantInitiatives';
import { Btn } from '../../../../components/Button';
import { PermittedTeamSelectorForAction } from './initiativeDrawer/PermittedTeamSelectorForAction';

interface Props {
  tenantId?: string;
  initiativeFullId: string;
  refetchQueries?: Array<string | PureQueryOptions> | RefetchQueriesFunction;
  disabled?: boolean;
  alreadyJoinedTeams: string[];
}

export const JoinInitiativesDrawer = ({
  alreadyJoinedTeams,
  initiativeFullId,
  refetchQueries,
  disabled,
}: Props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [openJoinInitiativeDrawer, setOpenJoinInitiativeDrawer] =
    useState(false);
  const { data, loading: loadingInitiatives } = useTenantInitiatives();

  let howweErrors;

  const [joinInitiative, { loading }] = useMutation(JoinInitiativeDocument, {
    onCompleted: () => {
      showNotification('success', {
        message: <strong>{t('InitiativeNotifications.joinSuccess')}</strong>,
      });
      setOpenJoinInitiativeDrawer(false);
    },
    refetchQueries,
    onError: (e) => {
      howweErrors = howweErrorParser(e);

      showNotification('error', {
        message: (
          <strong>
            <ul>
              {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
            </ul>
          </strong>
        ),
      });
    },
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const { team, id } = values;
      joinInitiative({
        variables: {
          teamId: team,
          initiativeFullId: id,
        },
      });
    });
  };

  const initialValue = {
    id: initiativeFullId,
  };

  return (
    <div>
      <Tooltip
        title={
          disabled ? t('JoinInitiativesDrawer.completedOrArchivedInfo') : null
        }
      >
        <Btn
          icon={<PlusCircleFilled />}
          onClick={() => setOpenJoinInitiativeDrawer(true)}
          disabled={disabled}
        >
          {t('InitiativeDrawer.joinInitiative')}
        </Btn>
      </Tooltip>

      <Drawer
        placement="right"
        closable={true}
        width={400}
        open={openJoinInitiativeDrawer}
        destroyOnClose={true}
        onClose={() => {
          setOpenJoinInitiativeDrawer(false);
        }}
        title={
          <DrawerTitle>{t('InitiativeDrawer.joinInitiative')}</DrawerTitle>
        }
      >
        {loadingInitiatives ? (
          <Skeleton />
        ) : (
          <>
            <Form
              layout="vertical"
              form={form}
              requiredMark={false}
              initialValues={initialValue}
            >
              <Form.Item name="id" label={t('InitiativeDrawer.joinInitiative')}>
                <Select
                  loading={loadingInitiatives}
                  options={data?.tenantInitiatives.allInitiatives.map((i) => ({
                    value: i.id,
                    label: i.name,
                  }))}
                />
              </Form.Item>
              <h4 className="InitiativeForm__subHeaders">
                {t('InitiativeForm.alignment')}
              </h4>
              <h5 className="InitiativeForm__subHeaders__description mb--l">
                {t('InitiativeForm.selectTeamDescription')}
              </h5>
              <Form.Item
                name="team"
                label="Team"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <PermittedTeamSelectorForAction
                  alreadySelectedTeams={alreadyJoinedTeams}
                  teamResourceType={TeamResourceType.TEAM_INITIATIVE}
                  action={Action.CREATE}
                />
              </Form.Item>
            </Form>
            <Btn
              type="primary"
              htmlType="submit"
              onClick={handleSubmit}
              loading={loading}
            >
              {t('InitiativeDrawer.join')}
            </Btn>
          </>
        )}
      </Drawer>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const JOIN_INITIATIVE = gql`
  mutation joinInitiative($tenantId: ID, $teamId: ID!, $initiativeFullId: ID!) {
    joinInitiative(
      tenantId: $tenantId
      teamId: $teamId
      initiativeFullId: $initiativeFullId
    ) {
      id
    }
  }
`;
