import './TeamOverviewPageDivider.less';

interface Props {
  title: string;
}

export const TeamOverviewPageDivider = ({ title }: Props) => {
  return (
    <div className="TeamPageDivider flx flx--row flx--ai-center mb--l">
      <h5 className="txt--secondary">{title}</h5>
      <div className="TeamPageDivider__line ml--l" />
    </div>
  );
};
