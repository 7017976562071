import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContextualNavbarContent } from '../../../components/Navigation/ContextualNavbarContent/ContextualNavbarContent';
import { useSprintTerms } from '../../../hooks/useSprintTerms';
import { useTeamPermissions } from '../../../usePermissions';
import { Action } from '../../../generated/graphql';

interface Props {
  id: string;
  name: string;
  parent: {
    id: string;
    name: string;
  } | null;
  leaders: {
    id: string;
    displayName: string | null;
    name: string | null;
    email: string;
  }[];
}

export const TeamPageTitleMenu = (team: Props) => {
  const { t } = useTranslation();
  const match = useRouteMatch();

  // Needed to remove the sprint key activity and team tag check due to that otherwise the sprint admin would get access to setup
  const { isAllowed: isAllowedToViewSetup } = useTeamPermissions({
    requestedAction: [
      {
        resource: 'mig',
        action: [Action.CREATE, Action.UPDATE],
      },
      {
        resource: 'weeklyKA',
        action: [Action.CREATE, Action.UPDATE],
      },
    ],
    teamId: team.id,
  });
  const { isAllowed: isAllowedToViewSprint } = useTeamPermissions({
    requestedAction: [
      {
        resource: 'sprintKA',
        action: [Action.READ],
      },
    ],
    teamId: team.id,
  });
  const { sprintTermsEnabled } = useSprintTerms(team.id);
  const canViewSprints = isAllowedToViewSprint && sprintTermsEnabled;

  const teamMenu = [
    {
      url: match.url + '/overview',
      title: t('TeamPageTitleMenu.overview'),
      intercomDataTarget: 'Team Overview Navigation',
    },
    {
      url: match.url + '/sprint/summary',
      title: t('TeamPageTitleMenu.sprintView'),
      exclude: !canViewSprints,
      intercomDataTarget: 'Team Sprint Navigation',
    },
    {
      url: match.url + '/sprint/planning',
      title: t('TeamPageTitleMenu.sprintPlanning'),
      exclude: !canViewSprints,
      intercomDataTarget: 'Team Sprint Planning Navigation',
    },
    {
      url: match.url + '/members',
      title: t('TeamPageTitleMenu.members'),
      intercomDataTarget: 'Team Member Navigation',
    },
    {
      url: match.url + '/setup',
      title: t('TeamPageTitleMenu.setup'),
      exclude: !isAllowedToViewSetup,
      intercomDataTarget: 'Team Setup Navigation',
    },
  ];

  return (
    <ContextualNavbarContent
      title={team?.name}
      pageTitle={team?.name}
      menuItems={teamMenu.filter((t) => t.exclude !== true)}
    />
  );
};
