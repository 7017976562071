import { gql, useMutation } from '@apollo/client';
import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { DisplayName } from '../../../components/DisplayName';
import { ErrorAlert } from '../../../components/ErrorAlert';
import {
  GetTeamMembersV2HookDocument,
  UnassignFromTeamDocument,
} from '../../../generated/graphql';
import { TeamMember } from '../../../hooks/useTeamMembersV2';
import { showNotification } from '../../../services/fetchNotificationProperties';
import { Btn } from '../../../components/Button';

interface Props {
  member: TeamMember;
  tenantId?: string;
}

export const UnassignUserFromTeamModalWithConfirmButton = ({
  member,
  tenantId,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [unassignUserFromTeam, unassignRequest] = useMutation(
    UnassignFromTeamDocument,
    {
      variables: { teamId: member.teamId, userId: member.user.id, tenantId },
      refetchQueries: () => [
        {
          query: GetTeamMembersV2HookDocument,
          variables: { teamId: member.teamId, tenantId },
        },
      ],
    }
  );

  return (
    <Popconfirm
      title={
        <>
          <header>
            <b>{t('TeamSettingsPage.unassign.popTitle')}</b>
          </header>
          <br />
          <main>
            <p>{t('TeamSettingsPage.unassign.popText1')}</p>
            <p>
              <b>{<DisplayName user={member.user}></DisplayName>} </b>
            </p>
            <p>{t('TeamSettingsPage.unassign.popText2')}</p>
          </main>
          <br />
        </>
      }
      okText={t('TeamSettingsPage.unassign.removeButtonOkText')}
      onConfirm={() => {
        unassignUserFromTeam({
          variables: {
            teamId: member.teamId,
            userId: member.user.id,
            tenantId,
          },
        }).then(() => {
          showNotification('success', {
            message: (
              <span>
                {t('TeamSettingsPage.unassign.successText')}{' '}
                <strong>
                  <DisplayName user={member.user} />
                </strong>
                .
              </span>
            ),
          });
        });
      }}
    >
      <Btn type="text" onClick={() => null}>
        {t('TeamSettingsPage.unassign.removeButtonOkText')}
      </Btn>
      <ErrorAlert error={unassignRequest.error} />
    </Popconfirm>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UNASSIGN_FROM_TEAM = gql`
  mutation unassignFromTeam($teamId: ID!, $userId: ID!, $tenantId: ID) {
    removeFromTeam(teamId: $teamId, userId: $userId, tenantId: $tenantId) {
      userId
      role
    }
  }
`;
