import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCountdown } from '../../../../../../../../../hooks/useCountdown';
import { friendlyDate } from '../../../../../../../../../services/dateFormats';

import './SprintDetailsCard.less';
import { gql } from '@apollo/client';
import { SprintDetailsCard_MitemSprintFragment } from '../../../../../../../../../generated/graphql';
import dayjs from 'dayjs';

interface Props {
  sprint: SprintDetailsCard_MitemSprintFragment | null;
}

export const SprintDetailsCard = ({ sprint }: Props) => {
  const { t } = useTranslation();
  const { timeDiff, deadlineMissed } = useCountdown(sprint?.endDate);
  if (!sprint) return null;

  var weekDiff = dayjs(sprint.endDate)
    .add(1, 'day')
    .diff(sprint?.startDate, 'week');

  return (
    <div className="SprintDetailsCard flx flx--column flx--jc-space-between">
      <Typography.Text strong>{t('SprintDetailsCard.title')}</Typography.Text>
      <div className="flx mt--s">
        <div className="SprintDetailsCard__column mr--l">
          <div className="flx flx--jc-space-between">
            <Typography.Text type="secondary">
              {t('common.startDate')}
            </Typography.Text>
            <Typography.Text>{friendlyDate(sprint.startDate)}</Typography.Text>
          </div>
          <div className="flx flx--jc-space-between">
            <Typography.Text type="secondary">
              {t('common.endDate')}
            </Typography.Text>
            <Typography.Text>{friendlyDate(sprint.endDate)}</Typography.Text>
          </div>
        </div>
        <div className="SprintDetailsCard__column">
          <div className="flx flx--jc-space-between">
            <Typography.Text type="secondary">
              {t('SprintDetails.SprintDuration')}
            </Typography.Text>
            <Typography.Text>
              {t('SprintDetails.SprintDurationValue', {
                duration: weekDiff,
                count: weekDiff,
              })}
            </Typography.Text>
          </div>
          <div className="flx flx--jc-space-between">
            {deadlineMissed && (
              <>
                <Typography.Text type="secondary">
                  {t('SprintDetails.Overdue')}
                </Typography.Text>
                <Typography.Text>
                  {t('SprintDetails.TimeOverdue', { time: timeDiff })}
                </Typography.Text>
              </>
            )}
            {!deadlineMissed && (
              <>
                <Typography.Text type="secondary">
                  {t('SprintDetails.TimeLeft')}
                </Typography.Text>
                <Typography.Text>{`${timeDiff}`}</Typography.Text>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_SUMMARY = gql`
  fragment SprintDetailsCard_MitemSprint on MitemSprint {
    id
    startDate
    endDate
  }
`;
