import { gql, useMutation, useQuery } from '@apollo/client';
import { Btn } from '../../../Button';
import { SelectTenantUser } from './SelectTenantUser';
import { useState } from 'react';
import {
  AssignTenantRoleToUserDocument,
  GetTenantUsersDocument,
  GetUsersByRoleTypeDocument,
  SelectTenantUser_User2Fragment,
  TenantRoleType,
} from '../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { showNotification } from '../../../../services/fetchNotificationProperties';
import { UserAvatar } from '../../../UserAvatar';
import { friendlyUsername } from '../../../../services/friendlyUsername';
import { ErrorAlert } from '../../../ErrorAlert';
import { PersonShieldIcon } from '../../../../icons/PersonShield';

interface Props {
  alreadyAdmins: {
    id: string;
  }[];
}

export const AddInitiativeAdminSelector = ({ alreadyAdmins }: Props) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(GetTenantUsersDocument, {
    fetchPolicy: 'cache-and-network',
  });

  const [assignTenantRoleToUser, { loading: promoteLoading }] = useMutation(
    AssignTenantRoleToUserDocument
  );

  const [selectedUser, setSelectedUser] = useState<
    SelectTenantUser_User2Fragment | undefined
  >();

  const filteredUsers =
    data?.tenantUsers.users.filter(
      (user) => !alreadyAdmins.some((admin) => admin.id === user.id)
    ) ?? [];

  const handleSubmit = () => {
    if (!selectedUser) return;
    assignTenantRoleToUser({
      variables: {
        userId: selectedUser.domainId.itemId,
        tenantRoleType: TenantRoleType.STRATEGIC_PLAN_ADMIN,
      },
      onCompleted: () => {
        setSelectedUser(undefined);
        showNotification('success', {
          message: (
            <div>
              <div className="mb">
                <strong>{t('AddInitiativeAdminSelector.success')}</strong>
              </div>
              <div>
                <UserAvatar user={selectedUser} size="small" />
                <span className="ml">{friendlyUsername(selectedUser)}</span>
              </div>
            </div>
          ),
        });
      },
      onError: (e) => {
        showNotification('error', {
          message: (
            <div>
              <div className="mb">
                <strong>{t('AddInitiativeAdminSelector.failed')}</strong>
              </div>
              <div>
                <ErrorAlert error={e} />
              </div>
            </div>
          ),
        });
      },
      refetchQueries: () => [
        {
          query: GetUsersByRoleTypeDocument,
          variables: { tenantRoleType: TenantRoleType.STRATEGIC_PLAN_ADMIN },
        },
      ],
    });
  };

  return (
    <div className="flx">
      <SelectTenantUser
        className="flx--1 mr--xl"
        value={selectedUser}
        onSelect={setSelectedUser}
        users={filteredUsers}
        loading={loading || promoteLoading}
      />
      <Btn
        type="primary"
        disabled={!selectedUser || promoteLoading}
        icon={<PersonShieldIcon />}
        onClick={handleSubmit}
      >
        {t('AddInitiativeAdminSelector.promoteToAdmin')}
      </Btn>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TENANT_USERS = gql`
  query getTenantUsers($tenantId: ID) {
    tenantUsers(tenantId: $tenantId) {
      users {
        id
        ...SelectTenantUser_User2
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ASSIGN_STRATEGIC_PLAN_ADMIN = gql`
  mutation assignTenantRoleToUser(
    $userId: ID!
    $tenantRoleType: TenantRoleType!
  ) {
    assignTenantRoleToUser(userId: $userId, roleType: $tenantRoleType) {
      id
    }
  }
`;
