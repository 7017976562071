import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';
import { InitiativeSelect } from '../../../../../../../../../components/initiatives/StrategicPlanAdminModal/components/manageCompanyInitiatives/InitiativeSelect';
import { Btn } from '../../../../../../../../../components/Button';
import {
  DomainId,
  RealignMilestoneModal_ContentQueryDocument,
  RealignMilestoneModalContent_MutationDocument,
  SupportedEntityType,
} from '../../../../../../../../../generated/graphql';
interface Props {
  milestoneId: string;
  onClose?: () => void;
}

export const RealignMilestoneModalContent = ({
  milestoneId,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const { data } = useSuspenseQuery(
    RealignMilestoneModal_ContentQueryDocument,
    { variables: { milestoneId }, fetchPolicy: 'no-cache' }
  );

  const { milestone, tenantInitiatives2 } = data;

  const allInitiatives = tenantInitiatives2.initiatives;
  //.filter((i) => i.domainId.itemId !== initiativeId);

  const currentParentInitiative = milestone.metadata.supportsInitiatives[0];

  const [selectedParentInitiative, setselectedParentInitiative] = useState<
    DomainId | undefined
  >(currentParentInitiative?.domainId);

  const [updateTenantInitiative, { loading }] = useMutation(
    RealignMilestoneModalContent_MutationDocument
  );

  const handleSave = () => {
    if (!selectedParentInitiative) {
      throw new Error('Parent initiative not found');
    }
    updateTenantInitiative({
      variables: {
        milestoneId: milestoneId,
        milestone: {
          rev: milestone.rev,
          supports: [
            {
              type: SupportedEntityType.initiative,
              item: {
                domainId: {
                  tenantId: selectedParentInitiative.tenantId,
                  itemId: selectedParentInitiative.itemId,
                },
              },
            },
          ],
        },
      },
      onCompleted: () => {
        onClose?.();
      },
    });
  };

  return (
    <div>
      <h5 className="txt--secondary bold mb--l">{milestone?.name}</h5>
      <InitiativeSelect
        style={{ width: '100%' }}
        initiatives={allInitiatives.filter(
          (i) => i.metadata.archived === false
        )}
        value={selectedParentInitiative?.itemId}
        onChange={(init) => setselectedParentInitiative(init.domainId)}
      />

      <div className="flx borderTop mt pt--xl">
        <Btn
          type="primary"
          className="ml--auto"
          onClick={handleSave}
          loading={loading}
        >
          {t('common.save')}
        </Btn>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REALIGN_MILESTONE_QUERY = gql`
  query RealignMilestoneModal_ContentQuery($tenantId: ID, $milestoneId: ID!) {
    milestone(tenantId: $tenantId, milestoneId: $milestoneId) {
      id
      domainId {
        itemId
        tenantId
      }
      rev
      name
      metadata {
        supportsInitiatives {
          domainId {
            tenantId
            itemId
          }
        }
      }
    }
    tenantInitiatives2 {
      initiatives {
        domainId {
          itemId
          tenantId
        }
        name
        id
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          completedAt
          archived
          status
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REALIGN_MILESTONE_MUTATION = gql`
  mutation RealignMilestoneModalContent_Mutation(
    $milestoneId: ID!
    $milestone: MilestoneUpdateInput!
  ) {
    updateMilestone(milestoneId: $milestoneId, milestone: $milestone) {
      id
    }
  }
`;
