import { EditOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { MenuProps, Dropdown } from 'antd';
import { Btn } from '../../Button';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '../../../icons/overviewIcons/CheckIcon';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { GroupsIcon } from '../../../icons/GroupsIcon';
import { AccountTreeOutlineIcon } from '../../../icons/initiativeIcons/AccountTreeOutlineIcon';
import { ArchiveIcon } from '../../../icons/initiativeIcons/ArchiveIcon';
import { EditInitiativeModal } from './components/editInitiativeModal/EditInitiativeModal';
import { CreateSubInitiativeModal } from './components/createSubInitiativeModal/CreateSubInitiativeModal';
import { MarkInitiativeAsCompletedModal } from './components/markInitiativeAsCompletedModal/MarkInitiativeAsCompletedModal';
import { MarkInitiativeAsArchivedModal } from './components/markInitiativeAsArchivedModal/MarkInitiativeAsArchivedModal';
import { ManageInitiativeTeamsModal } from './components/manageInitiativeTeamsModal/ManageInitiativeTeamsModal';
import { RealignInitiativeModal } from './components/realignInitiativeModal/RealignInitiativeModal';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { InitiativeActionsDropdown_QueryDocument } from '../../../generated/graphql';
import { ReactivateInitiativeModal } from './components/reactivateInitiativeModal/ReactivateInitiativeModal';
import { PublishedWithChangesIcon } from '../../../icons/PublishedWithChangesIcon';

export const InitiativeActionsDropdown = () => {
  const { t } = useTranslation();
  const { initiativeId } = useParams<{ initiativeId: string }>();

  const { data, loading } = useQuery(InitiativeActionsDropdown_QueryDocument, {
    variables: { initiativeId: initiativeId },
  });

  const completed = data?.tenantInitiative2.fullMetadata.completedAt != null;
  const archived = data?.tenantInitiative2.fullMetadata.archived;

  const archivedOrLoading = archived || loading;

  const [showModal, setShowModal] = useState<
    | 'createSubModal'
    | 'editModal'
    | 'archiveModal'
    | 'markAsCompleted'
    | 'reactivate'
    | 'markAsArchived'
    | 'manageTeams'
    | 'realign'
    | 'realignToTop'
    | null
  >(null);

  const initiativeActions: MenuProps['items'] = [
    {
      key: 'Edit',
      label: t('InitiativeActionsDropdown.edit'),
      icon: <EditOutlined className="font-size--lg" />,
      disabled: archivedOrLoading,
      onClick: () => {
        setShowModal('editModal');
      },
    },
    {
      key: 'Create subinitiative',
      label: t('InitiativeActionsDropdown.createSubinitiative'),
      icon: <PlusOutlined className="font-size--lg" />,
      disabled: archivedOrLoading,
      onClick: () => {
        setShowModal('createSubModal');
      },
    },
    {
      key: 'Manage teams',
      label: <span>{t('InitiativeActionsDropdown.manageTeams')}</span>,
      icon: <GroupsIcon className="font-size--lg" />,
      disabled: archivedOrLoading,
      onClick: () => {
        setShowModal('manageTeams');
      },
    },
    {
      key: 'Realign',
      label: <span>{t('InitiativeActionsDropdown.realign')}</span>,
      icon: <AccountTreeOutlineIcon className="font-size--lg" />,
      disabled: archivedOrLoading,
      onClick: () => {
        setShowModal('realign');
      },
    },
    completed
      ? {
          key: 'Reactivate',
          disabled: archivedOrLoading,
          label: <span>{t('InitiativeActionsDropdown.reactivate')}</span>,
          icon: <PublishedWithChangesIcon className="font-size--lg" />,
          onClick: () => {
            setShowModal('reactivate');
          },
        }
      : {
          key: 'Mark as Completed',
          disabled: archivedOrLoading,
          label: <span>{t('InitiativeActionsDropdown.markAsCompleted')}</span>,
          icon: <CheckIcon className="font-size--lg" />,
          onClick: () => {
            setShowModal('markAsCompleted');
          },
        },
    {
      key: 'Archive',
      label: <span>{t('common.archive')}</span>,
      icon: <ArchiveIcon className="font-size--lg" />,
      disabled: archivedOrLoading,
      onClick: () => {
        setShowModal('markAsArchived');
      },
    },
  ];

  return (
    <>
      <Dropdown menu={{ items: initiativeActions }}>
        <Btn icon={<MoreOutlined />} />
      </Dropdown>

      <EditInitiativeModal
        initiativeId={initiativeId}
        visible={showModal === 'editModal' || showModal === 'realignToTop'}
        isTopRealign={showModal === 'realignToTop'}
        onClose={() => setShowModal(null)}
      />

      <CreateSubInitiativeModal
        initiativeId={initiativeId}
        visible={showModal === 'createSubModal'}
        onClose={() => setShowModal(null)}
      />

      <MarkInitiativeAsCompletedModal
        initiativeId={initiativeId}
        visible={showModal === 'markAsCompleted'}
        onClose={() => setShowModal(null)}
      />

      <ReactivateInitiativeModal
        initiativeId={initiativeId}
        visible={showModal === 'reactivate'}
        onClose={() => setShowModal(null)}
      />

      <MarkInitiativeAsArchivedModal
        initiativeId={initiativeId}
        visible={showModal === 'markAsArchived'}
        onClose={() => setShowModal(null)}
      />

      <ManageInitiativeTeamsModal
        initiativeId={initiativeId}
        visible={showModal === 'manageTeams'}
        onClose={() => setShowModal(null)}
        title={<h3>{t('InitiativeActionsDropdown.manageTeams')}</h3>}
      />

      <RealignInitiativeModal
        initiativeId={initiativeId}
        visible={showModal === 'realign'}
        onClose={() => setShowModal(null)}
        onSetAsTopInitiative={() => setShowModal('realignToTop')}
      />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVE = gql`
  query initiativeActionsDropdown_query($tenantId: ID, $initiativeId: ID!) {
    tenantInitiative2(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      name
      rev
      fullMetadata {
        completedAt
        archived
        supportsMigs {
          id
          name
        }
      }
    }
  }
`;
