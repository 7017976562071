import Icon from '@ant-design/icons';

const GolfCourseIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.9998 13C10.7221 13 10.4861 12.9027 10.2918 12.708C10.0972 12.5138 9.99984 12.2778 9.99984 12C9.99984 11.7223 10.0972 11.4863 10.2918 11.292C10.4861 11.0974 10.7221 11 10.9998 11C11.2776 11 11.5136 11.0974 11.7078 11.292C11.9025 11.4863 11.9998 11.7223 11.9998 12C11.9998 12.2778 11.9025 12.5138 11.7078 12.708C11.5136 12.9027 11.2776 13 10.9998 13ZM4.6665 13.6667C3.55539 13.6667 2.61095 13.5363 1.83317 13.2754C1.05539 13.014 0.666504 12.7 0.666504 12.3334C0.666504 12.0778 0.849837 11.85 1.2165 11.65C1.58317 11.45 2.0665 11.2889 2.6665 11.1667V12.3334H3.99984V0.333374L9.33317 2.93337L5.33317 5.00004V11.0334C6.28873 11.0889 7.08317 11.236 7.7165 11.4747C8.34984 11.7138 8.6665 12 8.6665 12.3334C8.6665 12.7 8.27761 13.014 7.49984 13.2754C6.72206 13.5363 5.77761 13.6667 4.6665 13.6667Z" />
  </svg>
);

export const GolfCourseIcon = (props: any) => (
  <Icon component={GolfCourseIconSvg} {...props} />
);
