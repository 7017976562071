import { InitiativeNode } from '../../../../company/initiatives_old/initiativesPageV1/services/initiativeTreeNodeHelper';
import './TeamInitiativesCard.less';
import { TeamInitiativesCardContent } from './teamInitiativesCard/TeamInitiativesCardContent';
import { TeamInitiativesCardFooter } from './teamInitiativesCard/TeamInitiativesCardFooter';
import { TeamInitiativesCardHeader } from './teamInitiativesCard/TeamInitiativesCardHeader';
import { TeamInitiativesCardTitle } from './teamInitiativesCard/TeamInitiativesCardTitle';

interface Props {
  hierarchicalInitiative: InitiativeNode;
  showActions?: boolean;
  teamId: string | undefined;
  openTab?: boolean;
}

export const TeamInitiativesCard = ({
  hierarchicalInitiative,
  showActions,
  teamId,
  openTab,
}: Props) => {
  const isSubInitiative = hierarchicalInitiative.supportsInitiatives.length > 0;

  return (
    <div className="TeamInitiativesCard__card pa--xl">
      <TeamInitiativesCardHeader
        showActions={showActions}
        hierarchicalInitiative={hierarchicalInitiative}
        teamId={teamId}
        isSubInitiative={isSubInitiative}
      />
      <TeamInitiativesCardTitle
        name={hierarchicalInitiative.name}
        completed={hierarchicalInitiative.completed.value}
      />
      <TeamInitiativesCardContent team={hierarchicalInitiative.team} />
      <TeamInitiativesCardFooter
        openTab={openTab}
        teamId={teamId}
        hierarchicalInitiative={hierarchicalInitiative}
      />
    </div>
  );
};
