import { gql, useMutation } from '@apollo/client';
import { Col, Form, Input, Row, Switch, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { TitleWithTooltip } from '../../../../../appPages/team/setup/components/TitleWithTooltip';
import {
  CreateMitemAchievement2Document,
  GetSkaTimeStatusesDocument,
  MitemStatus,
  ReportDrawer2Document,
  TimePeriodFilter,
} from '../../../../../generated/graphql';
import { TEAM_COMMITMENTS_AND_ACHIEVEMENTS } from '../../../../../hooks/useTeamCommitmentAndAchievementV2';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { Btn } from '../../../../Button';
import { DisplayDate } from '../../../../DisplayDate';
import { ErrorAlert } from '../../../../ErrorAlert';
import { SprintKA } from '../../reportDrawerUtils';

import './SprintReportForm.less';
import { useCurrentSprint } from '../../../../../hooks/useCurrentSprint';

interface Props {
  sprintKA: SprintKA;
  teamId: string;
  onReported: () => void;
  onCancel: () => void;
}

export const SprintReportForm = ({
  sprintKA,
  teamId,
  onReported,
  onCancel,
}: Props) => {
  const { t } = useTranslation();
  const currentSprint = useCurrentSprint(teamId);

  const [form] = Form.useForm();
  const reportAsComplete = Form.useWatch('completed', form);

  const [reportAchievement, { error, loading }] = useMutation(
    CreateMitemAchievement2Document,
    {
      onCompleted: () => {
        showNotification('success', {
          message: t('SprintReportForm.success'),
          duration: 15,
        });
        onReported();
        (window as any).Intercom?.('update', {
          last_reported_sprint_key_activity_at: new Date(),
          last_reported_key_activity_at: new Date(),
        });
      },
      refetchQueries: () => [
        {
          query: ReportDrawer2Document,
        },
        {
          query: TEAM_COMMITMENTS_AND_ACHIEVEMENTS,
          variables: {
            teamId: teamId,
            timePeriodFilter: TimePeriodFilter.CURRENT,
          },
        },
        {
          query: GetSkaTimeStatusesDocument,
          variables: {
            teamId: teamId,
            startDate: currentSprint.currentSprint?.startDate,
            endDate: currentSprint.currentSprint?.endDate,
          },
        },
      ],
    }
  );

  const isCompleted = sprintKA.mitemStatus === MitemStatus.COMPLETED;

  return (
    <div className="SprintReportForm">
      <Row className="mb--xl">
        <Col span={6} className="SprintReportForm__label">
          {t('common.deadline')}
        </Col>
        <Col span={18}>
          <DisplayDate date={sprintKA.deadline} alwaysIncludeYear />
        </Col>
      </Row>

      <span className="SprintReportForm__label">
        {t('common.definitionOfDone')}
      </span>
      <Typography.Paragraph
        ellipsis={{ rows: 2, expandable: true }}
        className="mb--xl preserveLinebreaks"
      >
        {sprintKA.definitionOfDone}
      </Typography.Paragraph>

      <span className="SprintReportForm__label">
        {t('SprintReportForm.commitmentNote')}
      </span>
      <Typography.Paragraph className="mb--xl preserveLinebreaks">
        {sprintKA.progress.note}
      </Typography.Paragraph>

      <Form
        form={form}
        layout="vertical"
        className="mt--xs"
        hideRequiredMark
        onFinish={(values) => {
          reportAchievement({
            variables: {
              teamId: teamId,
              mitemId: sprintKA.sprintKeyActivityId,
              mitemAchievement: {
                completed: values.completed ?? true,
                description: values.achievementDescription,
              },
            },
          });
        }}
      >
        <Form.Item
          name="achievementDescription"
          label={
            <span className="SprintReportForm__label">
              <TitleWithTooltip
                title={t('SprintReportForm.achievementDescription.label')}
                tooltipContent={t(
                  'SprintReportForm.achievementDescription.hint'
                )}
              />
            </span>
          }
          rules={[
            {
              required: true,
              max: ACHIEVEMENT_DESCRIPTION_MAX_LENGTH,
              message: t('SprintReportForm.achievementDescription.maxLength', {
                max: ACHIEVEMENT_DESCRIPTION_MAX_LENGTH,
              }),
            },
          ]}
        >
          <Input.TextArea
            disabled={isCompleted}
            autoComplete="off"
            rows={5}
            showCount
            maxLength={ACHIEVEMENT_DESCRIPTION_MAX_LENGTH}
          />
        </Form.Item>
        <Form.Item
          label={
            <span className="SprintReportForm__label">
              <TitleWithTooltip
                title={t('SprintReportForm.mitemStatus')}
                tooltipContent={t('SprintReportForm.mitemStatusTooltip')}
              />
            </span>
          }
        >
          <Form.Item
            name="completed"
            initialValue={false}
            valuePropName="checked"
            noStyle
          >
            <Switch size="small" disabled={isCompleted} />
          </Form.Item>
          <span className="ml">
            {t('SprintReportForm.mitemStatusCompleted')}
          </span>
        </Form.Item>

        <Btn
          type="callToAction"
          loading={loading}
          disabled={isCompleted}
          htmlType="submit"
          data-intercom-target="Report Sprint Key Activity Submit Button"
        >
          {reportAsComplete
            ? t('SprintReportForm.reportComplete')
            : t('SprintReportForm.reportProgress')}
        </Btn>
        <Btn
          onClick={onCancel}
          htmlType="reset"
          loading={loading}
          type="text"
          className="ml--xl"
        >
          {t('common.cancel')}
        </Btn>
        <ErrorAlert error={error} />
      </Form>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REPORT_MITEM_ACHIEVEMENT2 = gql`
  mutation createMitemAchievement2(
    $teamId: ID!
    $mitemId: ID!
    $mitemAchievement: CreateMitemAchievementInput!
  ) {
    createMitemAchievement(
      teamId: $teamId
      mitemId: $mitemId
      mitemAchievement: $mitemAchievement
    ) {
      id
    }
  }
`;

const ACHIEVEMENT_DESCRIPTION_MAX_LENGTH = 1000;
