import { Line, LineChart, ResponsiveContainer } from 'recharts';
import { Colors } from '../appPages/componentLibrary/Colors';
import { PreviewGraphLegends } from './previewGraph/PreviewGraphLegends';

interface Props {
  periodData?: {
    targetDate: string;
    label?: string;
    actual?: number | null;
    goal?: number | null;
    historic?: number | null;
  }[];
  goalLegend?: number | null;
  progressLegend?: number | null;
  showLegends?: boolean;
  width?: number;
  height?: number;
}

export const PreviewGraph = ({
  periodData,
  goalLegend,
  progressLegend,
  showLegends = true,
  height = 50,
}: Props) => {
  if (periodData == null || periodData.length === 0) {
    return null;
  }

  return (
    <div>
      <ResponsiveContainer debounce={300} height={height} width="100%">
        <LineChart height={height} data={periodData}>
          <Line
            dataKey="goal"
            stroke={Colors.Howwe.MAIN_ORANGE}
            strokeWidth={2}
            isAnimationActive={false}
            dot={false}
          />
          <Line
            dataKey="actual"
            stroke={Colors.Action.BLUE}
            strokeWidth={2}
            isAnimationActive={false}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
      {showLegends && (
        <PreviewGraphLegends goal={goalLegend} progress={progressLegend} />
      )}
    </div>
  );
};
