import { gql, useMutation } from '@apollo/client';
import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../Button';
import {
  RemoveTenantRoleFromUserDocument,
  TenantRoleType,
  GetUsersByRoleTypeDocument,
} from '../../../../generated/graphql';
import { showNotification } from '../../../../services/fetchNotificationProperties';
import { ShieldXIcon } from '../../../../icons/ShieldX';

interface Props {
  userId: string;
  onDemote: () => void;
}

export const DemoteAdminRoleButton = ({ userId, onDemote }: Props) => {
  const { t } = useTranslation();
  const [removeTenantRoleFromUser, { loading: demoteLoading }] = useMutation(
    RemoveTenantRoleFromUserDocument
  );

  const handleDemote = () => {
    removeTenantRoleFromUser({
      variables: {
        userId,
        tenantRoleType: TenantRoleType.STRATEGIC_PLAN_ADMIN,
      },
      refetchQueries: () => [
        {
          query: GetUsersByRoleTypeDocument,
          variables: { tenantRoleType: TenantRoleType.STRATEGIC_PLAN_ADMIN },
        },
      ],
      onError: () => {
        showNotification('error', {
          message: t('DemoteAdminRoleButton.error'),
        });
      },
      onCompleted: () => {
        onDemote();
      },
    });
  };

  return (
    <Popconfirm
      title={t('DemoteAdminRoleButton.confirmation')}
      onConfirm={handleDemote}
      okText={t('common.yes')}
      cancelText={t('common.no')}
    >
      <Btn
        type="link"
        loading={demoteLoading}
        disabled={demoteLoading}
        icon={<ShieldXIcon />}
      >
        {t('DemoteAdminRoleButton.demoteButton')}
      </Btn>
    </Popconfirm>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REMOVE_STRATEGIC_PLAN_ADMIN = gql`
  mutation removeTenantRoleFromUser(
    $userId: ID!
    $tenantRoleType: TenantRoleType!
  ) {
    removeTenantRoleFromUser(userId: $userId, roleType: $tenantRoleType) {
      id
    }
  }
`;
