import { Collapse, Divider, Empty, Tooltip, Typography } from 'antd';
import { friendlyDate } from '../../../../../services/dateFormats';
import { DisplayDateRange } from '../../../../DisplayDateRange';
import { AccelerationMeetingEntry } from '../../../../../hooks/useTeamKeyActivityLog';
import { useTranslation } from 'react-i18next';
import { MessageOutlined } from '@ant-design/icons';
import { DisplayName } from '../../../../DisplayName';
import { DisplayName_UserFragment } from '../../../../../generated/graphql';
import dayjs from 'dayjs';

interface Props {
  accelerationMeeting: AccelerationMeetingEntry;
  expanded: boolean;
}

export const AccelerationMeetingEvent = ({
  accelerationMeeting,
  expanded,
}: Props) => {
  const { t } = useTranslation();

  const notes =
    accelerationMeeting.accelerationKpiCommitments?.flatMap(
      (akc) =>
        akc.commitments.filter(
          (commitment) => commitment.note != null && commitment.note !== ''
        ) ?? []
    ) ?? [];

  const meetingHeld = accelerationMeeting.created != null;

  const commitmentsPerKeyActivity =
    accelerationMeeting.accelerationKpiCommitments?.map((akc) => ({
      ...akc,
      commitments: akc.commitments.filter((c) => c.quantity != null),
    })) ?? [];

  return (
    <Collapse
      className="mb mt"
      expandIconPosition="end"
      defaultActiveKey={expanded ? [0] : undefined}
    >
      <Collapse.Panel
        key={0}
        extra={
          <span>
            <Typography.Text type="secondary">
              <span title={friendlyDate(accelerationMeeting.periodStartDate)}>
                {dayjs(accelerationMeeting.periodStartDate).fromNow()}
              </span>
            </Typography.Text>
          </span>
        }
        header={
          <div>
            <span className="bold mr--s">
              {t('AccelerationMeetingEvent.title')}
            </span>
            <Tooltip
              className="ml"
              title={
                <div
                  style={{ whiteSpace: 'pre-wrap' }}
                  className="flx flx--column "
                >
                  {notes.length !== 0
                    ? t('AccelerationMeetingEvent.noteHeader', {
                        numberOfNotes: notes.length,
                      })
                    : meetingHeld
                      ? t('AccelerationMeetingEvent.noNotes')
                      : t('AccelerationMeetingEvent.noMeeting')}
                </div>
              }
            >
              {notes.length === 0 ? (
                <MessageOutlined
                  style={{
                    fontSize: 18,
                    paddingTop: 4,
                    color: 'lightgray',
                    verticalAlign: 'center',
                  }}
                />
              ) : (
                <MessageOutlined
                  disabled={true}
                  style={{
                    fontSize: 18,
                    paddingTop: 4,
                    verticalAlign: 'center',
                  }}
                />
              )}
            </Tooltip>
          </div>
        }
      >
        <div>
          <div className="pa--m">
            <div className="flx flx--jc-space-between">
              <div className="flx flx--column">
                <Typography.Text>
                  {t('AccelerationMeetingEvent.periodTitle')}
                </Typography.Text>
                <Typography.Text type="secondary">
                  <DisplayDateRange
                    startDate={accelerationMeeting.periodStartDate}
                    endDate={accelerationMeeting.periodEndDate}
                  />
                </Typography.Text>
              </div>
              {commitmentsPerKeyActivity?.map((akc) => (
                <div className="flx flx--column" key={akc.accelerationKpiId}>
                  <Typography.Text>
                    {t('AccelerationMeetingEvent.teamCommitmentTitle')}
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    {akc.commitmentsTotal} {accelerationMeeting.unit}
                  </Typography.Text>
                </div>
              ))}
            </div>
            <Divider />
            {meetingHeld ? (
              <div>
                <div className="flx flx--jc-space-between">
                  <Typography.Text style={{ fontSize: 16 }}>
                    {t('AccelerationMeetingEvent.commitmentsTitle')}
                  </Typography.Text>
                  {accelerationMeeting.created && (
                    <Typography.Text type="secondary">
                      {friendlyDate(accelerationMeeting.created)}
                    </Typography.Text>
                  )}
                </div>
                <div className="mt--l">
                  {commitmentsPerKeyActivity?.length === 0 ? (
                    <Empty
                      description={t('AccelerationMeetingEvent.noCommitments')}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  ) : (
                    commitmentsPerKeyActivity.map((cpka) => (
                      <div key={cpka.accelerationKpiId}>
                        {cpka.commitments.map((commitment, index) => (
                          <KeyActivityCommitmentSection
                            key={index}
                            commitment={commitment}
                            unit={accelerationMeeting.unit}
                          />
                        ))}
                      </div>
                    ))
                  )}
                </div>
              </div>
            ) : (
              <Empty
                description={t('AccelerationMeetingEvent.noMeeting')}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

interface KeyActivityCommitmentSectionProps {
  commitment: {
    user?: DisplayName_UserFragment | null;
    quantity?: number | null;
    note?: string | null;
  };
  unit?: string | null;
}

function KeyActivityCommitmentSection({
  commitment,
  unit,
}: KeyActivityCommitmentSectionProps) {
  return (
    <div className="flx flx--column mb">
      <div>
        <Typography.Text>
          <DisplayName user={commitment.user!} />
        </Typography.Text>
        <Typography.Text type="secondary" className="space--l">
          {commitment.quantity} {unit}
        </Typography.Text>
      </div>
      {commitment.note && (
        <Typography.Text type="secondary">
          <div
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {commitment.note}
          </div>
        </Typography.Text>
      )}
    </div>
  );
}
