import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import './SkaDetailsContent.less';
import Skeleton from 'react-loading-skeleton';

export const SkaDetailsContentSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className="SkaDetailsContent__tabContent">
      <Typography.Title type="secondary" level={5} className="mb--none">
        {t('common.title')}
      </Typography.Title>
      <Typography.Text strong>
        <Skeleton width={'75%'} />
      </Typography.Text>
      <Typography.Title type="secondary" level={5} className="mb--none mt--xl">
        {t('SkaDetailsContent.deadlineLabel')}
      </Typography.Title>
      <Typography.Text>
        <Skeleton width={100} />
      </Typography.Text>
      <Typography.Title type="secondary" level={5} className="mb--none mt--xl">
        {t('SkaDetailsContent.migLabel')}
      </Typography.Title>
      <Typography.Text>
        <Skeleton width={250} />
      </Typography.Text>
      <Typography.Title type="secondary" level={5} className="mb--none mt--xl">
        {t('SkaDetailsContent.ownerLabel')}
      </Typography.Title>
      <Typography.Text>
        <Skeleton width={150} />
      </Typography.Text>
      <Typography.Title type="secondary" level={5} className="mb--none mt--xl">
        {t('SkaDetailsContent.dodLabel')}
      </Typography.Title>
      <Typography.Paragraph style={{ whiteSpace: 'pre-wrap' }}>
        <Skeleton />
      </Typography.Paragraph>

      <>
        <Typography.Title
          type="secondary"
          level={5}
          className="mb--none mt--xl"
        >
          {t('SkaDetailsContent.tags')}
        </Typography.Title>
        <div className="flx flx--gap--xs flx--wrap"></div>
      </>
    </div>
  );
};
