import { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import './ContextualNavbarContent.less';
import cx from 'classnames';
import { BetaBadge } from '../../BetaBadge';
import { PageHeader } from '../../PageHeader';

export type MenuItem = {
  title: string;
  url: string;
  isBeta?: boolean;
  intercomDataTarget?: string;
  dataTestId?: string;
};

interface Props {
  menuItems?: MenuItem[];
  pageTitle?: ReactNode;
  title?: string;
  useOldNav?: boolean; // allow us to skip migrating admin pages to new design
}

export const ContextualNavbarContent = ({
  pageTitle,
  menuItems,
  title,
  useOldNav,
}: Props) => {
  const header = document.getElementById('portalArea');
  const content = document.createElement('div');

  const items = menuItems?.map((mi) => (
    <TopMenuItem
      key={mi.url}
      title={mi.title}
      url={mi.url}
      isBeta={mi.isBeta}
      dataIntercomTarget={mi.intercomDataTarget}
    />
  ));

  useEffect(() => {
    if (header) {
      header.appendChild(content);
      return () => {
        header!.removeChild(content);
      };
    }
  }, [header, content]);

  if (!useOldNav) {
    return <PageHeader pageTitle={pageTitle} menuItems={menuItems} />;
  }

  return ReactDOM.createPortal(
    <div className="ContextualNavbarContent">
      <div
        className="ContextualNavbarContent__title"
        data-intercom-target="Page Title"
        title={title}
      >
        {pageTitle}
      </div>

      <div
        className="ContextualNavbarContent__navigation"
        data-intercom-target="Top Navigation Bar"
      >
        {items}
      </div>
    </div>,
    content
  );
};

interface topMenuItemProps {
  title: string;
  isActive?: boolean;
  url: string;
  isBeta?: boolean;
  dataIntercomTarget?: string;
}

const TopMenuItem = ({
  title,
  url,
  isBeta,
  dataIntercomTarget,
}: topMenuItemProps) => {
  return (
    <NavLink
      className={cx('ContextualNavbarContent__menuItem', {
        isBeta: isBeta,
      })}
      activeClassName="active"
      to={url}
      data-intercom-target={dataIntercomTarget}
    >
      <span>
        {title}
        {isBeta && <BetaBadge className="space--l" />}
      </span>
    </NavLink>
  );
};
