import {
  CalendarFilled,
  CalendarOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  StopFilled,
  StopOutlined,
} from '@ant-design/icons';
import { Alert, DatePicker, Form, Modal, Tooltip } from 'antd';
import cx from 'classnames';

import React, { useState } from 'react';
import './MitemActionGroup.less';
import { useTranslation } from 'react-i18next';

import { isValidDeadline } from '../../../../../../../sprint/common/services/isValidDeadline';
import { MitemActionType } from '../../../../../../../../../generated/graphql';

import { useClosestPeriods } from '../../../../../../../../../hooks/useClosestPeriods';
import { useAccelerationDay } from '../../../../../../../../../hooks/useAccelerationDay';
import { MitemAction } from '../../../../../../../sprint/summary/components/SprintMitemTable';
import { useCurrentSprint } from '../../../../../../../../../hooks/useCurrentSprint';
import { useSprintTerms } from '../../../../../../../../../hooks/useSprintTerms';
import dayjs from 'dayjs';
import { skaValidationRules } from '../../../../../../../common/utils/useSkaValidationRules';

interface MitemActionGroupProps {
  animationIndex: number;
  selectedAction: MitemAction | null;
  onActionTaken: (updatedMitem: MitemAction) => void;
  teamId: string;
}

export const MitemActionGroup = ({
  animationIndex,
  selectedAction,
  onActionTaken,
  teamId,
}: MitemActionGroupProps) => {
  const { t } = useTranslation();
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const { currentPeriod } = useClosestPeriods(teamId);
  const { currentSprint } = useCurrentSprint(teamId);
  const { data: accelerationDay } = useAccelerationDay(teamId);
  const { data: termsData } = useSprintTerms(teamId);
  const terms = termsData ?? [];

  const [formRef] = Form.useForm();

  const onSubmit = () => {
    formRef.validateFields().then((values) => {
      onActionTaken({
        action: MitemActionType.PLANNED,
        date: values.deadline,
      });
      setOpenRescheduleModal(false);
    });
  };
  return (
    <div className="MitemActionGroup" onClick={(e) => e.stopPropagation()}>
      <IconGroupItem
        animationIndex={animationIndex}
        icon={<CalendarOutlined />}
        checkedIcon={<CalendarFilled />}
        toolTipText={t('MitemActionGroup.RescheduleTooltip')}
        checked={!!selectedAction && selectedAction.action === 'PLANNED'}
        checkedClass="MitemActionGroup--RescheduleChecked"
        className="MitemActionGroup--Reschedule"
        onClick={() => setOpenRescheduleModal(true)}
      />
      <IconGroupItem
        animationIndex={animationIndex}
        icon={<CheckCircleOutlined />}
        checkedIcon={<CheckCircleFilled />}
        toolTipText={t('MitemActionGroup.CompleteTooltip')}
        checked={!!selectedAction && selectedAction.action === 'COMPLETED'}
        checkedClass="MitemActionGroup--CompleteChecked"
        className="MitemActionGroup--Complete"
        onClick={() => onActionTaken({ action: MitemActionType.COMPLETED })}
      />
      <IconGroupItem
        animationIndex={animationIndex}
        icon={<StopOutlined />}
        checkedIcon={<StopFilled />}
        toolTipText={t('MitemActionGroup.StopTooltip')}
        checked={!!selectedAction && selectedAction.action === 'ARCHIVED'}
        checkedClass="MitemActionGroup--DeleteChecked"
        className="MitemActionGroup--Delete"
        onClick={() => onActionTaken({ action: MitemActionType.ARCHIVED })}
      />
      <Modal
        title={t('MitemActionGroup.RescheduleTitle')}
        open={openRescheduleModal}
        onOk={onSubmit}
        onCancel={() => setOpenRescheduleModal(false)}
      >
        <Alert
          className="mb"
          type="info"
          message={t('MitemForm.deadlineHint')}
        />
        <Form
          form={formRef}
          layout="vertical"
          initialValues={{
            deadline:
              currentPeriod?.timePeriodEndDate &&
              dayjs(currentPeriod.timePeriodEndDate),
          }}
        >
          <Form.Item
            name="deadline"
            label={t('MitemForm.deadlineLabel')}
            required
            rules={skaValidationRules.deadline}
          >
            <DatePicker
              disabledDate={(date) =>
                !date ||
                !accelerationDay ||
                !currentSprint ||
                !isValidDeadline({
                  date,
                  accelerationDay,
                  currentSprint,
                  terms,
                })
              }
              allowClear={false}
              showToday={false}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

interface IconGroupItemProps {
  animationIndex: number;
  icon: React.ReactNode;
  checkedIcon: React.ReactNode;
  onClick?: () => void;
  checked?: boolean;
  toolTipText?: string;
  checkedClass: string;
  className?: string;
}

const IconGroupItem = ({
  animationIndex,
  icon,
  checkedIcon,
  checked,
  className,
  onClick,
  toolTipText,
  checkedClass,
}: IconGroupItemProps) => {
  return (
    <div
      ref={(div) => {
        return div?.style.setProperty(
          '--anim-delay',
          500 + animationIndex * 150 + 'ms'
        );
      }}
      onClick={onClick}
      className={cx('ml IconGroupItem', className, {
        [checkedClass]: checked,
      })}
    >
      <Tooltip placement="top" title={toolTipText} mouseEnterDelay={0.7}>
        {checked ? checkedIcon : icon}
      </Tooltip>
    </div>
  );
};
