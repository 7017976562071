import './WizardSteps.less';
import { useWizardNavigation } from '../../../../../../../components/WizardNavigationProvider';
import cx from 'classnames';

export const WizardSteps = () => {
  const wizard = useWizardNavigation();

  return (
    <div className="WizardSteps">
      {wizard.steps.map((step) =>
        step.type === 'WizardComponentStep' ? (
          <div
            key={step.key}
            className={cx('WizardSteps__step', {
              'WizardSteps__step--active':
                step.index === wizard.activeStep.index,
              'WizardSteps__step--visited':
                step.index < wizard.activeStep.index,
            })}
          >
            <div className="WizardSteps__stepCircle">{step.index + 1}</div>
            <div className="WizardSteps__stepTitle">{step.title}</div>
            <div className="WizardSteps__stepLine"></div>
          </div>
        ) : (
          <div key={step.key} className="WizardSteps__step">
            N/A
          </div>
        )
      )}
    </div>
  );
};
