import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  DomainId,
  GetInitiativesForNavigationDocument,
  RealignInitiativeModalContentMutationDocument,
  RealignInitiativeModalContentQueryDocument,
  SupportedEntityType,
} from '../../../../../generated/graphql';
import { InitiativeSelect } from '../../../StrategicPlanAdminModal/components/manageCompanyInitiatives/InitiativeSelect';
import { Btn } from '../../../../Button';
import { useState } from 'react';
interface Props {
  initiativeId: string;
  onClose?: () => void;
  onSetAsTopInitiative: () => void;
}

export const RealignInitiativeModalContent = ({
  initiativeId,
  onClose,
  onSetAsTopInitiative,
}: Props) => {
  const { t } = useTranslation();

  const { data } = useSuspenseQuery(
    RealignInitiativeModalContentQueryDocument,
    { variables: { initiativeId }, fetchPolicy: 'no-cache' }
  );

  const allInitiatives = data.tenantInitiatives2.initiatives.filter(
    (i) => i.domainId.itemId !== initiativeId
  );
  const currentInitiative = data.tenantInitiative2;

  if (!currentInitiative) {
    throw new Error('Initiative not found');
  }

  const currentParentInitiative =
    currentInitiative.metadata.supportsInitiatives[0];

  const [selectedParentInitiative, setselectedParentInitiative] = useState<
    DomainId | undefined
  >(currentParentInitiative?.domainId);

  const [updateTenantInitiative, { loading }] = useMutation(
    RealignInitiativeModalContentMutationDocument
  );

  const handleSave = () => {
    if (!selectedParentInitiative) {
      throw new Error('Parent initiative not found');
    }
    updateTenantInitiative({
      variables: {
        initiativeId: initiativeId,
        initiative: {
          rev: currentInitiative?.rev,
          supports: [
            {
              type: SupportedEntityType.initiative,
              item: {
                domainId: {
                  tenantId: selectedParentInitiative.tenantId,
                  itemId: selectedParentInitiative.itemId,
                },
              },
            },
          ],
        },
      },
      onCompleted: () => {
        onClose?.();
      },
      refetchQueries: [{ query: GetInitiativesForNavigationDocument }],
    });
  };

  return (
    <div>
      <h3 className="mb--s">{t('RealignInitiativeModalContent.title')}</h3>
      <h5 className="txt--secondary bold mb--l">{currentInitiative?.name}</h5>
      <InitiativeSelect
        style={{ width: '100%' }}
        initiatives={allInitiatives.filter(
          (i) => i.metadata.archived === false
        )}
        value={selectedParentInitiative?.itemId}
        onChange={(init) => setselectedParentInitiative(init.domainId)}
      />
      <Btn
        disabled={!currentParentInitiative}
        onClick={onSetAsTopInitiative}
        type="link"
        className="mt--s pl--none"
      >
        {t('RealignInitiativeModalContent.setAsTopInitiative')}
      </Btn>
      <div className="flx borderTop mt pt--xl">
        <Btn
          type="primary"
          className="ml--auto"
          onClick={handleSave}
          loading={loading}
        >
          {t('common.save')}
        </Btn>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REALIGN_INITIATIVE_QUERY = gql`
  query realignInitiativeModalContentQuery($tenantId: ID, $initiativeId: ID!) {
    tenantInitiative2(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      domainId {
        itemId
        tenantId
      }
      rev
      name
      metadata {
        supportsInitiatives {
          domainId {
            tenantId
            itemId
          }
        }
      }
    }
    tenantInitiatives2 {
      initiatives {
        domainId {
          itemId
          tenantId
        }
        name
        id
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          completedAt
          archived
          status
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REALIGN_INITIATIVE_MUTATION = gql`
  mutation realignInitiativeModalContentMutation(
    $initiativeId: ID!
    $initiative: UpdateInitiative2Input!
  ) {
    updateTenantInitiative2(
      initiativeId: $initiativeId
      initiative: $initiative
    ) {
      id
    }
  }
`;
