import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { InitiativeDetailsIcon } from '../../../../../../icons/initiativeIcons/InitiativeDetailsIcon';
import './TeamInitiativesCardFooter.less';
import SubInitiativesModal from './teamInitiativesCardFooter/SubInitiativesModal';
import { InitiativeNode } from '../../../../../company/initiatives_old/initiativesPageV1/services/initiativeTreeNodeHelper';
import { ContributingTeamsModal } from './teamInitiativesCardFooter/ContributingTeamsModal';

interface Props {
  openTab?: boolean;
  teamId?: string;
  hierarchicalInitiative: InitiativeNode;
}

export const TeamInitiativesCardFooter = ({
  openTab = false,
  teamId,
  hierarchicalInitiative,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flx mt--xs TeamInitiativesCardFooter__text">
        <SubInitiativesModal hierarchicalInitiative={hierarchicalInitiative} />
        <ContributingTeamsModal
          hierarchicalInitiative={hierarchicalInitiative}
        />
      </div>

      <div className=" flx flx--row mt--s TeamInitiativesCardFooter__detailsButton">
        <Link
          to={
            '/company/initiatives/' +
            hierarchicalInitiative.domainId.itemId +
            (teamId ? `?filteredByTeamId=${teamId}` : '')
          }
          target={openTab ? '_blank' : ''}
        >
          <InitiativeDetailsIcon />
          <span className="ml--s">
            {t('TeamInitiativesCard.initiativeDetails')}
          </span>
        </Link>
      </div>
    </div>
  );
};
