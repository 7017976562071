import { KeyActivityGraph } from '../../graphs/KeyActivityGraph';
import { useTranslation } from 'react-i18next';
import { useKeyActivityPerformance } from '../../../hooks/useKeyActivityPerformanceQuery';
import { lineColor } from '../../../styleVars';
import { PerformanceTag } from '../../PerformanceTag';
import './AkpiCard.less';
import { AkpiDescription } from './akpiCard/AkpiDescription';
import './MitemTermCard.less';

interface PeriodData {
  targetDate: string;
  goal: number;
  label: string;
  actual?: number | null;
}

interface MitemTerm {
  id: string;
  startDate: string;
  periodData: PeriodData[];
}

interface Props {
  term: MitemTerm;
  showDescription: boolean;
  highlightedPeriod?: string | null;
  teamId: string;
  tenantId?: string;
  action?: React.ReactNode;
}

export const MitemTermCard = ({
  term,
  highlightedPeriod,
  showDescription,
  teamId,
  tenantId,
  action,
}: Props) => {
  const { t } = useTranslation();
  const { data } = useKeyActivityPerformance(teamId, term.id, tenantId);

  return (
    <div className="AkpiCard">
      {showDescription && (
        <div className="AkpiCard__description">
          <AkpiDescription
            akpi={{
              name: t('MitemTermCard.title'),
              descriptionHtml: t('MitemTermCard.description'),
            }}
          />
        </div>
      )}
      <div className="AkpiCard__header">
        <div>
          <PerformanceTag value={data?.targetFulfillment} className="mr--xs" />
        </div>

        <div className="AkpiCard__title">{t('MitemTermCard.title')}</div>
        {action}
      </div>
      <div>
        <KeyActivityGraph
          lineData={term.periodData}
          lineConf={[
            {
              dataKey: 'goal',
              stroke: lineColor.goal,
              name: t('common.goal') as string,
            },
            {
              dataKey: 'actual',
              stroke: lineColor.actual,
              name: t('common.actual') as string,
            },
          ]}
          startDate={term.startDate}
          highlightedPeriod={highlightedPeriod}
        />
      </div>
    </div>
  );
};
