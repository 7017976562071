import Icon from '@ant-design/icons';

const ComputerOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.3335 12V10.6667H5.66683V9.33333H1.66683C1.30016 9.33333 0.986385 9.20289 0.725496 8.942C0.464163 8.68067 0.333496 8.36667 0.333496 8V1.33333C0.333496 0.966667 0.464163 0.652667 0.725496 0.391333C0.986385 0.130444 1.30016 0 1.66683 0H12.3335C12.7002 0 13.0142 0.130444 13.2755 0.391333C13.5364 0.652667 13.6668 0.966667 13.6668 1.33333V8C13.6668 8.36667 13.5364 8.68067 13.2755 8.942C13.0142 9.20289 12.7002 9.33333 12.3335 9.33333H8.3335V10.6667H9.66683V12H4.3335ZM1.66683 8H12.3335V1.33333H1.66683V8Z" />
  </svg>
);

export const ComputerOutlineIcon = (props: any) => (
  <Icon component={ComputerOutlineIconSvg} {...props} />
);
