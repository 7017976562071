import dayjs from 'dayjs';
import {
  MigStatus,
  MitemStatus,
  PublishStatus,
  SkaGraphSkaTimeStatusFragment,
} from '../../../../generated/graphql';
import { groupBy } from '../../../../services/groupBy';

interface SprintKeyActivityWithDeadline {
  id: string;
  deadline: string;
}
export const filterExpectedToBeDoneSkas = <
  T extends SprintKeyActivityWithDeadline,
>(
  sprintKeyActivities: T[]
) => {
  // Filtering SKA's that has passed deadline
  const todaysDate = dayjs();
  const filteredSprint = sprintKeyActivities.filter((ska) =>
    dayjs(ska.deadline).isBefore(todaysDate, 'day')
  );

  return filteredSprint;
};

export const calculateDoneVsPlan = (
  completedActivities: number,
  activitiesExpectedToBeCompleted: number
) => {
  if (activitiesExpectedToBeCompleted === 0) {
    return 100;
  }

  return Math.floor(
    (completedActivities / activitiesExpectedToBeCompleted) * 100
  );
};

export const groupByStatus = <
  T extends { status: PublishStatus | MigStatus | MitemStatus },
>(
  activities: T[],
  status: PublishStatus[] | MigStatus[] | MitemStatus[]
) => {
  const activitiesByStatus = groupBy(activities, (act) => act.status);

  status.forEach((status) => {
    if (!activitiesByStatus[status]) {
      activitiesByStatus[status] = [];
    }
  });
  return activitiesByStatus;
};

export const getCompletedAtSkasBetweenDates = (
  sprintKeyActivities: SkaGraphSkaTimeStatusFragment[],
  startDate: string,
  endDate?: string
) => {
  const completedAtSkas = sprintKeyActivities.filter(
    (ska) =>
      ska.sprintKeyActivity?.completedAt != null &&
      dayjs(ska.sprintKeyActivity.completedAt).isBetween(
        startDate,
        endDate,
        'day',
        '[]'
      )
  );
  return completedAtSkas;
};
