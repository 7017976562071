import { List } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { UpdateMySettingsModal } from './UpdateMySettingsModal';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Btn } from '../../../components/Button';
import './MySettingsPage.less';

interface Props {
  name?: string | null;
  email: string;
  displayName?: string | null;
  locale?: string | null;
}

const missingDataMarker = <span style={{ color: '#ddd' }}>-</span>;

export const MySettingsPage = (props: Props) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [userField, setUserField] = useState<
    'name' | 'displayName' | 'locale' | null
  >(null);

  const { name, displayName, email, locale } = props;

  return (
    <div>
      <Helmet title={t('MySettingsPage.settingsTab')} />
      {userField !== null && (
        <UpdateMySettingsModal
          field={userField}
          value={props[userField]}
          onClose={() => {
            if (isMounted()) {
              setUserField(null);
            }
          }}
        />
      )}
      <List size="small">
        <List.Item
          actions={[
            <Btn
              key="edit-name"
              className="MySettingsPage__buttonTitle-lowerCase"
              type="link"
              onClick={() => setUserField('name')}
            >
              {t('common.edit')}
            </Btn>,
          ]}
          data-intercom-target="User Settings Form Full Name Input"
        >
          <List.Item.Meta
            data-hj-suppress
            title={t('common.name')}
            description={name || missingDataMarker}
          />
        </List.Item>
        <List.Item
          actions={[
            <Btn
              key="edit-display-name"
              className="UserBasicSettings__buttonTitle-lowerCase"
              type="link"
              onClick={() => setUserField('displayName')}
            >
              {t('common.edit')}
            </Btn>,
          ]}
          data-intercom-target="User Settings Form Display Name Input"
        >
          <List.Item.Meta
            data-hj-suppress
            title={t('MySettingsPage.displayNameField')}
            description={displayName || missingDataMarker}
          />
        </List.Item>
        <List.Item
          actions={[
            <Btn
              key="edit-locale"
              className="UserBasicSettings__buttonTitle-lowerCase"
              type="link"
              onClick={() => setUserField('locale')}
            >
              {t('common.edit')}
            </Btn>,
          ]}
          data-intercom-target="User Settings Form Locale Input"
        >
          <List.Item.Meta
            title={t('MySettingsPage.locale')}
            description={locale ? t(`locale.${locale}`) : missingDataMarker}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            data-hj-suppress
            title={t('MySettingsPage.emailField')}
            description={email}
            data-intercom-target="User Settings Form Email Input"
          />
        </List.Item>
      </List>
    </div>
  );
};

const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return () => isMounted.current;
};
