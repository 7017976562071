import {
  GetTenantBusinessPlanInitiativeDocument,
  GetTenantInitiativeForReportDocument,
} from '../../../../generated/graphql';
import { gql, useSuspenseQuery } from '@apollo/client';
import { InitiativeDetailsSkeleton } from './InitiativeDetails.skeleton';
import { ProgressSection } from './progressSection/ProgressSection';
import { TitleSection } from './titleSection/TitleSection';
import { OverviewSection } from './overviewSection/OverviewSection';
import { InitiativeHeaderSection } from './headerSection/InitiativeHeaderSection';
import {
  InitiativeDetailsNode,
  initiativeDetailsTreeBuilder,
} from '../../../../components/initiatives/Gantish/services/initiativeDetailsTreeBuilder';
import { TeamContributionTable } from './teamContributionSection/TeamContributionTable';
import { useTranslation } from 'react-i18next';

interface Props {
  initiativeId: string;
}

export type InitiativeDetailsTreeNode = InitiativeDetailsNode;

export const InitiativeDetails = ({ initiativeId }: Props) => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(GetTenantBusinessPlanInitiativeDocument, {
    variables: { initiativeId: initiativeId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const { data: initiativeData } = useSuspenseQuery(
    GetTenantInitiativeForReportDocument,
    {
      variables: { initiativeId: initiativeId },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    }
  );

  const initiativeReportNode = initiativeDetailsTreeBuilder(
    data.initiativeDetailedReport,
    initiativeData.tenantInitiative2
  );

  return (
    <div>
      <div className="mb--l">
        <TitleSection initiative={initiativeData.tenantInitiative2} />
      </div>

      <InitiativeHeaderSection
        initiative={initiativeData.tenantInitiative2}
        initiativeReport={data.initiativeDetailedReport}
      />
      <div className="divider mb--xxl mt--xxl" />
      <OverviewSection
        report={data.initiativeDetailedReport}
        initiativeReportNode={initiativeReportNode}
      />
      <div className="divider mb--xxl mt--xxl" />

      <ProgressSection
        report={data.initiativeDetailedReport}
        initiativeId={initiativeId}
        initiative={initiativeData.tenantInitiative2}
      />

      <div className="divider mb--xxl mt--xxl" />
      <h3 className="mb--xl">{t('TeamContributionSection.title')}</h3>
      <TeamContributionTable initiativeReport={data.initiativeDetailedReport} />
    </div>
  );
};

InitiativeDetails.Skeleton = InitiativeDetailsSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TENANT_INITIATIVES = gql`
  query getTenantBusinessPlanInitiative($tenantId: ID, $initiativeId: ID!) {
    initiativeDetailedReport(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      ...InitiativeHeaderSection__InitiativeDetailedReportResponse
      ...InitiativeProgressSection__InitiativeDetailedReportResponse
      ...InitiativeOverviewSection__InitiativeDetailedReportResponse
      ...TeamContributionTable_InitiativeDetailedReportResponse
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TENANT_INITIATIVE = gql`
  query getTenantInitiativeForReport($tenantId: ID, $initiativeId: ID!) {
    tenantInitiative2(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      domainId {
        itemId
        tenantId
      }
      ...TitleSection__InitiativeLight
      ...InitiativeHeaderSection__InitiativeLight
      ...Gantish__InitiativeLight
      ...ProgressSection__InitiativeLight
    }
  }
`;
