import { useTeamInitiatives } from '../../../../hooks/useTeamInitiatives';
import { TeamInitiativesCard } from './teamInitiatives/TeamInitiativesCard';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { NoInitiativesPlaceholder } from '../../../company/initiatives_old/initiativesPageV1/NoInitiativesPlaceholder';
import {
  findHierarchicalInitiativeById,
  useHierarchicalInitiatives,
} from '../../../../hooks/useTenantInitiatives';
import { ToggleSection } from '../../overview/components/ToggleSection';
import { stringSort } from '../../../../services/stringSort';
import { useMemo } from 'react';

interface Props {
  teamId: string;
}

export const TeamInitiatives = ({ teamId }: Props) => {
  const { data, loading } = useTeamInitiatives(teamId);
  const { t } = useTranslation();

  const { hierarchicalInitiatives } = useHierarchicalInitiatives(
    undefined,
    true
  );

  const teamInitiatives = useMemo(() => {
    return (
      data?.initiatives.initiatives
        .filter(
          (initiative) => initiative.team.id === teamId && !initiative.archived
        )
        .sort(sortByName) ?? []
    );
  }, [data, teamId]);

  const joinedInitiatives =
    data?.initiatives.initiatives
      .filter(
        (initiative) => initiative.team.id !== teamId && !initiative.archived
      )
      .sort(sortByName) ?? [];

  const archivedInitiatives =
    data?.initiatives.initiatives
      .filter((initiative) => initiative.archived)
      .sort(sortByName) ?? [];

  return (
    <div>
      <h2 className="mt--xl">{t('TeamInitiatives.header')}</h2>

      {teamInitiatives.length === 0 &&
        joinedInitiatives.length === 0 &&
        !loading && (
          <NoInitiativesPlaceholder
            header={t('TeamInitiatives.noTeamInitiativesPlaceholder')}
            className="flx--jc-center"
          />
        )}

      {teamInitiatives.length > 0 && (
        <>
          <h3 className="mb--xl mt--xl">
            {t('TeamInitiatives.ourInitiatives')}
          </h3>
          <Row gutter={[18, 18]}>
            {teamInitiatives.map((initiative) => {
              const hierarchicalInitiative = findHierarchicalInitiativeById(
                hierarchicalInitiatives,
                initiative.id
              );
              if (hierarchicalInitiative == null) {
                return null;
              }

              return (
                <Col key={initiative.id} xs={12} xl={8}>
                  <TeamInitiativesCard
                    hierarchicalInitiative={hierarchicalInitiative}
                    teamId={teamId}
                    showActions={true}
                    openTab={true}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}

      {joinedInitiatives.length > 0 && (
        <>
          <h3 className="mb--xl mt--xl">
            {t('TeamInitiatives.joinedInitiatives')}
          </h3>

          <Row gutter={[18, 18]}>
            {joinedInitiatives.map((initiative) => {
              const hierarchicalInitiative = findHierarchicalInitiativeById(
                hierarchicalInitiatives,
                initiative.id
              );
              if (hierarchicalInitiative == null) {
                return null;
              }
              return (
                <Col key={initiative.id} xs={12} xl={8}>
                  <TeamInitiativesCard
                    hierarchicalInitiative={hierarchicalInitiative}
                    teamId={teamId}
                    showActions={true}
                    openTab={true}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}

      {archivedInitiatives.length > 0 && (
        <div className="flx flx--jc-center flx--column">
          <ToggleSection
            titleExpanded={t('TeamInitiatives.hideArchived')}
            titleNotExpanded={t('TeamInitiatives.showArchived')}
          >
            <div className="mt--l">
              <Row gutter={[18, 18]}>
                {archivedInitiatives.map((initiative) => {
                  const hierarchicalInitiative = findHierarchicalInitiativeById(
                    hierarchicalInitiatives,
                    initiative.id
                  );
                  if (hierarchicalInitiative == null) {
                    return null;
                  }
                  return (
                    <Col key={initiative.id} xs={12} xl={8}>
                      <TeamInitiativesCard
                        hierarchicalInitiative={hierarchicalInitiative}
                        teamId={teamId}
                        showActions={true}
                        openTab={true}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </ToggleSection>
        </div>
      )}
    </div>
  );
};

type NamedEntity = { name: string };
const sortByName = (a: NamedEntity, b: NamedEntity) =>
  stringSort(a.name, b.name);
