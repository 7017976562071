import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { TeamInitiativesCard } from '../../../team/setup/overviewPage/teamInitiatives/TeamInitiativesCard';
import { NoInitiativesPlaceholder } from './NoInitiativesPlaceholder';
import { InitiativeNode } from './services/initiativeTreeNodeHelper';

interface Props {
  teamId?: string;
  strategicInitiatives: InitiativeNode[];
  teamInitiatives: InitiativeNode[];
  loading: boolean;
}

export const InitiativesOverview = ({
  teamId,
  strategicInitiatives,
  teamInitiatives,
  loading,
}: Props) => {
  const { t } = useTranslation();

  let noGlobalInitiatives = null;

  if (strategicInitiatives.length === 0 && !loading) {
    noGlobalInitiatives = teamId ? (
      <NoInitiativesPlaceholder
        header={t('InitiativesOverview.strategicTeamHeader')}
        width="420px"
        height="300px"
      />
    ) : (
      <NoInitiativesPlaceholder
        header={t('InitiativesOverview.strategicHeader')}
        width="550px"
        height="500px"
      />
    );
  }

  return (
    <div>
      <h2
        className="mb--s"
        data-intercom-target="Strategic initiative headline"
      >
        {t('common.initiative.globalInitiatives')}
      </h2>
      {noGlobalInitiatives}

      <Row gutter={[18, 18]} className="mb--xl">
        {strategicInitiatives.map((initiative) => {
          return (
            <Col key={initiative.id} xs={12} xl={8}>
              <TeamInitiativesCard
                hierarchicalInitiative={initiative}
                teamId={teamId}
              />
            </Col>
          );
        })}
      </Row>

      {teamInitiatives.length > 0 && (
        <>
          <h2 className="mb--s">{t('common.initiative.teamInitiatives')}</h2>
          <Row gutter={[18, 18]}>
            {teamInitiatives.map((initiative) => {
              return (
                <Col key={initiative.id} xs={12} xl={8}>
                  <TeamInitiativesCard
                    hierarchicalInitiative={initiative}
                    teamId={teamId}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </div>
  );
};
