import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
  HighlightCarousel_MitemFragment,
  MitemStatus,
} from '../../../../../generated/graphql';
import { stringSort } from '../../../../../services/stringSort';
import nothingCompleted from './images/nothingCompleted.svg';
import noOverdueHighlights from './images/noOverdueHighlights.svg';
import nothingPlanned from './images/nothingPlanned.svg';

import { HighlightCard } from './HighlightCard';
import { useTranslation } from 'react-i18next';
import './HighlightCarousel.less';
import { Btn } from '../../../../../components/Button';
import { ManageSearchIcon } from '../../../../../icons/ManageSearch';
import { gql } from '@apollo/client';

interface Props {
  highlights: HighlightCarousel_MitemFragment[];
  filterStatus:
    | MitemStatus.COMPLETED
    | MitemStatus.OVERDUE
    | MitemStatus.PLANNED;
  onScrollToActivitiesProgress?: () => void;
}

const MAX_NUMBER_OF_HIGHLIGHTS = 8;

const placeholderImage = {
  COMPLETED: nothingCompleted,
  OVERDUE: noOverdueHighlights,
  PLANNED: nothingPlanned,
};

export const HighlightCarousel = ({
  highlights,
  filterStatus,
  onScrollToActivitiesProgress,
}: Props) => {
  const { t } = useTranslation();

  const filteredHighlights =
    highlights
      ?.filter((ska) => {
        if (filterStatus === MitemStatus.PLANNED) {
          return (
            ska.status === MitemStatus.PLANNED ||
            ska.status === MitemStatus.ACTIVE
          );
        }
        return ska.status === filterStatus;
      })
      .sort((a, b) =>
        filterStatus === MitemStatus.PLANNED
          ? stringSort(a.deadline, b.deadline)
          : stringSort(b.deadline, a.deadline)
      ) ?? [];

  const hasNoHighlightsForSelectedFilter = filteredHighlights.length === 0;

  if (hasNoHighlightsForSelectedFilter) {
    if (
      filterStatus === MitemStatus.COMPLETED &&
      highlights.filter((ska) => ska.status === MitemStatus.PLANNED).length > 0
    ) {
      const firstPlannedHighlight = highlights
        .filter((ska) => ska.status === MitemStatus.PLANNED)
        .sort((a, b) => stringSort(a.deadline, b.deadline))[0];

      return (
        <div className="flx flx--jc-center ">
          <div className="flx flx--ai-center">
            <img
              className="HighlightCarousel__svgImg mr--xl"
              src={placeholderImage.COMPLETED}
              alt=""
            />
            <div className="HighlightCarousel__placeholderContainer">
              <h3 className="mb">
                {t(`HighlightCarousel.completedWithPlanned.title`)}
              </h3>
              <div>
                {t(`HighlightCarousel.completedWithPlanned.text`, {
                  firstPlannedHighlight: firstPlannedHighlight.deadline,
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="flx flx--jc-center ">
        <div className="flx flx--ai-center">
          <img
            className="HighlightCarousel__svgImg mr--xl"
            src={placeholderImage[filterStatus]}
            alt=""
          />
          <div style={{ width: 420 }}>
            <h3 className="mb">
              {t(`HighlightCarousel.${filterStatus.toLowerCase()}.title`)}
            </h3>
            <div>
              {t(`HighlightCarousel.${filterStatus.toLowerCase()}.text`)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const items = filteredHighlights
    .slice(0, MAX_NUMBER_OF_HIGHLIGHTS)
    .map((ska) => <HighlightCard sprintKeyActivity={ska} key={ska.id} />);

  const showMaximumHighlightCard =
    filteredHighlights.length > MAX_NUMBER_OF_HIGHLIGHTS;

  const carouselItems = showMaximumHighlightCard
    ? items.concat(
        <div
          className="HighlightCarousel__maxItemsCard"
          key="maximum_number_of_highlights_card"
        >
          {t(`HighlightCarousel.maximumItemsInfo`)}
          <div>
            <Btn
              icon={<ManageSearchIcon />}
              className="pl--none mt--l cursor-pointer"
              type="link"
              onClick={onScrollToActivitiesProgress}
            >
              {t(`HighlightCarousel.scrollDownToSeeAll`)}
            </Btn>
          </div>
        </div>
      )
    : items;

  return (
    <Carousel
      key={filterStatus}
      arrows
      draggable
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={true}
      renderDotsOutside={true}
      responsive={{
        desktopLarge: {
          breakpoint: {
            max: 10000,
            min: 1500,
          },
          items: 4,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1200,
            min: 0,
          },
          items: 2,
          partialVisibilityGutter: 30,
        },
        desktop: {
          breakpoint: {
            max: 1500,
            min: 1200,
          },
          items: 3,
          partialVisibilityGutter: 30,
        },
      }}
      slidesToSlide={1}
    >
      {carouselItems}
    </Carousel>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HIGHLIGHT_CAROUSEL_FRAGMENT = gql`
  fragment HighlightCarousel_Mitem on Mitem {
    id
    deadline
    status
    ...HighlightCard_Mitem
  }
`;
