import { Auth0Client } from '@auth0/auth0-spa-js';
import { Typography } from 'antd';
import './VerifyEmail.less';
import customerSuccess from './images/CustomerSuccess.jpg';

interface Props {
  auth0Client: Auth0Client | null;
  email: string;
}

export const VerifyEmail = ({ email }: Props) => {
  return (
    <div className="VerifyEmail">
      <div className="VerifyEmail__topSection VerfyEmail__container">
        <div className="VerfyEmail__innerContainer">
          <Typography.Title
            style={{ color: 'white' }}
            className="text-c VerfyEmail__h1"
          >
            Welcome to Howwe
          </Typography.Title>

          <div className="VerfyEmail__infoText">
            Before we can begin, you need to verify your email
          </div>
          <div className="VerfyEmail__infoText" style={{ fontSize: 30 }}>
            {email}
          </div>
          <div className="mt--xl">
            <div className="VerfyEmail__infoText">
              Please check your inbox and follow the instructions in the email
              you will soon get from us. If you for any reasons haven't received
              an email from us within the hour, please feel free to contact
              customer support and we will help you.
            </div>
          </div>
        </div>
      </div>
      <div className="VerfyEmail__container">
        <div className="VerfyEmail__innerContainer flx">
          <div className="flx--1">
            <Typography.Title>Customer success</Typography.Title>
            <Typography.Text className="VerfyEmail__infoText--blue">
              Meet Nadine Glimhester, our Head of Customer Success. Nadine and
              her team are always just a click away, during your onboarding
              journey and beyond!
            </Typography.Text>
            <div className="mt">
              <Typography.Text className="VerfyEmail__infoText--blue">
                You can always reach our support team either by email,
                <a href="mailto: support@howwe.io"> support@howwe.io </a>
                or using the chat-widget on our site.
              </Typography.Text>
            </div>
          </div>
          <div className="flx--1">
            <figure className="flx flx--jc-center">
              <img
                className="VerfyEmail__image"
                src={customerSuccess}
                alt="Katarina Bennich"
              />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};
