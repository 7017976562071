import {
  BaseInitiative,
  InitiativeNode,
  initiativeTreeBuilder,
} from '../initiativeTreeBuilder';
import { InitiativeTitle } from './InitiativeTitle';

export function navigationTreeBuilder<T extends BaseInitiative>(
  initiatives: T[]
): InitiativeNode[] {
  const flatInitNodes = initiatives.map((i) => ({
    id: i.id,
    key: i.id,
    title: (
      <InitiativeTitle
        tag={i.tag}
        domainId={i.domainId}
        isTopInitiative={i.metadata.supportsInitiatives.length === 0}
        metadata={i.metadata}
      />
    ),
    parentIsCompletedOrArchived: false,
    baseInitiative: i,
    data: null,
  }));

  const initiativesTree: InitiativeNode[] =
    initiativeTreeBuilder(flatInitNodes);

  return initiativesTree;
}
