import Icon from '@ant-design/icons';

const BankIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.3335 11.3333V6.66663H3.66683V11.3333H2.3335ZM6.3335 11.3333V6.66663H7.66683V11.3333H6.3335ZM0.333496 14V12.6666H13.6668V14H0.333496ZM10.3335 11.3333V6.66663H11.6668V11.3333H10.3335ZM0.333496 5.33329V3.99996L7.00016 0.666626L13.6668 3.99996V5.33329H0.333496ZM3.30016 3.99996H10.7002L7.00016 2.16663L3.30016 3.99996Z" />
  </svg>
);

export const BankIcon = (props: any) => (
  <Icon component={BankIconSvg} {...props} />
);
