import './Gigantish.less';
import dayjs from 'dayjs';
import { GigantishLegendInitiative } from './components/GigantishLegendInitiative/GigantishLegendInitiative';
import { GigantishChartColumns } from './components/GigantishChartColumns';
import { Btn } from '../../../../../components/Button';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export const GigantishSkeleton = () => {
  const { t } = useTranslation();

  const boundary = {
    start: dayjs().subtract(1, 'year').startOf('year'),
    end: dayjs().add(5, 'year').endOf('year'),
  };

  return (
    <div className="Gigantish">
      <div className="Gigantish__legend">
        <div className="Gigantish__legendHeader">
          <div className="mr--auto">{t('Gigantish.companyInitiatives')}</div>
          <div className="Gigantish__valueColumn">{t('Gigantish.profit')}</div>
          <div className="Gigantish__valueColumn">{t('Gigantish.revenue')}</div>
        </div>
        <div className="Gigantish__legendRows">
          <GigantishLegendInitiative.skeleton />
          <GigantishLegendInitiative.skeleton />
        </div>
      </div>
      <div className="flx--1 relative">
        <Btn
          className="Gigantish__prevBtn"
          type="link"
          disabled={true}
          icon={<CaretLeftFilled style={{ fontSize: ICON_SIZE }} />}
          onClick={() => {}}
        />
        <Btn
          type="link"
          className="Gigantish__nextBtn"
          disabled={true}
          icon={<CaretRightFilled style={{ fontSize: ICON_SIZE }} />}
          onClick={() => {}}
        />
        <div className="Gigantish__chartWindow">
          <div className="Gigantish__chartContent">
            <div className="Gigantish__chartColumns">
              <GigantishChartColumns boundary={boundary} />
            </div>
            <div className="Gigantish__rows" style={{ width: '100%' }}>
              <div>
                <div className={'GigantishChartInitiative__row'}></div>
              </div>
              <div>
                <div className={'GigantishChartInitiative__row'}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ICON_SIZE = 16;
