import { CollapseClosedIcon } from '../../../../../icons/newTeamPageIcons/CollapseClosedIcon';
import { CollapseOpenedIcon } from '../../../../../icons/newTeamPageIcons/CollapseOpenedIcon';
import cx from 'classnames';
import './CollapseCardHeader.less';
import Skeleton from 'react-loading-skeleton';

interface Props {
  expanded: boolean;
  onClick: (open: boolean) => void;
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
}

export const CollapseCardHeader = ({
  expanded,
  onClick,
  children,
  className,
  loading,
}: Props) => {
  if (loading) {
    return <Skeleton className="CollapseCardHeader" />;
  }

  return (
    <div
      className={cx('CollapseCardHeader flx clickable divider', className)}
      onClick={() => onClick(!expanded)}
    >
      {children}
      <div
        className="CollapseCardHeader__icon flx flx--ai-center"
        data-intercom-target="Card expandable icon"
        data-image-ignore="true"
      >
        {expanded ? <CollapseOpenedIcon /> : <CollapseClosedIcon />}
      </div>
    </div>
  );
};
