import { gql, useMutation } from '@apollo/client';
import { EditInitiativeDocument } from '../../../../../generated/graphql';

export const useEditInitiative = () => {
  const [editInitiative, { loading, error }] = useMutation(
    EditInitiativeDocument
  );
  return { editInitiative, loading, error };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EDIT_INITIATIVE = gql`
  mutation editInitiative(
    $teamId: ID!
    $initiativeId: ID!
    $initiative: UpdateInitiativeInput!
  ) {
    editInitiative(
      teamId: $teamId
      initiativeId: $initiativeId
      initiative: $initiative
    ) {
      ...InitiativeContent
    }
  }
`;
