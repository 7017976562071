import { useQuery } from '@apollo/client';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import { GetTeamsForAccelerationBoardDocument } from '../../../../../../generated/graphql';
import { InitiativesColors } from '../../../../../../styleVars';
import { getInitiativeColor } from '../../services/initiativeStatusColor';
import './TeamContributionTeamTable.less';

type StatusSummary = {
  keyActivitiesMarkedAsMilestone: number;
  keyActivitiesNotMarkedAsMilestones: number;
  total: number;
};

type ActivitiesSummary = {
  totalNumberOfActivities: number;
  onTrackPercentage: number;
  completed: StatusSummary;
  expectedToBeCompleted: StatusSummary;
  overdue: StatusSummary;
  planned: StatusSummary;
};

type TeamSummary = {
  teamId: {
    id: string;
    domainId: { itemId: string };
  };
  activitiesSummary: ActivitiesSummary;
};

type TeamTableSummary = {
  onTrackPercentage: number;
  completed: number;
  expected: number;
  overdue: number;
  planned: number;
};

const teamTableSummaryInitialValue = {
  onTrackPercentage: 0,
  completed: 0,
  expected: 0,
  overdue: 0,
  planned: 0,
};

interface ContributionTeam {
  name: string;
  id: string;
  created: string;
  umbrellaTeam: boolean | null;
  parentTeamId?: string | null;
  children?: ContributionTeam[] | null;
  isTeamContributing: boolean;
  numberOfDescendingTeamsContributing: number;
  descendantsContributionSummary: TeamTableSummary;
  contributionSummary: TeamTableSummary;
}

function buildTeamTree(
  teams: ContributionTeam[],
  parentId: string | null = null,
  teamsSummary: TeamSummary[]
): ContributionTeam[] {
  const result: ContributionTeam[] = [];

  const filteredTeams = teams.filter((team) => team.parentTeamId === parentId);

  filteredTeams.forEach((team) => {
    const children = buildTeamTree(teams, team.id, teamsSummary);
    const teamSummary = teamsSummary.find(
      (t) => t.teamId.domainId.itemId === team.id
    );

    let node: ContributionTeam = {
      ...team,
      numberOfDescendingTeamsContributing: 0,
      children: null as ContributionTeam[] | null,
    };
    node.isTeamContributing = !!teamSummary;

    if (children.length > 0) {
      node.children = children;

      const childrenContribution = children.reduce((acc, child) => {
        node.numberOfDescendingTeamsContributing +=
          child.numberOfDescendingTeamsContributing +
          (child.isTeamContributing ? 1 : 0);
        const completed =
          acc.completed +
          child.contributionSummary.completed +
          child.descendantsContributionSummary.completed;
        const expected =
          acc.expected +
          child.contributionSummary.expected +
          child.descendantsContributionSummary.expected;
        const overdue =
          acc.overdue +
          child.contributionSummary.overdue +
          child.descendantsContributionSummary.overdue;
        const planned =
          acc.planned +
          child.contributionSummary.planned +
          child.descendantsContributionSummary.planned;
        const onTrackPercentage = Math.round(
          (completed / (expected === 0 ? 1 : expected)) * 100
        );
        return {
          onTrackPercentage,
          completed,
          expected,
          overdue,
          planned,
        };
      }, teamTableSummaryInitialValue);

      node.descendantsContributionSummary = childrenContribution;
    }
    if (teamSummary?.activitiesSummary != null) {
      node.contributionSummary = {
        onTrackPercentage: teamSummary.activitiesSummary.onTrackPercentage,
        completed: teamSummary.activitiesSummary.completed.total,
        expected: teamSummary.activitiesSummary.expectedToBeCompleted.total,
        overdue: teamSummary.activitiesSummary.overdue.total,
        planned: teamSummary.activitiesSummary.planned.total,
      };
    }

    if (
      node.isTeamContributing ||
      node.numberOfDescendingTeamsContributing > 0
    ) {
      result.push(node);
    }
  });
  return result;
}

interface Props {
  teamsSummary?: TeamSummary[];
  teamId?: string;
}

export const TeamContributionTeamTableV2 = ({
  teamsSummary,
  teamId,
}: Props) => {
  const { data } = useQuery(GetTeamsForAccelerationBoardDocument);
  const { t } = useTranslation();

  const columns: ColumnsType<ContributionTeam> = [
    {
      title: t('TeamContributionTeamTable.team'),
      dataIndex: 'name',
      key: 'id',
      className: 'flx',
      render: (text, record) => {
        return (
          <div className="flx flx--column">
            <div>{text}</div>
            {record.children && (
              <span className="txt--secondary font-size--sm">
                {t('TeamContributionTeamTable.teamsBelowContributing', {
                  count: record.numberOfDescendingTeamsContributing,
                })}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: t('common.sprintKeyActivity.statuses.completed'),
      key: 'Completed',
      align: 'center',
      width: '150px',
      render: (_, record) => {
        return (
          <div className="flx flx--column">
            <div>
              {!record.isTeamContributing ? (
                <div style={{ color: InitiativesColors.neverStarted }}>-</div>
              ) : (
                <div>{record.contributionSummary.completed}</div>
              )}
            </div>
            {record.children && (
              <div className="font-size--sm txt--secondary">
                {record.descendantsContributionSummary.completed}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t('common.sprintKeyActivity.statuses.overdue'),
      key: 'Overdue',
      align: 'center',
      width: '180px',
      render: (_, record) => {
        return (
          <div className="flx flx--column">
            <div>
              {!record.isTeamContributing ? (
                <div
                  className="no-wrap"
                  style={{ color: InitiativesColors.neverStarted }}
                >
                  {t('TeamContributionTeamTable.teamNotContributing')}
                </div>
              ) : (
                <div>{record.contributionSummary.overdue}</div>
              )}
            </div>
            {record.children && (
              <div className="font-size--sm txt--secondary">
                {record.descendantsContributionSummary.overdue}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t('TeamContributionTeamTable.doneVsPlan'),
      key: 'DoneVsPlan',
      align: 'center',
      width: '150px',
      render: (_, record) => {
        return (
          <div className="flx flx--column">
            <div>
              {!record.isTeamContributing ? (
                <div style={{ color: InitiativesColors.neverStarted }}>-</div>
              ) : (
                <div>
                  <div
                    style={{
                      color: getInitiativeColor(
                        record.contributionSummary.onTrackPercentage,
                        record.contributionSummary.expected
                      ),
                    }}
                  >
                    {record.contributionSummary.onTrackPercentage}%
                  </div>
                </div>
              )}
            </div>
            {record.children && (
              <div
                className="font-size--sm txt--secondary"
                style={{
                  color: getInitiativeColor(
                    record.descendantsContributionSummary.onTrackPercentage,
                    record.descendantsContributionSummary.expected
                  ),
                }}
              >
                {record.descendantsContributionSummary.onTrackPercentage}%
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t('TeamContributionTeamTable.upcoming'),
      key: 'Planned',
      width: '150px',
      align: 'center',
      className: 'TeamContributionTeamTable__plannedColumn',
      render: (_, record) => {
        return (
          <div className="flx flx--column">
            <div>
              {!record.isTeamContributing ? (
                <div style={{ color: InitiativesColors.neverStarted }}>-</div>
              ) : (
                <div>{record.contributionSummary.planned}</div>
              )}
            </div>
            {record.children && (
              <div className="font-size--sm txt--secondary">
                {record.descendantsContributionSummary.planned}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const teamTreeDataInitialValue: ContributionTeam[] =
    data?.teams.map((team) => ({
      ...team,
      isTeamContributing: false,
      numberOfDescendingTeamsContributing: 0,
      descendantsContributionSummary: teamTableSummaryInitialValue,
      contributionSummary: teamTableSummaryInitialValue,
    })) ?? [];

  const startWithParentId =
    data?.teams.find((t) => t.id === teamId)?.parentTeamId ?? null;

  const teamTreeData = buildTeamTree(
    teamTreeDataInitialValue,
    startWithParentId,
    teamsSummary ?? []
  );

  const topLevelTeamIds = data?.teams
    .filter((team) => !team.parentTeamId)
    .map((team) => team.id);

  const expandedRowKeys = topLevelTeamIds;

  if (!data) return null;

  return (
    <Table
      columns={columns}
      rowKey="id"
      pagination={false}
      dataSource={teamTreeData}
      defaultExpandedRowKeys={expandedRowKeys}
      rowClassName={(record) => {
        return record.children && record.children.length > 0
          ? 'clickable ml'
          : 'ml';
      }}
      sticky={true}
      scroll={{ y: 400 }}
      expandable={{
        expandRowByClick: true,
      }}
    />
  );
};
