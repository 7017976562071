import Icon from '@ant-design/icons';

const ScienceIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.33329 12C1.76663 12 1.36385 11.7472 1.12496 11.2417C0.886071 10.7361 0.944405 10.2667 1.29996 9.83333L4.99996 5.33333V1.33333H4.33329C4.1444 1.33333 3.98607 1.26944 3.85829 1.14167C3.73052 1.01389 3.66663 0.855556 3.66663 0.666667C3.66663 0.477778 3.73052 0.319444 3.85829 0.191667C3.98607 0.0638889 4.1444 0 4.33329 0H9.66663C9.85552 0 10.0138 0.0638889 10.1416 0.191667C10.2694 0.319444 10.3333 0.477778 10.3333 0.666667C10.3333 0.855556 10.2694 1.01389 10.1416 1.14167C10.0138 1.26944 9.85552 1.33333 9.66663 1.33333H8.99996V5.33333L12.7 9.83333C13.0555 10.2667 13.1138 10.7361 12.875 11.2417C12.6361 11.7472 12.2333 12 11.6666 12H2.33329ZM2.33329 10.6667H11.6666L7.66663 5.8V1.33333H6.33329V5.8L2.33329 10.6667Z" />
  </svg>
);

export const ScienceIcon = (props: any) => (
  <Icon component={ScienceIconSvg} {...props} />
);
