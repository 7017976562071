import './TeamCardUmbrella.less';

interface Props {
  name: string;
}

export const TeamCardUmbrella = ({ name }: Props) => {
  return (
    <div className="TeamCardUmbrella">
      <div className="TeamCardUmbrella__name">{name}</div>
    </div>
  );
};
