import Icon from '@ant-design/icons';

const MeditationIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.53333 11.3333C2.2 11.3333 1.91667 11.2166 1.68333 10.9833C1.45 10.75 1.33333 10.4666 1.33333 10.1333C1.33333 9.89996 1.4 9.6804 1.53333 9.47463C1.66667 9.26929 1.84444 9.12218 2.06667 9.03329L4.66667 7.99996V6.49996C4.06667 7.19996 3.36956 7.73596 2.57533 8.10796C1.78067 8.4804 0.922222 8.66663 0 8.66663V7.33329C0.755556 7.33329 1.44178 7.17774 2.05867 6.86663C2.67511 6.55552 3.23333 6.11107 3.73333 5.53329L4.63333 4.46663C4.76667 4.31107 4.92222 4.1944 5.1 4.11663C5.27778 4.03885 5.46667 3.99996 5.66667 3.99996H6.33333C6.53333 3.99996 6.72222 4.03885 6.9 4.11663C7.07778 4.1944 7.23333 4.31107 7.36667 4.46663L8.26667 5.53329C8.76667 6.11107 9.32511 6.55552 9.942 6.86663C10.5584 7.17774 11.2444 7.33329 12 7.33329V8.66663C11.0778 8.66663 10.2196 8.4804 9.42533 8.10796C8.63067 7.73596 7.93333 7.19996 7.33333 6.49996V7.99996L9.93333 9.03329C10.1556 9.12218 10.3333 9.26929 10.4667 9.47463C10.6 9.6804 10.6667 9.89996 10.6667 10.1333C10.6667 10.4666 10.55 10.75 10.3167 10.9833C10.0833 11.2166 9.8 11.3333 9.46667 11.3333H4.66667V11C4.66667 10.7111 4.76111 10.4722 4.95 10.2833C5.13889 10.0944 5.37778 9.99996 5.66667 9.99996H7.66667C7.76667 9.99996 7.84733 9.96952 7.90867 9.90863C7.96956 9.84729 8 9.76663 8 9.66663C8 9.56663 7.96956 9.48596 7.90867 9.42463C7.84733 9.36374 7.76667 9.33329 7.66667 9.33329H5.66667C5.2 9.33329 4.80556 9.4944 4.48333 9.81663C4.16111 10.1388 4 10.5333 4 11V11.3333H2.53333ZM6 3.33329C5.63333 3.33329 5.31956 3.20263 5.05867 2.94129C4.79733 2.6804 4.66667 2.36663 4.66667 1.99996C4.66667 1.63329 4.79733 1.31951 5.05867 1.05863C5.31956 0.797293 5.63333 0.666626 6 0.666626C6.36667 0.666626 6.68067 0.797293 6.942 1.05863C7.20289 1.31951 7.33333 1.63329 7.33333 1.99996C7.33333 2.36663 7.20289 2.6804 6.942 2.94129C6.68067 3.20263 6.36667 3.33329 6 3.33329Z" />
  </svg>
);

export const MeditationIcon = (props: any) => (
  <Icon component={MeditationIconSvg} {...props} />
);
