import {
  CalendarOutlined,
  ClockCircleOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayDateRange } from '../../../DisplayDateRange';
import { TeamSectionCountdown } from './teamSection/TeamSectionCountdown';
import './TeamSection.less';

interface Props {
  teamName: string;
  periodStartTime: string;
  periodEndTime: string;
  keyActivitiesCount: number;
  KAShowDueIn: boolean;
  children?: React.ReactNode;
}

export const TeamSection: FC<Props> = ({
  teamName,
  periodStartTime,
  periodEndTime,
  keyActivitiesCount,
  children,
  KAShowDueIn,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mt--l pb--xl">
      <div className="flx flx--ai-center">
        <div className="TeamSectionV2__teamName">
          <TeamOutlined className="TeamSectionV2__teamIcon" />
          {teamName}
        </div>
        <div className="TeamSectionV2__periodInfo txt--secondary bold">
          <CalendarOutlined className="mr--s" />
          {/* todo: see if this icon should be moved inside <DisplayDateRange /> 
          (check that the icon would look good in all instance, and if not make it possible to hide via prop) */}
          <DisplayDateRange
            startDate={periodStartTime}
            endDate={periodEndTime}
          />
        </div>
        {keyActivitiesCount > 0 && KAShowDueIn && (
          <div className="TeamSectionV2__periodInfo txt--secondary">
            <ClockCircleOutlined className="mr--s" />
            <TeamSectionCountdown periodEndTime={periodEndTime} />
          </div>
        )}
      </div>

      <div className="TeamSectionV2__content">
        <div className="TeamSectionV2__contentHeader">
          {t('TeamSectionV2.keyActivities')}
        </div>
        {children}
      </div>
    </div>
  );
};
