import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ArchivedIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="2 2 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="inventory_2">
      <g>
        <path
          id="inventory_2_2"
          d="M5.8 16C5.47 16 5.1875 15.8825 4.9525 15.6475C4.7175 15.4125 4.6 15.13 4.6 14.8V8.035C4.42 7.925 4.275 7.7825 4.165 7.6075C4.055 7.4325 4 7.23 4 7V5.2C4 4.87 4.1175 4.5875 4.3525 4.3525C4.5875 4.1175 4.87 4 5.2 4H14.8C15.13 4 15.4125 4.1175 15.6475 4.3525C15.8825 4.5875 16 4.87 16 5.2V7C16 7.23 15.945 7.4325 15.835 7.6075C15.725 7.7825 15.58 7.925 15.4 8.035V14.8C15.4 15.13 15.2825 15.4125 15.0475 15.6475C14.8125 15.8825 14.53 16 14.2 16H5.8ZM5.8 8.2V14.8H14.2V8.2H5.8ZM5.2 7H14.8V5.2H5.2V7ZM8.2 11.2H11.8V10H8.2V11.2Z"
        />
      </g>
    </g>
  </svg>
);

export const ArchivedIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={ArchivedIconSvg} {...props} />
);
