import { InitiativesTree } from './InitiativesTree';
import cx from 'classnames';
import {
  InitiativeNode,
  initiativeTreeWalker,
} from './services/initiativeTreeNodeHelper';
import { ArchivedIcon } from '../../../../icons/overviewIcons/ArchivedIcon';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '../../../../icons/overviewIcons/CheckIcon';
import './InitiativeMenu.less';
import { Colors } from '../../../componentLibrary/Colors';
interface Props {
  initiatives: InitiativeNode[];
  initiativeId?: string;
  teamId?: string;
}

export const InitiativeMenu = ({
  initiatives,
  initiativeId,
  teamId,
}: Props) => {
  const { t } = useTranslation();
  const teamTreeData = initiativeTreeWalker(initiatives, (node) => {
    let modifiedNode: InitiativeNode = {
      ...node,
    };

    modifiedNode.title = (
      <div
        className={cx('InitiativesTree__link flx', {
          active: node.domainId.itemId === initiativeId,
        })}
      >
        {node.tag.title}
        {node.archived && (
          <div className="flx ml--s flx--jc-center flx--ai-center">
            <Tooltip placement="topRight" title={t('InitiativeMenu.archived')}>
              <div className="InitiativeMenu__icon InitiativeMenu__icon--archived">
                <ArchivedIcon />
              </div>
            </Tooltip>
          </div>
        )}
        {node.completed.value && (
          <div className="flx ml--s flx--jc-center flx-ai-center">
            <Tooltip placement="topRight" title={t('InitiativeMenu.completed')}>
              <div className="InitiativeMenu__icon InitiativeMenu__icon--completed">
                <CheckIcon style={{ color: Colors.Status.OK_GREEN }} />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    );

    modifiedNode.link =
      node.domainId.itemId + (teamId ? `?filteredByTeamId=${teamId}` : '');

    return modifiedNode;
  });

  return teamTreeData.length > 0 ? (
    <InitiativesTree initiativeId={initiativeId} treeData={teamTreeData} />
  ) : (
    <div className="txt--secondary">-</div>
  );
};
