export type ResolveType<
  ObjectType,
  Path extends string,
  OrElse,
> = Path extends keyof ObjectType
  ? ObjectType[Path]
  : Path extends `${infer LeftSide}.${infer RightSide}`
    ? LeftSide extends keyof ObjectType
      ? ResolveType<ObjectType[LeftSide], RightSide, OrElse>
      : Path extends `${infer LeftSide}[${number}].${infer RightSide}`
        ? LeftSide extends keyof ObjectType
          ? ObjectType[LeftSide] extends Array<infer U>
            ? ResolveType<U, RightSide, OrElse>
            : OrElse
          : OrElse
        : OrElse
    : Path extends `${infer LeftSide}[${number}]`
      ? LeftSide extends keyof ObjectType
        ? ObjectType[LeftSide] extends Array<infer U>
          ? U
          : OrElse
        : OrElse
      : OrElse;

/**
 * Returns the value of a nested property inside an item
 * Example: user can access 'id', or 'parentId' inside item = { nestedObject: { id: 'myId', parentId: 'myParentId' } }
 * using getNestedItemProperty(item, 'nestedObject.id') or getNestedItemProperty(item, 'nestedObject.parentId')
 * @param item
 * @param nestedProperty the chained properties to access the nested property. Eg: 'your.nested.property'
 */
export const getNestedProperty = <
  ObjectType extends object,
  Path extends string,
  OrElse extends unknown,
>(
  obj: ObjectType,
  path: Path,
  orElse?: OrElse
): ResolveType<ObjectType, Path, OrElse> => {
  return (
    ((path as string).split('.').reduce((o: any, i) => o?.[i], obj) as any) ??
    orElse
  );
};
