import Icon from '@ant-design/icons';

const AddShoppingCartOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.33317 5.99996V3.99996H5.33317V2.66663H7.33317V0.666626H8.6665V2.66663H10.6665V3.99996H8.6665V5.99996H7.33317ZM4.6665 14.6666C4.29984 14.6666 3.98595 14.5361 3.72484 14.275C3.46373 14.0138 3.33317 13.7 3.33317 13.3333C3.33317 12.9666 3.46373 12.6527 3.72484 12.3916C3.98595 12.1305 4.29984 12 4.6665 12C5.03317 12 5.34706 12.1305 5.60817 12.3916C5.86928 12.6527 5.99984 12.9666 5.99984 13.3333C5.99984 13.7 5.86928 14.0138 5.60817 14.275C5.34706 14.5361 5.03317 14.6666 4.6665 14.6666ZM11.3332 14.6666C10.9665 14.6666 10.6526 14.5361 10.3915 14.275C10.1304 14.0138 9.99984 13.7 9.99984 13.3333C9.99984 12.9666 10.1304 12.6527 10.3915 12.3916C10.6526 12.1305 10.9665 12 11.3332 12C11.6998 12 12.0137 12.1305 12.2748 12.3916C12.536 12.6527 12.6665 12.9666 12.6665 13.3333C12.6665 13.7 12.536 14.0138 12.2748 14.275C12.0137 14.5361 11.6998 14.6666 11.3332 14.6666ZM4.6665 11.3333C4.1665 11.3333 3.78317 11.1138 3.5165 10.675C3.24984 10.2361 3.24428 9.79996 3.49984 9.36663L4.39984 7.73329L1.99984 2.66663H0.666504V1.33329H2.84984L5.68317 7.33329H10.3665L12.9498 2.66663L14.1165 3.29996L11.5332 7.96663C11.4109 8.18885 11.2498 8.36107 11.0498 8.48329C10.8498 8.60552 10.6221 8.66663 10.3665 8.66663H5.39984L4.6665 9.99996H12.6665V11.3333H4.6665Z" />
  </svg>
);

export const AddShoppingCartOutlineIcon = (props: any) => (
  <Icon component={AddShoppingCartOutlineIconSvg} {...props} />
);
