import { Card } from 'antd';
import { CSSProperties } from 'react';

import { MigCard } from '../../../mig/MigCard';
import { MigSetupTablePreviewMigFragment } from '../../../../generated/graphql';
import { gql } from '@apollo/client';

interface Props {
  teamMig: MigSetupTablePreviewMigFragment;
}

/* prevents weird overflow issue */
const style: CSSProperties = {
  width: '100px',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIG_SETUP_TABLE_PREVIEW_FRAGMENT = gql`
  fragment MigSetupTablePreviewMig on MigV2 {
    ...MigCardMig
  }
`;

export const MigSetupTablePreview = ({ teamMig }: Props) => {
  return (
    <div className="flx">
      <div className="flx--1 pa" style={style}>
        <Card styles={{ body: { padding: 0 } }}>
          <MigCard mig={teamMig} showDescription={false} graphHeight={120} />
        </Card>
      </div>
      <div className="flx--1 pa" style={style}>
        <Card styles={{ body: { padding: 0 } }}>
          <MigCard mig={teamMig} showDescription={true} graphHeight={120} />
        </Card>
      </div>
    </div>
  );
};
