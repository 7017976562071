import { gql, useQuery } from '@apollo/client';
import { Select } from 'antd';
import { GetGlobalMigsForFilterDocument } from '../../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string;
  onChange: (migId: string) => void;
}

export const GlobalMigFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(GetGlobalMigsForFilterDocument);

  const globalMigs = data?.globalMigs.globalMigs ?? [];
  return (
    <Select
      style={{ width: 250 }}
      value={value}
      onChange={onChange}
      options={[{ label: t('GlobalMigFilter.all'), value: 'ALL_MIGS' }].concat(
        globalMigs.map((mig) => ({
          label: mig.name,
          value: mig.id,
        }))
      )}
      loading={loading}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_GLOBAL_MIGS = gql`
  query getGlobalMigsForFilter {
    globalMigs {
      globalMigs {
        id
        name
        domainId {
          itemId
          teamId
        }
      }
    }
  }
`;
