import Icon from '@ant-design/icons';

const AccountTreeOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.00016 12V10H6.3335V3.33333H5.00016V5.33333H0.333496V0H5.00016V2H9.00016V0H13.6668V5.33333H9.00016V3.33333H7.66683V8.66667H9.00016V6.66667H13.6668V12H9.00016ZM10.3335 4H12.3335V1.33333H10.3335V4ZM10.3335 10.6667H12.3335V8H10.3335V10.6667ZM1.66683 4H3.66683V1.33333H1.66683V4Z" />
  </svg>
);

export const AccountTreeOutlineIcon = (props: any) => (
  <Icon component={AccountTreeOutlineIconSvg} {...props} />
);
