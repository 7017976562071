import { Modal, Spin } from 'antd';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { RealignMilestoneModalContent } from './RealignMilestoneModalContent';
import { ErrorPage } from '../../../../../../../../../components/ErrorPage';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
  milestoneId?: string;
  onClose?: () => void;
}

export const RealignMilestoneModal = ({
  milestoneId,
  visible,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={visible}
      footer={null}
      style={{ top: 20 }}
      onCancel={onClose}
      title={<h3>{t('RealignMilestoneModal.title')}</h3>}
      destroyOnClose
    >
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Suspense fallback={<Spin />}>
          {milestoneId && (
            <RealignMilestoneModalContent
              onClose={onClose}
              milestoneId={milestoneId}
            />
          )}
        </Suspense>
      </ErrorBoundary>
    </Modal>
  );
};
