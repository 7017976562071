import { useWizardNavigation } from '../../../../../../components/WizardNavigationProvider';
import { WizardSteps } from './components/WizardSteps';

export const AiWizard = () => {
  const wizard = useWizardNavigation();

  return (
    <div className="">
      <WizardSteps />
      {wizard.activeStep.component()}
    </div>
  );
};
