import dayjs from 'dayjs';
import cx from 'classnames';
import './MonthCell.less';

interface Props {
  startOfMonth: dayjs.Dayjs;
  children?: React.ReactNode;
}

const today = dayjs();

export const MonthCell = ({ startOfMonth, children }: Props) => {
  return (
    <div
      className={cx('MonthCell', {
        'MonthCell--isCurrent': startOfMonth.isSame(today, 'month'),
      })}
    >
      {children}
    </div>
  );
};
