import cx from 'classnames';
import './SmallCollapseCard.less';
import { SmallCollapseOpenedIcon } from '../../../../../../../../../icons/newTeamPageIcons/SmallCollapseOpenedIcon';
import { SmallCollapseClosedIcon } from '../../../../../../../../../icons/newTeamPageIcons/SmallCollapseClosedIcon';

interface Props {
  title: string;
  children: React.ReactNode;
  visible: boolean;
  onClick: (visible: boolean) => void;
}

export const SmallCollapseCard = ({
  title,
  children,
  visible,
  onClick,
}: Props) => {
  return (
    <div className="borderBottom">
      <div
        className="flx pa--l flx--ai-center flx--1 flx--jc-space-between clickable"
        onClick={() => onClick(!visible)}
      >
        <h3>{title}</h3>
        <div className="SmallCollapseCard__icon mr--s">
          {visible ? <SmallCollapseOpenedIcon /> : <SmallCollapseClosedIcon />}
        </div>
      </div>
      <div
        className={cx('SmallCollapseCard__grid', {
          open: visible,
        })}
      >
        <div className="SmallCollapseCard__content">
          <div className="pa--l">{children}</div>
        </div>
      </div>
    </div>
  );
};
