import './TeamCardWithPerf.less';
import { useTranslation } from 'react-i18next';
import { TrendArrow } from '../../../../../components/TrendArrow';
import { roundToFloor } from '../../../../../services/roundNumbersHelper';
import { Link } from 'react-router-dom';

interface Props {
  teamId: string;
  name: string;
  akpiPercent?: number | null;
  akpiDelta?: number | null;
  migPercent?: number | null;
}

const getProgressClass = (progress?: number | null) => {
  if (progress == null) return '';

  if (progress >= 100) return 'success';
  if (progress >= 80) return 'warning';
  return 'danger';
};

export const TeamCardWithPerf = ({
  teamId,
  name,
  akpiPercent,
  migPercent,
  akpiDelta,
}: Props) => {
  const { t } = useTranslation();

  const migPercentFloored = roundToFloor(migPercent);
  const akpiPercentFloored = roundToFloor(akpiPercent);
  return (
    <Link to={`/team/${teamId}`} className="TeamCardWithPerf">
      <div className="TeamCardWithPerf__main flx flx--ai-center">
        <h5 className="TeamCardWithPerf__name mb--none">{name}</h5>
      </div>

      <div className="TeamCardWithPerf__percentages">
        <div
          className={
            'TeamCardWithPerf__percentage ' +
            getProgressClass(migPercentFloored)
          }
        >
          <div className="TeamCardWithPerf__percentage-type">
            {t('TeamCardWithPerf.migLabel')}
          </div>
          {displayProgress(migPercentFloored)}
        </div>
        <div
          className={
            'TeamCardWithPerf__percentage ' +
            getProgressClass(akpiPercentFloored)
          }
        >
          <div className="TeamCardWithPerf__percentage-type">
            {t('TeamCardWithPerf.akpiLabel')}
          </div>
          <div>
            {displayProgress(akpiPercentFloored)}{' '}
            {akpiDelta != null && (
              <span className="TeamCardWithPerf__arrow">
                <TrendArrow delta={akpiDelta} />
              </span>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

const displayProgress = (progress?: number | null) =>
  progress == null ? '-' : `${progress}%`;
