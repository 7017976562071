import { gql, useQuery } from '@apollo/client';
import { TenantTeamsDocument } from '../generated/graphql';
import { stringSort } from '../services/stringSort';

export interface HierarchicalTeam {
  name: string;
  id: string;
  created: string;
  umbrellaTeam: boolean | null;
  parentTeamId?: string | null;
  children?: HierarchicalTeam[] | null;
  descendantIds?: string[] | null;
}

export function findHierarchicalTeamById(
  teams: HierarchicalTeam[],
  id: string
): HierarchicalTeam | null {
  for (let i = 0; i < teams.length; i++) {
    const team = teams[i];
    if (team.id === id) {
      return team;
    }
    if (team.children) {
      const result = findHierarchicalTeamById(team.children, id);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

export function buildHierarchicalTeamTree(
  teams: HierarchicalTeam[],
  parentId: string | null = null
): HierarchicalTeam[] {
  const result: HierarchicalTeam[] = [];

  const filteredTeams = teams.filter((team) => team.parentTeamId == parentId);

  filteredTeams.forEach((team) => {
    const children = buildHierarchicalTeamTree(teams, team.id);

    let node = {
      ...team,
      children: null as HierarchicalTeam[] | null,
      descendantIds: null as string[] | null,
    };

    if (children.length > 0) {
      node.children = children;
      node.descendantIds = children.flatMap((c) => [
        c.id,
        ...(c.descendantIds ?? []),
      ]);
    }

    result.push(node);
  });
  return result;
}

export const useTeams = (tenantId?: string) => {
  const { data, loading, error } = useQuery(TenantTeamsDocument, {
    variables: { tenantId },
  });

  const teams = data?.teams.filter((t) => !t.umbrellaTeam) ?? [];

  // we shallow clone `teams` to prevent the sort from mutating `teamAssociations` in the gql cache
  const sortedTeams = [...teams].sort((a, b) => {
    const levelDelta = a.teamHierarchy.length - b.teamHierarchy.length;

    if (levelDelta !== 0) return levelDelta;
    return stringSort(a.name, b.name);
  });

  return {
    loading,
    error,
    teams: sortedTeams,
    teamsWithUmbrellaTeams: data?.teams ?? [],
  };
};

export const useHierarchicalTeams = (tenantId?: string) => {
  const { teamsWithUmbrellaTeams, loading, error } = useTeams(tenantId);

  const hierarchicalTeamTree = buildHierarchicalTeamTree(
    teamsWithUmbrellaTeams
  );

  return {
    loading,
    error,
    hierarchicalTeams: hierarchicalTeamTree ?? [],
  };
};

export const TENANT_TEAMS = gql`
  fragment TenantTeam on Team {
    name
    id
    created
    umbrellaTeam
    parentTeamId
    teamHierarchy {
      id
    }
  }
  query tenantTeams($tenantId: ID) {
    teams(tenantId: $tenantId, teamFilter: { active: true }) {
      ...TenantTeam
    }
  }
`;
