import { DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Modal, Spin, Tooltip } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../../components/Button';
import { ErrorAlert } from '../../../../../components/ErrorAlert';
import {
  DeleteTeamTagDocument,
  GetTeamTagsDocument,
  TeamTag,
} from '../../../../../generated/graphql';
import { showNotification } from '../../../../../services/fetchNotificationProperties';

interface Props {
  teamId: string;
  tagId: string;
  tags: TeamTag[];
}

export const DeleteLabelModal = ({ teamId, tagId, tags }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const [deleteTeamTagById, { loading, error }] = useMutation(
    DeleteTeamTagDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: <strong>{t('Tags.tagRemoved')}</strong>,
        });
      },
      onError: () => {
        showNotification('error', {
          message: <ErrorAlert error={error} />,
        });
      },
      refetchQueries: () => [
        {
          query: GetTeamTagsDocument,
          variables: { teamId },
        },
      ],
    }
  );

  const handleDeleteTeamTag = () => {
    if (tags.find((l) => l.id === tagId)) {
      deleteTeamTagById({
        variables: {
          teamId,
          tagId,
        },
      });
    }
  };
  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <>
          <Tooltip title={t('Tags.deleteTag')}>
            <Btn
              type="text"
              className="txt--secondary"
              icon={<DeleteOutlined />}
              onClick={() => setOpenModal(true)}
            />
          </Tooltip>
          <Modal
            open={openModal}
            onCancel={() => setOpenModal(false)}
            onOk={handleDeleteTeamTag}
          >
            {t('Tags.tagRemoveConfirmation')}
          </Modal>
        </>
      )}
    </>
  );
};
