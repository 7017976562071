import Icon from '@ant-design/icons';

const RobotFaceIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.6665 9.00004C2.11095 9.00004 1.63873 8.8056 1.24984 8.41671C0.860948 8.02782 0.666504 7.5556 0.666504 7.00004C0.666504 6.44449 0.860948 5.97226 1.24984 5.58337C1.63873 5.19449 2.11095 5.00004 2.6665 5.00004V3.66671C2.6665 3.30004 2.79706 2.98615 3.05817 2.72504C3.31928 2.46393 3.63317 2.33337 3.99984 2.33337H5.99984C5.99984 1.77782 6.19428 1.3056 6.58317 0.916707C6.97206 0.527818 7.44428 0.333374 7.99984 0.333374C8.55539 0.333374 9.02762 0.527818 9.4165 0.916707C9.80539 1.3056 9.99984 1.77782 9.99984 2.33337H11.9998C12.3665 2.33337 12.6804 2.46393 12.9415 2.72504C13.2026 2.98615 13.3332 3.30004 13.3332 3.66671V5.00004C13.8887 5.00004 14.3609 5.19449 14.7498 5.58337C15.1387 5.97226 15.3332 6.44449 15.3332 7.00004C15.3332 7.5556 15.1387 8.02782 14.7498 8.41671C14.3609 8.8056 13.8887 9.00004 13.3332 9.00004V11.6667C13.3332 12.0334 13.2026 12.3473 12.9415 12.6084C12.6804 12.8695 12.3665 13 11.9998 13H3.99984C3.63317 13 3.31928 12.8695 3.05817 12.6084C2.79706 12.3473 2.6665 12.0334 2.6665 11.6667V9.00004ZM5.99984 7.66671C6.27762 7.66671 6.51373 7.56949 6.70817 7.37504C6.90262 7.1806 6.99984 6.94449 6.99984 6.66671C6.99984 6.38893 6.90262 6.15282 6.70817 5.95837C6.51373 5.76393 6.27762 5.66671 5.99984 5.66671C5.72206 5.66671 5.48595 5.76393 5.2915 5.95837C5.09706 6.15282 4.99984 6.38893 4.99984 6.66671C4.99984 6.94449 5.09706 7.1806 5.2915 7.37504C5.48595 7.56949 5.72206 7.66671 5.99984 7.66671ZM9.99984 7.66671C10.2776 7.66671 10.5137 7.56949 10.7082 7.37504C10.9026 7.1806 10.9998 6.94449 10.9998 6.66671C10.9998 6.38893 10.9026 6.15282 10.7082 5.95837C10.5137 5.76393 10.2776 5.66671 9.99984 5.66671C9.72206 5.66671 9.48595 5.76393 9.2915 5.95837C9.09706 6.15282 8.99984 6.38893 8.99984 6.66671C8.99984 6.94449 9.09706 7.1806 9.2915 7.37504C9.48595 7.56949 9.72206 7.66671 9.99984 7.66671ZM5.33317 10.3334H10.6665V9.00004H5.33317V10.3334ZM3.99984 11.6667H11.9998V3.66671H3.99984V11.6667Z" />
  </svg>
);

export const RobotFaceIcon = (props: any) => (
  <Icon component={RobotFaceIconSvg} {...props} />
);
