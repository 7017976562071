import { gql, useQuery } from '@apollo/client';
import { GetTeamTagsDocument } from '../generated/graphql';

export const useGetTags = (teamId: string) => {
  const { data, loading, error } = useQuery(GetTeamTagsDocument, {
    variables: { teamId },
    fetchPolicy: 'cache-and-network',
  });
  const tags = data?.teamTags.tags ?? [];
  return { tags, loading, error };
};

export const GET_TEAM_LABELS = gql`
  fragment TeamTag on TeamTag {
    id
    teamId
    name
    backgroundColor
    active
    createdAt
  }
  query GetTeamTags($teamId: ID!) {
    teamTags(teamId: $teamId) {
      tags {
        ...TeamTag
      }
    }
  }
`;
export const CREATE_LABEL = gql`
  mutation createTeamTag($teamId: ID!, $teamTagInput: TeamTagInput!) {
    createTeamTag(teamId: $teamId, teamTagInput: $teamTagInput) {
      name
      backgroundColor
      id
    }
  }
`;
export const DELETE_TEAM_LABEL = gql`
  mutation deleteTeamTag($teamId: ID!, $tagId: ID!) {
    deleteTeamTag(teamId: $teamId, tagId: $tagId) {
      name
      backgroundColor
    }
  }
`;
export const UPDATE_TEAM_LABEL = gql`
  mutation updateTeamTag(
    $teamId: ID!
    $tagId: ID!
    $updateTagInput: TeamTagPartialInput!
  ) {
    updateTeamTag(
      teamId: $teamId
      tagId: $tagId
      updateTagInput: $updateTagInput
    ) {
      name
      backgroundColor
    }
  }
`;
