import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import './StatusTag.less';
import { MilestoneStatus, MitemStatus } from '../generated/graphql';
import { match, P } from 'ts-pattern';
import { CheckIcon } from '../icons/overviewIcons/CheckIcon';
import { WarningIcon } from '../icons/performanceIcons/WarningIcon';
import { UpcomingEventIcon } from '../icons/overviewIcons/UpcomingEventIcon';
import { ArchiveIcon } from '../icons/initiativeIcons/ArchiveIcon';

interface Props {
  status: MitemStatus | MilestoneStatus;
  showIcon?: boolean;
  compact?: boolean;
}

export const StatusTag = ({ status, compact, showIcon = false }: Props) => {
  const { t } = useTranslation();

  const statusTag = match(status)
    .with(MitemStatus.ACTIVE, () => ({
      icon: null,
      text: t('StatusTag.ACTIVE'),
      className: 'StatusTag--ACTIVE',
    }))
    .with(P.union(MitemStatus.COMPLETED, MilestoneStatus.COMPLETED), () => ({
      icon: CheckIcon,
      text: t('StatusTag.COMPLETED'),
      className: 'StatusTag--COMPLETED',
    }))
    .with(P.union(MitemStatus.OVERDUE, MilestoneStatus.OVERDUE), () => ({
      icon: WarningIcon,
      text: t('StatusTag.OVERDUE'),
      className: 'StatusTag--OVERDUE',
    }))
    .with(P.union(MilestoneStatus.UPCOMING), () => ({
      icon: UpcomingEventIcon,
      text: t('StatusTag.UPCOMING'),
      className: 'StatusTag--PLANNED',
    }))
    .with(P.union(MitemStatus.PLANNED), () => ({
      icon: UpcomingEventIcon,
      text: t('StatusTag.PLANNED'),
      className: 'StatusTag--PLANNED',
    }))
    .with(MitemStatus.POSTPONED, () => ({
      icon: UpcomingEventIcon,
      text: t('StatusTag.POSTPONED'),
      className: 'StatusTag--POSTPONED',
    }))
    .with(MitemStatus.ARCHIVED, () => ({
      icon: ArchiveIcon,
      text: t('StatusTag.ARCHIVED'),
      className: 'StatusTag--ARCHIVED',
    }))
    .with(MitemStatus.NOT_COMPLETED, () => ({
      icon: UpcomingEventIcon,
      text: t('StatusTag.NOT_COMPLETED'),
      className: 'StatusTag--NOT_COMPLETED',
    }))
    .exhaustive();

  return (
    <div
      className={cx('StatusTag', statusTag.className, {
        'StatusTag--compact': compact,
      })}
    >
      {showIcon && statusTag.icon && <statusTag.icon className="mr--s" />}
      {statusTag.text}
    </div>
  );
};
