import { ComponentType } from 'react';
import './OverviewStatCard.less';

type IconProps = {
  style: { fontSize: string | number };
};
interface Props {
  icon: {
    SvgIcon: ComponentType<IconProps>;
    color: string;
    backgroundColor?: string;
  };
  cardBackgroundColor?: string;
  title: string;
  numberOfActivities?: number | string;
}

export const OverviewStatCard = ({
  icon,
  title,
  numberOfActivities,
  cardBackgroundColor,
}: Props) => {
  return (
    <div
      style={{ backgroundColor: cardBackgroundColor }}
      className="OverviewStatCard flx flx--ai-center"
    >
      <div
        style={{
          backgroundColor: icon.backgroundColor ?? icon.color + 10,
          color: icon.color,
        }}
        className="OverviewStatCard__iconFrame mr--l"
      >
        <div className="center-content">
          <icon.SvgIcon style={{ fontSize: 20 }} />
        </div>
      </div>
      <div className="flx--1 flx flx--jc-space-between bold">
        <div className="mr">{title}</div>
        <div
          className="no-wrap flx flx--column flx--jc-center"
          style={{ color: icon.color }}
        >
          {numberOfActivities}
        </div>
      </div>
    </div>
  );
};
