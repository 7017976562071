export interface Period {
  label: string;
  targetDate: string;
  goal?: number | null;
  actual?: number | null;
  historic?: number | null;
}

interface CustomAxisTickProps {
  lineData: Period[];
  [key: string]: any; // a buch of props (e.g. x,y, index) are added by recharts (probably via cloneElement) and since we don't want to send them ourselves as props we cannot add them to the interface explicitly
}

export const CustomAxisTick = (props: CustomAxisTickProps) => {
  const { x, y, index, lineData } = props;
  if (index === 0 || index === lineData.length - 1) {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          fontSize="12"
          x={0}
          y={0}
          dy={16}
          textAnchor={index === 0 ? 'middle' : 'end'}
          fill="#888"
        >
          {lineData[props.index]?.label}
        </text>
      </g>
    );
  }
  return null;
};
