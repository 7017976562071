export const groupBy = <T extends {}>(
  arr: T[],
  keySelector: (item: T) => string
) => {
  const map: Record<string, T[]> = {};
  for (const item of arr) {
    const key = keySelector(item);
    if (!map[key]) {
      map[key] = [];
    }
    map[key].push(item);
  }
  return map;
};
