import { EditOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../../components/Button';
import { useGetTags } from '../../../../../hooks/useGetTags';
import { modalType } from './AddLabelModal';
import { AddOrUpdateTagModal } from './AddOrUpdateTagModal';

interface Props {
  tagId: string;
  teamId: string;
}

export const UpdateTagModal = ({ tagId, teamId }: Props) => {
  const { t } = useTranslation();
  const { tags } = useGetTags(teamId);
  const [tagUpdatedName, setTagUpdatedName] = useState('');
  const [tagUpdatedBackgroundColor, setTagUpdatedBackgroundColor] =
    useState('');
  const [openEditTagModal, setOpenEditTagModal] = useState(false);

  const openModal = () => {
    const tagToBeUpdated = tags.find((l) => l.id === tagId);
    if (tagToBeUpdated) {
      setTagUpdatedName(tagToBeUpdated.name);
      setTagUpdatedBackgroundColor(tagToBeUpdated?.backgroundColor);
    }
    setOpenEditTagModal(true);
  };

  const closeEditLabelModal = () => {
    setOpenEditTagModal(false);
  };
  return (
    <>
      <Tooltip title={t('Tags.editTag')}>
        <Btn
          type="text"
          className="txt--secondary"
          icon={<EditOutlined />}
          onClick={openModal}
        />
      </Tooltip>
      <AddOrUpdateTagModal
        teamId={teamId}
        tagId={tagId}
        visible={openEditTagModal}
        onCancel={closeEditLabelModal}
        onCompleted={closeEditLabelModal}
        title={t('Tags.editTag')}
        currentName={tagUpdatedName}
        currentBackgroundColor={tagUpdatedBackgroundColor}
        modalActionType={modalType.modifyTag}
      />
    </>
  );
};
