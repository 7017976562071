import Icon from '@ant-design/icons';

const KeyOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 8"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.6665 5.33333C4.29984 5.33333 3.98595 5.20278 3.72484 4.94167C3.46373 4.68056 3.33317 4.36667 3.33317 4C3.33317 3.63333 3.46373 3.31944 3.72484 3.05833C3.98595 2.79722 4.29984 2.66667 4.6665 2.66667C5.03317 2.66667 5.34706 2.79722 5.60817 3.05833C5.86928 3.31944 5.99984 3.63333 5.99984 4C5.99984 4.36667 5.86928 4.68056 5.60817 4.94167C5.34706 5.20278 5.03317 5.33333 4.6665 5.33333ZM4.6665 8C3.55539 8 2.61095 7.61111 1.83317 6.83333C1.05539 6.05556 0.666504 5.11111 0.666504 4C0.666504 2.88889 1.05539 1.94444 1.83317 1.16667C2.61095 0.388889 3.55539 0 4.6665 0C5.41095 0 6.08595 0.183333 6.6915 0.55C7.29706 0.916667 7.77761 1.4 8.13317 2H13.9998L15.9998 4L12.9998 7L11.6665 6L10.3332 7L8.9165 6H8.13317C7.77761 6.6 7.29706 7.08333 6.6915 7.45C6.08595 7.81667 5.41095 8 4.6665 8ZM4.6665 6.66667C5.28873 6.66667 5.83595 6.47778 6.30817 6.1C6.78039 5.72222 7.09428 5.24444 7.24984 4.66667H9.33317L10.2998 5.35L11.6665 4.33333L12.8498 5.25L14.0998 4L13.4332 3.33333H7.24984C7.09428 2.75556 6.78039 2.27778 6.30817 1.9C5.83595 1.52222 5.28873 1.33333 4.6665 1.33333C3.93317 1.33333 3.30539 1.59444 2.78317 2.11667C2.26095 2.63889 1.99984 3.26667 1.99984 4C1.99984 4.73333 2.26095 5.36111 2.78317 5.88333C3.30539 6.40556 3.93317 6.66667 4.6665 6.66667Z" />
  </svg>
);

export const KeyOutlineIcon = (props: any) => (
  <Icon component={KeyOutlineIconSvg} {...props} />
);
