import Icon from '@ant-design/icons';

const ArchiveIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.97153 13.4001C1.59057 13.4001 1.26692 13.2666 1.00055 12.9996C0.733583 12.7333 0.600098 12.4096 0.600098 12.0287V3.0001C0.600098 2.82212 0.635145 2.64749 0.705241 2.47621C0.774726 2.30494 0.873164 2.1556 1.00055 2.02821L2.02821 1.00055C2.1556 0.873164 2.30494 0.774726 2.47621 0.705241C2.64749 0.635145 2.82212 0.600098 3.0001 0.600098H11.0001C11.1781 0.600098 11.3527 0.635145 11.524 0.705241C11.6953 0.774726 11.8446 0.873164 11.972 1.00055L12.9996 2.02821C13.127 2.1556 13.2255 2.30494 13.295 2.47621C13.3651 2.64749 13.4001 2.82212 13.4001 3.0001V12.0287C13.4001 12.4096 13.2666 12.7333 12.9996 12.9996C12.7333 13.2666 12.4096 13.4001 12.0287 13.4001H1.97153ZM2.08581 2.88581H11.9144L11.0001 1.97153H3.0001L2.08581 2.88581ZM1.97153 4.25724V12.0287H12.0287V4.25724H1.97153ZM7.0001 10.8858L9.74296 8.14295L8.77198 7.17198L7.68581 8.25724V5.4001H6.31438V8.25724L5.22821 7.17198L4.25724 8.14295L7.0001 10.8858Z" />
  </svg>
);

export const ArchiveIcon = (props: any) => (
  <Icon component={ArchiveIconSvg} {...props} />
);
