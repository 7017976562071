import Icon from '@ant-design/icons';

const BugOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.99984 12C5.27762 12 4.60828 11.8222 3.99184 11.4667C3.37495 11.1111 2.88873 10.6222 2.53317 10H0.666504V8.66667H2.0665C2.03317 8.44444 2.01384 8.22222 2.0085 8C2.00273 7.77778 1.99984 7.55556 1.99984 7.33333H0.666504V6H1.99984C1.99984 5.77778 2.00273 5.55556 2.0085 5.33333C2.01384 5.11111 2.03317 4.88889 2.0665 4.66667H0.666504V3.33333H2.53317C2.68873 3.07778 2.86384 2.83889 3.0585 2.61667C3.25273 2.39444 3.47762 2.2 3.73317 2.03333L2.6665 0.933333L3.59984 0L5.03317 1.43333C5.34428 1.33333 5.66095 1.28333 5.98317 1.28333C6.30539 1.28333 6.62206 1.33333 6.93317 1.43333L8.39984 0L9.33317 0.933333L8.23317 2.03333C8.48873 2.2 8.71939 2.39156 8.92517 2.608C9.1305 2.82489 9.31095 3.06667 9.46651 3.33333H11.3332V4.66667H9.93317C9.9665 4.88889 9.98606 5.11111 9.99184 5.33333C9.99717 5.55556 9.99984 5.77778 9.99984 6H11.3332V7.33333H9.99984C9.99984 7.55556 9.99717 7.77778 9.99184 8C9.98606 8.22222 9.9665 8.44444 9.93317 8.66667H11.3332V10H9.46651C9.11095 10.6222 8.62495 11.1111 8.0085 11.4667C7.39161 11.8222 6.72206 12 5.99984 12ZM5.99984 10.6667C6.73317 10.6667 7.36095 10.4056 7.88317 9.88333C8.40539 9.36111 8.6665 8.73333 8.6665 8V5.33333C8.6665 4.6 8.40539 3.97222 7.88317 3.45C7.36095 2.92778 6.73317 2.66667 5.99984 2.66667C5.2665 2.66667 4.63873 2.92778 4.1165 3.45C3.59428 3.97222 3.33317 4.6 3.33317 5.33333V8C3.33317 8.73333 3.59428 9.36111 4.1165 9.88333C4.63873 10.4056 5.2665 10.6667 5.99984 10.6667ZM4.6665 8.66667H7.33317V7.33333H4.6665V8.66667ZM4.6665 6H7.33317V4.66667H4.6665V6Z" />
  </svg>
);

export const BugOutlineIcon = (props: any) => (
  <Icon component={BugOutlineIconSvg} {...props} />
);
