import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  ArchiveMilestoneModalContent_MutationDocument,
  ArchiveMilestoneModalContent_QueryDocument,
} from '../../../../../../../../../generated/graphql';
import { howweErrorParser } from '../../../../../../../../../services/howweErrorParser';
import { showNotification } from '../../../../../../../../../services/fetchNotificationProperties';
import { InitiativesColors } from '../../../../../../../../../styleVars';
import { Divider } from 'antd';
import { Btn } from '../../../../../../../../../components/Button';
import { MarkAsArchivedIcon } from '../../../../../../../../../components/initiatives/InitiativeActionsDropdown/icons/MarkAsArchivedIcon';
import { ArchiveMilestoneModalContentSkeleton } from './ArchiveMilestoneModalContent.skeleton';

interface Props {
  milestoneId: string;
  onClose?: () => void;
}

export const ArchiveMilestoneModalContent = ({
  milestoneId,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(
    ArchiveMilestoneModalContent_QueryDocument,
    { variables: { milestoneId }, fetchPolicy: 'network-only' }
  );

  const [markAsCompleted, { loading }] = useMutation(
    ArchiveMilestoneModalContent_MutationDocument,
    {
      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: t('ArchiveMilestoneModalContent.saveError'),
          description: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
      onCompleted: () => {
        showNotification('success', {
          message: t('ArchiveMilestoneModalContent.saveSuccess'),
        });
        onClose?.();
      },
    }
  );

  const handleMarkAsCompleted = () => {
    markAsCompleted({
      variables: {
        milestoneId: milestoneId,
        milestone: {
          archived: true,
          rev: data.milestone.rev,
        },
      },
    });
  };

  return (
    <div className="text-c flx flx--column">
      <h3>{t('ArchiveMilestoneModalContent.title')}</h3>
      {data.milestone.name}
      <div className="mt">
        <MarkAsArchivedIcon
          style={{ color: InitiativesColors.orange, fontSize: 70 }}
        />
      </div>
      <Divider />
      <div>
        <Btn loading={loading} onClick={handleMarkAsCompleted} type="primary">
          {t('ArchiveMilestoneModalContent.markAsArchived')}
        </Btn>
      </div>
    </div>
  );
};

ArchiveMilestoneModalContent.Skeleton = ArchiveMilestoneModalContentSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ARCHIVE_MILESTONE = gql`
  mutation archiveMilestoneModalContent_mutation(
    $milestoneId: ID!
    $milestone: MilestoneUpdateInput!
  ) {
    updateMilestone(milestoneId: $milestoneId, milestone: $milestone) {
      id
      name
      rev
      metadata {
        archived
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MILESTONE_FOR_MARK_AS_COMPLETE = gql`
  query archiveMilestoneModalContent_query($milestoneId: ID!) {
    milestone(milestoneId: $milestoneId) {
      id
      name
      rev
      metadata {
        archived
      }
    }
  }
`;
