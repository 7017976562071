import { useTranslation } from 'react-i18next';
import { PerformanceTag } from '../../../../../../../../components/PerformanceTag';
import { useKeyActivityPerformance } from '../../../../../../../../hooks/useKeyActivityPerformanceQuery';
import './WeeklyActivityCard.less';

interface WeeklyKeyActivityCardProps {
  name: string;
  id: string;
  teamId: string;
}

export const WeeklyKeyActivityCard: React.FC<WeeklyKeyActivityCardProps> = (
  props
) => {
  const { name, id, teamId } = props;
  const { t } = useTranslation();
  const { data } = useKeyActivityPerformance(teamId, id);
  return (
    <div className="KaCard KaCard--weekly">
      <p className="KaCard__title">{name}</p>
      <div className="KaCard__fulfillment--wrapper">
        <span>{t('MigDrawerCard.goalFulfillment')}</span>
        <PerformanceTag value={data?.targetFulfillment} />
      </div>
    </div>
  );
};
