import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Cell, Label, Pie, PieChart } from 'recharts';
import { mitemStatusColor } from '../../../../../../../../../../../../styleVars';
import { BarChartLegend } from '../../../../common/BarChartLegend';
import { gql } from '@apollo/client';
import { SprintPieChart_MitemSprintFragment } from '../../../../../../../../../../../../generated/graphql';

interface Props {
  data?: {
    completed: number;
    active: number;
    overdue: number;
    notCompleted: number;
    planned: number;
  };
  sprintData?: SprintPieChart_MitemSprintFragment | null;
}

export const SprintPieChart = ({ data, sprintData }: Props) => {
  const { t } = useTranslation();
  const pieData = [
    {
      value: data?.completed,
      color: '#52C41A',
    },
    {
      value: data?.active,
      color: mitemStatusColor.ACTIVE,
    },
    {
      value: data?.overdue,
      color: mitemStatusColor.OVERDUE,
    },
    {
      value: data?.notCompleted,
      color: mitemStatusColor.NOT_COMPLETED,
    },
    {
      value: data?.planned,
      color: mitemStatusColor.PLANNED,
    },
  ];
  const today = dayjs();
  const sprintHasEnded =
    sprintData?.endDate && dayjs(sprintData.endDate).isBefore(today, 'day');
  const isPlanned = !sprintData?.locked;

  const totalSkaInSprint =
    (data?.active ?? 0) +
    (data?.completed ?? 0) +
    (data?.overdue ?? 0) +
    (data?.planned ?? 0) +
    (data?.notCompleted ?? 0);

  if (!data || totalSkaInSprint === 0)
    return (
      <div style={{ height: 200 }} className="center-content">
        {t('SprintPieChart.noSprintInfo')}
      </div>
    );

  return (
    <div className="flx flx--ai-center mt">
      <PieChart width={220} height={200}>
        <Pie
          data={pieData}
          cx={85}
          cy={100}
          innerRadius={70}
          outerRadius={90}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          <Label position="center" style={{ fontSize: 29 }}>
            {totalSkaInSprint}
          </Label>
          {pieData.map((item, index) => (
            <Cell key={`cell-${index}`} fill={item.color} />
          ))}
        </Pie>
      </PieChart>
      <div>
        <BarChartLegend
          className="mb mt"
          title={t('SprintPieChart.completed')}
          color={'#52C41A'}
          value={data?.completed}
        />
        {!sprintHasEnded && (
          <BarChartLegend
            className="mb"
            title={t('common.active')}
            value={data?.active}
            color={mitemStatusColor.ACTIVE}
          />
        )}

        {sprintHasEnded && sprintData?.locked ? (
          <BarChartLegend
            className="mb"
            title={t('SprintPieChart.notCompleted')}
            value={data?.notCompleted}
            color={mitemStatusColor.NOT_COMPLETED}
          />
        ) : (
          <BarChartLegend
            className="mb"
            title={t('SprintPieChart.overdue')}
            value={data?.overdue}
            color={mitemStatusColor.OVERDUE}
          />
        )}
        {isPlanned && (
          <BarChartLegend
            title={t('SprintPieChart.planned')}
            value={data?.planned}
            color={mitemStatusColor.PLANNED}
          />
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_SUMMARY = gql`
  fragment SprintPieChart_MitemSprint on MitemSprint {
    id
    endDate
    locked
  }
`;
