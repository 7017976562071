import Icon from '@ant-design/icons';

const InitiativeDetailsIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.79541 11.25V9.75H8.79541V11.25H0.79541ZM0.79541 7.5V6H4.79541V7.5H0.79541ZM0.79541 3.75V2.25H4.79541V3.75H0.79541ZM15.7334 11.25L12.5454 8.062C12.2261 8.28467 11.8754 8.455 11.4934 8.573C11.1114 8.691 10.7121 8.75 10.2954 8.75C9.18408 8.75 8.23974 8.361 7.46241 7.583C6.68441 6.80567 6.29541 5.86133 6.29541 4.75C6.29541 3.63867 6.68441 2.69433 7.46241 1.917C8.23974 1.139 9.18408 0.75 10.2954 0.75C11.4067 0.75 12.3511 1.139 13.1284 1.917C13.9064 2.69433 14.2954 3.63867 14.2954 4.75C14.2954 5.16667 14.2364 5.566 14.1184 5.948C14.0004 6.33 13.8301 6.68067 13.6074 7L16.7954 10.188L15.7334 11.25ZM10.2954 7.25C10.9901 7.25 11.5804 7.007 12.0664 6.521C12.5524 6.035 12.7954 5.44467 12.7954 4.75C12.7954 4.05533 12.5524 3.465 12.0664 2.979C11.5804 2.493 10.9901 2.25 10.2954 2.25C9.60074 2.25 9.01041 2.493 8.52441 2.979C8.03841 3.465 7.79541 4.05533 7.79541 4.75C7.79541 5.44467 8.03841 6.035 8.52441 6.521C9.01041 7.007 9.60074 7.25 10.2954 7.25Z" />
  </svg>
);

export const InitiativeDetailsIcon = (props: any) => (
  <Icon component={InitiativeDetailsIconSvg} {...props} />
);
