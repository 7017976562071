import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { MigStatus } from '../../../../../generated/graphql';

interface Props {
  migs?: {
    id: string;
    domainId: {
      itemId: string;
      teamId: string;
    };
    status: MigStatus;
    name: string;
  }[];
  selectedMigIds: string[];
  value?: string;
  onChange?: (migId: string) => void;
  className?: string;
  disabled?: boolean;
}

export const MigSelector = ({
  migs,
  selectedMigIds,
  onChange,
  className,
  disabled,
  value,
}: Props) => {
  const { t } = useTranslation();
  const migsOption = migs
    ?.filter((m) => {
      return (
        selectedMigIds.includes(m.id) ||
        m.status === MigStatus.ACTIVE ||
        m.status === MigStatus.UPCOMING
      );
    })
    .map((t) => (
      <Select.Option
        title={t.name}
        key={t.id}
        value={t.id}
        disabled={selectedMigIds.includes(t.id)}
      >
        {t.name}
      </Select.Option>
    ));
  return (
    <Select
      placeholder={t('MigSelector.migSelectorPlaceholder')}
      optionFilterProp="children"
      disabled={disabled}
      onChange={onChange}
      value={value}
      className={className}
      filterOption={(input, option) =>
        (option!.children as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      style={{ width: '300px' }}
    >
      {migsOption}
    </Select>
  );
};
