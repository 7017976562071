import { ReactElement } from 'react';
import { TeamCommitmentFulfillmentChart } from './teamCommitmentFulfillment/TeamCommitmentFulfillmentChart';
import { useTranslation } from 'react-i18next';
import { Empty, Result } from 'antd';
import { TeamCommitmentAndAchievementsResponseV2 } from '../../hooks/useTeamCommitmentAndAchievementV2';

interface Props extends TeamCommitmentAndAchievementsResponseV2 {
  graphHeight?: number;
  title?: ReactElement;
}

export const TeamCommitmentFulfillment = ({ data, error, title }: Props) => {
  const { t } = useTranslation();

  if (!data) return null;

  if (error) {
    return (
      <div className="center-content">
        <Result
          status="error"
          subTitle={t('TeamCommitmentFulfillmentChart.genericError')}
        ></Result>
      </div>
    );
  }

  if (!data || data.commitmentsAndAchievements.length === 0) {
    return (
      <div>
        {title}
        <div className="center-content">
          <Empty
            description={t('TeamCommitmentFulfillmentChart.noData')}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        </div>
      </div>
    );
  }
  return (
    <div>
      {title}
      <TeamCommitmentFulfillmentChart data={data.commitmentsAndAchievements} />
    </div>
  );
};
