import { KeyActivityGraph } from '../../../graphs/KeyActivityGraph';
import { Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { friendlyDate } from '../../../../services/dateFormats';
import { useSprintTerm } from '../../../../hooks/useSprintTerm';
import { lineColor } from '../../../../styleVars';
import './keyActivityTableCard/KeyActivityTableCard.less';

interface Props {
  teamId: string;
  tenantId?: string;
  termId: string;
  highlightedPeriod?: string;
}

export const SprintTermTableCard = ({
  teamId,
  tenantId,
  termId,
  highlightedPeriod,
}: Props) => {
  const { t } = useTranslation();

  const { loading, sprintTerm } = useSprintTerm({
    teamId,
    tenantId,
    sprintTermId: termId,
  });

  return (
    <Spin spinning={loading}>
      {sprintTerm && (
        <div className="flx pa--l ">
          <div className=" KeyActivityTableWeeklyCard__chart">
            <KeyActivityGraph
              lineData={sprintTerm.periodData.map((p, i) => ({
                ...p,
                label: friendlyDate(p.targetDate),
                timePeriodIndex: i,
              }))}
              lineConf={[
                {
                  dataKey: 'goal',
                  stroke: lineColor.goal,
                  name: t('common.goal') as string,
                },
                {
                  dataKey: 'actual',
                  stroke: lineColor.actual,
                  name: t('common.actual') as string,
                },
              ]}
              startDate={sprintTerm.startDate}
              highlightedPeriod={highlightedPeriod}
            />
          </div>
          <div className="flx KeyActivityTableWeeklyCard__info">
            <div>
              <div className="flx flx--column mb">
                <Typography.Text>{t('common.startDate')}</Typography.Text>
                <Typography.Text type="secondary">
                  {friendlyDate(sprintTerm.startDate)}
                </Typography.Text>
              </div>
              <div className="flx flx--column">
                <Typography.Text>{t('common.endDate')}</Typography.Text>
                <Typography.Text type="secondary">
                  {friendlyDate(sprintTerm.endDate)}
                </Typography.Text>
              </div>
            </div>

            <div className="pl--xl">
              <div className="flx flx--column mb">
                <Typography.Text>
                  {t('KeyActivityTableCard.unit')}
                </Typography.Text>
                <Typography.Text type="secondary">
                  {t('KeyActivityTableCard.sprintTermUnit')}
                </Typography.Text>
              </div>
              <div className="flx flx--column">
                <Typography.Text>
                  {t('KeyActivityTableCard.sprintInterval')}
                </Typography.Text>
                <Typography.Text type="secondary">
                  {t('KeyActivityTableCard.numberOfWeeks', {
                    weeks: sprintTerm.sprintInterval,
                  })}
                </Typography.Text>
              </div>
            </div>
          </div>
        </div>
      )}
    </Spin>
  );
};
