import { getNestedProperty } from './getNestedProperty';

export interface TreeItem<T extends any> {
  id: string;
  children: TreeItem<T>[];
  data: T;
}

type RequiredConfig = {
  idPath: string;
  parentIdPath: string;
};

export interface OptionalConfig {
  throwIfOrphans: boolean;
  rootParentIds: { [rootParentId: string]: true }; // use an object here for fast lookups
}

type Config = RequiredConfig & OptionalConfig;

const defaultConfig: OptionalConfig = {
  throwIfOrphans: false,
  rootParentIds: { '': true },
};

/**
 * Unflattens an array to a tree with runtime O(n)
 */
export function arrayToTree<T extends {}>(
  items: T[],
  config: Partial<OptionalConfig> & RequiredConfig
): TreeItem<T>[] {
  const conf: Config = { ...defaultConfig, ...config };

  // the resulting unflattened tree
  const rootItems: TreeItem<T>[] = [];

  // stores all already processed items with their ids as key so we can easily look them up
  const lookup: { [id: string]: TreeItem<T> } = {};

  // stores all item ids that have not been added to the resulting unflattened tree yet
  // this is an opt-in property, since it has a slight runtime overhead
  const orphanIds: null | Set<string | number> = config.throwIfOrphans
    ? new Set()
    : null;

  // idea of this loop:
  // whenever an item has a parent, but the parent is not yet in the lookup object, we store a preliminary parent
  // in the lookup object and fill it with the data of the parent later
  // if an item has no parentId, add it as a root element to rootItems
  for (const item of items) {
    const itemId = getNestedProperty(item, conf.idPath) as string;

    const parentId = getNestedProperty(item, conf.parentIdPath) as string;

    if (conf.rootParentIds[itemId]) {
      throw new Error(
        `The item array contains a node whose parentId both exists in another node and is in ` +
          `\`rootParentIds\` (\`itemId\`: "${itemId}", \`rootParentIds\`: ${Object.keys(
            conf.rootParentIds
          )
            .map((r) => `"${r}"`)
            .join(', ')}).`
      );
    }

    // look whether item already exists in the lookup table
    if (!lookup[itemId]) {
      // item is not yet there, so add a preliminary item (its data will be added later)
      lookup[itemId] = { id: itemId, children: [] } as any as TreeItem<T>;
    }

    // if we track orphans, delete this item from the orphan set if it is in it
    if (orphanIds) {
      orphanIds.delete(itemId);
    }

    // add the current item's data to the item in the lookup table
    lookup[itemId].data = item;

    const treeItem = lookup[itemId];

    if (
      parentId === null ||
      parentId === undefined ||
      conf.rootParentIds[parentId]
    ) {
      // is a root item
      rootItems.push(treeItem);
    } else {
      // has a parent

      // look whether the parent already exists in the lookup table
      if (!lookup[parentId]) {
        // parent is not yet there, so add a preliminary parent (its data will be added later)
        lookup[parentId] = { id: parentId, children: [] } as any as TreeItem<T>;

        // if we track orphans, add the generated parent to the orphan list
        if (orphanIds) {
          orphanIds.add(parentId);
        }
      }

      // add the current item to the parent
      lookup[parentId].children.push(treeItem);
    }
  }

  if (orphanIds?.size) {
    throw new Error(
      `The items array contains orphans that point to the following parentIds: ` +
        `[${Array.from(
          orphanIds
        )}]. These parentIds do not exist in the items array. Hint: prevent orphans to result ` +
        `in an error by passing the following option: { throwIfOrphans: false }`
    );
  }

  return rootItems;
}

const _pruneBranch = <T extends any>(
  node: TreeItem<T>,
  id: string
): TreeItem<T> => {
  const filteredChildren = node.children.filter((n) => n.id !== id);

  const mappedChildren = filteredChildren.map((n) => {
    return _pruneBranch(n, id);
  });

  const returnVal = { ...node, children: mappedChildren };

  return returnVal;
};

export const pruneBranch = <T extends any>(
  node: TreeItem<T>,
  id: string
): TreeItem<T> | null => {
  if (node.id === id) return null;

  return _pruneBranch(node, id);
};

export const treeToArray = <T extends any>(
  node: TreeItem<T>,
  list: T[] = []
): T[] => {
  const mapped = node.children.map((c) => treeToArray(c, list));
  const newList = [...list, node.data];

  return newList.concat(...mapped);
};
