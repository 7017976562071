import {
  AllocationAndProgressCardSkaTimeStatusFragment,
  MitemStatus,
  TeamTagFragment,
} from '../../../../generated/graphql';
import {
  calculateDoneVsPlan,
  filterExpectedToBeDoneSkas,
} from '../../common/utils/teamPageHelpers';
import {
  ActivitiesExpectedToBeDonePerGroup,
  ActivitiesPerGroup,
  AlignmentPercentagePerGroup,
  CompletedActivitiesPerGroup,
  DoneVsPlanPerGroup,
  OverdueActivitiesPerGroup,
  UpcomingActivitiesPerGroup,
} from './calculationTypes';

type CalcTypesWithTeamTag<T> = T & {
  teamTag: TeamTagFragment;
};

type TeamTagCalculationTypes = {
  ActivitiesPerGroup: CalcTypesWithTeamTag<ActivitiesPerGroup>;
  UpcomingActivitiesPerGroup: CalcTypesWithTeamTag<UpcomingActivitiesPerGroup>;
  AlignmentPercentagePerGroup: CalcTypesWithTeamTag<AlignmentPercentagePerGroup>;
  ActivitiesExpectedToBeDonePerGroup: CalcTypesWithTeamTag<ActivitiesExpectedToBeDonePerGroup>;
  CompletedActivitiesPerGroup: CalcTypesWithTeamTag<CompletedActivitiesPerGroup>;
  OverdueActivitiesPerGroup: CalcTypesWithTeamTag<OverdueActivitiesPerGroup>;
  DoneVsPlanPerGroup: CalcTypesWithTeamTag<DoneVsPlanPerGroup>;
};

const calculateTotalActivities = (
  sprintKeyActivities: AllocationAndProgressCardSkaTimeStatusFragment[],
  teamTagId: string
) =>
  sprintKeyActivities.filter((ska) => {
    return ska.sprintKeyActivity?.tags.some((supportTag) => {
      return supportTag.id === teamTagId;
    });
  }).length;

export const calculateTotalSupportedTeamTags = (
  sprintKeyActivities: AllocationAndProgressCardSkaTimeStatusFragment[],
  tags: TeamTagFragment[]
) => {
  const teamTagsWithTotalActivities: Record<
    string,
    TeamTagCalculationTypes['ActivitiesPerGroup']
  > = {};

  for (const tag of tags) {
    const current = teamTagsWithTotalActivities[tag.id] || {
      id: tag.id,
      name: tag.name,
      teamTag: tag,
      activities: calculateTotalActivities(sprintKeyActivities, tag.id),
    };
    teamTagsWithTotalActivities[tag.id] = current;
  }

  return teamTagsWithTotalActivities;
};

export const calculateUpcomingSkasPerTeamTag = (
  sprintKeyActivities: AllocationAndProgressCardSkaTimeStatusFragment[],
  teamTagsWithTotalActivities: Record<
    string,
    TeamTagCalculationTypes['ActivitiesPerGroup']
  >
) => {
  const supportedTeamTagsUpcomingActivities: Record<
    string,
    TeamTagCalculationTypes['UpcomingActivitiesPerGroup']
  > = {};

  Object.entries(teamTagsWithTotalActivities).forEach(
    ([teamTagId, val]) =>
      (supportedTeamTagsUpcomingActivities[teamTagId] = {
        ...val,
        upcomingActivities: sprintKeyActivities.filter((ska) => {
          return ska.sprintKeyActivity?.tags.some((supportTeamTag) => {
            return (
              supportTeamTag.id === teamTagId &&
              (ska.status === MitemStatus.ACTIVE ||
                ska.status === MitemStatus.PLANNED)
            );
          });
        }).length,
      })
  );

  return supportedTeamTagsUpcomingActivities;
};

export const calculateSupportedTeamTagsPercentage = (
  amountOfSKAs: number,
  teamTagsWithTotalActivities: Record<
    string,
    TeamTagCalculationTypes['UpcomingActivitiesPerGroup']
  >
) => {
  const supportedTeamTagsPercentage: Record<
    string,
    TeamTagCalculationTypes['AlignmentPercentagePerGroup']
  > = {};

  Object.entries(teamTagsWithTotalActivities).forEach(
    ([teamTagId, val]) =>
      (supportedTeamTagsPercentage[teamTagId] = {
        ...val,
        percentageOfAllActivities:
          amountOfSKAs === 0 || val.activities === 0
            ? null
            : Math.floor((val.activities / amountOfSKAs) * 100),
      })
  );

  return supportedTeamTagsPercentage;
};

export const calculateGoalPerTeamTag = (
  filteredSprint: AllocationAndProgressCardSkaTimeStatusFragment[],
  supportedTeamTagsPercentage: Record<
    string,
    TeamTagCalculationTypes['AlignmentPercentagePerGroup']
  >
) => {
  const supportedTeamTagsGoal: Record<
    string,
    TeamTagCalculationTypes['ActivitiesExpectedToBeDonePerGroup']
  > = {};

  Object.entries(supportedTeamTagsPercentage).forEach(
    ([teamTagId, val]) =>
      (supportedTeamTagsGoal[teamTagId] = {
        ...val,
        goal: calculateTotalActivities(filteredSprint, teamTagId),
      })
  );

  return supportedTeamTagsGoal;
};

export const calculateTotalCompletedActivitiesPerTeamTag = (
  filteredSprint: AllocationAndProgressCardSkaTimeStatusFragment[],
  supportedTeamTagsGoal: Record<
    string,
    TeamTagCalculationTypes['ActivitiesExpectedToBeDonePerGroup']
  >
) => {
  const supportedTeamTagsCompletedActivities: Record<
    string,
    TeamTagCalculationTypes['CompletedActivitiesPerGroup']
  > = {};

  Object.entries(supportedTeamTagsGoal).forEach(
    ([teamTagId, val]) =>
      (supportedTeamTagsCompletedActivities[teamTagId] = {
        ...val,
        completedActivities: filteredSprint.filter((ska) => {
          return ska.sprintKeyActivity?.tags.some((supportTeamTag) => {
            return (
              supportTeamTag.id === teamTagId &&
              ska.status === MitemStatus.COMPLETED
            );
          });
        }).length,
      })
  );

  return supportedTeamTagsCompletedActivities;
};

export const countOverdueActivitiesPerTeamTag = (
  sprintKeyActivities: AllocationAndProgressCardSkaTimeStatusFragment[],
  supportedTeamTagsCompletedActivities: Record<
    string,
    TeamTagCalculationTypes['CompletedActivitiesPerGroup']
  >
) => {
  const teamTagOverdueActivities: Record<
    string,
    TeamTagCalculationTypes['OverdueActivitiesPerGroup']
  > = {};

  const isOverdueAndSupportsTeamTag = (
    ska: AllocationAndProgressCardSkaTimeStatusFragment,
    teamTagId: string
  ) => {
    return ska.sprintKeyActivity?.tags.some((supportTeamTag) => {
      return (
        supportTeamTag.id === teamTagId && ska.status === MitemStatus.OVERDUE
      );
    });
  };

  Object.entries(supportedTeamTagsCompletedActivities).forEach(
    ([teamTagId, val]) => {
      const overdueActivitiesSupportingTeamTag = sprintKeyActivities.filter(
        (ska) => isOverdueAndSupportsTeamTag(ska, teamTagId)
      );

      return (teamTagOverdueActivities[teamTagId] = {
        ...val,
        overdueActivities: overdueActivitiesSupportingTeamTag.length,
      });
    }
  );

  return teamTagOverdueActivities;
};

export const teamTagDoneVsPlan = (
  teamTagOverdueActivities: Record<
    string,
    TeamTagCalculationTypes['OverdueActivitiesPerGroup']
  >
) => {
  const teamTagsData: Record<
    string,
    TeamTagCalculationTypes['DoneVsPlanPerGroup']
  > = {};

  Object.entries(teamTagOverdueActivities).forEach(
    ([teamTagId, val]) =>
      (teamTagsData[teamTagId] = {
        ...val,
        doneVsPlan:
          val.percentageOfAllActivities === null
            ? null
            : calculateDoneVsPlan(val.completedActivities, val.goal),
      })
  );

  return teamTagsData;
};

export const getTeamTagsData = (
  sprintKeyActivities: AllocationAndProgressCardSkaTimeStatusFragment[],
  teamTags?: TeamTagFragment[]
) => {
  const sumActivitiesPerTeamTag = calculateTotalSupportedTeamTags(
    sprintKeyActivities,
    teamTags ?? []
  );

  const sumUpcomingActivitiesPerTeamTag = calculateUpcomingSkasPerTeamTag(
    sprintKeyActivities,
    sumActivitiesPerTeamTag
  );

  const supportedTeamTagsPercentage = calculateSupportedTeamTagsPercentage(
    sprintKeyActivities.length,
    sumUpcomingActivitiesPerTeamTag
  );

  const filteredSkasExpectedToBeDone =
    filterExpectedToBeDoneSkas(sprintKeyActivities);

  const supportedTeamTagsGoal = calculateGoalPerTeamTag(
    filteredSkasExpectedToBeDone,
    supportedTeamTagsPercentage
  );
  const supportedTeamTagsTotalCompletedActivities =
    calculateTotalCompletedActivitiesPerTeamTag(
      sprintKeyActivities,
      supportedTeamTagsGoal
    );

  const teamTagOverdueActivities = countOverdueActivitiesPerTeamTag(
    sprintKeyActivities,
    supportedTeamTagsTotalCompletedActivities
  );

  const teamTagsData = teamTagDoneVsPlan(teamTagOverdueActivities);

  const completeTeamTagsData = Object.values(teamTagsData).map((val) => ({
    id: val.id,
    name: val.name,
    amountOfSKAs: val.activities,
    percentageOfAllActivities: val.percentageOfAllActivities,
    doneVsPlan: val.doneVsPlan,
    teamTag: val.teamTag,
    completed: val.completedActivities,
    upcomingActivities: val.upcomingActivities,
    overdueActivities: val.overdueActivities,
  }));

  return completeTeamTagsData;
};
