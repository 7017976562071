import './DemoWizardSteps.less';
import { useWizardNavigation } from '../../../../../../../components/WizardNavigationProvider';
import cx from 'classnames';

export const DemoWizardSteps = () => {
  const wizard = useWizardNavigation();

  return (
    <div className="DemoWizardSteps">
      {wizard.steps.map((step) =>
        step.type === 'WizardComponentStep' ? (
          <div
            key={step.key}
            className={cx('DemoWizardSteps__step', {
              'DemoWizardSteps__step--active':
                step.index === wizard.activeStep.index,
              'DemoWizardSteps__step--visited':
                step.index < wizard.activeStep.index,
            })}
          >
            <div className="DemoWizardSteps__stepCircle">{step.index + 1}</div>
            <div className="DemoWizardSteps__stepTitle">{step.title}</div>
            <div className="WizardSteps__stepLine"></div>
          </div>
        ) : (
          <div key={step.key} className="DemoWizardSteps__step">
            N/A
          </div>
        )
      )}
    </div>
  );
};
