import { useAuth0 } from '../Auth0Provider';
import { envConf } from '../envConf';
import { useTenantOverride } from '../TenantOverrideProvider';

type PredicatFn = (tenantsList: string[], userTenant: string) => boolean;

export const createFeatureFlagHook =
  <
    GA_KEY extends string & keyof typeof envConf,
    TENANTS_KEY extends string & keyof typeof envConf,
  >(
    feature: {
      GaKey?: GA_KEY;
      TenantsKey?: TENANTS_KEY;
    },
    useOverrideTenantId: boolean,
    predicate: PredicatFn
  ) =>
  () => {
    const { user } = useAuth0();
    const { tenantId } = useTenantOverride();

    const isFeatureGA = feature.GaKey && envConf[feature.GaKey] === 'true';

    if (isFeatureGA) {
      return true;
    }

    if (!feature.TenantsKey) return false;

    const tenants = envConf[feature.TenantsKey]?.split(',');

    const userTenantId = useOverrideTenantId
      ? tenantId || user?.howwe.tenantId
      : user?.howwe.tenantId;

    if (!tenants || !userTenantId) return false;

    return predicate(tenants, userTenantId);
  };

export const createIncludeListFeatureFlagHook = <
  GA_KEY extends string & keyof typeof envConf,
  TENANTS_KEY extends string & keyof typeof envConf,
>(
  feature: {
    GaKey?: GA_KEY;
    TenantsKey?: TENANTS_KEY;
  },
  useOverrideTenantId = false
) => {
  const isAllowedTenantCheck: PredicatFn = (tenants, userTenantId) =>
    tenants.includes(userTenantId);

  return createFeatureFlagHook(
    feature,
    useOverrideTenantId,
    isAllowedTenantCheck
  );
};

export const createExcludeListFeatureFlagHook = <
  GA_KEY extends string & keyof typeof envConf,
  TENANTS_KEY extends string & keyof typeof envConf,
>(
  feature: {
    GaKey?: GA_KEY;
    TenantsKey?: TENANTS_KEY;
  },
  useOverrideTenantId = false
) => {
  const isDisallowedTenantCheck: PredicatFn = (tenants, userTenantId) =>
    !tenants.includes(userTenantId);

  return createFeatureFlagHook(
    feature,
    useOverrideTenantId,
    isDisallowedTenantCheck
  );
};
