import Icon from '@ant-design/icons';

const AlarmOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.9999 13.6666C7.16657 13.6666 6.38612 13.5084 5.65857 13.192C4.93057 12.8751 4.29724 12.4471 3.75857 11.908C3.21946 11.3693 2.79146 10.736 2.47457 10.008C2.15812 9.28043 1.9999 8.49998 1.9999 7.66665C1.9999 6.83332 2.15812 6.05265 2.47457 5.32465C2.79146 4.59709 3.21946 3.96376 3.75857 3.42465C4.29724 2.88598 4.93057 2.45843 5.65857 2.14198C6.38612 1.82509 7.16657 1.66665 7.9999 1.66665C8.83324 1.66665 9.6139 1.82509 10.3419 2.14198C11.0695 2.45843 11.7028 2.88598 12.2419 3.42465C12.7806 3.96376 13.2083 4.59709 13.5252 5.32465C13.8417 6.05265 13.9999 6.83332 13.9999 7.66665C13.9999 8.49998 13.8417 9.28043 13.5252 10.008C13.2083 10.736 12.7806 11.3693 12.2419 11.908C11.7028 12.4471 11.0695 12.8751 10.3419 13.192C9.6139 13.5084 8.83324 13.6666 7.9999 13.6666ZM9.86657 10.4667L10.7999 9.53332L8.66657 7.39998V4.33332H7.33324V7.93332L9.86657 10.4667ZM3.73324 0.56665L4.66657 1.49998L1.83324 4.33332L0.899902 3.39998L3.73324 0.56665ZM12.2666 0.56665L15.0999 3.39998L14.1666 4.33332L11.3332 1.49998L12.2666 0.56665ZM7.9999 12.3333C9.2999 12.3333 10.4026 11.8804 11.3079 10.9746C12.2137 10.0693 12.6666 8.96665 12.6666 7.66665C12.6666 6.36665 12.2137 5.26376 11.3079 4.35798C10.4026 3.45265 9.2999 2.99998 7.9999 2.99998C6.6999 2.99998 5.59724 3.45265 4.6919 4.35798C3.78612 5.26376 3.33324 6.36665 3.33324 7.66665C3.33324 8.96665 3.78612 10.0693 4.6919 10.9746C5.59724 11.8804 6.6999 12.3333 7.9999 12.3333Z" />
  </svg>
);

export const AlarmOutlineIcon = (props: any) => (
  <Icon component={AlarmOutlineIconSvg} {...props} />
);
