import Icon from '@ant-design/icons';

const SearchOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 9"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.333496 8.66667V7.33333H7.00016V8.66667H0.333496ZM0.333496 5.33333V4H3.66683V5.33333H0.333496ZM0.333496 2V0.666667H3.66683V2H0.333496ZM12.7335 8.66667L10.1668 6.1C9.90016 6.28889 9.6085 6.43056 9.29183 6.525C8.97516 6.61944 8.65572 6.66667 8.3335 6.66667C7.41127 6.66667 6.62516 6.34167 5.97516 5.69167C5.32516 5.04167 5.00016 4.25556 5.00016 3.33333C5.00016 2.41111 5.32516 1.625 5.97516 0.975C6.62516 0.325 7.41127 0 8.3335 0C9.25572 0 10.0418 0.325 10.6918 0.975C11.3418 1.625 11.6668 2.41111 11.6668 3.33333C11.6668 3.65556 11.6196 3.975 11.5252 4.29167C11.4307 4.60833 11.2891 4.9 11.1002 5.16667L13.6668 7.73333L12.7335 8.66667ZM8.3335 5.33333C8.88905 5.33333 9.36127 5.13889 9.75016 4.75C10.1391 4.36111 10.3335 3.88889 10.3335 3.33333C10.3335 2.77778 10.1391 2.30556 9.75016 1.91667C9.36127 1.52778 8.88905 1.33333 8.3335 1.33333C7.77794 1.33333 7.30572 1.52778 6.91683 1.91667C6.52794 2.30556 6.3335 2.77778 6.3335 3.33333C6.3335 3.88889 6.52794 4.36111 6.91683 4.75C7.30572 5.13889 7.77794 5.33333 8.3335 5.33333Z" />
  </svg>
);

export const SearchOutlineIcon = (props: any) => (
  <Icon component={SearchOutlineIconSvg} {...props} />
);
