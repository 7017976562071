import { GaArgs, gtag } from './gtag';

/*
Links
https://developers.google.com/gtagjs/reference/api
https://developers.google.com/tag-platform/gtagjs/reference
*/

class GA4 {
  isInitialized = false;
  private _testMode = false;
  private _currentMeasurementId?: string;
  private _hasLoadedGA = false;
  private _isQueuing = false;
  private _queueGtag: GaArgs[] = [];

  constructor() {
    this.reset();
  }

  reset = () => {
    this.isInitialized = false;

    this._testMode = false;
    this._currentMeasurementId = undefined;
    this._hasLoadedGA = false;
    this._isQueuing = false;
    this._queueGtag = [];
  };

  private _gtag = (...args: GaArgs) => {
    if (!this._testMode) {
      if (this._isQueuing) {
        this._queueGtag.push(args);
      } else {
        gtag(...args);
      }
    } else {
      this._queueGtag.push(args);
    }
  };

  gtag(...args: GaArgs) {
    this._gtag(...args);
  }

  private _loadGA = (
    GA_MEASUREMENT_ID: string,
    nonce?: string,
    gtagUrl = 'https://www.googletagmanager.com/gtag/js'
  ) => {
    if (typeof window === 'undefined' || typeof document === 'undefined') {
      return;
    }

    if (!this._hasLoadedGA) {
      // Global Site Tag (gtag.js) - Google Analytics
      const script = document.createElement('script');
      script.async = true;
      script.src = `${gtagUrl}?id=${GA_MEASUREMENT_ID}`;
      if (nonce) {
        script.setAttribute('nonce', nonce);
      }
      document.body.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      window.gtag = function gtag() {
        window.dataLayer.push(arguments);
      };

      this._hasLoadedGA = true;
    }
  };

  initialize = (GA_MEASUREMENT_ID: string, options: InitOptions = {}) => {
    this._currentMeasurementId = GA_MEASUREMENT_ID;

    const { gtagOptions, nonce, testMode = false, gtagUrl } = options;
    this._testMode = testMode;

    if (!testMode) {
      this._loadGA(this._currentMeasurementId, nonce, gtagUrl);
    }
    if (!this.isInitialized) {
      this._gtag('js', new Date());
      this._gtag('config', GA_MEASUREMENT_ID, gtagOptions);
    }
    this.isInitialized = true;

    if (!testMode) {
      const queues = [...this._queueGtag];
      this._queueGtag = [];
      this._isQueuing = false;
      while (queues.length) {
        const queue = queues.shift();
        if (queue) {
          this._gtag(...queue);
          if (queue[0] === 'get') {
            this._isQueuing = true;
          }
        }
      }
    }
  };
}

interface InitOptions {
  gtagOptions?:
    | Gtag.ControlParams
    | Gtag.EventParams
    | Gtag.ConfigParams
    | Gtag.CustomParams;
  nonce?: string;
  testMode?: boolean;
  gtagUrl?: string;
}

export const ga4 = new GA4();
