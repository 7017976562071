import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { progressColor } from '../../../../../../../../../../components/akpi/teamCommitmentFulfillment/TeamCommitmentFulfillmentChart';
import { TeamKeyActivityBarChartTooltip } from '../../common/TeamKeyActivityBarChartTooltip';

interface Props {
  data?: {
    id: string;
    name?: string;
    totalCommitted: number;
    totalAchieved: number;
  };
}
export const TeamKeyActivityBarChart = ({ data }: Props) => {
  return (
    <>
      <ResponsiveContainer height={110} width="99%">
        <BarChart
          data={[data]}
          layout="vertical"
          barSize={14}
          barGap={8}
          margin={{ left: -55 }}
        >
          <XAxis
            domain={[0, `dataMax+2`]}
            type="number"
            allowDecimals={false}
          />
          <YAxis
            type="category"
            dataKey="id"
            textAnchor="end"
            domain={[0, 'dataMax']}
            axisLine={false}
            tick={false}
            hide={false}
          />

          <Tooltip
            cursor={{ fill: 'none' }}
            content={(args) => <TeamKeyActivityBarChartTooltip {...args} />}
          />

          <Bar
            dataKey="totalCommitted"
            fill={progressColor.committed}
            stroke={progressColor.committedBorder}
            layout="vertical"
          />
          <Bar
            dataKey="totalAchieved"
            fill={progressColor.achieved}
            stroke={progressColor.achievedBorder}
            layout="vertical"
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
