import { Modal, Spin } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './migSetupPage.less';
import { MigsWithGraphsCards } from './migSetupPage/MigsWithGraphsCards';
import { MigSetupTable } from './migSetupPage/MigSetupTable';
import { EditMigModal } from './migSetupPage/EditMigModal';
import { CreateMigModal } from './migSetupPage/CreateMigModal';
import { StatusMigModal } from './migSetupPage/StatusMigModal';
import {
  Action,
  MigDomainId,
  MigSetupPageQueryDocument,
  MigStatus,
} from '../../generated/graphql';
import { ArchiveOutlinedIcon } from '../../icons/ArchiveIcon';
import { SectionHeader } from '../SectionHeader';
import { LinkMigToTeamModal } from './migSetupPage/LinkMigToTeamModal';
import { DeleteMirrorMigModal } from './migSetupPage/DeleteMirrorMigModal';
import { Btn } from '../Button';
import { PermissionChecker } from '../../PermissionChecker';
import { gql, useQuery } from '@apollo/client';

interface Props {
  teamId: string;
  tenantId?: string;
}

interface CreateMig {
  id: null;
  type: 'CreateMig';
}

interface RemoveMirrorMig {
  domainId: MigDomainId;
  type: 'RemoveMirrorMig';
}

interface EditMig {
  id: string;
  type: 'EditMig';
}

interface UpdateMigStatus {
  id: string;
  type: 'UpdateMigStatus';
  status: MigStatus;
}

export type MigModalState =
  | CreateMig
  | EditMig
  | UpdateMigStatus
  | RemoveMirrorMig;

export const MigSetupPage = ({ teamId, tenantId }: Props) => {
  const { t } = useTranslation();

  const [migModalState, setMigModalState] = useState<MigModalState | null>(
    null
  );

  const { data, loading } = useQuery(MigSetupPageQueryDocument, {
    variables: { teamId, tenantId },
  });
  const teamMigs = data?.teamMigs.migs ?? [];

  const [showArchived, setShowArchived] = useState(false);

  return (
    <>
      <CreateMigModal
        migModalState={migModalState}
        teamId={teamId}
        tenantId={tenantId}
        onClose={() => setMigModalState(null)}
      />

      <EditMigModal
        migModalState={migModalState}
        teamId={teamId}
        tenantId={tenantId}
        onClose={() => setMigModalState(null)}
      />

      <StatusMigModal
        migModalState={migModalState}
        teamId={teamId}
        tenantId={tenantId}
        onClose={() => setMigModalState(null)}
      />

      <DeleteMirrorMigModal
        migModalState={migModalState}
        teamId={teamId}
        tenantId={tenantId}
        onClose={() => setMigModalState(null)}
      />

      <Modal
        width={800}
        open={showArchived}
        footer={false}
        title={t('common.archived.multiple')}
        onCancel={() => setShowArchived(false)}
      >
        <MigSetupTable
          teamId={teamId}
          tenantId={tenantId}
          status={MigStatus.ARCHIVED}
          teamMigs={teamMigs?.filter(
            (mig) => mig.status === MigStatus.ARCHIVED
          )}
          setMigModalState={setMigModalState}
        />
      </Modal>

      <Spin spinning={loading}>
        <div>
          <SectionHeader
            title={t('common.active')}
            extra={
              <div className="flx">
                <PermissionChecker
                  resourceOwner={{
                    type: 'TeamResource',
                    teamId: teamId,
                    requestedAction: {
                      resource: 'mig',
                      action: Action.CREATE,
                    },
                  }}
                >
                  <LinkMigToTeamModal
                    teamId={teamId}
                    tenantId={tenantId}
                    migsAlreadyLinkedToTeam={teamMigs.filter(
                      (mig) => mig.domainId.teamId !== teamId
                    )}
                  />

                  <Btn
                    type="primary"
                    data-intercom-target="MIG Setup Page Create Button"
                    onClick={() =>
                      setMigModalState({
                        id: null,
                        type: 'CreateMig',
                      })
                    }
                  >
                    {t('MigSetupPage.createMigButton')}
                  </Btn>
                </PermissionChecker>
              </div>
            }
          >
            <MigsWithGraphsCards
              migs={teamMigs.filter((mig) => mig.status === MigStatus.ACTIVE)}
              setMigModalState={setMigModalState}
            />
          </SectionHeader>
          <div className="pt--xl">
            <SectionHeader title={t('common.upcoming')}>
              <MigSetupTable
                teamId={teamId}
                tenantId={tenantId}
                status={MigStatus.UPCOMING}
                teamMigs={teamMigs?.filter(
                  (mig) => mig.status === MigStatus.UPCOMING
                )}
                setMigModalState={setMigModalState}
              />
            </SectionHeader>

            <SectionHeader
              title={t('MigSetupPage.endedTitle')}
              extra={
                <Btn
                  onClick={() => setShowArchived(true)}
                  data-intercom-target="MIG Setup Page Show Archived Button"
                  icon={<ArchiveOutlinedIcon />}
                >
                  {t('MigSetupPage.showArchiveButton', {
                    counter: teamMigs?.filter(
                      (mig) => mig.status === MigStatus.ARCHIVED
                    ).length,
                  })}
                </Btn>
              }
            >
              <MigSetupTable
                teamId={teamId}
                tenantId={tenantId}
                status={MigStatus.ENDED}
                teamMigs={teamMigs?.filter(
                  (mig) =>
                    mig.status === MigStatus.ENDED ||
                    mig.status === MigStatus.OVERDUE
                )}
                setMigModalState={setMigModalState}
              />
            </SectionHeader>
          </div>
        </div>
      </Spin>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIG_SETUP_PAGE_QUERY = gql`
  query migSetupPageQuery($teamId: ID!, $tenantId: ID) {
    teamMigs(teamId: $teamId, tenantId: $tenantId) {
      migs {
        ...MigSetupTableMig
        ...MigsWithGraphsCardsMig
      }
    }
  }
`;
