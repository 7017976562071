import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { DisplayDateRange } from '../../../../../../components/DisplayDateRange';
import { BaseMitemSprintFragment } from '../../../../../../generated/graphql';
import './SprintFilter.less';

interface Props {
  sprints: BaseMitemSprintFragment[];
  selectedSprintIndex: number;
  currentSprintIndex: number;
  setSelectedSprintIndex: (index: number) => void;
}

export const SprintFilter = ({
  sprints,
  setSelectedSprintIndex,
  selectedSprintIndex,
  currentSprintIndex,
}: Props) => {
  const { Option } = Select;
  const { t } = useTranslation();
  return (
    <div
      className="MitemFilters__filterContainer mr--s"
      data-intercom-target="Sprint View Table Sprint Filter"
    >
      <h5>{t('SprintFilter.title')}</h5>
      <Select
        value={selectedSprintIndex}
        onChange={setSelectedSprintIndex}
        className="MitemFilter__Select"
      >
        <Option value={-1} className="MitemFilters__divider">
          {t('common.showAll')}
        </Option>
        {sprints.map((s, index) => {
          const sprintStatusClass = sprintStatus(
            currentSprintIndex,
            index,
            s.locked
          );

          return (
            <Option
              key={index}
              value={index}
              className={`SprintFilter--${sprintStatusClass}`}
            >
              <div className="flx">
                <div
                  className={`SprintFilter__sprintIndicator--${sprintStatusClass}`}
                >{`#${index + 1}`}</div>

                <DisplayDateRange
                  startDate={s.startDate}
                  endDate={s.endDate}
                  className="SprintFilter__dateRange"
                />
              </div>
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export const sprintStatus = (
  currentSprintIndex: number,
  index: number,
  locked: boolean
) => {
  if (currentSprintIndex === index && locked) {
    return 'ACTIVE';
  }

  if (currentSprintIndex > index) {
    return 'PAST';
  }
  return 'PLANNED';
};
