import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Modal, Spin, Typography } from 'antd';
import { useMutation, gql } from '@apollo/client';
import {
  GetTeamKeyActivitiesPreviewDocument,
  PublishStatus,
  ChangeStatusSprintTermDocument,
  GetSprintsForPlanningDocument,
} from '../../../generated/graphql';
import { KeyActivityModalState } from '../KeyActivitySetupPage';
import { getStatusByDates } from '../../../services/sprintTermStatus';
import { useSprintTermLazy } from '../../../hooks/useSprintTerm';
import { MitemTermCard } from '../../akpi/teamAkpiPreviews/MitemTermCard';
import { useSprintTerms } from '../../../hooks/useSprintTerms';
import { ErrorAlert } from '../../ErrorAlert';
import { showNotification } from '../../../services/fetchNotificationProperties';
import dayjs from 'dayjs';

interface Props {
  teamId: string;
  tenantId?: string;
  keyActivityModalState: KeyActivityModalState | null;
  onClose: () => void;
}

export const StatusSprintTermModal = ({
  keyActivityModalState,
  onClose,
  teamId,
  tenantId,
}: Props) => {
  const { t } = useTranslation();
  const { fetchSprintTerm, sprintTerm, loading } = useSprintTermLazy();
  const [hasConflicts, setHasConflicts] = useState<boolean | undefined>();
  const toBeArchived =
    keyActivityModalState?.type === 'UpdateStatusSprintTerm' &&
    keyActivityModalState.status !== PublishStatus.ARCHIVED;

  useEffect(() => {
    if (
      keyActivityModalState?.type === 'UpdateStatusSprintTerm' &&
      keyActivityModalState.id
    ) {
      fetchSprintTerm({
        variables: {
          teamId,
          tenantId,
          termId: keyActivityModalState.id,
        },
      });
    }
  }, [fetchSprintTerm, keyActivityModalState, teamId, tenantId]);

  const [
    updateStatusSprintTerm,
    { error: updateError, loading: updateLoading },
  ] = useMutation(ChangeStatusSprintTermDocument, {
    onCompleted: (response) => {
      showNotification('success', {
        message: (
          <strong>
            {response.updateSprintTermStatus.status === PublishStatus.ARCHIVED
              ? t('StatusSprintTermModal.archivedSuccess')
              : t('StatusSprintTermModal.unarchiveSuccess')}
          </strong>
        ),
      });

      onClose();
    },
    refetchQueries: () => [
      {
        query: GetTeamKeyActivitiesPreviewDocument,
        variables: { teamId, tenantId },
      },
      {
        query: GetSprintsForPlanningDocument,
        variables: { teamId, includeUpcoming: true },
      },
    ],
  });

  const isOpen =
    keyActivityModalState?.type === 'UpdateStatusSprintTerm' &&
    keyActivityModalState.id != null;

  useEffect(() => {
    (window as any).Intercom?.('update', {
      hide_default_launcher: isOpen,
    });
  }, [isOpen]);

  const handleSave = () => {
    if (keyActivityModalState?.id == null) {
      throw new Error(
        'TermId is null or undefined when trying to change sprint term status'
      );
    }
    updateStatusSprintTerm({
      variables: {
        teamId,
        tenantId,
        sprintTermId: keyActivityModalState?.id,
        archive: toBeArchived,
      },
    });
  };

  if (keyActivityModalState?.type !== 'UpdateStatusSprintTerm') return null;

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      onOk={handleSave}
      keyboard={true}
      footer={hasConflicts ? false : undefined}
      confirmLoading={updateLoading}
      okText={
        toBeArchived
          ? t('StatusSprintTermModal.onArchive')
          : t('StatusSprintTermModal.onReactivate')
      }
      destroyOnClose
      title={
        <div className="flx flx--column">
          <Typography.Text>
            {toBeArchived
              ? t('StatusSprintTermModal.archiveTitle')
              : t('StatusSprintTermModal.activationTitle')}
          </Typography.Text>
        </div>
      }
    >
      <Spin spinning={loading}>
        <div>
          {sprintTerm && (
            <MitemTermCard
              term={sprintTerm}
              showDescription={false}
              teamId={teamId}
              tenantId={tenantId}
            />
          )}
          {toBeArchived ? (
            <ArchiveTerm
              currentStatus={keyActivityModalState.status}
              fromActiveStatus={
                keyActivityModalState.status === PublishStatus.ACTIVE
              }
            />
          ) : (
            <ReactivateTerm
              currentTerm={sprintTerm}
              teamId={teamId}
              tenantId={tenantId}
              setHasConflicts={setHasConflicts}
            />
          )}
          <ErrorAlert
            error={updateError}
            title={t('StatusSprintTermModal.activationError')}
          />
        </div>
      </Spin>
    </Modal>
  );
};

interface ArchiveProps {
  currentStatus?: PublishStatus;
  fromActiveStatus: boolean;
}

const ArchiveTerm = ({ currentStatus, fromActiveStatus }: ArchiveProps) => {
  const { t } = useTranslation();

  return (
    <div className="mt--xl">
      <Alert
        type="warning"
        className="mb"
        message={t('StatusSprintTermModal.archiveWarningTitle')}
        description={
          currentStatus
            ? t('StatusSprintTermModal.currentStatus', {
                status: t(`KeyActivityStatus.${currentStatus}`),
              })
            : ''
        }
      />
      <Typography.Title level={5}>
        {t('StatusSprintTermModal.pleaseNoteTitle')}
      </Typography.Title>
      <ul>
        {fromActiveStatus && (
          <li>{t('StatusSprintTermModal.archiveNoteOne')}</li>
        )}
        <li>{t('StatusSprintTermModal.archiveNoteTwo')}</li>
        <li>{t('StatusSprintTermModal.archiveNoteThree')}</li>
      </ul>
    </div>
  );
};

interface SprintTerm {
  id: string;
  endDate: string;
  startDate: string;
  publishDate: string;
  unpublishDate: string;
  sprintInterval: number;
  status: PublishStatus;
  published: boolean;
}

interface ReactivateTermProps {
  currentTerm?: SprintTerm;
  teamId: string;
  tenantId?: string;
  setHasConflicts: (hasConflict: boolean | undefined) => void;
}

const ReactivateTerm = ({
  currentTerm: termToReactivate,
  teamId,
  tenantId,
  setHasConflicts,
}: ReactivateTermProps) => {
  const { t } = useTranslation();
  const { data, loading } = useSprintTerms(teamId, tenantId);

  const hasConflict = data?.some((term) => {
    if (termToReactivate == null) return false; // no term to reactivate
    if (term.status === PublishStatus.ARCHIVED) return false; // cannot conflict with archived terms
    if (term.id === termToReactivate.id) return false; // cannot conflict with itself

    const startsDuring = dayjs(term.startDate).isBetween(
      termToReactivate.startDate,
      termToReactivate.endDate,
      'day',
      '[]'
    ); // starts during the term to reactivate

    const endsDuring = dayjs(term.endDate).isBetween(
      termToReactivate.startDate,
      termToReactivate.endDate,
      'day',
      '[]'
    ); // ends during the term to reactivate

    return startsDuring || endsDuring;
  });

  useEffect(() => {
    setHasConflicts(hasConflict);
  }, [hasConflict, setHasConflicts]);

  return (
    <Spin spinning={loading}>
      <div className="mt--xl">
        {hasConflict ? (
          <>
            <Alert
              type="warning"
              className="mb--l"
              message={t('StatusSprintTermModal.conflictWarning')}
              description={t('StatusSprintTermModal.conflictDescription')}
            />
            <Typography.Title level={5}>
              {t('StatusSprintTermModal.pleaseNoteTitle')}
            </Typography.Title>
            <ul>
              <li>{t('StatusSprintTermModal.reactivateConflictTip1')}</li>

              <li>{t('StatusSprintTermModal.reactivateConflictTip2')}</li>
            </ul>
          </>
        ) : (
          <>
            <Alert
              className="mb--l"
              type="info"
              message={t('StatusSprintTermModal.reactivateTermTitle')}
            />
            <Typography.Title level={5}>
              {t('StatusSprintTermModal.pleaseNoteTitle')}
            </Typography.Title>
            {termToReactivate && (
              <p>
                {t(
                  `StatusSprintTermModal.${getStatusByDates(
                    termToReactivate.startDate,
                    termToReactivate.endDate
                  )}`
                )}
              </p>
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CHANGE_STATUS_SPRINT_TERM = gql`
  mutation changeStatusSprintTerm(
    $teamId: ID!
    $sprintTermId: ID!
    $archive: Boolean!
    $tenantId: ID
  ) {
    updateSprintTermStatus(
      teamId: $teamId
      sprintTermId: $sprintTermId
      archive: $archive
      tenantId: $tenantId
    ) {
      id
      status
    }
  }
`;
