import Icon, {
  IconComponentProps,
} from '@ant-design/icons/lib/components/Icon';

const GroupsSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 15v-1.313c0-.597.306-1.083.917-1.458.61-.375 1.416-.562 2.416-.562.18 0 .354.003.521.01.167.007.327.024.48.052-.195.292-.34.597-.438.917-.097.32-.146.653-.146 1V15H0Zm5 0v-1.354c0-.445.122-.85.365-1.219.243-.368.586-.69 1.03-.969.445-.277.977-.486 1.595-.625a9.163 9.163 0 0 1 2.01-.208c.736 0 1.413.07 2.031.208.618.14 1.15.348 1.594.625.444.278.785.601 1.02.97.237.367.355.773.355 1.218V15H5Zm11.25 0v-1.354c0-.361-.045-.702-.135-1.021a3.261 3.261 0 0 0-.407-.896c.153-.028.31-.045.47-.052.159-.007.322-.01.489-.01 1 0 1.805.184 2.416.552.611.368.917.857.917 1.469V15h-3.75Zm-9.48-1.667h6.48c-.139-.277-.524-.52-1.156-.729A6.656 6.656 0 0 0 10 12.292c-.764 0-1.462.104-2.094.312-.632.209-1.01.452-1.135.73Zm-3.437-2.5c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.176c0-.473.164-.868.49-1.188.327-.32.719-.479 1.177-.479.473 0 .868.16 1.188.48.32.319.479.714.479 1.187 0 .458-.16.85-.48 1.177-.319.326-.714.49-1.187.49Zm13.334 0c-.459 0-.851-.163-1.177-.49A1.605 1.605 0 0 1 15 9.168c0-.473.163-.868.49-1.188.326-.32.718-.479 1.177-.479.472 0 .868.16 1.187.48.32.319.48.714.48 1.187 0 .458-.16.85-.48 1.177-.32.326-.715.49-1.187.49ZM10 10a2.41 2.41 0 0 1-1.77-.73A2.41 2.41 0 0 1 7.5 7.5c0-.708.243-1.302.73-1.781A2.429 2.429 0 0 1 10 5c.708 0 1.302.24 1.781.719.48.479.719 1.073.719 1.781 0 .694-.24 1.285-.719 1.77-.479.487-1.073.73-1.781.73Zm0-1.667c.236 0 .434-.08.594-.24.16-.159.24-.357.24-.593a.806.806 0 0 0-.24-.594.807.807 0 0 0-.594-.24.806.806 0 0 0-.594.24.806.806 0 0 0-.24.594c0 .236.08.434.24.594.16.16.358.24.594.24Z" />
  </svg>
);

export const GroupsIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={GroupsSvg} {...props} />
);
