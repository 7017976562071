export function getUnique<T extends { id: string | number }>(arr: T[]): T[];
export function getUnique<T extends {}>(
  arr: T[],
  identityChecker: (item: T) => string | number
): T[];
export function getUnique<T extends {}>(
  arr: T[],
  identityChecker?: (item: T) => string | number
): T[] {
  const uniques: T[] = [];
  const alreadyMatched: Record<string | number, boolean> = {};

  if (!identityChecker && arr.length >= 1 && !('id' in arr[0]))
    throw Error(
      "You must provide an identityChecker function if the items in the list doesn't contain an id"
    );

  for (const item of arr) {
    const key = identityChecker ? identityChecker(item) : (item as any).id;
    if (alreadyMatched[key] !== true) {
      alreadyMatched[key] = true;
      uniques.push(item);
    }
  }

  return uniques;
}
