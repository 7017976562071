import { gql } from '@apollo/client';
import { SubInitiativeSection__DirectChildInitiativeReportFragment } from '../../../../../../../generated/graphql';
import { Col, Row } from 'antd';
import { InitiativeCard } from '../../../../../../../components/initiatives/initiativeCard/InitiativeCard';
import { MilestoneOrActiveToggleEnum } from '../../../milestoneOrActivityToggle/MilestoneOrActiveToggle';
import { stringSort } from '../../../../../../../services/stringSort';

interface Props {
  subInitiatives: SubInitiativeSection__DirectChildInitiativeReportFragment[];
  milestonesOrActivities: MilestoneOrActiveToggleEnum;
}

export const SubInitiativeSection = ({
  subInitiatives,
  milestonesOrActivities,
}: Props) => {
  const subInitiativeToMap =
    subInitiatives.toSorted((a, b) => stringSort(a.name, b.name)) || [];
  return (
    <Row gutter={[18, 18]} className="mb--xl">
      {subInitiativeToMap.map((subInitiative) => (
        <Col xs={12} xl={8} key={subInitiative.domainId.itemId}>
          <InitiativeCard
            domainId={subInitiative.domainId}
            name={subInitiative.name}
            accumulatedStats={subInitiative.accumulatedStats}
            milestonesOrActivities={milestonesOrActivities}
          />
        </Col>
      ))}
    </Row>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SUB_INITIATIVE_SECTION__DIRECT_CHILD_INITIATIVE_REPORT = gql`
  fragment SubInitiativeSection__DirectChildInitiativeReport on DirectChildInitiativeReport {
    name
    id
    domainId {
      itemId
      tenantId
    }
    accumulatedStats {
      milestones {
        completed
        overdue
        upcoming
        graph {
          date
          completed
          total
        }
      }
      subInitiatives {
        total
      }
      activities {
        completed
        overdue
        upcoming
        graph {
          date
          completed
          total
        }
      }
    }
  }
`;
