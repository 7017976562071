import { Moment } from 'moment';
import { printPeriodRange, standardDateFormat } from '../services/dateFormats';
import { momentToDayjs } from '../services/dateHelpers';

interface DisplayRangeProps {
  startDate: string | Moment | null | undefined;
  endDate: string | Moment | null | undefined;
  className?: string;
  titleDateFormat?: string;
}

export const DisplayDateRange = ({
  startDate,
  endDate,
  className,
}: DisplayRangeProps) => {
  const start = momentToDayjs(startDate);
  const end = momentToDayjs(endDate);
  const longForm = `${start ? standardDateFormat(start) : ''} - ${
    end ? standardDateFormat(end) : ''
  }`;

  return (
    <span className={className} title={longForm}>
      {printPeriodRange(start, end)}
    </span>
  );
};
