import React, { FunctionComponent } from 'react';
import './MitemCard.less';

interface Props {
  title: string;
  actions?: React.ReactNode;
  titleColors: { light: string; dark: string };
  className?: string;
  children?: React.ReactNode;
}

export const MitemCard: FunctionComponent<Props> = ({
  children,
  title,
  titleColors,
  actions,
  className,
}) => (
  <div className={className}>
    <div className="flx flx--jc-space-between">
      <div
        className="MitemCard__tableHeader"
        style={{ color: titleColors.dark, backgroundColor: titleColors.light }}
      >
        {title}
      </div>
      {actions}
    </div>
    {children}
  </div>
);
