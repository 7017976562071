import dayjs from 'dayjs';
import { PlannedSprint } from './PlannedSprint';
import { FinalizedSprint } from './FinalizedSprint';
import { AllSprints } from './AllSprints';
import { ActiveSprint } from './ActiveSprint';
import { SelectedSprint } from './SprintSelector';
import { checkIfPlannedSprint } from '../utils/sprintStatusCheck';
import { gql } from '@apollo/client';
import {
  MitemStatus,
  SkaOverviewSkaTimeStatusFragment,
} from '../../../../../../../../generated/graphql';
import { groupByStatus } from '../../../../../../common/utils/teamPageHelpers';
import './SkaOverview.less';
import { getSelectedSprintKeyActivities } from '../utils/getSelectedSprintKeyActivities';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SKA_OVERVIEW_FRAGMENT = gql`
  fragment SkaOverviewSkaTimeStatus on SkaTimeStatus {
    id
    ...SkaSection_SkaTimeStatus
  }
`;

interface Props {
  selectedSprint: SelectedSprint | null;
  allSKAs: SkaOverviewSkaTimeStatusFragment[];
}

export const SkaOverview = ({ allSKAs, selectedSprint }: Props) => {
  const selectedSprintKeyActivities = getSelectedSprintKeyActivities(
    selectedSprint,
    false,
    allSKAs
  );

  const skasByStatus = groupByStatus(
    selectedSprintKeyActivities,
    Object.values(MitemStatus)
  );

  const todaysDate = dayjs();

  const isPlannedSprint = checkIfPlannedSprint(selectedSprint);

  const isEndedSprint =
    selectedSprint?.endDate &&
    dayjs(selectedSprint.endDate).isBefore(todaysDate);

  const numberOfArchivedActivities = skasByStatus[MitemStatus.ARCHIVED].length;
  const totalNumberOfSprintKeyActivities =
    selectedSprintKeyActivities.length - numberOfArchivedActivities;
  const numberOfCompletedActivities =
    skasByStatus[MitemStatus.COMPLETED].length;
  const numberOfPlannedActivities = skasByStatus[MitemStatus.PLANNED].length;
  const numberOfActiveActivities = skasByStatus[MitemStatus.ACTIVE].length;
  const numberOfUpcomingActivities =
    numberOfPlannedActivities + numberOfActiveActivities;

  const numberOfOverdueActivities = skasByStatus[MitemStatus.OVERDUE].length;

  if (isPlannedSprint) {
    return (
      <div className="SkaOverview__grid">
        <PlannedSprint
          numberOfSprintKeyActivities={totalNumberOfSprintKeyActivities}
          numberOfCompletedActivities={numberOfCompletedActivities}
          numberOfOverdueActivities={numberOfOverdueActivities}
          numberOfUpcomingActivities={numberOfUpcomingActivities}
          numberOfArchivedActivities={numberOfArchivedActivities}
        />
      </div>
    );
  }

  if (isEndedSprint) {
    return (
      <div className="SkaOverview__grid">
        <FinalizedSprint
          numberOfSprintKeyActivities={totalNumberOfSprintKeyActivities}
          numberOfCompletedActivities={numberOfCompletedActivities}
          numberOfOverdueActivities={numberOfOverdueActivities}
          numberOfArchivedActivities={numberOfArchivedActivities}
        />
      </div>
    );
  }

  if (selectedSprint === null) {
    return (
      <div className="SkaOverview__grid">
        <AllSprints
          numberOfSprintKeyActivities={totalNumberOfSprintKeyActivities}
          numberOfCompletedActivities={numberOfCompletedActivities}
          numberOfArchivedActivities={numberOfArchivedActivities}
          numberOfUpcomingActivities={numberOfUpcomingActivities}
          numberOfOverdueActivities={numberOfOverdueActivities}
        />
      </div>
    );
  }

  return (
    <div className="SkaOverview__grid">
      <ActiveSprint
        numberOfSprintKeyActivities={totalNumberOfSprintKeyActivities}
        numberOfCompletedActivities={numberOfCompletedActivities}
        numberOfArchivedActivities={numberOfArchivedActivities}
        numberOfUpcomingActivities={numberOfUpcomingActivities}
        numberOfOverdueActivities={numberOfOverdueActivities}
      />
    </div>
  );
};
