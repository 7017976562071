import { Collapse, CollapseProps } from 'antd';
import cx from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isSprintKA, SprintKA, WeeklyKA } from '../reportDrawerUtils';
import './KaEntry.less';
import { SprintReportForm } from './kaEntry/SprintReportForm';
import { WeekliesReportForm } from './kaEntry/WeekliesReportForm';
import { StatusTag } from '../../../StatusTag';

interface Props {
  keyActivity: SprintKA | WeeklyKA;
  teamId: string;
}

const FORM_PANEL_KEY = 'FORM_PANEL_KEY';

export const KaEntry = ({ keyActivity, teamId }: Props) => {
  const [formOpen, setFormOpen] = useState(false);
  const toggleFormOpen = () => setFormOpen((current) => !current);
  const closeForm = () => setFormOpen(false);

  const items: CollapseProps['items'] = [
    {
      key: FORM_PANEL_KEY,
      label: (
        <div>
          <Tag keyActivity={keyActivity} />
          <div className="KaEntry__title mt--s">{keyActivity.name}</div>
        </div>
      ),
      children: (
        <>
          {isSprintKA(keyActivity) ? (
            <SprintReportForm
              sprintKA={keyActivity}
              teamId={teamId}
              onReported={closeForm}
              onCancel={closeForm}
            />
          ) : (
            <WeekliesReportForm
              weeklyKA={keyActivity}
              teamId={teamId}
              onReported={closeForm}
              onCancel={closeForm}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <Collapse
      className="mb"
      expandIconPosition="end"
      activeKey={formOpen ? FORM_PANEL_KEY : undefined}
      onChange={toggleFormOpen}
      destroyInactivePanel
      items={items}
    />
  );
};

const Tag = ({ keyActivity }: { keyActivity: SprintKA | WeeklyKA }) => {
  const { t } = useTranslation();

  if (isSprintKA(keyActivity))
    return <StatusTag status={keyActivity.mitemStatus} compact />;

  const { achievement, commitment } = keyActivity.progress;

  const completed = (achievement ?? 0) >= (commitment ?? 0);

  return (
    <div>
      <div
        className={cx('KaEntry__weeklyStatus', {
          'KaEntry__weeklyStatus--completed': completed,
        })}
      >
        {t('KaEntry.weekliesProgress', {
          achievement,
          commitment: commitment ?? 0,
        })}
      </div>
    </div>
  );
};
