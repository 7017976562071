import { gql } from '@apollo/client';
import {
  CompanyInitiativeTable__InitiativeLightFragment,
  DurationStatus,
} from '../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import Table, { ColumnProps } from 'antd/es/table';
import { match } from 'ts-pattern';
import { InitiativeTag } from '../../../../initiative/InitiativeTag';
import { stringSort } from '../../../../../services/stringSort';
import { RemoveInitiativeLinkToTenant } from './RemoveInitiativeLinkToTenant';
import { Colors } from '../../../../../appPages/componentLibrary/Colors';
import { CompanyInitiativeTableSkeleton } from './CompanyInitiativeTable.skeleton';

interface Props {
  initiatives: CompanyInitiativeTable__InitiativeLightFragment[];
}

export const CompanyInitiativeTable = ({ initiatives }: Props) => {
  const { t } = useTranslation();
  const initiativeColumns: ColumnProps<CompanyInitiativeTable__InitiativeLightFragment>[] =
    [
      {
        title: t('common.status'),
        width: 120,
        key: 'status',
        dataIndex: ['metadata'],
        align: 'center',
        sorter: (a, b) => stringSort(a.metadata.status, b.metadata.status),
        render: (_, report) => {
          return match(report.metadata.status)
            .with(DurationStatus.COMPLETED, () => (
              <div className="bold" style={{ color: Colors.Status.OK_GREEN }}>
                {t('common.initiative.status.completed')}
              </div>
            ))
            .with(DurationStatus.UPCOMING, () => (
              <div
                className="bold"
                style={{ color: Colors.Status.FUTURE_PURPLE }}
              >
                {t('common.initiative.status.upcoming')}
              </div>
            ))
            .with(DurationStatus.ACTIVE, () => (
              <div className="bold" style={{ color: Colors.Action.BLUE }}>
                {t('common.initiative.status.active')}
              </div>
            ))
            .with(DurationStatus.OVERDUE, () => (
              <div className="bold" style={{ color: Colors.Status.OVERDUE }}>
                {t('common.initiative.status.overdue')}
              </div>
            ))
            .exhaustive();
        },
      },
      {
        title: t('CompanyInitiativeTable.tagColumn'),
        width: 60,
        key: 'status',
        dataIndex: ['tag'],
        render: (tag) => {
          return <InitiativeTag tag={tag} hideTitle />;
        },
      },
      {
        title: t('CompanyInitiativeTable.initiativeColumn'),
        key: 'name',
        sorter: (a, b) => stringSort(a.name, b.name),
        dataIndex: ['name'],
        render: (text) => {
          return (
            <div>
              <div>{text}</div>
            </div>
          );
        },
      },
      {
        title: '',
        key: 'unlink',
        width: 90,
        render: (_, initiative) => (
          <RemoveInitiativeLinkToTenant
            initiativeDomainId={initiative.domainId}
          />
        ),
      },
    ];

  return (
    <Table
      columns={initiativeColumns}
      dataSource={initiatives}
      pagination={false}
      rowKey={(initiative) => initiative.id}
    />
  );
};

CompanyInitiativeTable.Skeleton = CompanyInitiativeTableSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const COMPANY_INITIATIVE_TABLE__TOP_INITIATIVE_REPORT = gql`
  fragment companyInitiativeTable__InitiativeLight on InitiativeLight {
    domainId {
      itemId
      tenantId
    }
    name
    id
    tag {
      title
      iconId
      colorCode
    }
    metadata {
      completedAt
      archived
      status
    }
  }
`;
