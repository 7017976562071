import Icon from '@ant-design/icons';

const CallOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.3 12C9.86667 12 8.46945 11.6806 7.10833 11.0417C5.74722 10.4028 4.54167 9.55833 3.49167 8.50833C2.44167 7.45833 1.59722 6.25278 0.958333 4.89167C0.319444 3.53056 0 2.13333 0 0.7C0 0.5 0.0666667 0.333333 0.2 0.2C0.333333 0.0666667 0.5 0 0.7 0H3.4C3.55556 0 3.69444 0.05 3.81667 0.15C3.93889 0.25 4.01111 0.377778 4.03333 0.533333L4.46667 2.86667C4.48889 3.02222 4.48611 3.16389 4.45833 3.29167C4.43056 3.41944 4.36667 3.53333 4.26667 3.63333L2.65 5.26667C3.11667 6.06667 3.70278 6.81667 4.40833 7.51667C5.11389 8.21667 5.88889 8.82222 6.73333 9.33333L8.3 7.76667C8.4 7.66667 8.53056 7.59167 8.69167 7.54167C8.85278 7.49167 9.01111 7.47778 9.16667 7.5L11.4667 7.96667C11.6222 8 11.75 8.075 11.85 8.19167C11.95 8.30833 12 8.44445 12 8.6V11.3C12 11.5 11.9333 11.6667 11.8 11.8C11.6667 11.9333 11.5 12 11.3 12ZM2.01667 4L3.11667 2.9L2.83333 1.33333H1.35C1.40556 1.78889 1.48333 2.23889 1.58333 2.68333C1.68333 3.12778 1.82778 3.56667 2.01667 4ZM7.98333 9.96667C8.41667 10.1556 8.85833 10.3056 9.30833 10.4167C9.75833 10.5278 10.2111 10.6 10.6667 10.6333V9.16667L9.1 8.85L7.98333 9.96667Z" />
  </svg>
);

export const CallOutlineIcon = (props: any) => (
  <Icon component={CallOutlineIconSvg} {...props} />
);
