import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import copy from 'copy-to-clipboard';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Btn } from './Button';

type BaseProps = {
  buttonText?: string;
  className?: string;
  successText?: string;
};

type Props =
  | (BaseProps & {
      text: string;
    })
  | (BaseProps & {
      onClick: (e: React.MouseEvent<HTMLElement>) => string | Promise<string>;
    });

export const CopyText = ({ buttonText, className, ...props }: Props) => {
  const { t } = useTranslation();
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (showSuccess) {
      timerId = setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    }
    return () => timerId && clearTimeout(timerId);
  }, [showSuccess]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if ('onClick' in props) {
      const toCopy = props.onClick(e);
      if (toCopy instanceof Promise) {
        toCopy.then((text) => {
          copy(text);
          setShowSuccess(true);
        });
      } else {
        copy(toCopy);
        setShowSuccess(true);
      }
    } else {
      copy(props.text);
      setShowSuccess(true);
    }
  };

  return (
    <Tooltip
      title={props.successText || t('CopyText.successText')}
      open={showSuccess}
    >
      <Btn
        size="small"
        className={className}
        onClick={handleClick}
        type="text"
        icon={
          showSuccess ? (
            <CheckOutlined className="txt--success" />
          ) : (
            <CopyOutlined />
          )
        }
      >
        {buttonText ? buttonText : t('CopyText.buttonText')}
      </Btn>
    </Tooltip>
  );
};
