import Icon from '@ant-design/icons';

const CoffeeIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.666504 12V10.6667H11.3332V12H0.666504ZM3.33317 9.33333C2.59984 9.33333 1.97206 9.07222 1.44984 8.55C0.927615 8.02778 0.666504 7.4 0.666504 6.66667V0H11.3332C11.6998 0 12.0137 0.130556 12.2748 0.391667C12.5359 0.652778 12.6665 0.966667 12.6665 1.33333V3.33333C12.6665 3.7 12.5359 4.01389 12.2748 4.275C12.0137 4.53611 11.6998 4.66667 11.3332 4.66667H9.99984V6.66667C9.99984 7.4 9.73873 8.02778 9.2165 8.55C8.69428 9.07222 8.0665 9.33333 7.33317 9.33333H3.33317ZM3.33317 8H7.33317C7.69984 8 8.01373 7.86944 8.27484 7.60833C8.53595 7.34722 8.6665 7.03333 8.6665 6.66667V1.33333H1.99984V6.66667C1.99984 7.03333 2.13039 7.34722 2.3915 7.60833C2.65262 7.86944 2.9665 8 3.33317 8ZM9.99984 3.33333H11.3332V1.33333H9.99984V3.33333Z" />
  </svg>
);

export const CoffeeIcon = (props: any) => (
  <Icon component={CoffeeIconSvg} {...props} />
);
