import { Checkbox } from 'antd';
import { Btn } from '../../../../../../components/Button';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useWizardNavigation } from '../../../../../../components/WizardNavigationProvider';
import { MilestoneSuggestion } from './Step2';
import { CheckboxGroupProps } from 'antd/es/checkbox';
import { isArrayOfStrings } from '../../../../../../services/typeGuards';

interface Props {
  milestoneSuggestion: MilestoneSuggestion;
  activitySuggestions: ActivitySuggestion[];
  selectedActivitySuggestions: string[];
  onSelectActivitySuggestions: (activities: string[]) => void;
}

export const Step3 = ({
  milestoneSuggestion,
  activitySuggestions,
  selectedActivitySuggestions,
  onSelectActivitySuggestions,
}: Props) => {
  const wizard = useWizardNavigation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 700);

    return () => clearTimeout(timer);
  }, []);

  const handleCheckboxClick: CheckboxGroupProps['onChange'] = (selected) => {
    if (!isArrayOfStrings(selected)) {
      throw new Error('Expected array of strings');
    }

    if (selected.length > 0) {
      wizard.unlockStep(wizard.currentStepPath);
    } else {
      wizard.lockStep(wizard.currentStepPath);
    }

    onSelectActivitySuggestions(selected);
  };

  return (
    <div>
      <h3 className="mb--l">Create plan: {milestoneSuggestion.area}</h3>

      <div className="font-size--sm pb--l borderBottom">
        <span className="bold space--r">Definition of done:</span>
        {milestoneSuggestion.description}
      </div>
      <div>
        {loading ? (
          activitySuggestions.map((activity, index) => (
            <div className="borderBottom pa" key={index}>
              <h4>
                <Skeleton count={0.6}></Skeleton>
              </h4>
              <p className="font-size--sm">
                <Skeleton count={2.3}></Skeleton>
              </p>
            </div>
          ))
        ) : (
          <Checkbox.Group
            onChange={handleCheckboxClick}
            value={selectedActivitySuggestions}
          >
            {activitySuggestions.map((activity) => (
              <div className="borderBottom pa" key={activity.id}>
                <Checkbox value={activity.id}>
                  <div className="pl">
                    <h4>{activity.headline}</h4>
                    <p className="font-size--sm txt--secondary">
                      {activity.description}
                    </p>
                  </div>
                </Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        )}
      </div>

      <div className="mt--l flx flx--jc-flx-end">
        <Btn type="link" onClick={wizard.goToPreviousStep}>
          Go back to change proposition
        </Btn>
        <Btn
          type="primary"
          onClick={wizard.goToNextStep}
          disabled={
            loading ||
            (!!wizard.activeStep.nextStep &&
              wizard.isLocked(wizard.activeStep.nextStep))
          }
        >
          Add details
        </Btn>
      </div>
    </div>
  );
};

export type ActivitySuggestion = {
  id: string;
  headline: string;
  description: string;
};
