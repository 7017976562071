import { TeamGantishChart } from './components/TeamGantishChart';

import './TeamGantish.less';
import { TeamGantishLegend } from './components/TeamGantishLegend';
import { useTranslation } from 'react-i18next';

export const TeamGantishSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className="Gantish">
      <h3>{t('TeamGantish.title')}</h3>
      <div className="flx">
        <TeamGantishLegend.Skeleton />
        <div className="Gantish__chart">
          <TeamGantishChart.Skeleton />
        </div>
      </div>
    </div>
  );
};
