import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const TextAreaIconIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <g mask="url(#a)">
      <path
        d="m13.688 17.48-2.355-2.355 1.167-1.167 1.188 1.188 2.645-2.646 1.167 1.167-3.813 3.812ZM2.5 17.5v-1.667H10V17.5H2.5Zm0-3.333V12.5H10v1.667H2.5Zm0-3.334V9.167h15v1.666h-15Zm0-3.333V5.833h15V7.5h-15Zm0-3.333V2.5h15v1.667h-15Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const TextAreaIconIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={TextAreaIconIconSvg} {...props} />
);
