import { LineChartOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DotProps,
  Line,
  LineChart,
  LineProps as BrokenLineProps,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CustomTooltip } from '../../../../../../../../../components/graphs/CustomTooltip';
import { friendlyDate } from '../../../../../../../../../services/dateFormats';
import {
  roundToDecimals,
  roundToFloor,
} from '../../../../../../../../../services/roundNumbersHelper';
import { lineColor } from '../../../../../../../../../styleVars';
import './KeyActivitySummaryGraph.less';
import { statusColorHelper } from '../../../../../../../../../services/statusColorHelper';

type LineProps = Omit<BrokenLineProps, 'ref'>; // Don't know why but if we don't remove ref it fails to compile due to type missmatch

interface KeyActivitySummaryGraphProps {
  periodData: {
    targetDate: string;
    goal: number;
    actual: number | null;
    committed?: number | null;
  }[];
  referenceDate?: string | null;
  activeDot?: string | null;
  height?: number;
  children?: React.ReactNode;
  targetFulfillment?: number | null;
}

// Todo: refactor to use fragments for Props
export const KeyActivitySummaryGraph = ({
  periodData,
  referenceDate,
  activeDot,
  children,
  targetFulfillment,
}: KeyActivitySummaryGraphProps) => {
  const { t } = useTranslation();
  const [graphHeight, setGraphHeight] = useState(0);

  const graphPeriods = periodData.map((pdwc) => ({
    ...pdwc,
    label: friendlyDate(pdwc.targetDate),
  }));

  const referencePeriod = graphPeriods.find((gp) =>
    dayjs(gp.targetDate).isSame(referenceDate, 'day')
  );

  useEffect(() => {
    setGraphHeight(100);
  }, []);

  if (periodData.length === 0)
    return (
      <div className="AkpiGraph__noDataWrapper">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );

  const lineConf = [
    { dataKey: 'goal', stroke: lineColor.goal, name: 'Goal' },
    { dataKey: 'actual', stroke: lineColor.actual, name: 'Actual' },
    {
      dataKey: 'committed',
      name: 'Committed',
      stroke: lineColor.actual,
      strokeWidth: 2,
      strokeDasharray: '6,4',
      dot: false,
    },
  ];

  const baseProps: LineProps = {
    type: 'linear',
    isAnimationActive: false,
    strokeWidth: 2,
    dot: ({ key, ...props }) => (
      <CustomDot
        {...props}
        key={key}
        highlightedPeriod={referencePeriod?.targetDate}
        activeDot={activeDot}
      />
    ),
  };

  return (
    <div style={{ width: '100%', height: graphHeight, marginBottom: '80px' }}>
      <ResponsiveContainer debounce={300}>
        <LineChart
          data={graphPeriods}
          margin={{
            top: 12,
            bottom: 5,
            left: 0,
            right: 13,
          }}
        >
          <Tooltip
            formatter={(value: any) =>
              typeof value === 'number' ? roundToDecimals(value, 2) : value
            }
            labelFormatter={(label) =>
              graphPeriods.find((p) => p.targetDate === label)?.label
            }
            content={CustomTooltip}
          />
          <XAxis dataKey={'targetDate'} hide={true} />
          <YAxis hide={true} />

          <ReferenceLine
            x={referencePeriod?.targetDate}
            stroke={lineColor.currentPeriod}
            strokeDasharray="3 3"
          />

          {lineConf.map((lineProps, i) => {
            const mergedProps = { ...baseProps, ...lineProps };
            return <Line key={i} {...mergedProps} />;
          })}

          {children}
        </LineChart>
      </ResponsiveContainer>
      <div className="KeyActivityGraph--description flx flx--jc-space-between mt--l pr pl">
        <div className="legend">
          <div className="flx flx--ai-center">
            <LineChartOutlined style={{ color: lineColor.goal }} />
            <span style={{ color: lineColor.goal }} className="ml--s">
              {t('WeeklyKeyActivitySummaryGraph.goal', {
                goal: referencePeriod?.goal ?? '-',
              })}
            </span>
          </div>
          <div className="flx flx--ai-center">
            <LineChartOutlined style={{ color: lineColor.actual }} />
            <span style={{ color: lineColor.actual }} className="ml--s">
              {t('WeeklyKeyActivitySummaryGraph.actual', {
                actual: referencePeriod?.actual ?? '-',
              })}
            </span>
          </div>
        </div>
        <p
          style={{ color: statusColorHelper(targetFulfillment) }}
          className={'KeyActivitySummaryGraph__targetFulfillment'}
        >
          {roundToFloor(targetFulfillment)}%
        </p>
      </div>
    </div>
  );
};

interface CustomDotProps extends DotProps {
  index: number;
  dataKey: string;
  payload: {
    targetDate: string;
  };
  highlightedPeriod: string;
  activeDot?: string;
}

const CustomDot = (props: CustomDotProps) => {
  if (!props.cx || !props.cy) return null;

  if (props.payload.targetDate === props.activeDot) {
    return (
      <circle
        r={3}
        strokeWidth="1"
        stroke={props.stroke}
        fill={props.stroke}
        className="recharts-dot recharts-line-dot"
        cx={props.cx}
        cy={props.cy}
      />
    );
  }

  if (props.payload.targetDate === props.highlightedPeriod) {
    return (
      <circle
        r={5}
        strokeWidth="1"
        stroke={props.stroke}
        fill={'#ffff'}
        className="recharts-dot recharts-line-dot"
        cx={props.cx}
        cy={props.cy}
      />
    );
  }

  return null;
};
