import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const StarSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.854 16.333l1.354-5.854L.667 6.542l6-.521L9 .5l2.333 5.52 6 .522-4.541 3.937 1.354 5.854L9 13.23l-5.146 3.104z" />
  </svg>
);

export const StarIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={StarSvg} {...props} />
);
