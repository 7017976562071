import Icon from '@ant-design/icons';

const MoneyOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.33317 6.66663C8.77762 6.66663 8.30539 6.47218 7.9165 6.08329C7.52762 5.6944 7.33317 5.22218 7.33317 4.66663C7.33317 4.11107 7.52762 3.63885 7.9165 3.24996C8.30539 2.86107 8.77762 2.66663 9.33317 2.66663C9.88873 2.66663 10.3609 2.86107 10.7498 3.24996C11.1387 3.63885 11.3332 4.11107 11.3332 4.66663C11.3332 5.22218 11.1387 5.6944 10.7498 6.08329C10.3609 6.47218 9.88873 6.66663 9.33317 6.66663ZM4.6665 8.66663C4.29984 8.66663 3.98595 8.53607 3.72484 8.27496C3.46373 8.01385 3.33317 7.69996 3.33317 7.33329V1.99996C3.33317 1.63329 3.46373 1.3194 3.72484 1.05829C3.98595 0.797182 4.29984 0.666626 4.6665 0.666626H13.9998C14.3665 0.666626 14.6804 0.797182 14.9415 1.05829C15.2026 1.3194 15.3332 1.63329 15.3332 1.99996V7.33329C15.3332 7.69996 15.2026 8.01385 14.9415 8.27496C14.6804 8.53607 14.3665 8.66663 13.9998 8.66663H4.6665ZM5.99984 7.33329H12.6665C12.6665 6.96663 12.7971 6.65274 13.0582 6.39163C13.3193 6.13052 13.6332 5.99996 13.9998 5.99996V3.33329C13.6332 3.33329 13.3193 3.20274 13.0582 2.94163C12.7971 2.68052 12.6665 2.36663 12.6665 1.99996H5.99984C5.99984 2.36663 5.86928 2.68052 5.60817 2.94163C5.34706 3.20274 5.03317 3.33329 4.6665 3.33329V5.99996C5.03317 5.99996 5.34706 6.13052 5.60817 6.39163C5.86928 6.65274 5.99984 6.96663 5.99984 7.33329ZM13.3332 11.3333H1.99984C1.63317 11.3333 1.31928 11.2027 1.05817 10.9416C0.797059 10.6805 0.666504 10.3666 0.666504 9.99996V2.66663H1.99984V9.99996H13.3332V11.3333Z" />
  </svg>
);

export const MoneyOutlineIcon = (props: any) => (
  <Icon component={MoneyOutlineIconSvg} {...props} />
);
