import { P, match } from 'ts-pattern';
import { DurationStatus } from '../../../../../../../../generated/graphql';
import './InitiativeValue.less';
import cx from 'classnames';

interface Props {
  value?: {
    potential?: number | null;
    current?: number | null;
  } | null;
  status?: DurationStatus;
  archived?: boolean;
  deprecatedStatus?: DurationStatus;
  unit?: string | null;
}

export const InitiativeValue = ({
  value,
  unit,
  status,
  archived,
  deprecatedStatus,
}: Props) => {
  const ratio = match({ potential: value?.potential, current: value?.current })
    .with({ potential: P.nullish, current: P.nullish }, () => 0)
    .with({ potential: P.number, current: P.nullish }, () => 0)
    .with({ potential: P.nullish, current: P.number }, () => 1)
    .with(
      { potential: P.number, current: P.number },
      ({ potential, current }) => current / potential
    )
    .exhaustive();

  const current = value?.current ?? missingValue;
  const potential = value?.potential ?? missingValue;

  const hasAnyValue = value?.current != null || value?.potential != null;

  return (
    <div className="InitiativeValue">
      <span>{current}</span>
      <span className="space--l space--r">/</span>
      <span className="bold">{potential}</span>
      {hasAnyValue && <span className="bold space--l">{unit}</span>}
      <div
        className={cx('InitiativeValue__viz', {
          'InitiativeValue__viz--completed':
            status === 'COMPLETED' ||
            deprecatedStatus === DurationStatus.COMPLETED,
          'InitiativeValue__viz--archived': archived,
          'InitiativeValue__viz--upcoming':
            status === 'UPCOMING' ||
            deprecatedStatus === DurationStatus.UPCOMING,
          'InitiativeValue__viz--overdue':
            status === 'OVERDUE' || deprecatedStatus === DurationStatus.OVERDUE,
          'InitiativeValue__viz--active':
            status === 'ACTIVE' || deprecatedStatus === DurationStatus.ACTIVE,
          'InitiativeValue__viz--empty':
            value?.potential == null && value?.current == null,
        })}
        style={{ ['--value-progress' as any]: ratio * 100 + '%' }}
      />
    </div>
  );
};

const missingValue = '-';
