import {
  FullscreenOutlined,
  NodeCollapseOutlined,
  NodeExpandOutlined,
} from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { MigOutOfDateWarning } from '../../../../../../components/mig/MigOutOfDateWarning';
import { useMigPerformance } from '../../../../../../hooks/useMigPerformanceQuery';
import './MigListEntry.less';
import { Btn } from '../../../../../../components/Button';

import { PerformanceTag } from '../../../../../../components/PerformanceTag';
import { useTeamMigV2 } from '../../../../../../components/setup/migSetupPage/hooks/useTeamMigV2';
interface BaseMig {
  id: string;
  name: string;
  team: {
    id: string;
    name: string;
    teamHierarchy: { id: string; name: string }[];
  };
  domainId: {
    itemId: string;
    teamId: string;
  };
}

interface Props {
  mig: BaseMig;
  isExpanded: boolean;
  onExpandRelations: () => void;
  onInspectChildMig: (mig: BaseMig | null) => void;
  faded?: boolean;
}

export const MigListEntry = ({
  mig,
  isExpanded,
  faded,
  onExpandRelations,
  onInspectChildMig,
}: Props) => {
  const { t } = useTranslation();
  const { migPerformance, loading } = useMigPerformance(
    mig.team.id,
    mig.domainId.itemId
  );

  const { teamMig, loading: teamMigLoading } = useTeamMigV2(
    mig.team.id,
    mig.domainId.itemId
  );

  const supportingMigsCounter = teamMig?.supportedByMigs?.length ?? 0;

  return (
    <div
      className={cx({
        MigListEntry: true,
        'MigListEntry--hasSupporters': supportingMigsCounter > 0,
        'MigListEntry--showingSupporters': isExpanded,
        'MigListEntry--faded': faded,
      })}
      onClick={() => {
        onExpandRelations();
      }}
    >
      <div className="flx--1">
        <div className="MigListEntry__header">
          <PerformanceTag loading={loading} value={migPerformance} />
          {teamMig && (
            <MigOutOfDateWarning
              mig={teamMig}
              iconClassName="MigListEntry__warningIcon font-size--default ml"
            />
          )}

          <div className="MigListEntry__actions">
            <Tooltip title={t('MigListEntry.showMigDetails')}>
              <Btn
                className="mr--s"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onInspectChildMig(mig);
                }}
                icon={<FullscreenOutlined />}
              />
            </Tooltip>
            <Tooltip title={t('MigListEntry.showRelatedMigs')}>
              <Spin spinning={teamMigLoading} size="small">
                <Btn
                  type={isExpanded ? 'primary' : 'default'}
                  size="small"
                  icon={
                    isExpanded ? (
                      <NodeCollapseOutlined />
                    ) : (
                      <NodeExpandOutlined />
                    )
                  }
                  disabled={supportingMigsCounter === 0}
                >
                  <span className="ml--xs">
                    {teamMig?.supportedByMigs?.length ?? '-'}
                  </span>
                </Btn>
              </Spin>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="MigListEntry__migName">{mig.name}</div>
      <div className="MigListEntry__team">
        {t('MigListEntry.team', { teamName: mig.team.name })}
      </div>
    </div>
  );
};
