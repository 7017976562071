export function getWkaAveragePerf(
  teamKeyActivityPerformance:
    | { type: string; targetFulfillment: number | null }[]
    | null
    | undefined
) {
  const weeklyKeyActivityPerformance = teamKeyActivityPerformance?.filter(
    (ka) => ka.type === 'WEEKLY'
  );

  if (
    !weeklyKeyActivityPerformance ||
    weeklyKeyActivityPerformance.length === 0
  )
    return null;

  const wkaSummedPerf = weeklyKeyActivityPerformance.reduce(
    (acc, curr) => acc + (curr?.targetFulfillment ?? 0),
    0
  );

  const wkaAveragePerf = wkaSummedPerf / weeklyKeyActivityPerformance.length;

  return wkaAveragePerf;
}
