import { MinusOutlined } from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult, SortOrder } from 'antd/lib/table/interface';
import { Suspense, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayDate } from '../../../../../components/DisplayDate';
import { DisplayName } from '../../../../../components/DisplayName';
import { ExpandArrow } from '../../../../../components/ExpandArrow';
import {
  GetMitemDetailsDocument,
  MitemActionType,
  SprintMitemTable_MilestoneFragment,
  SprintMitemTable_SkaTimeStatusFragment,
} from '../../../../../generated/graphql';
import { StarIcon } from '../../../../../icons/Star';
import { dateCompare } from '../../../../../services/dateHelpers';
import { friendlyUsername } from '../../../../../services/friendlyUsername';
import { recordDefaultSortOrder } from '../../../../../services/sprintKeyActivityUtils';
import { stringSort } from '../../../../../services/stringSort';
import { mitemStatusColor } from '../../../../../styleVars';
import { InitiativeTag } from '../../../../../components/initiative/InitiativeTag';
import {
  Icons,
  InitiativeIcon,
} from '../../../../company/initiatives_old/initiativesPageV1/InitiativeIcons';
import { Colors } from '../../../../componentLibrary/Colors';
import { TagImage } from '../../../setup/overviewPage/labels/TagImage';
import { tagsContext } from '../../common/TeamSprintProvider';
import { MitemFilterStatus } from '../../common/components/mitemFilters/StatusFilter';
import { MitemHistory } from '../../common/components/MitemHistory';
import {
  migFilterCompare,
  ownerFilterCompare,
  statusFilterCompare,
  sprintKeyActivityHasTagMatch,
  milestoneFilterCompare,
} from '../../common/hooks/useMitemFilters';
import './SprintMitemTable.less';
import { CommitColumn } from './sprintMitemTable/CommitColumn';
import { StatusTag } from '../../../../../components/StatusTag';
import { useTenantDetails } from '../../../../../hooks/useTenantDetails';
import { match } from 'ts-pattern';
import { ErrorBoundary } from 'react-error-boundary';
import { ProgressStats } from '../../../../../components/initiatives/ProgressStats';
import { MilestoneCardActivityStats } from '../../planning/components/mitemPlanningBoard/milestoneCard/MilestoneCardActivityStats';

export type MitemRescheduleAction = {
  action: MitemActionType.PLANNED;
  date: string;
};

export type MitemCompleteAction = {
  action: MitemActionType.COMPLETED;
};

export type MitemDeleteAction = {
  action: MitemActionType.ARCHIVED;
};

export type MitemAction =
  | MitemRescheduleAction
  | MitemDeleteAction
  | MitemCompleteAction;

interface Props {
  timeStatuses: SprintMitemTable_SkaTimeStatusFragment[] | null;
  milestones?: SprintMitemTable_MilestoneFragment[] | null;
  loading?: boolean;
  filters?: {
    ownerId?: string;
    status?: MitemFilterStatus;
    migId?: string;
    milestoneId?: string;
  };
  actionColumn?: ColumnProps<
    SprintMitemTable_SkaTimeStatusFragment | SprintMitemTable_MilestoneFragment
  >;
  pagination?: boolean;
  includeStatus?: boolean;
  committable?: boolean;
  teamId?: string;
  mitemActions?: {
    [mitemId: string]: MitemAction | null;
  };
}

interface ColumnPropsWithExclude<T> extends ColumnProps<T> {
  excluded?: boolean;
}

const sortTeamTags = (timeStatus: SprintMitemTable_SkaTimeStatusFragment) => {
  const teamTags = timeStatus.sprintKeyActivity?.tags ?? [];

  const sortedTeamTags = [...teamTags].sort((a, b) => {
    return stringSort(a.name, b.name);
  });
  return sortedTeamTags;
};

const sortInitiativeTags = (
  features: {
    teamInitiativesEnabled: boolean;
    tenantInitiativesEnabled: boolean;
  },
  timeStatus: SprintMitemTable_SkaTimeStatusFragment
) => {
  const supportedInitiatives = match(features)
    .with({ teamInitiativesEnabled: true }, () => {
      return (
        timeStatus.sprintKeyActivity?.supportedInitiatives?.map(
          (teamInitiative) => {
            return {
              id: teamInitiative.id,
              tag: teamInitiative.tag,
              metadata: {
                archived: teamInitiative.archived,
                completedAt: teamInitiative.completed.value
                  ? teamInitiative.completed
                  : null,
              },
            };
          }
        ) ?? []
      );
    })
    .with({ tenantInitiativesEnabled: true }, () => {
      return (
        timeStatus.sprintKeyActivity?.supportsInitiatives2?.map(
          (i) => i.initiative
        ) ?? []
      );
    })
    .otherwise(() => {
      return [];
    });

  const sortedInitiativeTags = [...supportedInitiatives].sort((a, b) => {
    return stringSort(a.tag.title, b.tag.title);
  });
  return sortedInitiativeTags;
};

type SprintMitemTableColumnType =
  | SprintMitemTable_SkaTimeStatusFragment
  | SprintMitemTable_MilestoneFragment;

export const SprintMitemTable = ({
  timeStatuses,
  milestones,
  loading,
  filters,
  actionColumn,
  pagination = false,
  includeStatus = true,
  committable = false,
  mitemActions,
  teamId,
}: Props) => {
  const { t } = useTranslation();
  const { features } = useTenantDetails();
  const { tagsSelectedForFiltering, untaggedOnly, andOrChoice } = useContext(
    tagsContext
  ) as any;

  const [sortInfo, setSortedInfo] = useState<
    SorterResult<
      | SprintMitemTable_SkaTimeStatusFragment
      | SprintMitemTable_MilestoneFragment
    >[]
  >([]);

  const handleChange = (
    sorter:
      | SorterResult<
          | SprintMitemTable_SkaTimeStatusFragment
          | SprintMitemTable_MilestoneFragment
        >
      | SorterResult<
          | SprintMitemTable_SkaTimeStatusFragment
          | SprintMitemTable_MilestoneFragment
        >[]
  ) => {
    setSortedInfo(Array.isArray(sorter) ? sorter : [sorter]);
  };

  useEffect(() => {
    setSortedInfo([{ columnKey: 'deadline', order: 'ascend' }]);
  }, [tagsSelectedForFiltering]);

  const sprintKeyActivityHasTags = (
    record:
      | SprintMitemTable_SkaTimeStatusFragment
      | SprintMitemTable_MilestoneFragment
  ) => {
    let hasTags = false;
    if (record.__typename === 'SkaTimeStatus') {
      const hasInitiativeTags = match(features)
        .with(
          { teamInitiativesEnabled: true, tenantInitiativesEnabled: false },
          () =>
            (record?.sprintKeyActivity?.supportedInitiatives?.length ?? 0) > 0
        )
        .with(
          { teamInitiativesEnabled: false, tenantInitiativesEnabled: true },
          () =>
            (record?.sprintKeyActivity?.supportsInitiatives2?.length ?? 0) > 0
        )
        .with(
          { teamInitiativesEnabled: false, tenantInitiativesEnabled: false },
          () => false
        )
        .with(
          { teamInitiativesEnabled: true, tenantInitiativesEnabled: true },
          () => {
            throw new Error('Both team and tenant initiatives enabled');
          }
        )
        .exhaustive();
      hasTags =
        hasInitiativeTags && (record?.sprintKeyActivity?.tags?.length ?? 0) > 0;
    } else if (record.__typename === 'Milestone') {
      hasTags =
        (record.metadata.supports.filter(
          (s) => s.__typename === 'SupportedInitiative'
        ).length ?? 0) > 0;
    }

    return hasTags;
  };

  const tableDataToBeFiltered = [
    ...(timeStatuses ?? []),
    ...(milestones?.filter((m) => m.metadata.archived == false) ?? []),
  ];

  const filteredTableData = tableDataToBeFiltered
    ?.filter((m) =>
      ownerFilterCompare(
        m.__typename === 'SkaTimeStatus'
          ? m.owner.id
          : m.assignedTo[0].domainId.itemId,
        filters?.ownerId
      )
    )
    .filter((m) =>
      statusFilterCompare(
        m.__typename === 'SkaTimeStatus' ? m.status : m.metadata.status,
        filters?.status
      )
    )
    .filter((m) =>
      milestoneFilterCompare(
        m.__typename === 'SkaTimeStatus'
          ? m.sprintKeyActivity?.supportsMilestones
          : [{ id: m.id }],
        filters?.milestoneId
      )
    )
    .filter((m) =>
      m.__typename === 'SkaTimeStatus'
        ? migFilterCompare(
            m.sprintKeyActivity?.supportedMigs.map(
              (sm) => sm.domainId.itemId
            ) ?? [],
            filters?.migId
          )
        : true
    );

  const sprintMitemColumns: ColumnPropsWithExclude<SprintMitemTableColumnType>[] =
    [
      {
        title: t('common.sprintKeyActivity.status'),
        excluded: !includeStatus,
        width: 90,
        key: 'status',
        sorter: (a, b, sortOrder) => {
          const t = compareTags(a, b, sortOrder);
          const statusA =
            a.__typename === 'Milestone' ? a.metadata.status : a.status;
          const statusB =
            b.__typename === 'Milestone' ? b.metadata.status : b.status;
          return (
            t ||
            recordDefaultSortOrder.indexOf(statusA) -
              recordDefaultSortOrder.indexOf(statusB)
          );
        },
        sortOrder: sortInfo.find((i) => i.columnKey === 'status')?.order,
        render: (_, record) => {
          return (
            <div className="flx flx--column dimmable">
              {record.__typename === 'Milestone' ? (
                <StatusTag status={record.metadata.status} />
              ) : (
                <StatusTag status={record.status} />
              )}
            </div>
          );
        },
      },
      {
        title: t('common.title'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b, sortOrder) => {
          const t = compareTags(a, b, sortOrder);
          return t || stringSort(a.name, b.name);
        },
        sortOrder: sortInfo.find((i) => i.columnKey === 'name')?.order,
        render: (_, record) => {
          return (
            <div className="dimmable">
              <Typography.Text>{record.name}</Typography.Text>
              {record.__typename === 'Milestone' && (
                <ErrorBoundary
                  FallbackComponent={MilestoneCardActivityStats.Error}
                >
                  <Suspense fallback={<ProgressStats.Skeleton />}>
                    {teamId && (
                      <div className="flx txt--secondary flx--ai-center">
                        <div className="mr">
                          {t('SprintMitemTable.activityProgress')}
                        </div>
                        <MilestoneCardActivityStats
                          milestoneId={record.domainId.itemId}
                          teamId={teamId}
                        />
                      </div>
                    )}
                  </Suspense>
                </ErrorBoundary>
              )}
            </div>
          );
        },
      },
      {
        title: t('common.sprintKeyActivity.owner'),
        dataIndex: ['owner', 'id'],
        key: 'owner',
        sortOrder: sortInfo.find((i) => i.columnKey === 'owner')?.order,
        sorter: (a, b, sortOrder) => {
          const t = compareTags(a, b, sortOrder);
          const ownerA =
            a.__typename === 'Milestone' ? a.assignedTo[0] : a.owner;
          const ownerB =
            b.__typename === 'Milestone' ? b.assignedTo[0] : b.owner;

          const ownerSort = stringSort(
            friendlyUsername(ownerA),
            friendlyUsername(ownerB)
          );
          const deadlineA =
            a.__typename === 'Milestone' ? a.deadlineAt : a.deadline;
          const deadlineB =
            b.__typename === 'Milestone' ? b.deadlineAt : b.deadline;
          const deadlineSort = dateCompare(deadlineA, deadlineB);
          const deadlineDirection = sortOrder === 'descend' ? -1 : 1;

          return t || ownerSort || deadlineSort * deadlineDirection;
        },
        render: (_, record) => {
          if (record.__typename === 'Milestone') {
            return (
              <div className="dimmable">
                <DisplayName user={record.assignedTo[0]} />
              </div>
            );
          }
          return (
            <div className="dimmable">
              <DisplayName user={record.owner} />
            </div>
          );
        },
      },
      {
        title: t('common.sprintKeyActivity.deadline'),
        sorter: (a, b, sortOrder) => {
          const t = compareTags(a, b, sortOrder);
          const deadlineA =
            a.__typename === 'Milestone' ? a.deadlineAt : a.deadline;
          const deadlineB =
            b.__typename === 'Milestone' ? b.deadlineAt : b.deadline;
          const deadlineSort = dateCompare(deadlineA, deadlineB);
          const ownerA =
            a.__typename === 'Milestone' ? a.assignedTo[0] : a.owner;
          const ownerB =
            b.__typename === 'Milestone' ? b.assignedTo[0] : b.owner;
          const ownerSort = stringSort(
            friendlyUsername(ownerA),
            friendlyUsername(ownerB)
          );
          return t || deadlineSort || ownerSort;
        },
        defaultSortOrder: 'ascend',
        dataIndex: 'deadline',
        key: 'deadline',
        sortOrder: sortInfo.find((i) => i.columnKey === 'deadline')?.order,
        render: (_, record) => {
          const formattedDeadline = (
            <div className="dimmable">
              {record.__typename === 'Milestone' ? (
                <DisplayDate date={record.deadlineAt} />
              ) : (
                <DisplayDate date={record.deadline} />
              )}
            </div>
          );
          if (record.__typename === 'SkaTimeStatus') {
            const handledMitem = mitemActions?.[record.id];
            if (handledMitem?.action === MitemActionType.PLANNED) {
              return (
                <div className={'flx flx--column'}>
                  <Typography.Text type="secondary" className="lineThrough">
                    {formattedDeadline}
                  </Typography.Text>
                  <div style={{ color: mitemStatusColor.PLANNED }}>
                    <DisplayDate date={handledMitem.date} />
                  </div>
                </div>
              );
            }
          }
          return formattedDeadline;
        },
      },
      {
        title: <StarIcon title={t('common.sprintKeyActivity.milestone')} />,
        width: 48,
        dataIndex: 'milestone',
        render(_, record) {
          if (record.__typename === 'SkaTimeStatus') {
            return (
              <span style={{ fontSize: 16 }}>
                {record.sprintKeyActivity?.milestone ? (
                  <StarIcon className="dimmable" />
                ) : (
                  <MinusOutlined
                    style={{ color: Colors.Grays.FRAMES_AND_LINES }}
                  />
                )}
              </span>
            );
          }
        },
      },
      {
        title: t('common.sprintKeyActivity.tags'),
        render: (_, record) => {
          let tags = [] as React.ReactNode[];
          if (record.__typename === 'SkaTimeStatus') {
            const sortedTeamTags =
              sortTeamTags(record).map((t) => (
                <TagImage key={t.id} tag={t} />
              )) ?? [];

            const sortedInitiativeTags =
              sortInitiativeTags(features, record).map(
                ({ id, tag, metadata }) => (
                  <InitiativeTag
                    key={id}
                    title={tag.title}
                    borderColor={tag.colorCode}
                    completed={metadata.completedAt !== null}
                    icon={Icons[tag.iconId as InitiativeIcon]}
                    archived={metadata.archived}
                  />
                )
              ) ?? [];

            tags = [...sortedInitiativeTags, ...sortedTeamTags];
          }
          if (record.__typename === 'Milestone') {
            tags =
              record.metadata.supports
                ?.filter((si) => si.__typename === 'SupportedInitiative')
                .map((si) => (
                  <InitiativeTag
                    key={si.item.id}
                    title={si.item.tag.title}
                    borderColor={si.item.tag.colorCode}
                    completed={si.item.metadata.completedAt !== null}
                    icon={Icons[si.item.tag.iconId as InitiativeIcon]}
                  />
                )) ?? [];
          }
          return (
            <div className="flx flx--gap--xs flx--wrap dimmable">
              {tags.length > 0 ? tags : <span>-</span>}
            </div>
          );
        },
      },
      ...(actionColumn ? [actionColumn] : []),
      {
        title: t('common.commitment', { count: filteredTableData?.length }),
        defaultSortOrder: 'ascend',
        excluded: !committable,
        dataIndex: 'commitment',
        render: (_, record) => {
          return (
            record.__typename === 'SkaTimeStatus' && (
              <div className="dimmable">
                <CommitColumn
                  sprintKaId={record.id}
                  ownerId={record.owner.id}
                />
              </div>
            )
          );
        },
      },
      Table.EXPAND_COLUMN,
    ];

  const compareTags = (
    a:
      | SprintMitemTable_SkaTimeStatusFragment
      | SprintMitemTable_MilestoneFragment,
    b:
      | SprintMitemTable_SkaTimeStatusFragment
      | SprintMitemTable_MilestoneFragment,
    sortDirection: SortOrder | undefined
  ) => {
    if (!sortDirection) return 0;
    const aHasMatch =
      (!untaggedOnly &&
        sprintKeyActivityHasTagMatch(
          features,
          a,
          andOrChoice,
          tagsSelectedForFiltering
        )) ||
      (!sprintKeyActivityHasTags(a) && untaggedOnly);
    const bHasMatch =
      (!untaggedOnly &&
        sprintKeyActivityHasTagMatch(
          features,
          b,
          andOrChoice,
          tagsSelectedForFiltering
        )) ||
      (!sprintKeyActivityHasTags(b) && untaggedOnly);
    if (aHasMatch === bHasMatch) {
      return 0;
    } else if (sortDirection === 'ascend') {
      return aHasMatch ? -1 : 1;
    } else {
      return bHasMatch ? 1 : -1;
    }
  };

  return (
    <Table
      className="SprintMitemTableCard__mitemTable"
      rowHoverable={false}
      columns={sprintMitemColumns.filter((c) => !c.excluded)}
      dataSource={filteredTableData}
      onChange={(_pagination, _filter, sorter) => handleChange(sorter)}
      expandable={{
        rowExpandable(record) {
          return record.__typename === 'SkaTimeStatus';
        },
        expandRowByClick: true,
        expandedRowRender: (record) => {
          if (record.__typename === 'Milestone') {
            return null;
          }

          return <MitemDetails timeStatus={record} />;
        },
        expandIcon: ({ expanded, onExpand, record }) => {
          if (record.__typename === 'Milestone') return null;
          return (
            <ExpandArrow
              onClick={(e) => onExpand(record, e)}
              expanded={expanded}
            />
          );
        },
      }}
      loading={loading}
      pagination={!pagination ? false : { size: 'small' }}
      rowKey={(timeStatus) => timeStatus.id}
      rowClassName={(record) => {
        if (record.__typename === 'Milestone') {
          // Add status class to milestone rows
          return `SprintMitemTable__row__milestone SprintMitemTable__row__milestone--${record.metadata.status}`;
        }
        return (untaggedOnly && sprintKeyActivityHasTags(record)) ||
          (!untaggedOnly &&
            !sprintKeyActivityHasTagMatch(
              features,
              record,
              andOrChoice,
              tagsSelectedForFiltering
            ))
          ? 'MitemListEntry--dimmed'
          : '';
      }}
    />
  );
};

interface MitemDetailsProps {
  timeStatus: SprintMitemTable_SkaTimeStatusFragment;
}

const MitemDetails = ({ timeStatus }: MitemDetailsProps) => {
  const { t } = useTranslation();
  const { features } = useTenantDetails();
  const { data } = useQuery(GetMitemDetailsDocument, {
    variables: { teamId: timeStatus.teamId, mitemId: timeStatus.id },
  });

  const mostImportantItem = data?.mitem;

  const alignedMigs =
    mostImportantItem?.supportedMigs ??
    timeStatus.sprintKeyActivity?.supportedMigs ??
    [];

  const sortedInitiativeTags = sortInitiativeTags(features, timeStatus);
  const sortedTeamTags = sortTeamTags(timeStatus);

  return (
    <>
      <div className="flx">
        <div className="flx--1 SprintMitemTableCard__detailsSection">
          {alignedMigs.length > 0 &&
            alignedMigs.map((m) => (
              <div key={m.id} className="flx flx--column mb">
                <Typography.Text>
                  {t('SprintMitemTable.AlignedToMig')}
                </Typography.Text>
                <Typography.Text type="secondary">{m.name}</Typography.Text>
              </div>
            ))}

          {alignedMigs.length === 0 && (
            <div className="flx flx--column mb">
              <Typography.Text>{t('SprintMitemTable.noMig')}</Typography.Text>
              <Typography.Text type="secondary">
                {mostImportantItem?.noMigAssociation}
              </Typography.Text>
            </div>
          )}
          <div className="flx flx--column mb">
            <Typography.Text>{t('common.definitionOfDone')}</Typography.Text>
            <Typography.Text
              type="secondary"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {mostImportantItem?.definitionOfDone}
            </Typography.Text>
          </div>
          <div className="flx flx--column ">
            <Typography.Text>
              {t('common.sprintKeyActivity.tags')}
            </Typography.Text>
            <div className="flx flx--gap--xs flx--wrap">
              {sortedInitiativeTags.length > 0
                ? sortedInitiativeTags.map(({ tag, metadata }) => (
                    <InitiativeTag
                      key={tag.title}
                      title={tag.title}
                      borderColor={tag.colorCode}
                      icon={Icons[tag.iconId as InitiativeIcon]}
                      completed={metadata.completedAt !== null}
                      archived={metadata.archived}
                    />
                  ))
                : '-'}
              {sortedTeamTags.length > 0
                ? sortedTeamTags.map((l, i) => <TagImage tag={l} key={i} />)
                : '-'}
            </div>
          </div>
        </div>
        <div className="flx--1 SprintMitemTableCard__detailsSection">
          <div>
            <Typography.Text>{t('common.history')}</Typography.Text>
            <div className="mt">
              <MitemHistory
                teamId={timeStatus.teamId}
                mitemId={timeStatus.id}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SPRINT_MITEM_TABLE__MILESTONE = gql`
  fragment SprintMitemTable_Milestone on Milestone {
    id
    domainId {
      itemId
    }
    deadlineAt
    description
    name
    assignedTo {
      id
      domainId {
        itemId
      }
      email
      name
      displayName
    }
    metadata {
      completedAt
      status
      archived
      supports {
        ... on SupportedInitiative {
          item {
            id
            domainId {
              itemId
            }
            name
            tag {
              colorCode
              iconId
              title
            }
            metadata {
              completedAt
              archived
            }
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MITEMS_DETAILS = gql`
  query getMitemDetails($teamId: ID!, $mitemId: ID!) {
    mitem(teamId: $teamId, mitemId: $mitemId) {
      id
      teamId
      supportedMigs {
        id
        name
        domainId {
          itemId
          teamId
        }
      }
      noMigAssociation
      definitionOfDone
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_MITEM_TABLE_TIME_STATUS = gql`
  fragment SprintMitemTable_SkaTimeStatus on SkaTimeStatus {
    id
    teamId
    tenantId
    name
    deadline
    status
    owner {
      id
      email
      name
      displayName
    }
    sprintKeyActivity {
      ...SprintMitemTable_Mitem
      ...SprintMitemTableActions_Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_MITEM_TABLE_MITEM = gql`
  fragment SprintMitemTable_Mitem on Mitem {
    id
    teamId
    completed
    milestone
    status
    tags {
      id
      name
      backgroundColor
    }
    supportedInitiatives {
      id
      tag {
        title
        colorCode
        iconId
      }
      completed {
        value
      }
      archived
    }
    supportsMilestones {
      id
    }
    supportsInitiatives2 {
      id
      initiative {
        tag {
          title
          iconId
          colorCode
        }
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          completedAt
          archived
        }
      }
    }
  }
`;
