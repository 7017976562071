import {
  gql,
  PureQueryOptions,
  useMutation,
  useSuspenseQuery,
} from '@apollo/client';
import { SprintKaForm } from '../SprintKaForm';
import {
  EditMitemDocument,
  EditSprintKeyActivityDrawerContent_QueryDocument,
  GetSprintKeyActivitiesStatsForMilestoneCardDocument,
  MitemStatus,
} from '../../../../../../generated/graphql';
import { MIG_ASSOCIATION_OTHER } from '../../SprintPlanningPage';
import { standardDateFormat } from '../../../../../../services/dateFormats';
import { useTenantDetails } from '../../../../../../hooks/useTenantDetails';
import { showNotification } from '../../../../../../services/fetchNotificationProperties';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
  skaId: string;
  teamId: string;
  refetchQueries?: Array<string | PureQueryOptions>;
  setFormIsDirty: (dirty: boolean) => void;
  onCompleted: (data: any) => void;
  onCancel: () => void;
}

export const EditSprintKeyActivityDrawerContent = ({
  teamId,
  skaId,
  refetchQueries = [],
  setFormIsDirty,
  onCompleted,
  onCancel,
}: Props) => {
  const { t } = useTranslation();
  const { features } = useTenantDetails();
  const { data } = useSuspenseQuery(
    EditSprintKeyActivityDrawerContent_QueryDocument,
    {
      variables: { skaId, teamId },
    }
  );

  const [editMitem, { loading: editPending }] = useMutation(EditMitemDocument, {
    onError: () => {
      showNotification('error', {
        message: t('EditSprintKeyActivityDrawerContent.saveError'),
      });
    },
    onCompleted: (data) => {
      onCompleted(data.editSprintKa);
      showNotification('success', {
        message: t('EditSprintKeyActivityDrawerContent.saveSuccess'),
        description: data.editSprintKa.name,
        placement: 'top',
      });
    },
  });

  const mitem = data.mitem;

  const handleEditMItem = (values: any) => {
    const mitemFormValues = values;
    const {
      supportsMigId,
      supportsInitiativeIds,
      supportsMilestoneIds,
      ...rest
    } = mitemFormValues;
    const supportsInitiativeIdsToBeSubmitted =
      (supportsInitiativeIds as (string | null | undefined)[]) ?? [];

    const supportsMilestoneIdsToBeSubmitted =
      (supportsMilestoneIds as (string | null | undefined)[]) ?? [];

    let noMigAssociation: string | null | undefined =
      mitemFormValues.noMigAssociation;

    const mitemToSubmit =
      mitem.status === MitemStatus.COMPLETED
        ? {
            tags: mitemFormValues.tags,
            milestone: mitemFormValues.milestone,
          }
        : {
            ...rest,
            supportsMigIds:
              supportsMigId === MIG_ASSOCIATION_OTHER || supportsMigId == null
                ? []
                : [{ id: supportsMigId }],
            noMigAssociation:
              mitemFormValues.supportsMigId === MIG_ASSOCIATION_OTHER
                ? noMigAssociation
                : null,
            deadline: standardDateFormat(mitemFormValues.deadline),
            supportsMilestoneIds: supportsMilestoneIdsToBeSubmitted
              .filter((i) => i != undefined)
              .filter((i) => i !== ''),
          };

    if (features.teamInitiativesEnabled) {
      mitemToSubmit.supportsInitiativeIds = supportsInitiativeIdsToBeSubmitted
        .filter((i) => i != undefined)
        .filter((i) => i !== '');
    }
    if (features.tenantInitiativesEnabled) {
      mitemToSubmit.supportsInitiative2Ids = supportsInitiativeIdsToBeSubmitted
        .filter((i) => i != undefined)
        .filter((i) => i !== '');
    }

    editMitem({
      variables: {
        teamId: mitem.teamId,
        sprintKaId: mitem.id,
        mitem: mitemToSubmit,
      },
      refetchQueries: (response) => {
        const previousMilestoneAlignment = new Set(
          mitem.supportsMilestones.map((sm) => sm.domainId.itemId)
        );
        const currentMilestoneAlignment = new Set(
          response.data?.editSprintKa?.supportsMilestones.map(
            (sm) => sm.domainId.itemId
          )
        );
        const affectedMilestones =
          previousMilestoneAlignment.symmetricDifference(
            currentMilestoneAlignment
          );
        const affectedMilestonesRefetchQueries = [...affectedMilestones].map(
          (milestoneId) => ({
            query: GetSprintKeyActivitiesStatsForMilestoneCardDocument,
            variables: {
              teamId: mitem.teamId,
              milestoneId: milestoneId,
            },
          })
        );

        return [...refetchQueries, ...affectedMilestonesRefetchQueries];
      },
    });
  };

  return (
    <>
      {mitem.completed && (
        <Alert
          className="mb"
          message={
            <>
              <InfoCircleOutlined className="mr--s" />
              <strong>
                {t('EditSprintKeyActivityDrawerContent.isCompleted')}
              </strong>
            </>
          }
        />
      )}
      <SprintKaForm
        teamId={mitem.teamId}
        initialValues={mitem}
        submitPending={editPending}
        isEdit={true}
        onSubmit={handleEditMItem}
        onCancel={onCancel}
        setIsFormDirty={setFormIsDirty}
      />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_SPRINT_KEY_ACTIVITY = gql`
  query EditSprintKeyActivityDrawerContent_Query($teamId: ID!, $skaId: ID!) {
    mitem(teamId: $teamId, mitemId: $skaId) {
      ...SprintKaForm_Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EDIT_MITEM = gql`
  mutation editMitem($teamId: ID!, $sprintKaId: ID!, $mitem: SprintKaInput!) {
    editSprintKa(
      teamId: $teamId
      sprintKaId: $sprintKaId
      sprintKaData: $mitem
    ) {
      ...EditSprintKeyActivityDrawerContent__Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditSprintKeyActivityDrawerFragment = gql`
  fragment EditSprintKeyActivityDrawerContent__Mitem on Mitem {
    id
    teamId
    status
    completed
    supportedMigs {
      id
      domainId {
        itemId
        teamId
      }
      name
    }
    supportsMilestones {
      id
      domainId {
        itemId
      }
    }
    ...SprintKaForm_Mitem
  }
`;
