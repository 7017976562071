import { gql, useQuery } from '@apollo/client';
import { GetTeamMembersHookDocument } from '../generated/graphql';

export const useTeamMembers = (teamId: string) => {
  const { data, loading, error } = useQuery(GetTeamMembersHookDocument, {
    variables: { teamId },
  });

  const usersInTeam = data?.usersInTeamPaginated.content ?? [];

  const isMember = (userId: string) =>
    loading ? null : usersInTeam.some((u) => u.id === userId);

  return {
    data: usersInTeam,
    loading,
    error,
    isMember,
  };
};

export const GET_TEAM_MEMBERS_HOOK = gql`
  query getTeamMembersHook($teamId: ID!) {
    usersInTeamPaginated(teamId: $teamId, filter: { archived: false }) {
      content {
        id
        name
        displayName
        email
        role
        teamAssociations {
          role
          sprintAdmin
          team {
            id
          }
        }
      }
    }
  }
`;
