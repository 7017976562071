import React from 'react';
import { Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useClosestPeriods } from '../../hooks/useClosestPeriods';
import { useKeyActivityPerformance } from '../../hooks/useKeyActivityPerformanceQuery';
import { roundToFloor } from '../../services/roundNumbersHelper';
import { PerformanceTag } from '../PerformanceTag';
import { PeriodDataGraph } from '../PeriodDataGraph';
import './AkpiCardLarge.less';
import { standardDateFormat } from '../../services/dateFormats';

interface AkpiData {
  id: string;
  name: string;
  descriptionHtml?: string | null;
  acceptanceCriteriaHtml?: string | null;
  currentPeriodIndex: number | null;
  startDate?: string | null;
  unit: string;
  periodData: Period[];
  teamId: string;
}

interface Props {
  akpi: AkpiData;
  activeDot?: number | null;
  height?: number;
}

interface Period {
  targetDate: string;
  label: string;
  goal?: number | null;
  actual?: number | null;
  committed?: number | null;
}

export const AkpiCardLarge = ({ akpi, height }: Props) => {
  const { t } = useTranslation();
  const { currentPeriod } = useClosestPeriods(akpi.teamId);

  const { data } = useKeyActivityPerformance(akpi.teamId, akpi.id);

  const periodData = akpi.periodData ?? [];
  const activeIndex = periodData.findIndex((pd) =>
    dayjs(pd.targetDate).isSame(currentPeriod?.timePeriodEndDate, 'day')
  );

  const previousIndex = activeIndex > 0 ? activeIndex - 1 : null;
  const previousPeriod =
    previousIndex != null ? periodData[previousIndex] : null;

  return (
    <div className="AkpiCardLarge">
      <div className="AkpiCardLarge__body">
        <div className="AkpiCardLarge__graphArea">
          <div className="AkpiCardLarge__unit">
            <div className="AkpiCardLarge__unitText">
              <div
                className="AkpiCardLarge__rotate"
                style={{ width: height && height - 16 }}
              >
                {akpi.unit}
              </div>
            </div>
          </div>
          <div className="AkpiCardLarge__graph ">
            <PeriodDataGraph
              periodData={periodData}
              referenceLine={previousPeriod?.targetDate}
              height={height}
              startDate={akpi.startDate ?? standardDateFormat(dayjs())}
            />
          </div>
        </div>
      </div>

      <div className="AkpiCardLarge__footer">
        <FooterItem title={t('AkpiCardLarge.resultFor')}>
          {previousPeriod?.label ?? '-'}
        </FooterItem>
        <FooterItem title={t('common.goal')}>
          <span className="AkpiGoal">
            {roundToFloor(previousPeriod?.goal) ?? '-'}
          </span>
        </FooterItem>
        <FooterItem title={t('common.actual')}>
          <span className="AkpiActual">
            {roundToFloor(previousPeriod?.actual) ?? '-'}
          </span>
        </FooterItem>
        <FooterItem title={t('AkpiCardLarge.goalFulfillment')}>
          <PerformanceTag value={data?.targetFulfillment} />
        </FooterItem>
      </div>
    </div>
  );
};

const FooterItem: React.FC<{ title: string; children: React.ReactNode }> = ({
  children,
  title,
}) => (
  <div className="flx--1 text-c">
    <Typography.Text type="secondary">{title}</Typography.Text>
    <div className="bold">{children}</div>
  </div>
);
