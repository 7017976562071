import { useTranslation } from 'react-i18next';
import { MilestoneActivitiesTableSkeleton } from '../../MilestoneActivitiesTable.skeleton';

export const MilestoneCardActivityTabSkeleton = ({
  teamId,
}: {
  teamId?: string;
}) => {
  const { t } = useTranslation();
  if (!teamId) {
    return <MilestoneActivitiesTableSkeleton numberOfActivities={4} />;
  }

  return (
    <div>
      <div className="mt--l">
        <h4 className="mb">{t('MilestoneCardActivityTab.yourTeam')}</h4>
        <MilestoneActivitiesTableSkeleton numberOfActivities={2} />
      </div>
      <div className="mt--xl">
        <h4 className="mb">{t('MilestoneCardActivityTab.otherTeams')}</h4>
        <MilestoneActivitiesTableSkeleton numberOfActivities={4} />
      </div>
    </div>
  );
};
