import Icon from '@ant-design/icons';

const ShoppingCartOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.6665 13.6667C4.29984 13.6667 3.98595 13.5362 3.72484 13.275C3.46373 13.0139 3.33317 12.7 3.33317 12.3334C3.33317 11.9667 3.46373 11.6528 3.72484 11.3917C3.98595 11.1306 4.29984 11 4.6665 11C5.03317 11 5.34706 11.1306 5.60817 11.3917C5.86928 11.6528 5.99984 11.9667 5.99984 12.3334C5.99984 12.7 5.86928 13.0139 5.60817 13.275C5.34706 13.5362 5.03317 13.6667 4.6665 13.6667ZM11.3332 13.6667C10.9665 13.6667 10.6526 13.5362 10.3915 13.275C10.1304 13.0139 9.99984 12.7 9.99984 12.3334C9.99984 11.9667 10.1304 11.6528 10.3915 11.3917C10.6526 11.1306 10.9665 11 11.3332 11C11.6998 11 12.0137 11.1306 12.2748 11.3917C12.5359 11.6528 12.6665 11.9667 12.6665 12.3334C12.6665 12.7 12.5359 13.0139 12.2748 13.275C12.0137 13.5362 11.6998 13.6667 11.3332 13.6667ZM4.09984 3.00004L5.69984 6.33337H10.3665L12.1998 3.00004H4.09984ZM3.4665 1.66671H13.2998C13.5554 1.66671 13.7498 1.7806 13.8832 2.00837C14.0165 2.23615 14.0221 2.46671 13.8998 2.70004L11.5332 6.96671C11.4109 7.18893 11.2471 7.36115 11.0415 7.48337C10.8359 7.6056 10.6109 7.66671 10.3665 7.66671H5.39984L4.6665 9.00004H12.6665V10.3334H4.6665C4.1665 10.3334 3.78873 10.1139 3.53317 9.67504C3.27762 9.23615 3.2665 8.80004 3.49984 8.36671L4.39984 6.73337L1.99984 1.66671H0.666504V0.333374H2.83317L3.4665 1.66671Z" />
  </svg>
);

export const ShoppingCartOutlineIcon = (props: any) => (
  <Icon component={ShoppingCartOutlineIconSvg} {...props} />
);
