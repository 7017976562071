import './BarChartLegend.less';

interface Props {
  title: string;
  color: string;
  borderColor?: string;
  className?: string;
  value?: React.ReactNode;
}

export const BarChartLegend = ({
  title,
  color,
  borderColor,
  className,
  value,
}: Props) => {
  return (
    <div className={className}>
      <div className="flx flx--ai-center ">
        <div
          className="BarChartLegend mr--s"
          style={{
            backgroundColor: color,
            border: `1px solid ${borderColor ?? color}`,
          }}
        />

        <span className="BarChartLegend__totalCommitted">{value}</span>
      </div>
      <span className="BarChartLegend__LegendLabels txt--secondary">
        {title}
      </span>
    </div>
  );
};
