import { useTranslation } from 'react-i18next';
import {
  generateArgumentsMap,
  GenericInsights,
  getInsightCode,
  MigCustomInsights,
} from '../../../../../../../../hooks/useAccelerationMeetingInsights';
import { GenericInsightCard } from '../genericCard/GenericInsightCard';
import './migInsightCard.less';
import { MigActualCard } from './MigInsights/MigActualCard';
import * as sentry from '@sentry/react';

type Props = {
  customInsights: MigCustomInsights;
  genericInsights: GenericInsights[];
};

export const MigInsightCards = ({ customInsights, genericInsights }: Props) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      {customInsights.actual && (
        <MigActualCard migActualInsight={customInsights.actual} />
      )}
      {genericInsights.map((gi, index) => {
        if (!i18n.exists(`MigInsights.${gi.code}.short`)) {
          sentry.captureMessage(`MigInsights.${gi.code} has no translation`, {
            level: 'warning',
          });
          return null;
        }
        const args = generateArgumentsMap(gi.args);
        return (
          <GenericInsightCard
            heading={t('MigInsights.miginsight')}
            title={t(`MigInsights.${gi.code}.short`, args)}
            key={index}
            keyValue={index}
            backSide={{
              description: t(`backCardDescriptions.${getInsightCode(gi.code)}`),
              why: t(
                `backCardWhyImportantDescriptions.${getInsightCode(gi.code)}`
              ),
            }}
          >
            {t(`MigInsights.${gi.code}.long`, args)}
          </GenericInsightCard>
        );
      })}
    </>
  );
};
