import Icon from '@ant-design/icons';

const SavingsOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.66683 6.33337C9.85572 6.33337 10.0141 6.26949 10.1418 6.14171C10.2696 6.01393 10.3335 5.8556 10.3335 5.66671C10.3335 5.47782 10.2696 5.31949 10.1418 5.19171C10.0141 5.06393 9.85572 5.00004 9.66683 5.00004C9.47794 5.00004 9.31961 5.06393 9.19183 5.19171C9.06405 5.31949 9.00016 5.47782 9.00016 5.66671C9.00016 5.8556 9.06405 6.01393 9.19183 6.14171C9.31961 6.26949 9.47794 6.33337 9.66683 6.33337ZM4.3335 5.00004H7.66683V3.66671H4.3335V5.00004ZM2.00016 13C1.62238 11.7334 1.25016 10.4695 0.883496 9.20837C0.516829 7.94726 0.333496 6.6556 0.333496 5.33337C0.333496 4.31115 0.689052 3.44449 1.40016 2.73337C2.11127 2.02226 2.97794 1.66671 4.00016 1.66671H7.3335C7.65572 1.24449 8.04738 0.916707 8.5085 0.683374C8.96961 0.450041 9.46683 0.333374 10.0002 0.333374C10.2779 0.333374 10.5141 0.430596 10.7085 0.625041C10.9029 0.819485 11.0002 1.0556 11.0002 1.33337C11.0002 1.40004 10.9918 1.46671 10.9752 1.53337C10.9585 1.60004 10.9391 1.66115 10.9168 1.71671C10.8724 1.83893 10.8307 1.96393 10.7918 2.09171C10.7529 2.21949 10.7224 2.35004 10.7002 2.48337L12.2168 4.00004H13.6668V8.65004L11.7835 9.26671L10.6668 13H7.00016V11.6667H5.66683V13H2.00016ZM3.00016 11.6667H4.3335V10.3334H8.3335V11.6667H9.66683L10.7002 8.23337L12.3335 7.68337V5.33337H11.6668L9.3335 3.00004C9.3335 2.77782 9.34738 2.56393 9.37516 2.35837C9.40294 2.15282 9.44461 1.94449 9.50016 1.73337C9.17794 1.82226 8.89461 1.97504 8.65016 2.19171C8.40572 2.40837 8.22794 2.67782 8.11683 3.00004H4.00016C3.35572 3.00004 2.80572 3.22782 2.35016 3.68337C1.89461 4.13893 1.66683 4.68893 1.66683 5.33337C1.66683 6.42226 1.81683 7.48615 2.11683 8.52504C2.41683 9.56393 2.71127 10.6112 3.00016 11.6667Z" />
  </svg>
);

export const SavingsOutlineIcon = (props: any) => (
  <Icon component={SavingsOutlineIconSvg} {...props} />
);
