import { RightOutlined } from '@ant-design/icons';
import cx from 'classnames';
import './ExpandArrow.less';

interface Props {
  expanded: boolean;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

export const ExpandArrow = ({ onClick, expanded }: Props) => {
  return (
    <RightOutlined
      className={cx('ExpandArrow__toggle', {
        'ExpandArrow__toggle--expanded': expanded,
      })}
      onClick={onClick}
    />
  );
};
