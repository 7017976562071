import Icon from '@ant-design/icons';

const HumidityIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.99984 13.3333C4.52206 13.3333 3.26373 12.8222 2.22484 11.8C1.18595 10.7777 0.666504 9.53329 0.666504 8.06663C0.666504 7.36663 0.802615 6.69718 1.07484 6.05829C1.34706 5.4194 1.73317 4.85552 2.23317 4.36663L5.99984 0.666626L9.7665 4.36663C10.2665 4.85552 10.6526 5.4194 10.9248 6.05829C11.1971 6.69718 11.3332 7.36663 11.3332 8.06663C11.3332 9.53329 10.8137 10.7777 9.77484 11.8C8.73595 12.8222 7.47762 13.3333 5.99984 13.3333ZM1.99984 8.06663H9.99984C9.99984 7.5444 9.89984 7.04718 9.69984 6.57496C9.49984 6.10274 9.21095 5.68885 8.83317 5.33329L5.99984 2.53329L3.1665 5.33329C2.78873 5.68885 2.49984 6.10274 2.29984 6.57496C2.09984 7.04718 1.99984 7.5444 1.99984 8.06663Z" />
  </svg>
);

export const HumidityIcon = (props: any) => (
  <Icon component={HumidityIconSvg} {...props} />
);
