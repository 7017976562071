import Icon from '@ant-design/icons';

const GrowthIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 8"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.26683 8L0.333496 7.06667L5.26683 2.1L7.9335 4.76667L11.4002 1.33333H9.66683V0H13.6668V4H12.3335V2.26667L7.9335 6.66667L5.26683 4L1.26683 8Z" />
  </svg>
);

export const GrowthIcon = (props: any) => (
  <Icon component={GrowthIconSvg} {...props} />
);
