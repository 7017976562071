import { Modal, Skeleton } from 'antd';
import { Btn } from '../../../../../components/Button';
import {
  HighlightCard_MitemFragment,
  MitemStatus,
} from '../../../../../generated/graphql';
import { ManageSearchIcon } from '../../../../../icons/ManageSearch';
import { StarIcon } from '../../../../../icons/Star';
import { SkaDetails } from '../../../../team/sprint/planning/components/mitemPlanningBoard/SkaDetails';
import { useState } from 'react';
import { mitemStatusColor } from '../../../../../styleVars';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import './HighlightCard.less';
import { gql } from '@apollo/client';

interface props {
  sprintKeyActivity: HighlightCard_MitemFragment;
}

export const HighlightCard = ({ sprintKeyActivity }: props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        modalRender={() =>
          sprintKeyActivity ? (
            <SkaDetails
              selectedSka={sprintKeyActivity}
              onClose={() => setVisible(false)}
              editable={false}
            />
          ) : (
            <div className="bg--white pa--xxl">
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </div>
          )
        }
      />
      <div className="HighlightCard flx flx--column flx--jc-space-between">
        <div>
          <div className="flx flx--jc-space-between flx--ai-center">
            <div className="flx">
              <div
                className="flx"
                style={{ color: mitemStatusColor[sprintKeyActivity.status] }}
              >
                <StarIcon />
                <div className="ml--s mb--none font-size--sm HighlightCard__header">
                  {t(
                    `common.sprintKeyActivity.statuses.${sprintKeyActivity.status.toLowerCase()}`
                  )}
                </div>
              </div>
              <div className="ml--s mb--none font-size--sm txt--secondary HighlightCard__header">
                {sprintKeyActivity.status === MitemStatus.COMPLETED
                  ? dayjs(sprintKeyActivity.completedAt).fromNow()
                  : dayjs(sprintKeyActivity.deadline).fromNow()}
              </div>
            </div>
            <Btn
              title={t('HighlightCard.showDetails')}
              onClick={() => {
                setVisible(true);
              }}
              type="link"
              icon={<ManageSearchIcon />}
            />
          </div>
          <h4>{sprintKeyActivity.name}</h4>
        </div>
        <div className="flx">
          <div className="txt--secondary font-size--sm space--r">
            {t('HighlightCard.team')}
          </div>
          <div className="font-size--sm">{sprintKeyActivity.team.name}</div>
        </div>
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HIGHLIGHT_CARD_FRAGMENT = gql`
  fragment HighlightCard_Mitem on Mitem {
    id
    name
    deadline
    completedAt
    status
    team {
      id
      name
    }
  }
`;
