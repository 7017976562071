import {
  gql,
  PureQueryOptions,
  RefetchQueriesFunction,
  useMutation,
} from '@apollo/client';
import { Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerTitle } from '../../../../../../components/DrawerTitle';
import { ErrorAlert } from '../../../../../../components/ErrorAlert';
import { SKADrawerConfirmCloseAlert } from '../../../../../../components/SKADrawerConfirmCloseAlert';
import {
  CopySprintKeyActivityDrawer_MitemFragment,
  CopySprintKeyActivityResponse_MitemFragment,
  CreateSkaCopyDocument,
} from '../../../../../../generated/graphql';
import { standardDateFormat } from '../../../../../../services/dateFormats';
import { showNotification } from '../../../../../../services/fetchNotificationProperties';
import { SprintKaForm } from '../SprintKaForm';
import dayjs from 'dayjs';
import { MIG_ASSOCIATION_OTHER } from '../../SprintPlanningPage';
import { useTenantDetails } from '../../../../../../hooks/useTenantDetails';

interface Props {
  mitem: CopySprintKeyActivityDrawer_MitemFragment;
  showDrawer: boolean;
  onCompleted: (data: CopySprintKeyActivityResponse_MitemFragment) => void;
  onCancel: () => void;
  refetchQueries?: Array<string | PureQueryOptions> | RefetchQueriesFunction;
}

export const CopySprintKeyActivityDrawer = ({
  mitem,
  showDrawer,
  onCompleted,
  onCancel,
  refetchQueries,
}: Props) => {
  const { features } = useTenantDetails();
  const { t } = useTranslation();
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [createSkaCopy, { loading: editPending, error }] = useMutation(
    CreateSkaCopyDocument,
    {
      refetchQueries,
      onError: () => {
        showNotification('error', {
          message: t('CopySprintKeyActivityDrawer.error'),
          description: <ErrorAlert error={error} />,
        });
      },
      onCompleted: (data) => {
        onCompleted(data.createSprintKeyActivity);
        showNotification('success', {
          message: t('CopySprintKeyActivityDrawer.success'),
          description: data.createSprintKeyActivity.name,
        });
        resetConfirmationData();
      },
      update(cache, { data }) {
        if (data?.createSprintKeyActivity) {
          cache.modify({
            fields: {
              sprintKeyActivities(existingMItems = []) {
                const newMItemRef = cache.writeFragment({
                  data: data.createSprintKeyActivity,
                  fragment: COPY_SPRINT_KEY_ACTIVITY_RESPONSE_FRAGMENT,
                });
                return {
                  sprintKeyActivities: [
                    ...existingMItems.sprintKeyActivities,
                    newMItemRef,
                  ],
                };
              },
            },
          });
        }
      },
    }
  );

  // TODO: Should be changed, need to handle intercom on a higher level
  const isAccMeeting = window.location.href.includes('acceleration-meeting');

  const handleCancel = () => {
    if (formIsDirty) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
      onCancel();
    }
  };

  const resetConfirmationData = () => {
    setFormIsDirty(false);
    setShowWarning(false);
    setConfirmed(false);
  };

  useEffect(() => {
    if (confirmed) {
      resetConfirmationData();
      onCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed]);

  useEffect(() => {
    if (!isAccMeeting) {
      (window as any).Intercom?.('update', {
        hide_default_launcher: showDrawer,
      });

      // sometimes the drawer is unmounted rather than getting the prop showModal={false}
      // this will make sure we show the intercom widget again in that case
      return () =>
        (window as any).Intercom?.('update', {
          hide_default_launcher: false,
        });
    }

    if (!showDrawer) {
      setFormIsDirty(false);
      setShowWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDrawer, isAccMeeting]);

  const handleCopySprintKeyActivity = (values: any) => {
    const mitemFormValues = values;

    let noMigAssociation: string | null | undefined =
      mitemFormValues.noMigAssociation;
    const {
      supportsMigId,
      supportsInitiativeIds,
      supportsMilestoneIds,
      ...rest
    } = mitemFormValues;
    const supportsInitiativeIdsToBeSubmitted =
      (supportsInitiativeIds as (string | null | undefined)[]) ?? [];

    const supportsMilestoneIdsToBeSubmitted =
      (supportsMilestoneIds as (string | null | undefined)[]) ?? [];

    const noMigSelected =
      supportsMigId === MIG_ASSOCIATION_OTHER || supportsMigId == null;

    const skaToSubmit = {
      ...rest,
      supportsMigIds: noMigSelected ? [] : [{ id: supportsMigId }],
      noMigAssociation: noMigSelected ? noMigAssociation : null,
      deadline: standardDateFormat(mitemFormValues.deadline),
      supportsMilestoneIds: supportsMilestoneIdsToBeSubmitted
        .filter((i) => i != undefined)
        .filter((i) => i !== ''),
    };

    if (features.teamInitiativesEnabled) {
      skaToSubmit.supportsInitiativeIds = supportsInitiativeIdsToBeSubmitted
        .filter((i) => i != undefined)
        .filter((i) => i !== '');
    }

    if (features.tenantInitiativesEnabled) {
      skaToSubmit.supportsInitiative2Ids = supportsInitiativeIdsToBeSubmitted
        .filter((i) => i != undefined)
        .filter((i) => i !== '');
    }

    createSkaCopy({
      variables: {
        teamId: mitem.teamId,
        sprintKeyActivity: skaToSubmit,
      },
    });
  };

  const initialValues = {
    ...mitem,
    owner: undefined,
    completed: false,
    deadline: dayjs(mitem.deadline).isSameOrAfter(dayjs())
      ? mitem.deadline
      : undefined,
  };

  return (
    <Drawer
      title={
        <DrawerTitle>{t('CopySprintKeyActivityDrawer.title')}</DrawerTitle>
      }
      open={showDrawer}
      onClose={handleCancel}
      width={420}
      destroyOnClose
    >
      {showWarning && (
        <SKADrawerConfirmCloseAlert
          onConfirm={() => setConfirmed(true)}
          onClose={() => setShowWarning(false)}
        />
      )}

      <SprintKaForm
        teamId={mitem.teamId}
        initialValues={initialValues}
        submitPending={editPending}
        setIsFormDirty={setFormIsDirty}
        onSubmit={handleCopySprintKeyActivity}
        onCancel={handleCancel}
      />
    </Drawer>
  );
};

export const CREATE_SKA_COPY = gql`
  mutation createSKACopy($teamId: ID!, $sprintKeyActivity: SprintKaInput!) {
    createSprintKeyActivity(
      teamId: $teamId
      sprintKeyActivity: $sprintKeyActivity
    ) {
      ...CopySprintKeyActivityResponse_Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const COPY_SPRINT_KEY_ACTIVITY_RESPONSE_FRAGMENT = gql`
  fragment CopySprintKeyActivityResponse_Mitem on Mitem {
    id
    name
    owner {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const COPY_SPRINT_KEY_ACTIVITY_DRAWER_FRAGMENT = gql`
  fragment CopySprintKeyActivityDrawer_Mitem on Mitem {
    id
    teamId
    deadline
    supportedMigs {
      id
      name
      domainId {
        itemId
        teamId
      }
    }
    ...SprintKaForm_Mitem
  }
`;
