import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const TitleIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <g mask="url(#a)">
      <path
        d="M4.167 16.667V10h-2.5V7.5h7.5V10h-2.5v6.667h-2.5Z"
        fill="#E0E0E0"
      />
    </g>
    <g mask="url(#b)">
      <path
        d="M11.667 16.667V5.834H7.5v-2.5h10.833v2.5h-4.166v10.833h-2.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const TitleIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={TitleIconSvg} {...props} />
);
