import Icon from '@ant-design/icons';

const TagOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.50016 13.2667C8.24461 13.5223 7.92794 13.65 7.55016 13.65C7.17238 13.65 6.85572 13.5223 6.60016 13.2667L0.733496 7.40004C0.611274 7.27782 0.514052 7.13337 0.441829 6.96671C0.369607 6.80004 0.333496 6.62226 0.333496 6.43337V1.66671C0.333496 1.30004 0.464052 0.986152 0.725163 0.725041C0.986274 0.46393 1.30016 0.333374 1.66683 0.333374H6.4335C6.62239 0.333374 6.80016 0.369485 6.96683 0.441707C7.1335 0.51393 7.27794 0.611152 7.40016 0.733374L13.2668 6.61671C13.5224 6.87226 13.6502 7.18615 13.6502 7.55837C13.6502 7.9306 13.5224 8.24448 13.2668 8.50004L8.50016 13.2667ZM7.55016 12.3334L12.3168 7.56671L6.4335 1.66671H1.66683V6.43337L7.55016 12.3334ZM3.3335 4.33337C3.61127 4.33337 3.84738 4.23615 4.04183 4.04171C4.23627 3.84726 4.3335 3.61115 4.3335 3.33337C4.3335 3.0556 4.23627 2.81948 4.04183 2.62504C3.84738 2.4306 3.61127 2.33337 3.3335 2.33337C3.05572 2.33337 2.81961 2.4306 2.62516 2.62504C2.43072 2.81948 2.3335 3.0556 2.3335 3.33337C2.3335 3.61115 2.43072 3.84726 2.62516 4.04171C2.81961 4.23615 3.05572 4.33337 3.3335 4.33337Z" />
  </svg>
);

export const TagOutlineIcon = (props: any) => (
  <Icon component={TagOutlineIconSvg} {...props} />
);
