import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { GetTeamInitiativesDocument } from '../generated/graphql';

export const useTeamInitiatives = (teamId: string, tenantId?: string) => {
  const { data, loading, error } = useQuery(GetTeamInitiativesDocument, {
    variables: {
      tenantId,
      teamId,
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    data,
    teamInitiatives: data?.initiatives.initiatives,
    loading,
    error,
  };
};

export const useTeamInitiativesLazy = () => {
  const [fetchTeamInitiatives, { data, loading, error }] = useLazyQuery(
    GetTeamInitiativesDocument
  );
  return [
    fetchTeamInitiatives,
    {
      data,
      teamInitiatives: data?.initiatives.initiatives,
      loading,
      error,
    },
  ] as const;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_INITIATIVES = gql`
  fragment InitiativeContent on Initiative {
    id
    name
    description
    objectives {
      text
    }
    archived
    completed {
      value
      setAt
    }
    rev
    teamsContributing {
      domainId {
        itemId
      }
    }
    supportsInitiatives {
      id
      domainId {
        tenantId
        teamId
        itemId
      }
    }
    supportsMigs {
      id
      domainId {
        tenantId
        teamId
        itemId
      }
      migV2 {
        id
        name
        domainId {
          itemId
          teamId
        }
      }
    }
    domainId {
      tenantId
      teamId
      itemId
    }

    tag {
      iconId
      colorCode
      title
    }
    team {
      id
      name
    }
  }

  query getTeamInitiatives($tenantId: ID, $teamId: ID!) {
    initiatives(tenantId: $tenantId, teamId: $teamId) {
      initiatives {
        ...InitiativeContent
      }
    }
  }
`;
