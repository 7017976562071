import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SmallCollapseClosedIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 5"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 5L0 0L10 0L5 5Z" />
  </svg>
);

export const SmallCollapseClosedIcon = (
  props: Omit<IconComponentProps, 'ref'>
) => <Icon component={SmallCollapseClosedIconSvg} {...props} />;
