import { InitiativeSectionGridSkeleton } from './InitiativeSectionGrid.skeleton';
import { useTranslation } from 'react-i18next';
import { ToggleSection } from '../../../../team/overview/components/ToggleSection';
import { Radio } from 'antd';
import {
  MilestoneOrActiveToggle,
  MilestoneOrActiveToggleEnum,
} from '../../../initiatives/initiativeDetails/milestoneOrActivityToggle/MilestoneOrActiveToggle';

const options = [
  { label: 'Tiles', value: 'tiles' },
  { label: 'Timeline', value: 'timeline' },
];

export const InitiativeSectionSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h3>{t('InitiativeSection.companyInitiatives')}</h3>
      <div className="flx flx--jc-space-between flx--ai-center mt">
        <MilestoneOrActiveToggle
          value={MilestoneOrActiveToggleEnum.Milestones}
          onChange={() => {}}
          disabled={true}
        />
        <div>
          <Radio.Group disabled options={options} optionType="button" />
        </div>
      </div>
      <InitiativeSectionGridSkeleton />
      <div className="flx flx--jc-center flx--column">
        <ToggleSection
          titleExpanded={t('InitiativeSection.showLess')}
          titleNotExpanded={t('InitiativeSection.showMore')}
          data-intercom-target="Expandable section to show ended and upcoming MIGs"
          disabled={true}
        >
          <InitiativeSectionGridSkeleton />
        </ToggleSection>
      </div>
    </div>
  );
};
