import { MinusOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Divider, Input, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../components/Button';
import { LearnMoreLink } from '../../../../components/LearnMoreLink';
import {
  useTeamsWithMigsPreview,
  TeamWithMig,
} from '../../../../components/setup/migSetupPage/hooks/useTeamsWithMigsPreview';
import {
  arrayToTree,
  pruneBranch,
  treeToArray,
} from '../../../../services/arrayToTree';
import { toMap } from '../../../../services/toMap';

import './MigRelationSelector.less';
import { RelationsMig } from './migRelationsSelector/RelationsMig';
import { TitleWithTooltip } from './TitleWithTooltip';
import { MigStatus } from '../../../../generated/graphql';

export interface SupportMig {
  id: string;
  name: string;
  lastOutcomeDate: string;
  status: MigStatus;
  domainId: {
    itemId: string;
    teamId: string;
    tenantId: string;
  };
  team: {
    id: string;
    name: string;
  };
}

interface Props {
  teamId: string;
  tenantId?: string;
  currentMigId?: string;
  value: SupportMig[];
  onChange: (selected: SupportMig[]) => void;
}

export const MigRelationSelector = ({
  value: relatedMigs,
  onChange,
  currentMigId,
  teamId,
  tenantId,
}: Props) => {
  const { teamsWithMigs } = useTeamsWithMigsPreview(tenantId);
  const { t } = useTranslation();
  const [migFilter, setMigFilter] = useState('');

  const { migs, teamNameMap } = getMigsFromValidTeams(
    teamsWithMigs?.teams ?? [],
    teamId
  );

  const selectableMigs = getSelectableMigs(
    migs,
    migFilter,
    relatedMigs,
    currentMigId,
    teamNameMap
  );

  const handleRemove = (migId: string) =>
    onChange(relatedMigs.filter((m) => m.id !== migId));

  return (
    <div>
      <div className="mb--xl">
        <Typography.Title
          level={5}
          className="mb--none mt--none mr"
          style={{ display: 'inline-block' }}
        >
          {t('MigRelationSelector.shortDescription')}
        </Typography.Title>
        <LearnMoreLink urlTemplate="https://help.howwe.io/{{locale}}/articles/5723853-mig-alignment?utm_source=app.howwe.io&utm_medium=web&utm_campaign=mig_alignment_header" />
      </div>
      <Divider />
      <TitleWithTooltip
        title={t('MigRelationSelector.migAlign')}
        tooltipContent={
          <div className="flx flx--column">
            {t('MigRelationSelector.tooltip.migAlign')}
          </div>
        }
        tooltipPlacement="topRight"
      />
      <div className="MigRelationSelector__filterInputs">
        <Input
          placeholder={t('MigRelationSelector.searchPlaceholder')}
          value={migFilter}
          onChange={(e) => setMigFilter(e.target.value)}
          allowClear
          prefix={<SearchOutlined />}
        />
      </div>
      <div className="MigRelationSelector__migListWrapper">
        <div className="MigRelationSelector__migList showScrollBar">
          {selectableMigs?.map((supportMig) => (
            <RelationsMig
              key={supportMig.id}
              supportMig={supportMig}
              action={
                <Btn
                  size="small"
                  icon={<PlusOutlined />}
                  className="MigRelationSelector__addBtn ml"
                  onClick={() => {
                    onChange([...relatedMigs, supportMig]);
                  }}
                >
                  {t('MigRelationSelector.addButton')}
                </Btn>
              }
            />
          ))}
        </div>
      </div>
      <div className="flx flx--column">
        <b className="mt">{t('MigRelationSelector.selectedMigs')}</b>
        {relatedMigs.map((sm) => (
          <RelationsMig
            key={sm.id}
            className="MigRelationSelector__selectedMig"
            supportMig={sm}
            action={
              <Btn
                size="small"
                danger
                icon={<MinusOutlined />}
                className="ml"
                onClick={() => {
                  handleRemove(sm.id);
                }}
              >
                {t('MigRelationSelector.removeButton')}
              </Btn>
            }
          />
        ))}
      </div>
    </div>
  );
};

const getMigsFromValidTeams = (teams: TeamWithMig[], teamId: string) => {
  const currentTeam = teams.find((t) => t.id === teamId);

  const validTeams = removeTeamAndDecendants(teams, teamId);

  // we should always have a current Team, we just have the `if` to please typescript
  if (currentTeam) {
    validTeams.unshift(currentTeam);
  }

  const validTeamsWithoutMirroredMigs = validTeams.map((vt) => {
    return {
      ...vt,
      migs: vt.teamMigs.filter((m) => m.domainId.teamId === vt.id),
    };
  });

  const teamNameMap = toMap(validTeamsWithoutMirroredMigs, (t) => t.id);

  const migs = validTeamsWithoutMirroredMigs.flatMap((t) => t.migs);

  return { migs, teamNameMap };
};

const removeTeamAndDecendants = (
  teams: TeamWithMig[],
  teamIdToPrune: string
) => {
  if (teams.length === 0) return teams;

  const tree = arrayToTree(teams, {
    idPath: 'id',
    parentIdPath: 'parent.id',
  });

  const pruned = tree.map((root) => pruneBranch(root, teamIdToPrune));

  const newList = pruned.flatMap((root) => (root ? treeToArray(root) : []));
  return newList;
};

const getSelectableMigs = (
  migs: TeamWithMig['teamMigs'],
  migFilter: string,
  relatedMigs: SupportMig[],
  currentMigId: string | undefined,
  teamNameMap: { [key: string]: TeamWithMig }
) => {
  const lowerCasedFilter = migFilter.toLowerCase();

  const filteredMigs = migs.filter((m) => {
    if (m.domainId.itemId === currentMigId) return false;

    const alreadyAdded = relatedMigs.some((sm) => sm.id === m.id);
    if (alreadyAdded) return false;

    // A MIG cannot have relations to MIGs in in multiple teams
    const firstSelectedMigTeam = relatedMigs[0]?.team.id;
    if (firstSelectedMigTeam && m.domainId.teamId !== firstSelectedMigTeam)
      return false;

    if (!teamNameMap[m.domainId.teamId]) return false;

    const teamName = teamNameMap[m.domainId.teamId].name.toLowerCase();
    const migName = m.name.toLowerCase();

    return (
      migName.includes(lowerCasedFilter) || teamName.includes(lowerCasedFilter)
    );
  });

  return filteredMigs.map((m) => {
    const team = teamNameMap[m.domainId.teamId];
    return {
      ...m,
      team: { id: team.id, name: team.name },
    };
  });
};
