import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../Button';
import { Alert } from 'antd';
import { CompanyInitiativeTable } from './CompanyInitiativeTable';
import { InitiativeSelect } from './InitiativeSelect';

export const ManageCompanyInitiativesSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Alert
        className="mb--xl"
        showIcon
        closable
        message={t('ManageCompanyInitiatives.helpText')}
      />

      <h3 className="mb--l">
        {t('ManageCompanyInitiatives.selectInitiative')}
      </h3>
      <div className="flx mb--xl">
        <InitiativeSelect
          placeholder={t('ManageCompanyInitiatives.selectInitiative')}
          initiatives={[]}
          className="flx--1"
          disabled
        />
        <Btn type="primary" className="ml--xl" disabled={true}>
          {t('ManageCompanyInitiatives.linkInitiative')}
        </Btn>
      </div>
      <h3 className="mb--l">{t('ManageCompanyInitiatives.currentlyLinked')}</h3>
      <CompanyInitiativeTable.Skeleton />
    </div>
  );
};
