import {
  gql,
  PureQueryOptions,
  RefetchQueriesFunction,
  useMutation,
} from '@apollo/client';
import { Form, Modal, Radio, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { DisplayName } from '../../../components/DisplayName';
import { ErrorAlert } from '../../../components/ErrorAlert';
import {
  AssignTeamRoleToUserDocument,
  UserTeamRole,
} from '../../../generated/graphql';
import { TeamUser } from '../../../hooks/useTeamMembersV2';
import { showNotification } from '../../../services/fetchNotificationProperties';

const SPRINT_ADMIN = 'SPRINT_ADMIN';

interface Props {
  teamRole: UserTeamRole;
  sprintAdmin: boolean;
  teamId: string;
  user: TeamUser;
  tenantId?: string;
  visible: boolean;
  hideModal: () => void;
  refetchQueries?: Array<string | PureQueryOptions> | RefetchQueriesFunction;
}

export const AssignTeamMemberRoleModal = ({
  teamRole,
  sprintAdmin,
  teamId,
  user,
  visible,
  hideModal,
  tenantId,
  refetchQueries,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const [assignTeamRole, assignTeamRoleRequest] = useMutation(
    AssignTeamRoleToUserDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: (
            <Trans i18nKey="TeamSettingsPage.assignTeamRole.success">
              The team role for user:
              <DisplayName user={user}></DisplayName>
              has successfully been updated
            </Trans>
          ),
        });
        hideModal();
      },
      refetchQueries,
    }
  );
  const [form] = Form.useForm();
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      assignTeamRole({
        variables: {
          teamId,
          tenantId,
          userId: user.id,
          teamRole:
            values.teamRole === SPRINT_ADMIN
              ? UserTeamRole.MEMBER
              : values.teamRole,
          sprintAdmin: values.teamRole === SPRINT_ADMIN,
        },
      });
    });
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  // text directly in a <Radio.Group> has font-size: 0 for some reason, so we have to override it
  const fixZeroHeight = {
    fontSize: '14px',
    paddingTop: '8px',
  };

  return (
    <Modal
      title={
        <span>
          {t('TeamSettingsPage.assignTeamRole.modalTitle')}
          <DisplayName user={user} />
        </span>
      }
      open={visible}
      onOk={handleSubmit}
      onCancel={hideModal}
      okText={t('common.confirm')}
      cancelText={t('common.cancel')}
      cancelButtonProps={{ disabled: assignTeamRoleRequest.loading }}
      confirmLoading={assignTeamRoleRequest.loading}
    >
      <Form
        form={form}
        initialValues={{
          teamRole: sprintAdmin ? SPRINT_ADMIN : teamRole,
        }}
        data-testid="assign-member-role-form"
        layout="vertical"
      >
        <Form.Item name="teamRole">
          <Radio.Group>
            <Radio style={radioStyle} value={UserTeamRole.MEMBER}>
              <Typography.Text strong>
                {t('common.teamRole.member')}
              </Typography.Text>
            </Radio>

            <div className="mb" style={fixZeroHeight}>
              {t('TeamSettingsPage.assignTeamRole.memberDescription')}
            </div>
            <Radio style={radioStyle} value={SPRINT_ADMIN}>
              <Typography.Text strong>
                {t('common.teamRole.sprint_admin')}
              </Typography.Text>
            </Radio>

            <div style={fixZeroHeight}>
              {t('TeamSettingsPage.assignTeamRole.sprintAdminDescription')}
            </div>

            <Radio style={radioStyle} value={UserTeamRole.LEADER}>
              <Typography.Text strong>
                {t('common.teamRole.leader')}
              </Typography.Text>
            </Radio>
            <div style={fixZeroHeight}>
              {t('TeamSettingsPage.assignTeamRole.leaderDescription')}
            </div>
          </Radio.Group>
        </Form.Item>
      </Form>

      <ErrorAlert
        error={assignTeamRoleRequest.error}
        title={t('TeamSettingsPage.assignTeamRole.errorTitle')}
      />
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ASSIGN_TEAM_ROLE_TO_USER = gql`
  mutation assignTeamRoleToUser(
    $teamId: ID!
    $userId: ID!
    $teamRole: String!
    $sprintAdmin: Boolean!
    $tenantId: ID
  ) {
    assignUserToTeam(
      teamId: $teamId
      userId: $userId
      teamRole: $teamRole
      sprintAdmin: $sprintAdmin
      tenantId: $tenantId
    ) {
      id
      membersV2(filter: { archived: false }) {
        teamId
        teamName
        members {
          teamId
          teamRole
          sprintAdmin
          user {
            id
            name
            displayName
            email
          }
        }
      }
      leaders {
        id
        displayName
        name
        email
      }
    }
  }
`;
