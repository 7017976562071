import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { getUnique } from '../../../../../../../../../../services/getUnique';
import { UserContributionData } from '../../hooks/usePreviousPeriodSummary';

interface Props {
  userContributionData?: UserContributionData[] | null;
  setFilteredKeyActivity: (
    keyActivitiyId: string | 'ALL_KEY_ACTIVITIES'
  ) => void;
  className?: string;
}

export const WeeklyKeyActivityFilter = ({
  userContributionData,
  setFilteredKeyActivity,
  className,
}: Props) => {
  const { t } = useTranslation();
  const uniqueWeeklyKeyActivities = getUnique(
    userContributionData?.flatMap((u) => u.weeklyKeyActivities) ?? []
  );

  const { Option } = Select;

  return (
    <div
      className={className}
      data-intercom-target="Previous Period Summary - team member filter"
    >
      <Select
        defaultValue={'ALL_KEY_ACTIVITIES'}
        onChange={setFilteredKeyActivity}
        style={{ width: '220px' }}
      >
        <Option value={'ALL_KEY_ACTIVITIES'}>
          {t('WeeklyKeyActivityFilter.allKeyActivities')}
        </Option>

        {uniqueWeeklyKeyActivities.map((wka) => {
          return (
            <Option key={wka.id} value={wka.id}>
              {wka.name}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};
