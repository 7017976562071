import { useMutation } from '@apollo/client';
import { Divider, Form, Modal, Typography } from 'antd';
import gql from 'graphql-tag';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorAlert } from '../../../../components/ErrorAlert';
import { RenderedMarkdown } from '../../../../components/RenderedMarkdown';
import { WeeklyActivityAchievementForm } from '../../../../components/WeeklyActivityAchievementForm';
import {
  AchievementStatus,
  EditAchievementDocument,
  GetUserAchievementsDocument,
} from '../../../../generated/graphql';
import { showNotification } from '../../../../services/fetchNotificationProperties';
import { EditOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface Props {
  akpi: {
    acceptanceCriteriaHtml?: string | null;
    name: string;
    unit: string;
    achievementDescriptionMandatory: boolean;
  } | null;
  achievement: {
    description?: string | null;
    quantity: number;
    id: string;
    userId: string;
  };
  tenantId?: string;
}

export const EditWeeklyAchievementModal = ({
  achievement,
  akpi,
  tenantId,
}: Props) => {
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const [updateAchievement, { loading, error }] = useMutation(
    EditAchievementDocument,
    {
      refetchQueries: (res) => {
        if (!res.data?.editWeeklyAchievement) return [];
        const queries = [
          {
            query: GetUserAchievementsDocument,
            variables: {
              userId: res.data.editWeeklyAchievement.userId,
              achievementStatus: AchievementStatus.DELETABLE_BY_USER,
              tenantId,
            },
          },
        ];
        if (tenantId) {
          queries.push({
            query: GetUserAchievementsDocument,
            variables: {
              userId: res.data.editWeeklyAchievement.userId,
              achievementStatus: AchievementStatus.NOT_DELETABLE_BY_USER,
              tenantId,
            },
          });
        }
        return queries;
      },
      onCompleted: () => {
        setShowModal(false);
        showNotification('success', {
          message: 'Achievement successfully updated.',
        });
      },
    }
  );

  const handleSave = () => {
    form.validateFields().then((values) => {
      if (!achievement) return;

      updateAchievement({
        variables: {
          achievementId: achievement.id,
          achievementData: values,
          tenantId,
        },
      });
    });
  };

  const handleClose = () => {
    form.resetFields();
    setShowModal(false);
  };

  return (
    <>
      <EditOutlined
        className="txt--action-blue"
        onClick={() => {
          setShowModal(true);
        }}
      />

      <Modal
        maskClosable={false}
        title={t('EditAchievementModal.modalTitle') + ': ' + akpi?.name}
        open={showModal}
        footer={null}
        onCancel={handleClose}
        okText="Save"
      >
        {akpi?.acceptanceCriteriaHtml && (
          <>
            <Text
              type="secondary"
              strong
              data-testid="report-card-acceptance-criteria-title"
            >
              {t('ReportCard.acceptanceCriteriaTitle')}
            </Text>
            <RenderedMarkdown html={akpi.acceptanceCriteriaHtml} />
          </>
        )}
        {akpi?.acceptanceCriteriaHtml && <Divider className="mt mb" />}
        {akpi && (
          <WeeklyActivityAchievementForm
            initialValues={achievement}
            form={form}
            akpi={akpi}
            onSubmit={handleSave}
            loading={loading}
          />
        )}
      </Modal>
      {error && (
        <ErrorAlert error={error} title={t('EditAchievementModal.error')} />
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_WEEKLY_ACHIEVEMENT = gql`
  mutation editAchievement(
    $achievementId: ID!
    $achievementData: EditWeeklyAchievementInput!
    $tenantId: ID
  ) {
    editWeeklyAchievement(
      achievementId: $achievementId
      achievementData: $achievementData
      tenantId: $tenantId
    ) {
      id
      description
      akpi {
        id
        name
        unit
      }
      quantity
      team {
        id
        name
      }
      userId
    }
  }
`;
