import Icon, {
  IconComponentProps,
} from '@ant-design/icons/lib/components/Icon';

const ManufacturingIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m4.875 10-.25-1.25a5.906 5.906 0 0 1-.468-.22 3.627 3.627 0 0 1-.448-.28L2.5 8.623l-.833-1.416.958-.834a3.557 3.557 0 0 1 0-1.083l-.958-.833L2.5 3.04l1.209.375c.152-.111.302-.205.448-.281.145-.077.302-.15.468-.219l.25-1.25h1.667l.25 1.25c.167.07.323.142.469.219.146.076.295.17.448.281l1.208-.375.833 1.417-.958.833a3.557 3.557 0 0 1 0 1.083l.958.834-.833 1.416-1.208-.375a3.627 3.627 0 0 1-.448.282 5.866 5.866 0 0 1-.469.218L6.542 10H4.875Zm.834-2.5c.458 0 .85-.164 1.177-.49.326-.327.49-.719.49-1.177 0-.459-.164-.851-.49-1.177a1.605 1.605 0 0 0-1.177-.49c-.459 0-.851.163-1.177.49-.327.326-.49.718-.49 1.177 0 .458.163.85.49 1.177.326.326.718.49 1.177.49Zm6.625 11.666-.375-1.75a3.777 3.777 0 0 1-.657-.302 5.284 5.284 0 0 1-.593-.406l-1.667.541-1.167-2 1.334-1.166a6.786 6.786 0 0 1 0-1.5l-1.334-1.167 1.167-2 1.667.542a5.26 5.26 0 0 1 .593-.407c.202-.118.42-.218.657-.302l.375-1.75h2.333l.375 1.75c.236.084.455.184.656.302.202.118.4.254.594.407l1.667-.542 1.166 2-1.333 1.167a6.781 6.781 0 0 1 0 1.5l1.333 1.166-1.166 2-1.667-.541a5.294 5.294 0 0 1-.594.406c-.201.118-.42.219-.656.302l-.375 1.75h-2.333Zm1.166-3.333c.695 0 1.285-.243 1.771-.73a2.41 2.41 0 0 0 .73-1.77c0-.695-.244-1.285-.73-1.771a2.411 2.411 0 0 0-1.77-.73 2.41 2.41 0 0 0-1.771.73 2.411 2.411 0 0 0-.73 1.77c0 .695.243 1.285.73 1.771.486.487 1.076.73 1.77.73Z" />
  </svg>
);

export const ManufactoringIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={ManufacturingIconSvg} {...props} />
);
