import { gql, useLazyQuery } from '@apollo/client';
import { GetRelatedWeeklyKeyActivitiesDocument } from '../generated/graphql';

export const useMigRelatedWeeklyKeyActivitiesLazy = () => {
  const [fetchRelatedSprintKeyActivities, { data, loading, error }] =
    useLazyQuery(GetRelatedWeeklyKeyActivitiesDocument);

  return [
    fetchRelatedSprintKeyActivities,
    {
      data: data?.relatedWeeklyKeyActivities,
      loading,
      error,
    },
  ] as const;
};

export const MIG_RELATED_WEEKLY_KA = gql`
  query getRelatedWeeklyKeyActivities($teamId: ID!, $migId: ID!) {
    relatedWeeklyKeyActivities(teamId: $teamId, migId: $migId) {
      keyActivities {
        id
        name
        teamId
      }
    }
  }
`;
