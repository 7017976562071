import { useQuery, gql } from '@apollo/client';
import { GetMitemTermsDocument, PublishStatus } from '../generated/graphql';
import { sprintTermDTOtoSprintTermData } from '../services/sprintKeyActivityUtils';

export const useSprintTerms = (teamId: string, tenantId?: string) => {
  const { data, loading, error } = useQuery(GetMitemTermsDocument, {
    variables: { teamId, tenantId },
  });

  const terms = data?.mitemTerms.payload.map((t) =>
    sprintTermDTOtoSprintTermData(t)
  );

  return {
    data: terms,
    sprintTermsEnabled:
      !loading &&
      terms?.some(
        (t) =>
          t.status === PublishStatus.ACTIVE ||
          t.status === PublishStatus.UPCOMING
      ),
    loading,
    error,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MITEM_TERMS = gql`
  query getMitemTerms($teamId: ID!, $tenantId: ID) {
    mitemTerms(teamId: $teamId, tenantId: $tenantId) {
      payload {
        id
        sprintInterval
        startDate
        archivedAt
        endDate
        publishDate
        unpublishDate
        status
        published
      }
    }
  }
`;
