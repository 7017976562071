import { gql, useSuspenseQuery } from '@apollo/client';
import { Divider, FormInstance, Modal, Select } from 'antd';
import {
  GetAlignToMigSelector_MigsDocument,
  MigStatus,
} from '../../../generated/graphql';
import { Btn } from '../../Button';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamMigSelector } from './TeamMigSelector';

type TeamMig = {
  id: string;
  name: string;
  status: MigStatus;
};

interface Props {
  form: FormInstance<any>;
  id?: string;
  value?: string;
  initialMig?: { id: string; name: string; status: MigStatus };
  onChange?: (migId: string) => void;
  className?: string;
  disabled?: boolean;
}

export const AlignToMigSelector = ({
  form,
  id,
  value,
  initialMig,
  onChange,
  className,
  disabled,
}: Props) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [showTeamSelect, setShowTeamSelect] = useState(false);
  const { data } = useSuspenseQuery(GetAlignToMigSelector_MigsDocument);
  const globalMigs = data.globalMigs.globalMigs;

  const initialMigAlreadyIncluded = globalMigs.some(
    (m) => m.id === initialMig?.id
  );
  const [teamMig, setTeamMig] = useState<TeamMig | undefined>(
    initialMigAlreadyIncluded ? undefined : initialMig
  );

  const options = [
    {
      label: <span>{t('AlignToMigSelector.companyMigs')}</span>,
      options: globalMigs.map((m) => ({
        value: m.id,
        label: (
          <span>
            {m.name}
            <ArchivedOrCompleted status={m.status} />
          </span>
        ),
      })),
    },
    {
      label: <span>{t('AlignToMigSelector.teamMigs')}</span>,
      exclude: !teamMig,
      options: [
        {
          label: (
            <span>
              {teamMig?.name}
              <ArchivedOrCompleted status={initialMig?.status} />
            </span>
          ),
          value: teamMig?.id,
        },
      ],
    },
  ];

  return (
    <>
      <Modal
        open={showTeamSelect}
        footer={null}
        destroyOnClose
        onCancel={() => setShowTeamSelect(false)}
      >
        <TeamMigSelector
          onCancel={() => setShowTeamSelect(false)}
          onAddTeamMig={(mig) => {
            setTeamMig(mig);
            form.setFieldsValue({ mig: mig.id });
            setShowTeamSelect(false);
          }}
        />
      </Modal>

      <Select
        id={id}
        className={className}
        options={options.filter((o) => !o.exclude)}
        value={value}
        open={open}
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        onChange={onChange}
        disabled={disabled}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <div className="flx flx--jc-center">
              <Btn
                className="mb"
                onClick={() => {
                  setShowTeamSelect(true);
                  setOpen(false);
                }}
              >
                {t('AlignToMigSelector.addTeamMig')}
              </Btn>
            </div>
          </>
        )}
      />
    </>
  );
};

const ArchivedOrCompleted = ({ status }: { status?: MigStatus }) => {
  const { t } = useTranslation();

  if (!status || status === MigStatus.ACTIVE || status === MigStatus.UPCOMING) {
    return null;
  }

  return (
    <span className="ml">
      [
      <span className="bold pl--xs pr--xs">
        {t(`common.mig.status.${status.toLowerCase()}`)}
      </span>
      ]
    </span>
  );
};

export const GET_GLOBAL_MIGS_TO_ALIGN_TO = gql`
  query GetAlignToMigSelector_Migs {
    globalMigs {
      globalMigs {
        id
        name
        domainId {
          itemId
          teamId
        }
        status
      }
    }
  }
`;
