import { Alert, Form, Input, Select, Spin, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MitemStatus,
  SprintKaForm_MilestoneQueryDocument,
  SprintKaForm_MitemFragment,
} from '../../../../../generated/graphql';
import { useSprintTerms } from '../../../../../hooks/useSprintTerms';
import { useTeamMembers } from '../../../../../hooks/useTeamMembers';
import { friendlyUsername } from '../../../../../services/friendlyUsername';
import { useSprintPlanningData } from '../../../common/hooks/useSprintPlanningData';
import { TitleWithTooltip } from '../../../setup/components/TitleWithTooltip';
import { useMitemRelationshipQuery } from '../../common/services/useMitemManagementData';
import { MIG_ASSOCIATION_OTHER } from '../SprintPlanningPage';
import { DateStatus, getSprintDateStatus } from './getSprintDateStatus';
import { TagsSelector } from './MitemForm/TagsItem';
import { SprintDatepicker } from './SprintDatepicker';
import dayjs from 'dayjs';
import { useTenantDetails } from '../../../../../hooks/useTenantDetails';
import { StarIcon } from '../../../../../icons/Star';
import './SprintKaForm.less';
import { Btn } from '../../../../../components/Button';
import { Link } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { skaValidationRules } from '../../../common/utils/useSkaValidationRules';
import { MitemFormTeamInitiativeFormItem } from './MitemForm/MitemFormTeamInitiativeFormItem';
import { match } from 'ts-pattern';
import { MitemFormTenantInitiativeFormItem } from './MitemForm/MitemFormTenantInitiativeFormItem';
import { MitemFormMilestoneFormItem } from './MitemForm/MitemFormMilestoneFormItem';
import { RecursiveOmit } from '../../../../../services/typeHelpers';

export type SkaInitialValues = Partial<
  RecursiveOmit<SprintKaForm_MitemFragment, '__typename'>
>;

interface Props {
  initialValues?: SkaInitialValues;
  submitPending?: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  setIsFormDirty?: (dirty: boolean) => void;
  isEdit?: boolean;
  teamId: string;
  mitemStatus?: DateStatus | null;
}

export const SprintKaForm = ({
  mitemStatus,
  initialValues,
  submitPending,
  teamId,
  isEdit = false,
  onSubmit,
  onCancel,
  setIsFormDirty,
}: Props) => {
  const { t } = useTranslation();
  const { features } = useTenantDetails();
  const [showOtherReasonField, setShowOtherReasonField] = useState(() => {
    const supportsMig = initialValues?.supportedMigs?.[0];

    return supportsMig == null && initialValues?.noMigAssociation != null;
  });
  const { data: sprintData, loading: sprintLoading } =
    useSprintPlanningData(teamId);

  const [fetchMilestone, { data: milestoneData, loading: milestoneLoading }] =
    useLazyQuery(SprintKaForm_MilestoneQueryDocument);

  const initialMig = initialValues?.supportedMigs?.[0];
  const [formRef] = Form.useForm();
  const selectedInitiativeIds =
    (Form.useWatch('supportsInitiativeIds', formRef) as string[]) ?? [];

  const selectedMilestoneIds =
    (Form.useWatch('supportsMilestoneIds', formRef) as string[]) ?? [];

  const { data: termsData, loading: loadingTerms } = useSprintTerms(teamId);
  const terms = termsData ?? [];
  const currentSprint =
    sprintData &&
    sprintData.currentSprintAndOnward &&
    sprintData.currentSprintAndOnward[0];

  const [isOwnerUpdated, setOwnerUpdated] = useState(false);
  const [selectedDeadlineStatus, setSelectedDeadlineStatus] = useState<
    MitemStatus | DateStatus | null
  >(initialValues?.status || mitemStatus || null);

  const { data, loading } = useMitemRelationshipQuery(teamId);
  const {
    data: members,
    isMember,
    loading: loadingMembers,
  } = useTeamMembers(teamId);
  const mitemOwnerId = initialValues?.owner?.id;
  const isOwnerMemberOfTeam = mitemOwnerId && isMember(mitemOwnerId);

  const initialMilestoneIds =
    initialValues?.supportsMilestones?.map((i) => i.id) ?? [];

  // if we are creating a new mitem and there is only one prefilled milestone,
  // we fetch it so we can show the milestone name in the form while hiding all the
  // alignment fields
  const hasPrefilledMilestoneAlignment =
    !isEdit && initialMilestoneIds.length === 1;
  const milestoneToFetch =
    hasPrefilledMilestoneAlignment &&
    initialValues?.supportsMilestones?.[0].domainId.itemId;

  useEffect(() => {
    if (milestoneToFetch) {
      fetchMilestone({ variables: { milestoneId: milestoneToFetch } });
    }
  }, [milestoneToFetch, fetchMilestone]);

  if (loadingMembers || loadingTerms || loading || sprintLoading) {
    return <Spin className="center-content" />;
  }

  let supportMigIdInitialValue = null;
  if (
    initialValues?.supportedMigs?.length === 0 &&
    initialValues?.noMigAssociation != null
  ) {
    supportMigIdInitialValue = MIG_ASSOCIATION_OTHER;
  } else if (initialValues && initialMig) {
    supportMigIdInitialValue = initialMig.id;
  }

  const initialSupportInitiativeIds = match(features)
    .with(
      { teamInitiativesEnabled: true, tenantInitiativesEnabled: false },
      () => {
        return initialValues?.supportedInitiatives?.map((i) => i.id) ?? [];
      }
    )
    .with(
      { teamInitiativesEnabled: false, tenantInitiativesEnabled: true },
      () => {
        return initialValues?.supportsInitiatives2?.map((i) => i.id) ?? [];
      }
    )
    .with(
      { teamInitiativesEnabled: false, tenantInitiativesEnabled: false },
      () => {
        return [];
      }
    )
    .with(
      { teamInitiativesEnabled: true, tenantInitiativesEnabled: true },
      () => {
        throw new Error('Both team and tenant initiatives enabled');
      }
    )
    .exhaustive();

  const formInitialValues = {
    ...initialValues,
    supportsMigId: supportMigIdInitialValue,
    deadline: initialValues?.deadline && dayjs(initialValues.deadline),
    ownerId: isOwnerMemberOfTeam ? mitemOwnerId : null,
    tags: initialValues?.tags?.map((t) => t.id) ?? [],
    supportsInitiativeIds:
      initialSupportInitiativeIds.length > 0
        ? initialSupportInitiativeIds
        : [''],
    supportsMilestoneIds:
      initialMilestoneIds.length > 0 ? initialMilestoneIds : [''],
  };
  const selectableMigs = getSelectableMigs(data.migs, initialMig);

  const isCompleted = initialValues?.completed ?? false;

  if (currentSprint == null) {
    return (
      <Alert
        type="info"
        message={t('SprintKaForm.noTermForTeam')}
        description={
          <div>
            <Link
              className="space--r"
              to={`/team/${teamId}/setup/key_activity`}
            >
              {t('SprintKaForm.clickHere')}
            </Link>
            {t('SprintKaForm.goToSetup')}
          </div>
        }
      ></Alert>
    );
  }

  return (
    <Spin spinning={loading || loadingMembers}>
      <Form
        clearOnDestroy={true}
        form={formRef}
        layout="vertical"
        requiredMark="optional"
        initialValues={formInitialValues}
        onValuesChange={(value) => {
          setIsFormDirty?.(true);
          if (value?.deadline && data.accelerationDay && currentSprint) {
            setSelectedDeadlineStatus(
              getSprintDateStatus({
                selectedDate: value.deadline,
                accelerationDay: data.accelerationDay,
                currentSprint,
                terms,
              })
            );
          }
        }}
        onFinish={(values) => {
          setIsFormDirty?.(false);
          onSubmit(values);
        }}
      >
        <h4 className="bold">{t('SprintKaForm.basicInfo')}</h4>
        <Form.Item
          name="name"
          label={t('common.title')}
          required
          rules={skaValidationRules.name}
          data-intercom-target="Sprint Key Activity Form Name Input"
        >
          <Input autoComplete="off" autoFocus disabled={isCompleted} />
        </Form.Item>
        <Form.Item
          name="definitionOfDone"
          label={t('SprintKaForm.dodLabel')}
          required
          rules={skaValidationRules.definitionOfDone}
          data-intercom-target="Sprint Key Activity Form Definition of Done Input"
        >
          <Input.TextArea
            autoComplete="off"
            disabled={isCompleted}
            autoSize={{ minRows: 2, maxRows: 10 }}
            maxLength={1024}
            showCount
          />
        </Form.Item>
        <Form.Item
          name="deadline"
          hidden={isCompleted}
          label={
            <TitleWithTooltip
              tooltipContent={t('SprintKaForm.deadlineHint')}
              title={t('SprintKaForm.deadlineLabel')}
            />
          }
          required
          rules={skaValidationRules.deadline}
          data-intercom-target="Sprint Key Activity Form Deadline Input"
        >
          <SprintDatepicker
            disabled={isCompleted}
            teamId={teamId}
            accelerationDay={data.accelerationDay}
            className={`SprintDatepicker--${selectedDeadlineStatus}`}
            showNow={false}
          />
        </Form.Item>
        <Form.Item
          name="ownerId"
          label={t('SprintKaForm.ownerLabel')}
          hidden={isCompleted}
          extra={
            !isOwnerMemberOfTeam &&
            !isOwnerUpdated &&
            initialValues?.owner &&
            t('SprintKaForm.notMember', {
              user: friendlyUsername(initialValues.owner),
            })
          }
          required
          rules={skaValidationRules.ownerId}
          data-intercom-target="Sprint Key Activity Form Owner Input"
        >
          <Select
            showSearch
            options={members.map((m) => ({
              label: friendlyUsername(m),
              value: m.id,
            }))}
            filterOption={(inputValue, option) =>
              option?.label
                .toLocaleLowerCase()
                .includes(inputValue.toLocaleLowerCase()) ?? false
            }
            disabled={isCompleted}
            onChange={() => setOwnerUpdated(true)}
          />
        </Form.Item>
        <Form.Item
          name="tags"
          label={t('SprintKaForm.teamTags')}
          className="mb--xl"
        >
          <TagsSelector teamId={teamId} />
        </Form.Item>
        {!features.tenantInitiativesEnabled && (
          <>
            <h4 className="bold mt--xl">
              {t('SprintKaForm.specialProperties')}
            </h4>
            <Form.Item required>
              <Form.Item
                name="milestone"
                required
                valuePropName="checked"
                noStyle
              >
                <Switch className="mr--s" />
              </Form.Item>
              <StarIcon /> {t('SprintKaForm.milestoneLabel')}
            </Form.Item>
          </>
        )}

        <h4 className="bold mt--xl">{t('SprintKaForm.alignment')}</h4>

        {hasPrefilledMilestoneAlignment && (
          <Form.Item name="supportsMilestoneIds" label="Milestone" required>
            <Input type="hidden" />
            <Select
              disabled
              value={milestoneData?.milestone.name}
              loading={milestoneLoading}
            />
          </Form.Item>
        )}

        {!hasPrefilledMilestoneAlignment && (
          <>
            <Form.Item
              name="supportsMigId"
              label={t('SprintKaForm.migLabel')}
              hidden={isCompleted}
              data-intercom-target="Sprint Key Activity Form MIG Input"
            >
              <Select
                disabled={isCompleted}
                showSearch
                optionFilterProp="children"
                allowClear
                onChange={(value) => {
                  setShowOtherReasonField(value === MIG_ASSOCIATION_OTHER);
                }}
              >
                {selectableMigs.map((m) => (
                  <Select.Option key={m.id} value={m.id}>
                    {m.name}
                  </Select.Option>
                ))}
                <Select.Option value={MIG_ASSOCIATION_OTHER}>
                  {t('SprintKaForm.migOther')}
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              hidden={!showOtherReasonField}
              required={showOtherReasonField}
              rules={skaValidationRules.noMigAssociation({
                NoMigAssociationRequired: showOtherReasonField,
              })}
              name="noMigAssociation"
              label={t('SprintKaForm.noMigReasonLabel')}
            >
              <Input.TextArea
                autoComplete="off"
                disabled={isCompleted}
                autoSize={{ minRows: 2, maxRows: 8 }}
                maxLength={1024}
                showCount
              />
            </Form.Item>

            {features.teamInitiativesEnabled && (
              <MitemFormTeamInitiativeFormItem
                teamId={teamId}
                selectedInitiativeIds={selectedInitiativeIds}
                initialSupportInitiativeIds={initialSupportInitiativeIds}
              />
            )}

            {features.tenantInitiativesEnabled && (
              <MitemFormTenantInitiativeFormItem
                teamId={teamId}
                selectedInitiativeIds={selectedInitiativeIds}
                initialSupportInitiativeIds={initialSupportInitiativeIds}
              />
            )}

            {features.tenantInitiativesEnabled && (
              <MitemFormMilestoneFormItem
                teamId={teamId}
                selectedMilestoneIds={selectedMilestoneIds}
                initialMilestoneIds={initialMilestoneIds}
              />
            )}
          </>
        )}
        <>
          <Btn type="primary" loading={submitPending} htmlType="submit">
            {isEdit ? t('common.save') : t('common.create')}
          </Btn>
          <Btn type="text" onClick={onCancel} disabled={submitPending}>
            {t('common.cancel')}
          </Btn>
        </>
      </Form>
    </Spin>
  );
};

function getSelectableMigs<MIG extends { id: string; name: string }>(
  activeMigs: MIG[],
  connectedMig?: MIG | null
) {
  if (connectedMig && !activeMigs.some((am) => am.id === connectedMig.id)) {
    return [...activeMigs, connectedMig];
  }

  return activeMigs;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KA_FORM_MITEM = gql`
  fragment SprintKaForm_Mitem on Mitem {
    id
    name
    archived
    archivedAt
    completed
    completedAt
    deadline
    definitionOfDone
    noMigAssociation
    milestone
    supportedInitiatives {
      id
      archived
      completed {
        value
      }
      tag {
        title
        iconId
        colorCode
      }
    }
    supportsMilestones {
      id
      domainId {
        itemId
      }
    }
    supportsInitiatives2 {
      id
      initiative {
        id
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          archived
          completedAt
        }
      }
    }
    status
    tags {
      id
      name
      teamId
      active
      backgroundColor
      createdAt
    }
    owner {
      id
      email
      name
      displayName
      archivedAt
    }
    supportedMigs {
      id
      name
      domainId {
        itemId
        teamId
      }
    }
    teamId
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_QUERY = gql`
  query SprintKaForm_MilestoneQuery($milestoneId: ID!) {
    milestone(milestoneId: $milestoneId) {
      id
      domainId {
        itemId
        tenantId
      }
      name
    }
  }
`;
