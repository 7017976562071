import type { EventDataNode } from 'antd/es/tree';
import { ConfigProvider, Tree } from 'antd';
import { useState } from 'react';
import './InitiativesTree.less';
import { useHistory } from 'react-router';
import {
  getParentKeys,
  InitiativeNode,
} from './services/initiativeTreeNodeHelper';
import { Colors } from '../../../componentLibrary/Colors';

interface Props {
  initiativeId?: string;
  treeData: InitiativeNode[];
}

export const InitiativesTree = ({ initiativeId, treeData }: Props) => {
  const [expandedKeysArray, setExpandedKeysArray] = useState<string[]>(
    getParentKeys(treeData, initiativeId) || []
  );

  const history = useHistory();

  const onSelect = (node: EventDataNode<InitiativeNode>, redirect: boolean) => {
    const key = node.key;

    if (expandedKeysArray.includes(key)) {
      setExpandedKeysArray(expandedKeysArray.filter((k) => k !== key));
    } else {
      setExpandedKeysArray([...expandedKeysArray, key]);
    }
    redirect && history.push(`/company/initiatives/${node.link}`);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimaryBg: Colors.Grays.BACKGROUND,
        },
      }}
    >
      <Tree
        expandedKeys={expandedKeysArray}
        treeData={treeData}
        selectable={true}
        onExpand={(_, info) => onSelect(info.node, false)}
        showLine={true}
        showIcon={false}
        onSelect={(_, info) => onSelect(info.node, true)}
        rootStyle={{ backgroundColor: Colors.Grays.BACKGROUND }}
      />
    </ConfigProvider>
  );
};
