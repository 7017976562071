import { InfoCircleOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Divider, Typography } from 'antd';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateMirroredMigDocument,
  GetTeamFullMigsV2Document,
  LinkMigToTeamModal_MigV2Fragment,
  MigDomainId,
} from '../../../generated/graphql';
import { showNotification } from '../../../services/fetchNotificationProperties';
import { ErrorAlert } from '../../ErrorAlert';
import { FullscreenModal } from '../../FullscreenModal';

import './LinkMigToTeamModal.less';

import { Btn } from '../../Button';
import { LinkMigSteps } from '../components/LinkMigSteps';

interface Props {
  tenantId?: string;
  teamId: string;
  migsAlreadyLinkedToTeam: LinkMigToTeamModal_MigV2Fragment[];
}

export const LinkMigToTeamModal = ({
  tenantId,
  teamId,
  migsAlreadyLinkedToTeam,
}: Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [values, setValues] = useState<{
    teamId: string | null;
    migDomainId: MigDomainId | null;
  }>({ teamId: null, migDomainId: null });

  useEffect(() => {
    (window as any).Intercom?.('update', {
      hide_default_launcher: showModal,
    });

    // sometimes the drawer is unmounted rather than getting the prop showModal={false}
    // this will make sure we show the intercom widget again in that case
    return () =>
      (window as any).Intercom?.('update', {
        hide_default_launcher: false,
      });
  }, [showModal]);

  const [createMirror, { loading: createLoader }] = useMutation(
    CreateMirroredMigDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: <strong>{t('LinkMigToTeamModal.mirrorSuccess')}</strong>,
        });
        setValues({ teamId: null, migDomainId: null });
        setShowModal(false);
      },
      onError: (error) => {
        showNotification('error', {
          message: <strong>{t('LinkMigToTeamModal.mirrorFailed')}</strong>,
          description: <ErrorAlert error={error} />,
        });
      },
      refetchQueries: () => [
        {
          query: GetTeamFullMigsV2Document,
          variables: { teamId, tenantId },
        },
      ],
    }
  );

  const handleSubmit = () => {
    if (values.migDomainId)
      createMirror({
        variables: {
          teamId,
          tenantId,
          targetedMigDomainId: {
            tenantId: values.migDomainId.tenantId,
            teamId: values.migDomainId.teamId,
            itemId: values.migDomainId.itemId,
          },
        },
      });
  };

  return (
    <div>
      <Btn
        className="mr--s"
        data-intercom-target="MIG Setup Page Link MIG Button"
        onClick={() => setShowModal(true)}
      >
        {t('LinkMigToTeamModal.button')}
      </Btn>
      <FullscreenModal
        title={
          <div className="flx flx--column">
            <Typography.Text>{t('LinkMigToTeamModal.title')}</Typography.Text>
          </div>
        }
        styles={{ body: { backgroundColor: '#f1f1f1' } }}
        okButtonProps={{
          disabled: !values.migDomainId || createLoader,
        }}
        okText={t('LinkMigToTeamModal.okButton')}
        onOk={handleSubmit}
        destroyOnClose
        open={showModal}
        onCancel={() => {
          setValues({
            teamId: null,
            migDomainId: null,
          });

          setShowModal(false);
        }}
      >
        <div>
          <InfoCircleOutlined style={{ fontSize: 18, marginRight: 12 }} />
          {t('LinkMigToTeamModal.helpText')}
        </div>
        <Divider />
        <LinkMigSteps
          value={values}
          onChange={setValues}
          linkedMigs={migsAlreadyLinkedToTeam}
        />
      </FullscreenModal>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_MIRROR_MIG = gql`
  mutation createMirroredMig(
    $tenantId: ID
    $teamId: ID!
    $targetedMigDomainId: MigDomainIdInput!
  ) {
    createMirroredMig(
      tenantId: $tenantId
      teamId: $teamId
      targetedMigDomainId: $targetedMigDomainId
    ) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LINK_MIG_TO_TEAM_MODAL_MIG_V2 = gql`
  fragment LinkMigToTeamModal_MigV2 on MigV2 {
    id
    domainId {
      itemId
      teamId
      tenantId
    }
    ...LinkMigSteps_MigV2
  }
`;
