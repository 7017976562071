import { Strategy } from '../meetingSteps/AkpiCommitment/strategyUtils';

interface Period {
  timePeriodIndex: number;
  goal: number;
  actual?: number | null;
}

export function getCommitmentStrategies(
  periodData: Period[],
  currentPeriodIndex: number | null
) {
  return {
    [Strategy.ACCORDING_TO_PLAN]: Math.ceil(
      accordingToPlan(periodData, currentPeriodIndex)
    ),
    [Strategy.SAME_AS_LAST_PERIOD]: Math.ceil(
      sameAsLastPeriod(periodData, currentPeriodIndex)
    ),
    [Strategy.REACH_GOAL_THIS_PERIOD]: Math.ceil(
      reachGoalThisPeriod(periodData, currentPeriodIndex)
    ),
    [Strategy.REACH_GOAL_AT_END_DATE]: Math.ceil(
      reachGoalAtEnd(periodData, currentPeriodIndex)
    ),
    [Strategy.ALREADY_ON_TRACK]: accordingToPlan(
      periodData,
      currentPeriodIndex
    ),
  };
}

export function getInitialStrategy(
  periodData: Period[],
  currentPeriodIndex: number | null
) {
  if (currentPeriodIndex == null || currentPeriodIndex === 0)
    return Strategy.ALREADY_ON_TRACK;
  const previousActual = periodData[currentPeriodIndex - 1]?.actual ?? 0;
  const previousGoal = periodData[currentPeriodIndex - 1]?.goal ?? 0;

  return previousActual >= previousGoal
    ? Strategy.ALREADY_ON_TRACK
    : Strategy.ACCORDING_TO_PLAN;
}

export function sameAsLastPeriod(
  periodData: Period[],
  currentPeriodIndex: number | null
) {
  if (currentPeriodIndex == null) return 0;
  const previousActual = periodData[currentPeriodIndex - 1]?.actual ?? 0;
  const previousPreviousActual =
    periodData[currentPeriodIndex - 2]?.actual ?? 0;
  return previousActual - previousPreviousActual;
}

export function reachGoalThisPeriod(
  periodData: Period[],
  currentPeriodIndex: number | null
) {
  if (currentPeriodIndex == null) return 0;
  const currentGoal = periodData[currentPeriodIndex]?.goal ?? 0;
  const previousActual = periodData[currentPeriodIndex - 1]?.actual ?? 0;
  return currentGoal - previousActual;
}

export function reachGoalAtEnd(
  periodData: Period[],
  currentPeriodIndex: number | null
) {
  if (currentPeriodIndex == null) return 0;
  const endGoal = periodData[periodData.length - 1]?.goal ?? 0;
  const previousActual = periodData[currentPeriodIndex - 1]?.actual ?? 0;
  const periodsLeft = periodData.length - currentPeriodIndex;
  return (endGoal - previousActual) / periodsLeft;
}

export function accordingToPlan(
  periodData: Period[],
  currentPeriodIndex: number | null
) {
  if (currentPeriodIndex == null) return 0;

  const previousGoal = periodData[currentPeriodIndex - 1]?.goal;
  const currentGoal = periodData[currentPeriodIndex]?.goal ?? 0;
  return previousGoal == null ? currentGoal : currentGoal - previousGoal;
}
