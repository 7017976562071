import './MilestoneActivitiesTable.less';
import { useTranslation } from 'react-i18next';
import { MilestoneActivityTableRowSkeleton } from './MilestoneActivityTableRow.Skeleton';

interface Props {
  numberOfActivities: number;
}

export const MilestoneActivitiesTableSkeleton = ({
  numberOfActivities,
}: Props) => {
  const { t } = useTranslation();

  const numberOfSkeletons = Math.min(Math.max(numberOfActivities, 3), 20);

  return (
    <div className="MilestoneActivitiesTable">
      <table className="MilestoneActivitiesTable">
        <thead>
          <tr>
            <th className="text-l txt--secondary">{t('common.deadline')}</th>
            <th className="text-l txt--secondary">{t('common.activity')}</th>
            <th className="text-l txt--secondary">{t('common.team')}</th>
            <th className="text-c txt--secondary">{t('common.status')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: numberOfSkeletons }).map((_, index) => (
            <MilestoneActivityTableRowSkeleton key={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
