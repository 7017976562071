import Icon from '@ant-design/icons';

const StarOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.90016 9.88333L7.00016 8.61667L9.10016 9.9L8.55016 7.5L10.4002 5.9L7.96683 5.68333L7.00016 3.41667L6.0335 5.66667L3.60016 5.88333L5.45016 7.5L4.90016 9.88333ZM2.8835 12.6667L3.96683 7.98333L0.333496 4.83333L5.1335 4.41667L7.00016 0L8.86683 4.41667L13.6668 4.83333L10.0335 7.98333L11.1168 12.6667L7.00016 10.1833L2.8835 12.6667Z" />
  </svg>
);

export const StarOutlineIcon = (props: any) => (
  <Icon component={StarOutlineIconSvg} {...props} />
);
