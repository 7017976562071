import { Radio } from 'antd';
import { Btn } from '../../../../../../components/Button';
import { InitiativeTag } from '../../../../../../components/initiative/InitiativeTag';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useWizardNavigation } from '../../../../../../components/WizardNavigationProvider';
import { gql } from '@apollo/client';
import { Step2Demo_InitiativeFragment } from '../../../../../../generated/graphql';

interface Props {
  initiative: Step2Demo_InitiativeFragment;
  selectedMilestoneSuggestion: MilestoneSuggestion | null;
  onSelectMilestoneSuggestion: (milestone: MilestoneSuggestion) => void;
}

const milestoneSuggestions: MilestoneSuggestion[] = [
  {
    area: 'Integration of Financial Systems and Processes',
    description:
      'The financial systems of both companies are fully integrated, with all financial data migrated to a unified platform, including consolidated financial statements.',
  },
  {
    area: 'Pricing Harmonization within Expanded Portfolio',
    description:
      'A comprehensive pricing strategy is established and implemented across the combined product and service portfolio.',
  },
  {
    area: 'Realization of Operational Synergies',
    description:
      'Includes consolidated IT infrastructure, streamlined operational reporting, and optimized supply chain management, resulting in increased efficiency and reduced redundancies.',
  },
  {
    area: 'Compliance and Risk Management within Acquired Company Contracts',
    description:
      'All contracts within the acquired company have been reviewed for compliance risks. A comprehensive compliance training program is rolled out to all relevant employees.',
  },
];

export const Step2 = ({
  initiative,
  selectedMilestoneSuggestion,
  onSelectMilestoneSuggestion,
}: Props) => {
  const wizard = useWizardNavigation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 700);

    return () => clearTimeout(timer);
  }, []);

  const handleChange = (suggestion: MilestoneSuggestion) => {
    wizard.unlockStep(wizard.currentStepPath);
    onSelectMilestoneSuggestion(suggestion);
  };

  return (
    <div>
      <h3 className="mb">Choose Milestone</h3>
      <div className="mb--xs">
        <span className="space--r">
          Below are a few AI generated milestones concerning:
        </span>
        <InitiativeTag tag={initiative.tag} />
      </div>
      <div className="font-size--sm mb--l">
        <span className="bold space--r ">Metadata tags used:</span>
        <div>
          <div>
            <span className="bold txt--secondary">Team Type</span>: Finance and
            Business Controlling
          </div>
          <div>
            <span className="bold txt--secondary">Goals: Gross</span> Margin,
            Working Capital, Cost Savings
          </div>
          <div>
            <span className="bold txt--secondary">Initiatives</span>: Merger
          </div>
        </div>
      </div>

      {loading ? (
        milestoneSuggestions.map((_, i) => (
          <div key={i} className="borderBottom mb--s pa--s">
            <h4>
              <Skeleton className="" count={0.3}></Skeleton>
            </h4>
            <p className="font-size--sm">
              <Skeleton className="" count={1.6}></Skeleton>
            </p>
          </div>
        ))
      ) : (
        <Radio.Group
          value={selectedMilestoneSuggestion}
          onChange={(e) => handleChange(e.target.value)}
        >
          {milestoneSuggestions.map((suggestion, index) => (
            <div key={index} className="borderBottom mb--s pa--s">
              <Radio value={suggestion}>
                <h4 className="mb--xs">{suggestion.area}</h4>
                <p className="font-size--sm">
                  <span className="bold space--r">Definition of done:</span>
                  {suggestion.description}
                </p>
              </Radio>
            </div>
          ))}
        </Radio.Group>
      )}

      <div className="mt--l flx flx--jc-flx-end">
        <Btn type="link" onClick={wizard.goToPreviousStep}>
          Go back
        </Btn>
        <Btn
          type="primary"
          onClick={wizard.goToNextStep}
          disabled={
            loading ||
            (!!wizard.activeStep.nextStep &&
              wizard.isLocked(wizard.activeStep.nextStep))
          }
        >
          Generate plan
        </Btn>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_INITIATIVES = gql`
  fragment Step2Demo_Initiative on InitiativeLight {
    id
    tag {
      colorCode
      iconId
      title
    }
  }
`;

export type MilestoneSuggestion = {
  area: string;
  description: string;
};
