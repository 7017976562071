import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const EndingPointIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    <path
      d="M13.333 13.333a3.21 3.21 0 0 0 2.355-.979A3.21 3.21 0 0 0 16.667 10a3.21 3.21 0 0 0-.98-2.354 3.21 3.21 0 0 0-2.354-.98 3.21 3.21 0 0 0-2.354.98A3.21 3.21 0 0 0 10 10c0 .917.326 1.701.98 2.354a3.21 3.21 0 0 0 2.353.98Zm0 1.667c-1.25 0-2.336-.396-3.26-1.188a4.833 4.833 0 0 1-1.677-2.979h-6.73V9.167h6.73a4.833 4.833 0 0 1 1.677-2.98C10.997 5.396 12.083 5 13.333 5c1.39 0 2.57.486 3.542 1.458.972.973 1.458 2.153 1.458 3.542 0 1.389-.486 2.57-1.458 3.542S14.722 15 13.333 15Z"
      fill="currentColor"
    />
  </svg>
);

export const EndingPointIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={EndingPointIconSvg} {...props} />
);
