import { Progress, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { roundToFloor } from '../../../../../services/roundNumbersHelper';
import './SprintProgress.less';

interface Props {
  total: number;
  completed: number;
}

export const SprintProgress = ({ completed, total }: Props) => {
  const { t } = useTranslation();

  const progressPercentage =
    total !== 0 ? roundToFloor((completed / total) * 100)! : 0;
  return (
    <div className="SprintProgress">
      <Progress
        size="default"
        type="circle"
        percent={progressPercentage}
        strokeColor={'#027C34'}
        strokeWidth={2}
        format={(percent) => (
          <div>
            <div>{`${percent}%`}</div>
            <Typography.Text type="secondary" className="font-size--default">
              {t('Mitem.Status.Completed')}
            </Typography.Text>
          </div>
        )}
      />

      <div className="SprintProgressCard__details">
        <div className="SprintProgressCard__completeLegend" />

        <Typography.Text> {t('Mitem.Status.Completed')}</Typography.Text>
        <Typography.Text type="secondary">
          {t('SprintProgress.CompleteLegend', {
            completed,
            total,
          })}
        </Typography.Text>
      </div>
    </div>
  );
};
