import dayjs from 'dayjs';

interface Sprint {
  endDate: string;
  id?: string | null;
  finalized?: boolean;
}

export const checkIfPlannedSprint = (sprint: Sprint | null) => {
  const isPlannedSprint =
    sprint?.id == null &&
    sprint?.endDate &&
    dayjs(sprint.endDate).isAfter(dayjs(), 'day');

  return Boolean(isPlannedSprint);
};

export const checkIfActiveSprint = (sprint: Sprint | null) => {
  if (!sprint) return false; // No sprint therefore not active

  if (sprint.id == null) return false; // Not committed yet, thus not active

  if (sprint.finalized) return false; // Finalized sprint is not active

  return dayjs(sprint.endDate).isAfter(dayjs(), 'day');
};
