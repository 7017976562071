import { gql, useQuery } from '@apollo/client';
import { Alert, Spin } from 'antd';

import { useTranslation } from 'react-i18next';
import { DisplayName } from '../../../../DisplayName';
import { GetIgnorableTeamMembersDocument } from '../../../../../generated/graphql';
import { friendlyUsername } from '../../../../../services/friendlyUsername';
import { stringSort } from '../../../../../services/stringSort';
import { toMap } from '../../../../../services/toMap';
import './OmitCommitter.less';
import { Btn } from '../../../../Button';

interface Props {
  teamId: string;
  value: string[];
  onChange: (selected: string[]) => void;
}

export const OmitCommitter = ({
  value: secondaryCommitters,
  onChange,
  teamId,
}: Props) => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(GetIgnorableTeamMembersDocument, {
    variables: { teamId },
  });
  const teamMembers = data?.usersInTeamPaginated.content ?? [];

  const memberMap = toMap(teamMembers, (m) => m.id);

  const primaryCommitters = teamMembers
    .filter((m) => !secondaryCommitters.includes(m.id))
    .sort((a, b) => stringSort(friendlyUsername(a), friendlyUsername(b)));

  const handleRemove = (memberId: string) => {
    onChange(secondaryCommitters.filter((m) => m !== memberId));
  };

  return (
    <Spin spinning={loading}>
      <Alert
        className="mb font-size--sm preserveLinebreaks"
        type="info"
        message={t('OmitCommitter.helpText')}
      />

      <div className="mb--l">
        <b className="mt">{t('OmitCommitter.primaryCommitters')}</b>
        <MemberList
          members={primaryCommitters}
          onChange={(memberId) => onChange([...secondaryCommitters, memberId])}
          buttonText={t('OmitCommitter.addButton')}
        />
      </div>
      <div>
        <b className="mt">{t('OmitCommitter.secondaryCommitters')}</b>
        <MemberList
          members={secondaryCommitters
            .map((mId) => memberMap[mId])
            .filter((m) => m)}
          onChange={handleRemove}
          buttonText={t('OmitCommitter.removeButton')}
        />
      </div>
    </Spin>
  );
};

interface Member {
  id: string;
  name: string | null;
  email: string;
  displayName: string | null;
}

interface MemberListProps {
  members: Member[];
  onChange: (memberIdToMove: string) => void;
  buttonText: string;
}

const MemberList = ({ members, onChange, buttonText }: MemberListProps) => (
  <div className="OmitCommitter__memberList">
    {members?.map((member) => (
      <div className="OmitCommitter__member" key={member.id}>
        <div className="flx mb--s">
          <DisplayName user={member} />
          <div className="ml--auto">
            <Btn
              size="small"
              className="OmitCommitter__addBtn ml"
              onClick={() => {
                onChange(member.id);
              }}
            >
              {buttonText}
            </Btn>
          </div>
        </div>
      </div>
    ))}
  </div>
);

// this is used indirectly - `GetIgnorableTeamMembersDocument` is generated from it
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_MEMBERS = gql`
  query getIgnorableTeamMembers($teamId: ID!) {
    usersInTeamPaginated(
      teamId: $teamId
      filter: { archived: false }
      pagination: { size: 0 }
    ) {
      content {
        id
        name
        displayName
        email
      }
    }
  }
`;
