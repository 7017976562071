import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NoData } from '../../../NoData';
import './MigRelations.less';
import { gql } from '@apollo/client';
import { MigRelationsMigLightFragment } from '../../../../generated/graphql';
import { RecursiveOmit } from '../../../../services/typeHelpers';

export const MIG_RELATIONS_FRAGMENT = gql`
  fragment MigRelationsMigLight on MigLight {
    id
    name
    domainId {
      itemId
      teamId
    }
    team {
      id
      name
    }
  }
`;

type MigLightWithoutTypename = RecursiveOmit<
  MigRelationsMigLightFragment,
  '__typename'
>;

interface Props {
  relations?: MigLightWithoutTypename[];
}

export const MigRelations = ({ relations }: Props) => {
  if (!relations || relations.length === 0) return <NoData />;

  const migsGroupedByTeam = transform(relations);

  return (
    <>
      {migsGroupedByTeam?.map((team) => (
        <div key={team.id}>
          <div className="txt--secondary font-size--sm">
            <Trans
              i18nKey="MigDescription.teamName"
              count={team.migs.length}
              values={{ teamName: team.name }}
              components={{
                teamLink: <Link to={`/team/${team.id}`}></Link>,
              }}
            />
          </div>

          <ul className="MigRelations__migList">
            {team.migs?.map((mig) => {
              return <li key={mig.id}>{mig.name}</li>;
            })}
          </ul>
        </div>
      ))}
    </>
  );
};

interface Team {
  id: string;
  name: string;
}

const transform = (migRelations: MigLightWithoutTypename[]) => {
  const map: {
    [key: string]: Team & { migs: MigLightWithoutTypename[] };
  } = {};

  for (const mig of migRelations) {
    const teamId = mig.team.id;

    if (!map[teamId]) {
      map[teamId] = { ...mig.team, migs: [] };
    }

    map[teamId].migs.push(mig);
  }

  return Object.values(map);
};
