import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { GetTeamSprintTermDocument } from '../generated/graphql';
import { sprintTermDTOtoSprintTermData } from '../services/sprintKeyActivityUtils';

interface Props {
  teamId: string;
  tenantId?: string;
  sprintTermId: string;
}

export const useSprintTerm = ({ teamId, tenantId, sprintTermId }: Props) => {
  const { loading, data, error } = useQuery(GetTeamSprintTermDocument, {
    variables: { teamId, tenantId, termId: sprintTermId },
  });

  const sprintTerm =
    data?.mitemTerm && sprintTermDTOtoSprintTermData(data.mitemTerm);

  return {
    data: data?.mitemTerm,
    sprintTerm,
    error,
    loading: loading,
  };
};

export const useSprintTermLazy = () => {
  const [fetchSprintTerm, { data, loading, error }] = useLazyQuery(
    GetTeamSprintTermDocument
  );

  const sprintTerm =
    data?.mitemTerm && sprintTermDTOtoSprintTermData(data.mitemTerm);

  return {
    fetchSprintTerm,
    data: data?.mitemTerm,
    sprintTerm: sprintTerm,
    error,
    loading,
  };
};

export const GET_TEAM_SPRINT_TERM = gql`
  query getTeamSprintTerm($teamId: ID!, $termId: ID!, $tenantId: ID) {
    mitemTerm(teamId: $teamId, termId: $termId, tenantId: $tenantId) {
      id
      sprintInterval
      archivedAt
      startDate
      endDate
      publishDate
      unpublishDate
      status
      published
      periodData {
        targetDate
        goal
        actual
      }
    }
  }
`;
