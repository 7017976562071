import { gql, useSuspenseQuery } from '@apollo/client';
import { GetAssignedMilestonesForReportDrawerDocument } from '../../../../generated/graphql';
import { Collapse, Empty } from 'antd';
import { StatusTag } from '../../../StatusTag';
import { MilestoneCollapseItem } from './MilestoneSection/MilestoneCollapseItem';
import { Suspense } from 'react';
import { MilestoneCollapseItemSkeleton } from './MilestoneSection/MilestoneCollapseItem.skeleton';
import { FlagOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { stringSort } from '../../../../services/stringSort';
import { MilestoneSectionSkeleton } from './MilestoneSection.Skeleton';
import { DisplayDate } from '../../../DisplayDate';

export const MilestoneSection = () => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(
    GetAssignedMilestonesForReportDrawerDocument,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    }
  );

  const milestonesSortedByDeadline =
    data.assignedInitiativesAndMilestones.milestones.toSorted((a, b) =>
      stringSort(a.deadlineAt, b.deadlineAt)
    );

  return (
    <div>
      <div className="flx">
        <div>
          <FlagOutlined className="TeamSectionV2__teamIcon" />
        </div>
        <div className="flx--1">
          <h3 className="mb">{t('MilestoneSection.title')}</h3>
          {milestonesSortedByDeadline.length === 0 && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('MilestoneSection.noMilestones')}
            ></Empty>
          )}

          {milestonesSortedByDeadline.map((milestone) => (
            <Collapse
              className="mb"
              key={milestone.id}
              expandIconPosition="end"
              items={[
                {
                  key: milestone.id,
                  label: (
                    <div>
                      <div className="mb--s flx flx--ai-center">
                        <StatusTag status={milestone.metadata.status} compact />
                        <DisplayDate
                          className="txt--secondary font-size--sm ml--auto"
                          date={milestone.deadlineAt}
                        />
                      </div>
                      <h4> {milestone.name}</h4>
                    </div>
                  ),
                  children: (
                    <p>
                      <Suspense fallback={<MilestoneCollapseItemSkeleton />}>
                        <MilestoneCollapseItem
                          milestoneId={milestone.domainId.itemId}
                        />
                      </Suspense>
                    </p>
                  ),
                },
              ]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

MilestoneSection.Skeleton = MilestoneSectionSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_SECTION__QUERY = gql`
  query getAssignedMilestonesForReportDrawer {
    assignedInitiativesAndMilestones {
      milestones {
        id
        domainId {
          itemId
        }
        name
        deadlineAt
        metadata {
          status
        }
      }
    }
  }
`;
