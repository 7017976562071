import { ReactNode } from 'react';
import { TabLink } from './Navigation/TabLink';
import './PageHeader.less';
import { BetaBadge } from './BetaBadge';
import Skeleton from 'react-loading-skeleton';
interface Props {
  pageTitle?: ReactNode;
  menuItems?: MenuItem[];
  actions?: ReactNode;
}

export const PageHeader = ({ pageTitle, menuItems, actions }: Props) => {
  return (
    <div className="PageHeader">
      <h1>{pageTitle || <Skeleton width={'50%'} />}</h1>
      <div className="flx borderBottom">
        {menuItems?.map((item) => (
          <TabLink
            key={item.url}
            to={item.url}
            data-intercom-target={item.intercomDataTarget}
            data-test-id={item.dataTestId}
          >
            {item.title}
            {item.isBeta && <BetaBadge className="space--l font-size--sm" />}
          </TabLink>
        ))}
        <div className="ml--auto flx flx--gap--s">{actions}</div>
      </div>
    </div>
  );
};

type MenuItem = {
  title: string;
  url: string;
  intercomDataTarget?: string;
  dataTestId?: string;
  isBeta?: boolean;
};
