import { Modal } from 'antd';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../../../../../../../../components/ErrorPage';
import { ArchiveMilestoneModalContent } from './ArchiveMilestoneModalContent';

interface Props {
  visible: boolean;
  milestoneId: string | undefined;
  onClose?: () => void;
}

export const ArchiveMilestoneModal = ({
  milestoneId,
  visible,
  onClose,
}: Props) => {
  return (
    <Modal
      open={visible}
      footer={null}
      style={{ top: 20 }}
      onCancel={onClose}
      destroyOnClose
    >
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Suspense fallback={<ArchiveMilestoneModalContent.Skeleton />}>
          {milestoneId && (
            <ArchiveMilestoneModalContent
              milestoneId={milestoneId}
              onClose={onClose}
            />
          )}
        </Suspense>
      </ErrorBoundary>
    </Modal>
  );
};
