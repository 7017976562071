import './TeamGantishMilestoneStats.less';
import { CheckIcon } from '../../../../../../../icons/overviewIcons/CheckIcon';
import { UpcomingEventIcon } from '../../../../../../../icons/overviewIcons/UpcomingEventIcon';
import { WarningIcon } from '../../../../../../../icons/performanceIcons/WarningIcon';
import { Colors } from '../../../../../../componentLibrary/Colors';
import {
  MilestoneStatus,
  TeamGantishMilestoneStats__MilestoneFragment,
} from '../../../../../../../generated/graphql';
import { gql } from '@apollo/client';
import { TeamGantishMilestoneStatsSkeleton } from './TeamGantishMilestoneStats.skeleton';
import { useMemo } from 'react';

interface Props {
  milestones: TeamGantishMilestoneStats__MilestoneFragment[];
}

export const TeamGantishMilestoneStats = ({ milestones }: Props) => {
  const completedCount = useMemo(
    () =>
      milestones.filter((m) => m.metadata.status === MilestoneStatus.COMPLETED)
        .length,
    [milestones]
  );

  const overdueCount = useMemo(
    () =>
      milestones.filter((m) => m.metadata.status === MilestoneStatus.OVERDUE)
        .length,
    [milestones]
  );

  const upcomingCount = useMemo(
    () =>
      milestones.filter((m) => m.metadata.status === MilestoneStatus.UPCOMING)
        .length,
    [milestones]
  );

  return (
    <div className="TeamGantishMilestoneStats">
      <div className="TeamGantishMilestoneStats__stat">{completedCount}</div>
      <div className="TeamGantishMilestoneStats__stat">{overdueCount}</div>
      <div className="TeamGantishMilestoneStats__stat">{upcomingCount}</div>
    </div>
  );
};

const Header = () => {
  return (
    <div className="TeamGantishMilestoneStats">
      <div className="TeamGantishMilestoneStats__stat">
        <CheckIcon style={{ fontSize: 15, color: Colors.Status.OK_GREEN }} />
      </div>
      <div className="TeamGantishMilestoneStats__stat">
        <WarningIcon
          style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
          className="space--r"
        />
      </div>
      <div className="TeamGantishMilestoneStats__stat">
        <UpcomingEventIcon
          style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
          className="space--r"
        />
      </div>
    </div>
  );
};

TeamGantishMilestoneStats.Header = Header;
TeamGantishMilestoneStats.Skeleton = TeamGantishMilestoneStatsSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_GANTISH_MILESTONE_STATS__MILESTONES = gql`
  fragment TeamGantishMilestoneStats__Milestone on Milestone {
    id
    domainId {
      itemId
      tenantId
    }
    deadlineAt
    name
    metadata {
      archived
      completedAt
      status
    }
  }
`;
