import moment, { Moment } from 'moment';
import { without } from './without';

export const convertDateStringsToMoments = <T extends {}, K extends keyof T>(
  object: T,
  ...keys: K[]
) => {
  const withoutDateStrings = without(object, ...keys);

  const moments = {} as Record<K, Moment>;

  for (const key of keys) {
    moments[key] = moment(object[key] as string);
  }

  return Object.assign(withoutDateStrings, moments);
};
