import './SkaSection.less';
import {
  GetCurrentSprintTermFullAccessDocument,
  GetSkaTimeStatusesDocument,
  SkaSectionDates_MitemTermFragment,
} from '../../../../../../../generated/graphql';
import {
  AllocationAndProgressCard,
  SprintStatus,
} from './components/allocationAndProgressCard/AllocationAndProgressCard';
import { SelectedSprint, SprintSelector } from './components/SprintSelector';
import { useState } from 'react';
import {
  checkIfActiveSprint,
  checkIfPlannedSprint,
} from './utils/sprintStatusCheck';
import { gql, useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { SkaOverview } from './components/SkaOverview';
import { SkaGraph } from './components/SkaGraph';
import { SprintProgressTable } from './components/SprintProgressTable';
import { getSelectedSprintKeyActivities } from './utils/getSelectedSprintKeyActivities';

interface Props {
  teamId: string;
  sprintTermDates: SkaSectionDates_MitemTermFragment;
}

export const SkaSection = ({ teamId, sprintTermDates }: Props) => {
  const { t } = useTranslation();
  const { data: sprintTermData } = useSuspenseQuery(
    GetCurrentSprintTermFullAccessDocument,
    { variables: { teamId } }
  );
  const [selectedSprint, setSelectedSprint] = useState<SelectedSprint | null>(
    null
  );
  const sprintTerm = sprintTermData?.currentMitemTerm;
  const dateRange = selectedSprint ?? sprintTermDates;

  const { data } = useSuspenseQuery(GetSkaTimeStatusesDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      teamId,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    },
  });

  const allSKAs = data?.skaTimeStatuses.sprintKaStatuses;

  const selectedSprintKeyActivities = getSelectedSprintKeyActivities(
    selectedSprint,
    true,
    allSKAs
  );

  const isPlannedSprint = checkIfPlannedSprint(selectedSprint);
  const isActiveSprint = checkIfActiveSprint(selectedSprint);
  const isFinalizedSprint = selectedSprint?.finalized === true;

  let status: SprintStatus = undefined;

  if (isPlannedSprint) {
    status = 'Planned';
  } else if (isActiveSprint) {
    status = 'Active';
  } else if (isFinalizedSprint) {
    status = 'Finalized';
  }

  return (
    <>
      <h2 className="mb--xl" data-intercom-target="SKA section header">
        {t('SkaSection.header')}
      </h2>
      <div className="flx flx--ai-center mb--xl">
        <h3 className="mr--l">{t('SkaSection.showData')}</h3>
        {sprintTerm && (
          <SprintSelector
            value={selectedSprint}
            onChange={setSelectedSprint}
            sprints={sprintTerm.sprints}
          />
        )}
      </div>
      <SkaOverview selectedSprint={selectedSprint} allSKAs={allSKAs ?? []} />

      <div className="SkaSection__progressAndActivities mt--xl mb--xl">
        {sprintTerm && (
          <SkaGraph
            teamId={teamId}
            selectedSprint={selectedSprint}
            sprintKeyActivities={selectedSprintKeyActivities}
            currentSprintTerm={sprintTerm}
            width={330}
          />
        )}

        <AllocationAndProgressCard
          sprintKeyActivities={selectedSprintKeyActivities}
          teamId={teamId}
          status={status}
        />
      </div>

      <SprintProgressTable
        teamId={teamId}
        sprintKeyActivities={selectedSprintKeyActivities}
        selectedSprint={selectedSprint}
      />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_CURRENT_SPRINT_TERM_FULL_ACCESS = gql`
  query getCurrentSprintTermFullAccess($teamId: ID!) {
    currentMitemTerm(teamId: $teamId) {
      id
      ...SkaSection_MitemTerm
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_SELECTOR_WRAPPER_FRAGMENT = gql`
  fragment SkaSection_MitemTerm on MitemTerm {
    id
    startDate
    endDate
    sprints {
      currentSprintIndex
      payload {
        teamId
        id
        startDate
        endDate
      }
      ...SprintSelector_MitemSprintResponse
    }
    ...SkaGraph_MitemTerm
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_SELECTOR_DATE_FRAGMENT = gql`
  fragment SkaSectionDates_MitemTerm on MitemTerm {
    id
    startDate
    endDate
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_SPRINT_FOR_SKA_SECTION = gql`
  fragment SkaSection_SkaTimeStatus on SkaTimeStatus {
    id
    status
    name
    deadline
    ...SkaGraphSkaTimeStatus
    ...AllocationAndProgressCardSkaTimeStatus
    ...SprintProgressTableSkaTimeStatus
    ...SprintProgressTableSkaTimeStatus
  }

  query getSkaTimeStatuses(
    $teamId: ID!
    $startDate: String!
    $endDate: String!
  ) {
    skaTimeStatuses(teamId: $teamId, startDate: $startDate, endDate: $endDate) {
      sprintKaStatuses {
        ...SkaSection_SkaTimeStatus
      }
    }
  }
`;
