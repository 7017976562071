import { gql, useQuery } from '@apollo/client';
import { GetTeamAccelerationDayDocument } from '../generated/graphql';

export const useAccelerationDay = (teamId: string, tenantId?: string) => {
  const { data, loading, error } = useQuery(GetTeamAccelerationDayDocument, {
    variables: { teamId, tenantId },
  });

  return {
    data: data?.team?.accelerationDay,
    loading,
    error,
  };
};

export const TEAM_ACCELERATION_DAY = gql`
  query getTeamAccelerationDay($teamId: ID!, $tenantId: ID) {
    team(teamId: $teamId, tenantId: $tenantId) {
      id
      accelerationDay
    }
  }
`;
