import { Col, Divider, Row, Spin, Tabs, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ErrorAlert } from '../../../../../../components/ErrorAlert';
import { MigRelations } from '../../../../../../components/mig/MigCard/MigDescription/MigRelations';
import './MigInspector.less';
import { MigDrawerCard } from './migInspector/MigDrawerCard';
import { useTeamMigV2 } from '../../../../../../components/setup/migSetupPage/hooks/useTeamMigV2';
interface Props {
  mig: Mig;
}

interface Mig {
  id: string;
  name: string;
  team: {
    id: string;
    name: string;
    teamHierarchy: { id: string; name: string }[];
  };
  domainId: {
    itemId: string;
    teamId: string;
  };
}

export const MigInspector = (props: Props) => {
  const { t } = useTranslation();
  const { teamMig, loading, error } = useTeamMigV2(
    props.mig.team.id,
    props.mig.domainId.itemId
  );
  const { TabPane } = Tabs;

  return (
    <div className="MigInspector">
      <Tabs defaultActiveKey="1" size="middle">
        <TabPane tab="Details" key="1">
          <Spin spinning={loading}>
            {teamMig && <MigDrawerCard mig={teamMig} showDescription={false} />}
          </Spin>
        </TabPane>

        <TabPane
          tab={
            <Tooltip
              title={
                teamMig?.supportsMigs?.length === 0 &&
                teamMig?.supportedByMigs?.length === 0
                  ? `${t('MigInspector.noSupport')}`
                  : null
              }
            >
              Alignment
            </Tooltip>
          }
          key="2"
          disabled={
            teamMig?.supportsMigs?.length === 0 &&
            teamMig?.supportedByMigs?.length === 0
          }
        >
          <Row>
            <Col span={6}>
              <Typography.Text strong>
                {t('MigDescription.supportTitle')}
              </Typography.Text>
            </Col>
            <Col span={18}>
              <MigRelations relations={teamMig?.supportsMigs ?? []} />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={6}>
              <Typography.Text strong>
                {t('MigDescription.supportedByTitle')}
              </Typography.Text>
            </Col>
            <Col span={18}>
              <MigRelations relations={teamMig?.supportedByMigs ?? []} />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
      {error && (
        <ErrorAlert error={error} title={t('MigRelationExplorer.fetchError')} />
      )}
    </div>
  );
};
