import { useMemo, useState } from 'react';
import { Alert, Drawer, Empty, Modal, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { MigRelationExplorer } from './components/migRelationsExplorer/MigRelationExplorer';
import { Helmet } from 'react-helmet';
import { MigListEntry } from './components/migRelationsExplorer/migRelationsDrawer/MigListEntry';
import { MigInspector } from './components/migRelationsExplorer/migRelationsDrawer/MigInspector';
import './components/AlignmentPage.less';
import { gql, useQuery } from '@apollo/client';
import {
  GetMigsMissingRelationsDocument,
  GetTeamsForMigAlignmentDocument,
  MigStatus,
} from '../../../generated/graphql';
import { useEffect } from 'react';
import { TeamSelector } from './components/TeamSelector';
import { useHistory, useLocation } from 'react-router-dom';
import { MigsMissingRelations } from './components/MigsMissingRelations';
import { TourLink } from '../../../components/TourLink';
import { envConf } from '../../../envConf';
import { DrawerTitle } from '../../../components/DrawerTitle';
import { isDefined } from '../../../services/isDefined';
import { LearnMoreLink } from '../../../components/LearnMoreLink';
import { Btn } from '../../../components/Button';
import { useTeamFullMigsLazyV2 } from '../../../components/setup/migSetupPage/hooks/useTeamMigV2';

const intercomProductTourId = {
  SV: 2308,
  EN: 2305,
};

interface MigSelection {
  id: string;
  name: string;
  team: {
    id: string;
    name: string;
    teamHierarchy: { id: string; name: string }[];
  };
  domainId: {
    itemId: string;
    teamId: string;
  };
}

interface Team {
  id: string;
  name: string;
  teamHierarchy: { id: string; name: string }[];
}

export const AlignmentPage = () => {
  const { t } = useTranslation();
  const { INTERCOM_KEY } = envConf;
  const queryParams = useQueryParams();
  const history = useHistory();

  const missingRelationsQuery = useQuery(GetMigsMissingRelationsDocument);

  useEffect(() => {
    if (INTERCOM_KEY)
      (window as any).Intercom('update', {
        last_seen_on_mig_alignment_page_at: Math.floor(Date.now() / 1000),
      });
  }, [INTERCOM_KEY]);

  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);

  const { data, loading: teamsLoading } = useQuery(
    GetTeamsForMigAlignmentDocument,
    {
      onCompleted: (data) => {
        const teamId = queryParams.get('teamId');

        const team = data.teams.find((t) => {
          if (teamId) return t.id === teamId;

          if (t.umbrellaTeam) return false;

          return t.parentTeamId === null;
        });

        if (team) setSelectedTeam(team);
      },
    }
  );

  const [selectedMig, setSelectedMig] = useState<MigSelection | null>(null);
  const [inspectedMig, setInspectedMig] = useState<MigSelection | null>(null);
  const [showMissingRelations, setShowMissingRelations] = useState(false);

  const [fetchMigs, { teamMigs, loading: teamMigsLoading }] =
    useTeamFullMigsLazyV2();

  useEffect(() => {
    if (selectedTeam) {
      fetchMigs({
        variables: {
          teamId: selectedTeam.id,
          statusFilters: [MigStatus.ACTIVE],
        },
      });
      setSelectedMig(null);
    }
  }, [fetchMigs, selectedTeam]);

  const handleTeamSelect = (team: Team) => {
    history.replace({ search: `teamId=${team.id}` });
    setSelectedTeam(team);
  };

  const migsMissingRelations =
    missingRelationsQuery.data?.migsV2MissingRelations.migs.filter(
      (m) => m.team.parentTeamId != null
    );

  const noActiveMigs = teamMigs?.length === 0;

  const totalRelatedMigs = teamMigs
    ?.flatMap((m) => m.supportedByMigs?.length)
    .reduce((acc = 0, supportedBy = 0) => acc + supportedBy, 0);

  const hasRelatedMigs = isDefined(totalRelatedMigs) && totalRelatedMigs > 0;

  const pageLoading = teamsLoading || teamMigsLoading;

  return (
    <Spin spinning={pageLoading}>
      <div className="AlignmentPage content mt--l">
        <Helmet title={t('AlignmentPage.migRelations')} />
        <div className="AlignmentPage__headerArea">
          <Typography.Title
            level={5}
            className="mb--none mt--none mr"
            style={{ display: 'inline-block' }}
          >
            {t('AlignmentPage.shortDescription')}
          </Typography.Title>
          <LearnMoreLink urlTemplate="https://help.howwe.io/{{locale}}/articles/28904-alignment?utm_source=app.howwe.io&utm_medium=web&utm_campaign=mig_alignment_header" />
          <TourLink
            intercomTarget="Alignment product tour button"
            engTourId={intercomProductTourId.EN}
            sweTourId={intercomProductTourId.SV}
          />
          <div className="flx flx--ai-flx-end">
            <div className="flx flx--column mt">
              <label className="font-size--sm">
                {t('AlignmentPage.teamSelectLabel')}
              </label>
              <TeamSelector
                teams={data?.teams ?? []}
                value={selectedTeam}
                onChange={handleTeamSelect}
              />
            </div>
            <div>
              <Spin spinning={missingRelationsQuery.loading && !pageLoading}>
                <Btn
                  type="link"
                  onClick={() => setShowMissingRelations((current) => !current)}
                >
                  {t('AlignmentPage.migsMissingButton', {
                    count: migsMissingRelations?.length,
                  })}
                </Btn>
              </Spin>
            </div>
          </div>
        </div>

        <Typography.Title level={5} type="secondary">
          {t('AlignmentPage.teamMigs', { name: selectedTeam?.name })}
        </Typography.Title>
        <div className="AlignmentPage__columnArea">
          <div
            className="AlignmentPage__topMigs"
            data-intercom-target="Team MIGs"
          >
            {noActiveMigs && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                className="mt--xxl"
                description={t('TeamPerformanceMigCard.noInProgressMig')}
              />
            )}
            {teamMigs?.map((mig) => (
              <MigListEntry
                key={mig.id}
                mig={mig}
                isExpanded={mig.id === selectedMig?.id}
                faded={mig.id !== selectedMig?.id && !!selectedMig}
                onExpandRelations={() => {
                  if (mig.id === selectedMig?.id) {
                    setSelectedMig(null);
                  } else {
                    setSelectedMig(mig);
                  }
                }}
                onInspectChildMig={setInspectedMig}
              />
            ))}
          </div>
          <MigRelationExplorer
            mig={selectedMig}
            inspectedMig={inspectedMig}
            setInspectedMig={setInspectedMig}
          />

          {!selectedMig && !noActiveMigs && hasRelatedMigs && (
            <div>
              <Alert
                className="ml--s"
                type="info"
                message={t('AlignmentPage.selectAMig')}
              ></Alert>
            </div>
          )}
        </div>
        <Drawer
          title={
            <DrawerTitle>{t('AlignmentPage.detailsModalTitle')}</DrawerTitle>
          }
          placement="right"
          closable={true}
          width={820}
          onClose={() => setInspectedMig(null)}
          open={!!inspectedMig}
          destroyOnClose
        >
          {inspectedMig && <MigInspector mig={inspectedMig} />}
        </Drawer>
        <Modal
          title={t('AlignmentPage.missingRelations')}
          open={showMissingRelations}
          onCancel={() => setShowMissingRelations(false)}
          footer={null}
          width={800}
        >
          <MigsMissingRelations
            migsMissingRelations={migsMissingRelations ?? []}
          />
        </Modal>
      </div>
    </Spin>
  );
};

function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

// this is used indirectly - `GetTeamsForMigAlignmentDocument` is generated from it
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAMS = gql`
  query getTeamsForMigAlignment {
    teams(teamFilter: { active: true }) {
      name
      id
      created
      umbrellaTeam
      parentTeamId
      teamHierarchy {
        id
        name
      }
    }
    tenant {
      id
      name
    }
  }
`;

// this is used indirectly - `GetMigsMissingRelationsDocument` is generated from it
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MIGS_MISSING_RELATIONS = gql`
  query getMigsMissingRelations {
    migsV2MissingRelations {
      migs {
        id
        domainId {
          itemId
          teamId
        }
        name
        team {
          id
          name
          parentTeamId
        }
      }
    }
  }
`;
