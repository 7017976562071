import Icon from '@ant-design/icons';

const BoltOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.66667 13.6667L5.33333 8.66671L0 8.00004L8 0.333374H9.33333L6.66667 5.33337L12 6.00004L4 13.6667H2.66667ZM6.36667 9.55004L9.05 6.98337L4.56667 6.41671L5.61667 4.46671L2.95 7.03337L7.41667 7.58337L6.36667 9.55004Z" />
  </svg>
);

export const BoltOutlineIcon = (props: any) => (
  <Icon component={BoltOutlineIconSvg} {...props} />
);
