import { Typography } from 'antd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { MigOutOfDateWarning } from '../MigOutOfDateWarning';
import { Link } from 'react-router-dom';
import { gql } from '@apollo/client';
import { MigCardHeaderMigFragment } from '../../../generated/graphql';

interface Props {
  mig: MigCardHeaderMigFragment;
  truncateName?: boolean;
  action?: React.ReactNode;
}

export const MIG_CARD_HEADER_FRAGMENT = gql`
  fragment MigCardHeaderMig on MigV2 {
    id
    name
    lastOutcomeDate
    mirroredFromTeam(teamId: $teamId) {
      id
      name
    }
    ...MigOutOfDateWarningMig
  }
`;

export const MigCardHeader = ({ mig, truncateName, action }: Props) => {
  const { t } = useTranslation();

  const dueByText = t('MigCard.dateDueBy', {
    date: mig.lastOutcomeDate,
  });

  return (
    <div className="MigCard__header">
      <MigOutOfDateWarning mig={mig} iconClassName="MigCard__warningIcon" />
      <div className="MigCard__headerGroup">
        <div
          className={cx('MigCard__title', { ellipsis: truncateName })}
          title={mig.name ?? undefined}
        >
          {mig.name}
        </div>
        {mig.mirroredFromTeam ? (
          <div className="font-size--sm">
            {t(`common.mig.managedBy`)}
            <Link to={`/team/${mig.mirroredFromTeam.id}`}>
              {mig.mirroredFromTeam.name}
            </Link>
          </div>
        ) : null}

        <Typography.Text type="secondary" className="ellipsis font-size--sm">
          {dueByText}
        </Typography.Text>
      </div>
      {action}
    </div>
  );
};
