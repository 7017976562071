import { SkaSection } from './components/skaSection/SkaSection';
import { useTeamPermissions } from '../../../../../usePermissions';
import { SimpleSkaSection } from './components/SimpleSkaSection';
import { WkaSection } from './components/wkaSection/WkaSection';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  Action,
  GetCurrentSprintTermDocument,
} from '../../../../../generated/graphql';
import { Divider } from 'antd';
import { KeyActivitySectionSkeleton } from './KeyActivitySection.skeleton';

interface Props {
  teamId: string;
}

export const KeyActivitySection = ({ teamId }: Props) => {
  const { data: sprintTermData } = useSuspenseQuery(
    GetCurrentSprintTermDocument,
    { variables: { teamId } }
  );

  const { isAllowed: isAllowedToViewSprint, loading: loadingPermissions } =
    useTeamPermissions({
      requestedAction: [
        {
          resource: 'sprintKA',
          action: [Action.READ],
        },
      ],
      teamId: teamId,
    });

  const sprintTerm = sprintTermData?.currentMitemTerm;

  const showSkaSection =
    sprintTerm && isAllowedToViewSprint && !loadingPermissions;

  const showSimpleSkaSection =
    sprintTerm && !isAllowedToViewSprint && !loadingPermissions;

  return (
    <>
      {showSkaSection && (
        <>
          <SkaSection teamId={teamId} sprintTermDates={sprintTerm} />
          <Divider />
        </>
      )}

      {showSimpleSkaSection && (
        <>
          <SimpleSkaSection teamId={teamId} sprintTerm={sprintTerm} />
          <Divider />
        </>
      )}

      <WkaSection teamId={teamId} />
    </>
  );
};
KeyActivitySection.Skeleton = KeyActivitySectionSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_CURRENT_SPRINT_TERM = gql`
  query getCurrentSprintTerm($teamId: ID!) {
    currentMitemTerm(teamId: $teamId) {
      id
      ...SkaSectionDates_MitemTerm
      ...SimpleSkaSection_MitemTerm
    }
  }
`;
