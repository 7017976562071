import {
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Popconfirm, Spin, Tabs, TabsProps, Tooltip } from 'antd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Action,
  GetSkaDetailsForModalDocument,
  MitemStatus,
  SkaDetails_MitemFragment,
} from '../../../../../../generated/graphql';
import { ArchiveOutlinedIcon } from '../../../../../../icons/ArchiveIcon';
import { showNotification } from '../../../../../../services/fetchNotificationProperties';
import { Sprint } from '../../../../common/hooks/useSprintPlanningData';
import { MitemHistory } from '../../../common/components/MitemHistory';
import {
  isPossibleToChangeArchiveState,
  useMitemArchivation,
} from '../../../common/services/useMitemArchivation';
import './SkaDetails.less';
import { Btn } from '../../../../../../components/Button';
import { gql, useQuery } from '@apollo/client';
import { useTeamPermissions } from '../../../../../../usePermissions';
import { SkaDetailsContent } from './skaDetails/SkaDetailsContent';
import { StatusTag } from '../../../../../../components/StatusTag';

type SelectedSka =
  | { id: string; team: { id: string } }
  | { id: string; teamId: string };

interface EditableProps {
  selectedSka: SelectedSka;
  sprint: Sprint;
  onClose: () => void;
  onOpenEditForm: (ska: SkaDetails_MitemFragment, sprint: Sprint) => void;
  onOpenCopyForm: () => void;
  editable: true;
}

interface NonEditableProps {
  selectedSka: SelectedSka;
  onClose: () => void;
  editable: false;
}

type Props = EditableProps | NonEditableProps;

export const SkaDetails = (props: Props) => {
  const { selectedSka, onClose } = props;
  const { t } = useTranslation();

  const teamId =
    'teamId' in selectedSka ? selectedSka.teamId : selectedSka.team.id;

  const { data, loading: loadingSkaData } = useQuery(
    GetSkaDetailsForModalDocument,
    {
      variables: {
        teamId,
        mitemId: selectedSka.id,
      },
    }
  );

  const fullSKa = data?.mitem;

  const { isAllowed, loading } = useTeamPermissions({
    teamId,
    requestedAction: {
      action: Action.READ,
      resource: 'sprintKA',
    },
  });

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('SkaDetails.detailsTab'),
      children: <SkaDetailsContent fullSKa={fullSKa} />,
    },
    {
      key: '2',
      label: t('SkaDetails.history'),
      disabled: loading || !isAllowed,
      children: (
        <div className="SkaDetails__tabContent pl--xs">
          <MitemHistory teamId={teamId} mitemId={selectedSka.id} />
        </div>
      ),
    },
  ];

  return (
    <div className={cx('SkaDetails', `SkaDetails--${fullSKa?.status}`)}>
      <Spin spinning={loadingSkaData}>
        <div className="SkaDetails__header">
          <div className="SkaDetails__statusTagWrapper">
            {fullSKa?.status && <StatusTag status={fullSKa?.status} />}
          </div>
          {props.editable && fullSKa && <SkaActions {...props} ska={fullSKa} />}

          <Btn
            shape="circle"
            type="text"
            onClick={onClose}
            className="SkaDetails__closeButton"
            icon={<CloseOutlined />}
          />
        </div>

        <Tabs size="small" tabBarStyle={{ fontSize: 12 }} items={items} />
      </Spin>
    </div>
  );
};

type MitemActionsProps = Omit<EditableProps, 'selectedSka'> & {
  ska: SkaDetails_MitemFragment;
};

const SkaActions = ({
  ska,
  sprint,
  onClose,
  onOpenEditForm,
  onOpenCopyForm,
  editable,
}: MitemActionsProps) => {
  const { t } = useTranslation();

  const [archiveMitem, { loading }] = useMitemArchivation({
    onCompleted() {
      showNotification('success', {
        message: t('MitemListEntry.archiveSuccess'),
      });
      onClose();
    },
    onError() {
      showNotification('error', {
        message: t('MitemListEntry.archiveError'),
      });
    },
    update(cache) {
      cache.modify({
        fields: {
          sprintKeyActivities(existingMitems, { readField }) {
            const newPayload =
              existingMitems?.sprintKeyActivities?.filter((mitemRef: any) => {
                return readField('id', mitemRef) !== ska.id;
              }) ?? [];

            return { ...existingMitems, sprintKeyActivities: newPayload };
          },
        },
      });
    },
  });

  const archive = editable && {
    icon: sprint.locked ? <ArchiveOutlinedIcon /> : <DeleteOutlined />,
    tooltipTitle: sprint.locked
      ? t('MitemListEntry.archiveMitem')
      : t('MitemListEntry.deleteMitem'),
    confirmTitle: sprint.locked
      ? t('MitemListEntry.confirmArchive.title.archive')
      : t('MitemListEntry.confirmArchive.title.remove'),
  };

  return (
    <>
      <Tooltip
        placement="top"
        title={t('MitemListEntry.copySka')}
        mouseEnterDelay={0.4}
      >
        <Btn
          className="txt--secondary"
          type="text"
          onClick={(e) => {
            e.stopPropagation();
            onOpenCopyForm();
          }}
          icon={<CopyOutlined />}
        />
      </Tooltip>
      {isPossibleToChangeArchiveState(ska, sprint) && (
        <Popconfirm
          title={archive.confirmTitle}
          onConfirm={() => archiveMitem(ska.teamId, ska.id)}
          okText={t('MitemListEntry.confirmArchive.confirm')}
          cancelText={t('MitemListEntry.confirmArchive.cancel')}
          disabled={loading}
        >
          <Tooltip
            placement="top"
            title={archive.tooltipTitle}
            mouseEnterDelay={0.7}
          >
            <Btn
              className="txt--secondary"
              shape="circle"
              type="text"
              icon={archive.icon}
            />
          </Tooltip>
        </Popconfirm>
      )}

      {ska.status !== MitemStatus.ARCHIVED && (
        <Tooltip
          placement="top"
          title={t('MitemListEntry.editMitem')}
          mouseEnterDelay={0.7}
        >
          <Btn
            className="txt--secondary"
            shape="circle"
            type="text"
            onClick={() => onOpenEditForm(ska, sprint)}
            icon={<EditOutlined />}
          />
        </Tooltip>
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SkaDetailsFragment = gql`
  fragment SkaDetails_Mitem on Mitem {
    id
    teamId
    status
    completed
    archived
    ...SkaDetailsContent_Mitem
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MITEMS_DETAILS_DETAILS = gql`
  query getSkaDetailsForModal($teamId: ID!, $mitemId: ID!) {
    mitem(teamId: $teamId, mitemId: $mitemId) {
      ...SkaDetails_Mitem
    }
  }
`;
