import { Checkbox, Input, Tooltip } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccelerationMeeting } from '../../../../overview/components/accelerationMeetingModal/AccelerationMeetingProvider';

interface Props {
  sprintKaId: string;
  ownerId: string;
}

export const CommitColumn = ({ sprintKaId, ownerId }: Props) => {
  const { sprintKeyActivityContext } = useAccelerationMeeting();

  const { t } = useTranslation();
  const [isDirty, setIsDirty] = useState(false);

  const usersCommitments =
    sprintKeyActivityContext.getUsersCommitments(sprintKaId);
  const ownerCommitment = usersCommitments?.commitment;

  return (
    <div
      className="flx flx--ai-center"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Tooltip title={t('CommitColumn.checkBoxTooltip')} mouseEnterDelay={0.7}>
        <Checkbox
          className="mr"
          checked={ownerCommitment?.committed}
          onChange={(e) => {
            sprintKeyActivityContext.setUserCommitment(sprintKaId, {
              committed: e.target.checked,
              userId: ownerId,
            });
          }}
        />
      </Tooltip>

      <Input.TextArea
        autoComplete="off"
        autoSize={{ minRows: 1, maxRows: 5 }}
        placeholder={t('CommitColumn.commitmentNote')}
        style={{ minWidth: 220 }}
        defaultValue={ownerCommitment?.note}
        onChange={(e) => {
          if (!isDirty) {
            setIsDirty(true);
            sprintKeyActivityContext.setUserCommitment(sprintKaId, {
              userId: ownerId,
              committed: true,
              note: e.target.value,
            });
          }
        }}
        onBlur={(e) => {
          sprintKeyActivityContext.setUserCommitment(sprintKaId, {
            ...ownerCommitment,
            note: e.target.value,
            userId: ownerId,
          });
        }}
        maxLength={140}
        showCount={false}
      />
    </div>
  );
};
