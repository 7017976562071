import { useTranslation } from 'react-i18next';
import './MitemMoveMessage.less';
import { WarningFilled } from '@ant-design/icons';
import { Btn } from '../../../../../../../../components/Button';

type Props = {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
};

export const MitemMoveMessage = ({ title, message, ...props }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="MitemMoveMessage">
      <div>
        <div className="MitemMoveMessage__title">
          {title}
          <span className="MitemMoveMessage__icon">
            <WarningFilled />
          </span>
        </div>
        <div className="MitemMoveMessage__text">{message}</div>
      </div>
      <div className="flx">
        <Btn
          size="small"
          type="link"
          onClick={props.onConfirm}
          className="MitemMoveMessage__button ml--auto"
        >
          {t('common.confirm')}
        </Btn>
        <Btn
          size="small"
          type="link"
          onClick={props.onCancel}
          className="MitemMoveMessage__button"
        >
          {t('common.cancel')}
        </Btn>
      </div>
    </div>
  );
};
