import { Typography } from 'antd';
import dayjs from 'dayjs';
import { friendlyDate } from '../../../../../services/dateFormats';
import { DisplayName } from '../../../../DisplayName';
import './AkpiAchievement.less';

interface Props {
  user: {
    displayName?: string | null;
    name?: string | null;
    email: string;
  };
  quantity: number;
  unit?: string | null;
  created: string;
  description?: string | null;
}

export function AkpiAchievement({
  user,
  quantity,
  unit,
  created,
  description,
}: Props) {
  return (
    <div className="AkpiAchievement__container">
      <div className="flx flx--jc-space-between">
        <div>
          <span className="bold mr--s">
            <DisplayName user={user} />
          </span>

          <span className="AkpiAchievement__quantity">
            {quantity} {unit}
          </span>
        </div>

        <Typography.Text type="secondary">
          <span title={friendlyDate(created)}>{dayjs(created).fromNow()}</span>
        </Typography.Text>
      </div>
      <Typography.Paragraph
        className="AkpiAchievement__description"
        ellipsis={{
          rows: 3,
          expandable: true,
        }}
      >
        {description}
      </Typography.Paragraph>
    </div>
  );
}
