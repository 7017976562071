import { useSuspenseQuery } from '@apollo/client';
import { Gantish } from '../Gantish/Gantish';
import {
  GetTenantBusinessPlanInitiativeDocument,
  GetTenantInitiativeForReportDocument,
} from '../../../generated/graphql';
import { GantishPopoverContentSkeleton } from './GantishPopoverContent.skeleton';
import { initiativeDetailsTreeBuilder } from '../Gantish/services/initiativeDetailsTreeBuilder';

interface Props {
  initiativeDomainId: {
    itemId: string;
    tenantId: string;
  };
}

export const GantishPopoverContent = ({ initiativeDomainId }: Props) => {
  // Uses the full report query, same as used on the detailed page. This way we don't need to load additional data when navigating to the detailed page.
  const { data } = useSuspenseQuery(GetTenantBusinessPlanInitiativeDocument, {
    variables: { initiativeId: initiativeDomainId.itemId },
  });

  const { data: initiativeData } = useSuspenseQuery(
    GetTenantInitiativeForReportDocument,
    {
      variables: { initiativeId: initiativeDomainId.itemId },
    }
  );

  const initiativeReportNode = initiativeDetailsTreeBuilder(
    data.initiativeDetailedReport,
    initiativeData.tenantInitiative2
  );

  return <Gantish initiativeReportNode={initiativeReportNode} />;
};

GantishPopoverContent.Skeleton = GantishPopoverContentSkeleton;
