export const HappyBlobs = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 280">
      <path
        d="M152.662 53.589S133.913 95.67 84.331 86.03c-49.583-9.64-79.456 58.182-32.58 82.912 46.876 24.729 14.39 53.137 45.653 67.648 35.533 16.492 48.783-38.655 74.96-33.533 57.774 11.304 93.512-8.083 77.71-34.173-27.739-45.798-47.88-44.112-39.695-73.687 8.185-29.575-38.039-74.977-57.717-41.608Z"
        fill="#DEE9FF"
      />
      <path
        d="M143.612 149.634c-4.357 8.349-5.456 12.786-18.671 21.071a.538.538 0 0 0-.218.283 45.321 45.321 0 0 1-10.451 17.483.509.509 0 0 0-.121.509c1.479 4.922 3.08 13.797-1.375 21.072-6.547 10.71-11.396 4.243-10.386 1.818.865-2.077 8.253-6.539 6.547-17.685a.518.518 0 0 0-.321-.403.528.528 0 0 0-.511.064 45.011 45.011 0 0 1-18 7.784.525.525 0 0 0-.354.235.51.51 0 0 0-.059.42c1.657 5.932 2.441 15.204-4.477 23.731-3.694 4.445-8.592-2.054-3.694-6.482 3.039-2.741 2.804-10.621 1.382-16.691a.524.524 0 0 0-.526-.404c-.282.008-.574.008-.856.008a45.15 45.15 0 0 1-27.635-9.393.523.523 0 0 0-.519-.076.525.525 0 0 0-.33.407c-1.9 12.544 5.642 16.885 6.717 20.102 1.196 3.597-1.552 6.797-4.938 5.892a.595.595 0 0 1-.203-.105c-11.04-8.293-10.079-25.671-8.866-34.036a.485.485 0 0 0-.105-.388 45.143 45.143 0 0 1-9.498-27.788c0-18.453 11.017-34.336 26.835-41.424l.003-.001.003-.002.002-.002v-.003c2.433-3.08 5.4-7.436 5.852-10.492a.524.524 0 0 1 .394-.435.525.525 0 0 1 .552.201c1.164 1.568 2.966 4.559 2.57 7.711-.016.105-.024.21-.04.315-.033.178-.073.348-.114.525-.04.138-.072.275-.12.413a20.492 20.492 0 0 0 1.615-1.504c1.188-1.22 2.692-3.055 3.338-5.019a.522.522 0 0 1 .477-.379.528.528 0 0 1 .517.323c.42 1.035.913 2.481 1.083 3.96a.539.539 0 0 0 .542.477c.622-.024 1.244-.04 1.867-.04 25.064 0 45.384 20.32 45.384 45.376 0 .574-.008 1.14-.032 1.705a.529.529 0 0 0 .448.548.538.538 0 0 0 .255-.022c2.7-.946 6.102-3.225 8.155-8.649 3.84-10.103 4.244-12.932 3.032-20.813-1.213-7.88 4.648-7.476 5.859-4.849 1.212 2.626 3.84 15.356-1.009 24.652Z"
        fill="#0E84E5"
      />
      <path
        d="M63.794 161.042a.696.696 0 0 1-.676-.522 11.512 11.512 0 0 1-.194-4.06c.398-2.943 2-5.106 4.632-6.256 1.72-.751 3.346-.795 4.833-.128 3.047 1.365 4.344 5.237 4.398 5.401a.701.701 0 0 1-1.33.436c-.012-.034-1.165-3.454-3.645-4.562-1.11-.496-2.353-.45-3.695.136-2.178.952-3.456 2.674-3.799 5.119a10.252 10.252 0 0 0 .155 3.56.702.702 0 0 1-.679.876ZM84.896 154.632a.698.698 0 0 1-.663-.478 11.411 11.411 0 0 1-.457-4.022c.207-2.951 1.665-5.205 4.217-6.518 1.671-.86 3.293-1.008 4.822-.438 3.122 1.162 4.665 4.917 4.73 5.076a.701.701 0 0 1-.923.901.7.7 0 0 1-.376-.378c-.014-.032-1.383-3.347-3.924-4.289-1.143-.424-2.384-.298-3.688.373-2.111 1.086-3.275 2.879-3.458 5.329a10.16 10.16 0 0 0 .385 3.523.703.703 0 0 1-.665.921ZM83.115 169.276c-4.765 0-8.397-3.171-8.58-3.332a.705.705 0 0 1-.233-.482.7.7 0 0 1 1.163-.564c.048.042 4.845 4.211 10.246 2.601 5.377-1.602 6.85-8.428 6.865-8.496a.7.7 0 0 1 1.371.282 16.228 16.228 0 0 1-1.55 4.052c-1.536 2.833-3.71 4.736-6.285 5.504-.973.29-1.983.437-2.997.435Z"
        fill="#fff"
      />
      <path
        d="M234.01 138.119a36.71 36.71 0 0 1-.463 4.536c-3.375 20.644-22.836 34.637-43.48 31.262-20.636-3.374-34.637-22.837-31.262-43.481.215-1.316.499-2.604.836-3.86-15.311-10.398-14.417-30.448-11.482-32.671 2.964-2.25 6.201-.354 5.862 5.872-.347 9.042 5.22 16.52 8.307 19.777 7.229-14.412 23.206-23.118 39.957-20.38 16.257 2.657 28.384 15.295 31.162 30.622 3.03-1.102 8.381-4.281 12.717-13.318.606-1.801.773-3.125.805-3.923a3.086 3.086 0 0 1 .819-2.004c.948-.991 2.627-2.044 4.796-.428a3.86 3.86 0 0 1 1.543 3.185c-.136 4.884-4.044 16.767-20.117 24.811Z"
        fill="#699CFF"
      />
      <path
        d="M211.489 144.815c-.956 5.847-8.997 9.397-17.955 7.933-8.966-1.466-15.458-7.392-14.503-13.239a7.339 7.339 0 0 1 1.245-3.024 1.168 1.168 0 0 1 1.582-.308c3.21 2.01 8.29 3.782 14.151 4.74 5.394.882 10.386.895 14.018.206a1.172 1.172 0 0 1 1.361.875c.219.923.253 1.88.101 2.817ZM194.491 133.214a.696.696 0 0 1-.534-.247.69.69 0 0 1-.158-.566 5.489 5.489 0 0 0-8.625-5.345 5.495 5.495 0 0 0-2.214 3.573.694.694 0 0 1-.282.455.704.704 0 0 1-.977-.159.703.703 0 0 1-.122-.522 6.892 6.892 0 1 1 13.602 2.223.705.705 0 0 1-.237.421.707.707 0 0 1-.453.167ZM211.283 135.959a.708.708 0 0 1-.534-.247.707.707 0 0 1-.158-.566 5.489 5.489 0 0 0-4.536-6.286 5.494 5.494 0 0 0-6.302 4.514.691.691 0 0 1-.096.256.686.686 0 0 1-.435.315.702.702 0 0 1-.851-.797 6.896 6.896 0 0 1 2.783-4.469 6.895 6.895 0 0 1 9.603 1.57 6.886 6.886 0 0 1 1.216 5.122.705.705 0 0 1-.237.421.71.71 0 0 1-.453.167Z"
        fill="#fff"
      />
    </svg>
  );
};
