import dayjs, { Dayjs } from 'dayjs';
import moment, { Moment } from 'moment';
import { friendlyDate } from '../services/dateFormats';
import { momentToDayjs } from '../services/dateHelpers';

interface DisplayDateProps {
  date: string | Moment | Dayjs | null | undefined;
  className?: string;
  emptyState?: React.ReactNode;
  alwaysIncludeYear?: boolean;
}

export const DisplayDate = ({
  date,
  className,
  emptyState,
  alwaysIncludeYear = false,
}: DisplayDateProps) => {
  if (!date) return <span>{emptyState}</span>;

  const dayjsDate = moment.isMoment(date) ? momentToDayjs(date) : dayjs(date);

  return (
    <span
      className={className}
      title={friendlyDate(dayjsDate, { alwaysIncludeYear: true })}
    >
      {friendlyDate(dayjsDate, { alwaysIncludeYear })}
    </span>
  );
};
