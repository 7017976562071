import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

export const MilestoneDetailsError = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Alert
        message={t('MilestoneDetailsError.title')}
        description={t('MilestoneDetailsError.description')}
        type="error"
        showIcon={false}
      />
    </div>
  );
};
