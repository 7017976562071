import React, { ReactNode } from 'react';
import { RenderedMarkdown } from '../../../RenderedMarkdown';
import './AkpiDescription.less';

interface Props {
  akpi: {
    name: ReactNode;
    descriptionHtml?: string | null;
  };
}

export const AkpiDescription = ({ akpi }: Props) => {
  return (
    <div className="AkpiDescription">
      <div className="AkpiDescription__content">
        <div className="AkpiDescription__name bg--white">{akpi.name}</div>
        <div className="AkpiDescription__description pa--s">
          <RenderedMarkdown html={akpi.descriptionHtml} emptyPlaceholder="-" />
        </div>
      </div>
    </div>
  );
};
