import { GantishChart } from './components/GantishChart';

import './Gantish.less';
import { GantishLegend } from './components/GantishLegend';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import { GantishSkeleton } from './Gantish.skeleton';
import { InitiativeDetailsTreeNode } from '../../../appPages/company/initiatives/initiativeDetails/InitiativeDetails';

interface Props {
  initiativeReportNode: InitiativeDetailsTreeNode;
}

export const Gantish = ({ initiativeReportNode }: Props) => {
  const { t } = useTranslation();

  const [expandedInitiatives, setExpandedInitiatives] = useState<string[]>([]);

  const handleToggle = (id: string) => {
    if (expandedInitiatives.includes(id)) {
      setExpandedInitiatives(expandedInitiatives.filter((i) => i !== id));
    } else {
      setExpandedInitiatives([...expandedInitiatives, id]);
    }
  };

  return (
    <div className="Gantish">
      <h3>{t('Gantish.title')}</h3>
      <div className="flx">
        <GantishLegend
          initiativeNode={initiativeReportNode}
          expanded={expandedInitiatives}
          onToggleExpanded={handleToggle}
        />
        <div className="Gantish__chart">
          <GantishChart
            initiativeNode={initiativeReportNode}
            expanded={expandedInitiatives}
          />
        </div>
      </div>
    </div>
  );
};

Gantish.Skeleton = GantishSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GANTISH__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment Gantish__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    domainId {
      itemId
      tenantId
    }
    milestones {
      ...Gantish__MilestoneReport
    }
    stats {
      ...Gantish__InitiativeStats
    }
    allSubInitiatives {
      id
      domainId {
        itemId
        tenantId
      }
      initiative {
        ...Gantish__InitiativeLight
      }
      stats {
        ...Gantish__InitiativeStats
      }
      milestones {
        ...Gantish__MilestoneReport
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GANTISH__INITIATIVE_LIGHT = gql`
  fragment Gantish__InitiativeLight on InitiativeLight {
    ...legendSubInitiative__InitiativeLight
    ...chartSubinitiative__InitiativeLight
    startAt
    endAt
    name
    tag {
      title
      iconId
      colorCode
    }
    domainId {
      itemId
      tenantId
    }
    metadata {
      completedAt
      archived
      status
      supportsInitiatives {
        id
        domainId {
          itemId
          tenantId
        }
      }
      supportsMigs {
        id
        domainId {
          itemId
          tenantId
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GANTISH_INITIATIVE_STATS = gql`
  fragment Gantish__InitiativeStats on InitiativeStats {
    activities {
      completed
      overdue
      upcoming
    }
    milestones {
      completed
      overdue
      upcoming
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GANTISH_MILESTONE_FRAGMENT = gql`
  fragment Gantish__MilestoneReport on MilestoneReport {
    id
    domainId {
      itemId
      tenantId
    }
    milestone {
      id
      domainId {
        itemId
        tenantId
      }
      name
      deadlineAt
      metadata {
        completedAt
        status
      }
    }
  }
`;
