export const pick = <T extends {}, K extends keyof T>(
  obj: T,
  ...include: K[]
): Pick<T, K> => {
  const entries = Object.entries(obj).filter(([key]) =>
    include.includes(key as K)
  );

  return Object.fromEntries(entries) as any;
};
