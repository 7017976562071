import { gql, useLazyQuery } from '@apollo/client';
import { GetInitiativeDocument } from '../generated/graphql';

export const useInitiativeLazy = () => {
  return useLazyQuery(GetInitiativeDocument);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVE = gql`
  query getInitiative($teamId: ID!, $initiativeId: ID!) {
    initiative(teamId: $teamId, initiativeId: $initiativeId) {
      ...InitiativeContent
    }
  }
`;
