import { useEffect, useState } from 'react';
import { Btn } from '../../../../../../components/Button';
import { Modal } from 'antd';
import { Step1 } from './Step1';
import { MilestoneSuggestion, Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';
import {
  ConfigStep,
  WizardNavigationProvider,
} from '../../../../../../components/WizardNavigationProvider';
import { AiWizard } from './AiWizard';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  AiSuggestButtonQueryDocument,
  AiWizardButtonActivitySuggestionsDocument,
  SprintKaInput,
} from '../../../../../../generated/graphql';
import { toMap } from '../../../../../../services/toMap';
import { without } from '../../../../../../services/without';
import { SparklesIcon } from '../../../../../../icons/SparklesIcon';

interface Props {
  teamId: string;
}

interface RequiredMetaTags {
  industry: string;
  location: string;
  teamType: string;
  goals: string;
  initiatives: string;
}

export interface SkaDraft extends SprintKaInput {
  id: string;
}
export const AiSuggestButton = (props: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInitiativeId, setSelectedInitiative] = useState<string | null>(
    null
  );
  const [selectedMilestoneSuggestion, setSelectedMilestoneSuggestion] =
    useState<MilestoneSuggestion | null>(null);

  const [selectedActivitySuggestions, setSelectedActivitySuggestions] =
    useState<string[]>([]);

  const [getActivities, activitiesQuery] = useLazyQuery(
    AiWizardButtonActivitySuggestionsDocument
  );

  const baseInfo = useQuery(AiSuggestButtonQueryDocument, {
    variables: { teamId: props.teamId },
  });

  const initiatives =
    baseInfo?.data?.joinedTenantInitiativesForTeam.initiatives ?? [];

  const selectedInitiative = initiatives.find(
    (init) => init.id === selectedInitiativeId
  );

  const tenantMetaTags = baseInfo.data?.tenantMetaTags.tenantMetaTags;

  const metaTags = stringifyMetaTags<RequiredMetaTags>({
    industry: tenantMetaTags?.filter((t) => t.type === 'industry'),
    location: tenantMetaTags?.filter((t) => t.type === 'location'),
    teamType: baseInfo.data?.team?.metaTags,
    goals: baseInfo.data?.team?.teamMigs.flatMap((mig) => mig.metaTags),
    initiatives:
      baseInfo.data?.joinedTenantInitiativesForTeam.initiatives.flatMap(
        (i) => i.metaTags
      ),
  });

  useEffect(() => {
    if (activitiesQuery.data) {
      setSkaDrafts(
        activitiesQuery.data.activitySuggestions.activities.map((a) => ({
          id: a.id,
          name: a.headline,
          definitionOfDone: a.definitionOfDone,
        }))
      );
      setSelectedActivitySuggestions(
        activitiesQuery.data.activitySuggestions.activities.map((a) => a.id)
      );
    }
  }, [activitiesQuery.data]);

  const [_skaDrafts, setSkaDrafts] = useState<SkaDraft[]>([]);

  const skaDrafts = selectedInitiativeId
    ? _skaDrafts.map((draft) => ({
        ...draft,
        supportsInitiativeIds: [selectedInitiativeId],
      }))
    : _skaDrafts;

  const handleEdit = (draftId: string, field: Partial<SkaDraft>) => {
    setSkaDrafts((drafts) =>
      drafts.map((draft) =>
        draft.id === draftId ? { ...draft, ...field } : draft
      )
    );
  };

  const handlesave = () => {
    setModalOpen(false);
  };

  const goToStep3 = () => {
    const selectedInitiativeTags = tagsToString(selectedInitiative?.metaTags);
    if (selectedMilestoneSuggestion && selectedInitiativeTags) {
      getActivities({
        variables: {
          activityInput: {
            ...without(metaTags, 'initiatives'),
            selectedInitiative: selectedInitiativeTags,
            selectedImprovementArea: selectedMilestoneSuggestion.area,
          },
        },
      });
    }
  };

  const selectedSkaDrafts = skaDrafts.filter((draft) =>
    selectedActivitySuggestions.includes(draft.id)
  );

  const teamMembers = baseInfo.data?.membersV2.members.map((m) => m.user) ?? [];

  const memberLookup = toMap(teamMembers, (m) => m.id);

  const tagLookup = toMap(baseInfo.data?.teamTags.tags ?? [], (t) => t.id);

  const wizardConfig: ConfigStep[] = [
    {
      title: 'Select Initiative',
      type: 'ConfigComponentStep',
      key: 'SELECT_INITIATIVE',
      isMandatory: true,
      component: () => (
        <Step1
          initiatives={initiatives}
          selectedInitiative={selectedInitiativeId}
          onSelectInitiative={setSelectedInitiative}
        />
      ),
    },
    {
      title: 'Choose Area of Improvement',
      type: 'ConfigComponentStep',
      key: 'SELECT_PROPOSITION',
      isMandatory: true,
      component: () =>
        selectedInitiative && (
          <Step2
            initiative={selectedInitiative}
            inputContext={{ teamId: props.teamId }}
            selectedMilestoneSuggestion={selectedMilestoneSuggestion}
            onSelectMilestoneSuggestion={setSelectedMilestoneSuggestion}
            onNextStep={goToStep3}
            metaTags={{
              ...metaTags,
              selectedInitiative: tagsToString(selectedInitiative.metaTags),
            }}
          />
        ),
    },
    {
      title: 'Select activities',
      type: 'ConfigComponentStep',
      key: 'SELECT_ACTIVITIES',
      isMandatory: false, // it is mandatory, but everything required is prefilled by default
      component: () =>
        selectedMilestoneSuggestion && (
          <Step3
            milestoneSuggestion={selectedMilestoneSuggestion}
            loadingSuggestions={activitiesQuery.loading}
            activitySuggestions={skaDrafts.map((draft) => ({
              headline: draft.name ?? '',
              description: draft.definitionOfDone ?? '',
              id: draft.id,
            }))}
            selectedActivitySuggestions={selectedActivitySuggestions}
            onSelectActivitySuggestions={setSelectedActivitySuggestions}
          />
        ),
    },
    {
      title: 'Refine plan',
      type: 'ConfigComponentStep',
      key: 'ACTIVITY_FORMS',
      isMandatory: true,
      component: () => (
        <Step4
          teamId={props.teamId}
          skaDrafts={selectedSkaDrafts}
          members={teamMembers}
          membersLoading={baseInfo.loading}
          onEditDraft={handleEdit}
          onConfirmSelection={setSelectedActivitySuggestions}
        />
      ),
    },
    {
      title: 'Confirm and save',
      type: 'ConfigComponentStep',
      key: 'SUMMARY',
      component: () =>
        selectedMilestoneSuggestion && (
          <Step5
            teamId={props.teamId}
            milestoneSuggestion={selectedMilestoneSuggestion}
            skaDrafts={selectedSkaDrafts}
            tags={tagLookup}
            members={memberLookup}
            onSave={handlesave}
          />
        ),
    },
  ];

  return (
    <div>
      <Btn
        icon={<SparklesIcon />}
        type="primary"
        className="ml--s"
        onClick={() => setModalOpen(true)}
      >
        Get suggestions
      </Btn>
      <WizardNavigationProvider config={wizardConfig}>
        <Modal
          title={<h2>AI-enabled planning</h2>}
          width={660}
          open={modalOpen}
          onCancel={() => setModalOpen(false)}
          footer={null}
        >
          <AiWizard />
        </Modal>
      </WizardNavigationProvider>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_MEMBERS_V2_HOOK = gql`
  query AiSuggestButtonQuery($teamId: ID!) {
    membersV2(teamId: $teamId, filter: { archived: false }) {
      teamId
      members {
        teamId
        user {
          ...Step4_User
          ...Step5_User
        }
      }
    }
    teamTags(teamId: $teamId) {
      tags {
        ...Step5_TeamTag
      }
    }
    tenantMetaTags {
      tenantMetaTags {
        ...AiSuggestButton__MetaTag
      }
    }
    team(teamId: $teamId) {
      id
      name
      tenantId
      metaTags {
        ...AiSuggestButton__MetaTag
      }
      teamMigs {
        id
        name
        metaTags {
          ...AiSuggestButton__MetaTag
        }
      }
    }
    joinedTenantInitiativesForTeam(teamId: $teamId) {
      initiatives {
        ...Step1_Initiative
        metaTags {
          _id
          type
          value
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_ACTIVITY_SUGGESTIONS = gql`
  query AiWizardButtonActivitySuggestions($activityInput: ActivityInputVars!) {
    activitySuggestions(activityInput: $activityInput) {
      activities {
        id
        headline
        definitionOfDone
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const META_TAG_FRAGMENT = gql`
  fragment AiSuggestButton__MetaTag on MetaTag {
    _id
    type
    value
  }
`;

const stringifyMetaTags = <
  T extends {},
  U extends Record<string, { value: string }[] | undefined> = Record<
    keyof T,
    { value: string }[] | undefined
  >,
>(
  metaTags: U
): T => {
  return Object.entries(metaTags).reduce((acc, [key, tags]) => {
    return {
      ...acc,
      [key]: tagsToString(tags),
    };
  }, {} as T);
};

type TagList = { value: string }[];

const tagsToString = <T extends TagList | undefined>(
  tags: T
): T extends TagList ? string : T => {
  return getUnique(tags?.map((t) => t.value))?.join(', ') as any;
};

const getUnique = (arr: string[] | undefined) =>
  arr && Array.from(new Set(arr));
