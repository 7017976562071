import { useWizardNavigation } from '../../../../../../components/WizardNavigationProvider';
import { DemoWizardSteps } from './components/DemoWizardSteps';

export const AiWizard = () => {
  const wizard = useWizardNavigation();

  return (
    <div className="">
      <DemoWizardSteps />
      {wizard.activeStep.component()}
    </div>
  );
};
