import React from 'react';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';

interface Props extends InputNumberProps {}

export const NumberInput = (props: Props) => {
  return (
    <InputNumber
      autoComplete="off"
      decimalSeparator=","
      {...props}
    ></InputNumber>
  );
};
