import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CustomAxisTick, Period } from '../../../../services/GraphTickHelper';
import { Colors } from '../../../componentLibrary/Colors';
import './Graph.less';
import { CustomTooltip } from '../../../../components/graphs/CustomTooltip';
import { roundToDecimals } from '../../../../services/roundNumbersHelper';
import { friendlyDate } from '../../../../services/dateFormats';

interface Props {
  periodData: Period[];
  startDate?: string;
  children?: React.ReactNode;
  width?: number;
  referenceLine?: string;
  showDots?: boolean;
  minY?: number | null;
  maxY?: number | null;
}

export const Graph = ({
  periodData,
  startDate,
  children,
  width = 720,
  referenceLine,
  showDots = true,
  minY,
  maxY,
}: Props) => {
  let lineData = periodData;

  if (startDate) {
    const firstPeriod: Period = {
      goal: 0,
      actual: 0,
      historic: 0,
      targetDate: startDate,
      label: friendlyDate(startDate),
    };

    lineData = [firstPeriod, ...periodData];
  }

  return (
    <div className="flx flx--ai-center flx--jc-center">
      <div className="flx flx--column">
        <LineChart
          width={width}
          height={250}
          data={lineData}
          margin={{ top: 5, right: 30, left: 10, bottom: 20 }}
        >
          <CartesianGrid opacity={0.5} vertical={false} />
          <XAxis
            dataKey="targetDate"
            interval={0}
            tickSize={0}
            tick={<CustomAxisTick lineData={lineData} />}
            minTickGap={5}
            height={2}
            strokeOpacity={0.5}
          />
          <YAxis
            width={30}
            strokeOpacity={0.5}
            tick={{ fontSize: 9 }}
            domain={[minY ?? 'dataMin', maxY ?? 'dataMax']}
          />
          <Tooltip
            formatter={(value) =>
              typeof value === 'number' ? roundToDecimals(value, 2) : value
            }
            labelFormatter={(label) => {
              return lineData.find((p) => p.targetDate === label)?.label;
            }}
            itemStyle={{ padding: 0 }}
            content={(props) => <CustomTooltip {...props} />}
          />

          {referenceLine && (
            <ReferenceLine
              x={referenceLine}
              stroke={Colors.Action.BLUE}
              strokeDasharray="3 3"
            />
          )}

          <Line
            dataKey="goal"
            stroke={Colors.Howwe.MAIN_ORANGE}
            strokeWidth={2}
            isAnimationActive={false}
            dot={showDots}
          />
          <Line
            dataKey="actual"
            stroke={Colors.Action.BLUE}
            strokeWidth={2}
            isAnimationActive={false}
            dot={showDots}
          />
          <Line
            dataKey="historic"
            stroke={Colors.Grays.SECONDARY_FONT}
            strokeWidth={2}
            isAnimationActive={false}
            dot={showDots}
          />
        </LineChart>
        <div className="flx mt ml--xxl mr--xxl ">{children}</div>
      </div>
    </div>
  );
};
