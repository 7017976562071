import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { Btn } from './Button';
import './SKADrawerConfirmCloseAlert.less';

type SKADrawerConfirmCloseAlertProps = {
  onConfirm: () => void;
  onClose: () => void;
};

export const SKADrawerConfirmCloseAlert = ({
  onConfirm,
  onClose,
}: SKADrawerConfirmCloseAlertProps) => {
  const { t } = useTranslation();
  return (
    <Alert
      className="SKADrawerConfirmCloseAlert"
      closable
      onClose={onClose}
      message={t('SKADrawerConfirmCloseAlert.warning')}
      description={
        <>
          {t('SKADrawerConfirmCloseAlert.confirmClose')}
          <div className="mt flx flx--jc-flx-end">
            <Btn size="small" type="primary" onClick={onConfirm}>
              {t('SKADrawerConfirmCloseAlert.accept')}
            </Btn>
          </div>
        </>
      }
      type="warning"
    />
  );
};
