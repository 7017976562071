import './SummaryStat.less';

interface Props {
  value: React.ReactNode;
  label: string;
  description: string;
}

export const SummaryStat = ({ value, label, description }: Props) => {
  return (
    <div className="SummaryStat">
      <div className="SummaryStat__value">{value}</div>
      <div className="SummaryStat__label">{label}</div>
      <div className="SummmaryStat__description">{description}</div>
    </div>
  );
};
