import { gql, useQuery } from '@apollo/client';
import { GetTeamKeyActivitiesPreviewDocument } from '../../../../generated/graphql';
import { convertDateStringsToMoments } from '../../../../services/convertDateStringsToMoments';

interface Props {
  teamId: string;
  tenantId?: string;
}

export const useTeamKeyActivitiesPreview = ({ teamId, tenantId }: Props) => {
  const { loading, data, error } = useQuery(
    GetTeamKeyActivitiesPreviewDocument,
    {
      variables: { teamId, tenantId },
    }
  );
  const weeklyKeyActivities = data?.weeklyKeyActivities.map((a) =>
    convertDateStringsToMoments(a, 'startDate', 'endDate', 'archivedAt')
  );

  const sprintTerms = data?.mitemTerms.payload.map((st) =>
    convertDateStringsToMoments(
      st,
      'startDate',
      'endDate',
      'publishDate',
      'unpublishDate',
      'archivedAt'
    )
  );

  return {
    weeklyKeyActivities,
    sprintTerms,
    error,
    loading,
  };
};

export type TeamKeyActivitiesPreview = ReturnType<
  typeof useTeamKeyActivitiesPreview
>;

export const GET_TEAM_KEY_ACTIVITIES_PREVIEW = gql`
  query getTeamKeyActivitiesPreview($teamId: ID!, $tenantId: ID) {
    weeklyKeyActivities(teamId: $teamId, tenantId: $tenantId) {
      name
      id
      status
      startDate
      endDate
      archivedAt
    }
    mitemTerms(teamId: $teamId, tenantId: $tenantId) {
      payload {
        id
        sprintInterval
        archivedAt
        startDate
        endDate
        publishDate
        unpublishDate
        status
        published
      }
    }
  }
`;
