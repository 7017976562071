import { gql, useLazyQuery } from '@apollo/client';
import { GetRelatedSprintKeyActivitiesV2Document } from '../generated/graphql';

export const useMigRelatedSprintKeyActivitiesLazy = () => {
  const [fetchRelatedSprintKeyActivities, { data, loading, error }] =
    useLazyQuery(GetRelatedSprintKeyActivitiesV2Document);

  return [
    fetchRelatedSprintKeyActivities,
    {
      data: data?.relatedSprintKeyActivitiesV2,
      loading,
      error,
    },
  ] as const;
};

export const MIG_RELATED_SPRINT_KA = gql`
  query GetRelatedSprintKeyActivitiesV2($teamId: ID!, $migId: ID!) {
    relatedSprintKeyActivitiesV2(teamId: $teamId, migId: $migId) {
      target
      actual
      upcoming
      total
      fulfillment
    }
  }
`;
