import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTeamMembers } from '../../../../../../../../../hooks/useTeamMembersV2';

type TeamMember = {
  id: string;
  name: string | null;
  displayName: string | null;
  email: string;
  initials: string;
};

interface Props {
  teamId: string;
  tenantId?: string;
  contributingMembers?: TeamMember[];
  setFileredTeamMember: (userId: string | 'ALL_MEMEMBERS') => void;
  className?: string;
}

export const TeamContributingMemberFilter = ({
  contributingMembers,
  setFileredTeamMember,
  className,
  teamId,
  tenantId,
}: Props) => {
  const { t } = useTranslation();
  const { data: membersData } = useTeamMembers(teamId, tenantId);
  const nonContributors = membersData?.membersV2.members
    .filter((m) => !contributingMembers?.some((cu) => cu.id === m.user.id))
    .map((fu) => fu.user);

  const { Option } = Select;

  return (
    <div
      className={className}
      data-intercom-target="Previous Period Summary - team member filter"
    >
      <Select
        defaultValue={'ALL_MEMEMBERS'}
        onChange={setFileredTeamMember}
        style={{ width: '220px' }}
      >
        <Option value={'ALL_MEMEMBERS'}>
          {t('TeamContributingMemberFilter.allMembers')}
        </Option>
        {contributingMembers?.map((o) => {
          return (
            <Option key={o.id} value={o.id}>
              {o.name}
            </Option>
          );
        })}
        {nonContributors?.map((nc) => {
          return (
            <Option key={nc.id} value={nc.id} disabled>
              {nc.name}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};
