import { gql } from '@apollo/client';
import { InitiativeContentFragment } from '../../../../../../generated/graphql';
import { InitiativeTag } from '../../../../../../components/initiative/InitiativeTag';
import {
  Icons,
  InitiativeIcon,
} from '../../../../../company/initiatives_old/initiativesPageV1/InitiativeIcons';
import { InitiativeDropdownMenu } from './teamInitiativeCardHeader/InitiativeDropDownMenu';

interface Props {
  showActions?: boolean;
  hierarchicalInitiative: InitiativeContentFragment;
  teamId: string | undefined;
  isSubInitiative: boolean;
}

export const TeamInitiativesCardHeader = ({
  showActions = false,
  hierarchicalInitiative,
  teamId,
  isSubInitiative,
}: Props) => {
  return (
    <>
      <div className="TeamInitiativesCard__header mb--s">
        <InitiativeTag
          icon={Icons[hierarchicalInitiative.tag.iconId as InitiativeIcon]}
          borderColor={hierarchicalInitiative.tag.colorCode}
          title={hierarchicalInitiative.tag.title}
          completed={hierarchicalInitiative.completed?.value}
          archived={hierarchicalInitiative.archived}
        />

        {showActions && (
          <InitiativeDropdownMenu
            initiative={hierarchicalInitiative}
            teamId={teamId}
            isSubInitiative={isSubInitiative}
          />
        )}
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ARCHIVE_INITIATIVE = gql`
  mutation archiveInitiative(
    $teamId: ID!
    $initiativeId: ID!
    $initiative: UpdateInitiativeInput!
  ) {
    editInitiative(
      teamId: $teamId
      initiativeId: $initiativeId
      initiative: $initiative
    ) {
      id
      name
      description
      archived
      rev
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LEAVE_INITIATIVE = gql`
  mutation leaveInitiative(
    $tenantId: ID
    $teamId: ID!
    $initiativeFullId: ID!
  ) {
    leaveInitiative(
      tenantId: $tenantId
      teamId: $teamId
      initiativeFullId: $initiativeFullId
    ) {
      id
    }
  }
`;
