import dayjs, { Dayjs } from 'dayjs';
import { MigInterval } from '../generated/graphql';
import { friendlyDate } from './dateFormats';

interface Period {
  targetDate: string;
  actual?: number | null;
}

const lastDayToReport = {
  WEEK: (targetDate: Dayjs) => {
    return targetDate.add(3, 'days');
  },
  MONTH: (targetDate: Dayjs) => {
    return targetDate.add(14, 'days');
  },
  QUARTER: (targetDate: Dayjs) => {
    return targetDate.add(14, 'days');
  },
};

export const getMigWarnings = (
  periodData: Period[],
  interval: MigInterval,
  today: Dayjs
) => {
  const warnings: string[] = [];

  periodData.forEach((d) => {
    const targetDate = dayjs(d.targetDate);
    if (d.actual == null) {
      const limit = lastDayToReport[interval](targetDate);
      if (today.isAfter(limit)) {
        warnings.push(friendlyDate(targetDate));
      }
    }
  });

  return warnings;
};
