import { useTranslation } from 'react-i18next';
import { TeamContributionTeamTableV2 } from './teamContributionSection/TeamContributionTeamTableV2';

type StatusSummary = {
  keyActivitiesMarkedAsMilestone: number;
  keyActivitiesNotMarkedAsMilestones: number;
  total: number;
};

interface Props {
  teamSummary?: {
    teamId: {
      id: string;
      domainId: { itemId: string };
    };
    activitiesSummary: {
      totalNumberOfActivities: number;
      onTrackPercentage: number;
      completed: StatusSummary;
      expectedToBeCompleted: StatusSummary;
      overdue: StatusSummary;
      planned: StatusSummary;
    };
  }[];
  teamId?: string;
}

export const TeamContributionSection = ({ teamSummary, teamId }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <h2
        className="mt--xl mb--l"
        data-intercom-target="Initiative team contribution title"
      >
        {t('TeamContributionSection.progressByTeamV2', {
          count: teamSummary?.length ?? 0,
        })}
      </h2>
      <TeamContributionTeamTableV2 teamsSummary={teamSummary} teamId={teamId} />
    </div>
  );
};
