import { DeleteOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayDateRange } from '../../../../../../../../../../../components/DisplayDateRange';
import {
  MitemStatus,
  SprintPerformanceQueryDocument,
  SprintPerformance_MitemSprintFragment,
} from '../../../../../../../../../../../generated/graphql';
import { PostponedIcon } from '../../../../../../../../../../../icons/PostponedIcon';
import { SprintPieChart } from './SprintPerformance/SprintPieChart';
import { Colors } from '../../../../../../../../../../componentLibrary/Colors';
import { gql, useLazyQuery } from '@apollo/client';

interface Props {
  sprint?: SprintPerformance_MitemSprintFragment | null;
  teamId: string;
}

export const SprintPerformance = ({ teamId, sprint }: Props) => {
  const { t } = useTranslation();
  const [loadSkaTimeStatuses, { data }] = useLazyQuery(
    SprintPerformanceQueryDocument
  );

  const sprintKaStatuses = data?.skaTimeStatuses.sprintKaStatuses;

  useEffect(() => {
    if (sprint)
      loadSkaTimeStatuses({
        variables: {
          teamId,
          startDate: sprint.startDate,
          endDate: sprint.endDate,
        },
      });
  }, [loadSkaTimeStatuses, teamId, sprint]);

  const sprintData = sprintKaStatuses?.reduce(
    (acc, ska) => {
      if (ska.status === MitemStatus.COMPLETED) {
        acc = { ...acc, completed: acc.completed + 1 };
      }
      if (ska.status === MitemStatus.ACTIVE) {
        acc = { ...acc, active: acc.active + 1 };
      }
      if (ska.status === MitemStatus.OVERDUE) {
        acc = { ...acc, overdue: acc.overdue + 1 };
      }
      if (ska.status === MitemStatus.NOT_COMPLETED)
        acc = { ...acc, notCompleted: acc.notCompleted + 1 };

      if (ska.status === MitemStatus.ARCHIVED)
        acc = { ...acc, archived: acc.archived + 1 };

      if (
        ska.status === MitemStatus.POSTPONED ||
        (sprint?.locked && ska.status === MitemStatus.PLANNED)
      )
        acc = { ...acc, postponed: acc.postponed + 1 };

      if (!sprint?.locked && ska.status === MitemStatus.PLANNED)
        acc = { ...acc, planned: acc.planned + 1 };

      return acc;
    },
    {
      completed: 0,
      active: 0,
      overdue: 0,
      notCompleted: 0,
      archived: 0,
      postponed: 0,
      planned: 0,
    }
  );

  return (
    <div>
      <h4 className="mb--none">{t('SprintPerformance.title')}</h4>
      <span className="txt--secondary font-size--sm">
        <DisplayDateRange
          startDate={sprint?.startDate}
          endDate={sprint?.endDate}
        />
      </span>
      <SprintPieChart data={sprintData} sprintData={sprint} />
      <div className="mt--l">
        <h4 className="mb--none">{t('SprintPerformance.deviations')}</h4>
        <div>
          <div className="mt flx flx--ai-center">
            <PostponedIcon
              style={{ color: Colors.Grays.SECONDARY_FONT, fontSize: 20 }}
            />
            <Typography.Text type="secondary" className="ml--l">
              {t('SprintPerformance.postponed', {
                postponed: sprintData?.postponed,
              })}
            </Typography.Text>
          </div>

          <div className="mt flx flx--ai-center">
            <DeleteOutlined
              style={{ color: Colors.Grays.SECONDARY_FONT, fontSize: 20 }}
            />
            <Typography.Text type="secondary" className="ml--l">
              {t('SprintPerformance.archived', {
                archived: sprintData?.archived,
              })}
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_PERFORMANCE_SKA_TIME_STATUS = gql`
  fragment SprintPerformance_SkaTimeStatus on SkaTimeStatus {
    id
    teamId
    tenantId
    name
    deadline
    status
    owner {
      id
      email
      displayName
      name
    }
    sprintKeyActivity {
      id
      status
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_PERFORMANCE_MITEM_SPRINT = gql`
  fragment SprintPerformance_MitemSprint on MitemSprint {
    id
    startDate
    endDate
    locked
    ...SprintPieChart_MitemSprint
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MITEMS_IN_SPRINT = gql`
  query SprintPerformanceQuery(
    $teamId: ID!
    $startDate: String!
    $endDate: String!
  ) {
    skaTimeStatuses(teamId: $teamId, startDate: $startDate, endDate: $endDate) {
      sprintKaStatuses {
        ...SprintPerformance_SkaTimeStatus
      }
    }
  }
`;
