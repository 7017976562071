import { useEffect, useState } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, FormInstance, Input, Skeleton } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import {
  ColorPicker,
  TagColorBox,
} from '../../../../components/initiative/ColorPicker';
import { IconContainer } from '../../../../components/initiative/IconContainer';
import { InitiativeTag } from '../../../../components/initiative/InitiativeTag';
import { PermittedTeamSelectorForAction } from './initiativeDrawer/PermittedTeamSelectorForAction';
import { MigSelector } from './initiativeDrawer/MigSelector';
import { Icons, InitiativeIcon } from './InitiativeIcons';
import { useMigForInitiativeLazy } from './hooks/useMigsForInitiative';
import {
  Action,
  InitiativeContentFragment,
  TeamResourceType,
} from '../../../../generated/graphql';
import './InitiativeForm.less';
import { Btn } from '../../../../components/Button';
import cx from 'classnames';

interface Props {
  form: FormInstance<any>;
  loading?: boolean;
  initialValue?: InitiativeContentFragment;
  includeTagEditing: boolean;
  isEdit?: boolean;
}

export const InitiativeForm = ({
  form,
  loading,
  initialValue,
  includeTagEditing,
  isEdit,
}: Props) => {
  const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>(
    initialValue?.team?.id
  );
  const selectedMigIds = Form.useWatch('migs', form) as string[];
  const tagIcon = (Form.useWatch('iconId', form) ??
    initialValue?.tag.iconId) as InitiativeIcon;
  const tagColor = (Form.useWatch('colorCode', form) ??
    initialValue?.tag.colorCode) as TagColorBox;
  const tagTitle = Form.useWatch('tagTitle', form) as string;
  const { t } = useTranslation();

  const [fetchMigsForInitiativeLazy, { data, activeOrUpcomingMigs }] =
    useMigForInitiativeLazy();

  const initialValueTeamId = initialValue?.domainId.teamId;

  useEffect(() => {
    if (selectedTeamId || initialValueTeamId) {
      fetchMigsForInitiativeLazy({
        variables: { teamId: (selectedTeamId ?? initialValueTeamId)! },
      });
    }
  }, [selectedTeamId, fetchMigsForInitiativeLazy, initialValueTeamId]);

  const formInitialValue = initialValue
    ? {
        ...initialValue,
        tagTitle: initialValue.tag.title,
        colorCode: initialValue.tag.colorCode,
        iconId: initialValue.tag.iconId,
        teamId: initialValue.domainId.teamId,
        objectives: initialValue.objectives.map((o) => o.text),
        migs: initialValue.supportsMigs.map((m) => m.migV2.id),
      }
    : { objectives: [''] };

  return (
    <div>
      {loading ? (
        <Skeleton />
      ) : (
        <Form
          layout="vertical"
          form={form}
          requiredMark={'optional'}
          initialValues={formInitialValue}
        >
          <h4 className="InitiativeForm__subHeaders mb--l">
            {t('InitiativeForm.initiativeSetup')}
          </h4>
          <Form.Item
            name="name"
            label={t('common.title')}
            rules={[
              {
                required: true,
              },
              {
                min: 2,
                max: 51,
              },
            ]}
          >
            <Input
              type="text"
              placeholder={t('InitiativeForm.titlePlaceholder')}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label={t('common.initiative.purpose')}
            rules={[
              {
                required: true,
              },
              {
                min: 2,
                max: 500,
              },
            ]}
          >
            <TextArea rows={4} showCount maxLength={500} />
          </Form.Item>

          <Form.Item label={t('common.initiative.objectives')} required={false}>
            <Form.List name="objectives">
              {(fields, { add, remove }) => (
                <div>
                  {fields.map((field, index) => (
                    <div className="flx" key={field.key}>
                      <Form.Item
                        {...field}
                        className={cx('flx--1', {
                          mb: index === fields.length - 1,
                        })}
                      >
                        <Input />
                      </Form.Item>
                      <Btn
                        type="text"
                        icon={
                          <DeleteOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      />
                    </div>
                  ))}
                  <Btn
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      add();
                    }}
                  >
                    {t('InitiativeForm.addObjective')}
                  </Btn>
                </div>
              )}
            </Form.List>
          </Form.Item>

          <h4 className="InitiativeForm__subHeaders mb--l">
            {t('InitiativeForm.initiativeTagSetup')}
          </h4>
          {includeTagEditing && !isEdit ? (
            <h5 className="InitiativeForm__subHeaders__description mb--l">
              {t('InitiativeForm.tagDescription')}
            </h5>
          ) : (
            <h5 className="InitiativeForm__subHeaders__description mb--l">
              {t('InitiativeForm.tagDescriptionForEdit')}
            </h5>
          )}

          <Form.Item
            name="tagTitle"
            label={t('InitiativeForm.tagTitle')}
            rules={[
              {
                required: true,
              },
              {
                min: 2,
                max: 20,
              },
            ]}
          >
            <Input
              type="text"
              placeholder={t('InitiativeForm.tagTitlePlaceholder')}
              maxLength={21}
            />
          </Form.Item>
          {includeTagEditing && (
            <>
              <Form.Item
                label={t('InitiativeForm.tagColor')}
                name="colorCode"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <ColorPicker />
              </Form.Item>
              <Form.Item
                label={t('InitiativeForm.tagIcon')}
                name="iconId"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <IconContainer icons={Icons} />
              </Form.Item>
            </>
          )}

          <Form.Item label={t('InitiativeForm.tagPreview')} required>
            {/* required is set only to hide '(optional) label */}
            <InitiativeTag
              title={tagTitle}
              borderColor={tagColor}
              icon={Icons[tagIcon]}
              completed={initialValue?.completed?.value}
              archived={initialValue?.archived}
            />
          </Form.Item>
          <h4 className="InitiativeForm__subHeaders">
            {t('InitiativeForm.alignment')}
          </h4>
          <h5 className="InitiativeForm__subHeaders__description mb--l">
            {t('InitiativeForm.alignmentDescription')}
          </h5>

          {!isEdit && (
            <Form.Item
              label={t('InitiativeForm.teamSelect')}
              name="teamId"
              rules={[
                {
                  required: true,
                  whitespace: true,
                },
              ]}
            >
              <PermittedTeamSelectorForAction
                teamResourceType={TeamResourceType.TEAM_INITIATIVE}
                action={Action.CREATE}
                onChange={(value) => {
                  form.setFieldValue('migs', ['']);
                  setSelectedTeamId(value);
                }}
              />
            </Form.Item>
          )}
          <Form.Item label="Migs" required>
            <Form.List name="migs">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <div
                        className="flx"
                        key={field.key + (selectedTeamId ?? 'no-team-selected')}
                      >
                        <Form.Item
                          className={cx('flx--1', {
                            mb: index === fields.length - 1,
                          })}
                          {...field}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <MigSelector
                            selectedMigIds={selectedMigIds ?? []}
                            migs={
                              isEdit
                                ? data?.teamMigs.migs
                                : activeOrUpcomingMigs
                            }
                            disabled={
                              selectedTeamId === undefined &&
                              initialValueTeamId === undefined
                            }
                          />
                        </Form.Item>
                        {index > 0 ? (
                          <Btn
                            type="text"
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </div>
                    ))}
                    <Form.Item>
                      <Btn
                        type="link"
                        disabled={selectedTeamId === undefined}
                        icon={<PlusOutlined />}
                        onClick={() => {
                          add();
                        }}
                      >
                        {t('InitiativeForm.addMigAlignment')}
                      </Btn>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};
