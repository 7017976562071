import { useTranslation } from 'react-i18next';

interface NoDataProps {
  text?: string;
}

export const NoData: React.FC<NoDataProps> = (props) => {
  let { text } = props;
  const { t } = useTranslation();
  if (!text) {
    text = t('NoData.defaultInfo');
  }
  return <span className="txt--secondary">- {text} -</span>;
};
