import Icon from '@ant-design/icons';

const SparklesIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.6665 5.99996L11.8332 4.16663L9.99984 3.33329L11.8332 2.49996L12.6665 0.666626L13.4998 2.49996L15.3332 3.33329L13.4998 4.16663L12.6665 5.99996ZM12.6665 15.3333L11.8332 13.5L9.99984 12.6666L11.8332 11.8333L12.6665 9.99996L13.4998 11.8333L15.3332 12.6666L13.4998 13.5L12.6665 15.3333ZM5.99984 13.3333L4.33317 9.66663L0.666504 7.99996L4.33317 6.33329L5.99984 2.66663L7.6665 6.33329L11.3332 7.99996L7.6665 9.66663L5.99984 13.3333ZM5.99984 10.1L6.6665 8.66663L8.09984 7.99996L6.6665 7.33329L5.99984 5.89996L5.33317 7.33329L3.89984 7.99996L5.33317 8.66663L5.99984 10.1Z" />
  </svg>
);

export const SparklesIcon = (props: any) => (
  <Icon component={SparklesIconSvg} {...props} />
);
