import { GraphLegendIcon } from './GraphLegendIcon';
import cx from 'classnames';

interface Props {
  color: string;
  title: string;
  value?: number | string | null;
  className?: string;
}

export const GraphLegend = ({ color, title, value, className }: Props) => {
  return (
    <div className={cx('flx flx--ai-center flx--column', className)}>
      <div style={{ color: color }}>
        <GraphLegendIcon />
        <span className={cx('ml--xs', { 'bold ': value != null })}>
          {value ?? '--'}
        </span>
      </div>
      <span className="font-size--sm txt--secondary mt--s">{title}</span>
    </div>
  );
};
