import {
  CheckCircleFilled,
  CheckCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import * as sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import {
  ActualInsight,
  getInsightCode,
} from '../../../../../../../../../hooks/useAccelerationMeetingInsights';
import { GenericInsightCard } from '../../genericCard/GenericInsightCard';

interface Props {
  migActualInsight: ActualInsight;
}

export const MigActualCard = ({ migActualInsight }: Props) => {
  const { t, i18n } = useTranslation();
  const actualsCode = migActualInsight.code;

  const migStatusIcon = (code: string | undefined | null) => {
    if (!code) return;
    if (code === 'MIG__ACTUAL__ALL_MISSING') {
      return <WarningOutlined className="icon allMissing  mr--xl" />;
    }
    if (code === 'MIG__ACTUAL__WARNING_NUDGE') {
      return <WarningOutlined className="icon pastMissing  mr--xl" />;
    }
    if (code === 'MIG__ACTUAL__FRIENDLY_NUDGE') {
      return <CheckCircleOutlined className="icon lastMissing  mr--xl" />;
    }

    return <CheckCircleFilled className="icon allUpdated  mr--xl" />;
  };
  if (!i18n.exists(`MigInsights.${actualsCode}.short`)) {
    sentry.captureMessage(
      `MigInsights.${actualsCode}.short has no translation`,
      {
        level: 'warning',
      }
    );
    return null;
  }
  return (
    <GenericInsightCard
      heading={t('MigInsights.miginsight')}
      title={t(`MigInsights.${actualsCode}.short`)}
      keyValue={actualsCode}
      backSide={{
        description: t(
          `backCardDescriptions.${getInsightCode(migActualInsight.code)}`
        ),
        why: t(
          `backCardWhyImportantDescriptions.${getInsightCode(
            migActualInsight.code
          )}`
        ),
      }}
    >
      <span className="genericCard--text">
        {t(`MigInsights.${actualsCode}.long`)}
      </span>

      <div className="migInformation flx flx--column">
        {migActualInsight.migsToShow.map((mig, i) => {
          return (
            <div className="flx flx--row flx--ai-center" key={i}>
              {migStatusIcon(mig.state)}
              <div className="migInformation-text flx--column">
                <p className="migInformation-text--title">{mig.name}</p>
                <p className="migInformation-text--advice">
                  {mig.missingPeriods.length
                    ? t('MigInsights.MigActualsInfo.textWithData', {
                        count: mig.missingPeriods.length,
                        countToDisplay: mig?.missingPeriods
                          ? mig.missingPeriods?.length - 1
                          : 0,
                        firstMigMissedDate: mig.missingPeriods[0],
                      })
                    : t('MigInsights.MigActualsInfo.textAllUpdated')}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </GenericInsightCard>
  );
};
