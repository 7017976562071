type OptionalString = string | undefined | null;

export const isNonEmptyString = (s: OptionalString): s is string =>
  s != null && s !== '';

export const ifNonEmptyString = <Y, N = undefined>(
  s: OptionalString,
  yes: (val: string) => Y,
  no?: N
) => {
  if (isNonEmptyString(s)) {
    return yes(s);
  }
  return no;
};
