import { Alert } from 'antd';
import { DisplayDate } from '../../../../../../../components/DisplayDate';
import { DisplayName } from '../../../../../../../components/DisplayName';
import { UserAvatar } from '../../../../../../../components/UserAvatar';
import { TagImage } from '../../../../../setup/overviewPage/labels/TagImage';
import {
  SkaSummary_TeamTagFragment,
  SkaSummary_UserFragment,
  SprintKaInput,
} from '../../../../../../../generated/graphql';
import { gql } from '@apollo/client';

interface Props {
  activity: SprintKaInput;
  tags: Record<string, SkaSummary_TeamTagFragment>;
  members: Record<string, SkaSummary_UserFragment>;
}

export const SkaSummary = ({ activity, tags, members }: Props) => {
  return (
    <div className="borderBottom pa pb--l flx">
      <div className="pl flx--1">
        <h4 className="mb--s">{activity.name}</h4>

        <div className="flx mb">
          <div>
            <span className="font-size--sm txt--secondary mb--xs mr--s">
              Owner:
            </span>
            <UserAvatar user={members[activity.ownerId!]} size="small" />
            <span className="ml--s font-size--sm bold">
              <DisplayName user={members[activity.ownerId!]} />
            </span>
          </div>
          <div className="ml--l">
            <span className="font-size--sm txt--secondary mb--xs  space--r">
              Deadline:
            </span>
            <span className="font-size--sm bold">
              <DisplayDate date={activity.deadline} />
            </span>
          </div>
          <div className="ml--l flx--1">
            <span className="font-size--sm txt--secondary mb--xs">Tags:</span>
            {activity.tags?.map((tagId) => (
              <span className="space--l" key={tagId}>
                <TagImage tag={tags[tagId]} />
              </span>
            ))}
          </div>
        </div>
        <Alert
          type="info"
          message={
            <>
              <span className="font-size--sm bold mb--xs space--r">
                Definition of done:
              </span>
              {activity.definitionOfDone}
            </>
          }
        />
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_TAG_FRAGMENT = gql`
  fragment SkaSummary_TeamTag on TeamTag {
    id
    teamId
    name
    backgroundColor
    active
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const USER_FRAGMENT = gql`
  fragment SkaSummary_User on User {
    id
    name
    displayName
    email
    initials
  }
`;
