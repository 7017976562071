import { Input, Skeleton } from 'antd';

import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../../../../components/Button';
import { SearchOutlined } from '@ant-design/icons';

export const SelectInitiativeTeamSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h4
        className="bold mb--s"
        data-intercom-target="Sprint Key Activity Form Name Input"
      >
        {t('SelectInitiativeTeam.selectTeam')}
      </h4>

      <Input
        className="mb--l"
        disabled={true}
        placeholder={t('SelectInitiativeTeam.filterPlaceholder')}
        suffix={<SearchOutlined className="txt--secondary" />}
      />

      <h5 className="mb--s txt--secondary">
        {t('SelectInitiativeTeam.teamsAssignedToMilestone')}
      </h5>

      <Skeleton active paragraph={{ rows: 1, width: [175] }} title={false} />

      <h5 className="mt--l mb--s txt--secondary">
        {t('SelectInitiativeTeam.teamsAssignedToInitiative')}
      </h5>

      <Skeleton
        active
        paragraph={{ rows: 2, width: [200, 150] }}
        title={false}
      />
      <div className="mt--xl mb--xxl">
        <Btn disabled={true}>
          {t('SelectInitiativeTeam.confirmTeamSelection')}
        </Btn>
      </div>
    </div>
  );
};
