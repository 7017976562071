import { useEffect, useState } from 'react';
import { debounce } from '../../../../../services/debounce';

type UseElementWidthOpts = {
  onExpand?: (newWidth: number) => void;
  debounceTimeout?: number;
};

export const useElementWidth = (
  elementRef: React.RefObject<HTMLElement>,
  { debounceTimeout = 50, onExpand }: UseElementWidthOpts = {}
) => {
  const [currentWidth, setCurrentWidth] = useState(
    elementRef.current?.offsetWidth ?? 0
  );

  useEffect(() => {
    const handleResize = debounce(() => {
      const newWidth = elementRef.current?.offsetWidth ?? 0;

      if (onExpand) {
        const isExpanding = newWidth > currentWidth;

        isExpanding && onExpand(newWidth);
      }

      setCurrentWidth(newWidth);
    }, debounceTimeout);

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener when the component unmounts or dependencies change
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [currentWidth, debounceTimeout, elementRef, onExpand]);

  useEffect(() => {
    const newWidth = elementRef.current?.offsetWidth ?? 0;
    setCurrentWidth(newWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only run once

  return currentWidth;
};
