import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  GetMigsForInitiativesDocument,
  MigStatus,
} from '../../../../../generated/graphql';

export const useMigForInitiative = (teamId: string) => {
  const { data, error, loading } = useQuery(GetMigsForInitiativesDocument, {
    variables: { teamId },
  });
  const activeOrUpcomingMigs = getActiveOrUpcomingMigs(data?.teamMigs.migs);

  return { data, activeOrUpcomingMigs, error, loading };
};

export const useMigForInitiativeLazy = () => {
  const [fetchMigsForInitiativeLazy, { data, error, loading }] = useLazyQuery(
    GetMigsForInitiativesDocument
  );
  const activeOrUpcomingMigs = getActiveOrUpcomingMigs(data?.teamMigs.migs);

  return [
    fetchMigsForInitiativeLazy,
    { data, activeOrUpcomingMigs, error, loading },
  ] as const;
};

const getActiveOrUpcomingMigs = <T extends { status: MigStatus }>(
  migs: T[] | undefined
) =>
  migs?.filter((m) => m.status === (MigStatus.ACTIVE || MigStatus.UPCOMING)) ??
  [];

// eslint-disable-next-line
const MIG_FOR_INITIATIVES = gql`
  query getMigsForInitiatives($tenantId: ID, $teamId: ID!) {
    teamMigs(tenantId: $tenantId, teamId: $teamId) {
      migs {
        domainId {
          itemId
          teamId
        }
        id
        name
        status
      }
    }
  }
`;
