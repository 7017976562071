import { Divider, Radio } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WeeklyKeyActivityBlock } from './WeeklyKeyActivityTeamSummary/WeeklyKeyActivityBlock';

interface Props {
  startDate?: string;
  weeklyActivities: {
    id: string;
    name: string | null;
    startDate: string;
    endDate: string;
    teamId: string;
    descriptionHtml: string | null;
    acceptanceCriteriaHtml: string | null;
    unit: string | null;
    periodDataWithCommitment: {
      targetDate: string;
      goal: number;
      actual: number | null;
      committed: number | null;
    }[];
  }[];
  weeklyKeyActivityTeamContributions: {
    id: string;
    name: string;
    totalCommitted: number;
    totalAchieved: number;
  }[];
}

export enum FilterPeriod {
  PERVIOUS_PERIOD = 'PERVIOUS_PERIOD',
  ALL_TIME = 'ALL_TIME',
}

export const WeeklyKeyActivityTeamSummary = ({
  startDate,
  weeklyActivities,
  weeklyKeyActivityTeamContributions,
}: Props) => {
  const { t } = useTranslation();
  const [filterPeriod, setFilterPeriod] = useState<FilterPeriod>(
    FilterPeriod.PERVIOUS_PERIOD
  );
  return (
    <div>
      <h3>{t('WeeklyKeyActivityTeamSummary.title')}</h3>

      <Radio.Group
        className="flx mt"
        value={filterPeriod}
        buttonStyle="solid"
        onChange={(e) => setFilterPeriod(e.target.value)}
      >
        <Radio.Button className="flx--1" value={FilterPeriod.PERVIOUS_PERIOD}>
          {t('WeeklyKeyActivityTeamSummary.previousPeriod')}
        </Radio.Button>
        <Radio.Button className="flx--1" value={FilterPeriod.ALL_TIME}>
          {t('WeeklyKeyActivityTeamSummary.allTime')}
        </Radio.Button>
      </Radio.Group>
      <Divider className="mt--s" />

      {weeklyActivities.map((weeklyKeyActivity) => {
        return (
          <div
            className="borderBottom--skipLast pb mb"
            key={weeklyKeyActivity.id}
          >
            <WeeklyKeyActivityBlock
              startDate={startDate}
              filterPeriod={filterPeriod}
              weeklyKeyActivity={weeklyKeyActivity}
              weeklyKeyActivityTeamContributions={
                weeklyKeyActivityTeamContributions
              }
            />
          </div>
        );
      })}
    </div>
  );
};
