import { KeyActivityGraph } from '../../../../graphs/KeyActivityGraph';
import { Alert, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { PerformanceTag } from '../../../../PerformanceTag';
import { useClosestPeriods } from '../../../../../hooks/useClosestPeriods';
import { friendlyDate } from '../../../../../services/dateFormats';
import { lineColor } from '../../../../../styleVars';
import { useKeyActivityPerformance } from '../../../../../hooks/useKeyActivityPerformanceQuery';

import './KeyActivityTableCard.less';
import { useWeeklyKeyActivity } from '../../hooks/useWeeklyKeyActivity';

interface Props {
  teamId: string;
  tenantId?: string;
  keyActivityId: string;
}

export const KeyActivityWeeklyTableCard = ({
  teamId,
  tenantId,
  keyActivityId,
}: Props) => {
  const { t } = useTranslation();
  const { keyActivity, loading, error } = useWeeklyKeyActivity({
    teamId,
    tenantId,
    keyActivityId,
  });
  const { data, loading: akpiTargetFulfillmentForTeamLoading } =
    useKeyActivityPerformance(teamId, keyActivityId, tenantId);

  const { lastFinishedPeriod } = useClosestPeriods(teamId, tenantId);

  if (error) return <Alert message={t('KeyActivityTableCard.fetchError')} />;

  return (
    <Spin spinning={loading}>
      {keyActivity && (
        <div className="flx pa--l ">
          <div className=" KeyActivityTableWeeklyCard__chart">
            <KeyActivityGraph
              lineData={keyActivity.periodData}
              lineConf={[
                {
                  dataKey: 'goal',
                  stroke: lineColor.goal,
                  name: t('common.goal') as string,
                },
                {
                  dataKey: 'actual',
                  stroke: lineColor.actual,
                  name: t('common.actual') as string,
                },
              ]}
              highlightedPeriod={lastFinishedPeriod?.timePeriodEndDate}
              startDate={keyActivity.startDate}
            />
          </div>
          <div className="flx KeyActivityTableWeeklyCard__info">
            <div>
              <div className="flx flx--column mb">
                <Typography.Text>{t('common.startDate')}</Typography.Text>
                <Typography.Text type="secondary">
                  {friendlyDate(keyActivity.startDate)}
                </Typography.Text>
              </div>
              <div className="flx flx--column">
                <Typography.Text>{t('common.endDate')}</Typography.Text>
                <Typography.Text type="secondary">
                  {friendlyDate(keyActivity.endDate)}
                </Typography.Text>
              </div>
            </div>

            <div className="pl--xl">
              <div className="flx flx--column mb">
                <Typography.Text>
                  {t('KeyActivityTableCard.unit')}
                </Typography.Text>
                <Typography.Text type="secondary">
                  {keyActivity.unit}
                </Typography.Text>
              </div>
              <div className="flx flx--column">
                <Typography.Text>
                  {t('KeyActivityTableCard.percentage')}
                </Typography.Text>
                <div>
                  <PerformanceTag
                    value={data?.targetFulfillment}
                    loading={akpiTargetFulfillmentForTeamLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Spin>
  );
};
