import Icon from '@ant-design/icons';

const TreeOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.3 13.6667H4.7V11H0L2.66667 7.00004H1.33333L6 0.333374L10.6667 7.00004H9.33333L12 11H7.3V13.6667ZM2.5 9.66671H5.16667H3.9H8.1H6.83333H9.5H2.5ZM2.5 9.66671H9.5L6.83333 5.66671H8.1L6 2.66671L3.9 5.66671H5.16667L2.5 9.66671Z" />
  </svg>
);

export const TreeOutlineIcon = (props: any) => (
  <Icon component={TreeOutlineIconSvg} {...props} />
);
