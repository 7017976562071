import React, { FunctionComponent } from 'react';
import './MitemLayout.less';

interface Props {
  sidebar: React.ReactNode;
  title: React.ReactNode;
  children: React.ReactNode;
}

export const MitemLayout: FunctionComponent<Props> = ({
  children,
  title,
  sidebar,
}) => (
  <div>
    <div className="mb--xl mt--l flx">{title}</div>
    <div className="flx">
      <div className="flx--1 mr">
        <div className="mb--xl">{children}</div>
      </div>
      <div className="MitemLayout__sideArea">{sidebar}</div>
    </div>
  </div>
);
