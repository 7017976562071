import { gql, useQuery } from '@apollo/client';
import {
  TeamGantishForMilestonesQueryDocument,
  TeamGigantishForMilestones__InitiativeLightFragment,
  TeamGigantishForMilestones__MilestoneFragment,
} from '../../../../generated/graphql';
import { TeamGantish } from './TeamGantish/TeamGantish';
import { CollapseCard } from '../../overview/components/collapseCard/CollapseCard';
import { useMemo, useState } from 'react';
import { ProgressStats } from '../../../../components/initiatives/ProgressStats';
import { useTranslation } from 'react-i18next';

interface Props {
  teamId: string;
}

type Data = {
  joinedTenantInitiativesForTeam: {
    initiatives: TeamGigantishForMilestones__InitiativeLightFragment[];
  };
  milestonesForTeam: {
    milestones: TeamGigantishForMilestones__MilestoneFragment[];
  };
};

function processInitiativesAndMilestones(data: Data) {
  const initiativesMap = new Map<
    string,
    {
      initiative: TeamGigantishForMilestones__InitiativeLightFragment;
      milestones: TeamGigantishForMilestones__MilestoneFragment[];
    }
  >(
    data?.joinedTenantInitiativesForTeam.initiatives.map((i) => [
      i.domainId.itemId,
      { initiative: i, milestones: [] },
    ])
  );

  const unassociatedMilestones: TeamGigantishForMilestones__MilestoneFragment[] =
    [];

  data?.milestonesForTeam.milestones.forEach((m) => {
    const supportedInitiative = m.metadata.supports.find(
      (s) => s.__typename === 'SupportedInitiative'
    );
    const groupedInitiative =
      supportedInitiative &&
      initiativesMap.get(supportedInitiative.item.domainId.itemId);

    if (groupedInitiative) {
      groupedInitiative.milestones.push(m);
    } else {
      unassociatedMilestones.push(m);
    }
  });

  return {
    initiativesWithMilestones: Array.from(initiativesMap.values()),
    unassociatedMilestones,
  };
}

export const TeamGantishForMilestones = ({ teamId }: Props) => {
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation();
  const { loading, data } = useQuery(TeamGantishForMilestonesQueryDocument, {
    variables: {
      teamId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const result = data && processInitiativesAndMilestones(data);

  const totalStats = useMemo(
    () => ({
      completed:
        data?.milestonesForTeam.milestones.filter(
          (m) => m.metadata.status === 'COMPLETED'
        ).length ?? 0,
      overdue:
        data?.milestonesForTeam.milestones.filter(
          (m) => m.metadata.status === 'OVERDUE'
        ).length ?? 0,
      upcoming:
        data?.milestonesForTeam.milestones.filter(
          (m) => m.metadata.status === 'UPCOMING'
        ).length ?? 0,
    }),
    [data]
  );

  if (
    result?.initiativesWithMilestones.length === 0 &&
    result?.unassociatedMilestones.length === 0
  ) {
    return null;
  }

  return (
    <CollapseCard
      loading={loading}
      onClick={() => setToggle(!toggle)}
      expanded={toggle}
      headerContent={
        <div className="flx flx--1 flx--ai-center ">
          <h3 className="flx flx--1 flx--ai-center">
            {t('TeamGantishForMilestones.title')}
          </h3>
          <div className="flx">
            <span className="txt--secondary mr">
              {t('TeamGantishForMilestones.milestoneProgress')}
            </span>
            <ProgressStats stats={totalStats} />
          </div>
        </div>
      }
    >
      <div className="flx--1">
        <TeamGantish
          initiativesWithMilestones={result?.initiativesWithMilestones ?? []}
          unassociatedMilestones={result?.unassociatedMilestones ?? []}
        />
      </div>
    </CollapseCard>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_GIGANTISH__INITIATIVE_LIGHT = gql`
  fragment teamGigantishForMilestones__InitiativeLight on InitiativeLight {
    id
    domainId {
      itemId
      tenantId
    }
    ...teamGantish__InitiativeLight
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_GIGANTISH__MILESTONES = gql`
  fragment teamGigantishForMilestones__Milestone on Milestone {
    id
    domainId {
      itemId
      tenantId
    }
    ...teamGantish__Milestone
    metadata {
      completedAt
      status
      supports {
        ... on SupportedInitiative {
          type
          item {
            id
            domainId {
              itemId
              tenantId
            }
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAMS_WITH_PARENT_ADMIN = gql`
  query teamGantishForMilestonesQuery($tenantId: ID, $teamId: ID!) {
    joinedTenantInitiativesForTeam(tenantId: $tenantId, teamId: $teamId) {
      initiatives {
        id
        ...teamGigantishForMilestones__InitiativeLight
      }
    }
    milestonesForTeam(teamId: $teamId) {
      milestones {
        id
        ...teamGigantishForMilestones__Milestone
      }
    }
  }
`;
