import React, { ReactNode } from 'react';

interface Props {
  html?: string | null;
  emptyPlaceholder?: ReactNode;
}

/** This component is wide-open for script injection - so make sure that your source of html is sanitized and safe */
export const RenderedMarkdown = ({ html, emptyPlaceholder }: Props) => {
  if (!html) return <div>{emptyPlaceholder}</div>;

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
