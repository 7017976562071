import Icon from '@ant-design/icons';

const OpenLockIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.99984 5.33329H7.99984V3.99996C7.99984 3.4444 7.80539 2.97218 7.4165 2.58329C7.02762 2.1944 6.55539 1.99996 5.99984 1.99996C5.44428 1.99996 4.97206 2.1944 4.58317 2.58329C4.19428 2.97218 3.99984 3.4444 3.99984 3.99996H2.6665C2.6665 3.07774 2.9915 2.29163 3.6415 1.64163C4.2915 0.991626 5.07762 0.666626 5.99984 0.666626C6.92206 0.666626 7.70817 0.991626 8.35817 1.64163C9.00817 2.29163 9.33317 3.07774 9.33317 3.99996V5.33329H9.99984C10.3665 5.33329 10.6804 5.46385 10.9415 5.72496C11.2026 5.98607 11.3332 6.29996 11.3332 6.66663V13.3333C11.3332 13.7 11.2026 14.0138 10.9415 14.275C10.6804 14.5361 10.3665 14.6666 9.99984 14.6666H1.99984C1.63317 14.6666 1.31928 14.5361 1.05817 14.275C0.797059 14.0138 0.666504 13.7 0.666504 13.3333V6.66663C0.666504 6.29996 0.797059 5.98607 1.05817 5.72496C1.31928 5.46385 1.63317 5.33329 1.99984 5.33329ZM1.99984 13.3333H9.99984V6.66663H1.99984V13.3333ZM5.99984 11.3333C6.3665 11.3333 6.68039 11.2027 6.9415 10.9416C7.20262 10.6805 7.33317 10.3666 7.33317 9.99996C7.33317 9.63329 7.20262 9.3194 6.9415 9.05829C6.68039 8.79718 6.3665 8.66663 5.99984 8.66663C5.63317 8.66663 5.31928 8.79718 5.05817 9.05829C4.79706 9.3194 4.6665 9.63329 4.6665 9.99996C4.6665 10.3666 4.79706 10.6805 5.05817 10.9416C5.31928 11.2027 5.63317 11.3333 5.99984 11.3333Z" />
  </svg>
);

export const OpenLockIcon = (props: any) => (
  <Icon component={OpenLockIconSvg} {...props} />
);
