import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Btn } from '../../../../components/Button';
import React, { useState } from 'react';
import cx from 'classnames';
import './ToggleSection.less';

interface Props {
  children: React.ReactNode;
  titleExpanded: string;
  titleNotExpanded: string;
  disabled?: boolean;
}

export const ToggleSection = ({
  children,
  titleExpanded,
  titleNotExpanded,
  disabled,
}: Props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Btn
        className="mt--l"
        icon={expanded ? <UpOutlined /> : <DownOutlined />}
        type="link"
        onClick={() => setExpanded((current) => !current)}
        disabled={disabled}
      >
        {expanded ? titleExpanded : titleNotExpanded}
      </Btn>
      <div
        className={cx('ToggleSection__container', {
          'ToggleSection__container--hidden': !expanded,
        })}
      >
        <div className="ToggleSection__content">{children}</div>
      </div>
    </>
  );
};
