import { CalendarOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Alert, DatePicker, Modal, Tooltip, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../../components/Button';
import { ErrorAlert } from '../../../../../components/ErrorAlert';
import {
  CreateOffPeriodDocument,
  GetOffPeriodsDocument,
} from '../../../../../generated/graphql';
import { useLazyTeamTimeline } from '../../../../../hooks/useTeamTimeline';
import {
  friendlyDate,
  FRIENDLY_DATE_FORMAT,
  standardDateFormat,
} from '../../../../../services/dateFormats';
import {
  momentToDayjs,
  dayjsToMoment,
} from '../../../../../services/dateHelpers';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { useTeamOffPeriods } from '../../../common/hooks/useTeamOffPeriods';

interface Props {
  teamId: string;
  buttonType?: 'primary' | 'link';
  buttonSize?: 'small' | 'middle';
}

export const CreateOffWeek = ({
  teamId,
  buttonType = 'link',
  buttonSize = 'middle',
}: Props) => {
  const { t } = useTranslation();
  const [showCreateModal, setShowCreateModal] = useState(false);

  return (
    <div>
      <Modal
        title={t('CreateOffWeek.title')}
        destroyOnClose
        open={showCreateModal}
        footer={false}
        styles={{ body: { padding: 0 } }}
        onCancel={() => {
          setShowCreateModal(false);
        }}
      >
        <CreateOffWeekModalContent
          teamId={teamId}
          onClose={() => setShowCreateModal(false)}
        />
      </Modal>
      <Btn
        icon={<CalendarOutlined />}
        type={buttonType}
        size={buttonSize}
        onClick={() => setShowCreateModal(true)}
      >
        {t('TeamOverview.addOffWeek')}
      </Btn>
    </div>
  );
};

interface CreateOffWeekModalContentProps {
  teamId: string;
  onClose: () => void;
}

const CreateOffWeekModalContent = ({
  teamId,
  onClose,
}: CreateOffWeekModalContentProps) => {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const { data, error: fetchOffPeriodsError } = useTeamOffPeriods(teamId);

  const offPeriodData = data?.offPeriods.daysOff ?? [];

  const [createPeriod, { error, loading }] = useMutation(
    CreateOffPeriodDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: <strong>{t('CreateOffWeek.success')}</strong>,
        });

        onClose();
      },
      refetchQueries: () => [
        {
          query: GetOffPeriodsDocument,
          variables: { teamId },
        },
      ],
    }
  );
  const { getTimeline, timeline } = useLazyTeamTimeline();
  const [dates, setDates] = useState<[Moment | null, Moment | null]>([
    null,
    null,
  ]);
  const [startDate, endDate] = dates;

  const handleCreate = () => {
    if (startDate && endDate)
      createPeriod({
        variables: {
          teamId,
          offPeriodInput: {
            startDate: standardDateFormat(startDate),
            endDate: standardDateFormat(endDate),
          },
        },
      });
  };

  const upperLimit = dayjs().add(18, 'month');

  const isDisabledOffDates = (date: Dayjs) => {
    if (offPeriodData.length === 0) return false;

    if (date.isSameOrAfter(upperLimit)) return true;

    return offPeriodData.some(
      (period) =>
        date.isSameOrAfter(period.startDate, 'day') &&
        date.isSameOrBefore(period.endDate, 'day')
    );
  };

  useEffect(() => {
    if (startDate && endDate) {
      getTimeline(teamId, startDate, endDate);
    }
  }, [teamId, startDate, endDate, getTimeline]);

  const timePeriodStartDate = timeline && timeline[0]?.timePeriodStartDate;
  const timePeriodEndDate =
    timeline && timeline[timeline.length - 1]?.timePeriodEndDate;

  const showStartDateInfo =
    startDate != null &&
    timePeriodStartDate != null &&
    !startDate.isSame(timePeriodStartDate, 'day');
  const showEndDateInfo =
    endDate != null &&
    timePeriodEndDate != null &&
    !endDate.isSame(timePeriodEndDate, 'day');

  return (
    <div>
      <div style={{ padding: 24 }}>
        <div className="mb--l">
          <Typography.Text italic>
            {t('CreateOffWeek.description')}
          </Typography.Text>
        </div>
        <RangePicker
          value={[momentToDayjs(startDate), momentToDayjs(endDate)]}
          onChange={(values) =>
            setDates(
              values
                ? [dayjsToMoment(values[0]), dayjsToMoment(values[1])]
                : [null, null]
            )
          }
          className="fullWidth"
          disabledDate={isDisabledOffDates}
          format={FRIENDLY_DATE_FORMAT}
          renderExtraFooter={() => (
            <div>{t('CreateOffWeek.calendarFooter')}</div>
          )}
        />
        <div className="mt">
          <div className="mt--l">
            <Typography.Title level={4}>
              {t('CreateOffWeek.summary')}
            </Typography.Title>
            <Alert
              description={
                <div>
                  <div className="flx flx--jc-space-between">
                    <div>
                      <Typography.Text type="secondary">
                        {t('common.startDate')}
                      </Typography.Text>
                      <div>
                        {friendlyDate(momentToDayjs(startDate))}

                        {showStartDateInfo && (
                          <Tooltip
                            className="space--l"
                            title={t('CreateOffWeek.startDateInfo', {
                              startDate: friendlyDate(
                                momentToDayjs(timePeriodStartDate)
                              ),
                            })}
                          >
                            <QuestionCircleOutlined />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                    <div>
                      <Typography.Text type="secondary">
                        {t('common.endDate', {
                          endDate: friendlyDate(
                            momentToDayjs(timePeriodEndDate)
                          ),
                        })}
                      </Typography.Text>
                      <div>
                        {friendlyDate(momentToDayjs(endDate))}
                        {showEndDateInfo && (
                          <Tooltip
                            className="space--l"
                            title={t('CreateOffWeek.endDateInfo', {
                              endDate: friendlyDate(
                                momentToDayjs(timePeriodEndDate)
                              ),
                            })}
                          >
                            <QuestionCircleOutlined />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                    <div>
                      <Typography.Text type="secondary">
                        {t('CreateOffWeek.numberOfWeeks')}
                      </Typography.Text>
                      <div>{timeline?.length ?? '-'}</div>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
        <ErrorAlert
          title={t('CreateOffWeek.errorTitle')}
          error={error || fetchOffPeriodsError}
        />
        <div className="text-r mt--l">
          <Btn className="mr--s" onClick={onClose}>
            {t('CreateOffWeek.closeButton')}
          </Btn>
          <Btn
            type="primary"
            loading={loading}
            onClick={handleCreate}
            disabled={!startDate || !endDate}
          >
            {t('CreateOffWeek.createButton')}
          </Btn>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_OFF_PERIOD = gql`
  mutation createOffPeriod($teamId: ID!, $offPeriodInput: OffPeriodInput!) {
    createOffPeriod(teamId: $teamId, offPeriodInput: $offPeriodInput) {
      id
      teamId
      startDate
      endDate
    }
  }
`;
