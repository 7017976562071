import { gql, useMutation } from '@apollo/client';
import { Alert, Modal, Spin } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  UpdateSprintTermDocument,
  GetTeamKeyActivitiesPreviewDocument,
  GetTeamSprintTermDocument,
  GetSprintsForPlanningDocument,
} from '../../../generated/graphql';
import { useSprintTermLazy } from '../../../hooks/useSprintTerm';
import { showNotification } from '../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../services/howweErrorParser';
import { KeyActivityModalState } from '../KeyActivitySetupPage';

import { SprintTermForm } from './components/SprintTermForm';

interface Props {
  keyActivityModalState: KeyActivityModalState | null;
  onClose: () => void;
  teamId: string;
  tenantId?: string;
}

export const EditSprintTermModal = ({
  onClose,
  teamId,
  tenantId,
  keyActivityModalState,
}: Props) => {
  const { t } = useTranslation();
  const { fetchSprintTerm, sprintTerm, loading } = useSprintTermLazy();

  const openEditSprintTermModal =
    keyActivityModalState?.type === 'EditSprintTerm' &&
    keyActivityModalState.id != null;

  useEffect(() => {
    if (
      keyActivityModalState?.type === 'EditSprintTerm' &&
      keyActivityModalState.id
    ) {
      fetchSprintTerm({
        variables: {
          teamId,
          tenantId,
          termId: keyActivityModalState.id,
        },
      });
    }
  }, [fetchSprintTerm, keyActivityModalState, teamId, tenantId]);

  const [updateSprintTerm, { error: updateError, loading: updateLoading }] =
    useMutation(UpdateSprintTermDocument, {
      onCompleted: () => {
        showNotification('success', {
          message: (
            <strong>{t('UpdateSprintTermModal.updatedSuccessfully')}</strong>
          ),
        });

        onClose();
      },
      refetchQueries: () => [
        {
          query: GetTeamKeyActivitiesPreviewDocument,
          variables: { teamId, tenantId },
        },
        {
          query: GetTeamSprintTermDocument,
          variables: { teamId, tenantId, termId: keyActivityModalState?.id },
        },
        {
          query: GetSprintsForPlanningDocument,
          variables: { teamId, includeUpcoming: true },
        },
      ],
    });

  const handleSubmit = (
    startDate: string,
    endDate: string,
    sprintInterval: number
  ) => {
    if (keyActivityModalState?.id == null) {
      throw new Error(
        'TermId is null or undefined when trying to update sprint-term'
      );
    }
    // Publish dates are same as start / end dates. In the future publish-dates will be deprecated
    updateSprintTerm({
      variables: {
        teamId,
        tenantId,
        termId: keyActivityModalState?.id,
        term: {
          startDate,
          endDate,
          sprintInterval: sprintInterval,
          publishDate: startDate,
          unpublishDate: endDate,
        },
      },
    });
  };

  const howweErrors = howweErrorParser(updateError);

  return (
    <Modal
      title={t('UpdateSprintTermModal.title')}
      confirmLoading={updateLoading}
      open={openEditSprintTermModal}
      footer={false}
      keyboard={false}
      onCancel={onClose}
      destroyOnClose //- this does not work when working with children using ant form
    >
      <Spin spinning={loading}>
        <SprintTermForm
          key={sprintTerm?.id} // Use a dynamic key in order to handle a ant form problem (formRef preserves data)
          teamId={teamId}
          onClose={onClose}
          initialValues={sprintTerm}
          handleSave={handleSubmit}
        />
      </Spin>
      {howweErrors && (
        <Alert
          type="error"
          className="mt"
          message={t('UpdateSprintTermModal.error')}
          description={
            <ul>
              {howweErrors.map((e, i) => (
                <li key={i}>{e.translation}</li>
              ))}
            </ul>
          }
        />
      )}
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_SPRINT_TERM = gql`
  mutation updateSprintTerm(
    $teamId: ID!
    $termId: ID!
    $term: MitemTermInput!
    $tenantId: ID
  ) {
    updateMitemTerm(
      teamId: $teamId
      termId: $termId
      term: $term
      tenantId: $tenantId
    ) {
      id
      sprintInterval
      startDate
      endDate
      publishDate
      unpublishDate
    }
  }
`;
