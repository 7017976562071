import { Dayjs } from 'dayjs';
import {
  AccelerationDay,
  PublishStatus,
} from '../../../../../generated/graphql';
import { isEndOfPeriod } from '../../../../../services/accelerationDayHelpers';
import { getEarliestTermStartDate } from './getEarliestTermStartDate';

type SprintTerm = {
  startDate: string;
  endDate: string;
  status: PublishStatus;
};

type Options = {
  date: Dayjs;
  accelerationDay: AccelerationDay;
  currentSprint?: {
    startDate: string;
  } | null;
  terms: SprintTerm[];
};

export const isValidDeadline = ({
  date,
  accelerationDay,
  currentSprint,
  terms,
}: Options) => {
  const currentSprintStartDateOrTerm =
    currentSprint != null
      ? currentSprint.startDate
      : getEarliestTermStartDate(terms);

  const termIntervals =
    terms.filter(
      (t) =>
        t.status === PublishStatus.ACTIVE || t.status === PublishStatus.UPCOMING
    ) ?? [];

  if (
    !termIntervals.some((term) =>
      date.isBetween(term.startDate, term.endDate, 'day', '[]')
    )
  ) {
    return false;
  }
  if (date.isBefore(currentSprintStartDateOrTerm, 'day')) return false;
  if (isEndOfPeriod(date, accelerationDay)) return true;

  return false;
};
