import { FlagOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

export const MilestoneSectionSkeleton = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flx">
        <div>
          <FlagOutlined className="TeamSectionV2__teamIcon" />
        </div>
        <div className="flx--1">
          <h3 className="mb">{t('MilestoneSection.title')}</h3>
          <Skeleton count={3} />
        </div>
      </div>
    </div>
  );
};
