import { AlignLeftOutlined, LineChartOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Btn } from './Button';

interface Props {
  showDescription: boolean;
  onClick: () => void;
}
export function GraphDescriptionToggle({ showDescription, onClick }: Props) {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={
        showDescription
          ? t('GraphDescriptionToggle.showGraph')
          : t('GraphDescriptionToggle.showDescription')
      }
    >
      <Btn
        size="small"
        onClick={onClick}
        icon={showDescription ? <LineChartOutlined /> : <AlignLeftOutlined />}
      />
    </Tooltip>
  );
}
