import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { DurationStatus } from '../../../../../generated/graphql';
import { InitiativeTag } from '../../../../initiative/InitiativeTag';
import { DefaultOptionType } from 'antd/es/select';

type Initiative = {
  name: string;
  id: string;
  domainId: { itemId: string; tenantId: string };
  tag: {
    title: string;
    iconId: string;
    colorCode: string;
  };
  metadata: {
    completedAt: string | null;
    archived: boolean;
    status: DurationStatus;
  };
};

interface Props extends SelectProps {
  initiatives: Initiative[];
  onChange?: (initiative: Initiative) => void;
}

interface Option extends DefaultOptionType {
  initiative: Initiative | null;
}

export const InitiativeSelect = ({
  onChange,
  initiatives,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const options: Option[] = [
    {
      initiative: null,
      label: <span>{t('InitiativeSelect.activeInitiatives')}</span>,
      options: initiatives
        .filter((i) => i.metadata.status === DurationStatus.ACTIVE)
        .map((i) => ({
          initiative: i,
          value: i.domainId.itemId,
          label: (
            <div>
              <InitiativeTag tag={i.tag} hideTitle className="mr" />
              {i.name}
            </div>
          ),
        })),
    },
    {
      initiative: null,
      label: <span>{t('InitiativeSelect.upcomingInitiatives')}</span>,
      options: initiatives
        .filter((i) => i.metadata.status === DurationStatus.UPCOMING)
        .map((i) => ({
          initiative: i,
          value: i.domainId.itemId,
          label: (
            <div>
              <InitiativeTag tag={i.tag} hideTitle className="mr" />
              {i.name}
            </div>
          ),
        })),
    },
  ];

  return (
    <Select
      {...props}
      showSearch
      onChange={(itemId) => {
        const initiative = initiatives.find(
          (i) => i.domainId.itemId === itemId
        );
        if (initiative) {
          onChange?.(initiative);
        }
      }}
      options={options}
      filterOption={(input, option) => {
        if (!option?.initiative) return false;

        return (
          option.initiative.name.toLowerCase().includes(input.toLowerCase()) ||
          option.initiative.tag.title
            .toLowerCase()
            .includes(input.toLowerCase())
        );
      }}
    />
  );
};
