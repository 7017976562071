import React, { FC } from 'react';
import './CurveEditorPeriodAction.less';
import cx from 'classnames';
import { Btn } from '../../../../../Button';
interface Props {
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  toggled: boolean;
  icon: React.ReactNode;
}

export const CurveEditorPeriodAction: FC<Props> = ({
  onClick,
  toggled,
  icon,
}) => {
  return (
    <Btn
      type="text"
      onClick={onClick}
      className={cx('CurveEditorPeriodAction', {
        active: toggled,
      })}
      icon={icon}
    ></Btn>
  );
};
