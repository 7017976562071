import { useMutation } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArchiveInitiativeDocument,
  InitiativeContentFragment,
} from '../../../../../../../../generated/graphql';
import { showNotification } from '../../../../../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../../../../../services/howweErrorParser';

interface Props {
  initiative: InitiativeContentFragment;
}

export const ArchiveInitiative = ({ initiative }: Props) => {
  const { t } = useTranslation();

  let howweErrors;

  const [archiveInitiative] = useMutation(ArchiveInitiativeDocument, {
    onCompleted: (data) => {
      showNotification('success', {
        message: (
          <strong>
            {data.editInitiative.archived
              ? t('InitiativeNotifications.archiveInitiative', {
                  name: data.editInitiative.name,
                })
              : t('InitiativeNotifications.reactivateInitiative', {
                  name: data.editInitiative.name,
                })}
          </strong>
        ),
      });
    },
    onError: (e) => {
      howweErrors = howweErrorParser(e);

      showNotification('error', {
        message: (
          <strong>
            <ul>
              {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
            </ul>
          </strong>
        ),
      });
    },
  });

  const handleArchiveInitiative = () => {
    archiveInitiative({
      variables: {
        initiativeId: initiative.domainId.itemId,
        initiative: { archived: !initiative.archived, rev: initiative.rev },
        teamId: initiative.domainId.teamId,
      },
    });
  };

  return (
    <div onClick={handleArchiveInitiative}>
      {initiative.archived ? t('common.reactivate') : t('common.archive')}
    </div>
  );
};
