import { useState } from 'react';
import { AccMeetingWeeklyKaCommitmentsFragment } from '../../../../../../generated/graphql';
import {
  getCommitmentStrategies,
  getInitialStrategy,
} from '../accelerationMeetingContainer/accelerationMeeting/commitmentStrategyUtils';
import {
  AkpiCommitments,
  MemberCommitment,
} from '../AccelerationMeetingProvider';
import { Strategy } from '../accelerationMeetingContainer/meetingSteps/AkpiCommitment/strategyUtils';

interface Params {
  isNewMeeting: boolean;
  members: { id: string }[];
  weeklyKeyActivities: {
    id: string;
    periodData: {
      goal: number;
      timePeriodIndex: number;
      actual?: number | null;
    }[];
    currentPeriodIndex: number | null;
  }[];
  weeklyKaCommitmentsDTO: AccMeetingWeeklyKaCommitmentsFragment[];
  markAsDirty: () => void;
}

export const useWeeklyCommitments = ({
  isNewMeeting,
  members,
  weeklyKeyActivities,
  weeklyKaCommitmentsDTO,
  markAsDirty,
}: Params) => {
  const [weeklyKaCommitments, setWeeklyKeyActivityCommitments] =
    useState<AkpiCommitments>(() =>
      isNewMeeting
        ? setInitialStateForCommitmentsInNewMeeting(
            members,
            weeklyKeyActivities
          )
        : setInitialStateForCommitmentsInOldMeeting(
            weeklyKeyActivities,
            weeklyKaCommitmentsDTO
          )
    );

  const setWeeklyKaMemberCommitment = (
    weeklyKaId: string,
    userId: string,
    commitment: { value: string; note: string }
  ) => {
    const newState: AkpiCommitments = {
      ...weeklyKaCommitments,
      [weeklyKaId]: {
        ...weeklyKaCommitments[weeklyKaId],
        memberCommitments: {
          ...weeklyKaCommitments[weeklyKaId].memberCommitments,
          ...{ [userId]: commitment },
        },
      },
    };
    markAsDirty();

    setWeeklyKeyActivityCommitments(newState);
  };

  const setWeeklyKaStrategy = (weeklyKaId: string, strategy: Strategy) => {
    const newState: AkpiCommitments = {
      ...weeklyKaCommitments,
      [weeklyKaId]: {
        ...weeklyKaCommitments[weeklyKaId],
        selectedStrategy: strategy,
      },
    };

    markAsDirty();
    setWeeklyKeyActivityCommitments(newState);
  };

  return {
    weeklyKaCommitments,
    setWeeklyKaMemberCommitment,
    setWeeklyKaStrategy,
  };
};

/** set initial commitment state for a NEW meeting */
export function setInitialStateForCommitmentsInNewMeeting(
  members: { id: string }[],
  weeklyKeyActivities: {
    id: string;
    periodData: {
      goal: number;
      timePeriodIndex: number;
      actual?: number | null;
    }[];
    currentPeriodIndex: number | null;
  }[]
) {
  let state: AkpiCommitments = {};

  for (const weeklyKeyActivity of weeklyKeyActivities) {
    const memberCommits = members.reduce(
      (acc, m) => ({ ...acc, [m.id]: { value: '', note: '' } }),
      {} as MemberCommitment
    );

    state[weeklyKeyActivity.id] = {
      akpiId: weeklyKeyActivity.id,
      strategies: getCommitmentStrategies(
        weeklyKeyActivity.periodData,
        weeklyKeyActivity.currentPeriodIndex
      ),
      selectedStrategy: getInitialStrategy(
        weeklyKeyActivity.periodData,
        weeklyKeyActivity.currentPeriodIndex
      ),
      memberCommitments: memberCommits,
    };
  }

  return state;
}

/** set initial commitment state when EDITING an OLD meeting */
export function setInitialStateForCommitmentsInOldMeeting(
  weeklyKeyActivities: {
    id: string;
    periodData: {
      goal: number;
      timePeriodIndex: number;
      actual?: number | null;
    }[];
    currentPeriodIndex: number | null;
  }[],
  weeklyKeyActivityDTOCommitments: AccMeetingWeeklyKaCommitmentsFragment[]
) {
  const initialState: AkpiCommitments = {};
  for (const ac of weeklyKeyActivityDTOCommitments) {
    const currentKeyActivity = weeklyKeyActivities?.find(
      (a) => a.id === ac.accelerationKpiId
    );
    if (currentKeyActivity == null) {
      // this shouldn't be possible
      continue;
    }

    initialState[ac.accelerationKpiId] = {
      akpiId: ac.accelerationKpiId,
      selectedStrategy: Strategy.ALREADY_ON_TRACK,
      strategies: getCommitmentStrategies(
        currentKeyActivity.periodData,
        currentKeyActivity.currentPeriodIndex
      ),
      memberCommitments: ac.commitments.reduce(
        (acc, c) => ({
          ...acc,
          [c.userId]: {
            value: c.quantity?.toString() ?? '',
            note: c.note ?? '',
          },
        }),
        {} as MemberCommitment
      ),
    };
  }

  return initialState;
}
