import React from 'react';
import './BetaBadge.less';

interface Props {
  className?: string;
}

export const BetaBadge = ({ className = '' }: Props) => {
  return <span className={'BetaBadge ' + className}>Beta</span>;
};
