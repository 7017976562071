import { GantishChart } from './components/GantishChart';

import './Gantish.less';
import { GantishLegend } from './components/GantishLegend';
import { useTranslation } from 'react-i18next';

export const GantishSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className="Gantish">
      <h3>{t('Gantish.title')}</h3>
      <div className="flx">
        <GantishLegend.Skeleton />
        <div className="Gantish__chart">
          <GantishChart.Skeleton />
        </div>
      </div>
    </div>
  );
};
