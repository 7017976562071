import { useTranslation } from 'react-i18next';
import { OverviewStatCard } from './initiativeOverview/OverviewStatCard';
import { CheckIcon } from '../../../../../icons/overviewIcons/CheckIcon';
import { Colors } from '../../../../componentLibrary/Colors';
import { HistoryIcon } from '../../../../../icons/overviewIcons/HistoryIcon';
import { UpcomingEventIcon } from '../../../../../icons/overviewIcons/UpcomingEventIcon';
import { gql } from '@apollo/client';
import { InitiativeOverviewFragment } from '../../../../../generated/graphql';
import { ProgressIcon } from '../../../../../icons/overviewIcons/ProgressIcon';
import { getInitiativeColor } from '../services/initiativeStatusColor';
import { InitiativesColors } from '../../../../../styleVars';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_OVERVIEW_FRAGMENT = gql`
  fragment InitiativeOverview on InitiativeReportKeyActivitiesTotalSummary {
    onTrackPercentage
    totalNumberOfActivities
    completed {
      total
    }
    overdue {
      total
    }
    planned {
      total
    }
  }
`;

type Props = { activitiesSummary?: InitiativeOverviewFragment };

export const InitiativeOverview = ({ activitiesSummary }: Props) => {
  const { t } = useTranslation();

  const progressPercentage = activitiesSummary?.onTrackPercentage;
  const progressPercentageString =
    progressPercentage && `${progressPercentage}%`;
  const totalNumberOfActivities = activitiesSummary?.totalNumberOfActivities;

  const statusColor =
    progressPercentage != null && totalNumberOfActivities != null
      ? getInitiativeColor(progressPercentage, totalNumberOfActivities)
      : InitiativesColors.neverStarted;

  return (
    <div>
      <h2
        className="mt--xl mb--l"
        data-intercom-target="Initiative overview title"
      >
        {t('InitiativeOverview.title')}
      </h2>

      <div className="flx">
        <div
          className="mr--xl flx--1 txt--white"
          data-intercom-target="Initiative total progress card"
        >
          <OverviewStatCard
            title={t('InitiativeOverview.onTrackPercentage')}
            cardBackgroundColor={statusColor}
            numberOfActivities={progressPercentageString}
            icon={{
              SvgIcon: ProgressIcon,
              color: '#FFFFFF',
            }}
          />
        </div>
        <div
          className="mr--xl flx--1"
          data-intercom-target="Initiative total done information card"
        >
          <OverviewStatCard
            title={t('InitiativeOverview.activities.done')}
            numberOfActivities={activitiesSummary?.completed.total}
            icon={{
              SvgIcon: CheckIcon,
              color: Colors.Status.OK_GREEN,
              backgroundColor: Colors.Lights.OK,
            }}
          />
        </div>
        <div
          className="mr--xl flx--1"
          data-intercom-target="Initiative total overdue information card"
        >
          <OverviewStatCard
            title={t('InitiativeOverview.activities.overdue')}
            icon={{
              SvgIcon: HistoryIcon,
              color: Colors.Status.OVERDUE,
              backgroundColor: Colors.Lights.WARNING,
            }}
            numberOfActivities={activitiesSummary?.overdue.total}
          />
        </div>
        <div
          className="flx--1"
          data-intercom-target="Initiative total planned information card"
        >
          <OverviewStatCard
            title={t('InitiativeOverview.activities.upcoming')}
            icon={{
              SvgIcon: UpcomingEventIcon,
              color: Colors.Status.FUTURE_PURPLE,
              backgroundColor: Colors.Lights.FUTURE,
            }}
            numberOfActivities={activitiesSummary?.planned.total}
          />
        </div>
      </div>
    </div>
  );
};
