import dayjs from 'dayjs';
import cx from 'classnames';
import './TeamGantishMonthCell.less';

interface Props {
  startOfMonth: dayjs.Dayjs;
  children?: React.ReactNode;
}

const today = dayjs();

export const TeamGantishMonthCell = ({ startOfMonth, children }: Props) => {
  return (
    <div
      className={cx('MonthCell', {
        'TeamGantishMonthCell--isCurrent': startOfMonth.isSame(today, 'month'),
      })}
    >
      {children}
    </div>
  );
};
