import Icon from '@ant-design/icons';

const MergeIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.26683 12L0.333496 11.0667L3.55016 7.83333C3.80572 7.57778 4.00016 7.28889 4.1335 6.96667C4.26683 6.64444 4.3335 6.30556 4.3335 5.95V2.55L3.26683 3.6L2.3335 2.66667L5.00016 0L7.66683 2.66667L6.7335 3.6L5.66683 2.55V5.95C5.66683 6.30556 5.7335 6.64444 5.86683 6.96667C6.00016 7.28889 6.19461 7.57778 6.45016 7.83333L9.66683 11.0667L8.7335 12L5.00016 8.26667L1.26683 12Z" />
  </svg>
);

export const MergeIcon = (props: any) => (
  <Icon component={MergeIconSvg} {...props} />
);
