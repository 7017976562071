import { DisplayName } from '../../../../../../../../../components/DisplayName';
import { UserAvatar } from '../../../../../../../../../components/UserAvatar';
import './AvatarColumn.less';

interface Props {
  user: {
    id: string;
    name: string | null;
    displayName: string | null;
    email: string;
    initials: string;
  };
}

export const AvatarColumn = ({ user }: Props) => {
  return (
    <div className="AvatarColumn">
      <UserAvatar
        user={user}
        style={{
          width: AVATAR_SIZE,
          height: AVATAR_SIZE,
          lineHeight: AVATAR_SIZE + 'px',
        }}
      />
      <div
        className="AvatarColumn__userName font-size--sm mt--s"
        style={{ width: AVATAR_SIZE }}
      >
        <DisplayName user={user} />
      </div>
    </div>
  );
};

const AVATAR_SIZE = 50;
