import { useState } from 'react';
import { SprintKaCommitments } from '../AccelerationMeetingProvider';

interface SprintKeyActivityCommitment {
  commitments: {
    committed: boolean;
    note: string | null;
    userId: string;
  }[];
  mitemId: string;
}

interface Params {
  isNewMeeting: boolean;
  sprintKaCommitmentsDTO: SprintKeyActivityCommitment[];
  markAsDirty: () => void;
}

export const useSprintCommitments = ({
  isNewMeeting,
  sprintKaCommitmentsDTO,
  markAsDirty,
}: Params) => {
  const [sprintKaCommitments, setSprintKaCommitments] =
    useState<SprintKaCommitments>(() =>
      isNewMeeting
        ? {}
        : setInitialStateForSprintKaCommitmentsInOldMeeting(
            sprintKaCommitmentsDTO
          )
    );

  const setSprintKaMemberCommitment = (
    sprintKaId: string,
    newCommitment: { userId: string; committed?: boolean; note?: string }
  ) => {
    const oldCommitment = sprintKaCommitments[sprintKaId]?.commitment ?? {
      userId: newCommitment.userId,
      committed: false,
      note: '',
    };
    const newState: SprintKaCommitments = {
      ...sprintKaCommitments,
      [sprintKaId]: {
        sprintKaId,
        commitment: {
          ...oldCommitment,
          ...newCommitment,
        },
      },
    };
    markAsDirty();
    setSprintKaCommitments(newState);
  };

  const toggleAllCommitments = (
    committed: boolean,
    skas: { id: string; owner: { id: string } }[]
  ) => {
    const previouslyUncommitted: SprintKaCommitments = {};
    skas.forEach((ska) => {
      previouslyUncommitted[ska.id] = {
        sprintKaId: ska.id,
        commitment: { userId: ska.owner.id, committed, note: '' },
      };
    });

    const newState: SprintKaCommitments = Object.fromEntries(
      Object.entries(sprintKaCommitments).map(([key, value]) => [
        key,
        {
          sprintKaId: value.sprintKaId,
          commitment: { ...value.commitment, committed },
        },
      ])
    );
    setSprintKaCommitments({ ...previouslyUncommitted, ...newState });
  };

  const addSprintKeyActivity = (sprintKaId: string, ownerId: string) => {
    const newState: SprintKaCommitments = {
      ...sprintKaCommitments,
      [sprintKaId]: {
        sprintKaId,
        commitment: { userId: ownerId, committed: false, note: '' },
      },
    };
    setSprintKaCommitments(newState);
  };

  const removeSprintKeyActivity = (sprintKaId: string) => {
    const newState = { ...sprintKaCommitments };
    delete newState[sprintKaId];
    setSprintKaCommitments(newState);
  };

  return {
    sprintKaCommitments,
    setSprintKaMemberCommitment,
    addSprintKeyActivity,
    removeSprintKeyActivity,
    toggleAllCommitments,
  };
};

/** set initial commitment state for when EDITING an OLD meeting */
export function setInitialStateForSprintKaCommitmentsInOldMeeting(
  commitments: SprintKeyActivityCommitment[]
) {
  let state: SprintKaCommitments = {};

  for (const commitment of commitments) {
    const commitInfo = commitment.commitments[0];
    state[commitment.mitemId] = {
      sprintKaId: commitment.mitemId,
      commitment: {
        userId: commitInfo.userId,
        committed: commitInfo.committed,
        note: commitInfo.note ?? '',
      },
    };
  }

  return state;
}
