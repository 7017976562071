import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { TenantDetailsDocument } from '../generated/graphql';
import { useMemo } from 'react';

export const useTenantDetails = (tenantId?: string) => {
  const { loading, error, data } = useQuery(TenantDetailsDocument, {
    variables: { id: tenantId },
  });

  return useMemo(
    () => ({
      tenant: data?.tenant,
      features: {
        teamInitiativesEnabled:
          (data?.tenant?.initiativesEnabled &&
            !data?.tenant?.businessPlanEnabled) ??
          false,
        tenantInitiativesEnabled:
          (data?.tenant?.businessPlanEnabled &&
            data?.tenant?.migratedToBusinessPlan) ??
          false,
      },
      error,
      loading,
    }),
    [data, error, loading]
  );
};

export const useTenantDetailsLazy = () => {
  const [fetchTenantDetails, { loading, error, data }] = useLazyQuery(
    TenantDetailsDocument
  );

  return [
    fetchTenantDetails,
    {
      tenant: data?.tenant,
      features: { hasInitiative: data?.tenant?.initiativesEnabled },
      error,
      loading,
    },
  ] as const;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TENANT_DETAILS = gql`
  query tenantDetails($id: ID) {
    tenant(id: $id) {
      archived
      name
      locale
      id
      defaultAccessLevel
      restrictedToSingleSignOn
      initiativesEnabled
      businessPlanEnabled
      migratedToBusinessPlan
      timezone
      rev
      domainId {
        tenantId
      }
      unit
    }
  }
`;
