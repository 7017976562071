import { useTranslation } from 'react-i18next';
import { PerformanceTag } from '../../../../../../../../components/PerformanceTag';
import './SprintActivityCard.less';
interface CardData {
  target: number;
  actual: number;
  upcoming: number;
  total: number;
  fulfillment: number;
}
export const SprintKeyActivityCard: React.FC<{
  cardData: CardData;
}> = (Props) => {
  const { target, actual, upcoming, fulfillment } = Props.cardData;
  const { t } = useTranslation();
  return (
    <div className="KaCard">
      <p className="KaCard__title">{t('MigDrawerCard.SKACardTitle')}</p>
      {actual + target + upcoming !== 0 ? (
        <>
          <span className="KaCard__info">
            {t('MigDrawerCard.SKACardPart1', {
              actual,
              target,
              context: actual !== 1 ? 'moreCompleted' : 'oneCompleted',
              count: target,
            })}
            {t('MigDrawerCard.SKACardPart2', {
              upcoming,
              count: upcoming,
            })}
          </span>
          <div className="KaCard__fulfillment--wrapper">
            <span>{t('MigDrawerCard.goalFulfillment')}</span>
            <PerformanceTag value={fulfillment} />
          </div>
        </>
      ) : (
        <>
          <span>{t('MigDrawerCard.SKACardNoSKAaligned')}</span>
          <div className="KaCard__fulfillment--wrapper">
            <span className="KaCard__fulfillment--emptyTarget">
              {t('MigDrawerCard.goalFulfillment')}: -
            </span>
          </div>
        </>
      )}
    </div>
  );
};
