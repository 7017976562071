import Icon from '@ant-design/icons';

const ThumbsUpIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.9974 17.5026H4.83073V6.66927L10.6641 0.835938L11.7057 1.8776C11.803 1.97483 11.8828 2.10677 11.9453 2.27344C12.0078 2.4401 12.0391 2.59983 12.0391 2.7526V3.04427L11.1224 6.66927H16.4974C16.9418 6.66927 17.3307 6.83594 17.6641 7.16927C17.9974 7.5026 18.1641 7.89149 18.1641 8.33594V10.0026C18.1641 10.0998 18.1502 10.204 18.1224 10.3151C18.0946 10.4262 18.0668 10.5304 18.0391 10.6276L15.5391 16.5026C15.4141 16.7804 15.2057 17.0165 14.9141 17.2109C14.6224 17.4054 14.3168 17.5026 13.9974 17.5026ZM6.4974 15.8359H13.9974L16.4974 10.0026V8.33594H8.9974L10.1224 3.7526L6.4974 7.3776V15.8359ZM4.83073 6.66927V8.33594H2.33073V15.8359H4.83073V17.5026H0.664062V6.66927H4.83073Z" />
  </svg>
);

export const ThumbsUpIcon = (props: any) => (
  <Icon component={ThumbsUpIconSvg} {...props} />
);
