import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { InitiativeDetails } from './InitiativeDetails';

export const InitiativeDetailsWrapper = () => {
  const { initiativeId } = useParams<{ initiativeId: string }>();

  return (
    <Suspense fallback={<InitiativeDetails.Skeleton />}>
      {initiativeId && <InitiativeDetails initiativeId={initiativeId} />}
    </Suspense>
  );
};
