import { gql, useMutation } from '@apollo/client';
import React, {
  useState,
  useContext,
  createContext,
  useRef,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import {
  AccelerationMeetingProvider_AccelerationKpiCommitmentsFragment,
  AccelerationMeetingProvider_AkpiFragment,
  AccelerationMeetingProvider_MemberFragment,
  AccelerationMeetingProvider_MitemCommitmentsFragment,
  AccelerationMeetingStatusDocument,
  CreateAccelerationMeetingDocument,
  GetTeamActivityDocument,
  ReportDrawer2Document,
  TeamKeyActivityCommitmentAndAchievementsForPeriodDocument,
  TimePeriodFilter,
  UpdateAccelerationMeetingDocument,
} from '../../../../../generated/graphql';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { stringToNumber } from '../../../../../services/stringToNumber';
import { howweErrorParser } from '../../../../../services/howweErrorParser';
import { HowweError } from '../../../../../services/error-parser';
import { useHistory } from 'react-router-dom';
import { useWeeklyCommitments } from './accelerationMeetingProvider/useWeeklyCommitments';
import { useSprintCommitments } from './accelerationMeetingProvider/useSprintCommitments';
import { Strategy } from './accelerationMeetingContainer/meetingSteps/AkpiCommitment/strategyUtils';

interface MemberSprintKaCommitment {
  userId: string;
  committed: boolean;
  note: string;
}

interface SprintKaCommitment {
  sprintKaId: string;
  commitment: MemberSprintKaCommitment;
}

export interface SprintKaCommitments {
  [sprintKaId: string]: SprintKaCommitment;
}

export interface MemberCommitment {
  [userId: string]: { value: string; note: string };
}

type AkpiStrategies = Record<Strategy, number>;

export interface AkpiMemberCommitment {
  akpiId: string;
  selectedStrategy: Strategy;
  strategies: AkpiStrategies;
  memberCommitments: MemberCommitment;
}

export interface AkpiCommitments {
  [akpiId: string]: AkpiMemberCommitment;
}

interface Context {
  isNewMeeting: boolean;
  members: AccelerationMeetingProvider_MemberFragment[];
  weeklyKeyActivityContext: {
    commitments: AkpiCommitments;
    setStrategy: (weeklyKaId: string, strategy: Strategy) => void;
    setUserCommitment: (
      weeklyKaId: string,
      userId: string,
      commitment: { value: string; note: string }
    ) => void;
  };
  sprintKeyActivityContext: {
    commitments: SprintKaCommitments;
    addSprintKeyActivity: (sprintKaId: string, ownerId: string) => void;
    removeSprintKeyActivity: (sprintKaId: string) => void;
    getUsersCommitments: (sprintKaId: string) => SprintKaCommitment;
    setUserCommitment: (
      sprintKaId: string,
      commitment: { userId: string; committed?: boolean; note?: string }
    ) => void;
    toggleAllCommitments: (
      committed: boolean,
      skas: { id: string; owner: { id: string } }[]
    ) => void;
  };
  onSubmit: (onSuccess: () => void) => void;
  submitPending: boolean;
  submitErrors?: HowweError[];
}

export const AccelerationMeetingContext = createContext<Context>({} as Context);
export const useAccelerationMeeting = () =>
  useContext(AccelerationMeetingContext);

interface Props {
  teamId: string;
  isNewMeeting: boolean;
  members: AccelerationMeetingProvider_MemberFragment[];
  sprintKaCommitmentsDTO: AccelerationMeetingProvider_MitemCommitmentsFragment[];
  weeklyKeyActivities: AccelerationMeetingProvider_AkpiFragment[];
  weeklyKaCommitmentsDTO: AccelerationMeetingProvider_AccelerationKpiCommitmentsFragment[];
  children: React.ReactNode;
}

export const AccelerationMeetingProvider: React.FC<Props> = ({
  teamId,
  isNewMeeting,
  members,
  sprintKaCommitmentsDTO,
  weeklyKeyActivities,
  weeklyKaCommitmentsDTO,
  children,
}) => {
  const { t } = useTranslation();
  const [isDirty, setIsDirty] = useState(false);
  const history = useHistory();
  const unblockRef = useRef(() => {});

  const markAsDirty = () => {
    if (!isDirty) setIsDirty(true);
  };

  const {
    weeklyKaCommitments,
    setWeeklyKaMemberCommitment,
    setWeeklyKaStrategy,
  } = useWeeklyCommitments({
    isNewMeeting,
    members,
    weeklyKeyActivities,
    weeklyKaCommitmentsDTO,
    markAsDirty,
  });

  const {
    sprintKaCommitments,
    setSprintKaMemberCommitment,
    addSprintKeyActivity,
    removeSprintKeyActivity,
    toggleAllCommitments,
  } = useSprintCommitments({
    isNewMeeting,
    sprintKaCommitmentsDTO,
    markAsDirty,
  });

  const getUsersCommitments = (sprintKaId: string) => {
    return sprintKaCommitments[sprintKaId];
  };

  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = () =>
        t('AccelerationMeetingProvider.leavingMeetingInfo');
    }
    unblockRef.current = history.block(() => {
      if (isDirty) {
        return '' + t('AccelerationMeetingProvider.leavingMeetingInfo');
      }
    });

    return () => {
      window.onbeforeunload = null;
      unblockRef.current();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const refetchQueries = [
    {
      query: TeamKeyActivityCommitmentAndAchievementsForPeriodDocument,
      variables: {
        teamId: teamId,
        timePeriodFilter: TimePeriodFilter.CURRENT,
      },
    },
    { query: ReportDrawer2Document },
    { query: GetTeamActivityDocument, variables: { teamId: teamId } },
  ];

  const [createMeeting, { loading: createPending, error: createError }] =
    useMutation(CreateAccelerationMeetingDocument, {
      refetchQueries: () => [
        ...refetchQueries,
        {
          query: AccelerationMeetingStatusDocument,
          variables: { teamId: teamId },
        },
      ],
    });

  const [updateMeeting, { loading: updatePending, error: updateError }] =
    useMutation(UpdateAccelerationMeetingDocument, {
      refetchQueries: () => refetchQueries,
    });

  const onSubmit = (onSuccess: () => void) => {
    const weeklyKeyActivityCommitments = weeklyKeyActivities.map((akpi) => {
      const commitment = weeklyKaCommitments[akpi.id];
      return {
        accelerationKpiId: akpi.id,
        commitments: members.map((m) => {
          const parsedCommitment = stringToNumber(
            commitment.memberCommitments[m.id]?.value
          );

          return {
            quantity:
              commitment.memberCommitments[m.id]?.value === ''
                ? null
                : parsedCommitment,
            note: commitment.memberCommitments[m.id]?.note,
            userId: m.id,
          };
        }),
      };
    });

    const sprintKeyActivityCommitments = Object.values(sprintKaCommitments).map(
      (skaCommitment) => {
        return {
          mitemId: skaCommitment.sprintKaId,
          commitments: [skaCommitment.commitment],
        };
      }
    );

    isNewMeeting
      ? createMeeting({
          variables: {
            accelerationMeetingInput: {
              accelerationKpiCommitments: weeklyKeyActivityCommitments,
              mitemCommitments: sprintKeyActivityCommitments,
            },
            teamId: teamId,
          },
          onCompleted() {
            showNotification('success', {
              message: (
                <strong>
                  {t('AccelerationMeetingProvider.meetingCreatedFeedback')}
                </strong>
              ),
            });

            unblockRef.current();
            onSuccess();

            (window as any).Intercom?.('update', {
              last_conducted_acceleration_meeting_at: new Date(),
            });
          },
          onError(error) {
            Sentry.captureException(error);
            const howweErrors = howweErrorParser(error);
            showNotification('error', {
              message: (
                <strong>
                  {t('AccelerationMeetingProvider.errorCreatingMeeting')}
                </strong>
              ),
              description: (
                <div>
                  {howweErrors?.map((e, i) => (
                    <div key={i}>{e.translation}</div>
                  ))}
                </div>
              ),
            });
          },
        })
      : updateMeeting({
          variables: {
            accelerationMeetingInput: {
              accelerationKpiCommitments: weeklyKeyActivityCommitments,
              mitemCommitments: sprintKeyActivityCommitments,
            },
            teamId: teamId,
          },
          onCompleted() {
            showNotification('success', {
              message: (
                <strong>
                  {t('AccelerationMeetingProvider.meetingUpdatedFeedback')}
                </strong>
              ),
            });
            unblockRef.current();
            onSuccess();
          },
          onError(error) {
            Sentry.captureException(error);
            const howweErrors = howweErrorParser(error);
            showNotification('error', {
              message: (
                <strong>
                  {t('AccelerationMeetingProvider.errorCreatingMeeting')}
                </strong>
              ),
              description: (
                <div>
                  {howweErrors?.map((e, i) => (
                    <div key={i}>{e.translation}</div>
                  ))}
                </div>
              ),
            });
          },
        });
  };

  const howweErrors =
    howweErrorParser(createError) || howweErrorParser(updateError);

  return (
    <AccelerationMeetingContext.Provider
      value={{
        isNewMeeting,
        members,
        weeklyKeyActivityContext: {
          commitments: weeklyKaCommitments,
          setStrategy: setWeeklyKaStrategy,
          setUserCommitment: setWeeklyKaMemberCommitment,
        },
        sprintKeyActivityContext: {
          commitments: sprintKaCommitments,
          addSprintKeyActivity: addSprintKeyActivity,
          removeSprintKeyActivity: removeSprintKeyActivity,
          setUserCommitment: setSprintKaMemberCommitment,
          getUsersCommitments: getUsersCommitments,
          toggleAllCommitments: toggleAllCommitments,
        },
        onSubmit,
        submitPending: createPending || updatePending,
        submitErrors: howweErrors,
      }}
    >
      {children}
    </AccelerationMeetingContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACCELERATION_MEETING_PROVIDER_MEMBER_FRAGMENT = gql`
  fragment AccelerationMeetingProvider_Member on User {
    id
    ...AkpiCommitment_User
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACCELERATION_MEETING_PROVIDER_WEEKLY_KEY_ACTIVITY_FRAGMENT = gql`
  fragment AccelerationMeetingProvider_Akpi on Akpi {
    id
    periodData {
      goal
      timePeriodIndex
      actual
    }
    currentPeriodIndex
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WEEKLY_KEY_ACTIVITY_COMMITMENTS_FRAGMENT = gql`
  fragment AccelerationMeetingProvider_AccelerationKpiCommitments on AccelerationKpiCommitments {
    accelerationKpiId
    commitments {
      quantity
      userId
      note
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_COMMITMENTS_FRAGMENT = gql`
  fragment AccelerationMeetingProvider_MitemCommitments on MitemCommitments {
    commitments {
      committed
      note
      userId
    }
    mitemId
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_ACCELERATION_MEETING = gql`
  mutation createAccelerationMeeting(
    $teamId: ID!
    $accelerationMeetingInput: AccelerationMeetingInput!
  ) {
    createAccelerationMeeting2(
      teamId: $teamId
      accelerationMeetingInput: $accelerationMeetingInput
    ) {
      accelerationKpiCommitments {
        ...AccMeetingWeeklyKaCommitments
      }
      id
      created
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_ACCELERATION_MEETING = gql`
  mutation updateAccelerationMeeting(
    $teamId: ID!
    $accelerationMeetingInput: AccelerationMeetingInput!
  ) {
    updateAccelerationMeeting2(
      teamId: $teamId
      accelerationMeetingInput: $accelerationMeetingInput
    ) {
      accelerationKpiCommitments {
        ...AccMeetingWeeklyKaCommitments
      }
      id
      created
    }
  }
`;
