import Icon from '@ant-design/icons';

const MapOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 12L4 10.6L0.9 11.8C0.677778 11.8889 0.472222 11.8639 0.283333 11.725C0.0944445 11.5861 0 11.4 0 11.1667V1.83333C0 1.68889 0.0416667 1.56111 0.125 1.45C0.208333 1.33889 0.322222 1.25556 0.466667 1.2L4 0L8 1.4L11.1 0.2C11.3222 0.111111 11.5278 0.136111 11.7167 0.275C11.9056 0.413889 12 0.6 12 0.833333V10.1667C12 10.3111 11.9583 10.4389 11.875 10.55C11.7917 10.6611 11.6778 10.7444 11.5333 10.8L8 12ZM7.33333 10.3667V2.56667L4.66667 1.63333V9.43333L7.33333 10.3667ZM8.66667 10.3667L10.6667 9.7V1.8L8.66667 2.56667V10.3667ZM1.33333 10.2L3.33333 9.43333V1.63333L1.33333 2.3V10.2Z" />
  </svg>
);

export const MapOutlineIcon = (props: any) => (
  <Icon component={MapOutlineIconSvg} {...props} />
);
