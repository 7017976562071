import { Colors } from '../appPages/componentLibrary/Colors';
import { roundToFloor } from './roundNumbersHelper';

export const statusColorHelper = (value?: number | null) => {
  const roundedValue = roundToFloor(value);
  let color;

  if (roundedValue == null) {
    color = Colors.Grays.DISABLED_FONTS;
  } else if (roundedValue >= 100) {
    color = Colors.Status.OK_GREEN;
  } else if (roundedValue >= 80) {
    color = Colors.Status.WARNING_ORANGE;
  } else if (roundedValue < 80) {
    color = Colors.Status.ERROR_RED;
  } else {
    color = Colors.Grays.DISABLED_FONTS;
  }

  return color;
};
