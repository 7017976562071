import { Modal } from 'antd';
import React, { Suspense, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  SprintKeyActivityDetails,
  SprintKeyActivityDetailsWrapper,
} from '../../appPages/company/initiatives_old/initiativesPageV1/initiativeContent/activityProgress/SprintKeyActivityDetails';

type ActiveModalType = {
  activityId: string;
  teamId: string;
};

export const KeyActivityDetailsAndReportModal = () => {
  const location = useLocation();
  const history = useHistory();

  const [activeModal, setActiveModal] = useState<ActiveModalType | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const openActivityDetails = params.get('openActivityDetails');
    const activityId = params.get('activityId');
    const teamId = params.get('teamId');

    if (openActivityDetails === 'true' && activityId && teamId) {
      setActiveModal({
        activityId,
        teamId,
      });
    }
  }, [location.search]);

  const handleCloseModal = () => {
    setActiveModal(null);

    // Remove query params and navigate to the base route
    history.replace(location.pathname);
  };

  if (!activeModal) {
    return null;
  }

  return (
    <div>
      <Modal
        open={!!activeModal}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        footer={null}
        modalRender={() => (
          <Suspense
            fallback={
              <SprintKeyActivityDetails.skeleton onClose={handleCloseModal} />
            }
          >
            <SprintKeyActivityDetailsWrapper
              skaId={activeModal.activityId}
              teamId={activeModal.teamId}
              onClose={handleCloseModal}
            />
          </Suspense>
        )}
      />
    </div>
  );
};
