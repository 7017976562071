import Icon from '@ant-design/icons';

const SendOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 11.3333V0.666626L12.6667 5.99996L0 11.3333ZM1.33333 9.33329L9.23333 5.99996L1.33333 2.66663V4.99996L5.33333 5.99996L1.33333 6.99996V9.33329Z" />
  </svg>
);

export const SendOutlineIcon = (props: any) => (
  <Icon component={SendOutlineIconSvg} {...props} />
);
