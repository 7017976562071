import dayjs, { Dayjs } from 'dayjs';
import { TeamGantishMonthSlices } from './TeamGantishMonthSlices';
import './TeamGantishChartRow.less';
import { TeamGantishMonthCell } from './TeamGantishMonthCell';
import { TeamGantishChartRowSkeleton } from './TeamGantishChartRow.Skeleton';
import { gql } from '@apollo/client';
import { TeamGantishChartRow__MilestoneFragment } from '../../../../../../../generated/graphql';

export const TeamGantishChartRow = ({
  initiativeDates,
  milestones,
  months,
  window,
}: {
  initiativeDates: {
    startAt: Dayjs;
    endAt: Dayjs;
  } | null;
  milestones: TeamGantishChartRow__MilestoneFragment[];
  months: dayjs.Dayjs[];

  window: {
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  };
}) => {
  return (
    <div>
      <div className="TeamGantishChartRow gantishShared-AppendAnimation ">
        {months.map((m, i) => (
          <TeamGantishMonthCell key={i} startOfMonth={m}>
            <TeamGantishMonthSlices
              startOfMonth={m}
              initiativeDates={initiativeDates}
              milestones={milestones}
              window={window}
            />
          </TeamGantishMonthCell>
        ))}
      </div>
    </div>
  );
};

TeamGantishChartRow.Skeleton = TeamGantishChartRowSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Team_Gantish_Chart_Row__MILESTONES = gql`
  fragment TeamGantishChartRow__Milestone on Milestone {
    id
    ...TeamGantishLegendRow__Milestone
    ...TeamGantishMonthSlices__Milestone
  }
`;
