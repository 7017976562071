import { useEffect, ReactNode, useState, useRef } from 'react';
import { Layout } from 'antd';
import './accelerationMeeting.less';

import { useWizardNavigation } from '../../../../../../components/WizardNavigationProvider';
import { useAccelerationMeeting } from '../AccelerationMeetingProvider';
import { MeetingNavigationBar } from './MeetingNavigationBar';

const { Content } = Layout;

interface Props {
  hasKeyActivities: boolean;
  defaultOnClose: () => void;
  setTitle: (title: ReactNode) => void;
}

export const AccelerationMeeting = ({
  defaultOnClose,
  setTitle,
  hasKeyActivities,
}: Props) => {
  const { activeStep } = useWizardNavigation();
  const { isNewMeeting } = useAccelerationMeeting();
  const [expanded, setExpanded] = useState(false);
  const stepTitle = activeStep.title;

  useEffect(() => {
    setTitle(stepTitle);
  }, [stepTitle, setTitle]);

  const topRef = useRef<HTMLDivElement>(null);

  const handleClickScroll = () => {
    topRef?.current?.scrollIntoView(true);
  };

  return (
    <div className="AccelerationMeeting">
      <div ref={topRef}>
        <Content
          className="AccelerationMeeting_content content"
          style={{ padding: 16 }}
        >
          {activeStep.component()}
        </Content>
      </div>

      <MeetingNavigationBar
        expanded={expanded}
        setExpanded={setExpanded}
        isNewMeeting={isNewMeeting}
        defaultOnClose={defaultOnClose}
        onStepChange={handleClickScroll}
        hasKeyActivities={hasKeyActivities}
      />
    </div>
  );
};
