import { gql, useQuery } from '@apollo/client';
import { GetClosestPeriodsDocument } from '../generated/graphql';

export const useClosestPeriods = (teamId: string, tenantId?: string) => {
  const { data, loading, error } = useQuery(GetClosestPeriodsDocument, {
    variables: {
      teamId,
      tenantId,
    },
  });

  const currentPeriod = data?.closestPeriods.currentTimePeriod;
  const lastFinishedPeriod = data?.closestPeriods.lastFinishedTimePeriod;

  return {
    currentPeriod,
    lastFinishedPeriod,
    error,
    loading,
  };
};

// This is used indirectly, `GetClosestPeriodsDocument` is generated from it
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_CLOSEST_PERIODS = gql`
  query getClosestPeriods($teamId: ID!, $tenantId: ID) {
    closestPeriods(teamId: $teamId, tenantId: $tenantId) {
      currentTimePeriod {
        timePeriodStartDate
        timePeriodEndDate
      }
      lastFinishedTimePeriod {
        timePeriodStartDate
        timePeriodEndDate
      }
    }
  }
`;
