import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';

export const GlobalMigSectionError = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flx flx--jc-space-between">
        <h2>{t('GlobalMigSection.title')}</h2>
      </div>

      <div className="mt">
        <Alert
          message={<h4>{t('GlobalMigSection.fetchError')}</h4>}
          description={t('GlobalMigSection.fetchErrorDetails')}
          type="error"
        />
      </div>
    </div>
  );
};
