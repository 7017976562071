import { InputNumber, Slider } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: number | null;
  onChange: (value: number | null) => void;
}

export const WeightedMigSlider = ({ onChange, value }: Props) => {
  const { t } = useTranslation();
  const weight = value ?? 1;
  return (
    <>
      {t('WeightedMigSlider.title')}
      <div className="flx fullWidth">
        <Slider
          min={0.1}
          max={2}
          className="flx--1"
          style={{ maxWidth: 250 }}
          onChange={onChange}
          step={0.05}
          value={weight}
        />

        <InputNumber
          min={0.1}
          max={2}
          style={{ margin: '0 16px' }}
          value={weight}
          onChange={onChange}
        />
      </div>
    </>
  );
};
