import dayjs from 'dayjs';
import {
  MigCollapsibleCard_MigV2Fragment,
  MigStatus,
} from '../../../../../../generated/graphql';
import { getMigWarnings } from '../../../../../../services/getMigWarnings';
import { CollapseCard } from '../../collapseCard/CollapseCard';
import { MigHeaderContent } from './MigHeaderContent';
import cx from 'classnames';
import { MigGraph } from './MigGraph';
import { MigDetails } from './MigDetails';
import { useImageSaver } from '../../../../../../hooks/useImageSaver';
import './MigCollapsibleCard.less';
import { gql } from '@apollo/client';

interface Props {
  mig: MigCollapsibleCard_MigV2Fragment;
  teamInfo?: {
    id: string;
    name: string;
  };
  isExpanded: boolean;
  onToggleExpand: () => void;
  actions?: React.ReactNode;
  status?: MigStatus;
  loading?: boolean;
}

export const MigCollapsibleCard = ({
  mig,
  isExpanded,
  teamInfo,
  onToggleExpand,
  status,
  loading,
  actions,
}: Props) => {
  const { ref, SaveImageButton } = useImageSaver(
    `MIG - ${teamInfo?.name ?? mig.team.name} - ${mig.name}`
  );

  let numberOfWarnings = 0;

  const isMirrored = mig.domainId.teamId !== teamInfo?.id;

  if (!isMirrored) {
    numberOfWarnings = getMigWarnings(
      mig.periodData,
      mig.timePeriodInterval,
      dayjs()
    ).length;
  }

  return (
    <div ref={ref} className="mb--l">
      <CollapseCard
        key={mig.id}
        loading={loading}
        className={cx({
          'MigCollapsibleCard__headerBorder--missingData':
            numberOfWarnings > 0 && !loading,
          'MigCollapsibleCard__headerBorder--upcomingMig':
            status === MigStatus.UPCOMING,
          'MigCollapsibleCard__headerBorder--endedMig':
            status === MigStatus.ENDED,
          'MigCollapsibleCard__headerBorder--archivedMig':
            status === MigStatus.ARCHIVED,
        })}
        onClick={onToggleExpand}
        expanded={isExpanded}
        headerContent={
          <MigHeaderContent
            mig={mig}
            isMirrored={isMirrored}
            warnings={numberOfWarnings}
            expanded={isExpanded}
            actions={actions}
          />
        }
      >
        <div className="borderRight">
          <MigGraph mig={mig} actions={<SaveImageButton />} />
        </div>
        <MigDetails mig={mig} />
      </CollapseCard>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIG_COLLAPSIBLE_CARD_MIG_V2 = gql`
  fragment MigCollapsibleCard_MigV2 on MigV2 {
    id
    name
    status
    timePeriodInterval
    domainId {
      itemId
      teamId
      tenantId
    }
    team {
      id
      name
      teamHierarchy {
        id
        name
        tenantId
      }
    }
    periodData {
      targetDate
      actual
      goal
      historic
    }
    supportedByMigs {
      id
      name
      domainId {
        itemId
        teamId
        tenantId
      }
      team {
        id
        name
        teamHierarchy {
          id
          name
          tenantId
        }
      }
    }
    ...MigGraph_MigV2
    ...MigDetails_MigV2
    ...MigHeaderContent_MigV2
  }
`;
