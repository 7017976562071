import Table, { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '../../../../../icons/overviewIcons/CheckIcon';
import { Colors } from '../../../../componentLibrary/Colors';
import { WarningIcon } from '../../../../../icons/performanceIcons/WarningIcon';
import { UpcomingEventIcon } from '../../../../../icons/overviewIcons/UpcomingEventIcon';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetTeamForTeamContributionTableDocument,
  TeamContributionTable_InitiativeDetailedReportResponseFragment,
  TeamContributionTable_TeamFragment,
} from '../../../../../generated/graphql';
import { Unboxed } from '../../../../../services/typeHelpers';
import { Link } from 'react-router-dom';
import { ExternalLinkIcon } from '../../../../../components/Navigation/assets/ExternalLinkIcon';

interface Props {
  initiativeReport: TeamContributionTable_InitiativeDetailedReportResponseFragment;
}

export const TeamContributionTable = ({ initiativeReport }: Props) => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(GetTeamForTeamContributionTableDocument);

  const teamTreeData = treeBuilder(data.teams, initiativeReport.teamStats);

  const columns: ColumnsType<Unboxed<typeof teamTreeData>> = [
    {
      title: t('common.team'),
      dataIndex: 'name',
      key: 'id',
      render: (_, record) => {
        return (
          <>
            <div className="flx flx--column">
              <div>
                {record.team.name}
                <Link to={`/team/${record.team.id}`} target="_blank">
                  <ExternalLinkIcon className="font-size--sm ml--s" />
                </Link>
              </div>
              {record.numberOfChildrenContributing > 0 && (
                <div className="txt--secondary font-size--sm">
                  {t('TeamContributionTable.teamsBelowContributing', {
                    teams: record.numberOfChildrenContributing,
                  })}
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: (
        <div className="flx flx--jc-center">
          <CheckIcon style={{ fontSize: 15, color: Colors.Status.OK_GREEN }} />
        </div>
      ),
      key: 'Completed',
      width: 90,
      render: (_, record) => {
        return (
          <div className="flx flx--column flx--ai-center">
            {record.contributionSummary?.completed ?? '--'}
            {record?.children && record.children.length > 0 && (
              <div className="txt--secondary font-size--sm">
                {record.childContributionSummary.completed}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flx flx--jc-center">
          <WarningIcon
            style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
            className="space--r"
          />
        </div>
      ),
      key: 'Overdue',
      width: 90,

      render: (_, record) => {
        return (
          <div className="flx flx--column flx--ai-center">
            {record.contributionSummary?.overdue ?? '--'}
            {record?.children && record.children.length > 0 && (
              <div className="txt--secondary font-size--sm">
                {record.childContributionSummary.overdue}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flx flx--jc-center">
          <UpcomingEventIcon
            style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
            className="space--r"
          />
        </div>
      ),
      key: 'upcoming',
      width: 90,
      render: (_, record) => {
        return (
          <div className="flx flx--column flx--ai-center">
            {record.contributionSummary?.upcoming ?? '--'}
            {record?.children && record.children.length > 0 && (
              <div className="txt--secondary font-size--sm">
                {record.childContributionSummary.upcoming}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey="id"
      pagination={false}
      dataSource={teamTreeData}
      expandable={{
        expandRowByClick: true,
        defaultExpandedRowKeys: teamTreeData.map((t) => t.id),
      }}
    />
  );
};

type ContributionStats = {
  completed: number;
  overdue: number;
  upcoming: number;
};

type TeamContributionData = {
  id: string;
  team: TeamContributionTable_TeamFragment;
  contributionSummary: ContributionStats | null;
  childContributionSummary: ContributionStats;
  teamIsContributing: boolean;
  numberOfChildrenContributing: number;
  children: TeamContributionData[] | null;
};

const treeBuilder = (
  teams: TeamContributionTable_TeamFragment[],
  contributingTeamStats: TeamContributionTable_InitiativeDetailedReportResponseFragment['teamStats'],
  parentTeamId?: string
): TeamContributionData[] => {
  if (!teams) {
    return [];
  }
  const relevantTeams = teams.filter((t) => t.parentTeamId == parentTeamId);

  const contributors: TeamContributionData[] = [];

  for (const t of relevantTeams) {
    let children: TeamContributionData[] | null = treeBuilder(
      teams,
      contributingTeamStats,
      t.id
    );

    const accumulatedStats = aggregateContributionData(children);

    const numberOfChildrenContributing = children.reduce((acc, child) => {
      return (
        acc +
        (child.numberOfChildrenContributing || 0) +
        (child.teamIsContributing ? 1 : 0)
      );
    }, 0);

    const teamContributions =
      contributingTeamStats.find((s) => s.team.domainId.itemId === t.id)
        ?.activitiesStats ?? null;

    if (children.length === 0) {
      children = null;
    }

    if (teamContributions != null || (children && children.length > 0)) {
      contributors.push({
        id: t.id,
        team: t,
        contributionSummary: teamContributions,
        teamIsContributing: teamContributions != null,
        childContributionSummary: accumulatedStats,
        numberOfChildrenContributing,
        children,
      });
    }
  }

  return contributors;
};

/**
 * Aggregates contribution data from an array of TeamContributionData objects.
 *
 * @param children - The array of TeamContributionData objects to aggregate.
 * @returns An object containing the aggregated contribution data with properties for completed, overdue, and upcoming.
 */
function aggregateContributionData(children: TeamContributionData[]) {
  return children.reduce(
    (acc, currChild) => {
      return {
        completed:
          acc.completed +
          currChild.childContributionSummary.completed +
          (currChild.contributionSummary?.completed ?? 0),
        overdue:
          acc.overdue +
          currChild.childContributionSummary.overdue +
          (currChild.contributionSummary?.overdue ?? 0),
        upcoming:
          acc.upcoming +
          currChild.childContributionSummary.upcoming +
          (currChild.contributionSummary?.upcoming ?? 0),
      };
    },
    { completed: 0, overdue: 0, upcoming: 0 }
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAMS_TEAM_CONTRIBUTION_TABLE = gql`
  query getTeamForTeamContributionTable {
    teams(teamFilter: { active: true }) {
      ...TeamContributionTable_Team
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_FRAGMENT = gql`
  fragment TeamContributionTable_Team on Team {
    name
    id
    created
    umbrellaTeam
    parentTeamId
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_CONTRIBUTION_TABLE = gql`
  fragment TeamContributionTable_InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    teamStats {
      id
      domainId {
        tenantId
        itemId
      }
      activitiesStats {
        completed
        overdue
        upcoming
        total
      }
      team {
        id
        domainId {
          itemId
        }
        name
      }
    }
  }
`;
