import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { Moment } from 'moment';
import { momentToDayjs } from './dateHelpers';

export const FRIENDLY_DATE_FORMAT = 'MMM DD, YYYY';
export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const printPeriodRange = (
  startDate?: string | Dayjs | null,
  endDate?: string | Dayjs | null
) => {
  startDate = startDate ? friendlyDate(startDate) : null;
  endDate = endDate ? friendlyDate(endDate) : null;

  if (!startDate && !endDate) return '';
  if (!startDate && endDate) return ` - ${endDate}`;
  if (startDate && !endDate) return `${startDate} - `;

  return `${startDate} - ${endDate}`;
};

export const friendlyDate = (
  date: Dayjs | string | null | undefined,
  options?: { alwaysIncludeYear?: boolean; emptyString?: string }
) => {
  if (!date) return options?.emptyString ?? '-';

  date = typeof date === 'string' ? dayjs(date) : date;
  const today = dayjs();

  const formatter =
    date.isSame(today, 'year') && !options?.alwaysIncludeYear
      ? 'MMM DD'
      : FRIENDLY_DATE_FORMAT;
  return dayjs(date).format(formatter);
};

export const standardDateFormat = (date: Dayjs | Moment | string) => {
  const dateToBeFormatted =
    typeof date === 'string'
      ? dayjs(date)
      : moment.isMoment(date)
        ? momentToDayjs(date)
        : date;
  return dateToBeFormatted.format(API_DATE_FORMAT);
};

export const utcTimeFormat = (date: Dayjs | string) => {
  const dateToBeFormatted = typeof date === 'string' ? dayjs(date) : date;
  return dayjs(dateToBeFormatted).utc().format();
};
