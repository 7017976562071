import Icon from '@ant-design/icons';

const VideoCamIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.66683 11.3333C1.30016 11.3333 0.986385 11.2028 0.725496 10.942C0.464163 10.6806 0.333496 10.3666 0.333496 9.99996V1.99996C0.333496 1.63329 0.464163 1.31951 0.725496 1.05863C0.986385 0.797293 1.30016 0.666626 1.66683 0.666626H9.66683C10.0335 0.666626 10.3475 0.797293 10.6088 1.05863C10.8697 1.31951 11.0002 1.63329 11.0002 1.99996V4.99996L13.6668 2.33329V9.66663L11.0002 6.99996V9.99996C11.0002 10.3666 10.8697 10.6806 10.6088 10.942C10.3475 11.2028 10.0335 11.3333 9.66683 11.3333H1.66683ZM1.66683 9.99996H9.66683V1.99996H1.66683V9.99996ZM5.66683 8.66663L6.50016 6.83329L8.3335 5.99996L6.50016 5.16663L5.66683 3.33329L4.8335 5.16663L3.00016 5.99996L4.8335 6.83329L5.66683 8.66663Z" />
  </svg>
);

export const VideoCamIcon = (props: any) => (
  <Icon component={VideoCamIconSvg} {...props} />
);
