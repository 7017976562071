import { createIncludeListFeatureFlagHook } from './createFeatureFlagHook';

/* Remember to remove theses hooks and related usages
 * after the feature has reach GA and is confirmed stable.
 *
 * Also remember to remove env var keys specified in the FE server and envConf.ts when removing a hook below.
 */

export const useActiveTeamGraphFeatureFlag = createIncludeListFeatureFlagHook(
  {
    GaKey: 'ACTIVE_TEAMS_GRAPH_GA',
    TenantsKey: 'ACTIVE_TEAMS_GRAPH_TENANTS',
  },
  true // tenantSetting will override users tenant setting
);

export const useCompleteInitiativeFeatureFlag =
  createIncludeListFeatureFlagHook(
    {
      GaKey: 'COMPLETE_INITIATIVE_GA',
      TenantsKey: 'COMPLETE_INITIATIVE_TENANTS',
    },
    true // tenantSetting will override users tenant setting
  );

export const useInitiativePageAllowEditKeyActivityFeatureFlag =
  createIncludeListFeatureFlagHook(
    {
      GaKey: 'INITIATIVE_PAGE_ALLOW_EDIT_KEY_ACTIVITY_GA',
      TenantsKey: 'INITIATIVE_PAGE_ALLOW_EDIT_KEY_ACTIVITY_TENANTS',
    },
    true // tenantSetting will override users tenant setting
  );

export const useShowInitiativeBetaFeatureFlag =
  createIncludeListFeatureFlagHook(
    {
      GaKey: 'INITIATIVE_BETA_FLAG_GA',
      TenantsKey: 'INITIATIVE_BETA_FLAG_TENANTS',
    },
    true // tenantSetting will override users tenant setting
  );

export const useAiDemoFeatureFlag = createIncludeListFeatureFlagHook(
  {
    GaKey: 'AI_DEMO_GA',
    TenantsKey: 'AI_DEMO_TENANTS',
  },
  true // tenantSetting will override users tenant setting
);

export const useAiSuggestFeatureFlag = createIncludeListFeatureFlagHook({
  GaKey: 'AI_SUGGEST_GA',
  TenantsKey: 'AI_SUGGEST_TENANTS',
});
