import { stringSort } from '../services/stringSort';
import { useMe } from './useMe';

export const useMyTeams = () => {
  const { data, loading, error } = useMe();

  const teams = data?.me.teamAssociations ?? [];

  // we shallow clone `teams` to prevent the sort from mutating `teamAssociations` in the gql cache
  const sortedTeams = [...teams].sort((a, b) => {
    const levelDelta =
      a.team.teamHierarchy.length - b.team.teamHierarchy.length;

    if (levelDelta !== 0) return levelDelta;
    return stringSort(a.team.name, b.team.name);
  });

  return {
    loading,
    error,
    teams: sortedTeams,
  };
};
