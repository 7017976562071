import { gql, useMutation } from '@apollo/client';

import {
  DomainId,
  GetInitiativesForNavigationDocument,
  ManageCompanyInitiatives_QueryDocument,
  RemoveInitiativeLinkToTenantDocument,
  TenantLinkTypes,
} from '../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../Button';
import { LinkOffIcon } from '../../../../../icons/LinkOffIcon';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { ErrorAlert } from '../../../../ErrorAlert';

interface Props {
  initiativeDomainId: DomainId | null;
}

export const RemoveInitiativeLinkToTenant = ({ initiativeDomainId }: Props) => {
  const { t } = useTranslation();
  const [removeInitiativeLinkToTenant, { loading: pending }] = useMutation(
    RemoveInitiativeLinkToTenantDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: (
            <strong>
              {t('RemoveInitiativeLinkToTenant.removeLinkSuccess')}
            </strong>
          ),
        });
      },
      onError: (error) => {
        showNotification('error', {
          message: (
            <strong>{t('RemoveInitiativeLinkToTenant.removeLinkError')}</strong>
          ),
          description: <ErrorAlert error={error} />,
        });
      },
      refetchQueries: () => [
        ManageCompanyInitiatives_QueryDocument,
        GetInitiativesForNavigationDocument,
      ],
    }
  );

  const handleSubmit = () => {
    if (initiativeDomainId)
      removeInitiativeLinkToTenant({
        variables: {
          domainId: {
            tenantId: initiativeDomainId.tenantId,
            itemId: initiativeDomainId.itemId,
          },
          type: TenantLinkTypes.INITIATIVE,
        },
      });
  };

  return (
    <div>
      <Btn
        type="link"
        icon={<LinkOffIcon />}
        onClick={(event) => {
          handleSubmit();
          event.stopPropagation();
        }}
        loading={pending}
      >
        {t('RemoveInitiativeLinkToTenant.unlink')}
      </Btn>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REMOVE_INITIATIVE_LINK_TO_TENANT = gql`
  mutation removeInitiativeLinkToTenant(
    $tenantId: ID
    $domainId: TenantLinkDomainIdInput!
    $type: TenantLinkTypes!
  ) {
    removeLinkToTenant(tenantId: $tenantId, domainId: $domainId, type: $type) {
      id
    }
  }
`;
