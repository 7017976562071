import Icon from '@ant-design/icons';

const InfoOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.3335 10.3334H7.66683V6.33337H6.3335V10.3334ZM7.00016 5.00004C7.18905 5.00004 7.34738 4.93615 7.47516 4.80837C7.60294 4.6806 7.66683 4.52226 7.66683 4.33337C7.66683 4.14448 7.60294 3.98615 7.47516 3.85837C7.34738 3.7306 7.18905 3.66671 7.00016 3.66671C6.81127 3.66671 6.65294 3.7306 6.52516 3.85837C6.39738 3.98615 6.3335 4.14448 6.3335 4.33337C6.3335 4.52226 6.39738 4.6806 6.52516 4.80837C6.65294 4.93615 6.81127 5.00004 7.00016 5.00004ZM7.00016 13.6667C6.07794 13.6667 5.21127 13.4917 4.40016 13.1417C3.58905 12.7917 2.8835 12.3167 2.2835 11.7167C1.6835 11.1167 1.2085 10.4112 0.858496 9.60004C0.508496 8.78893 0.333496 7.92226 0.333496 7.00004C0.333496 6.07782 0.508496 5.21115 0.858496 4.40004C1.2085 3.58893 1.6835 2.88337 2.2835 2.28337C2.8835 1.68337 3.58905 1.20837 4.40016 0.858374C5.21127 0.508374 6.07794 0.333374 7.00016 0.333374C7.92238 0.333374 8.78905 0.508374 9.60016 0.858374C10.4113 1.20837 11.1168 1.68337 11.7168 2.28337C12.3168 2.88337 12.7918 3.58893 13.1418 4.40004C13.4918 5.21115 13.6668 6.07782 13.6668 7.00004C13.6668 7.92226 13.4918 8.78893 13.1418 9.60004C12.7918 10.4112 12.3168 11.1167 11.7168 11.7167C11.1168 12.3167 10.4113 12.7917 9.60016 13.1417C8.78905 13.4917 7.92238 13.6667 7.00016 13.6667ZM7.00016 12.3334C8.48905 12.3334 9.75016 11.8167 10.7835 10.7834C11.8168 9.75004 12.3335 8.48893 12.3335 7.00004C12.3335 5.51115 11.8168 4.25004 10.7835 3.21671C9.75016 2.18337 8.48905 1.66671 7.00016 1.66671C5.51127 1.66671 4.25016 2.18337 3.21683 3.21671C2.1835 4.25004 1.66683 5.51115 1.66683 7.00004C1.66683 8.48893 2.1835 9.75004 3.21683 10.7834C4.25016 11.8167 5.51127 12.3334 7.00016 12.3334Z" />
  </svg>
);

export const InfoOutlineIcon = (props: any) => (
  <Icon component={InfoOutlineIconSvg} {...props} />
);
