import {
  MitemStatus,
  SkaSection_SkaTimeStatusFragment,
} from '../../../../../../../../generated/graphql';
import { SelectedSprint } from '../components/SprintSelector';

export const getSelectedSprintKeyActivities = (
  selectedSprint: SelectedSprint | null,
  withoutArchived?: boolean,
  allSkas?: SkaSection_SkaTimeStatusFragment[]
) => {
  const selectedSprintKeyActivities =
    allSkas?.filter((ska) => {
      if (withoutArchived && ska.status === MitemStatus.ARCHIVED) return false;

      if (!selectedSprint) return true;

      const activityDeadline = ska.deadline;

      return (
        activityDeadline >= selectedSprint.startDate &&
        activityDeadline <= selectedSprint.endDate
      );
    }) ?? [];

  return selectedSprintKeyActivities;
};
