import { MessageOutlined } from '@ant-design/icons';
import { KeyActivityGraph } from '../../../../../../../components/graphs/KeyActivityGraph';
import {
  Card,
  Col,
  Divider,
  Input,
  Row,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Legend, Line, LineProps } from 'recharts';
import { ExpandArrow } from '../../../../../../../components/ExpandArrow';
import { GraphDescriptionToggle } from '../../../../../../../components/GraphDescriptionToggle';
import { NoData } from '../../../../../../../components/NoData';
import { NumberInput } from '../../../../../../../components/NumberInput';
import { RenderedMarkdown } from '../../../../../../../components/RenderedMarkdown';
import { roundToDecimals } from '../../../../../../../services/roundNumbersHelper';
import { stringSort } from '../../../../../../../services/stringSort';
import { stringToNumber } from '../../../../../../../services/stringToNumber';
import { lineColor } from '../../../../../../../styleVars';
import { TitleWithTooltip } from '../../../../../setup/components/TitleWithTooltip';
import { useAccelerationMeeting } from '../../AccelerationMeetingProvider';
import { CommitmentQuota } from '../../components/CommitmentQuota';
import './AkpiCommitment.less';
import { SelectStrategy } from './AkpiCommitment/SelectStrategy';
import {
  Strategy,
  addAccordingToPlan,
  addReachGoalAtEndDateStrategy,
  addReachGoalThisPeriodStrategy,
  addSameAsLastPeriodStrategy,
} from './AkpiCommitment/strategyUtils';
import { TableData, toTableData } from './MeetingSummary';
import { Btn } from '../../../../../../../components/Button';
import { TourLink } from '../../../../../../../components/TourLink';
import { gql } from '@apollo/client';
import { AkpiCommitment_AkpiFragment } from '../../../../../../../generated/graphql';
import { friendlyDate } from '../../../../../../../services/dateFormats';
const strategyAppender = {
  [Strategy.ACCORDING_TO_PLAN]: addAccordingToPlan,
  [Strategy.SAME_AS_LAST_PERIOD]: addSameAsLastPeriodStrategy,
  [Strategy.REACH_GOAL_THIS_PERIOD]: addReachGoalThisPeriodStrategy,
  [Strategy.REACH_GOAL_AT_END_DATE]: addReachGoalAtEndDateStrategy,
};

interface AkpiCommitmentPeriod {
  timePeriodIndex: number;
  targetDate: string;
  goal: number;
  label?: string;
  actual?: number | null;
}

interface Props {
  akpi: AkpiCommitment_AkpiFragment;
}

export const AkpiCommitment = ({ akpi }: Props) => {
  const { t } = useTranslation();
  const { weeklyKeyActivityContext, members } = useAccelerationMeeting();

  const currentKeyActivity = weeklyKeyActivityContext.commitments[akpi.id];

  const [showAll, setShowAll] = useState(false);
  const [showDescriptions, setShowDescriptions] = useState(false);

  const columns: ColumnProps<TableData>[] = [
    {
      title: t('common.name'),
      dataIndex: 'userName',
      key: 'userId',
      width: '50%',
    },
    {
      title: t('AkpiCommitment.commitmentsColumn'),
      dataIndex: ['akpiCommitments', akpi.id],
      render: (commitment: { value: string; note: string }, item) => {
        const userCommitment = item.akpiCommitments[akpi.id];
        return (
          <div className="flx flx--ai-center">
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <NumberInput
                data-testid={`akpiCommitmentInput-${item.userId}`}
                value={commitment.value}
                onChange={(value) => {
                  value =
                    typeof value === 'string' ? stringToNumber(value) : value;
                  if (value && value < 0) return;
                  weeklyKeyActivityContext.setUserCommitment(
                    akpi.id,
                    item.userId,
                    {
                      value: value?.toString() ?? '',
                      note: commitment.note ?? '',
                    }
                  );
                }}
                min={0}
              />
            </div>
            <div className="ml--l">
              <Tooltip
                title={
                  <div
                    style={{ whiteSpace: 'pre-wrap' }}
                    className="flx flx--column "
                  >
                    {userCommitment.note
                      ? userCommitment.note
                      : t('AkpiCommitment.notePlaceholder')}
                  </div>
                }
              >
                {!userCommitment.note ? (
                  <MessageOutlined
                    style={{
                      fontSize: 18,
                      paddingTop: 4,
                      color: 'lightgray',
                      verticalAlign: 'center',
                    }}
                  />
                ) : (
                  <MessageOutlined
                    disabled={true}
                    style={{
                      fontSize: 18,
                      paddingTop: 4,
                      verticalAlign: 'center',
                    }}
                  />
                )}
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const periodDataWithoutThisPeriodActual = akpi.periodData.map((ld) => {
    if (ld.timePeriodIndex === akpi.currentPeriodIndex) {
      return { ...ld, actual: null };
    }
    return ld;
  });

  const currentIndex = akpi.currentPeriodIndex;
  const previousIndex = currentIndex ? currentIndex - 1 : null;
  const selectedStrategy = currentKeyActivity.selectedStrategy;

  const periodDataWithStrategy =
    selectedStrategy !== Strategy.ALREADY_ON_TRACK
      ? strategyAppender[selectedStrategy](
          periodDataWithoutThisPeriodActual,
          previousIndex
        )
      : periodDataWithoutThisPeriodActual;

  const currentPeriod =
    currentIndex != null ? periodDataWithStrategy[currentIndex] : null;
  const previousPeriod =
    previousIndex != null ? periodDataWithStrategy[previousIndex] : null;

  const akpiOnTrack = isOnTrack(previousPeriod);

  const totalCommitted = Object.values(
    currentKeyActivity.memberCommitments
  ).reduce(
    (sum, userCommitment) => sum + stringToNumber(userCommitment.value),
    0
  );

  const recommendedCommitment =
    currentKeyActivity.strategies[currentKeyActivity.selectedStrategy];

  const periodDataWithCommitment = appendCommitmentToPeriodData(
    periodDataWithStrategy,
    currentIndex,
    previousIndex,
    totalCommitted
  );

  const lineConf: LineProps[] = [
    {
      dataKey: 'goal',
      stroke: lineColor.goal,
      name: t('common.goal') as string,
    },
    {
      dataKey: 'actual',
      stroke: lineColor.actual,
      name: t('common.actual') as string,
    },
    {
      dataKey: 'committed',
      name: t('common.committed_one'),
      stroke: lineColor.actual,
      strokeWidth: 2,
      strokeDasharray: '6,4',
      dot: false,
      animationDuration: 600,
      animationEasing: 'ease-out',
      animationBegin: 600,
    },
    Table.EXPAND_COLUMN,
  ];

  const underCommitted = totalCommitted < recommendedCommitment;
  const userCommitments = toTableData(members, [akpi], {
    [akpi.id]: currentKeyActivity,
  });

  userCommitments.sort((a, b) => {
    const aIsSecondary = akpi.ignoreListedUsers.includes(a.userId);
    const bIsSecondary = akpi.ignoreListedUsers.includes(b.userId);
    if (aIsSecondary !== bIsSecondary)
      return Number(aIsSecondary) - Number(bIsSecondary);

    return stringSort(a.plainTextUserName, b.plainTextUserName);
  });

  const visibleCommitters = userCommitments.filter((u) => {
    const hasCommitment =
      u.akpiCommitments[akpi.id].note || u.akpiCommitments[akpi.id].value;
    const isPrimaryCommitter = !akpi.ignoreListedUsers.includes(u.userId);

    return isPrimaryCommitter || hasCommitment;
  });

  const hiddenUsers = userCommitments.length - visibleCommitters.length;

  return (
    <>
      <Row gutter={16}>
        <Col span={16}>
          <Card
            title={
              <div className="flx">
                {akpi.name}
                <div>
                  <TourLink
                    intercomTarget="Acceleration meeting - Weekly key activity commitments guide modal"
                    engTourId={3562}
                    sweTourId={3418}
                  />
                </div>
              </div>
            }
            styles={{ body: { padding: 0 } }}
            data-testid="akpiCommitmentCard"
            extra={
              <GraphDescriptionToggle
                showDescription={showDescriptions}
                onClick={() => setShowDescriptions(!showDescriptions)}
              />
            }
          >
            <div className="pl pr pt">
              {showDescriptions ? (
                <>
                  <div className="flx flx--column pl--l pt--l">
                    <Typography.Text strong>
                      {t('common.definitionOfDone')}
                    </Typography.Text>
                    {akpi.acceptanceCriteriaHtml ? (
                      <RenderedMarkdown html={akpi.acceptanceCriteriaHtml} />
                    ) : (
                      <NoData />
                    )}
                  </div>
                  <Divider />
                  <div className="flx flx--column pl--l pb--l">
                    <Typography.Text strong>
                      {t('common.description')}
                    </Typography.Text>
                    {akpi.descriptionHtml ? (
                      <RenderedMarkdown html={akpi.descriptionHtml} />
                    ) : (
                      <NoData />
                    )}
                  </div>
                </>
              ) : (
                <KeyActivityGraph
                  lineConf={lineConf}
                  lineData={periodDataWithCommitment}
                  yLabel={akpi.unit}
                  startDate={akpi.startDate}
                  height={200}
                >
                  {!akpiOnTrack && (
                    <Line
                      type="linear"
                      dataKey="strategy"
                      name={t('AkpiCommitment.strategy') as string}
                      stroke={'#bbb'}
                      strokeWidth={2}
                      strokeDasharray="6,4"
                      dot={false}
                      animationDuration={600}
                      animationEasing="ease-out"
                      animationBegin={600}
                    />
                  )}

                  <Legend
                    height={15}
                    iconType="plainline"
                    align="right"
                    wrapperStyle={{ bottom: 10 }}
                    verticalAlign="bottom"
                  />
                </KeyActivityGraph>
              )}
            </div>
            <Footer
              currentPeriod={currentPeriod}
              previousPeriod={previousPeriod}
            />
            {!akpiOnTrack && akpi.commitmentStrategyEnabled && (
              <div className="mt--l pa">
                <SelectStrategy
                  strategy={selectedStrategy}
                  strategies={currentKeyActivity.strategies}
                  setStrategy={(strategy: Strategy) =>
                    weeklyKeyActivityContext.setStrategy(
                      currentKeyActivity.akpiId,
                      strategy
                    )
                  }
                  unit={akpi.unit}
                />
              </div>
            )}
            {akpiOnTrack && !!currentIndex && (
              <div className="AkpiCommitment__onTrack">
                <div className="center-content">
                  <Typography.Text>
                    {t('AkpiCommitment.onTrack')}
                  </Typography.Text>
                </div>
              </div>
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title={t('common.commitment', { count: members.length })}
            styles={{ body: { padding: 0 } }}
          >
            <Table
              dataSource={showAll ? userCommitments : visibleCommitters}
              expandable={{
                expandRowByClick: true,
                expandedRowRender: (commitment) => {
                  const userCommitment = commitment.akpiCommitments[akpi.id];
                  return (
                    <div style={{ padding: 8 }}>
                      <TitleWithTooltip
                        tooltipContent={t(
                          'AkpiCommitment.accelerationMeetingNoteHelper'
                        )}
                        title={
                          <span className="italic">
                            {t('AkpiCommitment.accelerationMeetingNote')}
                          </span>
                        }
                      />

                      <Input.TextArea
                        autoComplete="off"
                        autoSize={{ minRows: 2, maxRows: 2 }}
                        showCount
                        maxLength={140}
                        value={userCommitment.note}
                        onChange={(e) => {
                          return weeklyKeyActivityContext.setUserCommitment(
                            akpi.id,
                            commitment.userId,
                            {
                              ...userCommitment,
                              note: e.target.value,
                            }
                          );
                        }}
                      />
                    </div>
                  );
                },
                expandIcon: ({ expanded, onExpand, record }) => (
                  <ExpandArrow
                    onClick={(e) => onExpand(record, e)}
                    expanded={expanded}
                  />
                ),
              }}
              size="small"
              columns={columns}
              pagination={false}
              bordered={false}
              rowKey={(record) => record.userId}
            />
            {hiddenUsers > 0 && !showAll && (
              <Btn className="mt " block onClick={() => setShowAll(true)}>
                {t('AkpiCommitment.showAll', { count: hiddenUsers })}
              </Btn>
            )}
            <div className="pa">
              <Typography.Text type="secondary" className="flx bold">
                <div>{t('AkpiCommitment.suggestedCommitmentAvgPerMember')}</div>
                <div className="ml--auto">
                  {Math.ceil(recommendedCommitment / visibleCommitters.length)}
                  <span className="ml--xs">{akpi.unit}</span>
                </div>
              </Typography.Text>
              <div className="flx AkpiCommitment__total-container">
                <div>{t('AkpiCommitment.totalCommitment')}</div>
                <div
                  className={
                    'ml--auto AkpiCommitment__total-value ' +
                    (underCommitted ? 'too-little' : '')
                  }
                >
                  <Tooltip
                    placement="top"
                    overlayStyle={!underCommitted ? { display: 'none' } : {}}
                    title={<div>{t('AkpiCommitment.underCommittedInfo')}</div>}
                  >
                    <span>
                      <CommitmentQuota
                        totalCommitted={totalCommitted}
                        recommendedCommitment={recommendedCommitment}
                      />
                      <span className="ml--xs">{akpi.unit}</span>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

interface FooterProps {
  currentPeriod: AkpiCommitmentPeriod | null;
  previousPeriod: AkpiCommitmentPeriod | null;
}

const Footer = ({ currentPeriod, previousPeriod }: FooterProps) => {
  const { t } = useTranslation();
  let numberToCatchUp;

  if (currentPeriod != null && previousPeriod != null) {
    const currentPeriodActualStartValue = previousPeriod?.actual;
    const currentPeriodGoalValue = currentPeriod?.goal;

    numberToCatchUp =
      currentPeriodGoalValue != null && currentPeriodActualStartValue != null
        ? roundToDecimals(
            currentPeriodGoalValue - currentPeriodActualStartValue
          )
        : null;
  }

  return (
    <div className="AkpiCommitment__footer">
      <div className="flx--1 AkpiCommitment__footerLabel">
        <Typography.Text type="secondary">
          {t('AkpiCommitment.akpiChartFooterActual', {
            period:
              (previousPeriod?.targetDate &&
                friendlyDate(previousPeriod?.targetDate)) ??
              'start',
          })}
        </Typography.Text>
        <div className="AkpiActual">{previousPeriod?.actual ?? '0'}</div>
      </div>
      <div className="flx--1 AkpiCommitment__footerLabel AkpiCommitment__footerBorder">
        <Typography.Text type="secondary">
          {t('AkpiCommitment.akpiChartFooterGoal', {
            period:
              currentPeriod?.targetDate &&
              friendlyDate(currentPeriod?.targetDate),
          })}
        </Typography.Text>
        <div className=" AkpiGoal">{currentPeriod?.goal ?? '-'}</div>
      </div>

      <div className="flx--1 AkpiCommitment__footerLabel">
        {numberToCatchUp != null && numberToCatchUp > 0 && (
          <div>
            <Typography.Text type="secondary">
              {t('AkpiCommitment.akpiChartFooterToBeOnTrack', {
                period: friendlyDate(currentPeriod?.targetDate),
              })}
            </Typography.Text>
            <div>{numberToCatchUp}</div>
          </div>
        )}
      </div>
    </div>
  );
};

function isOnTrack(
  previousPeriod: { goal: number; actual?: number | null } | null
) {
  if (previousPeriod === null) return true;
  if (previousPeriod?.actual == null) return false;

  return previousPeriod?.actual >= previousPeriod?.goal;
}

function appendCommitmentToPeriodData<T extends AkpiCommitmentPeriod>(
  periodData: T[],
  currentIndex: number | null,
  previousIndex: number | null,
  totalCommitted: number
) {
  if (currentIndex == null || currentIndex < 0) return periodData;

  const previousActual =
    previousIndex == null ? 0 : (periodData[previousIndex].actual ?? 0);
  const expectedActual = previousActual + totalCommitted;

  return periodData.map((p) => {
    if (p.timePeriodIndex === previousIndex) {
      return {
        ...p,
        committed: previousActual,
      };
    }
    if (p.timePeriodIndex === currentIndex) {
      return {
        ...p,
        committed: expectedActual,
      };
    }
    return p;
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AKPI_COMMITMENT_FRAGMENT = gql`
  fragment AkpiCommitment_Akpi on Akpi {
    id
    teamId
    name
    startDate
    unit
    currentPeriodIndex
    ignoreListedUsers
    acceptanceCriteriaHtml
    descriptionHtml
    commitmentStrategyEnabled
    periodData {
      timePeriodIndex
      goal
      actual
      targetDate
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AKPI_COMMITMENT_USER = gql`
  fragment AkpiCommitment_User on User {
    id
    email
    name
    displayName
  }
`;
