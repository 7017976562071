import Icon from '@ant-design/icons';

const RealEstateIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.6665 8.33333V4L9.33317 1.66667L5.99984 4V5H4.6665V3.33333L9.33317 0L13.9998 3.33333V8.33333H12.6665ZM9.6665 4.33333H10.3332V3.66667H9.6665V4.33333ZM8.33317 4.33333H8.99984V3.66667H8.33317V4.33333ZM9.6665 5.66667H10.3332V5H9.6665V5.66667ZM8.33317 5.66667H8.99984V5H8.33317V5.66667ZM9.33317 14L4.6665 12.7V13.6667H0.666504V6.33333H5.9665L10.0998 7.86667C10.4665 8 10.7637 8.23333 10.9915 8.56667C11.2193 8.9 11.3332 9.26667 11.3332 9.66667H12.6665C13.2221 9.66667 13.6943 9.85 14.0832 10.2167C14.4721 10.5833 14.6665 11.0667 14.6665 11.6667V12.3333L9.33317 14ZM1.99984 12.3333H3.33317V7.66667H1.99984V12.3333ZM9.29984 12.6L13.2665 11.3667C13.2332 11.2444 13.1582 11.1528 13.0415 11.0917C12.9248 11.0306 12.7998 11 12.6665 11H9.4665C9.12206 11 8.81095 10.9778 8.53317 10.9333C8.25539 10.8889 7.95539 10.8111 7.63317 10.7L6.48317 10.3L6.8665 9.03333L8.19984 9.46667C8.39984 9.53333 8.63317 9.58333 8.89984 9.61667C9.1665 9.65 9.53317 9.66667 9.99984 9.66667C9.99984 9.54445 9.96373 9.42778 9.8915 9.31667C9.81928 9.20556 9.73317 9.13333 9.63317 9.1L5.73317 7.66667H4.6665V11.3333L9.29984 12.6Z" />
  </svg>
);

export const RealEstateIcon = (props: any) => (
  <Icon component={RealEstateIconSvg} {...props} />
);
