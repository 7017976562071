declare global {
  interface Window {
    dataLayer: Record<string, any>;
  }
}

type GaConfigArgs = [
  command: 'config',
  targetId: string,
  config?:
    | Gtag.ControlParams
    | Gtag.EventParams
    | Gtag.ConfigParams
    | Gtag.CustomParams,
];

type GaSetArgs = [command: 'set', config: Gtag.CustomParams];

type GaSetWithTargetArgs = [
  command: 'set',
  targetId: string,
  config: Gtag.CustomParams | boolean | string,
];

type GaJsArgs = [command: 'js', config: Date];

type GaEventArgs = [
  command: 'event',
  eventName: Gtag.EventNames | (string & {}),
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams,
];

type GaGetArgs = [
  command: 'get',
  targetId: string,
  fieldName: Gtag.FieldNames | string,
  callback?: (field: string | Gtag.CustomParams | undefined) => any,
];

type GaConsentArgs = [
  command: 'consent',
  consentArg: Gtag.ConsentArg | string,
  consentParams: Gtag.ConsentParams,
];

export type GaArgs =
  | GaConfigArgs
  | GaSetArgs
  | GaSetWithTargetArgs
  | GaJsArgs
  | GaEventArgs
  | GaGetArgs
  | GaConsentArgs;

export function gtag(...args: GaArgs) {
  if (typeof window !== 'undefined') {
    if (typeof window.gtag === 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function gtag() {
        window.dataLayer.push(args);
      };
    }

    window.gtag.apply(window, args as any);
  }
}
