import { Divider, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { GroupsIcon } from '../../../../../icons/GroupsIcon';
import { AlignmentIcon } from '../../../../initiatives/InitiativeForm/Icons/AlignmentIcon';
import { EndingPointIcon } from '../../../../initiatives/InitiativeForm/Icons/EndingPointIcon';
import { TextAreaIconIcon } from '../../../../initiatives/InitiativeForm/Icons/TextAreaIcon';
import Skeleton from 'react-loading-skeleton';
import { MilestoneActivitiesTableSkeleton } from '../../../../../appPages/company/initiatives/initiativeDetails/progressSection/components/milestoneTable/components/MilestoneActivitiesTable.skeleton';
import { Btn } from '../../../../Button';

export const MilestoneCollapseItemSkeleton = () => {
  const t = useTranslation().t;

  return (
    <div>
      <div>
        <div className="flx mb--xl">
          <Tooltip title={t('common.milestone.description')}>
            <TextAreaIconIcon className="mr--l txt--secondary font-size--lg" />
          </Tooltip>
          <div className="fullWidth">
            <Skeleton />
          </div>
        </div>
        <div className="flx mb--xl">
          <Tooltip title={t('common.milestone.deadline')}>
            <EndingPointIcon className="mr--l txt--secondary font-size--lg" />
          </Tooltip>
          <div className="fullWidth">
            <Skeleton />
          </div>
        </div>
        <div className="flx mb--xl">
          <Tooltip title={t('common.milestone.supports')}>
            <AlignmentIcon className="mr--l txt--secondary font-size--lg" />
          </Tooltip>
          <div className="fullWidth">
            <Skeleton />
          </div>
        </div>
        <div className="flx mb--xl">
          <Tooltip title={t('common.milestone.contributingTeams')}>
            <GroupsIcon className="mr--l txt--secondary font-size--lg" />
          </Tooltip>
          <div className="fullWidth">
            <Skeleton />
          </div>
        </div>
      </div>
      <h4 className="mb"> {t('MilestoneCollapseItem.connectedActivities')}</h4>
      <MilestoneActivitiesTableSkeleton numberOfActivities={3} />
      <Divider />
      <Btn type="primary" disabled>
        {t('MilestoneCollapseItem.markAsCompleted')}
      </Btn>
    </div>
  );
};
