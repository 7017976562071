import dayjs from 'dayjs';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';

import './TeamGantishChart.less';
import { TeamGantishChartRow } from './GantishChart/TeamGantishChartRow';
import { TeamGantishMonthCell } from './GantishChart/TeamGantishMonthCell';
import { Btn } from '../../../../../../components/Button';

const today = dayjs();

export const TeamGantishChartSkeleton = () => {
  const startDate = dayjs('2024-01-01');

  const months = Array.from({ length: 6 }, (_, i) => startDate.add(i, 'month'));

  const monthProgress = (today.date() / today.daysInMonth()) * 100;

  return (
    <div
      className="GantishChart"
      style={{ ['--month-percent' as any]: monthProgress + '%' }}
    >
      <div className="GantishChart__row GantishChart__row--header">
        <Btn
          className="GantishChart__backBtn"
          icon={<CaretLeftFilled style={{ fontSize: ICON_SIZE }} />}
          type="link"
          disabled
        />

        {months.map((m, i) => (
          <TeamGantishMonthCell key={i} startOfMonth={m}>
            <span className="ml">
              {m.format(isJanuary(m) ? 'MMM YYYY' : 'MMM')}
            </span>
          </TeamGantishMonthCell>
        ))}

        <Btn
          className="GantishChart__forwardBtn"
          icon={<CaretRightFilled style={{ fontSize: ICON_SIZE }} />}
          type="link"
          disabled
        />
      </div>

      <div className="GantishChart__row">
        {months.map((m, i) => (
          <TeamGantishMonthCell key={i} startOfMonth={m}></TeamGantishMonthCell>
        ))}
      </div>

      <div className="GantishChart__row GantishChart__row--spacer">
        {months.map((m, i) => (
          <TeamGantishMonthCell key={i} startOfMonth={m} />
        ))}
      </div>

      <div id="subInitiatives">
        <TeamGantishChartRow.Skeleton months={months} />
        <TeamGantishChartRow.Skeleton months={months} />
      </div>
    </div>
  );
};

const isJanuary = (date: dayjs.Dayjs) => date.month() === 0;

const ICON_SIZE = 11;
