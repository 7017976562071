import { Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTenantOverride } from '../../TenantOverrideProvider';
import { Btn } from '../Button';
import { DrawerTitle } from '../DrawerTitle';
import { ReportDrawer } from './ReportDrawer/ReportDrawer';

interface Props {
  className?: string;
}

export const ReportDrawerButton = ({ className }: Props) => {
  const { t } = useTranslation();

  const [openReport, setOpenReport] = useState(false);
  const tenantOverride = useTenantOverride();

  useEffect(() => {
    (window as any).Intercom?.('update', {
      hide_default_launcher: openReport,
    });
  }, [openReport]);

  const handleDrawerClose = () => setOpenReport(false);
  return (
    <>
      {!tenantOverride.isOverridden && (
        <Btn
          type="callToAction"
          id="reportProgress"
          onClick={() => setOpenReport(true)}
          data-intercom-target="Report Activity Button Navigation"
          className={className}
        >
          {t('ReportDrawerButton.reportProgressButton')}
        </Btn>
      )}

      <Drawer
        title={
          <DrawerTitle>
            {t('ReportDrawerButton.reportProgressDrawerTitle')}
          </DrawerTitle>
        }
        placement="right"
        closable={true}
        width={650}
        onClose={handleDrawerClose}
        open={openReport}
        destroyOnClose
        styles={{ header: { borderBottom: 'none' } }}
      >
        <ReportDrawer closeDrawer={handleDrawerClose} />
      </Drawer>
    </>
  );
};
