import { gql, useSuspenseQuery } from '@apollo/client';
import { FormInstance } from 'antd';
import { GetTenantBusinessPlanInitiativeForEditDocument } from '../../../../../generated/graphql';
import { InitiativeForm } from '../../../InitiativeForm/InitiativeForm';

interface Props {
  form: FormInstance;
  initiativeId: string;
  isTopRealign?: boolean;
}

export const EditInitiativeModalContent = ({
  form,
  initiativeId,
  isTopRealign,
}: Props) => {
  const { data } = useSuspenseQuery(
    GetTenantBusinessPlanInitiativeForEditDocument,
    { variables: { initiativeId: initiativeId }, fetchPolicy: 'no-cache' }
  );

  const parentInitiativeTag =
    data.tenantInitiative2.fullMetadata.supportsInitiatives[0]?.tag;

  return (
    <InitiativeForm
      form={form}
      initialValue={data.tenantInitiative2}
      parentInitiativeTag={isTopRealign ? undefined : parentInitiativeTag}
    />
  );
};

EditInitiativeModalContent.Skeleton = InitiativeForm.Skeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_EDIT_INITIATIVE_CONTENT = gql`
  query getTenantBusinessPlanInitiativeForEdit(
    $tenantId: ID
    $initiativeId: ID!
  ) {
    tenantInitiative2(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      fullMetadata {
        archived
        completedAt
        supportsInitiatives {
          id
          tag {
            title
            iconId
            colorCode
          }
        }
        supportsMigs {
          id
          name
        }
      }
      ...InitiativeForm_InitiativeLight
    }
  }
`;
