import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Modal, Spin } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetDeleteMirrorMigDocument,
  GetTeamFullMigsV2Document,
  RemoveMirroredMigDocument,
} from '../../../generated/graphql';
import { showNotification } from '../../../services/fetchNotificationProperties';
import { ErrorAlert } from '../../ErrorAlert';
import { MigCard } from '../../mig/MigCard';
import { MigModalState } from '../MigSetupPage';
import { without } from '../../../services/without';

interface Props {
  onClose: () => void;
  teamId: string;
  tenantId?: string;
  migModalState: MigModalState | null;
}

export const DeleteMirrorMigModal = ({
  onClose,
  teamId,
  tenantId,
  migModalState,
}: Props) => {
  const { t } = useTranslation();
  const [fetchFullTeamMig, { data, loading: loadMig, error: loadMigError }] =
    useLazyQuery(GetDeleteMirrorMigDocument);

  const teamMig = data?.teamMig;

  const isOpen =
    migModalState?.type === 'RemoveMirrorMig' && migModalState.domainId != null;

  useEffect(() => {
    if (migModalState?.type === 'RemoveMirrorMig') {
      fetchFullTeamMig({
        variables: {
          teamId: migModalState.domainId.teamId,
          migId: migModalState.domainId.itemId,
          tenantId,
        },
      });
    }
  }, [fetchFullTeamMig, migModalState, teamId, tenantId]);

  const [removeMirrorMig, { loading, error }] = useMutation(
    RemoveMirroredMigDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: <strong>{t('DeleteMirrorMigModal.success')}</strong>,
        });

        onClose();
      },
      refetchQueries: () => [
        {
          query: GetTeamFullMigsV2Document,
          variables: { teamId, tenantId },
        },
      ],
    }
  );

  const handleSave = () => {
    if (migModalState?.type === 'RemoveMirrorMig' && migModalState.domainId)
      removeMirrorMig({
        variables: {
          tenantId,
          teamId,
          targetedMigDomainId: without(migModalState?.domainId, '__typename'),
        },
      });
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      onOk={handleSave}
      title={t('DeleteMirrorMigModal.title')}
      okText={t('DeleteMirrorMigModal.confirm')}
      confirmLoading={loading}
    >
      <Spin spinning={loadMig}>
        <div className="mb--l">{t('DeleteMirrorMigModal.infoText')}</div>

        {teamMig && (
          <MigCard mig={teamMig} showDescription={false} graphHeight={120} />
        )}
        <ErrorAlert error={error || loadMigError} />
      </Spin>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REMOVE_MIRROR_MIG = gql`
  mutation removeMirroredMig(
    $tenantId: ID
    $teamId: ID!
    $targetedMigDomainId: MigDomainIdInput!
  ) {
    deleteMirroredMig(
      tenantId: $tenantId
      teamId: $teamId
      targetedMigDomainId: $targetedMigDomainId
    ) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_DELETE_MIRROR_MIG = gql`
  query getDeleteMirrorMig($teamId: ID!, $migId: ID!, $tenantId: ID) {
    teamMig(teamId: $teamId, migId: $migId, tenantId: $tenantId) {
      id
      ...MigCardMig
    }
  }
`;
