import { Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LearnMoreLink } from '../../../../components/LearnMoreLink';

interface Migs {
  id: string;
  name: string;
  team: {
    id: string;
    name: string;
  };
}

interface Props {
  migsMissingRelations: Migs[];
}

export const MigsMissingRelations = ({ migsMissingRelations }: Props) => {
  const { t } = useTranslation();

  const columns: ColumnProps<Migs>[] = [
    {
      title: t('common.team'),
      dataIndex: ['team', 'name'],
      render(name, mig) {
        return <Link to={`/team/${mig.team.id}`}>{name}</Link>;
      },
    },
    { title: t('common.mostImportantGoal'), dataIndex: 'name' },
  ];

  return (
    <div>
      <div className="mb--xl">
        <Typography.Title
          level={5}
          className="mb--none mt--none mr"
          style={{ display: 'inline-block' }}
        >
          {t('MigsMissingRelations.shortDescription')}
        </Typography.Title>
        <LearnMoreLink urlTemplate="https://help.howwe.io/{{locale}}/articles/28903-how-to-create-and-edit-most-important-goals" />
      </div>
      <div className="txt--secondary mb">
        {t('MigsMissingRelations.description', {
          count: migsMissingRelations.length,
        })}
      </div>
      <Table size="small" columns={columns} dataSource={migsMissingRelations} />
    </div>
  );
};
