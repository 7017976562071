import { BaseMutationOptions, gql, useMutation } from '@apollo/client';
import {
  ArchiveMitemDocument,
  ArchiveMitemMutation,
  ArchiveMitemMutationVariables,
  MitemStatus,
} from '../../../../../generated/graphql';

export const useMitemArchivation = (
  options: BaseMutationOptions<
    ArchiveMitemMutation,
    ArchiveMitemMutationVariables
  >
) => {
  const [mutate, result] = useMutation(ArchiveMitemDocument, options);

  const simplifiedMutate = (
    teamId: string,
    sprintKaId: string,
    options = { archive: true }
  ) => {
    return mutate({
      variables: {
        teamId,
        sprintKaId,
        sprintKaData: options,
      },
    });
  };

  return [simplifiedMutate, result] as const;
  // `as const` forces the type to a (immutable) tuple instead of an array
};

export const isPossibleToChangeArchiveState = (
  mitem: { status: MitemStatus },
  sprint: { locked: boolean; finalized: boolean }
) => {
  if (sprint.finalized) return false;
  if (mitem.status === MitemStatus.COMPLETED) return false;

  return true;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ARCHIVE_MITEM = gql`
  mutation archiveMitem(
    $teamId: ID!
    $sprintKaId: ID!
    $sprintKaData: SprintKaInput!
  ) {
    editSprintKa(
      teamId: $teamId
      sprintKaId: $sprintKaId
      sprintKaData: $sprintKaData
    ) {
      id
      teamId
      tenantId
      name
      completed
      archived
      status
    }
  }
`;
