import { Switch } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { tagsContext } from '../TeamSprintProvider';

export const UntaggedOnlyFilter = () => {
  const { t } = useTranslation();
  const { setUntaggedOnly, untaggedOnly } = useContext(tagsContext) as any;
  return (
    <div className="flx flx--ai-center flx-align-self-end mb--s">
      <Switch onChange={(e) => setUntaggedOnly(e)} checked={untaggedOnly} />
      <h5 className="ml--m no-wrap">{t('Tags.untaggedOnly')}</h5>
    </div>
  );
};
