import { useState } from 'react';
import { SkaInitialValues } from '../../planning/components/SprintKaForm';

interface ModalState {
  modalOpen: boolean;
  prefilledValues?: SkaInitialValues;
}

export const useCreateMitemModal = () => {
  const [modalState, setModalState] = useState<ModalState>({
    modalOpen: false,
  });

  const openCreateForm = ({
    deadline,
    preselectedMilestone,
  }: {
    deadline?: string;
    preselectedMilestone?: {
      id: string;
      domainId: { itemId: string; tenantId: string };
    };
  } = {}) => {
    setModalState({
      modalOpen: true,
      prefilledValues: {
        deadline,
        supportsMilestones: preselectedMilestone && [preselectedMilestone],
      },
    });
  };

  const closeAndClearDeadline = () => {
    setModalState({
      modalOpen: false,
      prefilledValues: {
        deadline: undefined,
        supportsMilestones: undefined,
      },
    });
  };

  return {
    isOpen: modalState.modalOpen,
    prefilledValues: modalState.prefilledValues,
    openModal: openCreateForm,
    closeModal: closeAndClearDeadline,
  };
};

export type CreateMitemModal = ReturnType<typeof useCreateMitemModal>;
