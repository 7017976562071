import { Skeleton, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import { InitiativeMenu } from './InitiativeMenu';
import { InitiativeNode } from './services/initiativeTreeNodeHelper';

interface Props {
  initiativeId?: string;
  teamId?: string;
  strategicInitiatives: InitiativeNode[];
  teamInitiatives: InitiativeNode[];
  loading: boolean;
}

export const InitiativeNavigation = ({
  initiativeId,
  teamId,
  strategicInitiatives,
  teamInitiatives,
  loading,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div style={{ flex: '0 auto', minWidth: 250 }}>
      <h2 data-intercom-target="Select initiative headline">
        {t('InitiativeNavigation.selectInitiative')}
      </h2>
      <div className="mb--xl">
        <Typography.Text
          type="secondary"
          data-intercom-target="Strategic initiative menu headline"
        >
          {t('common.initiative.globalInitiatives')}
        </Typography.Text>

        <div className="mt--xs">
          {loading ? (
            <Skeleton active={true} paragraph={{ rows: 2 }} title={false} />
          ) : (
            <InitiativeMenu
              teamId={teamId}
              initiativeId={initiativeId}
              initiatives={strategicInitiatives}
            />
          )}
        </div>
      </div>
      <div className="divider mt mb--xl" />
      {teamInitiatives.length > 0 && (
        <div>
          <Typography.Text
            type="secondary"
            data-intercom-target="Team initiative menu headline"
          >
            {t('common.initiative.teamInitiatives')}
          </Typography.Text>
          <div className="mt--xs">
            {loading ? (
              <Skeleton active paragraph={{ rows: 2 }} title={false} />
            ) : (
              <InitiativeMenu
                teamId={teamId}
                initiativeId={initiativeId}
                initiatives={teamInitiatives}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
