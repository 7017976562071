import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { CurrentUserDocument } from '../generated/graphql';

export const useMe = () => {
  return useQuery(CurrentUserDocument);
};
export const useMeLazy = () => {
  return useLazyQuery(CurrentUserDocument);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CURRENT_USER = gql`
  query currentUser {
    me {
      name
      initials
      id
      displayName
      email
      locale
      role
      accountSignedUpAt
      created
      teamAssociations(teamFilter: { active: true }) {
        role
        sprintAdmin
        team {
          id
          name
          accelerationDay
          teamHierarchy {
            id
          }
        }
      }
      tenant {
        id
        name
        domainId {
          tenantId
        }
      }
      intercomTerms {
        activeTermEndDate
        nextTermStartDate
      }
    }
  }
`;
