import { useHistory, useLocation } from 'react-router-dom';

export const useKeyActivityDetailsModal = () => {
  const history = useHistory();
  const location = useLocation();

  const openModal = (activityId: string, teamId: string) => {
    // Create a new URLSearchParams object from the current search string
    const params = new URLSearchParams(location.search);

    // Set the necessary query parameters
    params.set('openActivityDetails', 'true');
    params.set('activityId', activityId);
    params.set('teamId', teamId);

    // Construct the new URL
    const newUrl = `${location.pathname}?${params.toString()}`;

    // Navigate to the new URL
    history.push(newUrl);
  };

  return {
    openModal,
  };
};
