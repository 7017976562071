import Icon from '@ant-design/icons';

const ChatIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.00016 8.33337H8.3335V7.00004H3.00016V8.33337ZM3.00016 6.33337H11.0002V5.00004H3.00016V6.33337ZM3.00016 4.33337H11.0002V3.00004H3.00016V4.33337ZM0.333496 13.6667V1.66671C0.333496 1.30004 0.464052 0.986152 0.725163 0.725041C0.986274 0.46393 1.30016 0.333374 1.66683 0.333374H12.3335C12.7002 0.333374 13.0141 0.46393 13.2752 0.725041C13.5363 0.986152 13.6668 1.30004 13.6668 1.66671V9.66671C13.6668 10.0334 13.5363 10.3473 13.2752 10.6084C13.0141 10.8695 12.7002 11 12.3335 11H3.00016L0.333496 13.6667ZM1.66683 10.45L2.45016 9.66671H12.3335V1.66671H1.66683V10.45Z" />
  </svg>
);

export const ChatIcon = (props: any) => (
  <Icon component={ChatIconSvg} {...props} />
);
