import cx from 'classnames';
import './MeetingNavigationBar.less';
import {
  ArrowRightOutlined,
  CheckOutlined,
  DownCircleOutlined,
  LeftOutlined,
  LockOutlined,
  RightOutlined,
  UnlockOutlined,
  UpCircleOutlined,
} from '@ant-design/icons';
import { useWizardNavigation } from '../../../../../../components/WizardNavigationProvider';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../../../components/Button';
import { ReactNode, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useAccelerationMeeting } from '../AccelerationMeetingProvider';
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside';

interface Props {
  expanded: boolean;
  setExpanded: (expand: boolean) => void;
  isNewMeeting: boolean;
  hasKeyActivities: boolean;
  defaultOnClose: () => void;
  onStepChange?: () => void;
}

export const MeetingNavigationBar = ({
  expanded = false,
  setExpanded,
  isNewMeeting,
  hasKeyActivities,
  defaultOnClose,
  onStepChange,
}: Props) => {
  const { onSubmit, submitPending } = useAccelerationMeeting();
  const [hasExpanded, setHasExpanded] = useState(false);

  useEffect(() => {
    if (expanded && !hasExpanded) {
      setHasExpanded(true);
    }
  }, [expanded, hasExpanded]);

  const {
    steps,
    activeStep,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    isLocked,
    currentStepPath,
  } = useWizardNavigation();
  const { t } = useTranslation();

  const navigationBarRef = useRef<HTMLDivElement>(null);

  const clickOutsidehandler = () => {
    if (expanded) {
      setExpanded(false);
    }
  };

  useOnClickOutside(navigationBarRef, clickOutsidehandler);

  return (
    <div className="MeetingNavigationBar" ref={navigationBarRef}>
      <div
        className={cx('MeetingNavigationBarExpanded', {
          hasExpanded,
          expanded,
        })}
      >
        <div className="MeetingNavigationBarExpanded__steps">
          {steps.map((s) => {
            const lockedStep = isLocked(s.stepPath);
            const status = stepStatus(s.stepPath, currentStepPath);
            if (s.type === 'WizardGroupStep') {
              return (
                <div key={s.key}>
                  <div
                    className={cx('Step', {
                      isLocked: lockedStep,
                      isActiveStep: status === 'process',
                    })}
                    onClick={(e) => {
                      if (lockedStep) return;
                      e.stopPropagation();
                      goToStep(s.stepPath);
                      setExpanded(false);
                      onStepChange?.();
                    }}
                  >
                    <div style={{ marginLeft: 64 }}>{s.title}</div>
                  </div>

                  {s.subSteps.map((sub) => {
                    const status = stepStatus(sub.stepPath, currentStepPath);
                    const lockedSubStep = isLocked(sub.stepPath);
                    const nextStepLocked =
                      sub.type === 'WizardComponentStep' &&
                      sub.nextStep &&
                      isLocked(sub.nextStep);
                    const isMandatory =
                      sub.type === 'WizardComponentStep' && sub.isMandatory;
                    return (
                      <div
                        key={sub.key}
                        className={cx('SubStep', {
                          isLocked: lockedSubStep,
                          isActiveStep: status === 'process',
                        })}
                        onClick={(e) => {
                          if (lockedSubStep) return;
                          e.stopPropagation();
                          goToStep(sub.stepPath);
                          setExpanded(false);
                          onStepChange?.();
                        }}
                      >
                        <div style={{ width: 84 }}>
                          {status === 'process' && (
                            <ArrowRightOutlined className="activeIcon" />
                          )}
                          {status === 'finish' && (
                            <CheckOutlined className="finishStepIcon" />
                          )}
                        </div>
                        {isMandatory && (
                          <div className="mr">
                            {nextStepLocked ? (
                              <LockOutlined className="lockIcon" />
                            ) : (
                              <UnlockOutlined className="unlockedIcon" />
                            )}
                          </div>
                        )}
                        {sub.title}
                      </div>
                    );
                  })}
                </div>
              );
            }
            return (
              <div
                key={s.key}
                className={cx('Step', {
                  isLocked: lockedStep,
                  isActiveStep: status === 'process',
                })}
                onClick={(e) => {
                  if (lockedStep) return;
                  e.stopPropagation();
                  goToStep(s.stepPath);
                  setExpanded(false);
                  onStepChange?.();
                }}
              >
                <div style={{ width: 64 }}>
                  {status === 'process' && (
                    <ArrowRightOutlined className="activeIcon" />
                  )}
                  {status === 'finish' && (
                    <CheckOutlined className="finishStepIcon" />
                  )}
                </div>
                {s.title}
              </div>
            );
          })}
        </div>
      </div>
      <div className={cx('MeetingNavigationBarCompact', { expanded })}>
        <div
          className="MeetingNavigationBarCompact__expandButton"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? (
            <DownCircleOutlined style={{ color: '#ff6309' }} />
          ) : (
            <UpCircleOutlined style={{ color: '#ff6309' }} />
          )}
        </div>
        <div className="MeetingNavigationBarCompactContent">
          <div className="MeetingNavigationBarCompact__title">
            {activeStep.parentInfo ? (
              <div className="no-wrap">
                <div className="Step" onClick={() => setExpanded(!expanded)}>
                  <div className="space--r">{activeStep.parentInfo.title}</div>
                  {activeStep.parentInfo.numberOfSteps > 1 &&
                    t('MeetingNavigation.subStep', {
                      activeStep: activeStep.index + 1,
                      totalNumberOfSteps: activeStep.parentInfo.numberOfSteps,
                    })}
                </div>
                {activeStep.title}
              </div>
            ) : (
              <div>
                <div className="Step" onClick={() => setExpanded(!expanded)}>
                  {activeStep.title}
                </div>
              </div>
            )}
          </div>

          <div className="flx">
            {activeStep.prevStep && (
              <Btn
                data-testid="meetingNavigationPreviousStep"
                className="mr--xs LinkButton"
                type="link"
                onClick={() => {
                  setExpanded(false);
                  goToPreviousStep();
                  onStepChange?.();
                }}
              >
                <LeftOutlined />
                {t('MeetingNavigation.previousButton')}
              </Btn>
            )}
            {activeStep.nextStep && isLocked(activeStep.nextStep) && (
              <div id="PortalButton"></div>
            )}

            {activeStep.nextStep && !isLocked(activeStep.nextStep) && (
              <Btn
                type="callToAction"
                className="ml--xs"
                disabled={isLocked(activeStep.nextStep)}
                icon={isLocked(activeStep.nextStep) && <LockOutlined />}
                data-testid="meetingNavigationNextStep"
                onClick={() => {
                  setExpanded(false);
                  goToNextStep();
                  onStepChange?.();
                }}
              >
                {t('MeetingNavigation.nextButton')}
                <RightOutlined />
              </Btn>
            )}
            {!activeStep.nextStep && hasKeyActivities && (
              <Btn
                type="callToAction"
                className="ml--xs"
                data-testid="meetingNavigationCommit"
                onClick={() => onSubmit(defaultOnClose)}
                loading={submitPending}
              >
                {isNewMeeting
                  ? t('MeetingNavigation.commitButton')
                  : t('MeetingNavigation.updateCommitButton')}
              </Btn>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface PortalButtonProps {
  actionButton: ReactNode;
}

export const PortalButton = ({ actionButton }: PortalButtonProps) => {
  const button = document.getElementById('PortalButton');
  const content = document.createElement('div');

  useEffect(() => {
    if (button) {
      button.appendChild(content);
      return () => {
        button!.removeChild(content);
      };
    }
  }, [button, content]);

  return ReactDOM.createPortal(actionButton, content);
};

function stepStatus(
  stepPath: number[],
  activeStepPath: number[]
): 'wait' | 'finish' | 'process' {
  let status: 'wait' | 'finish' | 'process' = 'wait';

  for (let index = 0; index < stepPath.length; index++) {
    const stepLevelPathIndex = stepPath[index];
    const activeLevelPathIndex = activeStepPath[index];

    if (stepLevelPathIndex < activeLevelPathIndex) {
      return 'finish';
    }
    if (stepLevelPathIndex > activeLevelPathIndex) {
      return 'wait';
    }
    if (stepLevelPathIndex === activeLevelPathIndex) {
      status = 'process';
    }
  }

  return status;
}
