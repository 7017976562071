import Icon from '@ant-design/icons';

const EnvelopeOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.66683 11.3333C1.30016 11.3333 0.986274 11.2027 0.725163 10.9416C0.464052 10.6805 0.333496 10.3666 0.333496 9.99996V1.99996C0.333496 1.63329 0.464052 1.3194 0.725163 1.05829C0.986274 0.797182 1.30016 0.666626 1.66683 0.666626H12.3335C12.7002 0.666626 13.0141 0.797182 13.2752 1.05829C13.5363 1.3194 13.6668 1.63329 13.6668 1.99996V9.99996C13.6668 10.3666 13.5363 10.6805 13.2752 10.9416C13.0141 11.2027 12.7002 11.3333 12.3335 11.3333H1.66683ZM7.00016 6.66663L1.66683 3.33329V9.99996H12.3335V3.33329L7.00016 6.66663ZM7.00016 5.33329L12.3335 1.99996H1.66683L7.00016 5.33329ZM1.66683 3.33329V1.99996V9.99996V3.33329Z" />
  </svg>
);

export const EnvelopeOutlineIcon = (props: any) => (
  <Icon component={EnvelopeOutlineIconSvg} {...props} />
);
