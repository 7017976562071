import { Dropdown, Spin } from 'antd';
import { MitemTermCard } from '../../../akpi/teamAkpiPreviews/MitemTermCard';
import { useSprintTerm } from '../../../../hooks/useSprintTerm';
import '../keyActivityTable/keyActivityTableCard/KeyActivityTableCard.less';
import { KeyActivityModalState } from '../../KeyActivitySetupPage';
import { EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { ArchiveOutlinedIcon } from '../../../../icons/ArchiveIcon';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../Button';

interface Props {
  teamId: string;
  termId: string;
  tenantId?: string;
  highlightedPeriod?: string;
  setKeyActivityModalState: (
    keyActivityModalState: KeyActivityModalState | null
  ) => void;
}

export const SprintTermCard = ({
  teamId,
  termId,
  tenantId,
  highlightedPeriod,
  setKeyActivityModalState,
}: Props) => {
  const { t } = useTranslation();
  const { loading, sprintTerm } = useSprintTerm({
    teamId,
    tenantId,
    sprintTermId: termId,
  });

  return (
    <Spin spinning={loading}>
      {sprintTerm && (
        <MitemTermCard
          term={sprintTerm}
          teamId={teamId}
          tenantId={tenantId}
          showDescription={false}
          highlightedPeriod={highlightedPeriod}
          action={
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'EditSprintTerm',
                    icon: <EditOutlined />,
                    label: t('common.edit'),
                    onClick: () =>
                      setKeyActivityModalState({
                        type: 'EditSprintTerm',
                        id: sprintTerm.id,
                      }),
                  },
                  {
                    key: 'UpdateStatusWeeklyKeyActivity',
                    icon: (
                      <ArchiveOutlinedIcon className="keyActivityPage__icon" />
                    ),
                    label: t('common.archive'),
                    onClick: () =>
                      setKeyActivityModalState({
                        type: 'UpdateStatusSprintTerm',
                        id: sprintTerm.id,
                        status: sprintTerm.status,
                      }),
                  },
                ],
              }}
              placement="bottom"
            >
              <Btn size="small" icon={<EllipsisOutlined rotate={90} />} />
            </Dropdown>
          }
        />
      )}
    </Spin>
  );
};
