import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { StrategicPlanPage } from './StrategicPlan.page';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

import { ErrorPage } from '../../../components/ErrorPage';

export const StrategicPlanRoutes = () => {
  const match = useRouteMatch();

  return (
    <div className="mt">
      <ErrorBoundary
        FallbackComponent={ErrorPage}
        onError={(error) => Sentry.captureException(error)}
      >
        <Switch>
          <Route exact path={match.path}>
            <StrategicPlanPage />
          </Route>
        </Switch>
      </ErrorBoundary>
    </div>
  );
};
