import { Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { MigRelations } from './MigDescription/MigRelations';
import './MigDescription.less';
import { gql, useQuery } from '@apollo/client';
import {
  MigDescriptionDocument,
  MigDescriptionMigFragment,
} from '../../../generated/graphql';
import { RecursiveOmit } from '../../../services/typeHelpers';

interface MigDescriptionLazyProps {
  teamId: string;
  migId: string;
}

// @FredColl I separated the MigDescriptionLazy and MigDescription components so that we can use a the lazy version in the regular MigCard and then just the regular MigDescription in the setup context when no data i being fetched.
export const MigDescriptionLazy = ({
  teamId,
  migId,
}: MigDescriptionLazyProps) => {
  const { data, loading } = useQuery(MigDescriptionDocument, {
    variables: { teamId, migId },
  });

  const mig = data?.teamMig;

  return <MigDescription mig={mig} loading={loading} />;
};

type MigDescriptionWithoutTypename = RecursiveOmit<
  MigDescriptionMigFragment,
  '__typename'
>;
interface Props {
  mig?: MigDescriptionWithoutTypename;
  loading?: boolean;
}

export const MigDescription = ({ mig, loading = false }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="MigDescription">
      <div className="MigDescription__content">
        <Spin spinning={loading}>
          <div className="pa">
            <Typography.Text strong>{t('common.description')}</Typography.Text>
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {mig?.description || '-'}
            </div>
          </div>
          {mig?.weight && (
            <div className="pa">
              <Typography.Text strong>
                {t('MigDescription.weighted')}
              </Typography.Text>
              <div>{mig.weight * 100} %</div>
            </div>
          )}
          <div className="pa">
            <Typography.Text strong>
              {t('MigDescription.supportTitle')}
            </Typography.Text>
            <MigRelations relations={mig?.supportsMigs ?? []} />
          </div>
          <div className="pa">
            <Typography.Text strong>
              {t('MigDescription.supportedByTitle')}
            </Typography.Text>
            <MigRelations relations={mig?.supportedByMigs ?? []} />
          </div>
        </Spin>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIG_DESCRIPTION_FRAGMENT = gql`
  fragment MigDescriptionMig on MigV2 {
    id
    description
    weight
    supportsMigs {
      ...MigRelationsMigLight
    }
    supportedByMigs {
      ...MigRelationsMigLight
    }
  }
`;

export const MIG_DESCRIPTION_QUERY = gql`
  query MigDescription($teamId: ID!, $migId: ID!) {
    teamMig(teamId: $teamId, migId: $migId) {
      ...MigDescriptionMig
    }
  }
`;
