import { Empty } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { HappyBlobs } from '../../../../icons/illustrations/HappyBlobs';
import { isSprintKA, SprintKA, WeeklyKA } from '../reportDrawerUtils';
import { KaEntry } from './KaEntry';
import { Btn } from '../../../Button';

interface CommittedActivitiesProps {
  teamId: string;
  keyActivities: (WeeklyKA | SprintKA)[];
  onViewAll: () => void;
}

export const CommittedActivities = ({
  teamId,
  keyActivities,
  onViewAll,
}: CommittedActivitiesProps) => {
  const { t } = useTranslation();
  if (keyActivities.length === 0)
    return (
      <Empty
        description={t('CommittedActivities.noCommitments')}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      >
        <Trans i18nKey="CommittedActivities.viewAllKeyActivities">
          <Btn
            type="link"
            onClick={() => {
              onViewAll();
            }}
            className="pa--none"
          >
            View all
          </Btn>
          your Key Activities
        </Trans>
      </Empty>
    );

  if (
    keyActivities.every((ka) =>
      isSprintKA(ka)
        ? ka.progress.completedAt
        : (ka.progress.achievement ?? 0) >= (ka.progress.commitment ?? 0)
    )
  )
    return (
      <Empty
        image={<HappyBlobs />}
        imageStyle={{ transform: 'scale(1.4)' }}
        description={t('CommittedActivities.allCommitmentsDone')}
      >
        <Trans i18nKey="CommittedActivities.commitmentsCompletedViewAll">
          Have you done more
          <Btn
            type="link"
            onClick={() => {
              onViewAll();
            }}
            className="pa--none"
          >
            view all
          </Btn>
        </Trans>
      </Empty>
    );

  return (
    <div>
      {keyActivities.map((ka) => (
        <KaEntry
          teamId={teamId}
          keyActivity={ka}
          key={isSprintKA(ka) ? ka.sprintKeyActivityId : ka.weeklyKeyActivityId}
        />
      ))}
    </div>
  );
};
