import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { GetOffPeriodsDocument } from '../../../../generated/graphql';

export const useTeamOffPeriods = (teamId: string) => {
  const { data, loading, error } = useQuery(GetOffPeriodsDocument, {
    variables: {
      teamId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const today = dayjs();
  const activePeriods =
    data?.offPeriods?.daysOff.filter(
      (op) =>
        dayjs(op.startDate).isSameOrBefore(today, 'day') &&
        dayjs(op.endDate).isSameOrAfter(today, 'day')
    ) ?? [];

  const upcomingPeriods =
    data?.offPeriods?.daysOff.filter((op) =>
      dayjs(op.startDate).isAfter(today, 'day')
    ) ?? [];

  const endedPeriods =
    data?.offPeriods?.daysOff.filter((op) =>
      dayjs(op.endDate).isBefore(today, 'day')
    ) ?? [];

  return {
    error,
    loading,
    data,
    activePeriods,
    upcomingPeriods,
    endedPeriods,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_OFF_PERIODS = gql`
  query getOffPeriods($teamId: ID!) {
    offPeriods(teamId: $teamId) {
      daysOff {
        id
        teamId
        startDate
        endDate
      }
    }
  }
`;
