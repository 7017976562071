import cx from 'classnames';
import './MilestoneIndicator.less';
import { groupBy } from '../../../../../../services/groupBy';
import { Modal, Popover } from 'antd';
import { Btn } from '../../../../../Button';
import { SearchOutlineIcon } from '../../../../../../icons/initiativeIcons/SearchOutlineIcon';
import { gql } from '@apollo/client';
import { MilestoneIndicator__MilestoneFragment } from '../../../../../../generated/graphql';
import { RecursiveOmit } from '../../../../../../services/typeHelpers';

type Milestone = RecursiveOmit<
  MilestoneIndicator__MilestoneFragment,
  '__typename'
>;

interface Props {
  milestones: Milestone[];
}

import { useState } from 'react';
import { MilestoneCardModalContent } from '../../../../../../appPages/company/initiatives/initiativeDetails/progressSection/components/milestoneTable/components/milestoneActions/components/MilestoneCardModalContent';

export const MilestoneIndicator = ({ milestones }: Props) => {
  const [showDetailsFor, setShowDetailsFor] = useState<Milestone | null>(null);
  if (milestones.length === 0) return null;

  const isLoneMileStone = milestones.length === 1;

  const byStatus = Object.entries(
    groupBy(milestones, (m) => m.metadata.status)
  ).filter(([_status, milestones]) => milestones.length > 0);

  return (
    <>
      <Popover
        content={milestones.map((m) => (
          <div key={m.id} className="flx">
            <Btn
              type="text"
              size="small"
              className="flx flx--1"
              onClick={() => setShowDetailsFor(m)}
            >
              <div
                className={cx(
                  'MilestoneIndicator__status',
                  'MilestoneIndicator__status--isLoneMileStone',
                  {
                    [`MilestoneIndicator__status--${m.metadata.status}`]: true,
                  }
                )}
              />
              <div className="mr">{m.name}</div>
              <SearchOutlineIcon className="ml--auto link" />
            </Btn>
          </div>
        ))}
        overlayStyle={{
          paddingLeft: 30,
          paddingRight: 30,
          maxWidth: 1200,
        }}
      >
        <div className="MilestoneIndicator">
          {byStatus.map(([status, milestones]) => (
            <div
              key={status}
              className={cx('MilestoneIndicator__status', {
                'MilestoneIndicator__status--isLoneMileStone': isLoneMileStone,
                [`MilestoneIndicator__status--${status}`]: true,
              })}
            >
              <div className="MilestoneIndicator__count">
                {milestones.length}
              </div>
            </div>
          ))}
        </div>
      </Popover>
      <Modal
        open={showDetailsFor != null}
        footer={null}
        width={800}
        onCancel={() => setShowDetailsFor(null)}
      >
        {showDetailsFor != null && (
          <MilestoneCardModalContent
            milestoneId={showDetailsFor.domainId.itemId}
          />
        )}
      </Modal>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_INDICATOR__MILESTONES = gql`
  fragment MilestoneIndicator__Milestone on Milestone {
    id
    domainId {
      itemId
    }
    name
    metadata {
      status
    }
  }
`;
