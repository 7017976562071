import Icon from '@ant-design/icons';

const UpcomingEventIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 23"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 20.9961V18.9961H18V8.99609H4V12.9961H2V4.99609C2 4.44609 2.19567 3.97543 2.587 3.58409C2.979 3.19209 3.45 2.99609 4 2.99609H5V0.996094H7V2.99609H15V0.996094H17V2.99609H18C18.55 2.99609 19.021 3.19209 19.413 3.58409C19.8043 3.97543 20 4.44609 20 4.99609V18.9961C20 19.5461 19.8043 20.0171 19.413 20.4091C19.021 20.8004 18.55 20.9961 18 20.9961H14ZM7 22.9961L5.6 21.5961L8.175 18.9961H0V16.9961H8.175L5.6 14.3961L7 12.9961L12 17.9961L7 22.9961ZM4 6.99609H18V4.99609H4V6.99609Z" />
  </svg>
);

export const UpcomingEventIcon = (props: any) => (
  <Icon component={UpcomingEventIconSvg} {...props} />
);
