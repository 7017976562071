import { Card, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import { useTranslation } from 'react-i18next';
import { TeamTag } from '../../../../../generated/graphql';
import { useGetTags } from '../../../../../hooks/useGetTags';
import { friendlyDate } from '../../../../../services/dateFormats';
import { AddLabelModal } from './AddLabelModal';
import { DeleteLabelModal } from './DeleteLabelModal';
import { TagImage } from './TagImage';
import { UpdateTagModal } from './UpdateLabelModal';

interface LabelsTableProps {
  teamId: string;
}

export const LabelsTable = ({ teamId }: LabelsTableProps) => {
  const { tags } = useGetTags(teamId);
  const { t } = useTranslation();
  const columns: ColumnProps<TeamTag>[] = [
    {
      title: t('Tags.tag'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => {
        return <TagImage tag={record} />;
      },
    },
    {
      title: t('Tags.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => friendlyDate(record.createdAt),
    },
    {
      title: t('common.actions'),
      render: (_, record) => {
        return (
          <div className="flx" key={record.id}>
            <DeleteLabelModal tagId={record.id} teamId={teamId} tags={tags} />
            <UpdateTagModal tagId={record.id} teamId={teamId} />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Card styles={{ body: { padding: 0 } }}>
        <div className="addLabelButtonWrapper flx flx--jc-flx-end">
          <AddLabelModal teamId={teamId} />
        </div>

        <Table dataSource={tags} columns={columns} />
      </Card>
    </>
  );
};
