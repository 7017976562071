import { Form } from 'antd';
import { InitiativeForm } from './InitiativeForm';

interface Props {
  isSubInitiative?: boolean;
}

export const InitiativeFormSkeleton = ({ isSubInitiative }: Props) => {
  const [form] = Form.useForm();

  return isSubInitiative ? (
    <InitiativeForm
      form={form}
      loading
      parentInitiativeTag={{
        title: '',
        colorCode: '',
        iconId: '',
        __typename: 'InitiativeTag',
      }}
    />
  ) : (
    <InitiativeForm form={form} loading />
  );
};
