import { gql, useMutation } from '@apollo/client';
import { ErrorAlert } from '../../../../ErrorAlert';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import {
  GetGlobalMigsDocument,
  MigDomainId,
  RemoveMigLinkToTenantDocument,
  TenantLinkTypes,
} from '../../../../../generated/graphql';
import { LinkOffIcon } from '../../../../../icons/LinkOffIcon';
import { Btn } from '../../../../Button';
import { useTranslation } from 'react-i18next';

interface Props {
  migDomainId: MigDomainId | null;
}

export const RemoveMigLinkToTenant = ({ migDomainId }: Props) => {
  const { t } = useTranslation();
  const [removeMigLinkToTenant, { loading: pending }] = useMutation(
    RemoveMigLinkToTenantDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: (
            <strong>{t('RemoveMigLinkToTenant.removeLinkSuccess')}</strong>
          ),
        });
      },
      onError: (error) => {
        showNotification('error', {
          message: <strong>{t('RemoveMigLinkToTenant.removeLinkFail')}</strong>,
          description: <ErrorAlert error={error} />,
        });
      },
      refetchQueries: () => [
        {
          query: GetGlobalMigsDocument,
        },
      ],
    }
  );

  const handleSubmit = () => {
    if (migDomainId)
      removeMigLinkToTenant({
        variables: {
          domainId: {
            tenantId: migDomainId.tenantId,
            teamId: migDomainId.teamId,
            itemId: migDomainId.itemId,
          },
          type: TenantLinkTypes.MIG,
        },
      });
  };

  return (
    <div>
      <Btn
        type="link"
        icon={<LinkOffIcon />}
        onClick={(event) => {
          handleSubmit();
          event.stopPropagation();
        }}
        loading={pending}
      >
        {t('RemoveMigLinkToTenant.unlink')}
      </Btn>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REMOVE_MIG_LINK_TO_TENANT = gql`
  mutation removeMigLinkToTenant(
    $tenantId: ID
    $domainId: TenantLinkDomainIdInput!
    $type: TenantLinkTypes!
  ) {
    removeLinkToTenant(tenantId: $tenantId, domainId: $domainId, type: $type) {
      id
    }
  }
`;
