import './CollapseCard.less';
import { CollapseCardHeader } from './CollapseCardHeader';
import cx from 'classnames';

interface Props {
  children: React.ReactNode;
  headerContent: React.ReactNode;
  className?: string;
  onClick: () => void;
  expanded: boolean;
  loading?: boolean;
}

export const CollapseCard = ({
  children,
  headerContent,
  className,
  onClick,
  expanded,
  loading,
}: Props) => {
  return (
    <div className={cx('CollapseCard', className)}>
      <CollapseCardHeader
        expanded={expanded}
        onClick={onClick}
        loading={loading}
      >
        {headerContent}
      </CollapseCardHeader>
      <div
        className={cx('CollapseCard__container', {
          'CollapseCard__container--hidden': !expanded,
        })}
      >
        <div className="CollapseCard__content flx">{children}</div>
      </div>
    </div>
  );
};
