import { useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery, gql } from '@apollo/client';
import { GetTenantListDocument } from '../../../../../generated/graphql';
import { useTenantOverride } from '../../../../../TenantOverrideProvider';
import { Btn } from '../../../../Button';
import { stringSort } from '../../../../../services/stringSort';

interface Props {
  userTenant: string;
}

export const TenantSelector = (props: Props) => {
  const { t } = useTranslation();
  const {
    tenantId: tenantOverride,
    isOverridden,
    changeTenantIdAndReload,
    revertToUserTenantAndReload,
  } = useTenantOverride();

  const { data } = useQuery(GetTenantListDocument);
  const initialTenant = tenantOverride || props.userTenant;
  const [tenant, setTenant] = useState(initialTenant);

  const handleSwitch = () => {
    changeTenantIdAndReload(tenant);
  };

  const alreadyOnselectedTenant = tenant === initialTenant;

  const activeTenants =
    data?.tenantsV2.tenants
      .filter((t) => !t.archived)
      .sort((a, b) => stringSort(a.name, b.name)) ?? [];
  const archivedTenants =
    data?.tenantsV2.tenants
      .filter((t) => t.archived)
      .sort((a, b) => stringSort(a.name, b.name)) ?? [];

  return (
    <div className="flx flx--column mb--s">
      <Select
        listHeight={140}
        className="mb--s"
        popupMatchSelectWidth={true}
        showSearch
        value={tenant}
        placeholder={t('TenantSelector.placeholder')}
        filterOption={(input, option) => {
          // Antd should have a union type for options, group and options, but they don't
          if (option?.options != null || typeof option?.label !== 'string') {
            return false;
          }
          return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        onChange={setTenant}
        options={[
          {
            label: t('common.active'),
            options: activeTenants?.map((t) => ({
              key: t.domainId.tenantId,
              value: t.domainId.tenantId,
              label: t.name,
            })),
          },
          {
            label: t('common.archived.multiple'),
            options: archivedTenants?.map((t) => ({
              key: t.domainId.tenantId,
              value: t.domainId.tenantId,
              label: t.name,
            })),
          },
        ]}
      />

      <Btn
        type="primary"
        ghost
        onClick={handleSwitch}
        disabled={alreadyOnselectedTenant}
      >
        {t('TenantSelector.switchButton')}
      </Btn>
      <Btn
        type="link"
        onClick={revertToUserTenantAndReload}
        disabled={!isOverridden}
      >
        {t('TenantSelector.backToMyTenant')}
      </Btn>
    </div>
  );
};

export const LIST_TENANTS = gql`
  query getTenantList {
    tenantsV2 {
      tenants {
        name
        id
        archived
        rev
        domainId {
          tenantId
        }
      }
    }
  }
`;
