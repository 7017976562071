import Table, { ColumnProps } from 'antd/es/table';
import { statusColorHelper } from '../../../../../../../../../../services/statusColorHelper';
import './AllocationAndProgressTable.less';
import { InitiativeTag } from '../../../../../../../../../../components/initiative/InitiativeTag';
import { TeamTagFragment } from '../../../../../../../../../../generated/graphql';
import { TagImage } from '../../../../../../../../setup/overviewPage/labels/TagImage';
import cx from 'classnames';
import { stringSort } from '../../../../../../../../../../services/stringSort';
import { SprintStatus } from '../AllocationAndProgressCard';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ExcludedColumnProps<T> extends ColumnProps<T> {
  excluded?: boolean;
}

interface BaseData {
  name: string;
  percentageOfAllActivities: number | null;
  doneVsPlan: number | null;
  completed: number;
  upcomingActivities: number;
  overdueActivities: number;
}

interface InitiativeData extends BaseData {
  initiativeTag: { iconId: string; colorCode: string; title: string };
  initiativeCompleted?: boolean;
  domainId: { itemId: string };
}

interface TeamTagsData extends BaseData {
  teamTag: TeamTagFragment;
}

interface Props {
  data: BaseData[] | InitiativeData[] | TeamTagsData[];
  status?: SprintStatus;
  loading?: boolean;
}

export const AllocationAndProgressTable = ({
  data,
  status,
  loading,
}: Props) => {
  const { t } = useTranslation();
  const columns: ExcludedColumnProps<
    BaseData | InitiativeData | TeamTagsData
  >[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: <h5>{t('common.name')}</h5>,
      width: '30%',
      render: (_, record) => {
        if ('initiativeTag' in record) {
          return (
            <Link
              key={record.domainId.itemId}
              to={`/company/initiatives/${record.domainId.itemId}`}
            >
              <InitiativeTag
                tag={record.initiativeTag}
                completed={record.initiativeCompleted}
              />
            </Link>
          );
        } else if ('teamTag' in record) {
          return <TagImage tag={record.teamTag} />;
        } else {
          return record.name;
        }
      },
    },
    {
      key: 'percentageOfAllActivities',
      dataIndex: 'percentageOfAllActivities',
      title: (
        <h5 className="text-c">
          {t('AllocationAndProgressTable.activitiesPercentage')}
        </h5>
      ),
      sorter: (a, b) =>
        stringSort(a.percentageOfAllActivities, b.percentageOfAllActivities),
      defaultSortOrder: 'descend',
      render: (percentageOfAllActivities) => {
        return (
          <div
            className={cx('text-c', {
              'txt--disabled': percentageOfAllActivities === null,
            })}
          >
            {percentageOfAllActivities === null
              ? '-'
              : percentageOfAllActivities + '%'}
          </div>
        );
      },
    },
    {
      key: 'completed',
      dataIndex: 'completed',
      excluded: status === 'Planned',
      title: (
        <h5 className="text-c">{t('AllocationAndProgressTable.completed')}</h5>
      ),
      sorter: (a, b) => stringSort(a.completed, b.completed),
      render: (completed) => {
        return <div className={'text-c'}>{completed}</div>;
      },
    },
    {
      key: 'overdueActivities',
      dataIndex: 'overdueActivities',
      excluded: status === 'Planned',
      title: (
        <h5 className="text-c">{t('AllocationAndProgressTable.overdue')}</h5>
      ),
      sorter: (a, b) => stringSort(a.overdueActivities, b.overdueActivities),
      render: (overdueActivities) => {
        return <div className={'text-c'}>{overdueActivities}</div>;
      },
    },
    {
      key: 'upcomingActivities',
      dataIndex: 'upcomingActivities',
      excluded: status === 'Finalized',
      title: (
        <h5 className="text-c">{t('AllocationAndProgressTable.upcoming')}</h5>
      ),
      sorter: (a, b) => stringSort(a.upcomingActivities, b.upcomingActivities),
      render: (upcomingActivities) => {
        return <div className={'text-c'}>{upcomingActivities}</div>;
      },
    },
    {
      key: 'doneVsPlan',
      dataIndex: 'doneVsPlan',
      excluded: status === 'Planned' || status === 'Active',
      title: <h5 className="text-c">{t('common.doneVsPlan')}</h5>,
      sorter: (a, b) => stringSort(a.doneVsPlan, b.doneVsPlan),
      render: (doneVsPlan) => {
        return (
          <div
            className="AllocationAndProgressTable__performanceValue text-c"
            style={{
              color: statusColorHelper(doneVsPlan),
            }}
            data-intercom-target="Done vs plan"
          >
            {doneVsPlan === null ? '-' : doneVsPlan + '%'}
          </div>
        );
      },
    },
  ];

  return (
    <div className="AllocationAndProgressTable__table scroll-y">
      <Table
        dataSource={data}
        columns={columns.filter((c) => !c.excluded)}
        rowKey={'id'}
        loading={loading}
        pagination={false}
      />
    </div>
  );
};
