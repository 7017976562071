import { SuccessIcon } from '../../../../../../../../icons/performanceIcons/SuccessIcon';
import { WarningIcon } from '../../../../../../../../icons/performanceIcons/WarningIcon';
import { PostponedIcon } from '../../../../../../../../icons/PostponedIcon';
import { Colors } from '../../../../../../../componentLibrary/Colors';
import { useTranslation } from 'react-i18next';
import { OverviewProgressCard } from '../../../../progressCard/OverviewProgressCard';
import { ArchiveOutlinedIcon } from '../../../../../../../../icons/ArchiveIcon';
import { CompletionCard } from '../../../../progressCard/CompletionCard';

interface Props {
  numberOfSprintKeyActivities: number;
  numberOfCompletedActivities: number;
  numberOfOverdueActivities: number;
  numberOfUpcomingActivities: number;
  numberOfArchivedActivities: number;
}

export const ActiveSprint = ({
  numberOfSprintKeyActivities,
  numberOfCompletedActivities,
  numberOfOverdueActivities,
  numberOfUpcomingActivities,
  numberOfArchivedActivities,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <CompletionCard
        SvgIcon={SuccessIcon}
        iconColor={Colors.Status.OK_GREEN}
        title={t('common.sprintKeyActivity.activities.completed')}
        numberOfCompletedActivities={numberOfCompletedActivities}
        numberOfSprintKeyActivities={numberOfSprintKeyActivities}
        data-intercom-target="Completed sprint key activities card"
      />
      <OverviewProgressCard
        SvgIcon={WarningIcon}
        title={t('common.sprintKeyActivity.activities.overdue')}
        value={numberOfOverdueActivities}
        iconColor={Colors.Status.OVERDUE}
        data-intercom-target="Overdue sprint key activities card"
      />

      <OverviewProgressCard
        SvgIcon={PostponedIcon}
        title={t('common.sprintKeyActivity.activities.upcoming')}
        value={numberOfUpcomingActivities}
        iconColor={Colors.Status.FUTURE_PURPLE}
        data-intercom-target="Upcoming sprint key activities card"
      />
      <OverviewProgressCard
        SvgIcon={ArchiveOutlinedIcon}
        title={t('common.sprintKeyActivity.activities.archived')}
        value={numberOfArchivedActivities}
        iconColor={Colors.Grays.SECONDARY_FONT}
        data-intercom-target="Archived sprint key activities card"
      />
    </>
  );
};
