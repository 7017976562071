import Icon, {
  IconComponentProps,
} from '@ant-design/icons/lib/components/Icon';

const LinkOffSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 20 20">
    <g>
      <path
        d="m16.042 13.706-1.25-1.292a2.543 2.543 0 0 0 1.354-.885c.347-.438.521-.948.521-1.532 0-.694-.243-1.284-.73-1.77a2.41 2.41 0 0 0-1.77-.73h-3.333V5.831h3.333c1.153 0 2.135.406 2.948 1.218.812.813 1.219 1.796 1.219 2.948 0 .792-.205 1.521-.615 2.188a3.99 3.99 0 0 1-1.677 1.52Zm-2.833-2.875-1.667-1.667h1.792v1.667h-.125Zm3.291 8L1.167 3.497l1.167-1.166 15.333 15.333-1.167 1.167Zm-7.333-4.667H5.834c-1.153 0-2.136-.406-2.948-1.219-.813-.812-1.219-1.795-1.219-2.948 0-.958.292-1.812.875-2.562a4.06 4.06 0 0 1 2.25-1.48l1.542 1.542h-.5a2.41 2.41 0 0 0-1.771.73 2.41 2.41 0 0 0-.73 1.77c0 .695.244 1.285.73 1.771s1.076.73 1.77.73h3.334v1.666Zm-2.5-3.333V9.164h1.354l1.646 1.667h-3Z"
        fill="#197DE1"
      />
    </g>
  </svg>
);

export const LinkOffIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={LinkOffSvg} {...props} />
);
