import Icon from '@ant-design/icons';

const SprintIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.59984 11.3333L2.6665 10.4L9.0665 3.99996H7.33317V5.33329H5.99984V2.66663H9.88317C10.0609 2.66663 10.2332 2.69996 10.3998 2.76663C10.5665 2.83329 10.7109 2.92774 10.8332 3.04996L12.8332 5.03329C13.1332 5.33329 13.4998 5.56663 13.9332 5.73329C14.3665 5.89996 14.8332 5.98885 15.3332 5.99996V7.33329C14.6443 7.33329 14.0193 7.22774 13.4582 7.01663C12.8971 6.80552 12.3998 6.48885 11.9665 6.06663L11.2998 5.36663L9.83317 6.83329L11.3332 8.33329L6.9665 10.85L6.29984 9.69996L9.1665 8.04996L8.03317 6.91663L3.59984 11.3333ZM1.99984 6.66663V5.33329H5.33317V6.66663H1.99984ZM0.666504 4.66663V3.33329H3.99984V4.66663H0.666504ZM12.9832 3.33329C12.6165 3.33329 12.2998 3.20274 12.0332 2.94163C11.7665 2.68052 11.6332 2.36663 11.6332 1.99996C11.6332 1.63329 11.7665 1.3194 12.0332 1.05829C12.2998 0.797182 12.6165 0.666626 12.9832 0.666626C13.3498 0.666626 13.6665 0.797182 13.9332 1.05829C14.1998 1.3194 14.3332 1.63329 14.3332 1.99996C14.3332 2.36663 14.1998 2.68052 13.9332 2.94163C13.6665 3.20274 13.3498 3.33329 12.9832 3.33329ZM1.99984 2.66663V1.33329H5.33317V2.66663H1.99984Z" />
  </svg>
);

export const SprintIcon = (props: any) => (
  <Icon component={SprintIconSvg} {...props} />
);
