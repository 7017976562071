import { AkpiPeriodSelect } from '../../../AkpiPeriodSelect';
import { Card, Spin } from 'antd';
import { TimePeriodFilter } from '../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { DisplayDateRange } from '../../../DisplayDateRange';
import { TeamCommitmentAndAchievementsResponseV2 } from '../../../../hooks/useTeamCommitmentAndAchievementV2';
import { TeamCommitmentFulfillment } from '../../TeamCommitmentFulfillment';

interface Props {
  timePeriodFilter?: TimePeriodFilter;
  teamCommitmentAndAchievements: TeamCommitmentAndAchievementsResponseV2;
  onPeriodChange: (value?: TimePeriodFilter) => void;
}

export const CommitmentAndAchievementCard = ({
  timePeriodFilter,
  teamCommitmentAndAchievements,
  onPeriodChange,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Spin spinning={teamCommitmentAndAchievements.loading}>
      <Card
        styles={{ body: { minHeight: 170 } }}
        extra={
          <AkpiPeriodSelect
            timePeriodFilter={timePeriodFilter}
            onPeriodChange={onPeriodChange}
          />
        }
        title={
          <span>
            {t('CommitmentAndAchievementCard.title')}
            <DisplayDateRange
              className="txt--slim ml"
              startDate={teamCommitmentAndAchievements.data?.startDate}
              endDate={teamCommitmentAndAchievements.data?.endDate}
            />
          </span>
        }
      >
        <TeamCommitmentFulfillment
          {...teamCommitmentAndAchievements}
          loading={false}
        />
      </Card>
    </Spin>
  );
};
