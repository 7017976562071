import Icon from '@ant-design/icons';

const ProgressIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.5125 15.5052L11 5.01768L16.9912 11.0088L17 11.0177L17.0088 11.0088L27.4875 0.530178V4.73232L17 15.2198L11.0088 9.22866L11 9.21982L10.9912 9.22866L0.5125 19.7073V15.5052ZM0.5125 27.4875V24.5052L3.4875 21.5302V27.4875H0.5125ZM6.5125 27.4875V18.5052L9.4875 15.5302V27.4875H6.5125ZM12.5125 27.4875V15.5304L15.4875 18.5426V27.4875H12.5125ZM18.5125 27.4875V18.5427L21.4875 15.5677V27.4875H18.5125ZM24.5125 27.4875V12.5052L27.4875 9.53018V27.4875H24.5125Z" />
  </svg>
);

export const ProgressIcon = (props: any) => (
  <Icon component={ProgressIconSvg} {...props} />
);
