import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface Props {
  title: React.ReactNode;
  tooltipContent: React.ReactNode;
  tooltipPlacement?: TooltipPlacement;
}

export const TitleWithTooltip = ({
  title,
  tooltipContent,
  tooltipPlacement,
}: Props) => {
  return (
    <span>
      {title}
      <Tooltip
        className="space--l"
        placement={tooltipPlacement}
        title={tooltipContent}
      >
        <QuestionCircleOutlined />
      </Tooltip>
    </span>
  );
};
