import { Typography } from 'antd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { roundToDecimals } from '../../services/roundNumbersHelper';
import { MigCompactGraph } from '../graphs/MigCompactGraph';
import { PerformanceTag } from '../PerformanceTag';
import { MigCardHeader } from './MigCard/MigCardHeader';
import { MigDescriptionLazy } from './MigCard/MigDescription';
import { DisplayDate } from '../DisplayDate';
import './MigCard.less';
import { lineColor } from '../../styleVars';
import { friendlyDate } from '../../services/dateFormats';
import { gql } from '@apollo/client';
import { MigCardMigFragment } from '../../generated/graphql';

export const MIG_CARD_MIG_FRAGMENT = gql`
  fragment MigCardMig on MigV2 {
    id
    unit
    periodData {
      targetDate
      actual
      goal
      historic
    }
    minY
    maxY
    mostRecentReport {
      targetDate
      actual
      goal
      goalFulfillment
    }
    domainId {
      itemId
      teamId
    }
    ...MigCardHeaderMig
  }
`;

export interface MigCardProps {
  mig: MigCardMigFragment;
  activeDot?: number | null;
  showDescription: boolean;
  className?: string;
  graphHeight?: number;
  action?: React.ReactNode;
}

export const MigCard = ({
  mig,
  activeDot,
  showDescription,
  className,
  action,
  graphHeight = 180,
}: MigCardProps) => {
  const { t } = useTranslation();

  return (
    <div className={cx('MigCard', className)}>
      <MigCardHeader mig={mig} action={action} truncateName />

      <div className="MigCard__body">
        {showDescription && (
          <div className="MigCard__description">
            <MigDescriptionLazy
              teamId={mig.domainId.teamId}
              migId={mig.domainId.itemId}
            />
          </div>
        )}
        <div className="MigCard__graphArea">
          <div className="MigCard__unit">
            <div className="MigCard__unitText">
              <div
                className="MigCard__rotate"
                title={mig.unit ?? undefined}
                style={{ width: graphHeight - 48 }}
              >
                {mig.unit}
              </div>
            </div>
          </div>
          <div className="MigCard__graph">
            <MigCompactGraph
              height={graphHeight}
              lineData={mig.periodData.map((pd) => ({
                ...pd,
                label: friendlyDate(pd.targetDate),
              }))}
              minY={mig.minY}
              maxY={mig.maxY}
              lineConf={[
                {
                  dataKey: 'historic',
                  stroke: lineColor.historic,
                  strokeWidth: '1',
                  name: t('common.historic') as string,
                },
                {
                  dataKey: 'goal',
                  stroke: lineColor.goal,
                  name: t('common.goal') as string,
                },
                {
                  dataKey: 'actual',
                  stroke: lineColor.actual,
                  name: t('common.actual') as string,
                },
              ]}
              referenceLine={mig.mostRecentReport?.targetDate ?? undefined}
              activeDot={activeDot}
            />
          </div>
        </div>
      </div>
      <div className="MigCard__footer">
        <FooterItem title={t('MigCard.resultFor')}>
          <DisplayDate
            date={mig.mostRecentReport?.targetDate}
            emptyState={'-'}
          />
        </FooterItem>
        <FooterItem title={t('common.goal')}>
          <span className="MigGoal">
            {roundToDecimals(mig.mostRecentReport?.goal) ?? '-'}
          </span>
        </FooterItem>
        <FooterItem title={t('common.actual')}>
          <span className="MigActual">
            {roundToDecimals(mig.mostRecentReport?.actual) ?? '-'}
          </span>
        </FooterItem>
        <FooterItem title={t('MigCard.goalFulfillment')}>
          <PerformanceTag value={mig.mostRecentReport?.goalFulfillment} />
        </FooterItem>
      </div>
    </div>
  );
};

export const FooterItem: React.FC<{
  title: string;
  children?: React.ReactNode;
}> = ({ title, children }) => (
  <div className="flx--1 text-c">
    <Typography.Text type="secondary">{title}</Typography.Text>
    <div className="bold">{children}</div>
  </div>
);
