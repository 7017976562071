import { CSSProperties } from 'react';
import { friendlyUsername } from '../services/friendlyUsername';
import './UserAvatar.less';

type User = {
  initials: string;
  email: string;
  name?: string | null;
  displayName?: string | null;
};
interface Props {
  user?: User;
  style?: CSSProperties;
  size?: 'small' | 'default';
}

export const UserAvatar = ({ user, style, size }: Props) => {
  const styles = {
    backgroundColor: user?.email ? getColor(user.email) : undefined,
    '--size': size === 'small' ? '20px' : '32px',
    ...style,
  };

  return (
    <div
      style={styles}
      className="UserAvatar"
      title={user && friendlyUsername(user)}
    >
      {user?.initials ?? '~'}
    </div>
  );
};

const getColor = (str: string) => {
  const hash = getHash(str);

  const hue = hash % 360;

  const saturationOffset = (getHash(str.split('@')[0]) % 3) * 5;
  const saturation = 40 + saturationOffset;
  const lightness = 40;

  return 'hsl(' + hue + ', ' + saturation + '%, ' + lightness + '%)';
};

const getHash = (str: string) => {
  let hash = 0;

  // Why `hash << 5`? It's basically just an (hopefully) more optimized way of multiplying by 31
  // For more details see https://stackoverflow.com/a/51961690
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  return hash;
};
