import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const CheckIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="2 2 14 14"
    fill="currentColor"
  >
    <g>
      <path
        d="M7.36406 13.3672L3.16406 9.16719L4.41406 7.91719L7.36406 10.8672L13.6141 4.61719L14.8641 5.86719L7.36406 13.3672Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const CheckIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={CheckIconSvg} {...props} />
);
