import { LockFilled, PlusOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../../../../../../../components/SectionHeader';
import {
  Action,
  CurrentSprintProgress_MitemSprintFragment,
  GetAccelerationMeetingDocument,
  MitemStatus,
} from '../../../../../../../../generated/graphql';
import { useClosestPeriods } from '../../../../../../../../hooks/useClosestPeriods';
import { mitemStatusColorPairs } from '../../../../../../../../styleVars';
import { SprintMitemTableActions } from '../../../../../../sprint/summary/components/SprintMitemTableActions';
import { AddExistingMitemDrawer } from './AddExistingMitemDrawer';
import { CreateMitemDrawer } from '../../../../../../sprint/planning/components/CreateMitemDrawer';
import { useAccelerationMeeting } from '../../../AccelerationMeetingProvider';
import { MitemCard } from './components/MitemCard';
import './CurrentSprintProgress.less';
import { SprintDetailsCard } from './currentSprintProgress/SprintDetailsCard';
import { SprintProgressCard } from './currentSprintProgress/SprintProgressCard';
import { SkaTable } from '../../../components/SkaTable';
import { isPossibleToChangeArchiveState } from '../../../../../../sprint/common/services/useMitemArchivation';
import { Btn } from '../../../../../../../../components/Button';
import { useTeamPermissions } from '../../../../../../../../usePermissions';
import { TourLink } from '../../../../../../../../components/TourLink';
import { gql } from '@apollo/client';

interface Props {
  currentSprint?: CurrentSprintProgress_MitemSprintFragment;
  teamId: string;
}

export const CurrentSprintProgress = ({ currentSprint, teamId }: Props) => {
  const { t } = useTranslation();
  const { currentPeriod } = useClosestPeriods(teamId);
  const { sprintKeyActivityContext } = useAccelerationMeeting();
  const { isAllowed: isAllowedToEditSka } = useTeamPermissions({
    requestedAction: {
      resource: 'sprintKA',
      action: Action.UPDATE,
    },
    teamId: teamId,
  });

  const [showCreateMitemModal, setShowCreateMitemModal] = useState(false);
  const [showAddExistingMitemModal, setShowAddExistingMitemModal] =
    useState(false);

  if (!currentSprint) return null;

  const sprintKeyActivities = currentSprint.mitems ?? [];
  return (
    <>
      <div className="flx mb--xl">
        <SprintProgressCard
          total={sprintKeyActivities.length}
          completed={
            sprintKeyActivities.filter((m) => m.status === 'COMPLETED').length
          }
        />
        <SprintDetailsCard sprint={currentSprint} />
      </div>
      <SectionHeader
        title={
          <div className="flx flx--ai-baseline">
            <div className="mr--l CurrentSprintProgress__lockIcon">
              <LockFilled />
            </div>

            <Typography.Title level={3} className="mb--none">
              {t('CurrentSprintProgress.title')}
            </Typography.Title>
            <TourLink
              intercomTarget="Acceleration meeting - Sprint key activity commitment guide modal"
              engTourId={3561}
              sweTourId={3417}
            />
          </div>
        }
        extra={
          <div>
            <Btn
              className="mr--s"
              onClick={() => {
                setShowAddExistingMitemModal(true);
              }}
              icon={<PlusOutlined />}
            >
              {t('CurrentSprintProgress.addExisting')}
            </Btn>
            <Btn
              onClick={() => {
                setShowCreateMitemModal(true);
              }}
              icon={<PlusOutlined />}
            >
              {t('common.create')}
            </Btn>
          </div>
        }
      >
        <SkaTable
          mitems={sprintKeyActivities.filter(
            (t) => t.status !== MitemStatus.COMPLETED
          )}
          pagination={false}
          committable={true}
          actionColumn={
            isAllowedToEditSka
              ? {
                  title: t('common.actions') as string,
                  dataIndex: 'actions',
                  key: 'actions',
                  render: (_, mitem) => (
                    <SprintMitemTableActions
                      teamId={teamId}
                      mitem={mitem}
                      isLockedSprint={currentSprint.locked}
                      isAllowedToArchive={
                        currentSprint
                          ? isPossibleToChangeArchiveState(mitem, currentSprint)
                          : false
                      }
                      editable={
                        !(
                          mitem.status === MitemStatus.NOT_COMPLETED ||
                          mitem.status === MitemStatus.ARCHIVED
                        )
                      }
                      onEdited={(sprintKaId, ownerId) => {
                        sprintKeyActivityContext.setUserCommitment(sprintKaId, {
                          userId: ownerId,
                        });
                      }}
                      onCopied={(sprintKaId, ownerId) => {
                        sprintKeyActivityContext.addSprintKeyActivity(
                          sprintKaId,
                          ownerId
                        );
                      }}
                      refetchQueries={[
                        {
                          query: GetAccelerationMeetingDocument,
                          variables: { teamId: currentSprint.teamId },
                        },
                      ]}
                    />
                  ),
                }
              : null
          }
        />
      </SectionHeader>
      <div className="mt--l">
        <MitemCard
          title={t('common.sprintKeyActivity.statuses.completed')}
          titleColors={mitemStatusColorPairs.COMPLETED}
        >
          <SkaTable
            mitems={sprintKeyActivities.filter(
              (t) => t.status === MitemStatus.COMPLETED
            )}
            pagination={false}
          />
        </MitemCard>
      </div>
      <AddExistingMitemDrawer
        teamId={currentSprint.teamId}
        showModal={showAddExistingMitemModal}
        refetchQueries={[
          {
            query: GetAccelerationMeetingDocument,
            variables: { teamId: currentSprint.teamId },
          },
        ]}
        onCancel={() => {
          setShowAddExistingMitemModal(false);
        }}
      />
      <CreateMitemDrawer
        teamId={currentSprint.teamId}
        showHelpLinks={false}
        showModal={showCreateMitemModal}
        onCancel={() => {
          setShowCreateMitemModal(false);
          setShowAddExistingMitemModal(false);
        }}
        onCompleted={(sprintKeyActivity) => {
          sprintKeyActivityContext.addSprintKeyActivity(
            sprintKeyActivity.id,
            sprintKeyActivity.owner.id
          );
          setShowCreateMitemModal(false);
          setShowAddExistingMitemModal(false);
        }}
        prefilledValues={{ deadline: currentPeriod?.timePeriodEndDate }}
        refetchQueries={[
          {
            query: GetAccelerationMeetingDocument,
            variables: { teamId: currentSprint.teamId },
          },
        ]}
      />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_SUMMARY = gql`
  fragment CurrentSprintProgress_MitemSprint on MitemSprint {
    id
    teamId
    locked
    finalized
    mitems {
      status
      ...SkaTable_Mitem
      ...SprintMitemTableActions_Mitem
    }
    ...SprintDetailsCard_MitemSprint
  }
`;
