import { useEffect } from 'react';
import { PerformanceTag } from '../../../../../components/PerformanceTag';
import { useTeamKeyActivityPerformanceLazy } from '../../../../../hooks/useKeyActivityPerformanceQuery';

interface Props {
  teamId: string;
  fetchDetails: boolean;
}

export const TeamKeyActivityPerformanceTag = ({
  teamId,
  fetchDetails,
}: Props) => {
  const {
    fetchTeamKeyActivityPerformance,
    teamKeyActivityPerformance,
    loading,
  } = useTeamKeyActivityPerformanceLazy(teamId);

  useEffect(() => {
    if (fetchDetails) fetchTeamKeyActivityPerformance?.();
  }, [fetchDetails, fetchTeamKeyActivityPerformance]);

  return (
    <PerformanceTag
      value={teamKeyActivityPerformance?.teamTargetFulfillment}
      loading={loading}
    />
  );
};
