import { gql } from '@apollo/client';
import {
  Action,
  TeamResourceType,
  TitleSection__InitiativeLightFragment,
} from '../../../../../generated/graphql';
import { InitiativeTag } from '../../../../../components/initiative/InitiativeTag';
import { Icons } from '../../../../../components/initiatives/InitiativeIcons';
import { TitleSectionSkeleton } from './TitleSection.Skeleton';
import { Btn } from '../../../../../components/Button';
import { ManageInitiativeTeamsModal } from '../../../../../components/initiatives/InitiativeActionsDropdown/components/manageInitiativeTeamsModal/ManageInitiativeTeamsModal';
import { useState } from 'react';
import { usePermissionForTeams } from '../../../../../hooks/usePermissionForTeams';
import { useTranslation } from 'react-i18next';

type Props = {
  initiative: TitleSection__InitiativeLightFragment;
};

export const TitleSection = ({ initiative }: Props) => {
  const { t } = useTranslation();
  const [joinInitiativeModalOpen, setJoinInitiativeModalOpen] = useState(false);
  const { data, loading: loadingPermissions } = usePermissionForTeams(
    TeamResourceType.TEAM_LINKS,
    Action.CREATE
  );

  const linkableTeams =
    data?.permittedToPerformActionForTeam.permittedResources ?? [];

  return (
    <div className="flx--1">
      <div className="flx flx--ai-center">
        <h2>{initiative.name}</h2>
        <InitiativeTag
          title={initiative.tag.title}
          className="ml--l"
          borderColor={initiative.tag.colorCode}
          icon={Icons[initiative.tag.iconId as keyof typeof Icons]}
          completed={!!initiative?.metadata.completedAt}
          archived={initiative?.metadata.archived}
        />

        <Btn
          disabled={loadingPermissions || linkableTeams.length === 0}
          className="ml--auto"
          onClick={() => setJoinInitiativeModalOpen(true)}
        >
          {t('TitleSection.joinInitiative')}
        </Btn>
        <ManageInitiativeTeamsModal
          initiativeId={initiative.domainId.itemId}
          visible={joinInitiativeModalOpen}
          title={<h3>{t('TitleSection.modalTitle')}</h3>}
          onClose={() => setJoinInitiativeModalOpen(false)}
        />
      </div>
    </div>
  );
};

TitleSection.Skeleton = TitleSectionSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_TITLE_SECTION__INITIATIVE_LIGHT = gql`
  fragment TitleSection__InitiativeLight on InitiativeLight {
    id
    domainId {
      itemId
      tenantId
    }
    name
    tag {
      title
      iconId
      colorCode
    }
    metadata {
      completedAt
      archived
    }
  }
`;
