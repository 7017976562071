import { Typography } from 'antd';
import InitiativesPlaceholder from './InitiativesPlaceholder.svg';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../components/Button';
import { useCurrentLocale } from '../../../../hooks/useCurrentLocale';

interface Props {
  width?: string;
  height?: string;
  header: string;
  className?: string;
}

export const NoInitiativesPlaceholder = ({
  width = '450px',
  height = '400px',
  header,
  className,
}: Props) => {
  const { t } = useTranslation();
  const { locale } = useCurrentLocale();

  const selectedLanguage = locale ?? 'en';
  const url = `https://help.howwe.io/${selectedLanguage}/articles/66753-get-started-with-initiatives-in-howwe`;

  return (
    <div className={cx('flx flx--ai-center', className)}>
      <img
        width={width}
        height={height}
        src={InitiativesPlaceholder}
        alt="No initiatives"
      />
      <div className={'flx flx--column ml--x '} style={{ width: '30%' }}>
        <h2>{header}</h2>
        <Typography.Text type="secondary">
          {t('NoInitiativesPlaceholder.description')}
        </Typography.Text>
        <Btn
          type="primary"
          size="middle"
          className="mt--xl"
          style={{ width: '40%' }}
          target="_blank"
          href={url}
        >
          {t('NoInitiativesPlaceholder.learnMore')}
        </Btn>
      </div>
    </div>
  );
};
