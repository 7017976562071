import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '../Auth0Provider';
import { envConf } from '../envConf';
import { UserRole, UserTeamRole } from '../generated/graphql';
import { useMeLazy } from './useMe';

export function useIntercom() {
  const { isAuthenticated } = useAuth0();
  const [getUser, { data }] = useMeLazy();
  const { INTERCOM_KEY } = envConf;

  useEffect(() => {
    if (isAuthenticated) {
      getUser();
    }
  }, [getUser, isAuthenticated]);

  useEffect(() => {
    if (!data || !INTERCOM_KEY) return;

    const dateInSeconds = Math.floor(Date.now() / 1000);

    const accDays = data.me.teamAssociations.map(
      (ta) => ta.team.accelerationDay
    );

    (window as any).Intercom('boot', {
      api_base: 'https://api-iam.eu.intercom.io',
      app_id: INTERCOM_KEY,
      user_id: data.me.id,
      name: data.me.name, // Full name
      email: data.me.email, // Email address
      created_at: data.me.accountSignedUpAt,
      company: {
        company_id: data.me.tenant.domainId.tenantId,
        name: data.me.tenant.name,
      },
      isLeader: data.me.teamAssociations.some(
        (ta) => ta.role === UserTeamRole.LEADER
      ),
      isSprintAdmin: data.me.teamAssociations.some((ta) => ta.sprintAdmin),
      isAdmin: data.me.role === UserRole.ADMIN,
      language_override: data.me.locale,
      last_seen_on_main_app_at: dateInSeconds,
      signed_up_at: data.me.accountSignedUpAt,
      active_sprint_term_ends_at: data.me.intercomTerms?.activeTermEndDate,
      next_sprint_term_starts_at: data.me.intercomTerms?.nextTermStartDate,
      teamIds: data.me.teamAssociations.map((ta) => ta.team.id).join(','),
      accelerationDays: accDays
        .filter((item, index) => accDays.indexOf(item) === index)
        .join(','),
    });
  }, [data, INTERCOM_KEY]);

  const location = useLocation();
  useEffect(() => {
    if (!data || !INTERCOM_KEY) return; // if there's no data then we haven't booted yet
    (window as any).Intercom('update');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
}
