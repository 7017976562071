import { PlusOutlined } from '@ant-design/icons';
import { Divider, Form, Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTags } from '../../../../../../hooks/useGetTags';
import { modalType } from '../../../../setup/overviewPage/labels/AddLabelModal';
import { AddOrUpdateTagModal } from '../../../../setup/overviewPage/labels/AddOrUpdateTagModal';
import { TagImage } from '../../../../setup/overviewPage/labels/TagImage';
import { Btn } from '../../../../../../components/Button';
import { PermissionChecker } from '../../../../../../PermissionChecker';
import { Action } from '../../../../../../generated/graphql';

interface Props {
  teamId: string;
  value?: string[];
  className?: string;
  placeholder?: string;
  onChange?: (value: string[]) => void;
}

export const TagsSelector = ({
  teamId,
  className,
  placeholder,
  value = [],
  onChange,
}: Props) => {
  const { tags } = useGetTags(teamId);
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const [newTagId, setNewTagId] = useState<string | null>(null);
  const [openAddLabelModal, setOpenAddLabelModal] = useState(false);
  const [open, setOpen] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [tagNameInSearchbar, setTagNameInSearchbar] = useState<
    string | undefined
  >();
  const inputRef = useRef<BaseSelectRef>(null);

  useEffect(() => {
    if (newTagId && form) {
      const newFormValues = [...form.getFieldValue('tags'), newTagId];
      form.setFieldsValue({ tags: newFormValues });
      setNewTagId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTagId]);

  function handleChange(newValues: string[]) {
    onChange?.(newValues);
    setButtonDisabled(false);
  }

  return (
    <>
      <Select
        className={className}
        placeholder={placeholder}
        showSearch
        ref={inputRef}
        mode="multiple"
        value={value}
        open={open}
        onChange={handleChange}
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        filterOption={(input, option) => {
          setTagNameInSearchbar(input);
          return (option!.label as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider className="mb--s mt--xs" />
            <PermissionChecker
              resourceOwner={{
                type: 'TeamResource',
                teamId: teamId,
                requestedAction: [
                  {
                    resource: 'teamTag',
                    action: Action.CREATE,
                  },
                ],
              }}
            >
              <div className="flx flx--jc-space-between pa">
                <Btn
                  className="flx--1"
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setOpen(!open);
                    setOpenAddLabelModal(!openAddLabelModal);
                  }}
                >
                  {t('Tags.createTag')}
                </Btn>
                <Btn
                  className="flx--1"
                  onClick={() => setOpen(!open)}
                  disabled={buttonDisabled}
                  type="primary"
                >
                  {t('Tags.ok')}
                </Btn>
              </div>
            </PermissionChecker>
          </>
        )}
      >
        {tags.map((tag) => (
          <Select.Option
            name="tags"
            value={tag.id}
            label={tag.name}
            key={tag.id}
          >
            <TagImage tag={tag} />
          </Select.Option>
        ))}
      </Select>

      <AddOrUpdateTagModal
        teamId={teamId}
        visible={openAddLabelModal}
        onCancel={() => {
          inputRef.current?.focus();
          setOpenAddLabelModal(false);
          setTagNameInSearchbar(undefined);
        }}
        onCompleted={(data) => {
          onChange?.([...value, data.id]);
          setButtonDisabled(false);
          setNewTagId(data.id);
          inputRef.current?.focus();
          setTagNameInSearchbar(undefined);
          setOpenAddLabelModal(false);
        }}
        currentName={tagNameInSearchbar}
        title={t('Tags.createTag')}
        modalActionType={modalType.createTag}
      />
    </>
  );
};
