import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { GetTimeZonesDocument } from '../generated/graphql';

export const useTimezones = () => {
  const { loading, error, data } = useQuery(GetTimeZonesDocument);

  return {
    timezones: data?.timezones.timezones ?? [],
    error,
    loading,
  };
};

export const useLazyTimezones = () => {
  return useLazyQuery(GetTimeZonesDocument);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TIME_ZONES = gql`
  query getTimeZones {
    timezones {
      timezones
    }
  }
`;
