import Icon from '@ant-design/icons';

const OpenDoorOutlineIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 12V10.6667H1.33333V0H8V0.666667H10.6667V10.6667H12V12H9.33333V2H8V12H0ZM5.33333 6.66667C5.52222 6.66667 5.68056 6.60278 5.80833 6.475C5.93611 6.34722 6 6.18889 6 6C6 5.81111 5.93611 5.65278 5.80833 5.525C5.68056 5.39722 5.52222 5.33333 5.33333 5.33333C5.14444 5.33333 4.98611 5.39722 4.85833 5.525C4.73056 5.65278 4.66667 5.81111 4.66667 6C4.66667 6.18889 4.73056 6.34722 4.85833 6.475C4.98611 6.60278 5.14444 6.66667 5.33333 6.66667ZM2.66667 10.6667H6.66667V1.33333H2.66667V10.6667Z" />
  </svg>
);

export const OpenDoorOutlineIcon = (props: any) => (
  <Icon component={OpenDoorOutlineIconSvg} {...props} />
);
