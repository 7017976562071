import { useTranslation } from 'react-i18next';
import { friendlyDate } from '../../../../services/dateFormats';
import './SubHeader.less';

interface Props {
  lastOutcomeDate: string;
  unit: string;
  warnings?: number;
  mirroredFromTeam?: {
    name: string;
  } | null;
}

export const SubHeader = ({
  lastOutcomeDate,
  unit,
  warnings,
  mirroredFromTeam,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="SubHeader flx mt--s">
      <div className="mr--l">
        <span className="txt--secondary">{t('SubHeader.dueBy')}</span>
        <span className="SubHeader__boldText">
          {friendlyDate(lastOutcomeDate)}
        </span>
      </div>
      <div className="mr--l">
        <span className="txt--secondary">{t('common.unit')}: </span>
        <span className="SubHeader__boldText">{unit}</span>
      </div>
      {mirroredFromTeam && (
        <div className="mr--l">
          <span className="txt--secondary">
            {t('SubHeader.mirroredFrom')}:{' '}
          </span>
          <span className="SubHeader__boldText">{mirroredFromTeam.name}</span>
        </div>
      )}
      {warnings !== undefined && warnings > 0 && (
        <div>
          <span className="SubHeader--missingData">
            {t('SubHeader.missingPeriodData')}
          </span>
        </div>
      )}
    </div>
  );
};
