interface Props {
  totalCommitted: number;
  recommendedCommitment: number;
}

export const CommitmentQuota = ({
  totalCommitted,
  recommendedCommitment,
}: Props) => {
  return (
    <>
      <span data-testid="commitmentQuotaTotalCommitment">
        {format(totalCommitted)}
      </span>
      /<span>{format(recommendedCommitment)}</span>
    </>
  );
};

const format = (x: number) => {
  const integerPart = Math.floor(x);
  const decimalsPart = getDecimals(x);
  return decimalsPart ? `${integerPart}.${decimalsPart}` : integerPart;
};

const getDecimals = (number: number) =>
  +parseFloat(number.toFixed(2)).toString().split('.')[1];
