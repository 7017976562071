import { Card, Typography, Switch } from 'antd';
import './InterpolationHelper.less';
import { interpolationPeriod } from '../MigCurveEditor';

import { useTranslation } from 'react-i18next';
import { BaseMigPeriod } from '../../../../../services/migUtils';
import { NumberInput } from '../../../../NumberInput';
import { Btn } from '../../../../Button';

interface Props {
  interpolationFunction: (startValue: number, endValue: number) => void;
  setSelectedFields: (
    selectedFields: [interpolationPeriod, interpolationPeriod]
  ) => void;
  periodData: BaseMigPeriod[];
  selectedFields: [interpolationPeriod, interpolationPeriod];
  integersOnly: boolean;
  setIntegersOnly: (checked: boolean) => void;
}

export const InterpolationHelper = ({
  interpolationFunction,
  periodData,
  integersOnly,
  selectedFields: [firstSelectedDate, lastSelectedDate],
  setIntegersOnly,
  selectedFields,
  setSelectedFields,
}: Props) => {
  const { t } = useTranslation();
  const startPeriod =
    periodData.find((p) => p.targetDate === firstSelectedDate?.targetDate) ??
    periodData[0];
  const endPeriod =
    periodData.find((p) => p.targetDate === lastSelectedDate?.targetDate) ??
    periodData[periodData.length - 1];
  return (
    <Card
      title={t('InterpolationHelper.title')}
      className="flx flx--column mb mt fullWidth"
      size="small"
    >
      <div className="flx mb">
        <div className="mr">
          <div>{t('common.startDate')}</div>
          <Typography.Text type="secondary">
            {startPeriod.targetDate}
          </Typography.Text>
        </div>
        <div className="CreateCurveHelper__Input">
          <label htmlFor="start">{t('InterpolationHelper.startValue')}</label>

          <NumberInput
            id="start"
            name="start"
            value={selectedFields[0].value ?? undefined}
            onChange={(value) =>
              setSelectedFields([
                {
                  ...selectedFields[0],
                  value: value == null ? null : Number(value),
                },
                selectedFields[1],
              ])
            }
          />
        </div>
      </div>

      <div className="flx flx--column">
        <div className="flx">
          <div className="mr">
            <div>{t('common.endDate')}</div>
            <Typography.Text type="secondary">
              {endPeriod.targetDate}
            </Typography.Text>
          </div>
          <div className="CreateCurveHelper__Input">
            <label htmlFor="end">{t('InterpolationHelper.endValue')}</label>
            <NumberInput
              id="end"
              name="end"
              value={selectedFields[1].value ?? undefined}
              onChange={(value) =>
                setSelectedFields([
                  selectedFields[0],
                  {
                    ...selectedFields[1],
                    value: value == null ? null : Number(value),
                  },
                ])
              }
            />
          </div>
          <div className="mr--l">
            <label>{t('InterpolationHelper.integersOnly')}</label>
            <div>
              <Switch
                onChange={(checked) => setIntegersOnly(checked)}
                checked={integersOnly}
              />
            </div>
          </div>
          <Btn
            type="primary"
            ghost
            onClick={() => {
              interpolationFunction(startPeriod.goal!, endPeriod.goal!);
            }}
            style={{
              alignSelf: 'flex-end',
            }}
          >
            {t('InterpolationHelper.interpolate')}
          </Btn>
        </div>
      </div>
    </Card>
  );
};
