import { Select } from 'antd';
import { stringSort } from '../../../../services/stringSort';
import { toMap } from '../../../../services/toMap';

interface Team {
  id: string;
  name: string;
  umbrellaTeam?: boolean | null;
  parentTeamId?: string | null;
  teamHierarchy: { id: string; name: string }[];
}

interface Props {
  teams: Team[];
  value: Team | null;
  onChange: (team: Team) => void;
}

export const TeamSelector = ({ teams, value, onChange }: Props) => {
  teams = teams
    .filter((t) => !t.umbrellaTeam)
    .sort((teamA, teamB) => {
      const levelDelta =
        teamA.teamHierarchy.length - teamB.teamHierarchy.length;

      if (levelDelta !== 0) return levelDelta;
      return stringSort(teamA.name, teamB.name);
    });

  const teamLookup = toMap(teams, (t) => t.id);

  return (
    <Select
      data-intercom-target="MIG alignment select team"
      value={value?.id}
      onSelect={(_: any, option: any) => {
        // todo: propper typings for onSelect --> https://github.com/ant-design/ant-design/issues/34201
        const team = teamLookup[option.value];
        return onChange(team);
      }}
      showSearch
      style={{ width: 200 }}
      optionFilterProp="children"
      filterOption={(input, option) => {
        return option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {teams?.map((t) => (
        <Select.Option key={t.id} value={t.id} title={t.name}>
          {t.name}
        </Select.Option>
      ))}
    </Select>
  );
};
