import React from 'react';
import { TooltipProps } from 'recharts';
import {
  NameType,
  Payload,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import './CustomTooltip.less';
import { isStringOrNumber } from '../../services/typeGuards';
import './CustomTooltip.less';

export interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
  payload?: Array<Payload<ValueType, NameType> & { stroke?: string }>;
}
export const CustomTooltip = ({
  active,
  payload,
  label: _label,
  formatter,
  labelFormatter,
}: CustomTooltipProps) => {
  if (!active || !payload || payload.length === 0) {
    return null;
  }

  let label: React.ReactNode = _label;
  if (isStringOrNumber(_label) && labelFormatter) {
    label = labelFormatter(_label, payload);
  }

  return (
    <div className="CustomTooltip">
      {label}
      {payload?.map((item, i) => (
        <div key={`${i}-${item.name}`}>
          <ChartLegend
            color={item.color}
            borderColor={item.stroke}
            name={'' + (item.name ?? 'unnamed')}
          />
          :
          <span className="CustomTooltip__value">
            {formatter
              ? formatter(item.value ?? '-', item.name ?? '-', item, i, payload)
              : item.value}
          </span>
        </div>
      ))}
    </div>
  );
};

interface ChartLegendProps {
  name: string;
  color?: string;
  borderColor?: string;
}

export function ChartLegend({ name, color, borderColor }: ChartLegendProps) {
  const hasColor = color || borderColor;
  const borderStyle = borderColor && { border: `1px solid ${borderColor}` };
  return (
    <React.Fragment>
      {hasColor && (
        <span
          key={name}
          style={{ backgroundColor: color, ...borderStyle }}
          className="ChartLegend__dot"
        />
      )}
      {name}
    </React.Fragment>
  );
}
