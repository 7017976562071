import { MigInterval } from '../generated/graphql';
import { API_DATE_FORMAT, standardDateFormat } from './dateFormats';
import { roundToDecimals } from './roundNumbersHelper';

import dayjs, { Dayjs } from 'dayjs';

interface MigDto {
  timePeriodInterval: MigInterval;
  periodData: PeriodDto[];
}

interface PeriodDto {
  targetDate: string;
  goal: number;
  actual?: number | null;
  historic?: number | null;
}

export const migDTOtoMigData = <T extends MigDto>(
  migDTO: T,
  adjustPeriods?: boolean
) => {
  const adjustedPeriodData = migDtoToPeriodData(
    migDTO.periodData,
    migDTO.timePeriodInterval,
    adjustPeriods
  );

  return {
    ...migDTO,
    lastOutcomeDate:
      adjustedPeriodData[adjustedPeriodData.length - 1].targetDate,
    firstOutcomeDate: adjustedPeriodData[0].targetDate,
    periodData: adjustedPeriodData,
  };
};

const migDtoToPeriodData = (
  periods: PeriodDto[],
  interval: MigInterval,
  adjustPeriods?: boolean
) => {
  return periods.map(({ targetDate, goal, actual, historic }, index) => {
    // We need to do a correction and move targetDate to end of month, due to an earlier bug :(
    // We are doing this for MIGs with intervals month or quarter, this in order for matching targetDates during curve calculation
    // We skip the first targetDate (index == 0) since those should be start of period rather than end of.
    let correctedTargetDate = targetDate;
    if (
      adjustPeriods &&
      (interval === MigInterval.MONTH || interval === MigInterval.QUARTER) &&
      index >= 1
    ) {
      correctedTargetDate = dayjs(targetDate)
        .endOf('month')
        .format(API_DATE_FORMAT);
    }
    if (adjustPeriods && interval === MigInterval.WEEK) {
      if (index === 0) {
        correctedTargetDate =
          dayjs(targetDate).day() !== 0
            ? dayjs(targetDate).startOf('week').format(API_DATE_FORMAT)
            : dayjs(targetDate).add(1, 'day').format(API_DATE_FORMAT);
      } else {
        correctedTargetDate = dayjs(targetDate)
          .endOf('week')
          .format(API_DATE_FORMAT);
      }
    }
    return {
      targetDate: correctedTargetDate,
      goal: goal,
      actual: actual ?? undefined,
      historic: historic ?? undefined,
    };
  });
};

const MEASURE_TYPE = {
  QUARTER: 'quarter' as const,
  MONTH: 'months' as const,
  WEEK: 'weeks' as const,
};

export const getNumberOfOutcomesMig = (
  interval: MigInterval,
  firstDate?: Dayjs,
  lastDate?: Dayjs
) => {
  const measureType: 'months' | 'weeks' =
    interval === MigInterval.QUARTER
      ? MEASURE_TYPE[MigInterval.MONTH]
      : MEASURE_TYPE[interval];
  if (!lastDate || !firstDate) return 0;

  // We need to have "startOf" when it comes to monthly and quarterly intervals.
  // This is due to how moment is comparing a monthly diff, for example 2020-01-31 to 2020-02-28 would result in a diff of 0 (if feb would have 31 days it would be 1)
  // Therefor we are only comparing the first day of every interval when it comes to MIGs
  const startDate = firstDate.startOf(measureType);
  const endDate = lastDate.startOf(measureType);

  return endDate.diff(startDate, MEASURE_TYPE[interval]) + 1;
};

export const createEmptyCurve = (
  startFrom: Dayjs,
  numberOfTargetDates: number,
  interval: MigInterval
) => {
  const startPeriodDate = getMigInitialDate[interval](startFrom);
  const targetDate = standardDateFormat(startPeriodDate);
  const startPeriod = {
    targetDate,
    goal: null,
    actual: null,
    historic: null,
  };

  const targetPeriods = Array.from({ length: numberOfTargetDates }, (_, i) => {
    const targetDateDayjs = getMigTargetDate[interval](startPeriodDate, i);
    const targetDate = standardDateFormat(targetDateDayjs);
    return {
      targetDate,
      goal: null,
      actual: null,
      historic: null,
    };
  });
  return [startPeriod, ...targetPeriods];
};

export const getMigTargetDate = {
  WEEK: (startDate: Dayjs, periodIndex: number) =>
    startDate.add(periodIndex, 'weeks').endOf('week'),
  MONTH: (startDate: Dayjs, periodIndex: number) =>
    startDate.add(periodIndex, 'months').endOf('month'),
  QUARTER: (startDate: Dayjs, periodIndex: number) => {
    const addTwoMonths = startDate.add(2, 'month').endOf('month');
    const addQuarter = addTwoMonths
      .add(periodIndex * 3, 'month')
      .endOf('month');
    return addQuarter;
  },
};

export const getMigInitialDate = {
  WEEK: (firstTargetDate: string | Dayjs) =>
    dayjs(firstTargetDate).startOf('week'),
  MONTH: (firstTargetDate: string | Dayjs) =>
    dayjs(firstTargetDate).startOf('month'),
  QUARTER: (firstTargetDate: string | Dayjs) =>
    dayjs(firstTargetDate).startOf('month'),
};

export const correctTargetDates = (
  interval: MigInterval,
  targetDate: string
) => {
  if (interval === MigInterval.MONTH || interval === MigInterval.QUARTER) {
    return dayjs(targetDate).endOf('month').format(API_DATE_FORMAT);
  }
  return targetDate;
};

export function interpolateCurve(
  initialValue: number,
  lastValue: number,
  numPeriods: number,
  integersOnly: boolean
) {
  if (numPeriods < 2) {
    throw new Error('Must be at least two periods to interpolate');
  }
  const totalMovement = lastValue - initialValue;
  const incPerPeriod = totalMovement / (numPeriods - 1);

  return Array.from({ length: numPeriods }, (_, i) => {
    if (i === 0) {
      return initialValue;
    }

    const value = i * incPerPeriod + initialValue;
    return integersOnly ? Math.round(value) : roundToDecimals(value, 2);
  });
}

export type BaseMigPeriod = {
  targetDate: string;
  goal?: number | null;
  actual?: number | null;
  historic?: number | null;
};
