import { ComponentType } from 'react';
import './ProgressCardIcon.less';

interface Props {
  SvgIcon: ComponentType;
  color: string;
}

export const ProgressCardIcon = ({ SvgIcon, color }: Props) => {
  return (
    <div>
      <div
        //This will break if we send in other values than a hex code with 6 characters
        //TODO: FIX!!
        style={{
          backgroundColor: color + 10,
          color: color,
        }}
        className="ProgressCardIcon__iconFrame mr--l"
      >
        <div className="center-content ProgressCardIcon__icon">
          <SvgIcon />
        </div>
      </div>
    </div>
  );
};
