import { ReadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useCurrentLocale } from '../hooks/useCurrentLocale';

/* eslint-disable react/jsx-no-target-blank */

interface Props {
  /** urlTemplate must include `{{locale}}` that indicates where the current locale ('sv' | 'en') will be inserted */
  urlTemplate: string;
  linkText?: string;
}

const LOCALE_TOKEN = '{{locale}}';

export const LearnMoreLink = ({ urlTemplate, linkText }: Props) => {
  const { locale } = useCurrentLocale();
  const { t } = useTranslation();

  validateTemplateUrl(urlTemplate);

  const url = urlTemplate.replace(LOCALE_TOKEN, locale ?? 'en');
  const text = linkText || t('LearnMoreLink.linkText');

  return (
    <a href={url} target="_blank" rel="noopener">
      <ReadOutlined className="space--r" />
      {text}
    </a>
  );
};

const validateTemplateUrl = (urlTemplate: string) => {
  const isValidTemplate = urlTemplate.includes(LOCALE_TOKEN);

  if (!isValidTemplate) {
    const msg = `Value of prop \`urlTemplate\` must include \`${LOCALE_TOKEN}\``;
    if (import.meta.env.MODE === 'production') {
      console.warn(msg);
    } else {
      throw Error(msg);
    }
  }
};
