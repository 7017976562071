import React from 'react';
import './ChartLegend.less';

interface Props {
  name: string;
  color?: string;
  borderColor?: string;
}

export function ChartLegend({ name, color, borderColor }: Props) {
  const hasColor = color || borderColor;
  const borderStyle = borderColor && { border: `1px solid ${borderColor}` };
  return (
    <React.Fragment>
      {hasColor && (
        <span
          key={name}
          style={{ backgroundColor: color, ...borderStyle }}
          className="ChartLegend__dot"
        />
      )}
      {name}
    </React.Fragment>
  );
}
