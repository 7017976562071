import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  GetTeamFullMigsV2Document,
  GetTeamMigV2Document,
} from '../../../../generated/graphql';

export const useTeamMigV2 = (
  teamId: string,
  migId: string,
  tenantId?: string
) => {
  const { data, loading, error } = useQuery(GetTeamMigV2Document, {
    variables: {
      teamId,
      migId,
      tenantId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const teamMig = data?.teamMig;

  return {
    error,
    loading,
    teamMig,
  };
};

export const useTeamFullMigsLazyV2 = () => {
  const [fetchFullTeamMigs, { data, loading, error }] = useLazyQuery(
    GetTeamFullMigsV2Document,
    { fetchPolicy: 'network-only' }
  );

  const teamMigs = data?.teamMigs.migs ?? [];

  return [
    fetchFullTeamMigs,
    {
      teamMigs,
      error,
      loading,
    },
  ] as const;
};

export const useTeamMigLazyV2 = () => {
  const [fetchTeamMig, { loading, data, error }] =
    useLazyQuery(GetTeamMigV2Document);

  const teamMig = data?.teamMig;

  return {
    fetchTeamMig,
    error,
    loading,
    teamMig,
  };
};

export const SUPPORT_MIG_FRAGMENT = gql`
  fragment SupportMig on MigLight {
    id
    name
    lastOutcomeDate
    status
    domainId {
      itemId
      teamId
      tenantId
    }
    team {
      id
      name
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TEAM_MIG_FRAGMENT = gql`
  fragment TeamMig on MigV2 {
    id
    name
    description
    unit
    timePeriodInterval
    preferredOutcome
    lastOutcomeDate
    firstOutcomeDate
    startDate
    status
    minY
    maxY
    weight
    rev
    isMirrored(teamId: $teamId)
    mirroredFromTeam(teamId: $teamId) {
      id
      name
    }
    domainId {
      itemId
      teamId
      tenantId
    }
    mostRecentReport {
      targetDate
      actual
      goal
      goalFulfillment
      historic
    }
    team {
      id
      name
      teamHierarchy {
        id
        name
        tenantId
      }
    }
    mirroredBy {
      domainId {
        itemId
      }
    }
    periodData {
      targetDate
      actual
      goal
      historic
    }
    supportsMigs {
      ...SupportMig
    }
    supportedByMigs {
      ...SupportMig
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_MIG_V2 = gql`
  ${TEAM_MIG_FRAGMENT}
  query getTeamMigV2($teamId: ID!, $migId: ID!, $tenantId: ID) {
    teamMig(teamId: $teamId, migId: $migId, tenantId: $tenantId) {
      ...TeamMig
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_FULL_MIGS_V2 = gql`
  ${TEAM_MIG_FRAGMENT}
  query getTeamFullMigsV2(
    $teamId: ID!
    $tenantId: ID
    $statusFilters: [MigStatus!]
  ) {
    teamMigs(
      teamId: $teamId
      tenantId: $tenantId
      statusFilters: $statusFilters
    ) {
      migFulfillment {
        goalFulfillment
        id
        domainId {
          itemId
          tenantId
        }
      }
      migs {
        ...TeamMig
      }
    }
  }
`;
