import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { friendlyDate } from '../services/dateFormats';
import {
  GetKeyActivityTargetFulfillmentDocument,
  GetKeyActivityTargetFulfillmentForTeamDocument,
  KeyActivityType,
} from '../generated/graphql';
import { useMemo } from 'react';

export type TeamFulfillment = {
  teamId: string;
  teamTargetFulfillment: number | null;
  teamTargetFulfillmentDelta: undefined | number | null;
};

type TeamPerformance = {
  details: {
    label: string | null;
    id: string;
    type: KeyActivityType;
    actualValue: number | null;
    targetDate: string | null;
    targetFulfillment: number | null;
    targetValue: number | null;
    targetFulfillmentDelta: undefined | number | null;
  }[];
  teamTargetFulfillment: number | null;
  teamFulfillmentDelta: undefined | number | null;
};

export const useTeamsTargetFulfillment = () => {
  const { loading, data, error } = useQuery(
    GetKeyActivityTargetFulfillmentDocument
  );

  return useMemo(
    () => ({
      error,
      loading,
      akpiTargetFulfillment: data?.keyActivityTargetFulfillment.teams.map(
        (t) => {
          return {
            teamId: t.teamId,
            teamTargetFulfillment: t.teamTargetFulfillment,
          } as TeamFulfillment;
        }
      ),
    }),
    [data?.keyActivityTargetFulfillment.teams, error, loading]
  );
};

export const useTeamKeyActivityPerformance = (
  teamId: string,
  tenantId?: string
) => {
  const { data, loading, error } = useQuery(
    GetKeyActivityTargetFulfillmentForTeamDocument,
    {
      variables: { teamId, tenantId },
    }
  );
  const teamPerformance =
    data?.keyActivityTargetFulfillmentForTeam &&
    ({
      teamTargetFulfillment:
        data.keyActivityTargetFulfillmentForTeam.teamTargetFulfillment,
      details: data.keyActivityTargetFulfillmentForTeam.details.map((d) => ({
        id: d.id,
        type: d.type,
        targetFulfillment: d.targetFulfillment,
        targetDate: d.targetDate,
        targetValue: d.targetValue,
        actualValue: d.actualValue,
        label: friendlyDate(d.targetDate),
      })),
    } as TeamPerformance);
  return {
    teamKeyActivityPerformance: teamPerformance,
    loading,
    error,
  };
};

export const useTeamKeyActivityPerformanceLazy = (
  teamId: string,
  tenantId?: string
) => {
  const [fetchTeamPerformance, { data, error, loading }] = useLazyQuery(
    GetKeyActivityTargetFulfillmentForTeamDocument,
    {
      variables: { teamId, tenantId },
    }
  );

  if (error) {
    return { ...data, data: null };
  }
  const teamPerformance =
    data?.keyActivityTargetFulfillmentForTeam &&
    ({
      teamTargetFulfillment:
        data.keyActivityTargetFulfillmentForTeam.teamTargetFulfillment,
      details: data.keyActivityTargetFulfillmentForTeam.details.map((d) => ({
        id: d.id,
        type: d.type,
        targetFulfillment: d.targetFulfillment,
        targetDate: d.targetDate,
        targetValue: d.targetValue,
        actualValue: d.actualValue,
        label: friendlyDate(d.targetDate),
      })),
    } as TeamPerformance);

  return {
    fetchTeamKeyActivityPerformance: fetchTeamPerformance,
    teamKeyActivityPerformance: teamPerformance,
    error,
    loading,
  };
};

export const useKeyActivityPerformance = (
  teamId: string,
  keyActivityId: string,
  tenantId?: string
) => {
  const {
    teamKeyActivityPerformance: teamPerformance,
    loading,
    error,
  } = useTeamKeyActivityPerformance(teamId, tenantId);

  const akpiData = teamPerformance?.details.find((d) => d.id === keyActivityId);

  return {
    data: akpiData,
    loading,
    error,
  };
};

export const GET_TEAM_KEY_ACTIVITY_TARGET_FULFILLMENT = gql`
  query getKeyActivityTargetFulfillmentForTeam($teamId: ID!, $tenantId: ID) {
    keyActivityTargetFulfillmentForTeam(teamId: $teamId, tenantId: $tenantId) {
      teamTargetFulfillment
      details {
        id
        name
        type
        actualValue
        targetDate
        targetFulfillment
        targetValue
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_KEY_ACTIVITY_TARGETFULFILLMENT = gql`
  query getKeyActivityTargetFulfillment($tenantId: ID) {
    keyActivityTargetFulfillment(tenantId: $tenantId) {
      teams {
        teamId
        teamTargetFulfillment
      }
    }
  }
`;
