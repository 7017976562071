export const without = <T extends {}, K extends keyof T | '__typename'>(
  obj: T,
  ...exclude: K[]
): Omit<T, K> => {
  const entries = Object.entries(obj).filter(
    ([key]) => !exclude.includes(key as K)
  );

  return Object.fromEntries(entries) as any;
};
