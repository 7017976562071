import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  alignment?: string;
  team: {
    id: string;
    name: string;
  };
}

export const TeamInitiativesCardContent = ({ team }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flx, mb--s">
      {/* <Typography.Text
        type="secondary"
        className="space--r"
        style={{ fontSize: '11px' }}
      >
        {t('TeamInitiativesCard.supports')}
      </Typography.Text>
      <Typography.Text style={{ fontSize: '11px' }}>
        {alignment}
      </Typography.Text> */}
      <div className="flx flx--ai-center ">
        <Typography.Text
          type="secondary"
          className="TeamInitiativesCard__content space--r"
        >
          {t('TeamInitiativesCard.drivenBy')}
        </Typography.Text>
        <Link
          to={'/team/' + team.id}
          className="TeamInitiativesCard__content TeamInitiativesCard__teamName"
        >
          {team.name}
        </Link>
      </div>
    </div>
  );
};
