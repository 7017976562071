import Icon from '@ant-design/icons';

const SmartphoneIconSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.66683 15.3333C1.30016 15.3333 0.986274 15.2027 0.725163 14.9416C0.464052 14.6805 0.333496 14.3666 0.333496 14V1.99996C0.333496 1.63329 0.464052 1.3194 0.725163 1.05829C0.986274 0.797182 1.30016 0.666626 1.66683 0.666626H8.3335C8.70016 0.666626 9.01405 0.797182 9.27516 1.05829C9.53627 1.3194 9.66683 1.63329 9.66683 1.99996V14C9.66683 14.3666 9.53627 14.6805 9.27516 14.9416C9.01405 15.2027 8.70016 15.3333 8.3335 15.3333H1.66683ZM1.66683 13.3333V14H8.3335V13.3333H1.66683ZM1.66683 12H8.3335V3.99996H1.66683V12ZM1.66683 2.66663H8.3335V1.99996H1.66683V2.66663Z" />
  </svg>
);

export const SmartphoneIcon = (props: any) => (
  <Icon component={SmartphoneIconSvg} {...props} />
);
